export const industries = [
	{ label: "Healthcare", value: "healthcare" },
	{ label: "Technology", value: "technology" },
	{ label: "Finance", value: "finance" },
	{ label: "Manufacturing", value: "manufacturing" },
	{ label: "Education", value: "education" },
	{ label: "Entertainment", value: "entertainment" },
	{ label: "Retail", value: "retail" },
	{ label: "Automotive", value: "automotive" },
	{ label: "Aerospace", value: "aerospace" },
	{ label: "Energy", value: "energy" },
	{ label: "Telecommunications", value: "telecommunications" },
	{ label: "Hospitality", value: "hospitality" },
	{ label: "Transportation", value: "transportation" },
	{ label: "Real Estate", value: "real_estate" },
	{ label: "Food and Beverage", value: "food_and_beverage" },
	{ label: "Fashion", value: "fashion" },
	{ label: "Pharmaceuticals", value: "pharmaceuticals" },
	{ label: "Construction", value: "construction" },
	{ label: "Agriculture", value: "agriculture" },
	{ label: "Media", value: "media" },
	{ label: "Insurance", value: "insurance" },
	{ label: "Legal", value: "legal" },
	{ label: "Consulting", value: "consulting" },
	{ label: "Travel", value: "travel" },
	{ label: "Environmental", value: "environmental" },
	{ label: "Government", value: "government" },
	{ label: "Non-Profit", value: "non_profit" },
	{ label: "Sports", value: "sports" },
	{ label: "Biotechnology", value: "biotechnology" },
	{ label: "Mining", value: "mining" },
	{ label: "Music", value: "music" },
	{ label: "Gaming", value: "gaming" },
	{ label: "Logistics", value: "logistics" },
	{ label: "Marketing", value: "marketing" },
	{ label: "Human Resources", value: "human_resources" },
	{ label: "Information Technology", value: "information_technology" },
	{ label: "Space Exploration", value: "space_exploration" },
	{ label: "Renewable Energy", value: "renewable_energy" },
	{ label: "Textiles", value: "textiles" },
	{ label: "Engineering", value: "engineering" },
	{ label: "Forestry", value: "forestry" },
	{ label: "Venture Capital", value: "venture_capital" },
	{ label: "Blockchain", value: "blockchain" },
	{ label: "Sustainable Agriculture", value: "sustainable_agriculture" },
	{ label: "Fitness and Wellness", value: "fitness_and_wellness" },
	{ label: "Education Technology", value: "education_technology" },
];
export const strength = [];
export const level = [];
