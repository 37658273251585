/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PropTypes from "prop-types";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer, { DesktopSideModal } from "./ModalContainer";
import {
	openAddFundsModal,
	openMoveFundsModal,
	openWithdrawFundsModal,
} from "../redux/payments/paymentActions";
import {
	formatAmountToIncludeCurrency,
	getProfilePercentage,
} from "../common/utils";
import DepositFundsFlow from "../screens/payment/depositFlow";
import { fetchCustomerWallet } from "../redux/payments/paymentServices";
import Skeleton from "react-loading-skeleton";
import WithdrawFundsFlow from "../screens/payment/withdrawalflow";
import MoveFundsFlow from "../screens/payment/moveFundsFlow";
import { InactiveWalletIcon } from "../layout/assets";
import ActivateWallet from "../screens/dashboard/activateWallet";
import { onToggleActivateWalletModal } from "../redux/authRedux/authActions";

const Balance = ({
	isShowInvestBtn,
	onRequestClick,
	btnText,
	wallet,
	showAddFunds,
	showWithdrawFunds,
	cardTitle,
	showMoveFunds = false,
}) => {
	const dispatch = useDispatch();

	const { currentUser, toggleActivateWalletModal } = useSelector(
		(state) => state.auth
	);
	const { savingPersonalFormSuccess } = useSelector((state) => state.lease);
	const {
		toggleAddFundsModal,
		customerWalletLoading,
		customerWallet,
		toggleWithdrawFundsModal,
		toggleMoveFundsModal,
	} = useSelector((state) => state.payment);

	const [activePaymentTab, setActivePaymentTab] = useState(1);
	const [toggleBalance, setToggleBalance] = useState(false);
	const [toggleModal, setToggleModal] = useState(false);
	const [activeWithdrawalTab, setActiveWithdrawalTab] = useState(1);
	const [activeMovementTab, setActiveMovementTab] = useState(1);

	useEffect(() => {
		if (toggleModal) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "auto";
		};
	}, [toggleModal]);

	useEffect(() => {
		if (savingPersonalFormSuccess) {
			dispatch(fetchCustomerWallet(savingPersonalFormSuccess?.get("walletId")));
		} else {
			return;
		}
	}, [savingPersonalFormSuccess]);

	const walletCriteria = {
		bvn: currentUser?.bvn,
		accessPin: currentUser?.accessPin,
	};

	const isWalletActive = getProfilePercentage(walletCriteria);
	// console.log(isWalletActive);

	// const dataValidation = {
	// 	workEmail: currentUser?.workEmail,
	// 	workEmailVerified: currentUser?.workEmailVerified,
	// 	phoneNumber: currentUser?.phone,
	// 	phoneNumberVerified: currentUser?.phoneVerified,
	// 	homeAddress: currentUser?.homeAddress,
	// 	bvn: currentUser?.bvn,
	// 	bankName: currentUser?.bankName,
	// 	accountNumber: currentUser?.accountNumber,
	// 	workStatus: currentUser?.workStatus,
	// 	salaryRange: currentUser?.salaryRange,
	// 	idType: currentUser?.idType,
	// 	idNumber: currentUser?.idNo,
	// };

	// const percentageCompletionOfForm = getProfilePercentage(dataValidation);

	const isProfileReady = () => {
		if (isWalletActive > 5) {
			setActivePaymentTab(1);
		} else {
			setActivePaymentTab(0);
		}
	};

	const isWithdrawalReady = () => {
		if (isWalletActive > 5) {
			setActiveWithdrawalTab(1);
		} else {
			setActiveWithdrawalTab(0);
		}
	};

	const isMoveFundsReady = () => {
		if (isWalletActive > 5) {
			setActiveMovementTab(1);
		} else {
			setActiveWithdrawalTab(0);
		}
	};

	const onBeginAddFunds = () => {
		setToggleModal(false);
		dispatch(openAddFundsModal());
		isProfileReady();
	};

	const onBeginWithdrawFunds = () => {
		setToggleModal(false);
		dispatch(openWithdrawFundsModal());
		isWithdrawalReady();
	};

	const onBeginMoveFunds = () => {
		setToggleModal(false);
		dispatch(openMoveFundsModal());
		isMoveFundsReady();
	};

	return (
		<>
			{toggleModal && (
				<div
					className="fixed z-[13] inset-0 bg-[#393F4266]"
					onClick={() => setToggleModal(false)}
				></div>
			)}
			{toggleAddFundsModal && (
				<DesktopSideModal modal={toggleAddFundsModal}>
					<DepositFundsFlow
						activePaymentTab={activePaymentTab}
						setActivePaymentTab={setActivePaymentTab}
					/>
				</DesktopSideModal>
			)}
			{toggleWithdrawFundsModal && (
				<DesktopSideModal modal={toggleWithdrawFundsModal}>
					<WithdrawFundsFlow
						activeWithdrawalTab={activeWithdrawalTab}
						setActiveWithdrawalTab={setActiveWithdrawalTab}
					/>
				</DesktopSideModal>
			)}
			{toggleMoveFundsModal && (
				<DesktopSideModal modal={toggleMoveFundsModal}>
					<MoveFundsFlow
						activeMovementTab={activeMovementTab}
						setActiveMovementTab={setActiveMovementTab}
					/>
				</DesktopSideModal>
			)}
			{toggleActivateWalletModal && (
				<ModalContainer modal={toggleActivateWalletModal}>
					<ActivateWallet />
				</ModalContainer>
			)}
			<div className="flex justify-between items-start">
				<div
					className={`bg-[#fefefe] rounded-md w-full flex flex-col gap-2 w-full sm:w-9/12 md:w-10/12 max-w-[400px] sm:max-w-[360px] p-4 md:py-5 indexBalance ${
						isWalletActive
							? "h-[120px] md:h-[130px] justify-between"
							: "h-[110px] md:h-[120px]"
					}`}
				>
					<div>
						<div className="flex justify-between items-center">
							<div className="flex items-center gap-2">
								<p
									className={`${
										isWalletActive < 5 ? "text-[#AABDC5]" : "text-[#8E9DA490]"
									} text-xs zabalMedFont`}
								>
									{cardTitle ? cardTitle : "Account Balance"}
								</p>
								<button onClick={() => setToggleBalance((prev) => !prev)}>
									{toggleBalance ? (
										<FaEyeSlash
											size={15}
											color={isWalletActive < 5 ? "#AABDC5" : "#00525D"}
										/>
									) : (
										<FaEye
											size={15}
											color={isWalletActive < 5 ? "#AABDC5" : "#00525D"}
										/>
									)}
								</button>
							</div>
							<div className="relative">
								<button
									onClick={() => setToggleModal(true)}
									disabled={customerWalletLoading || isWalletActive < 5}
								>
									<BsThreeDotsVertical
										size={16}
										color={isWalletActive < 5 ? "#AABDC5" : "#00525D"}
									/>
								</button>
								{toggleModal && (
									<div className="absolute z-20 right-0 top-[25px] bg-white rounded divide-y divide-[#EEF2F3] px-4 py-2 flex flex-col text-[13px] w-[160px] indexBalance">
										{showAddFunds && (
											<button
												className="text-start py-2 text-[#00131B] zabalRegFont hover:text-fortcore-green hover:font-semibold"
												onClick={onBeginAddFunds}
											>
												Add Funds
											</button>
										)}
										<button className="text-start py-2 text-[#00131B] zabalRegFont hover:text-fortcore-green hover:font-semibold">
											View Statements
										</button>
										{showWithdrawFunds && (
											<button
												className="text-start py-2 text-[#00131B] zabalRegFont hover:text-fortcore-green hover:font-semibold"
												onClick={onBeginWithdrawFunds}
											>
												Withdraw
											</button>
										)}
										{showMoveFunds && (
											<button
												className="text-start py-2 text-[#00131B] zabalRegFont hover:text-fortcore-green hover:font-semibold"
												onClick={onBeginMoveFunds}
											>
												Move Funds
											</button>
										)}
									</div>
								)}
							</div>
						</div>
						<h3
							className="font-bold chillaxFont"
							style={{ color: isWalletActive < 5 ? "#AABDC5" : "#00525D" }}
						>
							{toggleBalance ? (
								<span className="text-xl">****</span>
							) : (
								<div className="flex gap-1 items-center">
									{customerWalletLoading && customerWallet ? (
										<p className="text-2xl">
											{formatAmountToIncludeCurrency(
												wallet?.currency || "NGN",
												wallet?.availableBalance || 0
											)}
										</p>
									) : customerWalletLoading ? (
										<Skeleton className="!w-[110px] h-[25px]" />
									) : customerWallet ? (
										<p className="text-2xl">
											{formatAmountToIncludeCurrency(
												wallet?.currency || "NGN",
												wallet?.availableBalance || 0
											)}
										</p>
									) : (
										<p className="text-2xl">
											<span>NGN </span>
											<span>00.</span>
											<span className="text-xl">00</span>
										</p>
									)}
								</div>
							)}
						</h3>
					</div>
					{!customerWalletLoading && isWalletActive < 8 && (
						<div className="flex justify-between items-center">
							<div className="flex items-center gap-1">
								<InactiveWalletIcon width={20} height={20} />
								<p className="zabalRegFont text-xs text-[#555F63]">
									Wallet is inactive
								</p>
							</div>
							<button
								className="zabalBoldFont text-xs text-fortcore-green border border-[#BCD2D5] bg-[#D3E5E780] rounded-2xl px-5 py-1.5"
								onClick={() => dispatch(onToggleActivateWalletModal(true))}
							>
								Activate Wallet
							</button>
						</div>
					)}
				</div>
				{isShowInvestBtn && (
					<div className="hidden md:block">
						<button
							className="text-primary-color w-[105px] text-[13px] border border-primary-color rounded-[5px] p-2.5 capitalize zabalRegFont font-semibold tracking-wider"
							onClick={onRequestClick}
						>
							{btnText ? btnText : "Invest"}
						</button>
					</div>
				)}
			</div>
		</>
	);
};
Balance.propTypes = {
	isShowInvestBtn: PropTypes.bool,
	onRequestClick: PropTypes.func,
	btnText: PropTypes.string,
	wallet: PropTypes.object,
	showAddFunds: PropTypes.bool,
	showWithdrawFunds: PropTypes.bool,
	cardTitle: PropTypes.string,
	showMoveFunds: PropTypes.bool,
};
export default Balance;
