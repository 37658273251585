import React from "react";
import { bardata, options } from "./investment";
import { Bar } from "react-chartjs-2";

const Summary = () => {
  const investmentSummary = {
    labels: bardata?.map((barlabel) => barlabel?.month),
    datasets: [
      {
        data: bardata?.map((bar) => bar.investment),
        backgroundColor: ['#557C8A'],
        barPercentage: 0.72,
        // borderRadius: 15,
        categoryPercentage: 0.8,
      },
      {
        data: bardata?.map((bar) => bar.interest),
        backgroundColor: ['#FFC670'],
        barPercentage: 0.72,
        // borderRadius: 15,
        categoryPercentage: 0.6,
      },
    ],
  }
  return (
    <div className="rounded-md bg-[#F6F7F8] p-4 md:p-5 space-y-4 scroll-wrapper">
      <div className="flex gap-y-3 gap-x-4 flex-wrap zabalMedFont">
        <div className="flex items-center gap-1.5">
          <div className="h-2.5 w-2.5 bg-[#557C8A]"></div>
          <p className="text-xs text-[#393F42]">Total Value of Active Investment (NGN)</p>
        </div>
        <div className="flex items-center gap-1.5">
          <div className="h-2.5 w-2.5 bg-[#FFC670]"></div>
          <p className="text-xs text-[#393F42]">Total Interest on Active Investment (NGN)</p>
        </div>
      </div>
      <div className="zabalRegFont h-[300px] md:h-[350px] min-w-[450px] sm:min-w-[500px]">
        <Bar data={investmentSummary} options={options} />
      </div>
    </div>
  )
}
export default Summary