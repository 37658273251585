import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdLock, MdOutlineClose } from "react-icons/md";
import PropTypes from "prop-types";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import {
	fetchCurrentInterest,
	submitNegotiation,
} from "../../redux/lease/leaseServices";
import { PlainSelect, SelectDropDown } from "../../layout/CustomComponents";
import {
	leaseStructureArr,
	expectedDuration,
} from "../../screens/leasing/requestlease/LeaseStructure";
import {
	formatAmountToIncludeCurrency,
	getAmountWithDurationAndInterest,
	getSingularOrPlural,
} from "../../common/utils";

const Negotiate = ({
	onClose,
	lease_structure,
	lease_tenure,
	interest,
	interestAmount,
	assetAmount,
}) => {
	const dispatch = useDispatch();

	const [togglePeriod, setTogglePeriod] = useState(false);
	const [toggleLength, setToggleLength] = useState(false);

	const [negotiateForm, setNogotiateForm] = useState({
		proposed_interest: "0",
		proposed_structure: "",
		length: "",
		period: "",
	});
	const { proposed_interest, proposed_structure, length, period } =
		negotiateForm;

	const [toggleLeaseProposal, setToggleLeaseProposal] = useState(false);

	const isBtnDisabled = () => {
		if (
			proposed_interest === "" ||
			proposed_structure === "" ||
			length === "" ||
			period === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		dispatch(fetchCurrentInterest("Base Interest rate leasing"));
	}, []);

	const data = {
		interest,
		leaseStructure: lease_structure,
		leaseTenure: lease_tenure,
		proposedTenure: `${length} ${getSingularOrPlural(length, period)}`,
		proposedInterest: proposed_interest,
		proposedStructure: proposed_structure,
	};

	const { negotiationLoading, currentGeneratedinterest } = useSelector(
		(state) => state.lease
	);

	useEffect(() => {
		if (period === "" || length === "") {
			return;
		}
		if (period && length) {
			const interestAmount = getAmountWithDurationAndInterest(
				currentGeneratedinterest?.value.replace("%", ""),
				`${length} ${period}`,
				assetAmount
			);

			setNogotiateForm((prev) => ({
				...prev,
				proposed_interest: interestAmount,
			}));
		}
	}, [length, period]);

	return (
		<div className="w-[100vw] flex justify-center items-center">
			<div className="text-[#393F42] space-y-4 md:space-y-5 bg-white rounded-md py-4 px-5 md:py-5 md:px-6 w-11/12 max-w-[400px] sm:max-w-[470px]">
				<div className="flex items-center justify-between zabalMedFont font-semibold">
					<p className="text-sm md:text-[15px]">Negotiate</p>
					<button onClick={onClose}>
						<MdOutlineClose size={20} color="#00525D" />
					</button>
				</div>
				<div className="grid gap-3">
					<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-2 sm:items-center">
						<p className="text-xs sm:text-[13px] zabalMedFont sm:w-1/3">
							Lease Tenure:
						</p>
						<div className="relative h-[43px] sm:w-2/3">
							<input
								type="text"
								className="bg-transparent h-full w-full px-2.5 rounded border border-[#B8B8B8] zabalRegFont text-[13px]"
								disabled={true}
								value={lease_tenure}
							/>
							<span className="absolute right-3 top-3">
								<MdLock color="#8E9DA4" size={18} />
							</span>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-2 sm:items-center">
						<p className="text-xs sm:text-[13px] zabalMedFont sm:w-1/3">
							Make a Proposal:
						</p>
						<div className="relative h-[43px] zabalRegFont w-full sm:w-2/3 grid grid-cols-2 gap-3">
							<div className="w-full relative">
								<PlainSelect
									context="period"
									isOpened={togglePeriod}
									currentText={period}
									handleToggle={() => {
										setTogglePeriod(!togglePeriod);
										setToggleLength(false);
									}}
								/>
								{togglePeriod && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={expectedDuration.map((a) => a.duration)}
											onHandleSelect={(a) => {
												setNogotiateForm((prev) => ({
													...prev,
													period: String(a),
													length: "",
												}));
												setTimeout(() => {
													setTogglePeriod(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
							<div className="w-full relative">
								<PlainSelect
									context="length"
									isOpened={toggleLength}
									currentText={length}
									handleToggle={() => {
										if (!period) return;
										setToggleLength(!toggleLength);
										setTogglePeriod(false);
									}}
								/>
								{toggleLength && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={expectedDuration
												?.find((a) => a.duration === period)
												?.length.map((a) => String(a))}
											onHandleSelect={(d) => {
												setNogotiateForm((prev) => ({
													...prev,
													length: String(d),
												}));
												setTimeout(() => setToggleLength(false), 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-2 sm:items-center">
						<p className="text-xs sm:text-[13px] zabalMedFont sm:w-1/3">
							Interest Rate:
						</p>
						<div className="text-[13px] relative h-[43px] sm:w-2/3 relative rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
							<div className="flex items-center justify-between w-full">
								<p>
									<span className="text-[#393F42] zabalMedFont">NGN </span>
									<span>
										{formatAmountToIncludeCurrency(undefined, interestAmount)}
									</span>
								</p>
								<p className="text-fortcore-green zabalMedFont">{`( ${interest}% P/A)`}</p>
							</div>
							<span className="h-full grid place-items-center">
								<MdLock size={18} color="#8E9DA4" />
							</span>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-2 sm:items-center">
						<p className="text-xs sm:text-[13px] zabalMedFont sm:w-1/3">
							New Interest Rate:
						</p>
						<div className="text-[13px] relative h-[43px] sm:w-2/3 relative rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
							<div className="flex items-center justify-between w-full">
								<p>
									<span className="text-[#393F42] zabalMedFont">NGN </span>
									<span>
										{formatAmountToIncludeCurrency(
											undefined,
											proposed_interest
										)}
									</span>
								</p>
								<p className="text-fortcore-green zabalMedFont">{`( ${currentGeneratedinterest?.value} P/A)`}</p>
							</div>
							<span className="h-full grid place-items-center">
								<MdLock size={18} color="#8E9DA4" />
							</span>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-2 sm:items-center">
						<p className="text-xs sm:text-[13px] zabalMedFont sm:w-1/3">
							Lease Structure:
						</p>
						<div className="relative h-[43px] sm:w-2/3">
							<input
								type="text"
								className="bg-transparent h-full w-full px-2.5 rounded border border-[#B8B8B8] zabalRegFont text-[13px]"
								disabled={true}
								value={lease_structure}
							/>
							<span className="absolute right-3 top-3">
								<MdLock color="#8E9DA4" size={18} />
							</span>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-2 sm:items-center">
						<p className="text-xs sm:text-[13px] zabalMedFont sm:w-1/3">
							Make a Proposal:
						</p>
						<div className="relative h-[43px] sm:w-2/3">
							<PlainSelect
								context="select preferred structure"
								isOpened={toggleLeaseProposal}
								currentText={proposed_structure}
								handleToggle={() =>
									setToggleLeaseProposal(!toggleLeaseProposal)
								}
							/>
							{toggleLeaseProposal && (
								<div className="absolute z-[2] w-full top-48px pb-8">
									<SelectDropDown
										arrOptions={leaseStructureArr}
										onHandleSelect={(d) => {
											setNogotiateForm((prev) => ({
												...prev,
												proposed_structure: d,
											}));
											setTimeout(() => {
												setToggleLeaseProposal(false);
											}, 100);
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="pt-4 pb-3 sm:hidden">
						<CustomGreenBtn
							text="proceed"
							isLoading={negotiationLoading}
							isDisabled={negotiationLoading || isBtnDisabled()}
							onRequestClick={() => dispatch(submitNegotiation(data))}
						/>
					</div>
					<div className="hidden sm:flex justify-end py-0.5">
						<CustomGreenBtn
							text="proceed"
							width="120px"
							height="42px"
							fontSize="13px"
							isLoading={negotiationLoading}
							isDisabled={negotiationLoading || isBtnDisabled()}
							onRequestClick={() => dispatch(submitNegotiation(data))}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Negotiate;

Negotiate.propTypes = {
	onClose: PropTypes.func,
	lease_structure: PropTypes.string,
	lease_tenure: PropTypes.string,
	interest: PropTypes.string,
	interestAmount: PropTypes.number,
	assetAmount: PropTypes.string,
};
