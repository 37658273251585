import { combineReducers } from "redux";
import { authReducer } from "./authRedux/authReducer";
import { leaseReducer } from "./lease/leaseReducer";
import { travelReducer } from "./travels/travelReducer";
import { paymentReducer } from "./payments/paymentReducer";
import { financeReducer } from "./financing/financeReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	lease: leaseReducer,
	travel: travelReducer,
	payment: paymentReducer,
	finance: financeReducer,
});

export default rootReducer;
