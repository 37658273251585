import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import NotifyUser from "../../../layout/NotifyUser";
import { closeWithdrawFundsModal } from "../../../redux/payments/paymentActions";
import { openAddBankAccountModal } from "../../../redux/authRedux/authActions";

const BankDetails = ({
	setActiveWithdrawalTab,
	onCloseModal,
	accountName,
	accountNumber,
	bankName,
}) => {
	const dispatch = useDispatch();

	const getInitials = () => {
		const splitName = accountName?.split(" ");
		const firstLetter = splitName[0].slice(0, 1);
		const secondLetter = splitName[1].slice(0, 1);
		return `${firstLetter}${secondLetter}`;
	};

	return (
		<NotifyUser
			header="Send to Bank Account"
			onCloseBtnClick={onCloseModal}
			onWhiteBtnClick={() => dispatch(closeWithdrawFundsModal())}
			onGreenBtnClick={() => setActiveWithdrawalTab(2)}
			// isGreenBtnDisabled={Number(amountToDeposit.replaceAll(",", "")) < 100}
			btnText="proceed"
		>
			<>
				<div className="flex items-center gap-4 border border-[#B8C8CF] bg-[#F6FAFB] rounded-md px-3 py-2">
					<p className="w-10 h-10 bg-fortcore-green rounded-full p-2 grid place-items-center text-white zabalBoldFont">
						{getInitials()}
					</p>
					<div className="text-[#393F42] zabalRegFont space-y-1">
						<p className="text-sm zabalMedFont">{accountName}</p>
						<p className="text-[13px]">{bankName}</p>
						<p className="text-[11px]">{accountNumber}</p>
					</div>
				</div>
				<div className="flex justify-end">
					<button
						className="text-xs text-fortcore-green zabalMedFont"
						onClick={() => dispatch(openAddBankAccountModal())}
					>
						Change Account
					</button>
				</div>
			</>
		</NotifyUser>
	);
};

export default BankDetails;

BankDetails.propTypes = {
	setActiveWithdrawalTab: PropTypes.func,
	onCloseModal: PropTypes.func,
	accountName: PropTypes.string,
	accountNumber: PropTypes.string,
	bankName: PropTypes.string,
};
