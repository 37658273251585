import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userContext from "../../../../../../context/UserContext";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { OtherUploadComponent } from "../UPLOAD/OtherUpload";
import { toast } from "react-toastify";
import PaymentModal from "../../../../paymentPin/PaymentPin";
import { FinalBox } from "./FinalBox";
import { ModalPop } from "./ModalPop";
import { Preloader } from "../../../../../../components/globalStyled";
import Parse from "../../../../../../api/ApiConfig";
import { useDispatch, useSelector } from "react-redux";
import {
	submitAppFinanceRequest,
	updateOngoingReq,
} from "../../../../../../redux/financing/financeServices";
import { onSaveUserFinanceRequest } from "../../../../../../redux/financing/financeActions";
import Container from "../../../../../../layout/Container";
import { MobileNavDetails } from "../../../../../../layout/MobileNavbar";
import Navbar from "../../../../../../layout/Navbar";
import {
	BackTracker,
	UrlTracker,
} from "../../../../../../layout/CustomComponents";
import { Helmet } from "react-helmet";

function FinalRequestStep() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { requestFinance, setRequestFinance, afRate } = useContext(userContext);
	const [title, setTitle] = useState("");
	const [modal, setModal] = useState(false);
	const [payModal, setPayModal] = useState(false);
	const [btnText, setBtnText] = useState("");
	const [loadingSave, setLoadingSave] = useState(false);

	const { currentUser } = useSelector((state) => state.auth);
	const { userFinanceRequest } = useSelector((state) => state.finance);

	const handleFileUpload = (file) => {
		// eslint-disable-next-line no-undef
		console.log("Uploaded IdCard:", file);

		setRequestFinance((prevState) => ({
			...prevState,
			idCard: file,
		}));
	};
	const handleUtilityUpload = (file) => {
		// eslint-disable-next-line no-undef
		console.log("Uploaded Utility:", file);

		setRequestFinance((prevState) => ({
			...prevState,
			utilityBill: file,
		}));
	};

	const numberWithCommas = (value) => {
		return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const idCard = requestFinance.idCard?.name || currentUser?.idCard;
	const utilityBill =
		requestFinance.utilityBill?.name || currentUser?.utilityBill;

	const saveToParse = async () => {
		const currentUser = Parse.User.current();
		if (currentUser) {
			const data = {
				guarantor: userFinanceRequest?.guarantor,
				guarantorsInfo: userFinanceRequest?.guarantorsInfo,
				autoDebit: requestFinance?.autoDebit,
				insurance: requestFinance?.insurance,
				user: currentUser.toPointer(),
				paymentType: userFinanceRequest?.paymentType || "wallet",
				tenure: requestFinance?.tenure,
				type: userFinanceRequest?.type,
				category: "Apprentice Request",
				requestType: "new",
				amount: userFinanceRequest?.amount,
				amountToRaise: userFinanceRequest?.amount,
				interest: userFinanceRequest?.interest,
				interestRate: afRate.interest?.value,
				purpose: userFinanceRequest?.purpose,
				businessDescription: userFinanceRequest?.businessDescription,
				businessType: userFinanceRequest?.businessType,
				commitmentFee: userFinanceRequest?.commitmentFee,
				commitmentFeeStatus: userFinanceRequest?.commitmentFeeStatus,
				expectedProfit: userFinanceRequest?.expectedProfit,
				programSavings: userFinanceRequest?.programSavings,
				totalAmountRepayable: userFinanceRequest?.amountRepayable,
				attachmentDoc: userFinanceRequest?.attachmentFile,
				proofOfPurchaseFile: userFinanceRequest?.proofOfPurchaseFile,
				productPhotos: userFinanceRequest?.productPhoto,
				marketDetails:
					requestFinance?.marketDetails ||
					userFinanceRequest?.marketDetails ||
					"",
				documents: true,
				requestStatus: "submitted",
				funded: false,
			};

			const message = "Finance Request Submitted";
			const moreDetails = `You finance request with the transaction ID of ${userFinanceRequest?.objectId} has been submitted successfully. You can view more information on the status of transaction.`;

			try {
				setLoadingSave(true);

				const response = await submitAppFinanceRequest(
					userFinanceRequest?.objectId,
					data,
					message,
					moreDetails
				);

				if (response) {
					toast.success("Finance request submitted successfully");

					setTimeout(() => {
						window.location.href = "/financing/submitted-transactions";
					}, 2000);
				}
			} catch (error) {
				toast.error(error);
			} finally {
				setLoadingSave(false);
			}
		} else {
			setLoadingSave(false);
			Parse.User.logOut();
			navigate("/");
		}
	};

	const actualFeeToDeduct = Number(
		requestFinance?.amount?.replaceAll(",", "") / 10
	);

	const guarantorStatus =
		userFinanceRequest?.guarantor || requestFinance?.guarantor;

	const isBtnDisabled = () => {
		if (
			!requestFinance.autoDebit ||
			!requestFinance.insurance ||
			!guarantorStatus ||
			userFinanceRequest?.commitmentFeeStatus !== "paid" ||
			!idCard ||
			!utilityBill
		) {
			return true;
		} else {
			return false;
		}
	};

	const [uLoading, setULoading] = useState(false);

	const onDataUpdate = async () => {
		try {
			setULoading(true);
			const dataToUpdate = {
				commitmentFeeStatus: "paid",
				paymentType: requestFinance?.paymentType,
			};
			const response = await updateOngoingReq(
				requestFinance?.objectId,
				dataToUpdate,
				"ApprenticeshipFinance"
			);

			if (response) {
				toast.success("Commitment fee paid and request updates successfully");
				dispatch(onSaveUserFinanceRequest(response));
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setULoading(false);
		}
	};

	useEffect(() => {
		if (
			requestFinance?.commitmentValid &&
			userFinanceRequest?.commitmentFeeStatus !== "paid"
		) {
			onDataUpdate();
		} else {
			return;
		}
		console.log(2);
	}, [requestFinance?.commitmentFee]);

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={uLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<MobileNavDetails text="Ongoing Transactions" />
			<Container>
				<Helmet>
					<title>Fortcore - Ongoing transaction</title>
				</Helmet>
				<Navbar text="Ongoing Transactions" />
				<div className="sm:pt-9 md:pt-10"></div>
				<div className="flex gap-1.5">
					<UrlTracker
						text="financing"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="ongoing transactions"
						onNavigate={() => navigate("/financing/ongoing-transactions")}
					/>
					<UrlTracker text="request finance" isCurrentUrl />
				</div>

				{/* content  */}
				<BackTracker
					text="request finace"
					onNavigate={() => navigate("/financing/ongoing-transactions")}
				/>
				<div className="sm:mt-6"></div>
				<Box width="100%" sx={{ padding: "20px" }}>
					<Stack spacing={3}>
						<Stack spacing={1}>
							<Typography variant="h2" color="primary">
								Final Step!
							</Typography>
							<Typography variant="caption" color="textsecondary">
								Complete the action required below to submit this transaction
							</Typography>

							<Stack direction="row">
								<Typography variant="body1" color="initial">
									Transaction ID:&nbsp;
								</Typography>
								<Typography variant="h5" color="primary">
									{requestFinance?.objectId}
								</Typography>
							</Stack>
						</Stack>

						<Stack spacing={2}>
							{/* Upload ID Card  */}
							{/* <FinalBox title="Upload ID Card" onClick={() => {}} /> */}
							<OtherUploadComponent
								title="Upload ID Card"
								onFileUpload={handleFileUpload}
								fileName={requestFinance.idCard.name || currentUser?.idCard}
							/>

							{/* Utility Bill  */}
							{/* <FinalBox title="Utility Bill" onClick={() => {}} /> */}
							<OtherUploadComponent
								title="Utility Bill"
								onFileUpload={handleUtilityUpload}
								fileName={
									requestFinance.utilityBill.name || currentUser?.utilityBill
								}
							/>

							{/* Auto Debit  */}
							<FinalBox
								title="Auto Debit"
								bool={requestFinance.autoDebit}
								modal={() => {
									if (!requestFinance.autoDebit) {
										setTitle("Authorize Auto Debit on your bank account?");
										setBtnText("Authorize");
										setModal(true);
									}
								}}
							/>

							{/* Insurance  */}
							<FinalBox
								title="Insurance"
								btnText="Proceed"
								bool={requestFinance.insurance}
								modal={() => {
									if (!requestFinance.insurance) {
										setTitle("Proceed to apply for insurance");
										setBtnText("Proceed");
										setModal(true);
									}
								}}
							/>

							{/* Guarantor  */}
							<FinalBox
								title="Guarantor"
								bool={guarantorStatus}
								modal={() => {
									if (!guarantorStatus) {
										navigate("/financing/new-transaction/request/guarantor", {
											state: { from: userFinanceRequest?.requestType },
										});
									}
								}}
							/>

							{/* Commitment Fee  */}
							<FinalBox
								title="Commitment Fee"
								btnText="Proceed"
								bool={userFinanceRequest?.commitmentFeeStatus === "paid"}
								modal={() => {
									if (userFinanceRequest?.commitmentFeeStatus !== "paid") {
										setTitle(
											`A commitment fee of NGN ${numberWithCommas(
												String(actualFeeToDeduct)
											)} (10% of amount to raise) will be deducted from your account.`
										);
										setBtnText("Authorize");
										setModal(true);
									}
								}}
							/>

							<Box
								mt={3}
								sx={{
									display: "flex",
									justifyContent: { sm: "flex-end" },
								}}
							>
								{!loadingSave ? (
									<Button
										variant="contained"
										disabled={isBtnDisabled()}
										onClick={saveToParse}
										sx={{ width: { xs: "100%", sm: "" } }}
									>
										Submit
									</Button>
								) : (
									<Button
										variant="contained"
										type="button"
										sx={{ width: { xs: "100%", sm: "" } }}
										disabled={loadingSave}
									>
										<Preloader />
									</Button>
								)}
							</Box>
						</Stack>
					</Stack>
				</Box>
			</Container>

			{modal && (
				<ModalPop
					title={title}
					modal={modal}
					setModal={setModal}
					onClick={() => {
						if (title === "Authorize Auto Debit on your bank account?") {
							setRequestFinance((prevState) => ({
								...prevState,
								autoDebit: true,
							}));
							toast.success("Auto Debit Authorized");
						}
						if (title === "Proceed to apply for insurance") {
							setRequestFinance((prevState) => ({
								...prevState,
								insurance: true,
							}));
							toast.success("Insurance Enabled");
						}
						if (
							title ===
							`A commitment fee of NGN ${actualFeeToDeduct} (10% of amount to raise) will be deducted from your account.`
						) {
							setPayModal(true);
						}
						setModal(false);
					}}
					btnText={btnText}
				/>
			)}

			{payModal && (
				<PaymentModal
					payModal={payModal}
					setPayModal={setPayModal}
					narration="Commitment fee payment"
					amountToDeduct={String(actualFeeToDeduct)}
					setState={() =>
						setRequestFinance((prevState) => ({
							...prevState,
							commitmentValid: true,
							commitmentFee: actualFeeToDeduct,
						}))
					}
					wallet={() => {
						setRequestFinance((prevState) => ({
							...prevState,
							paymentType: "wallet",
						}));
					}}
					card={() => {
						setRequestFinance((prevState) => ({
							...prevState,
							paymentType: "card",
						}));
					}}
				/>
			)}
		</>
	);
}

export default FinalRequestStep;
