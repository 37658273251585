import React from "react";
import { AnyModal } from "../../finance/newTransaction/investor/create/InvestorCreateAF";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import theme from "../../../components/mui/theme";
import { RiArrowRightSLine } from "react-icons/ri";
import { BsBank2 } from "react-icons/bs";

function PaymentMethod({ open, close, onClick }) {
  return (
    <>
      <AnyModal open={open}>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h3"
              sx={{ fontFamily: "ZabalBold", fontSize: "15px" }}
              color="primary"
            >
              Payment Method
            </Typography>
            <IconButton onClick={close}>
              <IoMdClose style={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>
          <Typography variant="caption" color="textSecondary">
            Select payment method to proceed
          </Typography>

          <Box mt={2}>
            <Box
              sx={{
                border: "1px solid var(--secondary-color-400, #557C8A)",
                borderRadius: "8px",
                height: "55px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <BsBank2 color={theme.palette.primary.main} size={25} />
                <Typography variant="h5" color="primary">
                  Bank Transfer
                </Typography>
              </Stack>

              <RiArrowRightSLine color={theme.palette.primary.main} size={25} />
            </Box>

            <Box
              mt={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              width="100%"
            >
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  onClick={close}
                  type="button"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={onClick}
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Proceed
                </Button>
              </Stack>
            </Box>

            {/* Mobile Buttons  */}
            <Box mt={2} width="100%" sx={{ display: { sm: "none" } }}>
              <Stack spacing={2}>
                <Button variant="contained" type="button" onClick={onClick}>
                  Proceed
                </Button>
                <Button variant="outlined" onClick={close} type="button">
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </AnyModal>
    </>
  );
}

PaymentMethod.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func,
};
export default PaymentMethod;
