import { LOGOUT_USER } from "../authRedux/authTypes";
import {
	GET_DELIVERY_FEE_ERROR,
	GET_DELIVERY_FEE_LOADING,
	GET_DELIVERY_FEE_SUCCESS,
} from "../lease/leaseTypes";
import {
	FETCH_CUSTOMER_WALLET_ERROR,
	FETCH_CUSTOMER_WALLET_LOADING,
	FETCH_CUSTOMER_WALLET_SUCCESS,
	OPEN_ADD_FUNDS_MODAL,
	CLOSE_ADD_FUNDS_MODAL,
	GET_PAYMENT_ACCOUNT_LOADING,
	GET_PAYMENT_ACCOUNT_ERROR,
	GET_PAYMENT_ACCOUNT_SUCCESS,
	OPEN_WITHDRAW_FUNDS_MODAL,
	CLOSE_WITHDRAW_FUNDS_MODAL,
	OPEN_MOVE_FUNDS_MODAL,
	CLOSE_MOVE_FUNDS_MODAL,
	DEDUCT_PAYMENT_FROM_WALLET_LOADING,
	DEDUCT_PAYMENT_FROM_WALLET_ERROR,
	DEDUCT_PAYMENT_FROM_WALLET_SUCCESS,
	CONFIRM_PROVIDUS_DEPOSIT_LOADING,
	CONFIRM_PROVIDUS_DEPOSIT_SUCCESS,
	CONFIRM_PROVIDUS_DEPOSIT_ERROR,
	CLEAR_PAYMENT_DEDUCTION_SUCCESS,
	MOVE_FUNDS_LOADING,
	MOVE_FUNDS_SUCCESS,
	MOVE_FUNDS_ERROR,
	CLEAR_MOVE_FUNDS_SUCCESS,
	CONFIRM_PAGA_DEPOSIT_LOADING,
	CONFIRM_PAGA_DEPOSIT_SUCCESS,
	CONFIRM_PAGA_DEPOSIT_ERROR,
	WITHDRAW_FROM_WALLET_LOADING,
	WITHDRAW_FROM_WALLET_SUCCESS,
	WITHDRAW_FROM_WALLET_ERROR,
	TOGGLE_UPCOMING_REPAYMENT,
} from "./paymentTypes";

const initialState = {
	customerWalletLoading: false,
	customerWallet: null,
	toggleAddFundsModal: false,
	paymentAccountLoading: false,
	paymentAccountSuccess: null,
	toggleWithdrawFundsModal: false,
	toggleMoveFundsModal: false,
	deductWalletLoading: false,
	deductWalletSuccess: null,
	providusDepositConfirmLoading: false,
	providusDepositConfirmSuccess: null,
	providusDepositConfirmError: null,
	moveFundsLoading: false,
	moveFundsSuccess: null,
	pagaDepositConfirmLoading: false,
	pagaDepositConfirmSuccess: null,
	pagaDepositConfirmError: null,
	withdrawFundsLoading: false,
	withdrawFundsSuccess: null,
	deliveryAccountLoading: false,
	deliveryAccountSuccess: null,
	toggleUpcomingPayments: false,
	currentItemToRepay: null,
};

export const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_WALLET_LOADING:
			return { ...state, customerWalletLoading: true };
		case FETCH_CUSTOMER_WALLET_SUCCESS:
			return {
				...state,
				customerWalletLoading: false,
				customerWallet: state.customerWallet
					? state.customerWallet
					: action.payload.wallet,
				walletDetails: state.walletDetails
					? state.walletDetails
					: action.payload.otherWalletDetails,
			};
		case FETCH_CUSTOMER_WALLET_ERROR:
			return { ...state, customerWalletLoading: false };
		case OPEN_ADD_FUNDS_MODAL:
			return { ...state, toggleAddFundsModal: true };
		case CLOSE_ADD_FUNDS_MODAL:
			return {
				...state,
				toggleAddFundsModal: false,
				providusDepositConfirmSuccess: null,
				providusDepositConfirmError: null,
			};
		case GET_PAYMENT_ACCOUNT_LOADING:
			return { ...state, paymentAccountLoading: true };
		case GET_PAYMENT_ACCOUNT_SUCCESS:
			return {
				...state,
				paymentAccountSuccess: action.payload,
				paymentAccountLoading: false,
			};
		case GET_PAYMENT_ACCOUNT_ERROR:
			return { ...state, paymentAccountLoading: false };
		case OPEN_WITHDRAW_FUNDS_MODAL:
			return { ...state, toggleWithdrawFundsModal: true };
		case CLOSE_WITHDRAW_FUNDS_MODAL:
			return { ...state, toggleWithdrawFundsModal: false };
		case OPEN_MOVE_FUNDS_MODAL:
			return { ...state, toggleMoveFundsModal: true };
		case CLOSE_MOVE_FUNDS_MODAL:
			return { ...state, toggleMoveFundsModal: false, moveFundsSuccess: null };
		case DEDUCT_PAYMENT_FROM_WALLET_LOADING:
			return { ...state, deductWalletLoading: true, deductWalletSuccess: null };
		case DEDUCT_PAYMENT_FROM_WALLET_SUCCESS:
			return {
				...state,
				deductWalletLoading: false,
				deductWalletSuccess: action.payload.message,
				customerWallet: action.payload.wallet,
			};
		case DEDUCT_PAYMENT_FROM_WALLET_ERROR:
			return {
				...state,
				deductWalletLoading: false,
				deductWalletSuccess: null,
			};
		case CONFIRM_PROVIDUS_DEPOSIT_LOADING:
			return {
				...state,
				providusDepositConfirmLoading: true,
				providusDepositConfirmError: null,
				providusDepositConfirmSuccess: null,
			};
		case CONFIRM_PROVIDUS_DEPOSIT_SUCCESS:
			return {
				...state,
				providusDepositConfirmLoading: false,
				providusDepositConfirmSuccess: action.payload,
			};
		case CONFIRM_PROVIDUS_DEPOSIT_ERROR:
			return {
				...state,
				providusDepositConfirmLoading: false,
				providusDepositConfirmError: action.error,
			};
		case LOGOUT_USER:
			return {
				...state,
				toggleAddFundsModal: false,
				toggleMoveFundsModal: false,
				toggleWithdrawFundsModal: false,
			};
		case CLEAR_PAYMENT_DEDUCTION_SUCCESS:
			return { ...state, deductWalletSuccess: null };
		case MOVE_FUNDS_LOADING:
			return { ...state, moveFundsLoading: true };
		case MOVE_FUNDS_SUCCESS:
			return {
				...state,
				moveFundsLoading: false,
				moveFundsSuccess: action.payload.message,
				customerWallet: action.payload.wallet,
			};
		case MOVE_FUNDS_ERROR:
			return { ...state, moveFundsLoading: false };
		case CLEAR_MOVE_FUNDS_SUCCESS:
			return { ...state, moveFundsSuccess: null };
		case CONFIRM_PAGA_DEPOSIT_LOADING:
			return {
				...state,
				pagaDepositConfirmLoading: true,
				pagaDepositConfirmError: null,
				pagaDepositConfirmSuccess: null,
			};
		case CONFIRM_PAGA_DEPOSIT_SUCCESS:
			return {
				...state,
				pagaDepositConfirmLoading: false,
				pagaDepositConfirmSuccess: action.payload,
			};
		case CONFIRM_PAGA_DEPOSIT_ERROR:
			return {
				...state,
				pagaDepositConfirmLoading: false,
				pagaDepositConfirmError: action.error,
			};
		case WITHDRAW_FROM_WALLET_LOADING:
			return { ...state, withdrawFundsLoading: true };
		case WITHDRAW_FROM_WALLET_SUCCESS:
			return {
				...state,
				withdrawFundsLoading: false,
				withdrawFundsSuccess: action.payload,
			};
		case WITHDRAW_FROM_WALLET_ERROR:
			return { ...state, withdrawFundsLoading: false };
		case GET_DELIVERY_FEE_LOADING:
			return { ...state, deliveryAccountLoading: true };
		case GET_DELIVERY_FEE_SUCCESS:
			return {
				...state,
				deliveryAccountLoading: false,
				withdrawFundsSuccess: action.payload,
			};
		case GET_DELIVERY_FEE_ERROR:
			return { ...state, deliveryAccountLoading: false };
		case TOGGLE_UPCOMING_REPAYMENT:
			return {
				...state,
				toggleUpcomingPayments: action.payload.state,
				currentItemToRepay: action.payload.id,
			};
		default:
			return state;
	}
};
