import React from "react";
import { SavingChartIcon, SavingKeyIcon } from "../../layout/assets";
import { Helmet } from "react-helmet";
import Container from "../../layout/Container";
import Balance from "../../components/Balance";
import { BackTracker, UrlTracker } from "../../layout/CustomComponents";
import Navbar from "../../layout/Navbar";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openConfirmAutoDebitModal } from "../../redux/lease/leaseActions";
import { walletType } from "../../common/utils";

const SavingOverview = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { customerWallet } = useSelector((state) => state.payment);

	const regularSavingsWallet = customerWallet?.find(
		(wallet) => wallet.product === walletType.regularSavings
	);
	const assetBasedWallet = customerWallet?.find(
		(wallet) => wallet.product === walletType.assetBasedSavings
	);

	const combinedWallet = {
		id: `${regularSavingsWallet?.id || "regular-savings"} & ${
			assetBasedWallet?.id || "asset-based-savings"
		}`,
		accountNumber: `${
			regularSavingsWallet?.accountNumber || "regular-savings"
		} & ${assetBasedWallet?.accountNumber || "asset-based-savings"}`,
		currency: regularSavingsWallet?.currency || "NGN",
		availableBalance:
			regularSavingsWallet?.availableBalance ||
			0 + assetBasedWallet?.availableBalance ||
			0,
		ledgerBalance:
			regularSavingsWallet?.ledgerBalance ||
			0 + assetBasedWallet?.ledgerBalance ||
			0,
		status: regularSavingsWallet?.status || 0 + assetBasedWallet?.status || 0,
		type:
			`${regularSavingsWallet?.type || "regular-savings"} & ${
				assetBasedWallet?.type || "asset-based-savings"
			}` || "savings",
		product:
			`${regularSavingsWallet?.product || "regular-savings"} & ${
				assetBasedWallet?.product || "asset-based-savings"
			}` || "combined-savings-wallet",
	};

	return (
		<>
			<MobileNavDetails text="savings" />
			<Helmet>
				<title>Fortcore - Saving Overview</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="leasing" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<BackTracker text="savings" onNavigate={() => navigate(-1)} />
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker text="saving overview" isCurrentUrl />
					</div>
					<div className="sm:mt-6"></div>
					<Balance wallet={combinedWallet} cardTitle="Total Savings Balance" />
					<div className="mt-[45px] sm:mt-[55px]"></div>
					<p className="text-sm text-[#717E83] zabalMedFont pb-4">
						Create a new savings plan
					</p>
					<div className="grid sm:grid-cols-2 gap-4 sm:gap-5 lg:gap-6 mb-10">
						<Card
							title="regular savings"
							subTitle="Save money regularly in a locked plan or atleast 3 months"
							icon={<SavingChartIcon width={48} height={48} />}
							onNavigate={() => dispatch(openConfirmAutoDebitModal())}
							onRequestClick={() => navigate("/leasing/regular-savings/view")}
						/>
						<Card
							title="asset based savings"
							subTitle="Create saving plans with your assets"
							icon={<SavingKeyIcon width={48} height={48} />}
							onNavigate={() => navigate("/leasing/asset-based-savings")}
							onRequestClick={() =>
								navigate("/leasing/asset-based-savings/view")
							}
						/>
					</div>
				</>
			</Container>
		</>
	);
};
export default SavingOverview;

const Card = ({ icon, title, subTitle, onNavigate, onRequestClick }) => {
	return (
		<div className="p-5 sm:p-6 md:p-8 rounded-md bg-[#FBFBFC] border border-[#E3E9EC]">
			<div className="flex flex-col justify-between gap-6 md:gap-7 md:h-[157px]">
				<div className="space-y-3 zabalMedFont">
					<div className="flex gap-3 items-center">
						<div>{icon}</div>
						<h4 className="capitalize zabalMedFont text-fortcore-green text-sm">
							{title}
						</h4>
					</div>
					<p className="text-[#717E83] text-[12.25px]">{subTitle}</p>
				</div>
				<div className="justify-self-end flex justify-end gap-3 zabalMedFont">
					<button
						className="px-5 h-[33px] rounded text-xs bg-[#EEF2F3] text-fortcore-green"
						onClick={onRequestClick}
					>
						View
					</button>
					<button
						className="px-5 h-[33px] rounded text-xs bg-fortcore-green text-white"
						onClick={onNavigate}
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};

Card.propTypes = {
	onNavigate: PropTypes.func,
	onRequestClick: PropTypes.func,
	icon: PropTypes.object,
	title: PropTypes.string,
	subTitle: PropTypes.string,
};
