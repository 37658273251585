import { createNotification } from "../../components/notification/CreateNotification";
import Parse from "../../api/ApiConfig";
import axios from "axios";
import baseURL from "../../api/UrlConfig";

export const deleteOngoingAf = async (financeId) => {
	try {
		const MyObject = Parse.Object.extend("ApprenticeshipFinance");
		const objectToDelete = new MyObject();
		objectToDelete.id = financeId;

		const data = await objectToDelete.destroy();

		const message = "Finance request deleted";
		const metaData = {
			category: "ApprenticeshipFinance",
			linkToId: financeId,
			moreDetails: `An ongoing finance request with the id of ${financeId} has been deleted.`,
		};

		if (data) {
			createNotification(message, metaData);
		}

		return data;
	} catch (error) {
		console.log(error);
		console.error("Error deleting finance request. Try again");
		throw error;
	}
};

export const createFinaceReqAsOngoing = async (dataToSave) => {
	try {
		const ApprenticeshipFinance = Parse.Object.extend("ApprenticeshipFinance");
		const ongoingAf = new ApprenticeshipFinance();
		const response = await ongoingAf.save(dataToSave);

		const data = response?.toJSON();

		const message = "An ongoing finance request created";
		const metaData = {
			category: "ApprenticeshipFinance",
			linkToId: response?.id,
			moreDetails:
				"You have successfully created a finance request. This request is not submitted yet. Complete necessary steps to submit",
		};

		if (data) {
			createNotification(message, metaData);
		}

		return data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const updateOngoingReq = async (
	id,
	dataToUpdate,
	classToUpdate,
	msg = "",
	moreInfo = "",
	user = Parse.User.current()
) => {
	try {
		const query = new Parse.Query(classToUpdate);
		const object = await query.get(id);

		const response = await object.save(dataToUpdate);

		const message = msg || "Request status update";
		const metaData = {
			category: classToUpdate,
			linkToId: response?.id,
			moreDetails:
				moreInfo ||
				`Your request with the transaction Id of ${id} has been updated.`,
		};

		if (response) {
			createNotification(message, metaData, user);
		}

		return response?.toJSON();
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const submitAppFinanceRequest = async (
	id,
	dataToSave,
	message,
	moreDetails
) => {
	try {
		const query = new Parse.Query("ApprenticeshipFinance");
		const object = await query.get(id);

		const response = await object.save(dataToSave);

		const data = response?.toJSON();

		const metaData = {
			category: "ApprenticeshipFinance",
			linkToId: response?.id,
			moreDetails: moreDetails,
		};

		if (data) {
			createNotification(message, metaData);
		}

		return data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const deleteInactiveFinance = async (financeId) => {
	try {
		const MyObject = Parse.Object.extend("AFBusinessCreate");
		const objectToDelete = new MyObject();
		objectToDelete.id = financeId;

		const data = await objectToDelete.destroy();

		const message = "Inactive finance request deleted";
		const metaData = {
			category: "AFBusinessCreate",
			linkToId: financeId,
			moreDetails: `An inactive finance request with the id of ${financeId} has been deleted.`,
		};

		if (data) {
			createNotification(message, metaData);
		}

		return data;
	} catch (error) {
		console.log(error);
		console.error("Error deleting finance request. Try again");
		throw error;
	}
};

export const fetchInvestorInvestments = async () => {
	try {
		const AFBusinessCreate = Parse.Object.extend("AFBusinessCreate");
		const ApprenticeshipFinance = Parse.Object.extend("ApprenticeshipFinance");

		const query = new Parse.Query(AFBusinessCreate);
		const queryTwo = new Parse.Query(ApprenticeshipFinance);

		query.equalTo("user", Parse.User.current()?.id);
		queryTwo.containedIn("investors", [Parse.User.current()?.id]);

		const responseOne = await query.find();
		const responseTwo = await queryTwo.find();

		const jsonResOne = responseOne.map((result) => result.toJSON());
		const jsonResTwo = responseTwo.map((result) => result.toJSON());

		const inActiveInvestments = jsonResOne?.filter(
			(status) => status.status === "inactive"
		);

		const activeOne = jsonResOne.filter((status) => status.status === "active");

		const activeInvestments = [...activeOne, ...jsonResTwo].sort(
			(dataOne, dataTwo) =>
				new Date(dataTwo?.createdAt).getTime() -
				new Date(dataOne?.createdAt).getTime()
		);

		return { inActiveInvestments, activeInvestments };
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const fetchRequestsForInvestors = async () => {
	try {
		const query = new Parse.Query("ApprenticeshipFinance");

		const filterQuery = query
			.equalTo("requestStatus", "submitted")
			.equalTo("category", "Apprentice Request")
			.equalTo("type", "sponsored")
			.equalTo("requestType", "new")
			.equalTo("funded", false)
			.addDescending("createdAt");

		const results = await filterQuery.find();
		const data = results.map((obj) => obj.toJSON());

		return data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const fetchUserMarketPlaceData = async () => {
	try {
		const query = new Parse.Query("AFBusinessCreate");
		const filterQuery = query
			.equalTo("status", "active")
			.equalTo("funded", true)
			.doesNotExist("commitmentFee");

		const results = await filterQuery.find();
		const data = results.map((obj) => obj.toJSON());

		return data;
	} catch (error) {
		console.log(error);

		throw error;
	}
};

export const fetchSubmittedRequests = async () => {
	try {
		const response = await axios.post(
			`${baseURL}/functions/getSubmittedAF`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);

		const data = await response.data?.result?.sort(
			(dataOne, dataTwo) =>
				new Date(dataTwo?.updatedAt).getTime() -
				new Date(dataOne?.updatedAt).getTime()
		);

		return data;
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			throw "You are not connected to the internet.";
		} else if (error?.response.data?.error === "Invalid session token") {
			throw "Invalid session token";
		} else {
			throw error?.response?.data?.error;
		}
	}
};

export const onInvestorUpdatesApprenticeStatus = async (
	createdAfId,
	investorMsg,
	data,
	newData,
	msg,
	moreInfo,
	apprenticeInfo
) => {
	try {
		const queryOne = new Parse.Query("AFBusinessCreate");
		const objectOne = await queryOne.get(createdAfId);
		const updatedObjectOne = await objectOne.save(data);

		// Create a query to fetch objects based on your criteria
		const query = new Parse.Query("ApprenticeshipFinance");
		query.containedIn(
			"objectId",
			newData.map((obj) => obj.objectId)
		);

		const objects = await query.find();

		// Create a map to associate object IDs with their corresponding Parse objects
		const objectMap = new Map();
		objects.forEach((parseObject) => {
			objectMap.set(parseObject.id, parseObject);
		});

		// Update each object with new data
		const updatedObjects = newData.map(async (newDataItem) => {
			const objectId = newDataItem.objectId;
			const parseObject = objectMap.get(objectId);
			if (parseObject) {
				// Update the parseObject with the new data
				parseObject.set("approvalStatus", newDataItem.approvalStatus);
				// Save the updated parseObject
				return await parseObject.save();
			} else {
				// Handle the case where the object with the provided objectId is not found
				console.log(`Object with objectId ${objectId} not found.`);
				return null;
			}
		});

		// // Update each object with new data
		// const updatedObjects = objects.map((parseObject, index) => {
		// 	parseObject.set("approvalStatus", newData[index].approvalStatus);
		// 	return parseObject.save();
		// });

		// Wait for all updates to complete
		const dataTwo = await Promise.all(updatedObjects);

		const message = "Request status updated";
		const metaData = {
			category: "AFBusinessCreate",
			linkToId: updatedObjectOne?.id,
			moreDetails: investorMsg,
		};

		if (updatedObjectOne) {
			createNotification(message, metaData);
		}

		if (dataTwo) {
			createNotification(msg, moreInfo, apprenticeInfo);
		}

		return {
			createdReq: updatedObjectOne?.toJSON(),
			apprenticeReqs: dataTwo,
		};
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			throw "Oops! You are not conected to the internet";
		} else {
			throw "Failed to update request status";
		}
	}
};

export const onApprenticePaysCommitmentFee = async (
	createdAfId,
	requestId,
	dataOne,
	dataTwo,
	infoOne,
	infoTwo,
	investorInfo
) => {
	try {
		const queryOne = new Parse.Query("AFBusinessCreate");
		const queryTwo = new Parse.Query("ApprenticeshipFinance");

		const objectOne = await queryOne.get(createdAfId);
		const objectTwo = await queryTwo.get(requestId);

		const updatedObjectOne = await objectOne.save(dataOne);
		const updatedObjectTwo = await objectTwo.save(dataTwo);

		const message = "Commitment Fee Payment";
		const metaDataTwo = {
			category: "ApprenticeshipFinance",
			linkToId: updatedObjectTwo?.id,
			moreDetails: infoTwo,
		};

		const metaDataOne = {
			category: "AFBusinessCreate",
			linkToId: updatedObjectOne?.id,
			moreDetails: infoOne,
		};

		if (updatedObjectOne) {
			createNotification(message, metaDataOne, investorInfo);
		}

		if (updatedObjectTwo) {
			createNotification(message, metaDataTwo);
		}

		return {
			createdReq: updatedObjectOne?.toJSON(),
			apprenticeReq: updatedObjectTwo?.toJSON(),
		};
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			throw "Oops! You are not conected to the internet";
		} else {
			throw "Failed to update request status";
		}
	}
};

export const fetchSubmittedRequestsCopy = async () => {
	try {
		const response = await axios.post(
			`${baseURL}/functions/submittedAF`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				},
			}
		);

		const data = response?.data?.result;

		return data;
	} catch (error) {
		if (error?.message === "Network Error") {
			throw "You are not connected to the internet.";
		} else if (error?.response.data?.error === "Invalid session token") {
			throw "Invalid session token";
		} else {
			throw error?.response?.data?.error;
		}
	}
};
