import React, { useState } from "react";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	CustomRadioInput,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import { Navigate, useNavigate } from "react-router";
import {
	LeaseSummaryInput,
	LeaseSummaryItemDetails,
} from "../requestlease/LeaseSummary";
import { useDispatch, useSelector } from "react-redux";
import {
	clearSubmittedNegotiation,
	closeInvestorReqLeaseModal,
	openInvestorReqLeaseModal,
} from "../../../redux/lease/leaseActions";
import { DesktopSideModal } from "../../../components/ModalContainer";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import { FaArrowLeft } from "react-icons/fa";
import {
	formatAmountToIncludeCurrency,
	getInterestAmount,
} from "../../../common/utils";
import Negotiate from "../../../components/payments/Negotiate";
import PaymentOption from "../../../components/payments/PaymentOption";
import { MdLock } from "react-icons/md";

const FundLease = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [activeModal, setActiveModal] = useState(0);
	const {
		selectedReqLeaseItem,
		toggleInvestorReqLeaseModal,
		negotiationSuccess,
	} = useSelector((state) => state.lease);

	const [summaryData] = useState({
		product_category: selectedReqLeaseItem?.productObj.category,
		brand: selectedReqLeaseItem?.productObj.brand,
		model: selectedReqLeaseItem?.productObj.model,
		year: selectedReqLeaseItem?.productObj.year,
		condition: selectedReqLeaseItem?.condition,
		color: selectedReqLeaseItem?.color,
		lease_duration: selectedReqLeaseItem?.leaseDuration,
		lease_structure: selectedReqLeaseItem?.leaseStructure,
		lease_type: selectedReqLeaseItem?.leaseType,
		delivery_type: selectedReqLeaseItem?.deliveryType,
		interest: String(selectedReqLeaseItem?.interest),
	});

	const {
		product_category,
		brand,
		model,
		year,
		condition,
		color,
		lease_duration,
		lease_structure,
		lease_type,
		interest,
		delivery_type,
	} = summaryData;

	const amount = selectedReqLeaseItem?.price;

	const interestAmount = getInterestAmount(interest, amount);
	console.log(interest);
	// const interestAmount = getAmountWithDurationAndInterest(
	// 	interest,
	// 	lease_duration,
	// 	amount
	// );

	const onNegotiateBtnClick = () => {
		dispatch(openInvestorReqLeaseModal());
		setActiveModal(1);
	};

	const onNegotiateSuccess = () => {
		dispatch(clearSubmittedNegotiation());
		dispatch(closeInvestorReqLeaseModal());

		navigate("/leasing/new-transaction/investor-market-place");
	};

	const beginFundRequestProcess = () => {
		dispatch(openInvestorReqLeaseModal());
		setActiveModal(2);
	};

	if (!selectedReqLeaseItem) {
		return <Navigate to="/leasing/new-transaction/investor-market-place" />;
	}

	return (
		<>
			{toggleInvestorReqLeaseModal && (
				<DesktopSideModal modal={toggleInvestorReqLeaseModal}>
					<>
						{activeModal === 1 && negotiationSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Submitted, you will be notified if your proposal is considered"
									onRequestClick={onNegotiateSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							activeModal === 1 &&
							!negotiationSuccess && (
								<Negotiate
									onClose={() => dispatch(closeInvestorReqLeaseModal())}
									lease_structure={lease_structure}
									lease_tenure={lease_duration}
									interest={Number(interest).toFixed(1)}
									interestAmount={interestAmount}
									assetAmount={amount}
								/>
							)
						)}
						{activeModal === 2 && (
							<PaymentOption
								onClose={() => dispatch(closeInvestorReqLeaseModal())}
								onWalletSelect={() =>
									navigate("/leasing/fund-request/investment-options")
								}
								onCardSelect={() =>
									navigate("/leasing/fund-request/investment-options")
								}
							/>
						)}
					</>
				</DesktopSideModal>
			)}
			<Helmet>
				<title>Fortcore - Fund Request</title>
			</Helmet>
			<MobileNavDetails text="fund request" />
			<Container>
				<>
					<Navbar text="new transaction" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="new transaction"
							onNavigate={() => navigate("/leasing/new-transaction")}
						/>
						<UrlTracker text="fund request" isCurrentUrl />
					</div>
					<div className="mb-10 sm:my-6">
						<BackTracker text="Fund Request" onNavigate={() => navigate(-1)} />
						<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
							<div className="hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
								<button className="zabalMedFont" onClick={() => navigate(-1)}>
									<FaArrowLeft />
								</button>
								<p>Fund Request</p>
							</div>
							<LeaseSummaryItemDetails
								item={selectedReqLeaseItem?.productObj}
								// interest={Number(interest).toFixed(1)}
								interestAmount={interestAmount}
							/>
							<div className="grid gap-4 md:gap-5 lg:gap-6">
								<LeaseSummaryInput input_value={lease_type} text="lease type" />
								<LeaseSummaryInput
									input_value={product_category}
									text="product category"
								/>
								<LeaseSummaryInput input_value={brand} text="brand" />
								<LeaseSummaryInput input_value={model} text="model" />
								<LeaseSummaryInput input_value={year} text="year" />
								<LeaseSummaryInput input_value={condition} text="condition" />
								<LeaseSummaryInput input_value={color} text="color" />
								<LeaseSummaryInput
									input_value={lease_structure}
									text="lease structure"
								/>
								<LeaseSummaryInput
									input_value={formatAmountToIncludeCurrency(
										"NGN",
										interestAmount
									)}
									text="rental income"
								/>
								<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
										Interest Rate:
									</p>
									<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
										<div className="flex items-center justify-between w-full">
											<p>
												<span className="text-[#393F42] zabalMedFont">
													NGN{" "}
												</span>
												<span>
													{formatAmountToIncludeCurrency(
														undefined,
														interestAmount
													)}
												</span>
											</p>
											<p className="text-fortcore-green zabalMedFont">{`( ${interest}% P/A)`}</p>
										</div>
										<span className="h-full grid place-items-center">
											<MdLock size={18} color="#717E83" />
										</span>
									</div>
								</div>
								{/* <LeaseSummaryInput input_value={interest} text="interest" /> */}
								<LeaseSummaryInput
									input_value={lease_duration}
									text="lease tenure"
								/>
								<div className="flex flex-col sm:items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[32%] md:w-2/6 lg:w-3/12">
										Delivery Type :
									</p>
									<div className="w-full h-[45px] sm:w-[67%] md:w-4/6 lg:w-9/12 flex items-center">
										<div
											className={`flex items-center gap-1.5 gap-y-2 ${
												delivery_type === "pickup"
													? "text-fortcore-green"
													: "text-[#8E9DA4]"
											}`}
										>
											<CustomRadioInput
												name="delivery_type"
												value={delivery_type}
												isChecked={!!delivery_type}
												isReadOnly={true}
											/>
											<p className="text-xs capitalize">{delivery_type}</p>
										</div>
									</div>
								</div>
								<div className="bg-[#E3E9EC] h-[1px] w-full hidden sm:block"></div>

								{/* form buttons */}
								<div className="hidden sm:flex justify-end gap-4">
									<CustomWhiteBtn
										text="negotiate"
										width="115px"
										height="42px"
										fontSize="13.5px"
										onRequestClick={onNegotiateBtnClick}
									/>
									<CustomGreenBtn
										text="fund request"
										width="140px"
										height="42px"
										fontSize="13px"
										onRequestClick={beginFundRequestProcess}
									/>
								</div>
								<div className="flex flex-col gap-4 pt-4 sm:hidden">
									<CustomWhiteBtn
										text="negotiate"
										onRequestClick={onNegotiateBtnClick}
									/>
									<CustomGreenBtn
										text="fund request"
										onRequestClick={beginFundRequestProcess}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			</Container>
		</>
	);
};
export default FundLease;
