import axios from "axios";
import { toast } from "react-toastify";
import {
	confirmPagaDepositError,
	confirmPagaDepositLoading,
	confirmPagaDepositSuccess,
	confirmProvidusDepositError,
	confirmProvidusDepositLoading,
	confirmProvidusDepositSuccess,
	deductPaymentFromWalletError,
	deductPaymentFromWalletLoading,
	deductPaymentFromWalletSuccess,
	fetchCustomerWalletError,
	fetchCustomerWalletLoading,
	fetchCustomerWalletSuccess,
	getLogisticsAccountError,
	getLogisticsAccountLoading,
	getLogisticsAccountSuccess,
	getPaymentAccountError,
	getPaymentAccountLoading,
	getPaymentAccountSuccess,
	moveUserFundsError,
	moveUserFundsLoading,
	moveUserFundsSuccess,
	withdrawFromWalletError,
	withdrawFromWalletLoading,
	withdrawFromWalletSuccess,
} from "./paymentActions";
import { logout, networkErrorTrue, redirect } from "../authRedux/authActions";
import baseURL from "../../api/UrlConfig";
import { createNotification } from "../../components/notification/CreateNotification";
import Parse from "../../api/ApiConfig";

export const generateAccountDetails = () => async (dispatch) => {
	dispatch(getPaymentAccountLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/getAccountDetails`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);

		const responseTwo = await axios.post(
			`${baseURL}/functions/getPagaAccountDetails`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);

		const data = response?.data;
		const dataTwo = responseTwo?.data.result;

		dispatch(
			getPaymentAccountSuccess({
				providus: data.result,
				paga: dataTwo?.pagaAccountDetails,
			})
		);
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(getPaymentAccountError());
		} else if (error?.response?.data?.error === "Invalid session token") {
			dispatch(getPaymentAccountError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else {
			dispatch(getPaymentAccountError());
			toast.error("Failed to generate payment details. Try again!");
		}
	}
};
export const fetchCustomerWallet = (walletId) => async (dispatch) => {
	dispatch(fetchCustomerWalletLoading());
	// console.log(Parse.User.current().getSessionToken(), token);

	try {
		const response = await axios.post(
			`${baseURL}/functions/getWalletDetails`,
			{ walletId },
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					// "X-Parse-Session-Token": token,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);

		const data = await response?.data;

		dispatch(
			fetchCustomerWalletSuccess({
				wallet: data?.result?.accounts,
				otherWalletDetails: data?.result,
			})
		);
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			dispatch(fetchCustomerWalletError());
			dispatch(networkErrorTrue(error?.message));
			return;
		} else if (error?.response?.data?.error === "Invalid session token") {
			dispatch(fetchCustomerWalletError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else {
			dispatch(fetchCustomerWalletError());
		}
	}
};

export const userConfirmProvidusDeposit =
	(accountNumber) => async (dispatch) => {
		dispatch(confirmProvidusDepositLoading());
		try {
			const response = await axios.post(
				`${baseURL}/functions/confirmMoney`,
				accountNumber,
				{
					headers: {
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					},
				}
			);

			const data = response?.data;
			setTimeout(() => {
				dispatch(confirmProvidusDepositSuccess(data.result));
			}, 3000);
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				setTimeout(() => {
					dispatch(confirmProvidusDepositError("network error"));
				}, 3000);
			} else if (
				error?.response?.data?.error.includes(
					"Transfer not found. Pls crosscheck"
				)
			) {
				setTimeout(() => {
					dispatch(confirmProvidusDepositError("transfer not found"));
				}, 3000);
			} else if (
				error?.response?.data?.error.includes(
					"Validation failed. Please specify data for accountNumber"
				)
			) {
				setTimeout(() => {
					dispatch(
						confirmProvidusDepositError(
							"Validation failed. Please specify data for accountNumber"
						)
					);
				}, 3000);
			} else {
				setTimeout(() => {
					dispatch(confirmProvidusDepositError(error?.response?.data?.error));
				}, 3000);
			}
		}
	};

export const deductPaymentFromUserWallet =
	(paymentInfo, successContext, context) => async (dispatch) => {
		dispatch(deductPaymentFromWalletLoading());
		try {
			const response = await axios.post(
				`${baseURL}/functions/makeWalletWithdrawal`,
				paymentInfo,
				{
					headers: {
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					},
				}
			);

			const data = await response?.data?.result;

			dispatch(
				deductPaymentFromWalletSuccess({
					wallet: data?.data?.accounts,
					message: successContext,
				})
			);

			const message = "Funds deduction on your wallet.";
			const metaData = {
				category: "Finance",
				linkToId: data?.result?.id,
				moreDetails: `The amount of N${
					paymentInfo?.amount
				} has been deducted from your wallet ${
					context ? `for ${context}.` : ""
				}.`,
			};

			if (data) {
				createNotification(message, metaData);
			}
		} catch (error) {
			console.log(error);
			toast.error("Payment transaction failed. Try again");
			dispatch(deductPaymentFromWalletError());
		}
	};

export const moveUserFunds =
	(sessionToken = "", details) =>
	async (dispatch) => {
		dispatch(moveUserFundsLoading());

		try {
			const response = await axios.post(
				`${baseURL}/functions/moveFunds`,
				details,
				{
					headers: {
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
						"X-Parse-Session-Token":
							Parse.User.current()?.getSessionToken() || sessionToken,
					},
				}
			);
			// data?.result?.accounts
			const data = response.data.result;

			dispatch(
				moveUserFundsSuccess({
					message: details?.message || data?.message,
					wallet: data?.updatedWallet.accounts,
				})
			);

			const message = `Funds deduction from your ${details?.source} wallet.`;
			const metaData = {
				category: "Finance",
				linkToId: data?.result?.updatedWallet?.id,
				moreDetails: `The amount of N${
					details?.amount
				} has been moved from your ${
					details?.source === "main" ? "main wallet" : details?.source
				} to your ${
					details?.destination === "main" ? "main wallet" : details?.destination
				}.`,
			};

			if (data) {
				createNotification(message, metaData);
			}
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				dispatch(moveUserFundsError());
				dispatch(networkErrorTrue(error?.message));
				return;
			} else if (error?.response?.data?.error === "Invalid session token") {
				dispatch(moveUserFundsError());
				dispatch(logout());
				localStorage.clear();
				toast.error("This session has expired. Please Login again");
				dispatch(redirect("/login"));
			} else {
				dispatch(moveUserFundsError());
				toast.error("Failed to move funds");
			}
		}
	};

export const userConfirmPagaDeposit = (details) => async (dispatch) => {
	dispatch(confirmPagaDepositLoading());
	try {
		// const response = await axios.post(
		// 	`${baseURL}/functions/confirmMoney`,
		// 	accountNumber,
		// 	{
		// 		headers: {
		// 			"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
		// 		},
		// 	}
		// );
		const response = {
			data: {
				result: {
					reference: "3Q79ADJKJKD",
					amount: details?.amount,
					accountNumber: details?.accountNumber,
				},
			},
		};

		const data = response?.data;
		setTimeout(() => {
			dispatch(confirmPagaDepositSuccess(data.result));
		}, 3000);
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			setTimeout(() => {
				dispatch(confirmPagaDepositError("network error"));
			}, 3000);
		} else {
			dispatch(confirmPagaDepositError("failed to verify deposit"));
		}
		// else if (
		// 		error?.response?.data?.error.includes(
		// 			"Transfer not found. Pls crosscheck"
		// 		)
		// 	) {
		// 		setTimeout(() => {
		// 			dispatch(confirmPagaDepositError("transfer not found"));
		// 		}, 3000);
		// 	} else if (
		// 		error?.response?.data?.error.includes(
		// 			"Validation failed. Please specify data for accountNumber"
		// 		)
		// 	) {
		// 		setTimeout(() => {
		// 			dispatch(
		// 				confirmPagaDepositError(
		// 					"Validation failed. Please specify data for accountNumber"
		// 				)
		// 			);
		// 		}, 3000);
		// 	} else {
		// 		setTimeout(() => {
		// 			dispatch(confirmPagaDepositError(error?.response?.data?.error));
		// 		}, 3000);
		// 	}
	}
};

export const withdrawFundsFromWallet =
	(withdrawalDetails, sessionToken) => async (dispatch) => {
		dispatch(withdrawFromWalletLoading());
		try {
			const response = await axios.post(
				`${baseURL}/functions/withdrawToBankPaga`,
				withdrawalDetails,
				{
					headers: {
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
						"X-Parse-Session-Token": sessionToken,
					},
				}
			);

			const data = response.data?.result;
			dispatch(withdrawFromWalletSuccess(data));
		} catch (error) {
			if (error?.message === "Network Error") {
				dispatch(withdrawFromWalletError());
				toast.error("Opps. You are not connected to the internet");
			} else if (
				error?.response?.data?.error.includes("because of insufficient balance")
			) {
				toast.error("We are unable to process withdrawals at this time.");
				dispatch(withdrawFromWalletError());
			} else {
				dispatch(withdrawFromWalletError());
				toast.error("Failed to process withdrawal. Try again.");
			}
		}
	};

export const payDeliveryFee = async (amount) => {
	const response = await axios.post(
		`${baseURL}/functions/payDeliveryFeeFromWallet`,
		{ amount },
		{
			headers: {
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"X-Parse-Session-Token": Parse.User.current()?.getSessionToken(),
			},
		}
	);
	const data = await response?.data;
	console.log(data);
	return data;
};

export const generateLogisticsAccountDetails = () => async (dispatch) => {
	dispatch(getLogisticsAccountLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/fetchDeliveryFeeAccount`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				},
			}
		);

		const data = response?.data;

		dispatch(getLogisticsAccountSuccess(data?.result));
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(getLogisticsAccountError());
		} else if (error?.response?.data?.error === "Invalid session token") {
			dispatch(getLogisticsAccountError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else {
			dispatch(getLogisticsAccountError());
			toast.error("Failed to generate payment details. Try again!");
		}
	}
};

export const payMonthlyPayment = async (dataToSubmit) => {
	try {
		const response = await axios.post(
			`${baseURL}/functions/makeMonthlyPayment`,
			dataToSubmit,
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current()?.getSessionToken(),
				},
			}
		);

		const data = response?.data?.result;

		return data;
	} catch (error) {
		if (error?.message === "Network Error") {
			throw "You are not connected to the internet.";
		} else if (error?.response.data?.error === "Invalid session token") {
			throw "Invalid session token";
		} else {
			throw error?.response?.data?.error;
		}
	}
};

export const fetchAllSavingsPlan = async (savingsType) => {
	try {
		const query = new Parse.Query("Savings");
		query
			.equalTo("userId", Parse.User.current()?.id)
			.equalTo("savingsType", savingsType);

		const response = await query.find();
		const data = response.map((item) => item.toJSON());
		return data;
	} catch (error) {
		if (error.message.includes("Unable to connect to the Parse API")) {
			throw "You are not connected to the internet.";
		} else if (error?.message?.includes("bad or missing username")) {
			throw "Invalid session token";
		} else {
			throw error?.response?.data?.error;
		}
	}
};
