import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
// import { BsFillPersonFill } from "react-icons/bs";
// import { CgOrganisation } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import userContext from "../../context/UserContext";
import theme from "../../components/mui/theme";
import { BusinessImage, PersonalImage } from "../../layout/assets";

function AccountType() {
	const navigate = useNavigate();
	const [accountType, setAccountType] = useState(null);
	const { createAccountModal, setCreateAccountModal } = useContext(userContext);

	const handleBoxClick = (type) => {
		if (type === accountType) {
			setAccountType(null);
		} else {
			setAccountType(type);
		}
		// eslint-disable-next-line no-undef
		console.log(type);
	};

	const handleProceed = () => {
		// eslint-disable-next-line no-undef
		localStorage.setItem("accountType", accountType);
		navigate("/create-account");
		handleClose();
	};

	const typeStyle = (type) => ({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 196,
		backgroundColor: "#EEF2F3",
		border: `1px solid ${accountType === type ? "#00525D" : "#EEF2F3"}`,
		borderRadius: "12px",
		cursor: "pointer",
		opacity: accountType === type ? 1 : 0.7,
		p: 1,
	});

	const handleClose = () => setCreateAccountModal(false);
	return (
		<Modal
			open={createAccountModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<Box sx={style}>
				<Box sx={boxStyle}>
					<Typography variant="h2" color="primary" sx={{ fontSize: "32px" }}>
						Account Type
					</Typography>
					<Typography variant="body1" color="secondary.dark" mt={1}>
						To proceed select the account type that suits you.
					</Typography>

					<Box
						mt={4}
						mb={3}
						sx={{
							display: "grid",
							gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
							gap: 2,
						}}>
						<Box
							onClick={() => handleBoxClick("personal")}
							sx={typeStyle("personal")}>
							<Stack
								spacing={1.5}
								direction="column"
								justifyContent="center"
								alignItems="center">
								<PersonalImage />
								<Typography variant="h3" align="center">
									Personal Account
								</Typography>
								<Typography
									variant="body1"
									align="center"
									color="secondary.dark">
									This account can be created for usage of just an individual
								</Typography>
							</Stack>
						</Box>

						<Box
							onClick={() => handleBoxClick("business")}
							sx={typeStyle("business")}>
							<Stack
								spacing={1.5}
								direction="column"
								justifyContent="center"
								alignItems="center">
								<BusinessImage />
								<Typography variant="h3" align="center">
									Co-operate Account
								</Typography>
								<Typography
									variant="body1"
									align="center"
									color="secondary.dark">
									This account can be created for organisations, agencies e.t.c
								</Typography>
							</Stack>
						</Box>
					</Box>

					<Stack spacing={1}>
						<Button variant="contained" fullWidth onClick={handleProceed}>
							Proceed
						</Button>
						<Button
							variant="text"
							sx={{
								color: "#717E83",
								fontFamily: "ZabalMedium",
								"&:hover": {
									background: "transparent",
								},
							}}
							fullWidth
							onClick={handleClose}>
							Cancel
						</Button>
					</Stack>
				</Box>
			</Box>
		</Modal>
	);
}

export default AccountType;

// const style = {
// 	width: "100%",
// 	height: { xs: "90vh", sm: "100vh" },
// 	display: "flex",
// 	justifyContent: "center",
// 	alignItems: "center",
// };

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	bgcolor: theme.palette.secondary.main,
	boxShadow: "0px 0px 40px 0px rgba(57, 63, 66, 0.08)",
	border: "1px solid var(--grey-200, #E3E9EC)",
	width: "95%",
	maxWidth: "592px",
	pl: 4,
	pr: 4,
	pt: { xs: 2.5, md: 8 },
	pb: { xs: 2.5, md: 6 },
};

const boxStyle = {
	// borderRadius: "20px",
	// border: "1px solid var(--grey-200, #E3E9EC)",
	// boxShadow: "0px 0px 40px 0px rgba(57, 63, 66, 0.08)",
	// width: "90%",
	// maxWidth: "500px",
	// p: 2,
};
