import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ModalContainer, {
	DesktopSideModal,
} from "../../../components/ModalContainer";
import { SuccessCheckModal } from "../../../layout/CustomComponents";
import {
	clearPinCreationSuccess,
	clearUpdateAccountSuccess,
	closeAddBvnModal,
	openResetPinModal,
} from "../../../redux/authRedux/authActions";
import { createAccessPin } from "../../../redux/authRedux/authServices";
import {
	ConfirmPin,
	CreatePin,
	EnterPin,
} from "../../../components/payments/PinComponent";
import AddBvnModal from "../../../components/AddBvnModal";
import { useNavigate } from "react-router-dom";
import UpdatePassword from "./UpdatePassword";
import ResetAccessPin from "./ResetAccessPin";

export const ProfileSecurity = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		currentUser,
		updatingPasswordSuccess,
		accessPinSuccess,
		toggleAddBvnModal,
		verifyBvnSuccess,
		bvnSuccessMsg,
		toggleResetPinModal,
	} = useSelector((state) => state.auth);

	const [toggleUpdatePassword, setToggleUpdatePassword] = useState(false);
	const [toggleUpdatePinModal, setToggleUpdatePinModal] = useState(false);
	const [inputPin, setInputPin] = useState("");
	const [createPin, setCreatePin] = useState("");
	const [confirmPin, setConfirmPin] = useState("");
	const [pinStatus, setPinStatus] = useState("");
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [invalidConfirmPinError, setInvalidConfirmPinError] = useState(false);
	const [resetPinTab, setResetPinTab] = useState(1);

	const [bvn, setBvn] = useState(verifyBvnSuccess?.bvn || currentUser?.bvn);

	const onPasswordUpdateSuccess = () => {
		setToggleUpdatePassword(false);
		dispatch(clearUpdateAccountSuccess());
	};

	useEffect(() => {
		if (accessPinSuccess) {
			setPinStatus("pin creation success");
		}
	}, [accessPinSuccess]);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	useEffect(() => {
		if (confirmPin.length < 4) {
			return;
		} else if (confirmPin !== createPin) {
			setInvalidConfirmPinError(true);
			setTimeout(() => {
				setInvalidConfirmPinError(false);
			}, 1500);
		}
	}, [confirmPin]);

	const onBeginUpdatePinProcess = () => {
		setToggleUpdatePinModal(true);
		setPinStatus("");
	};

	const onProceedToCreatePin = () => {
		setPinStatus("create-pin");
	};

	const onCloseBtnClickFromCreatePin = () => {
		setPinStatus("");
	};

	const onProceedToConfirmPin = () => {
		setPinStatus("confirm-pin");
	};

	const onCloseBtnClickFromConfirmPin = () => {
		setPinStatus("create-pin");
	};

	const onCloseModalOnInputPin = () => {
		setToggleUpdatePinModal(false);
	};

	const onCreatePin = () => {
		dispatch(createAccessPin({ accessPin: confirmPin }));
	};

	const onPinCreationSuccess = () => {
		setToggleUpdatePinModal(false);
		setPinStatus("");
		dispatch(clearPinCreationSuccess());
	};

	const isBvnAddSuccess = () => {
		dispatch(closeAddBvnModal());
		setBvn(verifyBvnSuccess?.bvn);
	};

	const onOpenResetPinModal = () => {
		dispatch(openResetPinModal());
		setResetPinTab(1);
	};

	return (
		<>
			{toggleUpdatePassword && (
				<DesktopSideModal modal={toggleUpdatePassword}>
					<div className="w-full grid place-items-center">
						{updatingPasswordSuccess ? (
							<SuccessCheckModal
								text="Password has been successfully updated"
								onRequestClick={onPasswordUpdateSuccess}
								isShowBtn={true}
								btnText="okay!"
							/>
						) : (
							<UpdatePassword
								onReqClose={() => setToggleUpdatePassword(false)}
							/>
						)}
					</div>
				</DesktopSideModal>
			)}
			{toggleUpdatePinModal && (
				<ModalContainer modal={toggleUpdatePinModal}>
					<>
						{pinStatus === "" && (
							<EnterPin
								btnText="proceed"
								onCloseBtnClick={onCloseModalOnInputPin}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								isPinExist={currentUser?.accessPin !== undefined}
								proceedToCreatePin={onProceedToCreatePin}
								isUpdateOldPin={true}
								onRequestClick={onProceedToCreatePin}
							/>
						)}
						{pinStatus === "create-pin" && (
							<CreatePin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromCreatePin}
								onInputChange={setCreatePin}
								onRequestClick={onProceedToConfirmPin}
								isAllowedToProceed={createPin.length > 3}
							/>
						)}
						{pinStatus === "confirm-pin" && (
							<ConfirmPin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromConfirmPin}
								onInputChange={setConfirmPin}
								onRequestClick={onCreatePin}
								isAllowedToProceed={
									confirmPin.length > 3 && createPin === confirmPin
								}
								isPinInvalid={invalidConfirmPinError}
							/>
						)}
						{pinStatus === "pin creation success" && (
							<SuccessCheckModal
								onRequestClick={onPinCreationSuccess}
								text={`Access pin has been ${
									accessPin ? "updated" : "created"
								} successfully!`}
								isShowBtn={true}
								btnText="okay"
							/>
						)}
					</>
				</ModalContainer>
			)}
			{toggleAddBvnModal && (
				<ModalContainer modal={toggleAddBvnModal}>
					{bvnSuccessMsg ? (
						<SuccessCheckModal
							text="BVN Verified successfully"
							onRequestClick={isBvnAddSuccess}
							isShowBtn={true}
						/>
					) : (
						<AddBvnModal />
					)}
				</ModalContainer>
			)}
			{toggleResetPinModal && (
				<ModalContainer modal={toggleResetPinModal}>
					<ResetAccessPin
						setResetPinTab={setResetPinTab}
						resetPinTab={resetPinTab}
					/>
				</ModalContainer>
			)}

			<div className="space-y-3 md:space-y-4 h-[300px]">
				<div className="flex flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7 border-[#B8C8CF]">
					<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
						BVN Verification :
					</p>
					<div className="w-full sm:w-[70%] md:w-[4/5] text-sm zabalMedFont">
						{bvn ? (
							<p className="text-sm text-primary-color">{bvn}</p>
						) : (
							<button
								className="zabalRegFont font-semibold text-fortcore-green"
								onClick={() =>
									navigate("/dashboard/profile/complete-your-profile")
								}
							>
								Add BVN
							</button>
						)}
					</div>
				</div>
				<div className="flex flex-row items-center gap-1.5 sm:gap-5 md:gap-7 border-[#B8C8CF] zabalMedFont">
					<p className="w-1/2 sm:w-[30%] md:w-1/5 text-xs md:text-[13px]">
						Update Password :
					</p>
					<SideButton
						onClick={() => setToggleUpdatePassword(true)}
						text="Change Password"
					/>
				</div>
				<div className="flex flex-row items-center gap-1.5 sm:gap-5 md:gap-7 border-[#B8C8CF] zabalMedFont">
					<p className="w-1/2 sm:w-[30%] md:w-1/5 text-xs md:text-[13px]">
						{accessPin ? "Update" : "Create"} Pin :
					</p>
					<SideButton
						onClick={onBeginUpdatePinProcess}
						text={`${accessPin ? "Change" : "Create"} Pin`}
					/>
				</div>
				{accessPin && (
					<div className="flex flex-row items-center gap-1.5 sm:gap-5 md:gap-7 border-[#B8C8CF] zabalMedFont">
						<p className="w-1/2 sm:w-[30%] md:w-1/5 text-xs md:text-[13px]">
							Reset Pin :
						</p>
						<SideButton onClick={onOpenResetPinModal} text="Reset pin" />
					</div>
				)}
			</div>
		</>
	);
};

export default ProfileSecurity;

const SideButton = ({ onClick, text }) => (
	<div className="w-1/2 sm:w-[70%] md:w-4/5 text-sm">
		<div className="w-inherit rounded px-2 py-1.5 border border-transparent md:border-[#96A0A5] flex justify-start sm:justify-end">
			<button
				className="border border-[#B8C8CF] bg-transparent rounded w-[145px] md:w-[150px] py-2.5 text-[12.5px] md:text-[13px] text-fortcore-green"
				onClick={onClick}
			>
				{text}
			</button>
		</div>
	</div>
);

SideButton.propTypes = {
	onClick: PropTypes.func,
	text: PropTypes.string,
};
