import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Image from "../../../assets/ipad.png";
import Bg from "../../../assets/bgCircle.png";
import { useNavigate } from "react-router-dom";

function AccountProcess() {
	const navigate = useNavigate();
	return (
		<>
			<Box
				mt={{ xs: 10, md: 10 }}
				pt={5}
				pb={5}
				sx={{
					background:
						"linear-gradient(237deg, #F9FBFB 29.55%, rgba(249, 249, 249, 0.00) 69.84%)",
					position: "relative",
				}}
			>
				<img
					src={Bg}
					alt=""
					style={{ position: "absolute", left: 0, top: 0, zIndex: -1 }}
				/>
				<Box
					sx={{
						position: "absolute",
						left: 0,
						bottom: 0,
						zIndex: -1,
						background:
							"radial-gradient(50% 50% at 50% 50%, rgba(249, 253, 255, 0.12) 0%, rgba(36, 187, 140, 0.00) 100%)",
						borderRadius: "50%",
						filter: "blur(71px)",
						height: "10%",
						width: "10%",
					}}
				/>
				<Container maxWidth="lg">
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
							gap: { xs: 4, md: 5 },
							alignItems: "center",
						}}
					>
						<Box>
							<img src={Image} alt="ipad-logo" />
						</Box>

						{/* Process  */}

						<Stack spacing={4} sx={{ position: "relative" }}>
							<Box
								sx={{
									background:
										"radial-gradient(50% 50% at 50% 50%, rgba(249, 253, 255, 0.12) 0%, rgba(36, 187, 140, 0.00) 100%)",
									height: "100%",
									width: "100%",
									borderRadius: "50%",
									filter: "blur(71px)",
									position: "absolute",
									zIndex: -1,
								}}
							/>
							<Typography
								variant="h1"
								component="h2"
								color="primary"
								sx={{ fontWeight: 600, fontSize: { xs: "25px", sm: "48px" } }}
							>
								It Only Takes 5 Minutes
							</Typography>
							{processData.map((data, index) => {
								return (
									<Process
										key={index}
										number={data.number}
										title={data.title}
										text={data.text}
									/>
								);
							})}
							<Button
								variant="contained"
								sx={{ width: "172px" }}
								onClick={() => navigate("/create-account")}
							>
								Sign Up Now
							</Button>
						</Stack>
					</Box>
				</Container>
			</Box>
		</>
	);
}

export default AccountProcess;

function Process({ number, title, text }) {
	return (
		<>
			<Stack direction="row" alignItems="flex-start" spacing={6}>
				<Stack alignItems="center" spacing={1}>
					<Typography
						variant="h1"
						component="h3"
						color="primary"
						sx={{ fontWeight: 600, fontSize: { xs: "20px", sm: "20px" } }}
					>
						{number}
					</Typography>
					<Box sx={{ borderLeft: "2px solid #00525D", height: "80px" }} />
				</Stack>
				<Stack spacing={1}>
					<Typography
						variant="h1"
						component="h3"
						color="primary"
						sx={{ fontWeight: 600, fontSize: { xs: "20px", sm: "24px" } }}
					>
						{title}
					</Typography>
					<Typography
						variant="body1"
						color="textPrimary.dark"
						sx={{ fontSize: { sm: "17px" } }}
					>
						{text}
					</Typography>
				</Stack>
			</Stack>
		</>
	);
}

Process.propTypes = {
	number: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
};

const processData = [
	{
		number: "01",
		title: "Create an account",
		text: "Sign up for an account with your email",
	},
	{
		number: "02",
		title: "Add a payment method",
		text: "Create an account profile by filling your name, phone number",
	},
	{
		number: "03",
		title: "Explore Alternative Financing",
		text: "Get other finance you need at your own term",
	},
];
