import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { closeMoveFundsModal } from "../../../redux/payments/paymentActions";
import { formatAmountToIncludeCurrency } from "../../../common/utils";
import NotifyUser from "../../../layout/NotifyUser";

const AmountConfirmation = ({
	setActiveMovementTab,
	from,
	to,
	amountToMove,
}) => {
	const dispatch = useDispatch();

	return (
		<NotifyUser
			header={`You are about to move funds from your ${from} wallet to ${to} wallet.`}
			onWhiteBtnClick={() => setActiveMovementTab(1)}
			onCloseBtnClick={() => dispatch(closeMoveFundsModal())}
			onGreenBtnClick={() => setActiveMovementTab(3)}
			isGreenBtnDisabled={amountToMove === ""}
		>
			<p className="text-sm">
				<span className="text-[#717E83] zabalMedFont">Amount: </span>
				<span className="zabalBoldFont text-primary-color">NGN </span>
				<span className="text-primary-color zabalMedFont">
					{formatAmountToIncludeCurrency(
						undefined,
						amountToMove?.replaceAll(",", "")
					)}
				</span>
			</p>
		</NotifyUser>
	);
};

export default AmountConfirmation;

AmountConfirmation.propTypes = {
	from: PropTypes.string,
	to: PropTypes.string,
	amountToMove: PropTypes.string,
	setActiveMovementTab: PropTypes.func,
};
