import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PaymentOption from "../../../../components/payments/PaymentOption";
import { CustomConfirmModal } from "../../../../layout/CustomComponents";
import { EnterPin } from "../../../../components/payments/PinComponent";
import { onTogglePayCommitmentFeeModal } from "../../../../redux/financing/financeActions";
import { walletType } from "../../../../common/utils";
import { clearPaymentDeductionSuccess } from "../../../../redux/payments/paymentActions";
import { onApprenticePaysCommitmentFee } from "../../../../redux/financing/financeServices";
import Parse from "../../../../api/ApiConfig";
import { deductPaymentFromUserWallet } from "../../../../redux/payments/paymentServices";
import { logoutUser } from "../../../../redux/authRedux/authServices";

const PayCommitmentFee = ({ request, agreement }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const investorInfo = request?.investorFinanceInfo;

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = investorInfo?.userId?.objectId;

	const { currentUser } = useSelector((state) => state.auth);
	const { payment } = useSelector((state) => state.lease);
	const {
		deductWalletLoading,
		customerWallet,
		walletDetails,
		deductWalletSuccess,
	} = useSelector((state) => state.payment);

	const accessPin = currentUser?.accessPin || "";

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const amountToPay =
		process.env.NODE_ENV === "production"
			? (10 / 100) * Number(request?.amount?.replaceAll(",", ""))
			: 1;

	const [activeTab, setActiveTab] = useState(1);
	const [loading, setLoading] = useState(false);
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");

	const onCloseModal = () => dispatch(onTogglePayCommitmentFeeModal(false));

	const onProceedToPay = () => {
		if (mainWallet?.availableBalance < amountToPay) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}
		setActiveTab(3);
	};

	const onDeductWallet = () => {
		const details = {
			walletId: walletDetails?.id,
			amount: amountToPay,
			narration: "asset-activation-fee",
		};

		if (Parse.User.current()?.id) {
			dispatch(
				deductPaymentFromUserWallet(
					details,
					"commitment-fee-payment-success",
					"the payment of your commitment fee"
				)
			);
		} else {
			dispatch(logoutUser());
		}
	};

	const onUpdateRequests = async () => {
		const dataTwo = {
			commitmentFeeStatus: "paid",
			paymentType: payment?.method,
			financeAgreement: agreement,
		};

		const dataOne = {
			commitmentFee: {
				transactionId: request?.objectId,
				status: "paid",
				amount: amountToPay,
			},
			financeAgreement: agreement,
		};

		const infoOne = `The apprentice ${currentUser?.firstName} ${currentUser?.lastName} with the tarnsaction Id of ${request?.objectId} has signed the agreement form and paid the amount of NGN ${amountToPay} for their commitment Fee.`;

		const infoTwo = `Agreement signed and the amount of NGN ${amountToPay} has been deducted from your wallet for your commitment Fee`;

		try {
			setLoading(true);

			const response = await onApprenticePaysCommitmentFee(
				investorInfo?.financeId,
				request?.objectId,
				dataOne,
				dataTwo,
				infoOne,
				infoTwo,
				user
			);

			if (response?.createdReq && response?.apprenticeReq) {
				toast.success("Commitment fee payment is successful");

				setTimeout(() => {
					navigate("/financing/submitted-transactions");
				}, 500);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	useEffect(() => {
		if (deductWalletSuccess === "commitment-fee-payment-success") {
			dispatch(clearPaymentDeductionSuccess());

			onUpdateRequests();
		}
	}, [deductWalletSuccess]);

	return (
		<>
			{activeTab === 1 && (
				<PaymentOption
					onWalletSelect={() => setActiveTab(2)}
					onClose={onCloseModal}
				/>
			)}
			{activeTab === 2 && (
				<CustomConfirmModal
					onCloseBtnClick={onCloseModal}
					greenBtnText="proceed"
					greenBtnClick={onProceedToPay}
					text={`The amount of NGN ${amountToPay} will be deducted from your wallet for commitment fee.`}
				/>
			)}
			{activeTab === 3 && (
				<EnterPin
					btnText="Confirm to Pay"
					onCloseBtnClick={() => setActiveTab(2)}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onDeductWallet}
					isPaymentLoading={deductWalletLoading || loading}
				/>
			)}
		</>
	);
};

PayCommitmentFee.propTypes = {
	request: PropTypes.object,
	agreement: PropTypes.object,
};

export default PayCommitmentFee;
