import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import Sidebar from "../../layout/Sidebar";
import "react-phone-input-2/lib/style.css";
import MobileNavbar, { MobileNavSideBar } from "../../layout/MobileNavbar";
import { useSelector, useDispatch } from "react-redux";
import ModalContainer from "../../components/ModalContainer";
import Logout from "../../layout/Logout";
import AutoDebitModal from "../../components/payments/AutoDebitModal";
import {
	closeConfirmAutoDebitModal,
	saveRegularSavingsForm,
} from "../../redux/lease/leaseActions";
import {
	fetchCurrentUser,
	getGuarantorsDataFromParse,
} from "../../redux/authRedux/authServices";
import {
	getBusinessDataFromParse,
	getInvestorDataFromParse,
	getPersonalDataFromParse,
} from "../../redux/lease/leaseServices";
import { getSavingsPath } from "../../common/utils";
import NetworkError from "../../components/networkerror/NetworkError";
import { fetchCustomerWallet } from "../../redux/payments/paymentServices";

const Index = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		toggleMobileMenuModal,
		toggleLogoutModal,
		currentUser,
		redirectUrl,
		networkError,
		guarantorsFormSuccess,
	} = useSelector((state) => state.auth);
	const {
		toggleAutoDebitModal,
		savingPersonalFormSuccess,
		savingBusinessFormSuccess,
		savingInvestorFormSuccess,
		savingsType,
	} = useSelector((state) => state.lease);

	const { customerWallet } = useSelector((state) => state.payment);

	const [automateSavings, setAutomateSavings] = useState("");

	useEffect(() => {
		if (currentUser) {
			return;
		} else {
			dispatch(fetchCurrentUser());
		}
	}, [location.pathname]);

	useEffect(() => {
		if (savingPersonalFormSuccess) {
			return;
		} else {
			dispatch(getPersonalDataFromParse());
		}
	}, [location.pathname]);

	useEffect(() => {
		if (savingBusinessFormSuccess) {
			return;
		} else {
			dispatch(getBusinessDataFromParse());
		}
	}, [location.pathname]);

	const savingsPath = getSavingsPath(savingsType);

	useEffect(() => {
		if (savingInvestorFormSuccess) {
			return;
		} else {
			dispatch(getInvestorDataFromParse());
		}
	}, [location.pathname]);

	useEffect(() => {
		if (customerWallet) {
			return;
		}
		if (savingPersonalFormSuccess) {
			dispatch(fetchCustomerWallet(savingPersonalFormSuccess?.get("walletId")));
		} else {
			return;
		}
	}, [savingPersonalFormSuccess]);

	useEffect(() => {
		if (guarantorsFormSuccess) {
			return;
		} else {
			dispatch(getGuarantorsDataFromParse());
		}
	}, [location.pathname]);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location.pathname]);

	const handleChange = (e) => setAutomateSavings(e.target.value);

	const onSelectOptions = () => {
		dispatch(
			saveRegularSavingsForm({
				automation: automateSavings === "auto-debit" ? "automatic" : "manual",
			})
		);
		navigate(`/${savingsPath}/regular-savings`);
		setTimeout(() => {
			dispatch(closeConfirmAutoDebitModal());
		}, 100);
	};

	if (redirectUrl === "/login") {
		return <Navigate to="/login" />;
	}

	return (
		<>
			{networkError && <NetworkError />}
			{toggleAutoDebitModal && (
				<ModalContainer modal={toggleAutoDebitModal}>
					<AutoDebitModal
						onCloseBtnClick={() => dispatch(closeConfirmAutoDebitModal())}
						onCanelBtnClick={() => dispatch(closeConfirmAutoDebitModal())}
						onProceedBtnClick={onSelectOptions}
						automateSavings={automateSavings}
						handleChange={handleChange}
					/>
				</ModalContainer>
			)}
			{toggleLogoutModal && (
				<ModalContainer modal={toggleLogoutModal}>
					<Logout />
				</ModalContainer>
			)}
			<>
				{toggleMobileMenuModal && <MobileNavSideBar />}
				<MobileNavbar />
				<div className="bg-fortcore-green text-white fixed h-screen hidden sm:block sm:w-[155px] md:w-[170px] lg:w-[185px] xl:w-[200px] z-[11] top-0">
					<Sidebar />
				</div>
			</>
			<Outlet />
		</>
	);
};
export default Index;
