import { styled } from "styled-components";

export const HeadStyle = styled.header`
  padding: 20px;
  position: fixed;
  top: 10;
  width: 100%;

  @media (max-width: 768px) {
    background: #f9fdff;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;

    .right {
    }
  }
`;

export const Right = styled.div`
  display: ${(props) => (props.hide ? "none" : "")};
  ul {
    display: flex;
    align-items: center;
    li {
      list-style: none;
      margin-left: 12px;
    }

    .desktop {
      @media (max-width: 480px) {
        display: none;
      }
    }
    .mobile {
      display: block;

      .icon {
        font-size: 25px;
      }
      @media (min-width: 480px) {
        display: none;
      }
    }
  }
`;
