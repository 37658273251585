import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	MdFormatListBulleted,
	MdKeyboardArrowDown,
	MdKeyboardArrowRight,
} from "react-icons/md";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { selectedRequestLeaseItem } from "../../../redux/lease/leaseActions";
import {
	formatAmountToIncludeCurrency,
	removeDuplicatesInData,
} from "../../../common/utils";
import moment from "moment";
import ProductImagesCarousel from "../../../layout/ProductImagesCarousel";

export const RequestOrderGridSkeleton = () => (
	<div className="space-y-4 bg-white rounded p-3">
		<div className="flex flex-col gap-1">
			<Skeleton className="h-[80px] w-full" baseColor="#f1f1f1" />
			<Skeleton className="h-[20px] w-full" />
			<Skeleton className="h-[19px] w-full" />
		</div>
		<div className="grid grid-cols-2 gap-2.5">
			<Skeleton className="h-[27px]" />
			<Skeleton className="h-[27px]" />
		</div>
	</div>
);

export const RequestOrderListSkeleton = () => (
	<div className="bg-[#FEFEFE] border border-[#EEF2F3] sm:border-none rounded p-2.5 sm:px-3 sm:py-4 flex flex-col sm:grid md:px-4 lg:px-5 sm:grid-cols-2 lg:flex lg:flex-row gap-4 md:gap-6">
		<div className="grid gap-3 grid-cols-2 w-full max-w-[300px]">
			<Skeleton className="w-full h-[75px] max-w-[135px]" />
			<Skeleton className="w-full h-[75px] max-w-[135px]" />
		</div>
		<div className="space-y-3 w-full">
			<div className="flex flex-col gap-0.5">
				<Skeleton className="h-[19px] w-full max-w-[300px] lg:!max-w-[330px]" />
				<Skeleton className="h-[18px] w-2/3 max-w-[220px] lg:!max-w-[250px]" />
				<Skeleton className="h-[17px] !w-2/4 max-w-[150px] lg:max-w-[170px]" />
			</div>
			<div className="flex gap-3 flex-wrap">
				<Skeleton className="h-[27px] !w-[75px]" />
				<Skeleton className="h-[27px] !w-[75px]" />
				<Skeleton className="h-[27px] !w-[75px] sm:!hidden md:!block" />
			</div>
		</div>
	</div>
);

export const OngoingLeaseSkeleton = () => {
	return (
		<div className="bg-[#FEFEFE] border border-[#EEF2F3] sm:border-none rounded p-2.5 sm:px-3 sm:py-4 flex flex-col sm:grid md:px-4 lg:px-5 sm:grid-cols-2 lg:flex lg:flex-row gap-4 md:gap-6">
			<div className="grid gap-3 grid-cols-2 w-full max-w-[300px]">
				<Skeleton className="w-full h-[75px] max-w-[135px]" />
				<Skeleton className="w-full h-[75px] max-w-[135px]" />
			</div>
			<div className="space-y-3 w-full">
				<div className="flex flex-col gap-0.5">
					<Skeleton className="h-[16px] !w-2/4 max-w-[150px] lg:max-w-[170px]" />
					<Skeleton className="h-[19px] w-full max-w-[300px] lg:!max-w-[330px]" />
					<Skeleton className="h-[18px] w-2/3 max-w-[220px] lg:!max-w-[250px]" />
					<Skeleton className="h-[17px] !w-2/4 max-w-[150px] lg:max-w-[170px]" />
				</div>
			</div>
		</div>
	);
};

export const GridListSortItems = ({
	currentTab,
	onGridSelect,
	onListSelect,
	recommend,
	onSelectRecommend,
}) => {
	const { categoriesSuccess } = useSelector((state) => state.lease);

	const newCategoryArray = removeDuplicatesInData(
		categoriesSuccess?.map((d) => d.categoryName)
	);
	const [toggleRecommened, setToggleRecommended] = useState(false);

	return (
		<div className="flex items-center justify-between py-4 border-b border-[#E3E9EC]">
			<div className="flex items-center gap-2">
				<button
					className={`${
						currentTab === "grid" ? "text-fortcore-green" : "text-[#8E9DA4]"
					}`}
					onClick={onGridSelect}
				>
					<BsGrid3X3GapFill size={17} />
				</button>
				<button
					className={`${
						currentTab === "list" ? "text-fortcore-green" : "text-[#8E9DA4]"
					}`}
					onClick={onListSelect}
				>
					<MdFormatListBulleted size={20} />
				</button>
			</div>
			<div className="flex items-center gap-2 text-xs zabalRegFont">
				<p className="">Sort by:</p>
				<div
					className="relative w-[130px] h-[40px] flex items-center gap-1 justify-center rounded border text-[#879094] border-fortcore-green capitalize cursor-pointer"
					onClick={() => setToggleRecommended(!toggleRecommened)}
				>
					<p>{recommend}</p>
					<MdKeyboardArrowDown size={18} />
					{toggleRecommened && (
						<div className="absolute z-10 w-full top-[40px] bg-white rounded-sm py-1.5 zabalRegFont text-xs text-black shadow-lg">
							<p
								className="hover:bg-slate-50 py-2 px-3"
								onClick={() => onSelectRecommend("all products")}
							>
								all products
							</p>
							{newCategoryArray?.map((category, i) => (
								<p
									key={i}
									className="hover:bg-slate-50 py-2 px-3"
									onClick={() => onSelectRecommend(category)}
								>
									{category}
								</p>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

GridListSortItems.propTypes = {
	currentTab: PropTypes.string,
	onGridSelect: PropTypes.func,
	onListSelect: PropTypes.func,
	recommend: PropTypes.string,
	onSelectRecommend: PropTypes.func,
};

export const LeaseListItem = ({ item, asset, onTabChange, showDate }) => {
	const dispatch = useDispatch();
	const changeTabSaveItem = () => {
		dispatch(selectedRequestLeaseItem(asset ? asset : item));
		onTabChange();
	};

	const imageArr = [
		item?.image,
		item?.secondImage || item?.image,
		item?.thirdImage,
	].filter((image) => image != undefined);

	return (
		<div className="bg-[#FEFEFE] rounded p-2.5 sm:px-3 sm:py-4 flex flex-col sm:flex-row gap-4 sm:gap-6 md:gap-8 lg:gap-10 hover:scale-[1.025] transition-transform">
			<div className="flex max-w-[300px] sm:max-w-[150px] md:max-w-[250px] lg:max-w-[300px] items-center">
				<ProductImagesCarousel
					imageArr={imageArr}
					productName={item?.productName}
				/>
			</div>
			<div className="flex justify-between items-center max-w-[300px] sm:max-w-none lg:w-1/2 gap-5">
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-1 sm:gap-1.5">
						<p className="text-[#222627] zabalMedFont text-[12.5px] md:text-[13px]">
							{item?.productName}
						</p>
						<p className="text-fortcore-green text-[11.5px]">
							<span className="zabalMedFont">
								<span className="text-[#393F42] zabalMedFont">
									Asset Value:{" "}
								</span>
								<span className="zabalRegFont font-semibold">NGN </span>
							</span>{" "}
							<span className="zabalMedFont">
								{formatAmountToIncludeCurrency(undefined, item?.amount)}
							</span>
						</p>
						<p className="text-[#393F42] text-[10.5px]">
							<span className="zabalMedFont">Service Life: </span>
							<span className="zabalRegFont capitalize">
								{" "}
								{item?.shelfLife?.length > 3
									? item.shelfLife
									: `${item.shelfLife} Months`}
							</span>
						</p>
						{showDate && (
							<div className="flex gap-2 text-[10.5px]">
								<p>
									<span className="zabalRegFont">Date: </span>
									<span className="zabalMedFont">
										{moment(item?.createdAt).format("Do MMM YYYY")}
									</span>
								</p>
								<p>
									<span className="zabalRegFont">Time: </span>
									<span className="zabalMedFont">
										{moment(item?.createdAt).format("hh:mmA")}
									</span>
								</p>
							</div>
						)}
					</div>
					<div className="flex gap-2">
						{item?.tags?.slice(0, 2).map((info, i) => (
							<p
								className="capitalize bg-[#E3E9EC] text-[#393f42] text-[10px] rounded-md px-3 py-1 zabalRegFont"
								key={i}
							>
								{info}
							</p>
						))}
					</div>
				</div>
				<button className="text-fortcore-green p-4" onClick={changeTabSaveItem}>
					<MdKeyboardArrowRight size={24} />
				</button>
			</div>
		</div>
	);
};

LeaseListItem.propTypes = {
	asset: PropTypes.object,
	item: PropTypes.object,
	onTabChange: PropTypes.func,
	showDate: PropTypes.bool,
};

export const LeaseGridItem = ({ item, asset, onTabChange }) => {
	const dispatch = useDispatch();
	const changeTabSaveItem = () => {
		dispatch(selectedRequestLeaseItem(asset ? asset : item));
		onTabChange();
	};

	return (
		<div
			className="border border-[#EEF2F3] rounded-md overflow-hidden cursor-pointer hover:scale-[1.025] transition-transform"
			onClick={changeTabSaveItem}
		>
			<div className="bg-[#8888881F] p-2.5 sm:px-3 sm:py-4 h-[120px] sm:h-[135px] grid place-items-center">
				<img
					src={item?.image}
					alt={item?.productName}
					className="h-auto w-full max-w-[110px] max-w-[120px] mx-auto max-h-[105px] sm:max-h-[110px] mix-blend-multiply"
				/>
			</div>
			<div className="flex p-2.5 sm:p-3 bg-[#FEFEFE] sm:pb-4 flex flex-col min-h-[120px] sm:min-h-[125px] md:min-h-[130px] justify-between">
				<div className="flex flex-col gap-1.5">
					<p className="pb-1 text-fortcore-green font-semibold zabalRegFont text-[12.5px] border-b border-[#EEF2F3]">
						<span className="zabalMedFont">NGN</span>{" "}
						{formatAmountToIncludeCurrency(undefined, item?.amount)}
					</p>
					<div className="space-y-1 5 min-h-[40px]">
						<p className="text-[#222627] zabalMedFont text-xs">
							{item?.productName}
						</p>
						<p className="text-[#393F42] text-[10.5px]">
							<span className="zabalMedFont">Service Life: </span>
							<span className="zabalRegFont capitalize">
								{" "}
								{item?.shelfLife?.length > 3
									? item.shelfLife
									: `${item.shelfLife} Months`}
							</span>
						</p>
					</div>
				</div>
				<div className="flex gap-2">
					{item?.tags?.slice(0, 2).map((info, i) => (
						<p
							className="capitalize bg-[#E3E9EC] text-[#393f42] text-[10px] rounded-md px-2.5 py-0.5 zabalRegFont"
							key={i}
						>
							{info}
						</p>
					))}
				</div>
			</div>
		</div>
	);
};

LeaseGridItem.propTypes = {
	asset: PropTypes.object,
	item: PropTypes.object,
	onTabChange: PropTypes.func,
};
