import React, { useEffect } from "react";
import { MdOutlineCloudOff } from "react-icons/md";
import { useDispatch } from "react-redux";
import { clearNetworkError } from "../../redux/authRedux/authActions";
import "./networkerror.css";

const NetworkError = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	const onRetryBtnClick = () => {
		window.location.reload();
		setTimeout(() => {
			dispatch(clearNetworkError());
		}, 3000);
	};

	return (
		<div className="network-error fixed">
			<div className="flex flex-col justify-center items-center gap-4 text-center max-w-[300px]">
				<div className="flex flex-col justify-center items-center gap-3">
					<MdOutlineCloudOff size={60} color="#00525D" />
					<h5>OOPS. No Internet.</h5>
				</div>
				<div className="flex flex-col justify-center items-center gap-5">
					<p>
						Poor network connection detected. Please check your connectivity and
						try again.
					</p>
					<button
						className="flex items-center justify-center bg-fortcore-green"
						onClick={onRetryBtnClick}
					>
						Retry
					</button>
				</div>
			</div>
		</div>
	);
};

export default NetworkError;
