import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Container from '../../../layout/Container'
import Navbar from '../../../layout/Navbar'
import { UrlTracker } from '../../../layout/CustomComponents'
import { Helmet } from 'react-helmet'
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import CreateLeaseForm from "./CreateLeaseForm";
import CreateLeaseItems from "./CreateLeaseitems";
import CreateLeaseStructure from "./CreateLeaseStructure";
import CreateLeaseSummary from "./CreateLeaseSummary";

const InvestorCreateLease = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [activeTab, setActiveTab] = useState(state?.currentTab || 1)
  // const [activeModalTab,setActiveModalTab]=useState(1)
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activeTab])


  return (
    <>
      <Helmet>
        <title>Fortcore - Create Lease</title>
      </Helmet>
      <MobileNavDetails text='create lease' />
      <Container>
        <>
          <Navbar text='new transaction' />
          <div className="sm:pt-9 md:pt-10 "></div>
          <div className="flex gap-1.5">
            <UrlTracker
              text="leasing"
              onNavigate={() => navigate("/leasing")}
            />
            <UrlTracker
              text="new transaction"
              onNavigate={() => navigate("/leasing/new-transaction")}
            />
            <UrlTracker text="create lease" isCurrentUrl />
          </div>
          <div className='mb-10 sm:my-6'>
            {activeTab === 1 && <CreateLeaseForm setActiveTab={setActiveTab} />}
            {activeTab === 2 && <CreateLeaseItems setActiveTab={setActiveTab} />}
            {activeTab === 3 && <CreateLeaseStructure setActiveTab={setActiveTab} />}
            {activeTab === 4 && <CreateLeaseSummary setActiveTab={setActiveTab} />}
          </div>
        </>
      </Container>
    </>
  )
}
export default InvestorCreateLease