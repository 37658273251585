import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { CustomRadioInput } from "../../../layout/CustomComponents";
import NotifyUser from "../../../layout/NotifyUser";
import { closeMoveFundsModal } from "../../../redux/payments/paymentActions";
import { availableWallets, onInputAmount } from "../../../common/utils";

const SelectWallet = ({
	from,
	setActiveMovementTab,
	handleChange,
	amountToMove,
	to,
	childWallet,
}) => {
	const dispatch = useDispatch();

	const displayOptionWallet = availableWallets.filter((wal) => wal !== from);

	const isBtnDisabled = () => {
		if (Number(amountToMove?.replaceAll(",", "")) < 100) {
			return true;
		} else if (to === "" || childWallet === "") {
			return true;
		} else {
			return false;
		}
	};

	return (
		<NotifyUser
			header="Move Funds To ?"
			onCloseBtnClick={() => dispatch(closeMoveFundsModal())}
			onWhiteBtnClick={() => dispatch(closeMoveFundsModal())}
			onGreenBtnClick={() => setActiveMovementTab(2)}
			isGreenBtnDisabled={isBtnDisabled()}
		>
			<div className="space-y-4 md:space-y-5 text-sm  text-[#393F42]">
				<ParentSelect
					handleChange={handleChange}
					value={displayOptionWallet[0]}
					name={"toWallet"}
					isChecked={displayOptionWallet[0] === to}
					childTextOne={"Regular Savings"}
					childTextTwo={"Asset Based Savings"}
				>
					<div className="ml-2 border-l py-1 pl-4 md:pl-5 space-y-4">
						<ChildSelect
							text={"Regular Savings"}
							value="regular"
							isChecked={childWallet === "regular"}
							handleChange={handleChange}
						/>
						<ChildSelect
							text={"Asset Based Savings"}
							value="asset based"
							isChecked={childWallet === "asset based"}
							handleChange={handleChange}
						/>
					</div>
				</ParentSelect>
				<ParentSelect
					handleChange={handleChange}
					value={displayOptionWallet[1]}
					name={"toWallet"}
					isChecked={displayOptionWallet[1] === to}
					childTextOne="Risk Free Investment"
					disabled={displayOptionWallet[1] === "investment"}
				/>

				<hr />
				<div className="space-y-2 text-sm mt-3">
					<p className="zabalMedFont text-[#393F42]">
						Specify Amount to Move <span className="text-[#FF7676]">*</span>
					</p>
					<div className="relative">
						<span className="absolute top-[13px] left-3">NGN</span>
						<input
							type="text"
							inputMode="numeric"
							className="h-[45px] bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green pl-[50px] pr-2.5"
							placeholder="enter an amount to move. Min N100"
							name="amount"
							value={amountToMove}
							onChange={handleChange}
							onInput={onInputAmount}
						/>
					</div>
				</div>
			</div>
		</NotifyUser>
	);
};

SelectWallet.propTypes = {
	from: PropTypes.string,
	to: PropTypes.string,
	amountToMove: PropTypes.string,
	setActiveMovementTab: PropTypes.func,
	handleChange: PropTypes.func,
	childWallet: PropTypes.string,
};

export default SelectWallet;

const ParentSelect = ({
	value,
	name,
	handleChange,
	isChecked,
	disabled,
	children,
}) => {
	return (
		<div className="space-y-2 md:space-y-3">
			<div className="flex items-center gap-3">
				<CustomRadioInput
					value={value}
					name={name}
					handleChange={handleChange}
					isChecked={isChecked}
					disabled={disabled}
				/>
				<span
					className={`capitalize ${
						isChecked
							? "zabalBoldFont text-primary-color"
							: "zabalRegFont text-[#393F42]"
					}`}
				>
					{value}
				</span>
			</div>
			{isChecked && children}
		</div>
	);
};

ParentSelect.propTypes = {
	value: PropTypes.string,
	name: PropTypes.string,
	handleChange: PropTypes.func,
	isChecked: PropTypes.bool,
	disabled: PropTypes.bool,
	children: PropTypes.node,
};

const ChildSelect = ({ text, value, isChecked, handleChange }) => {
	return (
		<div
			className={`flex justify-between items-center ${
				isChecked
					? "text-primary-color zabalMedFont"
					: "zabalRegFont text-[#393F42]"
			}`}
		>
			<p className="text-[12.5px]">{text}</p>
			<CustomRadioInput
				name="childWallet"
				value={value}
				isChecked={isChecked}
				handleChange={handleChange}
			/>
		</div>
	);
};

ChildSelect.propTypes = {
	text: PropTypes.string,
	value: PropTypes.string,
	isChecked: PropTypes.bool,
	handleChange: PropTypes.func,
};
