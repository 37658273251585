import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FatBackTracker } from "../../layout/CustomComponents";
import { expectedWaitingperiod } from "../../screens/leasing/requestlease/LeaseStructure";
import { formatAmountToIncludeCurrency } from "../../common/utils";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import {
	DualLeaseFormSelect,
	LeaseFormSelect,
} from "../../screens/leasing/requestlease/LeaseForm";
import { MdLock } from "react-icons/md";

const PartialWalletMode = ({
	setActiveTab,
	onBtnClick,
	setWalletForm,
	period,
	length,
	amount_payable,
	interestAmount,
	selectedSlots,
}) => {
	const { selectedReqLeaseItem } = useSelector((state) => state.lease);
	const [toggleWaitingPeriod, setToggleWaitingPeriod] = useState(false);
	const [toggleWaitingLength, setToggleWaitingLength] = useState(false);
	const [toggleAmount, setToggleAmount] = useState(false);

	const onToggleAmount = () => {
		setToggleWaitingLength(false);
		setToggleWaitingPeriod(false);
		setToggleAmount(!toggleAmount);
	};

	const amount =
		Number(selectedReqLeaseItem?.productObj.amount?.replaceAll(",", "")) / 4;

	let arrOfAmount = [];

	for (let i = 1; i <= 3; i++) {
		const a = {
			slotNum: i,
			price: amount * i,
			details: `${formatAmountToIncludeCurrency(
				"NGN",
				amount * i
			)} ( ${i} slot${i > 1 ? "s" : ""}  )`,
		};

		arrOfAmount.push(a);
	}

	const interestToEarn = (interestAmount / 4) * selectedSlots?.slotNum || 0;

	const isAmountGreater = useMemo(() => {
		if (
			Number(amount_payable.replaceAll(",", "")) >
			Number(selectedReqLeaseItem?.productObj.amount)
		) {
			return true;
		} else {
			return false;
		}
	}, [amount_payable]);

	const isProceedBtnValid = () => {
		if (
			amount_payable === "" ||
			isAmountGreater ||
			period === "" ||
			length === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		const selected_slots = arrOfAmount?.find(
			(slot) => slot.details === amount_payable
		);
		setWalletForm((prev) => ({ ...prev, selectedSlots: selected_slots }));
	}, [amount_payable]);

	return (
		<div className="space-y-4 sm:space-y-5 mb-6 sm:my-5 md:my-6">
			<div className="space-y-1.5">
				<FatBackTracker
					text="Partial Investment"
					onNavigate={() => setActiveTab(1)}
				/>
				<p className="text-xs md:text-[12.25px] text-[#717E83] zabalRegFont ">
					Please fill the fields below.
				</p>
			</div>
			<div className="relative text-sm rounded-md bg-[#F6F7F8] p-4 sm:p-5 pb-6 sm:pb-7 text-[#393F42] zabalRegFont text-justify leading-6 space-y-4 sm:space-y-5">
				<p className="text-sm text-fortcore-green zabalMedFont font-semibold">
					Investment
				</p>
				<LeaseFormSelect
					text="amount to invest"
					context="choose number of slots"
					isOpened={toggleAmount}
					currentText={amount_payable}
					handleToggle={onToggleAmount}
					arrOptions={arrOfAmount.map((a) => a.details)}
					onHandleSelect={(d) => {
						setWalletForm((prev) => ({
							...prev,
							amount_payable: d,
						}));
						setTimeout(() => {
							setToggleAmount(false);
						}, 100);
					}}
				/>
				<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
					<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
						Interest Rate (NGN):
					</p>
					<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
						<div className="flex items-center justify-between w-full">
							<p>
								<span className="text-[#393F42] zabalMedFont">NGN </span>
								<span>
									{formatAmountToIncludeCurrency(undefined, interestToEarn)}
								</span>
							</p>
							<p className="text-fortcore-green zabalMedFont">{`( ${selectedReqLeaseItem?.interest}% P/A)`}</p>
						</div>
						<span className="h-full grid place-items-center">
							<MdLock size={18} color="#717E83" />
						</span>
					</div>
				</div>
				<DualLeaseFormSelect
					label="Waiting period"
					dropDownOne={{
						context: "duration",
						currentText: period,
						isOpened: toggleWaitingPeriod,
						handleToggle: () => setToggleWaitingPeriod(!toggleWaitingPeriod),
						arrOptions: expectedWaitingperiod.map((a) => a.duration),
					}}
					handleSelectOne={(d) => {
						setWalletForm((prev) => ({
							...prev,
							period: String(d),
							length: "",
						}));
						setTimeout(() => setToggleWaitingPeriod(false), 100);
					}}
					dropDownTwo={{
						context: "length",
						currentText: length,
						isOpened: toggleWaitingLength,
						handleToggle: () => {
							if (!period) return;
							setToggleWaitingLength(!toggleWaitingLength);
						},
						arrOptions: expectedWaitingperiod
							?.find((a) => a.duration === period)
							?.length.map((a) => String(a)),
					}}
					handleSelectTwo={(d) => {
						setWalletForm((prev) => ({
							...prev,
							length: String(d),
						}));
						setTimeout(() => setToggleWaitingLength(false), 100);
					}}
				/>
				<div className="hidden sm:flex justify-end gap-4">
					<CustomGreenBtn
						text="proceed"
						width="110px"
						height="42px"
						fontSize="13px"
						isDisabled={isProceedBtnValid()}
						onRequestClick={onBtnClick}
					/>
				</div>
				<div className="flex flex-col gap-4 pt-4 sm:hidden">
					<CustomGreenBtn
						text="proceed"
						isDisabled={isProceedBtnValid()}
						onRequestClick={onBtnClick}
					/>
				</div>
			</div>
		</div>
	);
};
export default PartialWalletMode;

PartialWalletMode.propTypes = {
	setActiveTab: PropTypes.func,
	onBtnClick: PropTypes.func,
	setWalletForm: PropTypes.func,
	period: PropTypes.string,
	length: PropTypes.string,
	amount_payable: PropTypes.string,
	interestAmount: PropTypes.number,
	selectedSlots: PropTypes.object,
};
