import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const CountDown = ({ timer }) => {
  const [num, setNum] = useState(timer)

  let intervalRef = useRef()

  const decreaseNum = () => setNum(prev => prev - 1)
  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);

    return () => clearInterval(intervalRef.current)
  }, [])

  if (num <= 0) {
    clearInterval(intervalRef.current)
  }

  const mins = Math.floor(num / 60)
  const secs = Math.floor(num % 60)

  return <p className={`${num > 10 ? 'text-fortcore-green' : 'text-red-400'}`}>{num > 0 ? `${mins}:${secs < 10 ? `0${secs}` : secs}` : '0.00'}</p>

}

export default CountDown

CountDown.propTypes = {
  timer: PropTypes.number,
}