/* eslint-disable no-undef */
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../../api/UrlConfig";
import Parse from "../../api/ApiConfig";
import {
	addBankAccountError,
	addBankAccountLoading,
	addBankAccountSuccess,
	addBnvLoading,
	addBvnError,
	addBvnSuccess,
	createAccessPinError,
	createAccessPinLoading,
	createAccessPinSuccess,
	currentUserData,
	disableEmailButtonWhileCount,
	disablePhoneButtonWhileCount,
	fetchGuarantorsFormLoading,
	fetchGuarantorsFormSuccess,
	getCreditScoreError,
	getCreditScoreLoading,
	getCreditScoreSuccess,
	getEmailOtpError,
	getEmailOtpLoading,
	getEmailOtpSuccess,
	getPhoneOtpError,
	getPhoneOtpLoading,
	getPhoneOtpSuccess,
	getUpcomingPaymentsError,
	getUpcomingPaymentsLoading,
	getUpcomingPaymentsSuccess,
	logout,
	openAuthorizeGuarantorModal,
	savingGuarantorsFormError,
	savingGuarantorsFormLoading,
	savingGuarantorsFormSuccess,
	verifyEmailForTransactionsError,
	verifyEmailForTransactionsLoading,
	verifyEmailForTransactionsSuccess,
	verifyEmailOtpError,
	verifyEmailOtpLoading,
	verifyEmailOtpSuccess,
	verifyPhoneOtpError,
	verifyPhoneOtpLoading,
	verifyPhoneOtpSuccess,
	redirect,
	updateUserAccountLoading,
	updateUserAccountError,
	updateUserAccountSuccess,
	getCurrentUser,
	updatePasswordLoading,
	updatePasswordSuccess,
	updatePasswordError,
	fetchAllBanksLoading,
	fetchAllBanksSuccess,
	fetchAllBanksError,
	verifyBankAccountLoading,
	verifyBankAccountSuccess,
	verifyBankAccountError,
	networkErrorTrue,
} from "./authActions";
import {
	saveBusinessFormSuccess,
	saveInvestorFormSuccess,
	savePersonalFormSuccess,
} from "../lease/leaseActions";
import { createNotification } from "../../components/notification/CreateNotification";

export const getPhoneOTP = (phoneNumber) => async (dispatch) => {
	// console.log(sessionToken);
	dispatch(getPhoneOtpLoading());
	try {
		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/verifyPhone`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
				"X-Parse-Session-Token": Parse.User.current()?.getSessionToken(),
			},
			data: JSON.stringify({ phone: phoneNumber }),
		};
		const response = await axios.request(config);
		dispatch(getPhoneOtpSuccess(response.data.result));
		toast.success("OTP has been sent to the number provided!");
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(getPhoneOtpError());
		} else if (error?.response.data?.error === "Invalid session token") {
			dispatch(getPhoneOtpError());
			dispatch(logout());
			localStorage.clear();
			redirect("/login");
			toast.error("This session has expired. Please Login again");
		} else if (
			error.response.data.error ===
			"Phone Number must begin with 234 and must be 13 digits"
		) {
			toast.error("Invalid mobile number.");
			dispatch(getPhoneOtpError());
		} else if (
			error.response.data.error.includes("Phone number is already associated")
		) {
			dispatch(getPhoneOtpError());
			toast.error(error.response.data.error);
		} else {
			dispatch(getPhoneOtpError());
			toast.error("Failed to send OTP. Try again!");
		}
	}
};
export const verifyPhoneOTP = (phoneNumber, phoneCode) => async (dispatch) => {
	dispatch(verifyPhoneOtpLoading());
	try {
		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/verifyOtp`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: JSON.stringify({ phone: phoneNumber, otp: phoneCode }),
		};
		const response = await axios.request(config);

		const data = response?.data.result;

		dispatch(
			verifyPhoneOtpSuccess({ message: data?.message, phone: phoneNumber })
		);
		dispatch(getCurrentUser(data?.data));
		toast.success("OTP verification is successful");
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(verifyPhoneOtpError());
		} else if (error.response.data.error === "Invalid OTP") {
			toast.error("The OTP provided is incorrect. Please check!");
			dispatch(verifyPhoneOtpError());
		} else if (
			error.response.data.error === "OTP expired. Request for a new one!"
		) {
			toast.error("OTP has expired. Please request a new one");
			dispatch(verifyPhoneOtpError());
		} else {
			dispatch(verifyPhoneOtpError());
			toast.error("OTP verfication failed. Try again!");
		}
	}
};

export const disablePhoneButton = (time) => (dispatch) => {
	setTimeout(() => {
		dispatch(disablePhoneButtonWhileCount());
	}, time);
};

export const getWorkEmailOtp = (email, sessionToken) => async (dispatch) => {
	dispatch(getEmailOtpLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/sendWorkMailVerification`,
			email,
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": sessionToken,
				},
			}
		);

		const data = response?.data;
		dispatch(getEmailOtpSuccess(data.result));
		toast.success("OTP has been sent to the email provided!");
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(getEmailOtpError());
		} else if (error?.response.data?.error === "Invalid session token") {
			dispatch(getEmailOtpError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else if (
			error.response.data.error.includes("email cannot have gmail, yahoo")
		) {
			toast.error("Please specify a work email!");
			dispatch(getEmailOtpError());
		} else if (
			error.response.data.error === "Email already in use and verified."
		) {
			toast.error("This email is verified already!");
			dispatch(getEmailOtpError());
		} else if (
			error?.response.data.error.includes(
				"Email is already associated with a different account"
			)
		) {
			toast.error(error?.response.data.error);
			dispatch(getEmailOtpError());
		} else {
			dispatch(getEmailOtpError());
			toast.error("Failed to send OTP. Try again!");
		}
	}
};

export const verifyEmailOtp = (emailDetails) => async (dispatch) => {
	dispatch(verifyEmailOtpLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/verifyWorkMail`,
			emailDetails,
			{ headers: { "X-Parse-Application-Id": process.env.REACT_APP_APP_ID } }
		);

		const data = response?.data;
		dispatch(
			verifyEmailOtpSuccess({
				message: data.result.message,
				email: emailDetails.workEmail,
			})
		);
		toast.success("OTP verification is successful");
		dispatch(getCurrentUser(response?.data.result.data));
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(verifyEmailOtpError());
		} else if (error.response.data.error === "Invalid OTP") {
			toast.error("The OTP provided is incorrect. Please check!");
			dispatch(verifyEmailOtpError());
		} else if (
			error.response.data.error === "OTP expired. Request for a new one!"
		) {
			toast.error("OTP has expired. Please request a new one");
			dispatch(verifyEmailOtpError());
		} else {
			dispatch(verifyEmailOtpError());
			toast.error("OTP verfication failed. Try again!");
		}
	}
};

export const disableEmailButton = (time) => (dispatch) => {
	setTimeout(() => {
		dispatch(disableEmailButtonWhileCount());
	}, time);
};

export const verifyBVN = (bvnData) => async (dispatch) => {
	dispatch(addBnvLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/verifyBvn`,
			bvnData,
			{ headers: { "X-Parse-Application-Id": process.env.REACT_APP_APP_ID } }
		);
		const data = response?.data;
		dispatch(addBvnSuccess(data.result));
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(addBvnError());
		} else if (
			error?.response?.data?.error.includes(
				"Invalid bvn. Please cross check the bvn"
			)
		) {
			toast.error("The BVN provided is inavlid. Please check and try again.");
			dispatch(addBvnError());
		} else {
			dispatch(addBvnError());
			toast.error("Unable to verify BVN. Try again!");
		}
	}
};

export const logoutUser = () => async (dispatch) => {
	try {
		window.location.replace("/login");
		await Parse.User.logOut();

		toast.success("You are now logged out of your account");
		dispatch(logout());
		localStorage.clear();
		// setTimeout(() => {
		// }, 500);
	} catch (error) {
		console.log(error);
		// toast.error("Failed to logout");
	}
};

export const getCreditScore = (token) => async (dispatch) => {
	// console.log(Parse.User.current().getSessionToken(), token);
	dispatch(getCreditScoreLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/getCreditScore`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": token,
				},
			}
		);
		const data = await response?.data;

		dispatch(getCreditScoreSuccess(data));
	} catch (error) {
		console.log(error);
		console.error(error);
		if (error?.message === "Network Error") {
			// toast.error("Oops, Service provider has been disconnected!");
			dispatch(getCreditScoreError());
			dispatch(networkErrorTrue(error?.message));
			return;
		}
		if (error?.response?.data?.error === "Invalid session token") {
			dispatch(getCreditScoreError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
			setTimeout(() => {
				window.location.reload();
			}, 1200);
			return;
		} else {
			dispatch(getCreditScoreError());
			// toast.error("Failed to get credit score data. Try again!");
		}
	}
};

export const getUpcomingPayments = (token) => async (dispatch) => {
	dispatch(getUpcomingPaymentsLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/getUpcomingPayments`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": token,
				},
			}
		);
		const data = await response?.data.result;
		dispatch(getUpcomingPaymentsSuccess(data));
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			// toast.error("Oops, Service provider has been disconnected!");
			dispatch(networkErrorTrue(error?.message));
			dispatch(getUpcomingPaymentsError());
			return;
		}
		if (error?.response?.data?.error === "Invalid session token") {
			dispatch(getUpcomingPaymentsError());
			dispatch(redirect("/login"));
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			setTimeout(() => {
				window.location.reload();
			}, 1200);
			return;
		} else {
			dispatch(getUpcomingPaymentsError());
			toast.error("Failed to get credit score data. Try again!");
		}
	}
};

export const addBankAccount = (data) => (dispatch) => {
	dispatch(addBankAccountLoading());
	try {
		setTimeout(() => {
			dispatch(addBankAccountSuccess(data));
		}, 2000);
	} catch (error) {
		console.log(error);
		dispatch(addBankAccountError());
	}
};

export const fetchCurrentUser = () => async (dispatch) => {
	try {
		const user = Parse.Object.extend("User");
		const query = new Parse.Query(user);
		const response = await query?.get(Parse.User.current()?.id);
		dispatch(currentUserData(response));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
		} else {
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		}
	}
};

export const getGuarantorsDataFromParse = () => async (dispatch) => {
	dispatch(fetchGuarantorsFormLoading());
	try {
		const query = new Parse.Query("Guarantors");
		// query.equalTo('user', 'AVEZsCBVvF');
		query.equalTo("user", Parse.User.current()?.id);
		const response = await query.find();

		dispatch(fetchGuarantorsFormSuccess(response[0]));
	} catch (error) {
		console.log(error);
	}
};

export const saveGuarantorsFormToParse = (data) => async (dispatch) => {
	dispatch(savingGuarantorsFormLoading());
	try {
		const Guarantor = Parse.Object.extend("Guarantors");
		const guarantor = new Guarantor();
		const response = await guarantor.save(data);
		console.log(response);
		dispatch(savingGuarantorsFormSuccess(response));
		dispatch(openAuthorizeGuarantorModal());

		const message = "Guarantors' information updated";
		const metaData = {
			category: "Guarantor",
			linkToId: response?.id,
			moreDetails: "You have successfull updated your gurantors' information",
		};

		if (response) {
			createNotification(message, metaData);
		}
	} catch (error) {
		console.log(error);
		dispatch(savingGuarantorsFormError());
		toast.error("Failed to save details");
	}
};

export const updateGuarantorsFormInParse =
	(objectId, data) => async (dispatch) => {
		dispatch(savingGuarantorsFormLoading());
		try {
			const query = new Parse.Query("Guarantors");
			const object = await query.get(objectId);
			const updatedObject = await object.save(data);
			console.log(updatedObject);
			dispatch(savingGuarantorsFormSuccess(updatedObject));
			dispatch(openAuthorizeGuarantorModal());
		} catch (error) {
			console.log(error);
			dispatch(savingGuarantorsFormError());
			toast.error("Failed to update details");
		}
	};

export const getTransactionEmailOtp =
	(email, sessionToken) => async (dispatch) => {
		dispatch(getEmailOtpLoading());
		try {
			const response = await axios.post(
				`${baseURL}/functions/sendWorkMailVerification`,
				email,
				{
					headers: {
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
						"X-Parse-Session-Token": sessionToken,
					},
				}
			);

			const data = response.data;
			dispatch(getEmailOtpSuccess(data.result));
			toast.success("OTP has been sent to the email provided!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(getEmailOtpError());
			} else if (
				error.response.data.error.includes("email cannot have gmail, yahoo")
			) {
				toast.error("Please specify a work email!");
				dispatch(getEmailOtpError());
			} else if (
				error.response.data.error === "Email already in use and verified."
			) {
				toast.error("This email is verified already!");
				dispatch(getEmailOtpError());
			} else if (error?.response.data?.error === "Invalid session token") {
				dispatch(getEmailOtpError());
				dispatch(logout());
				localStorage.clear();
				toast.error("This session has expired. Please Login again");
				redirect("/login");
			} else {
				dispatch(getEmailOtpError());
				toast.error("Failed to send OTP. Try again!");
			}
		}
	};

export const verifyEmailForTransactions =
	(emailDetails) => async (dispatch) => {
		dispatch(verifyEmailForTransactionsLoading());
		try {
			const response = await axios.post(
				`${baseURL}/functions/verifyWorkMail`,
				emailDetails,
				{ headers: { "X-Parse-Application-Id": process.env.REACT_APP_APP_ID } }
			);

			const data = response.data.result;
			dispatch(verifyEmailForTransactionsSuccess({ message: data.message }));

			dispatch(getCurrentUser(data?.data));
			toast.success("OTP verification is successful");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(verifyEmailForTransactionsError());
			} else if (error.response.data.error === "Invalid OTP") {
				toast.error("The OTP provided is incorrect. Please check!");
				dispatch(verifyEmailForTransactionsError());
			} else if (
				error.response.data.error === "OTP expired. Request for a new one!"
			) {
				toast.error("OTP has expired. Please request a new one");
				dispatch(verifyEmailForTransactionsError());
			} else {
				dispatch(verifyEmailForTransactionsError());
				toast.error("OTP verfication failed. Try again!");
			}
		}
	};

export const createAccessPin = (data) => async (dispatch) => {
	dispatch(createAccessPinLoading());
	try {
		const query = new Parse.Query("User");
		const object = await query.get(Parse.User.current()?.id);
		const updatedObject = await object.save(data);
		dispatch(createAccessPinSuccess(updatedObject));
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(createAccessPinError());
		} else {
			dispatch(createAccessPinError());
			toast.error("Failed to create new access pin.");
		}
	}
};

export const updateUserDataInParse = (data) => async (dispatch) => {
	try {
		const query = new Parse.Query("User");
		const object = await query.get(Parse.User.current()?.id);
		const updatedObject = await object.save(data);
		console.log(updatedObject);
		dispatch(currentUserData(updatedObject));
	} catch (error) {
		console.log(error);
	}
};

export const updateUserData =
	(data, contextSuccessText) => async (dispatch) => {
		dispatch(updateUserAccountLoading());
		try {
			const query = new Parse.Query("User");
			const object = await query.get(Parse.User.current()?.id);
			const updatedObject = await object.save(data);
			if (contextSuccessText) {
				dispatch(
					updateUserAccountSuccess({
						parseUserData: updatedObject,
						successMsg: contextSuccessText,
					})
				);
			} else {
				dispatch(updateUserAccountSuccess({ parseUserData: updatedObject }));
				toast.success("Details has been updated successfully");
			}

			const message = "Profile update";
			const metaData = {
				category: "User",
				linkToId: updatedObject?.id,
				moreDetails: `Your profile has been updated successfully.`,
			};

			if (updatedObject) {
				createNotification(message, metaData);
			}
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(updateUserAccountError());
			} else {
				dispatch(updateUserAccountError());
				toast.error("Failed to update details");
				console.log(error);
			}
		}
	};
export const updateUserPassword =
	(passwordData, sessionToken) => async (dispatch) => {
		dispatch(updatePasswordLoading());
		try {
			const response = await axios.post(
				`${baseURL}/functions/updatePassword`,
				passwordData,
				{
					headers: {
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
						"X-Parse-Session-Token": sessionToken,
					},
				}
			);

			const data = response?.data.result;

			dispatch(updatePasswordSuccess(data.data));
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(updatePasswordError());
			} else if (error?.response.data?.error === "Invalid session token") {
				dispatch(updatePasswordError());
				dispatch(logout());
				localStorage.clear();
				toast.error("This session has expired. Please Login again");
				redirect("/login");
			} else if (
				error?.response.data.error.includes(
					"Password is required and must contain at least 1 uppercase letter"
				)
			) {
				toast.error("Invalid new password. Please match the specified hint!");
				dispatch(updatePasswordError());
			} else if (
				error?.response.data.error.includes("Invalid username/password")
			) {
				toast.error("Old password is invalid!");
				dispatch(updatePasswordError());
			} else {
				dispatch(updatePasswordError());
				toast.error("Failed to send OTP. Try again!");
			}
		}
	};

export const updateUserAndPersonalData =
	(personalData, userData, contextSuccessText) => async (dispatch) => {
		dispatch(updateUserAccountLoading());
		try {
			const personal = Parse.Object.extend("Personal");
			const query = new Parse.Query(personal);
			query.equalTo("userId", Parse.User.current()?.id);
			let myObject = null;

			try {
				myObject = await query.first();

				if (myObject) {
					// If the object exists, update its attributes with the values in newData
					for (const key in personalData) {
						myObject.set(key, personalData[key]);
					}
				} else {
					// If the object doesn't exist, create a new one
					const Personal = Parse.Object.extend("Personal");
					myObject = new Personal();

					myObject.set("userId", Parse.User.current()?.id);
					for (const key in personalData) {
						myObject.set(key, personalData[key]);
					}
				}

				// Save the object to Parse
				const createdOrUpdatedPersonalData = await myObject.save();
				dispatch(savePersonalFormSuccess(createdOrUpdatedPersonalData));
				const queryTwo = new Parse.Query(Parse.User);

				const object = await queryTwo.get(Parse.User.current()?.id);
				object.set("profile", createdOrUpdatedPersonalData?.toPointer());
				const updatedUserObject = await object.save(userData);

				if (contextSuccessText) {
					dispatch(
						updateUserAccountSuccess({
							parseUserData: updatedUserObject,
							successMsg: contextSuccessText,
						})
					);
				} else {
					dispatch(
						updateUserAccountSuccess({ parseUserData: updatedUserObject })
					);
					toast.success("Details has been updated successfully");
				}
			} catch (e) {
				toast.error("Failed to update details");
				dispatch(updateUserAccountError());
				console.log(e);
			}
		} catch (error) {
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(updateUserAccountError());
			} else if (error.includes("bad or missing username")) {
				dispatch(updateUserAccountError());
				dispatch(logout());
				localStorage.clear();
				redirect("/login");
				toast.error("This session has expired. Please Login again");
			} else {
				dispatch(updateUserAccountError());
				toast.error("Failed to update details");
			}
		}
	};

export const updateUserAndBusinessData =
	(businessData, userData, contextSuccessText) => async (dispatch) => {
		dispatch(updateUserAccountLoading());
		try {
			const business = Parse.Object.extend("Business");
			const query = new Parse.Query(business);
			query.equalTo("user", Parse.User.current()?.id);
			let myObject = null;

			try {
				myObject = await query.first();

				if (myObject) {
					// If the object exists, update its attributes with the values in newData
					for (const key in businessData) {
						myObject.set(key, businessData[key]);
					}
				} else {
					// If the object doesn't exist, create a new one
					const Business = Parse.Object.extend("Business");
					myObject = new Business();

					myObject.set("user", Parse.User.current()?.id);
					for (const key in businessData) {
						myObject.set(key, businessData[key]);
					}
				}

				// Save the object to Parse
				const createdOrUpdatedBusinessData = await myObject.save();

				dispatch(saveBusinessFormSuccess(createdOrUpdatedBusinessData));
				const queryTwo = new Parse.Query(Parse.User);

				const object = await queryTwo.get(Parse.User.current()?.id);
				object.set(
					"businessProfile",
					createdOrUpdatedBusinessData?.toPointer()
				);
				const updatedUserObject = await object.save(userData);

				if (contextSuccessText) {
					dispatch(
						updateUserAccountSuccess({
							parseUserData: updatedUserObject,
							successMsg: contextSuccessText,
						})
					);
				} else {
					dispatch(
						updateUserAccountSuccess({ parseUserData: updatedUserObject })
					);
					toast.success("Details has been updated successfully");
				}
			} catch (e) {
				toast.error("Failed to update details");
				dispatch(updateUserAccountError());
				console.log(e);
			}
		} catch (error) {
			console.log(e);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(updateUserAccountError());
			} else {
				dispatch(updateUserAccountError());
				toast.error("Failed to update details");
			}
		}
	};

export const updateUserAndInvestorData =
	(investorData, userData, contextSuccessText) => async (dispatch) => {
		dispatch(updateUserAccountLoading());
		try {
			const investor = Parse.Object.extend("Investor");
			const query = new Parse.Query(investor);
			query.equalTo("user", Parse.User.current()?.id);
			let myObject = null;

			try {
				myObject = await query.first();

				if (myObject) {
					// If the object exists, update its attributes with the values in newData
					for (const key in investorData) {
						myObject.set(key, investorData[key]);
					}
				} else {
					// If the object doesn't exist, create a new one
					const Investor = Parse.Object.extend("Investor");
					myObject = new Investor();

					myObject.set("user", Parse.User.current()?.id);
					for (const key in investorData) {
						myObject.set(key, investorData[key]);
					}
				}

				// Save the object to Parse
				const createdOrUpdatedInvestorData = await myObject.save();

				dispatch(saveInvestorFormSuccess(createdOrUpdatedInvestorData));
				console.log(createdOrUpdatedInvestorData);
				const queryTwo = new Parse.Query(Parse.User);
				console.log(queryTwo);
				const object = await queryTwo.get(Parse.User.current()?.id);
				console.log(object);
				object.set(
					"investorProfile",
					createdOrUpdatedInvestorData?.toPointer()
				);
				const updatedUserObject = await object.save(userData);

				if (contextSuccessText) {
					dispatch(
						updateUserAccountSuccess({
							parseUserData: updatedUserObject,
							successMsg: contextSuccessText,
						})
					);
				} else {
					dispatch(
						updateUserAccountSuccess({ parseUserData: updatedUserObject })
					);
					toast.success("Details has been updated successfully");
				}
			} catch (e) {
				toast.error("Failed to update details");
				dispatch(updateUserAccountError());
				console.log(e);
			}
		} catch (error) {
			console.log(e);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(updateUserAccountError());
			} else {
				dispatch(updateUserAccountError());
				toast.error("Failed to update details");
			}
		}
	};

export const fetchAllBanks = () => async (dispatch) => {
	dispatch(fetchAllBanksLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/getPagaBanks`,
			{},
			{
				headers: { "X-Parse-Application-Id": process.env.REACT_APP_APP_ID },
			}
		);
		const data = response?.data?.result.banks;
		dispatch(fetchAllBanksSuccess(data));
	} catch (error) {
		console.log(error);
		dispatch(fetchAllBanksError());
	}
};

export const verifyBankAccount = (bankInfo) => async (dispatch) => {
	dispatch(verifyBankAccountLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/fetchAccountDetailsPaga`,
			bankInfo,
			{
				headers: { "X-Parse-Application-Id": process.env.REACT_APP_APP_ID },
			}
		);
		const data = response?.data.result;
		// console.log(data);
		dispatch(verifyBankAccountSuccess(data));
	} catch (error) {
		console.log(error);
		dispatch(verifyBankAccountError());
	}
};

export const getResetPinOtp = async (sessionToken) => {
	const response = await axios.post(
		`${baseURL}/functions/resetPin`,
		{},
		{
			headers: {
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"X-Parse-Session-Token": sessionToken,
			},
		}
	);
	const data = await response?.data;
	console.log(data);
	return data;
};

export const verifyResetPinOtp = async (sessionToken, otp) => {
	const response = await axios.post(
		`${baseURL}/functions/verifyPinResetOtp`,
		otp,
		{
			headers: {
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"X-Parse-Session-Token": sessionToken,
			},
		}
	);
	const data = await response?.data;
	console.log(data);
	return data;
};

export const getWalletPhoneOtp = async (phoneNumber) => {
	const response = await axios.post(
		`${baseURL}/functions/verifyPhone`,
		{ phone: phoneNumber },
		{
			headers: {
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"X-Parse-Session-Token": Parse.User.current()?.getSessionToken(),
			},
		}
	);
	const data = await response?.data;
	console.log(data);
	return data;
};

export const verifyWalletPhoneOtp = async (otp, phone) => {
	const response = await axios.post(
		`${baseURL}/functions/verifyOtp`,
		{ phone, otp },
		{
			headers: {
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
			},
		}
	);
	const data = await response?.data;
	console.log(data);
	return data;
};
