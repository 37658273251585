import { LOGOUT_USER } from "../authRedux/authTypes";
import {
	SAVE_PERSONAL_INFO_ERROR,
	SAVE_PERSONAL_INFO_LOADING,
	SAVE_PERSONAL_INFO_SUCCESS,
	SAVE_CONTACT_INFO_ERROR,
	SAVE_CONTACT_INFO_LOADING,
	SAVE_CONTACT_INFO_SUCCESS,
	SAVE_PROFBUSINESS_INFO_ERROR,
	SAVE_PROFBUSINESS_INFO_LOADING,
	SAVE_PROFBUSINESS_INFO_SUCCESS,
	SAVE_EDUCATION_INFO_ERROR,
	SAVE_EDUCATION_INFO_LOADING,
	SAVE_EDUCATION_INFO_SUCCESS,
	SAVE_FAMILY_INFO_ERROR,
	SAVE_FAMILY_INFO_LOADING,
	SAVE_FAMILY_INFO_SUCCESS,
	SAVE_TRAVEL_INFO_ERROR,
	SAVE_TRAVEL_INFO_LOADING,
	SAVE_TRAVEL_INFO_SUCCESS,
	SAVE_ACCOUNT_INFO_ERROR,
	SAVE_ACCOUNT_INFO_LOADING,
	SAVE_ACCOUNT_INFO_SUCCESS,
	SAVE_REQUEST_INFO_ERROR,
	SAVE_REQUEST_INFO_LOADING,
	SAVE_REQUEST_INFO_SUCCESS,
	SAVE_FINANCIAL_INFO_ERROR,
	SAVE_FINANCIAL_INFO_LOADING,
	SAVE_FINANCIAL_INFO_SUCCESS,
	SAVE_DOCUMENTS_INFO_ERROR,
	SAVE_DOCUMENTS_INFO_LOADING,
	SAVE_DOCUMENTS_INFO_SUCCESS,
	FETCH_TRAVEL_SAVINGS_LOADING,
	FETCH_TRAVEL_SAVINGS_SUCCESS,
	FETCH_TRAVEL_SAVINGS_ERROR,
	SAVE_TRAVEL_INFO_TO_LOCAL,
	CREATE_NEW_APPLICATION_LOADING,
	CREATE_NEW_APPLICATION_SUCCESS,
	CREATE_NEW_APPLICATION_ERROR,
	CLEAR_APPLICATION_SUCCESS,
	FETCH_SINGLE_REGULAR_SAVINGS_LOADING,
	FETCH_SINGLE_REGULAR_SAVINGS_SUCCESS,
	FETCH_SINGLE_REGULAR_SAVINGS_ERROR,
	OPEN_REGULAR_SAVINGS_ADD_FUNDS_MODAL,
	CLOSE_REGULAR_SAVINGS_ADD_FUNDS_MODAL,
	UPDATE_REGULAR_SAVINGS,
	CLEAR_UPDATE_REGULAR_SAVINGS,
} from "./travelTypes";

const initialState = {
	personalInfoLoading: false,
	personalInfoSuccess: null,
	contactInfoLoading: false,
	contactInfoSuccess: null,
	profBusinessInfoLoading: false,
	profBusinessInfoSuccess: null,
	educationInfoLoading: false,
	educationInfoSuccess: null,
	familyInfoLoading: false,
	familyInfoSuccess: null,
	travelInfoLoading: false,
	travelInfoSuccess: null,
	accountInfoLoading: false,
	accountInfoSuccess: null,
	requestInfoLoading: false,
	requestInfoSuccess: null,
	financialInfoLoading: false,
	financialInfoSuccess: null,
	documentsInfoLoading: false,
	documentsInfoSuccess: null,
	fetchSavingsLoading: false,
	fetchSavingsSuccess: [],
	createApplicationLoading: false,
	createApplicationSuccess: null,
	fetchSingleRegSavingsLoading: false,
	fetchSingleRegSavingsSuccess: null,
	toggleRegularSavingsAddFundsModal: false,
	updateRegularSavingsSuccess: null,
};

export const travelReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_PERSONAL_INFO_LOADING:
			return { ...state, personalInfoLoading: true };
		case SAVE_PERSONAL_INFO_SUCCESS:
			return {
				...state,
				personalInfoLoading: false,
				personalInfoSuccess: action.payload,
			};
		case SAVE_PERSONAL_INFO_ERROR:
			return { ...state, personalInfoLoading: false };
		case SAVE_CONTACT_INFO_LOADING:
			return { ...state, contactInfoLoading: true };
		case SAVE_CONTACT_INFO_SUCCESS:
			return {
				...state,
				contactInfoLoading: false,
				contactInfoSuccess: action.payload,
			};
		case SAVE_CONTACT_INFO_ERROR:
			return { ...state, contactInfoLoading: false };
		case SAVE_PROFBUSINESS_INFO_LOADING:
			return { ...state, profBusinessInfoLoading: true };
		case SAVE_PROFBUSINESS_INFO_SUCCESS:
			return {
				...state,
				profBusinessInfoLoading: false,
				profBusinessInfoSuccess: action.payload,
			};
		case SAVE_PROFBUSINESS_INFO_ERROR:
			return { ...state, profBusinessInfoLoading: false };
		case SAVE_EDUCATION_INFO_LOADING:
			return { ...state, educationInfoLoading: true };
		case SAVE_EDUCATION_INFO_SUCCESS:
			return {
				...state,
				educationInfoLoading: false,
				educationInfoSuccess: action.payload,
			};
		case SAVE_EDUCATION_INFO_ERROR:
			return { ...state, educationInfoLoading: false };
		case SAVE_FAMILY_INFO_LOADING:
			return { ...state, familyInfoLoading: true };
		case SAVE_FAMILY_INFO_SUCCESS:
			return {
				...state,
				familyInfoLoading: false,
				familyInfoSuccess: action.payload,
			};
		case SAVE_FAMILY_INFO_ERROR:
			return { ...state, familyInfoLoading: false };
		case SAVE_TRAVEL_INFO_LOADING:
			return { ...state, travelInfoLoading: true };
		case SAVE_TRAVEL_INFO_SUCCESS:
			return {
				...state,
				travelInfoLoading: false,
				travelInfoSuccess: action.payload,
			};
		case SAVE_TRAVEL_INFO_ERROR:
			return { ...state, travelInfoLoading: false };
		case SAVE_ACCOUNT_INFO_LOADING:
			return { ...state, accountInfoLoading: true };
		case SAVE_ACCOUNT_INFO_SUCCESS:
			return {
				...state,
				accountInfoLoading: false,
				accountInfoSuccess: action.payload,
			};
		case SAVE_ACCOUNT_INFO_ERROR:
			return { ...state, accountInfoLoading: false };
		case SAVE_REQUEST_INFO_LOADING:
			return { ...state, requestInfoLoading: true };
		case SAVE_REQUEST_INFO_SUCCESS:
			return {
				...state,
				requestInfoLoading: false,
				requestInfoSuccess: action.payload,
			};
		case SAVE_REQUEST_INFO_ERROR:
			return { ...state, requestInfoLoading: false };
		case SAVE_FINANCIAL_INFO_LOADING:
			return { ...state, financialInfoLoading: true };
		case SAVE_FINANCIAL_INFO_SUCCESS:
			return {
				...state,
				financialInfoLoading: false,
				financialInfoSuccess: action.payload,
			};
		case SAVE_FINANCIAL_INFO_ERROR:
			return { ...state, financialInfoLoading: false };
		case SAVE_DOCUMENTS_INFO_LOADING:
			return { ...state, documentsInfoLoading: true };
		case SAVE_DOCUMENTS_INFO_SUCCESS:
			return {
				...state,
				documentsInfoLoading: false,
				documentsInfoSuccess: action.payload,
			};
		case SAVE_DOCUMENTS_INFO_ERROR:
			return { ...state, documentsInfoLoading: false };
		case FETCH_TRAVEL_SAVINGS_LOADING:
			return { ...state, fetchSavingsLoading: true };
		case FETCH_TRAVEL_SAVINGS_SUCCESS:
			return {
				...state,
				fetchSavingsLoading: false,
				fetchSavingsSuccess: action.payload,
			};
		case FETCH_TRAVEL_SAVINGS_ERROR:
			return { ...state, fetchSavingsLoading: false };
		case SAVE_TRAVEL_INFO_TO_LOCAL:
			return { ...state, travelInfoSuccess: action.payload };
		case CREATE_NEW_APPLICATION_LOADING:
			return {
				...state,
				createApplicationLoading: true,
				createApplicationSuccess: null,
			};
		case CREATE_NEW_APPLICATION_SUCCESS:
			return {
				...state,
				createApplicationLoading: false,
				createApplicationSuccess: action.payload,
			};
		case CREATE_NEW_APPLICATION_ERROR:
			return { ...state, createApplicationLoading: false };
		case CLEAR_APPLICATION_SUCCESS:
			return {
				...state,
				createApplicationSuccess: null,
				travelInfoSuccess: null,
			};
		case FETCH_SINGLE_REGULAR_SAVINGS_LOADING:
			return { ...state, fetchSingleRegSavingsLoading: true };
		case FETCH_SINGLE_REGULAR_SAVINGS_SUCCESS:
			return {
				...state,
				fetchSingleRegSavingsLoading: false,
				fetchSingleRegSavingsSuccess: action.payload,
			};
		case FETCH_SINGLE_REGULAR_SAVINGS_ERROR:
			return { ...state, fetchSingleRegSavingsLoading: false };
		case OPEN_REGULAR_SAVINGS_ADD_FUNDS_MODAL:
			return { ...state, toggleRegularSavingsAddFundsModal: true };
		case CLOSE_REGULAR_SAVINGS_ADD_FUNDS_MODAL:
			return { ...state, toggleRegularSavingsAddFundsModal: false };
		case UPDATE_REGULAR_SAVINGS:
			return {
				...state,
				updateRegularSavingsSuccess: "plan update success",
				fetchSingleRegSavingsSuccess: action.payload,
			};
		case CLEAR_UPDATE_REGULAR_SAVINGS:
			return { ...state, updateRegularSavingsSuccess: null };
		case LOGOUT_USER:
			return initialState;
		default:
			return state;
	}
};
