import {
	FETCH_TRAVEL_SAVINGS_ERROR,
	FETCH_TRAVEL_SAVINGS_LOADING,
	FETCH_TRAVEL_SAVINGS_SUCCESS,
	SAVE_ACCOUNT_INFO_ERROR,
	SAVE_ACCOUNT_INFO_LOADING,
	SAVE_ACCOUNT_INFO_SUCCESS,
	SAVE_CONTACT_INFO_ERROR,
	SAVE_CONTACT_INFO_LOADING,
	SAVE_CONTACT_INFO_SUCCESS,
	SAVE_DOCUMENTS_INFO_ERROR,
	SAVE_DOCUMENTS_INFO_LOADING,
	SAVE_DOCUMENTS_INFO_SUCCESS,
	SAVE_EDUCATION_INFO_ERROR,
	SAVE_EDUCATION_INFO_LOADING,
	SAVE_EDUCATION_INFO_SUCCESS,
	SAVE_FAMILY_INFO_ERROR,
	SAVE_FAMILY_INFO_LOADING,
	SAVE_FAMILY_INFO_SUCCESS,
	SAVE_PERSONAL_INFO_ERROR,
	SAVE_PERSONAL_INFO_LOADING,
	SAVE_PERSONAL_INFO_SUCCESS,
	SAVE_PROFBUSINESS_INFO_ERROR,
	SAVE_PROFBUSINESS_INFO_LOADING,
	SAVE_PROFBUSINESS_INFO_SUCCESS,
	SAVE_REQUEST_INFO_ERROR,
	SAVE_REQUEST_INFO_LOADING,
	SAVE_REQUEST_INFO_SUCCESS,
	SAVE_FINANCIAL_INFO_ERROR,
	SAVE_FINANCIAL_INFO_LOADING,
	SAVE_FINANCIAL_INFO_SUCCESS,
	SAVE_TRAVEL_INFO_ERROR,
	SAVE_TRAVEL_INFO_LOADING,
	SAVE_TRAVEL_INFO_SUCCESS,
	SAVE_TRAVEL_INFO_TO_LOCAL,
	CREATE_NEW_APPLICATION_LOADING,
	CREATE_NEW_APPLICATION_SUCCESS,
	CREATE_NEW_APPLICATION_ERROR,
	CLEAR_APPLICATION_SUCCESS,
	FETCH_SINGLE_REGULAR_SAVINGS_LOADING,
	FETCH_SINGLE_REGULAR_SAVINGS_ERROR,
	FETCH_SINGLE_REGULAR_SAVINGS_SUCCESS,
	OPEN_REGULAR_SAVINGS_ADD_FUNDS_MODAL,
	CLOSE_REGULAR_SAVINGS_ADD_FUNDS_MODAL,
	UPDATE_REGULAR_SAVINGS,
	CLEAR_UPDATE_REGULAR_SAVINGS,
} from "./travelTypes";

export const savePersonalInfoLoading = () => {
	return { type: SAVE_PERSONAL_INFO_LOADING };
};
export const savePersonalInfoSuccess = (data) => {
	return { type: SAVE_PERSONAL_INFO_SUCCESS, payload: data };
};
export const savePersonalInfoError = () => {
	return { type: SAVE_PERSONAL_INFO_ERROR };
};
export const saveContactInfoLoading = () => {
	return { type: SAVE_CONTACT_INFO_LOADING };
};
export const saveContactInfoSuccess = (data) => {
	return { type: SAVE_CONTACT_INFO_SUCCESS, payload: data };
};
export const saveContactInfoError = () => {
	return { type: SAVE_CONTACT_INFO_ERROR };
};
export const saveProfBusinessInfoLoading = () => {
	return { type: SAVE_PROFBUSINESS_INFO_LOADING };
};
export const saveProfBusinessInfoSuccess = (data) => {
	return { type: SAVE_PROFBUSINESS_INFO_SUCCESS, payload: data };
};
export const saveProfBusinessInfoError = () => {
	return { type: SAVE_PROFBUSINESS_INFO_ERROR };
};
export const saveEducationInfoLoading = () => {
	return { type: SAVE_EDUCATION_INFO_LOADING };
};
export const saveEducationInfoSuccess = (data) => {
	return { type: SAVE_EDUCATION_INFO_SUCCESS, payload: data };
};
export const saveEducationInfoError = () => {
	return { type: SAVE_EDUCATION_INFO_ERROR };
};
export const saveFamilyInfoLoading = () => {
	return { type: SAVE_FAMILY_INFO_LOADING };
};
export const saveFamilyInfoSuccess = (data) => {
	return { type: SAVE_FAMILY_INFO_SUCCESS, payload: data };
};
export const saveFamilyInfoError = () => {
	return { type: SAVE_FAMILY_INFO_ERROR };
};
export const saveTravelInfoLoading = () => {
	return { type: SAVE_TRAVEL_INFO_LOADING };
};
export const saveTravelInfoSuccess = (data) => {
	return { type: SAVE_TRAVEL_INFO_SUCCESS, payload: data };
};
export const saveTravelInfoError = () => {
	return { type: SAVE_TRAVEL_INFO_ERROR };
};
export const saveAccountInfoLoading = () => {
	return { type: SAVE_ACCOUNT_INFO_LOADING };
};
export const saveAccountInfoSuccess = (data) => {
	return { type: SAVE_ACCOUNT_INFO_SUCCESS, payload: data };
};
export const saveAccountInfoError = () => {
	return { type: SAVE_ACCOUNT_INFO_ERROR };
};
export const saveRequestInfoLoading = () => {
	return { type: SAVE_REQUEST_INFO_LOADING };
};
export const saveRequestInfoSuccess = (data) => {
	return { type: SAVE_REQUEST_INFO_SUCCESS, payload: data };
};
export const saveRequestInfoError = () => {
	return { type: SAVE_REQUEST_INFO_ERROR };
};
export const saveFinancialInfoLoading = () => {
	return { type: SAVE_FINANCIAL_INFO_LOADING };
};
export const saveFinancialInfoSuccess = (data) => {
	return { type: SAVE_FINANCIAL_INFO_SUCCESS, payload: data };
};
export const saveFinancialInfoError = () => {
	return { type: SAVE_FINANCIAL_INFO_ERROR };
};
export const saveDocumentsInfoLoading = () => {
	return { type: SAVE_DOCUMENTS_INFO_LOADING };
};
export const saveDocumentsInfoSuccess = (data) => {
	return { type: SAVE_DOCUMENTS_INFO_SUCCESS, payload: data };
};
export const saveDocumentsInfoError = () => {
	return { type: SAVE_DOCUMENTS_INFO_ERROR };
};
export const fetchTravelSavingsLoading = () => {
	return { type: FETCH_TRAVEL_SAVINGS_LOADING };
};
export const fetchTravelSavingsSuccess = (data) => {
	return { type: FETCH_TRAVEL_SAVINGS_SUCCESS, payload: data };
};
export const fetchTravelSavingsError = () => {
	return { type: FETCH_TRAVEL_SAVINGS_ERROR };
};
export const saveTravelInfoToLocal = (data) => {
	return { type: SAVE_TRAVEL_INFO_TO_LOCAL, payload: data };
};
export const createNewApplicationLoading = () => {
	return { type: CREATE_NEW_APPLICATION_LOADING };
};
export const createNewApplicationSuccess = (successMsg) => {
	return { type: CREATE_NEW_APPLICATION_SUCCESS, payload: successMsg };
};
export const createNewApplicationError = () => {
	return { type: CREATE_NEW_APPLICATION_ERROR };
};
export const clearApplicationSuccess = () => {
	return { type: CLEAR_APPLICATION_SUCCESS };
};
export const fetchSingleRegularSavingsLoading = () => {
	return { type: FETCH_SINGLE_REGULAR_SAVINGS_LOADING };
};
export const fetchSingleRegularSavingsSuccess = (data) => {
	return { type: FETCH_SINGLE_REGULAR_SAVINGS_SUCCESS, payload: data };
};
export const fetchSingleRegularSavingsError = () => {
	return { type: FETCH_SINGLE_REGULAR_SAVINGS_ERROR };
};
export const openRegularSavingsAddFundsModal = () => {
	return { type: OPEN_REGULAR_SAVINGS_ADD_FUNDS_MODAL };
};
export const closeRegularSavingsAddFundsModal = () => {
	return { type: CLOSE_REGULAR_SAVINGS_ADD_FUNDS_MODAL };
};
export const updateRegularSavings = (data) => {
	return { type: UPDATE_REGULAR_SAVINGS, payload: data };
};
export const clearUpdateRegularSavingsSuccess = () => {
	return { type: CLEAR_UPDATE_REGULAR_SAVINGS };
};
