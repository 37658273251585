/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { GridContainer, OnboardStyle } from "../../screens/onboarding/styled";
import {
	HTwo,
	LongBtn,
	// PageLayout,
	Preloader,
	SubText,
} from "../../components/globalStyled";
import { ContentBox, FormStyle, Layout, Right } from "./styled";
import Image from "./assets/image.png";
import Image2 from "./assets/image2.png";
import Image3 from "./assets/image3.png";
// import { HeadStyle } from "../../components/header/styled";
// import Logo from "../../components/header/assets/logo.png";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import {
	AiFillCheckCircle,
	AiFillCloseCircle,
	AiOutlineClose,
} from "react-icons/ai";
import axios from "axios";
import baseURL from "../../api/UrlConfig";
import Parse from "../../api/ApiConfig";
import { toast } from "react-toastify";
import Check from "./assets/check.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { validateEmail } from "../../common/utils";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../redux/authRedux/authActions";
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
	Button,
	Stack,
	Box,
	Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import theme from "../../components/mui/theme";
import HeaderNav from "../../components/header/HeaderNav";
import BusinessForm from "./component/BusinessForm";
import PersonalForm from "./component/PersonalForm";

function CreateAccount() {
	const [tab, setTab] = useState(1);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	// eslint-disable-next-line no-undef
	const accountType = localStorage.getItem("accountType");

	//STEP ONE STATES
	// eslint-disable-next-line no-undef
	const defaultEmail = localStorage.getItem("email");
	const [email, setEmail] = useState(defaultEmail || "");
	const [validEmail, setValidEmail] = useState(false);
	//END OF STEP ONE STATES

	//STEP TWO STATES
	const [code, setCode] = useState("");
	const [active, setActive] = useState(false);
	const [resend, setResend] = useState(false);
	const [countdown, setCountdown] = useState(600);
	//END OF STEP TWO STATES

	//STEP THREE STATES
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordCheck, setPasswordCheck] = useState(false);
	const [inviteCode, setInviteCode] = useState("");
	const [accept, setAccept] = useState(false);
	const [success, setSuccess] = useState(false);
	const dispatch = useDispatch();
	//END OF STEP THREE STATES

	useEffect(() => {
		const currentUser = Parse.User.current();
		if (currentUser) {
			navigate("/dashboard", { replace: true });
		}
	}, []);

	useEffect(() => {
		if (code.length !== 6) {
			setActive(false);
		} else {
			setActive(true);
		}
	}, [code]);

	useEffect(() => {
		if (password === confirmPassword) {
			setPasswordCheck(true);
		} else {
			setPasswordCheck(false);
		}
	}, [password, confirmPassword]);

	const handleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	useEffect(() => {
		// eslint-disable-next-line no-undef
		const timer = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown <= 0) {
					// eslint-disable-next-line no-undef
					clearInterval(timer);
					return 0;
				}
				return prevCountdown - 1;
			});
		}, 1000);

		return () => {
			// eslint-disable-next-line no-undef
			clearInterval(timer);
		};
	}, []);

	const handlePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleResend = async () => {
		setLoading(true);

		const data = JSON.stringify({
			email: email,
		});

		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/sendVerificationMail`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: data,
		};

		await axios
			.request(config)
			.then((response) => {
				// eslint-disable-next-line no-undef
				console.log(response);
				setLoading(false);
				setResend(true);
				setCountdown(600);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				if (error.message === "Network Error") {
					toast.error("Network Error");
				} else {
					toast.error(error.response.data.error);
				}
			});
	};

	const handleStepOne = async (e) => {
		e.preventDefault();

		if (validEmail) {
			setLoading(true);

			const data = JSON.stringify({
				email: email,
			});

			const config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${baseURL}/functions/sendVerificationMail`,
				headers: {
					// eslint-disable-next-line no-undef
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"Content-Type": "application/json",
				},
				data: data,
			};

			await axios
				.request(config)
				.then((response) => {
					// eslint-disable-next-line no-undef
					console.log(response);
					setLoading(false);
					setTab(2);
					toast.success("OTP Sent");
					// eslint-disable-next-line no-undef
					window.scrollTo(0, 0);
				})
				.catch((error) => {
					// eslint-disable-next-line no-undef
					console.log(error);
					setLoading(false);
					if (error.message === "Network Error") {
						toast.error("Network Error");
					} else {
						toast.error(error.response.data.error);
					}
				});
		} else {
			toast.error("Please enter a valid email address");
		}
	};

	const handleStepTwo = (e) => {
		e.preventDefault();
		setLoading(true);

		const data = JSON.stringify({
			email: email,
			otp: code,
		});

		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/verifyMail`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				// eslint-disable-next-line no-undef
				console.log(response);
				setLoading(false);
				setTab(3);
				// eslint-disable-next-line no-undef
				window.scrollTo(0, 0);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				if (error.message === "Network Error") {
					toast.error("Network Error");
				} else {
					toast.error(error.response.data.error);
				}
			});
	};

	const handleLogin = async () => {
		// eslint-disable-next-line no-undef
		console.log(email, password);
		setLoading(true);
		try {
			const user = await Parse.User.logIn(email, password);

			if (user) {
				const currentUser = Parse.User.current();
				const userData = currentUser?.toJSON();
				// eslint-disable-next-line no-undef
				console.log("CurrentUser: ", userData);
				dispatch(getCurrentUser(userData));
				setLoading(false);
				navigate("/dashboard", { replace: true });
			}
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
			setLoading(false);
			if (error.message === "Invalid username/password.") {
				toast.error("Invalid email/password.");
			} else {
				toast.error(error.message);
			}
		}
	};

	const handleStepThree = async (e) => {
		e.preventDefault();
		if (phoneNumber) {
			if (password === confirmPassword) {
				setLoading(true);

				const data = JSON.stringify({
					email: email,
					firstName: firstName,
					lastName: lastName,
					phone: phoneNumber,
					password: password,
					inviteCode: inviteCode,
				});

				const config = {
					method: "post",
					maxBodyLength: Infinity,
					url: `${baseURL}/functions/createAccount`,
					headers: {
						// eslint-disable-next-line no-undef
						"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
						"Content-Type": "application/json",
					},
					data: data,
				};

				axios
					.request(config)
					.then((response) => {
						// eslint-disable-next-line no-undef
						console.log(response);
						setSuccess(true);
						setLoading(false);
					})
					.catch((error) => {
						// eslint-disable-next-line no-undef
						console.log(error);
						setLoading(false);
						if (error.message === "Network Error") {
							toast.error("Network Error");
						} else {
							toast.error(error.response.data.error);
						}
					});
			} else {
				toast.error("Passwords do not match");
			}
		} else {
			toast.error("Phone number is required");
		}
	};

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;

		return `${minutes.toString().padStart(2, "0")}:${seconds
			.toString()
			.padStart(2, "0")}`;
	};

	const getTextColor = () => {
		if (countdown <= 10) {
			return "red";
		}
		return "black";
	};

	// const renderCountdown = () => {
	//   1;
	//   if (countdown <= 0) {
	//     return "Expired";
	//   }
	// };

	const hasNumber = /\d/.test(password);
	const hasSpecialCharacter = /[!@#=$%^&*()_+{}\\[\]:;<>,.?~\\/-]/.test(
		password
	);
	const hasLowerCase = /[a-z]/.test(password);
	const hasUpperCase = /[A-Z]/.test(password);

	return (
		<>
			<HeaderNav />
			<Helmet>
				<title>Fortcore - Create an Account</title>
			</Helmet>

			<Container maxWidth="lg">
				<Box
					sx={{
						height: { xs: "100%", md: "95vh" },
						display: "grid",
						gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
						alignItems: "center",
						pt: { xs: 10, md: 2 },
						pb: { xs: 10, md: 0 },
						gap: 3,
					}}>
					{/* Left  */}

					<Box>
						{tab === 1 && <img src={Image} alt="" />}
						{tab === 2 && <img src={Image2} alt="" />}
						{tab === 3 && <img src={Image3} alt="" />}
					</Box>

					{/* Right  */}
					<Box>
						{tab === 1 && (
							<Right>
								<span className="steps">Step {tab} of 3</span>
								<hr />
								<HTwo>Create Account</HTwo>
								<SubText>Set up your account now to access financing. </SubText>

								<FormStyle onSubmit={handleStepOne}>
									<label htmlFor="email">Email Address</label>
									<input
										type="email"
										value={email}
										name="email"
										onChange={(event) => {
											const { value } = event.target;
											setEmail(value);
											setValidEmail(validateEmail(value));
										}}
										placeholder="Enter your E-mail"
										required
									/>

									<LongBtn type="submit" disabled={!passwordCheck || loading}>
										{!loading ? "Create Account" : <Preloader />}
									</LongBtn>
								</FormStyle>
								<div className="center">
									<p className="link">
										Have an account? <Link to="/login">Log In</Link>
									</p>
								</div>
							</Right>
						)}

						{tab === 2 && (
							<Right>
								{resend && (
									<div className="resend">
										<p>A new set of code has been sent to you</p>
										<AiOutlineClose
											className="icon"
											onClick={() => setResend(false)}
										/>
									</div>
								)}
								<span className="steps">Step {tab} of 3</span>
								<hr />
								<HTwo>Verify Email</HTwo>
								<SubText>
									A verification code has been sent to{" "}
									<Typography component="span" color="primary">
										{email}
									</Typography>
								</SubText>

								<FormStyle onSubmit={handleStepTwo}>
									<label htmlFor="code">Code</label>
									<input
										type="tel"
										value={code}
										name="code"
										onChange={(e) => setCode(e.target.value)}
										required
									/>
									<div className="center" style={{ color: getTextColor() }}>
										<p>{formatTime(countdown)}</p>
									</div>
									<LongBtn
										type="submit"
										className={!active ? "inactive" : ""}
										disabled={!active || loading}>
										{!loading ? "Continue" : <Preloader />}
									</LongBtn>
								</FormStyle>
								<div className="center">
									<p className="link">
										Didn’t get OTP?{" "}
										<Link
											onClick={() => {
												handleResend();
											}}>
											Resend Code
										</Link>
									</p>
								</div>
							</Right>
						)}

						{tab === 3 && (
							<>
								{accountType === "business" && (
									<BusinessForm step={tab} email={email} />
								)}
								{accountType === "personal" && (
									<PersonalForm step={tab} email={email} />
								)}
							</>
						)}
					</Box>
				</Box>
			</Container>

			<OnboardStyle>
				<div className="zabalRegFont"></div>
			</OnboardStyle>
		</>
	);
}

export default CreateAccount;

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));
