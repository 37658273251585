import React from "react";
import PropTypes from "prop-types";
import { FatBackTracker } from "../../layout/CustomComponents";
import { LeaseSummaryInput } from "../../screens/leasing/requestlease/LeaseSummary";
import { formatAmountToIncludeCurrency } from "../../common/utils";
import { MdLock } from "react-icons/md";
import { CustomGreenBtn } from "../../layout/CustomButtons";

const FullInvestmentMode = ({
	setActiveTab,
	amount_to_invest,
	interestAmount,
	interest,
	onBtnClick,
}) => {
	const interestToEarn = (interestAmount / 4) * 4;

	return (
		<div className="space-y-4 sm:space-y-5 mb-6 sm:my-5 md:my-6">
			<div className="space-y-1.5">
				<FatBackTracker
					text="Full Investment"
					onNavigate={() => setActiveTab(1)}
				/>
				<p className="text-xs md:text-[12.25px] text-[#717E83] zabalRegFont ">
					View summary of your investment.
				</p>
			</div>
			<div className="relative text-sm rounded-md bg-[#F6F7F8] p-4 sm:p-5 pb-6 sm:pb-7 text-[#393F42] zabalRegFont text-justify leading-6 space-y-4 sm:space-y-5">
				<p className="text-sm text-fortcore-green zabalMedFont font-semibold">
					Investment
				</p>
				<LeaseSummaryInput
					input_value={formatAmountToIncludeCurrency("NGN", amount_to_invest)}
					text="amount to invest"
				/>
				<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
					<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
						Interest Rate (NGN):
					</p>
					<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
						<div className="flex items-center justify-between w-full">
							<p>
								<span className="text-[#393F42] zabalMedFont">NGN </span>
								<span>
									{formatAmountToIncludeCurrency(undefined, interestToEarn)}
								</span>
							</p>
							<p className="text-fortcore-green zabalMedFont">{`( ${interest}% P/A)`}</p>
						</div>
						<span className="h-full grid place-items-center">
							<MdLock size={18} color="#717E83" />
						</span>
					</div>
				</div>
				<div className="hidden sm:flex justify-end gap-4">
					<CustomGreenBtn
						text="proceed"
						width="110px"
						height="42px"
						fontSize="13px"
						onRequestClick={onBtnClick}
					/>
				</div>
				<div className="flex flex-col gap-4 pt-4 sm:hidden">
					<CustomGreenBtn text="proceed" onRequestClick={onBtnClick} />
				</div>
			</div>
		</div>
	);
};

export default FullInvestmentMode;

FullInvestmentMode.propTypes = {
	setActiveTab: PropTypes.func,
	amount_to_invest: PropTypes.string,
	interest: PropTypes.number,
	interestAmount: PropTypes.number,
	onBtnClick: PropTypes.func,
};
