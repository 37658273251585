import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { MdKeyboardArrowDown, MdPerson } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
	openLogoutModal,
	openMiniLoginModal,
} from "../redux/authRedux/authActions";
import { getProfilePercentage } from "../common/utils";
import Notifications from "../components/notification/Notifications";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/logosvg.svg";
import Parse from "../api/ApiConfig";

const Navbar = ({ text }) => {
	const { currentUser } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [toggleModal, setToggleModal] = useState(false);

	useEffect(() => {
		if (toggleModal) {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "hidden";
		}
		return () => {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "auto";
		};
	}, [toggleModal]);

	const openLogout = () => {
		setToggleModal(false);
		dispatch(openLogoutModal());
	};

	const onProfileClick = () => {
		setToggleModal(false);
		navigate("/dashboard/profile");
	};

	const onCompleteProfileClick = () => {
		setToggleModal(false);
		navigate("/dashboard/profile/complete-your-profile");
	};

	return (
		<>
			{toggleModal && (
				<div
					className="fixed z-[13] inset-0 hidden sm:block"
					onClick={() => setToggleModal(false)}
				>
					<div className="relative bg-[#393F4266] w-full h-full sm:px-6 md:px-8 lg:px-10 flex justify-end items-start">
						<ProfileDropDown
							onLogoutClick={openLogout}
							onProceedToProfile={onProfileClick}
						/>
					</div>
				</div>
			)}
			<nav className="fixed sm:h-[55px] md:h-[60px] w-full left-0 bg-[#EFF8F9] border-b border-[#EEF2F3] top-0 z-[10] hidden sm:flex">
				<div className="flex w-full max-w-[1440px] mx-auto">
					<div className="h-full sm:w-[155px] md:w-[170px] lg:w-[185px] xl:w-[200px]"></div>
					<div className="sm:px-6 md:px-8 lg:px-10 flex gap-3 items-center justify-between h-full sm:w-[calc(100%-155px)] md:w-[calc(100%-170px)] lg:w-[calc(100%-185px)] xl:w-[calc(100%-200px)]">
						<p className="font-bold capitalize text-primary-color chillaxFont text-[17px] md:text-lg lg:text-xl">
							{text}
						</p>
						<div className="flex gap-3 items-center">
							<ProfilePercentage
								onCompleteProfileClick={onCompleteProfileClick}
							/>

							<Notifications />

							<button
								className="flex items-center"
								onClick={() => setToggleModal(true)}
							>
								<div className="text-[11.5px] uppercase zabalBoldFont bg-[#BDBDBD66] text-fortcore-green rounded-full h-[27px] w-[27px] grid place-items-center p-1 h-[16px]">
									<p>
										{currentUser?.firstName?.charAt(0)}
										{currentUser?.lastName?.charAt(0)}
									</p>
								</div>
								<span>
									<MdKeyboardArrowDown color="#00525D" size={18} />
								</span>
							</button>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;

Navbar.propTypes = {
	text: PropTypes.string,
};

export const ProfilePercentage = ({
	onCompleteProfileClick,
	showPercentage = true,
}) => {
	const { currentUser } = useSelector((state) => state.auth);

	const dataValidation = {
		workEmail: currentUser?.workEmail,
		workEmailVerified: currentUser?.workEmailVerified,
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		businessAddress: currentUser?.businessAddress,
		businessName: currentUser?.businessName,
		businessType: currentUser?.businessType,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		idCard: currentUser?.idCard,
		utilityBill: currentUser?.utilityBill,
		workStatus: currentUser?.workStatus,
		incorporationStatus: currentUser?.incorporationStatus,
		investorSignature: currentUser?.investorSignature,
		witnessSignature: currentUser?.witnessSignature,
		netWorth: currentUser?.netWorth,
		salaryRange: currentUser?.salaryRange,
		numberOfDependants: currentUser?.numberOfDependants,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
		accessPin: currentUser?.accessPin,
	};

	const percentage = getProfilePercentage(dataValidation);

	return (
		<div className="flex items-center gap-1.5">
			<div
				className={`zabalBoldFont ${
					percentage === 100
						? "w-[34px] h-[34px] md:w-[35px] md:h-[35px]"
						: "w-[32px] h-[32px] md:w-[34px] md:h-[34px]"
				}`}
			>
				<CircularProgressbar
					value={percentage}
					text={`${percentage}%`}
					strokeWidth={percentage === 100 ? 11 : 12}
					styles={buildStyles({
						textSize: `${percentage === 100 ? "25px" : "27px"}`,
						textColor: "#00525D",
						pathColor: "#00525D",
						trailColor: "#C5CDD0",
					})}
				/>
			</div>
			{showPercentage && (
				<div>
					{percentage < 100 && (
						<button
							className="zabalBoldFont text-xs text-fortcore-green capitalize"
							onClick={onCompleteProfileClick}
						>
							complete your profile
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export const ProfileDropDown = ({ onLogoutClick, onProceedToProfile }) => {
	return (
		<div className="relative top-[55px] sm:top-[57px] md:top-[63px] w-[140px] md:w-[145px] text-[12.5px] bg-white rounded-sm divide-y divide-[#EEF2F3] px-4 py-2 flex flex-col w-[160px] indexBalance">
			<button
				className="py-2 flex gap-2 items-center text-slate-500 zabalMedFont hover:text-fortcore-green hover:font-semibold"
				onClick={onProceedToProfile}
			>
				<span>
					<MdPerson size={18} />
				</span>
				<span>My Profile</span>
			</button>
			<button
				className="py-2 flex gap-2 items-center text-slate-500 zabalMedFont hover:text-red-400 hover:font-semibold"
				onClick={onLogoutClick}
			>
				<span>
					<IoLogOut size={18} />
				</span>
				<span>Logout</span>
			</button>
		</div>
	);
};

export const SummaryPageNav = ({ dataExist }) => {
	const { currentUser } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onLogin = () => {
		if (dataExist) {
			dispatch(openMiniLoginModal());
		} else {
			navigate("/login");
		}
	};

	return (
		<>
			<nav className="fixed h-[60px] w-full top-0 bg-[#EFF8F9] left-0 px-3 sm:px-8 md:px-12 lg:px-16 xl:px-20 flex items-center z-10">
				<div className="flex items-center justify-between w-full max-w-[1440px] mx-auto">
					<Link to={Parse.User.current()?.id ? "/dashboard" : "/"}>
						<img
							src={Logo}
							alt="company-logo"
							className="h-[42px] sm:h-[45px]"
						/>
					</Link>
					<div className="flex items-center gap-2.5 sm:gap-3 md:gap-4">
						{Parse.User.current()?.id ? (
							<>
								<ProfilePercentage showPercentage={false} />
								<div className="text-xs uppercase zabalBoldFont bg-[#BDBDBD66] text-fortcore-green rounded-full h-[30px] w-[30px] grid place-items-center p-1">
									{currentUser?.firstName?.charAt(0)}
									{currentUser?.lastName?.charAt(0)}
								</div>
							</>
						) : (
							<button
								className="zabalBoldFont text-[14.5px] sm:text-[15px]"
								onClick={onLogin}
							>
								Login to your Account
							</button>
						)}
					</div>
				</div>
			</nav>
			<div className="h-2.5 md:h-3 w-full fixed mt-[60px] bg-white z-10"></div>
		</>
	);
};

ProfileDropDown.propTypes = {
	onLogoutClick: PropTypes.func,
	onProceedToProfile: PropTypes.func,
};
ProfilePercentage.propTypes = {
	onCompleteProfileClick: PropTypes.func,
	showPercentage: PropTypes.bool,
};
SummaryPageNav.propTypes = {
	dataExist: PropTypes.bool,
};
