import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	useParams,
	useLocation,
	useNavigate,
	Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import Parse from "../../api/ApiConfig";
import { networkErrorTrue } from "../../redux/authRedux/authActions";
import NetworkError from "../../components/networkerror/NetworkError";
import { Preloader } from "../../components/globalStyled";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import Container from "../../layout/Container";
import Navbar from "../../layout/Navbar";
import { UrlTracker } from "../../layout/CustomComponents";
import { NoRequestFound } from "../leasing/submittedtransactions/LeaseAgreement";
import theme from "../../components/mui/theme";
import {
	AgricultureImage,
	AutomotiveImage,
	ConstructionImage,
	ConsultingImage,
	ECommerceImage,
	EducationImage,
	FashionImage,
	FinancialImage,
	HealthcareImage,
	HospitalityImage,
	ManufacturingImage,
	MediaImage,
	RestaurantImage,
	RetailImage,
	TechnologyImage,
	TransportationImage,
} from "./images/ImagesAf";
import { addAmounts, formatAmountToIncludeCurrency } from "../../common/utils";
import { TrackerRadio, TrackerText } from "../leasing/submittedtransactions";
import ModalContainer from "../../components/ModalContainer";
import ViewFinanceRequests from "./ViewFinanceRequests";
import { onToggleViewFinanceReqModal } from "../../redux/financing/financeActions";
import { onInvestorUpdatesApprenticeStatus } from "../../redux/financing/financeServices";

const ActiveFinanceInvestment = () => {
	const navigate = useNavigate();
	const params = useParams();

	const { networkError } = useSelector((state) => state.auth);
	const { toggleViewFinanceReqModal } = useSelector((state) => state.finance);

	const dispatch = useDispatch();
	const { state } = useLocation();

	const [isLoading, setIsLoading] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [error, setError] = useState(null);

	const [approveLoaders, setApproveLoaders] = useState(null);

	const [rejectLoaders, setRejectLoaders] = useState(null);

	const fetchRequestWithQuery = async () => {
		try {
			setIsLoading(true);
			const classToQuery = state?.isCreatedReq
				? "AFBusinessCreate"
				: "ApprenticeshipFinance";

			const query = new Parse.Query(classToQuery);
			query.equalTo("objectId", params.id);
			const response = await query.first();

			const data = response?.toJSON();

			setSelectedData(data);
		} catch (error) {
			if (error?.message === "Network Error") {
				dispatch(networkErrorTrue("Network Error"));
			}
			console.log(error);
			if (error.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			} else if (error?.message?.includes("Invalid session token")) {
				toast.error("This session has expired. Please login.");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			} else {
				setError(error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const businessType = selectedData?.businessType?.toLowerCase();

	const amountPaid = state?.isCreatedReq
		? formatAmountToIncludeCurrency(undefined, selectedData?.amountToInvest)
		: selectedData?.investorsInfo &&
		  addAmounts(selectedData?.investorsInfo?.map((a) => a?.amountPaid));

	const totalAmount = state?.isCreatedReq
		? selectedData?.amountToInvest
		: selectedData?.amountToRaise;

	const isFundingCompleted =
		amountPaid === formatAmountToIncludeCurrency(undefined, totalAmount);

	const getImgUrl = () => {
		if (selectedData?.productPhotos) {
			return selectedData?.productPhotos[0]?.url;
		} else if (businessType?.includes("restaurant")) {
			return RestaurantImage;
		} else if (businessType?.includes("retail")) {
			return RetailImage;
		} else if (businessType?.includes("technology")) {
			return TechnologyImage;
		} else if (businessType?.includes("consulting")) {
			return ConsultingImage;
		} else if (businessType?.includes("construction")) {
			return ConstructionImage;
		} else if (businessType?.includes("healthcare")) {
			return HealthcareImage;
		} else if (businessType?.includes("e-commerce")) {
			return ECommerceImage;
		} else if (businessType?.includes("manufacturing")) {
			return ManufacturingImage;
		} else if (businessType?.includes("transportation")) {
			return TransportationImage;
		} else if (businessType?.includes("hospitality")) {
			return HospitalityImage;
		} else if (businessType?.includes("finance")) {
			return FinancialImage;
		} else if (businessType?.includes("education")) {
			return EducationImage;
		} else if (businessType?.includes("media")) {
			return MediaImage;
		} else if (businessType?.includes("agriculture")) {
			return AgricultureImage;
		} else if (businessType?.includes("fashion")) {
			return FashionImage;
		} else if (businessType?.includes("automotive")) {
			return AutomotiveImage;
		}
	};

	const isApprovedReq = selectedData?.apprentice?.find(
		(req) => req?.status === "approved"
	);

	const apprenticePayFee = selectedData?.apprentice?.find(
		(req) => req?.transactionId === selectedData?.commitmentFee?.transactionId
	);

	const status = {
		funded: isFundingCompleted,
		isCreatedReq: state?.isCreatedReq,
		commitmentFee: !state?.isCreatedReq
			? selectedData?.commitmentFeeStatus
			: apprenticePayFee
			? selectedData?.commitmentFee?.status
			: false,
		requests: selectedData?.apprentice,
		approvalStatus: !!isApprovedReq,
		financialAgreement: !!selectedData?.financeAgreement?.signature,
	};

	const onApproveRequest = async (status, requestId) => {
		const selectedApprentice = selectedData?.apprentice.find(
			(app) => app?.transactionId === requestId
		);

		const User = Parse.Object.extend("_User");
		const user = new User();
		user.id = selectedApprentice?.user?.objectId;

		const investorMsg = `You have accepted the request of apprentice ${selectedApprentice?.name} with the transaction ID of ${selectedApprentice?.transactionId}. The apprentice will be notified to sign the agreement form.`;

		const message = "Request Approval Status";
		const moreInfo = {
			category: "ApprenticeshipFinance",
			linkToId: requestId,
			moreDetails:
				"Your request has been approved. You can proceed to sign the apprentice agreement form and pay the commitment fee",
		};

		try {
			setApproveLoaders((prev) => ({ ...prev, [requestId]: true }));

			const updatedApprentice = selectedData?.apprentice?.map((req) => {
				if (req.transactionId === requestId) {
					return { ...req, status, funded: true };
				} else {
					return { ...req, status: "rejected", funded: false };
				}
			});

			const apprenticeData = updatedApprentice?.map((a) => ({
				objectId: a?.transactionId,
				approvalStatus: a?.status,
				funded: a?.funded,
			}));

			const response = await onInvestorUpdatesApprenticeStatus(
				selectedData.objectId,
				investorMsg,
				{ apprentice: updatedApprentice },
				apprenticeData,
				message,
				moreInfo,
				user
			);

			if (response?.createdReq && response?.apprenticeReqs) {
				toast.success("Request update is successful");

				setTimeout(() => {
					window.location.reload();
				}, 500);
			}
		} catch (error) {
			console.log(error);
			toast.error(error);
		} finally {
			setApproveLoaders((prev) => ({ ...prev, [requestId]: false }));
		}
	};

	const onRejectRequest = async (status, requestId) => {
		const selectedApprentice = selectedData?.apprentice.find(
			(app) => app?.transactionId === requestId
		);

		const User = Parse.Object.extend("_User");
		const user = new User();
		user.id = selectedApprentice?.user?.objectId;

		const investorMsg = `You have rejected the request of apprentice ${selectedApprentice?.name} with the transaction ID of ${selectedApprentice?.transactionId}`;

		const message = "Request Approval Status";
		const moreInfo = {
			category: "ApprenticeshipFinance",
			linkToId: requestId,
			moreDetails: "Your request has been rejected by the investor. ",
		};

		try {
			setRejectLoaders((prev) => ({ ...prev, [requestId]: true }));

			const updatedApprentice = selectedData?.apprentice?.map((req) => {
				if (req.transactionId === requestId) {
					return { ...req, status };
				} else {
					return req;
				}
			});

			const apprenticeData = updatedApprentice
				.filter((req) => req?.transactionId === requestId)
				.map((a) => ({
					objectId: a?.transactionId,
					approvalStatus: a?.status,
				}));

			const response = await onInvestorUpdatesApprenticeStatus(
				selectedData.objectId,
				investorMsg,
				{ apprentice: updatedApprentice },
				apprenticeData,
				message,
				moreInfo,
				user
			);

			if (response?.createdReq && response?.apprenticeReqs) {
				toast.success("Request update is successful");

				setTimeout(() => {
					window.location.reload();
				}, 500);
			}
		} catch (error) {
			console.log(error);
			toast.error(error);
		} finally {
			setRejectLoaders((prev) => ({ ...prev, [requestId]: false }));
		}
	};

	if (!state) {
		return <Navigate to="/financing/investment-portfolio" />;
	}

	useEffect(() => {
		const apploaders = selectedData?.apprentice
			? Object.assign(
					{},
					...selectedData.apprentice.map((request) => ({
						[request?.transactionId]: false,
					}))
			  )
			: false;
		setApproveLoaders(apploaders);

		const rejloaders = selectedData?.apprentice
			? Object.assign(
					{},
					...selectedData.apprentice.map((request) => ({
						[request?.transactionId]: false,
					}))
			  )
			: false;
		setRejectLoaders(rejloaders);
	}, [selectedData]);

	useEffect(() => {
		fetchRequestWithQuery();
	}, [params.id]);

	return (
		<>
			{networkError && <NetworkError />}
			{isLoading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}

			{toggleViewFinanceReqModal && (
				<ModalContainer modal={toggleViewFinanceReqModal}>
					<ViewFinanceRequests
						requests={selectedData?.apprentice}
						onApproveRequest={onApproveRequest}
						onRejectRequest={onRejectRequest}
						approveLoaders={approveLoaders}
						rejectLoaders={rejectLoaders}
					/>
				</ModalContainer>
			)}

			<MobileNavDetails text="active investment" />

			<Container>
				<>
					<Helmet>
						<title>Fortcore - Finance Investments</title>
					</Helmet>
					<Navbar text="active investment" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/financing")}
						/>
						<UrlTracker
							text="finance investment board"
							onNavigate={() => navigate("/financing/investment-portfolio")}
						/>
						<UrlTracker
							text={`active investment - ${params.id}`}
							isCurrentUrl
						/>
					</div>
					<div className="mt-1 sm:mt-[30px]"></div>
					{error ? (
						<NoRequestFound />
					) : (
						<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8 mb-10 sm:mb-14">
							<div className="flex gap-2.5 items-center font-semibold sm:text-[15px] md:text-base lg:text-lg text-fortcore-green chillaxFont pb-1.5 md:py-2 border-b border-[#E3E9EC]">
								<button className="zabalMedFont" onClick={() => navigate(-1)}>
									<FaArrowLeft />
								</button>
								<p>Transaction Details</p>
							</div>
							<Box
								sx={{
									border: "1px solid var(--grey-500, #B8C8CF)",
									borderRadius: "8px",
									background: theme.palette.secondary.main,
									width: "100%",
									p: 2,
									display: "flex",
									flexDirection: { xs: "column", sm: "row" },
									// flexWrap: "wrap",
									justifyContent: "flex-start",
									alignItems: "start",
									gap: { xs: 4, sm: 5 },
								}}
							>
								<Box
									sx={{
										width: "216px",
										height: "120px",
										border: "1px #E3E9EC solid",
										borderRadius: "5px",
										p: 0.5,
									}}
								>
									<img
										src={getImgUrl()}
										alt="image"
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
										}}
									/>
								</Box>
								<div className="flex flex-col gap-1 sm:gap-1.5">
									<p className="text-[#222627] zabalMedFont text-sm md:text-[14.5px] capitalize">
										{selectedData?.businessType}
									</p>
									<p className="text-[#393F42] zabalMedFont text-[12.5px] md:text-[13px]">
										<span className="zabalRegFont">Transaction ID: </span>
										<span>TRN-{selectedData?.objectId}</span>
									</p>
									<p className="text-fortcore-green zabalMedFont text-[12.5px] md:text-[13px]">
										<span className="text-[#393F42] zabalRegFont">
											Request Amount:
										</span>{" "}
										<span>
											NGN{" "}
											{formatAmountToIncludeCurrency(
												undefined,
												selectedData?.amountToRaise?.replaceAll(",", "") ||
													selectedData?.amountToInvest?.replaceAll(",", "")
											)}
										</span>
									</p>

									<p className="text-[#393F42] zabalMedFont text-[12.5px] md:text-[13px]">
										<span className="zabalRegFont">Tenure: </span>
										<span>
											{selectedData?.tenure || selectedData?.duration}
										</span>
									</p>

									<p className="text-[#393F42] zabalMedFont text-[12.5px] md:text-[13px]">
										<span className="zabalRegFont">Interest: </span>
										<span>{selectedData?.interestRate} P/A</span>
									</p>
								</div>
							</Box>
							<div className="mt-6 rounded-md pl-3 py-5 pr-4 md:p-6 bg-[#fefefe] border space-y-4">
								<StatusTracker
									status={status}
									onOpenViewRequests={() =>
										dispatch(onToggleViewFinanceReqModal(true))
									}
								/>
							</div>
						</div>
					)}
				</>
			</Container>
		</>
	);
};

export default ActiveFinanceInvestment;

const StatusTracker = ({ status, onOpenViewRequests }) => {
	// console.log(status);
	return (
		<div className="relative">
			<div className="mt-2.5 md:mt-3 absolute">
				<TrackerRadio currentStatus={status?.funded} />
				{status?.isCreatedReq ? (
					<>
						<TrackerRadio currentStatus={status?.requests?.length > 0} />
						<TrackerRadio currentStatus={status?.approvalStatus} />
						<TrackerRadio currentStatus={status?.commitmentFee === "paid"} />
					</>
				) : (
					<TrackerRadio currentStatus={status?.commitmentFee === "paid"} />
				)}
				<TrackerRadio currentStatus={status?.financialAgreement} isEnd />
			</div>
			<div className="flex justify-end">
				<div className="grid gap-3 md:gap-4 w-[calc(100%-32px)] md:w-[calc(100%-37px)] lg:w-[calc(100%-42px)]">
					<TrackerText text="fully funded" currentStatus={status?.funded} />
					{status?.isCreatedReq ? (
						<>
							<TrackerText
								text={`apprentice requests`}
								currentStatus={status?.requests?.length > 0}
								showBtns
								btnText={`${
									status?.requests?.length > 0 ? "View Requests" : "No request"
								}`}
								onNavigate={onOpenViewRequests}
								isBtnDisabled={status?.requests?.length < 1}
							/>
							<TrackerText
								text={`approval status ${
									status?.approvalStatus ? "( Approved )" : ""
								}`}
								currentStatus={status?.approvalStatus}
							/>
							<TrackerText
								text="commitment fee"
								currentStatus={status?.commitmentFee === "paid"}
							/>
						</>
					) : (
						<TrackerText
							text="commitment fee"
							currentStatus={status?.commitmentFee === "paid"}
							isEnd
						/>
					)}
					<TrackerText
						text="financial agreement"
						currentStatus={status?.financialAgreement}
					/>
				</div>
			</div>
		</div>
	);
};

StatusTracker.propTypes = {
	status: PropTypes.object,
	onOpenViewRequests: PropTypes.func,
};
