import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { CustomGreenBtn, CustomWhiteBtn } from "../../layout/CustomButtons";
import CountDown from "../Countdown";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
	disableEmailButton,
	verifyEmailForTransactions,
} from "../../redux/authRedux/authServices";
import { Preloader } from "../globalStyled";
// import Alert from "../../layout/Alert"

const VerifyPaymentEmail = ({
	onBackBtnClick,
	onProceedBtnClick,
	onCloseBtnClick,
	email,
	onGetEmailOtp,
	isLoading,
}) => {
	const dispatch = useDispatch();
	const [text, setText] = useState("");
	const {
		isShowEmailTimer,
		emailOtpMessageLoading,
		verifyEmailForTransactionsLoading,
		verifyEmailForTransactionsSuccess,
	} = useSelector((state) => state.auth);

	useEffect(() => {
		if (verifyEmailForTransactionsSuccess) {
			return;
		}
		if (text.length < 6) {
			return;
		} else if (isNaN(text)) {
			return;
		} else {
			dispatch(verifyEmailForTransactions({ workEmail: email, otp: text }));
		}
	}, [text]);

	// disable resend email otp while counter
	useEffect(() => {
		if (isShowEmailTimer) {
			dispatch(disableEmailButton(1000 * 115));
		}
	}, [isShowEmailTimer]);

	// console.log(
	// 	isShowEmailTimer,
	// 	emailOtpMessageLoading,
	// 	verifyEmailForTransactionsSuccess
	// );

	return (
		<div className="w-[100vw] flex justify-center items-center">
			<div className="text-[#393F42] rounded-md bg-white p-5 md:p-6 w-11/12 space-y-4 md:space-y-5 max-w-[450px]">
				<div className="flex justify-between">
					<p className="text-sm sm:text-[15px] zabalBoldFont">
						OTP Verification
					</p>
					<button onClick={onCloseBtnClick}>
						<MdOutlineClose color="#00525D" size={19} />
					</button>
				</div>
				{/* <Alert textOne='A new set of code has been sent to you' /> */}
				<div className="space-y-3.5">
					<p className="text-[12.5px] zabalRegFont">
						Please check your work email{" "}
						<span className="zabalMedfont text-fortcore-green">{email}</span>{" "}
						for OTP
					</p>
					<div className="flex flex-col gap-1.5">
						<p className="text-[11px] zabalRegFont">Verification</p>
						<div className="relative">
							<input
								type="tel"
								className={`w-full h-[45px] border border-[#96A0A5] px-2.5 rounded outline-none text-sm zabalRegFont focus:border-fortcore-green focus:border-2 ${
									verifyEmailForTransactionsSuccess
										? "bg-slate-300/70"
										: "bg-transparent"
								}`}
								placeholder="enter OTP"
								value={text?.trim()}
								onChange={(e) => {
									if (isNaN(e.target.value)) {
										return;
									}
									setText(e.target.value);
								}}
								disabled={verifyEmailForTransactionsSuccess}
							/>
							{verifyEmailForTransactionsLoading && (
								<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
									<Preloader />
								</div>
							)}
						</div>
						{verifyEmailForTransactionsSuccess ? (
							<p className="text-[13px] text-green-500 zabalMedFont text-xs">
								Email verification success!
							</p>
						) : (
							<div className="text-[11.5px] flex justify-between text-fortcore-green zabalMedFont">
								<div className="space-x-1.5 justify-self-end ">
									<span className="text-[#393F42]">Didn&apos;t get code?</span>
									<button
										className={`font-semibold ${
											isShowEmailTimer ||
											emailOtpMessageLoading ||
											verifyEmailForTransactionsSuccess
												? "text-[#717E83]"
												: "text-fortcore-green"
										}`}
										onClick={() => {
											onGetEmailOtp();
											setText("");
										}}
										disabled={
											isShowEmailTimer ||
											emailOtpMessageLoading ||
											verifyEmailForTransactionsSuccess
										}
									>
										{emailOtpMessageLoading ? "Sending..." : "Resend Code"}
									</button>
								</div>
								{isShowEmailTimer && <CountDown timer={115} />}
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col gap-3 py-3 sm:hidden">
					<CustomGreenBtn
						fontSize="13px"
						text="proceed"
						onRequestClick={onProceedBtnClick}
						isDisabled={!verifyEmailForTransactionsSuccess || isLoading}
						isLoading={isLoading}
					/>
					<CustomWhiteBtn
						fontSize="13px"
						text="back"
						onRequestClick={onBackBtnClick}
					/>
				</div>
				<div className="hidden sm:flex justify-end gap-3 pb-3">
					<CustomWhiteBtn
						width="100px"
						height="40px"
						fontSize="13px"
						text="back"
						onRequestClick={onBackBtnClick}
					/>
					<CustomGreenBtn
						width="100px"
						height="40px"
						fontSize="13px"
						text="proceed"
						onRequestClick={onProceedBtnClick}
						isDisabled={!verifyEmailForTransactionsSuccess || isLoading}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};
export default VerifyPaymentEmail;

VerifyPaymentEmail.propTypes = {
	onGetEmailOtp: PropTypes.func,
	email: PropTypes.string,
	onBackBtnClick: PropTypes.func,
	onProceedBtnClick: PropTypes.func,
	onCloseBtnClick: PropTypes.func,
	isLoading: PropTypes.bool,
};
