import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { BackTracker } from "../../../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import { saveDataFromLeaseStructure } from "../../../redux/lease/leaseActions";
import { FaArrowLeft } from "react-icons/fa";
import { DesktopSideModal } from "../../../components/ModalContainer";
import ItemDescription from "../marketplace/ItemDescription";
import {
	LeaseStructureItem,
	expectedDuration,
	expectedWaitingperiod,
	leaseStructureArr,
} from "../requestlease/LeaseStructure";
import {
	DualLeaseFormSelect,
	LeaseFormSelect,
} from "../requestlease/LeaseForm";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
	formatAmountToIncludeCurrency,
	getSingularOrPlural,
} from "../../../common/utils";
import { MdLock } from "react-icons/md";
import { fetchCurrentInterest } from "../../../redux/lease/leaseServices";

const CreateLeaseStructure = ({ setActiveTab }) => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		selectedReqLeaseItem,
		toggleMarketItemsModal,
		leaseStructureData,
		currentGeneratedinterest,
		leaseFormData,
	} = useSelector((state) => state.lease);

	const [leaseStructureForm, setLeaseStructureForm] = useState({
		lease_structure: leaseStructureData?.lease_structure || "",
		interest: leaseStructureData?.interest || "",
		wait_period: leaseStructureData?.waiting_period?.split(" ")[1] || "",
		wait_length: leaseStructureData?.waiting_period?.split(" ")[0] || "",
		lease_period: leaseStructureData?.lease_duration?.split(" ")[1] || "",
		lease_length: leaseStructureData?.lease_duration?.split(" ")[0] || "",
		selected_slots: leaseStructureData?.selected_slots?.details || "",
	});

	const {
		lease_structure,
		interest,
		wait_length,
		wait_period,
		lease_length,
		lease_period,
		selected_slots,
	} = leaseStructureForm;

	const amount = Number(selectedReqLeaseItem?.amount?.replaceAll(",", "")) / 4;

	let arrOfAmount = [];

	for (let i = 1; i < 5; i++) {
		const a = {
			slotNum: i,
			price: amount * i,
			details: `${formatAmountToIncludeCurrency(
				"NGN",
				amount * i
			)} ( ${i} slot${i > 1 ? "s" : ""}  )`,
		};

		arrOfAmount.push(a);
	}

	useEffect(() => {
		dispatch(fetchCurrentInterest("Base Interest rate leasing"));
	}, []);

	useEffect(() => {
		if (currentGeneratedinterest) {
			setLeaseStructureForm((prev) => ({
				...prev,
				interest: currentGeneratedinterest?.value?.replace("%", ""),
			}));
		}
	}, [currentGeneratedinterest]);

	const [formsToggler, setFormsToggler] = useState([
		{ id: 0, toggleForm: false },
		{ id: 1, toggleForm: false },
		{ id: 2, toggleForm: false },
		{ id: 3, toggleForm: false },
		{ id: 4, toggleForm: false },
		{ id: 5, toggleForm: false },
	]);

	const toggleModal = (id) => {
		setFormsToggler(
			formsToggler.map((a) =>
				a.id === id
					? { ...a, toggleForm: !a.toggleForm }
					: { ...a, toggleForm: false }
			)
		);
	};

	const handleWaitingTwo = () => {
		if (!wait_period) return;
		toggleModal(2);
	};

	const handleDurationTwo = () => {
		if (!lease_period) return;
		toggleModal(4);
	};

	const isProceedBtnValid = () => {
		if (
			lease_structure === "" ||
			selected_slots === "" ||
			interest === "" ||
			lease_period === "" ||
			lease_length === "" ||
			wait_period === "" ||
			wait_length === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const onProceedToNextTab = () => {
		const data = {
			lease_structure,
			interest,
			lease_duration: `${lease_length} ${getSingularOrPlural(
				lease_length,
				lease_period
			)}`,
			waiting_period: `${wait_length} ${getSingularOrPlural(
				wait_length,
				wait_period
			)}`,
			selected_slots: arrOfAmount?.find(
				(slot) => slot.details === selected_slots
			),
		};
		// console.log(data);
		dispatch(saveDataFromLeaseStructure(data));
		setActiveTab(4);
	};

	if (!selectedReqLeaseItem) {
		return <Navigate to="/leasing/new-transaction/investor-market-place" />;
	}

	if (leaseStructureData?.requestType === "new" && !leaseFormData) {
		return <Navigate to="/leasing/new-transaction/investor-market-place" />;
	}

	return (
		<>
			{toggleMarketItemsModal && (
				<DesktopSideModal modal={toggleMarketItemsModal}>
					<ItemDescription />
				</DesktopSideModal>
			)}
			<BackTracker
				text="Create Asset"
				onNavigate={
					state?.currentTab == 3 ? () => navigate(-1) : () => setActiveTab(2)
				}
			/>
			<div className="mb-4 hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold">
				<button
					className="zabalMedFont"
					onClick={
						state?.currentTab == 3 ? () => navigate(-1) : () => setActiveTab(2)
					}
				>
					<FaArrowLeft />
				</button>
				<p>Create Asset</p>
			</div>
			<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseStructureItem item={selectedReqLeaseItem} />
					<LeaseFormSelect
						text="lease structure"
						context="choose lease structure"
						isOpened={formsToggler[0].toggleForm}
						currentText={lease_structure}
						handleToggle={() => toggleModal(0)}
						arrOptions={leaseStructureArr}
						onHandleSelect={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								lease_structure: d,
							}));
							setTimeout(() => {
								toggleModal(0);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="amount to invest"
						context="choose number of slots"
						isOpened={formsToggler[5].toggleForm}
						currentText={selected_slots}
						handleToggle={() => toggleModal(5)}
						arrOptions={arrOfAmount.map((a) => a.details)}
						onHandleSelect={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								selected_slots: d,
							}));
							setTimeout(() => {
								toggleModal(5);
							}, 100);
						}}
					/>
					<DualLeaseFormSelect
						label="Waiting Period"
						dropDownOne={{
							context: "duration",
							currentText: wait_period,
							isOpened: formsToggler[1].toggleForm,
							handleToggle: () => toggleModal(1),
							arrOptions: expectedWaitingperiod.map((a) => a.duration),
						}}
						handleSelectOne={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								wait_period: String(d),
								wait_length: "",
							}));
							setTimeout(() => {
								toggleModal(1);
							}, 100);
						}}
						dropDownTwo={{
							context: "length",
							currentText: wait_length,
							isOpened: formsToggler[2].toggleForm,
							handleToggle: handleWaitingTwo,
							arrOptions: expectedWaitingperiod
								?.find((a) => a.duration === wait_period)
								?.length.map((a) => String(a)),
						}}
						handleSelectTwo={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								wait_length: String(d),
							}));
							setTimeout(() => {
								toggleModal(2);
							}, 100);
						}}
					/>
					<DualLeaseFormSelect
						label="Lease Duration"
						dropDownOne={{
							context: "duration",
							currentText: lease_period,
							isOpened: formsToggler[3].toggleForm,
							handleToggle: () => toggleModal(3),
							arrOptions: expectedDuration.map((a) => a.duration),
						}}
						handleSelectOne={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								lease_period: String(d),
								lease_length: "",
							}));
							setTimeout(() => {
								toggleModal(3);
							}, 100);
						}}
						dropDownTwo={{
							context: "length",
							currentText: lease_length,
							isOpened: formsToggler[4].toggleForm,
							handleToggle: handleDurationTwo,
							arrOptions: expectedDuration
								?.find((a) => a.duration === lease_period)
								?.length.map((a) => String(a)),
						}}
						handleSelectTwo={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								lease_length: String(d),
							}));
							setTimeout(() => {
								toggleModal(4);
							}, 100);
						}}
					/>
					{/* interest */}
					<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
							Interest Rate (%):
						</p>
						<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative zabalRegFont rounded border border-[#B8B8B8] text-[#393F42] flex gap-1.5 items-center px-2.5">
							<span className="">%</span>
							<span>{interest}</span>
							<span className="text-[#393F42] text-sm">P/A</span>
							<span className="absolute right-3">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>
					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						<CustomWhiteBtn
							text="back"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={() => setActiveTab(2)}
						/>
						<CustomGreenBtn
							text="proceed"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={onProceedToNextTab}
							isDisabled={isProceedBtnValid()}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						<CustomGreenBtn
							text="proceed"
							onRequestClick={onProceedToNextTab}
							isDisabled={isProceedBtnValid()}
						/>
						<CustomWhiteBtn
							text="back"
							onRequestClick={() => setActiveTab(2)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default CreateLeaseStructure;

CreateLeaseStructure.propTypes = {
	setActiveTab: PropTypes.func,
};
