import React from "react";
import PropTypes from "prop-types";
import { formatAmountToIncludeCurrency } from "../../../common/utils";
import { MdLock } from "react-icons/md";
import ProductImagesCarousel from "../../../layout/ProductImagesCarousel";

export const LeaseSummaryInput = ({ text, input_value, isLoading }) => {
	return (
		<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
			<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
				{text} :
			</p>
			<div className="w-full h-[45px] sm:w-[67%] md:w-4/6 lg:w-9/12 relative">
				<input
					type="text"
					className={`h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] zabalRegFont capitalize ${
						isLoading ? "text-[#B8B8B8]" : "text-[#393F42]"
					}`}
					value={input_value}
					disabled={true}
					readOnly
				/>
				<span className="absolute right-2 h-full w-[30px] grid place-items-center top-0">
					<MdLock size={18} color="#717E83" />
				</span>
			</div>
		</div>
	);
};

LeaseSummaryInput.propTypes = {
	text: PropTypes.string,
	input_value: PropTypes.string,
	isLoading: PropTypes.bool,
};

export const LeaseSummaryItemDetails = ({
	item,
	interest,
	interestAmount,
	availableSlots,
	amountInvested,
}) => {
	const imageArr = [
		item?.image,
		item?.secondImage || item?.image,
		item?.thirdImage,
	].filter((image) => image != undefined);

	return (
		<div className="!mt-0 sm:!mt-5 bg-[#FEFEFE] border border-[#B8C8CF] rounded p-3 sm:py-4 flex flex-col md:px-4 lg:px-5 sm:flex-row gap-4 md:gap-6 lg:gap-8 sm:items-center">
			<div className="flex max-w-[300px] sm:max-w-[150px] md:max-w-[250px] lg:max-w-[300px] items-center">
				<ProductImagesCarousel
					imageArr={item?.imageArr || imageArr}
					productName={item?.productName}
				/>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex flex-col gap-1.5 sm:gap-2">
					<p className="text-[#222627] zabalMedFont text-[13.5px] md:text-sm">
						{item?.productName}
					</p>
					<p className="text-fortcore-green text-xs md:text-[12.5px]">
						<span className="zabalMedFont">
							<span className="text-[#393F42] zabalMedFont">Asset Value: </span>
							<span className="zabalRegFont font-semibold">NGN </span>
						</span>{" "}
						<span className="zabalMedFont">
							{formatAmountToIncludeCurrency(undefined, item?.amount)}
						</span>
					</p>
					<p className="text-[#393F42] text-[11.5px] md:text-xs">
						<span className="zabalMedFont">Service Life: </span>
						<span className="zabalRegFont capitalize">
							{item?.shelfLife?.length > 3
								? item?.shelfLife
								: `${item?.shelfLife} Months`}
						</span>
					</p>
					{amountInvested && (
						<p className="text-[#393F42] zabalMedFont text-xs">
							<span className="zabalRegFont">Amount Invested: </span>
							<span>
								{formatAmountToIncludeCurrency("NGN", amountInvested)}
							</span>
						</p>
					)}
					{interestAmount && (
						<p className="text-[#393F42] zabalMedFont text-xs">
							<span className="zabalRegFont">Interest: </span>
							<span>
								NGN {formatAmountToIncludeCurrency(undefined, interestAmount)}{" "}
								{interest ? `at %${interest}` : "for one year"}
							</span>
						</p>
					)}
					{!isNaN(availableSlots) && (
						<p className="text-[#393F42] zabalMedFont text-xs">
							<span className="zabalRegFont">Available Slots: </span>
							<span>{availableSlots}</span>
						</p>
					)}
				</div>
				{item?.tags?.length > 0 && (
					<div className="flex gap-2">
						{item?.tags?.slice(0, 2).map((info, i) => (
							<p
								className="capitalize bg-[#E3E9EC] text-[#393f42] text-[10px] rounded px-2 py-1 zabalRegFont"
								key={i}
							>
								{info}
							</p>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

LeaseSummaryItemDetails.propTypes = {
	item: PropTypes.object,
	interest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	interestAmount: PropTypes.number,
	availableSlots: PropTypes.number,
	amountInvested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
