import React, { useEffect } from "react";
import Navbar from "../../../layout/Navbar";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import { Helmet } from "react-helmet";
import Container from "../../../layout/Container";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../../components/EmptyData";
import Skeleton from "react-loading-skeleton";
import { fetchAssetBasedSavings } from "../../../redux/lease/leaseServices";
import PropTypes from "prop-types";
import { MdKeyboardArrowRight } from "react-icons/md";
import moment from "moment";
import {
	formatAmountToIncludeCurrency,
	walletType,
} from "../../../common/utils";
import { DesktopSideModal } from "../../../components/ModalContainer";
import ItemDescription from "../marketplace/ItemDescription";
import { openMarketItemDetails } from "../../../redux/lease/leaseActions";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import Balance from "../../../components/Balance";
import { CustomWhiteBtn } from "../../../layout/CustomButtons";
import ProductImagesCarousel from "../../../layout/ProductImagesCarousel";

const AssetBasedSavingsView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		assetBasedSavingsLoading,
		assetBasedSavingsSuccess,
		toggleMarketItemsModal,
	} = useSelector((state) => state.lease);

	const { customerWallet } = useSelector((state) => state.payment);

	const assetBasedWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.assetBasedSavings
	);

	const sortedOngoingTransactions = assetBasedSavingsSuccess?.sort(
		(dataOne, dataTwo) =>
			new Date(dataTwo?.createdAt).getTime() -
			new Date(dataOne?.createdAt).getTime()
	);

	useEffect(() => {
		dispatch(fetchAssetBasedSavings());
	}, []);

	return (
		<>
			{toggleMarketItemsModal && (
				<DesktopSideModal modal={toggleMarketItemsModal}>
					<ItemDescription />
				</DesktopSideModal>
			)}
			<Helmet>
				<title>Fortcore - Asset Based Savings</title>
			</Helmet>
			<MobileNavDetails text="savings" />
			<Container>
				<>
					<Navbar text="leasing" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker text="asset based savings" isCurrentUrl />
					</div>
					<BackTracker
						text="asset based savings"
						onNavigate={() => navigate(-1)}
					/>
					<div className="mt-1 sm:mt-7"></div>
					<div className="mb-7"></div>
					<Balance
						isShowInvestBtn={true}
						btnText="Save Now"
						onRequestClick={() => navigate("/leasing/asset-based-savings")}
						wallet={assetBasedWallet}
						cardTitle="Savings Balance"
					/>
					<div className="mt-6 my-b md:hidden w-full max-w-[400px] sm:max-w-none">
						<CustomWhiteBtn
							text="save now"
							onRequestClick={() => navigate("/leasing/asset-based-savings")}
						/>
					</div>
					<div className="mt-10 mb-5 rounded-md bg-[#F6F7F8] p-5">
						{/* <div className="rounded-md p-4 lg:p-5 !pb-6 bg-[#F6F7F8] space-y-4 sm:space-y-5 lg:space-y-6"> */}
						{/* <p className="text-fortcore-green text-sm md:text-[15px] zabalBoldFont">
							Asset Based Savings
						</p> */}
						<div className="flex flex-col gap-4 lg:gap-5">
							{assetBasedSavingsLoading ? (
								Array(2)
									.fill(0)
									.map((_, i) => <AssetBasedSavingsSkeleton key={i} />)
							) : assetBasedSavingsSuccess?.length > 0 ? (
								sortedOngoingTransactions.map((item, i) => {
									return (
										<AssetSavingsItem
											item={item}
											key={item?.objectId || i}
											showId
											showBtn
										/>
									);
								})
							) : (
								<div className="w-full h-[300px] lg:h-[250px]">
									{/* <div className="col-span-2 sm:col-span-3 md:col-span-4 xl:col-span-5 w-full h-[340px] sm:h-[370px]"> */}
									<EmptyData text="You have not created any asset savings yet." />
								</div>
							)}
						</div>
					</div>
				</>
			</Container>
		</>
	);
};
export default AssetBasedSavingsView;

export const AssetBasedSavingsSkeleton = () => (
	<div className="bg-[#FEFEFE] border border-[#EEF2F3] sm:border-none rounded p-2.5 sm:px-3 sm:py-4 flex flex-col sm:grid md:px-4 lg:px-5 sm:grid-cols-2 lg:flex lg:flex-row gap-4 md:gap-8 lg:gap-12">
		<div className="grid gap-3 grid-cols-2 w-full max-w-[300px]">
			<Skeleton className="w-full h-[75px] max-w-[135px]" />
			<Skeleton className="w-full h-[75px] max-w-[135px]" />
		</div>
		<div className="space-y-3 w-full">
			<div className="flex flex-col gap-0.5">
				<Skeleton className="h-[19px] !w-1/3 max-w-[115px] lg:!max-w-[130px]" />
				<Skeleton className="h-[18px] !w-2/3 max-w-[220px] lg:!max-w-[270px]" />
				<Skeleton className="h-[17px] !w-2/3 max-w-[210px] lg:max-w-[250px]" />
			</div>
			<div className="flex gap-3 flex-wrap h-[35px]">
				<Skeleton className="h-[27px] !w-[75px]" />
				<Skeleton className="h-[27px] !w-[75px]" />
				<Skeleton className="h-[27px] !w-[75px] sm:!hidden md:!block" />
			</div>
		</div>
	</div>
);

export const AssetSavingsItem = ({
	item,
	showId,
	showAmount,
	showBtn,
	showMaturityDate,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const imageArr = [
		item?.productToSaveFor.image,
		item?.productToSaveFor.secondImage || item?.productToSaveFor?.image,
		item?.productToSaveFor.thirdImage,
	].filter((image) => image != undefined);

	return (
		<div
			className={`bg-[#FEFEFE] border border-[#B8C8CF] rounded p-2.5 sm:px-3 sm:py-4 flex flex-col md:px-4 lg:px-5 sm:flex-row gap-4 sm:gap-8 lg:gap-14 ${
				showBtn && "hover:scale-[1.01] transition-transform"
			}`}
		>
			<div className="flex max-w-[300px] sm:max-w-[150px] md:max-w-[250px] lg:max-w-[300px] items-center">
				<ProductImagesCarousel
					imageArr={item?.productObj?.imageArr || imageArr}
					productName={item?.productName || "product-name"}
				/>
			</div>
			<div className="flex justify-between items-center max-w-[400px] sm:max-w-none lg:w-1/2 gap-5">
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-1.5">
						{showAmount && (
							<p className="zabalRegFont font-semibold text-[13px]">
								{formatAmountToIncludeCurrency(
									"NGN",
									item?.amountSaved?.replaceAll(",", "") || 0
								)}
							</p>
						)}
						{showId && (
							<p className="text-xs">
								Transaction ID:{" "}
								<span className="zabalMedFont font-medium text-[13px]">
									{item?.objectId}
								</span>
							</p>
						)}
						<p className="text-[#222627] zabalMedFont text-xs">
							{item?.productObj.productName}
						</p>
						<p className="text-fortcore-green text-[11.5px]">
							<span className="zabalMedFont">
								<span className="text-[#393F42] zabalMedFont">
									Asset Value:{" "}
								</span>
								<span className="zabalRegFont font-semibold">NGN </span>
							</span>{" "}
							<span className="zabalMedFont">
								{formatAmountToIncludeCurrency(
									undefined,
									item?.productObj?.amount
								)}
							</span>
						</p>
						{/* <p className="text-[#393F42] text-[10.5px]">
							<span className="zabalMedFont">Service Life: </span>
							<span className="zabalRegFont capitalize">
								{item?.productObj?.shelfLife}{" "}
								{item?.productObj?.shelfLife > 1 ? "years" : "year"}
							</span>
						</p> */}
						<div className="flex gap-y-1 gap-2 text-[10.5px] flex-wrap">
							<p>
								<span className="zabalRegFont">Date Created: </span>
								<span className="zabalMedFont">
									{moment(item?.createdAt).format("Do MMM YYYY")}
								</span>
							</p>
							<p>
								<span className="zabalRegFont">Time: </span>
								<span className="zabalMedFont">
									{moment(item?.createdAt).format("hh:mmA")}
								</span>
							</p>
						</div>
						{showMaturityDate && (
							<div className="flex gap-2 text-[11px] xl:text-[11.5px]">
								<p>
									<span className="zabalRegFont">Maturity Date: </span>
									<span className="zabalMedFont">
										{moment(item?.maturityDate).format("Do MMMM YYYY")}
									</span>
								</p>
							</div>
						)}
						<button
							className="flex items-center gap-1 text-xs zabalMedFont"
							onClick={() => dispatch(openMarketItemDetails(item?.productObj))}
						>
							<BsFillExclamationCircleFill color="#AABDC5" size={15} />
							<span className="underline text-fortcore-green">Description</span>
						</button>
					</div>
					<div className="flex gap-2">
						{item?.productObj?.tags?.slice(0, 2).map((info, i) => (
							<p
								className="capitalize bg-[#E3E9EC] text-[#393f42] text-[10px] rounded px-2 py-1 zabalRegFont"
								key={i}
							>
								{info}
							</p>
						))}
					</div>
				</div>
				{showBtn && (
					<button
						className="text-fortcore-green p-4"
						onClick={() =>
							navigate(`/leasing/asset-based-savings/${item?.objectId}`)
						}
					>
						<MdKeyboardArrowRight size={24} />
					</button>
				)}
			</div>
		</div>
	);
};

AssetSavingsItem.propTypes = {
	item: PropTypes.object,
	showId: PropTypes.bool,
	showAmount: PropTypes.bool,
	showBtn: PropTypes.bool,
	showMaturityDate: PropTypes.bool,
};
