import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Toolip from "../../../../components/Toolip";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import {
	addAmounts,
	formatAmountToIncludeCurrency,
	onInputAmount,
} from "../../../../common/utils";
import Parse from "../../../../api/ApiConfig";
import { saveFinancialInformation } from "../../../../redux/travels/travelServices";

const FinancialInformation = () => {
	const dispatch = useDispatch();
	const { financialInfoLoading, financialInfoSuccess } = useSelector(
		(state) => state.travel
	);
	// cash
	const [cashInfo, setCashInfo] = useState({
		bankAccounts: financialInfoSuccess?.cash?.bankAccounts || "",
		savings: financialInfoLoading?.cash?.savings || "",
		certificateOfDeposit:
			financialInfoLoading?.cash?.certificateOfDeposit || "",
		otherCash: financialInfoLoading?.cash?.otherCash || "",
	});

	const { bankAccounts, savings, certificateOfDeposit, otherCash } = cashInfo;

	const handleCashChange = (e) =>
		setCashInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
	const totalCash = addAmounts(Object.values(cashInfo));

	// investments
	const [investmentInfo, setInvestmentInfo] = useState({
		securities: financialInfoSuccess?.investment?.securities || "",
		treasuries: financialInfoSuccess?.investment?.treasuries || "",
		lifeInsurance: financialInfoSuccess?.investment?.lifeInsurance || "",
		brokeageAccount: financialInfoSuccess?.investment?.brokeageAccount || "",
		realEstInvestment:
			financialInfoSuccess?.investment?.realEstInvestment || "",
		otherInvestments: financialInfoSuccess?.investment?.otherInvestments || "",
	});

	const {
		securities,
		treasuries,
		lifeInsurance,
		brokeageAccount,
		realEstInvestment,
		otherInvestments,
	} = investmentInfo;

	const handleInvestmentChange = (e) =>
		setInvestmentInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const totalInvestments = addAmounts(Object.values(investmentInfo));

	// personal property
	const [personalProperty, setPersonalProperty] = useState({
		primaryResidence:
			financialInfoSuccess?.personalProperty?.primaryResidence || "",
		automobiles: financialInfoSuccess?.personalProperty?.automobiles || "",
		jewelry: financialInfoSuccess?.personalProperty?.jewelry || "",
		bullion: financialInfoSuccess?.personalProperty?.bullion || "",
		otherProperties:
			financialInfoSuccess?.personalProperty.otherProperties || "",
	});

	const { primaryResidence, automobiles, jewelry, bullion, otherProperties } =
		personalProperty;

	const handlePersonalProperty = (e) =>
		setPersonalProperty((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const totalPersonalProperties = addAmounts(Object.values(personalProperty));

	// retirements
	const [retirements, setRetirements] = useState({
		pensionAccounts: financialInfoSuccess?.retirement?.pensionAccounts || "",
		otherRetirements: financialInfoSuccess?.retirement?.otherRetirements || "",
	});

	const { pensionAccounts, otherRetirements } = retirements;

	const handleRetirements = (e) =>
		setRetirements((prev) => ({
			...prev,
			[e.target.id]: e.target.value,
		}));

	const totalRetirements = addAmounts(Object.values(retirements));

	// notes and accounts
	const [notesAndAccounts, setNotesAndAccounts] = useState({
		notesAndAccountsReceivable:
			financialInfoSuccess?.notesAndAccounts?.notesAndAccountsReceivable || "",
		otherNotes: financialInfoSuccess?.notesAndAccounts?.otherNotes || "",
	});

	const { notesAndAccountsReceivable, otherNotes } = notesAndAccounts;

	const handleNotesAndAccounts = (e) =>
		setNotesAndAccounts((prev) => ({
			...prev,
			[e.target.id]: e.target.value,
		}));

	const totalNotesAndAccounts = addAmounts(Object.values(notesAndAccounts));

	// TOTAL ASSETS
	const totalAssets = addAmounts([
		totalCash,
		totalInvestments,
		totalPersonalProperties,
		totalRetirements,
		totalNotesAndAccounts,
	]);

	// liablities
	const [liablities, setLiabilities] = useState({
		otherLiabilties: financialInfoSuccess?.liablities?.otherLiabilties || "",
		loansOnLife: financialInfoSuccess?.liablities?.loansOnLife || "",
		realEstMort: financialInfoSuccess?.liablities?.realEstMort || "",
		studentLoan: financialInfoSuccess?.liablities?.studentLoan || "",
		unpaidTaxes: financialInfoSuccess?.liablities?.unpaidTaxes || "",
		creditAccount: financialInfoSuccess?.liablities?.creditAccount || "",
		loansOnProfit: financialInfoSuccess?.liablities?.loansOnProfit || "",
		notesAndLoans: financialInfoSuccess?.liablities?.notesAndLoans || "",
		accountPayable: financialInfoSuccess?.liablities?.accountPayable || "",
		investRealEstMort:
			financialInfoSuccess?.liablities?.investRealEstMort || "",
		loansOnRetirement:
			financialInfoSuccess?.liablities?.loansOnRetirement || "",
		monthlyLivingExpenses:
			financialInfoSuccess?.liablities.monthlyLivingExpenses || "",
	});

	const {
		realEstMort,
		investRealEstMort,
		creditAccount,
		studentLoan,
		accountPayable,
		notesAndLoans,
		loansOnLife,
		loansOnProfit,
		loansOnRetirement,
		unpaidTaxes,
		monthlyLivingExpenses,
		otherLiabilties,
	} = liablities;

	const handleLiablities = (e) =>
		setLiabilities((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const totalLiabilities = addAmounts([...Object.values(liablities)]);

	// net worth
	const netWorth =
		Number(totalAssets.replaceAll(",", "")) -
		Number(totalLiabilities.replaceAll(",", ""));

	// is save button disable
	const isBtnDisabled = () => {
		if (bankAccounts === "") {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onFinanceInfoSave = () => {
		const data = {
			notesAndAccounts,
			liablities,
			personalProperty,
			investment: investmentInfo,
			cash: cashInfo,
			retirement: retirements,
			userId: user,
			netWorth: String(netWorth),
		};
		dispatch(saveFinancialInformation(data));
	};

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<p className="text-fortcore-green text-[13.5px] md:text-[14.5px] zabalBoldFont">
				Assets
			</p>
			<div className="space-y-3 md:space-y-4 pb-1.5 sm:pb-2.5 border-b border-[#EEF2F3]">
				<Header text="cash" />
				<FinanceInput
					labelText="Bank Accounts"
					id="bankAccounts"
					value={bankAccounts}
					handleChange={handleCashChange}
				/>
				<FinanceInput
					labelText="Other Savings Accounts"
					id="savings"
					value={savings}
					handleChange={handleCashChange}
				/>
				<FinanceInput
					labelText="CDs (Certificates of deposit)"
					id="certificateOfDeposit"
					value={certificateOfDeposit}
					handleChange={handleCashChange}
				/>
				<FinanceInput
					labelText="Other Cash"
					id="otherCash"
					value={otherCash}
					handleChange={handleCashChange}
				/>
				<div className="flex justify-end gap-10 text-[#393F42] zabalRegFont font-semibold text-[13px] sm:text-[13.5px]">
					<p>Total Cash :</p>
					<p>{totalCash}</p>
				</div>
			</div>
			<div className="space-y-3 md:space-y-4 pb-1.5 sm:pb-2 border-b border-[#EEF2F3]">
				<Header text="investment" />
				<FinanceInput
					labelText="Life Insurance (Cash surrender value)"
					id="lifeInsurance"
					value={lifeInsurance}
					handleChange={handleInvestmentChange}
				/>
				<FinanceInput
					labelText="Brokerage Accounts (Non-retirement)"
					id="brokeageAccount"
					value={brokeageAccount}
					handleChange={handleInvestmentChange}
				/>
				<FinanceInput
					labelText="Securities (Stocks, bonds, mutual funds)"
					id="securities"
					value={securities}
					handleChange={handleInvestmentChange}
				/>
				<FinanceInput
					labelText="Investment Real Estate (Market value)"
					id="realEstInvestment"
					value={realEstInvestment}
					handleChange={handleInvestmentChange}
				/>
				<FinanceInput
					labelText="Treasury Bills/Notes"
					id="treasuries"
					value={treasuries}
					handleChange={handleInvestmentChange}
				/>
				<FinanceInput
					labelText="Other Investments"
					id="otherInvestments"
					value={otherInvestments}
					handleChange={handleInvestmentChange}
				/>
				<div className="flex justify-end gap-10 text-[#393F42] zabalRegFont font-semibold text-[13px] sm:text-[13.5px]">
					<p>Total Investment :</p>
					<p>{totalInvestments}</p>
				</div>
			</div>
			<div className="space-y-3 md:space-y-4 pb-1.5 sm:pb-2 border-b border-[#EEF2F3]">
				<Header text="personal property" />
				<FinanceInput
					labelText="Primary Residence (Market value)"
					id="primaryResidence"
					value={primaryResidence}
					handleChange={handlePersonalProperty}
				/>
				<FinanceInput
					labelText="Automobiles (Present value)"
					id="automobiles"
					value={automobiles}
					handleChange={handlePersonalProperty}
				/>
				<FinanceInput
					labelText="Bullion (Silver, gold, e.t.c)"
					id="bullion"
					value={bullion}
					handleChange={handlePersonalProperty}
				/>
				<FinanceInput
					labelText="Jewelry, Art and Collectibles"
					id="jewelry"
					value={jewelry}
					handleChange={handlePersonalProperty}
				/>
				<FinanceInput
					labelText="Other properties"
					id="otherProperties"
					value={otherProperties}
					handleChange={handlePersonalProperty}
				/>
				<div className="flex justify-end gap-10 text-[#393F42] zabalRegFont font-semibold text-[13px] sm:text-[13.5px]">
					<p>Total Property :</p>
					<p>{totalPersonalProperties}</p>
				</div>
			</div>
			<div className="space-y-3 md:space-y-4 pb-1.5 sm:pb-2 border-b border-[#EEF2F3]">
				<Header text="retirement" />
				<FinanceInput
					labelText="Pension Accounts"
					id="pensionAccounts"
					value={pensionAccounts}
					handleChange={handleRetirements}
				/>
				<FinanceInput
					labelText="Other Assets"
					id="otherRetirements"
					value={otherRetirements}
					handleChange={handleRetirements}
				/>
				<div className="flex justify-end gap-10 text-[#393F42] zabalRegFont font-semibold text-[13px] sm:text-[13.5px]">
					<p>Total Retirement :</p>
					<p>{totalRetirements}</p>
				</div>
			</div>
			<div className="space-y-3 md:space-y-4 pb-1.5 sm:pb-2 border-b border-[#EEF2F3]">
				<Header text="Notes and Accounts Recieveable" />
				<FinanceInput
					labelText="Notes and Accounts Recievable"
					id="notesAndAccountsReceivable"
					value={notesAndAccountsReceivable}
					handleChange={handleNotesAndAccounts}
				/>
				<FinanceInput
					labelText="Other Notes"
					id="otherNotes"
					value={otherNotes}
					handleChange={handleNotesAndAccounts}
				/>
				<div className="flex justify-end gap-10 text-[#393F42] zabalRegFont font-semibold text-[13px] sm:text-[13.5px]">
					<p>Total Receiveable :</p>
					<p>{totalNotesAndAccounts}</p>
				</div>
			</div>
			<div className="flex justify-between items-center p-3.5 rounded-md bg-[#EEF2F3] text-sm md:text-[15px] zabalBoldFont text-fortcore-green">
				<p>Total Assets</p>
				<p>{totalAssets}</p>
			</div>
			<p className="border-b border-[#C6D3D8] pt-3 md:pt-4 pb-1 md:pb-1.5 capitalize zabalMedFont text-[13.5px] md:text-sm text-fortcore-green font-semibold">
				Liabilities
			</p>
			<div className="space-y-3 md:space-y-4 pb-1.5 sm:pb-2">
				<FinanceInput
					labelText="Mortgages on Real Estate"
					id="realEstMort"
					value={realEstMort}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Mortgages on Investment Real Estate"
					id="investRealEstMort"
					value={investRealEstMort}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Credit Accounts, Bills Due, e.t.c "
					id="creditAccount"
					value={creditAccount}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Student Loan"
					id="studentLoan"
					value={studentLoan}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Accounts Payable"
					id="accountPayable"
					value={accountPayable}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Loans and Notes Payable to Bank and Others"
					id="notesAndLoans"
					value={notesAndLoans}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Loans on Life Insurance"
					id="loansOnLife"
					value={loansOnLife}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Loans on Profit Sharing / Pension "
					id="loansOnProfit"
					value={loansOnProfit}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Loans on Retirement Accounts"
					id="loansOnRetirement"
					value={loansOnRetirement}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Unpaid Taxes (Current Year)"
					id="unpaidTaxes"
					value={unpaidTaxes}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Monthly Living Expenses"
					id="monthlyLivingExpenses"
					value={monthlyLivingExpenses}
					handleChange={handleLiablities}
				/>
				<FinanceInput
					labelText="Other Liabilities"
					id="otherLiabilties"
					value={otherLiabilties}
					handleChange={handleLiablities}
				/>
				<div className="flex justify-between items-center p-3.5 rounded-md bg-[#EEF2F3] text-sm md:text-[15px] zabalBoldFont text-fortcore-green">
					<p>Total Liabilities</p>
					<p>{totalLiabilities}</p>
				</div>
			</div>
			<div className="flex justify-between items-center my-3 p-3 rounded-md border border-[#E3E9EC] text-[13.5px] md:text-sm zabalMedFont text-[#393F42]">
				<p>
					Net Worth <span className="zabalRegFont">(Assets - Liabilities)</span>
				</p>
				<p>{formatAmountToIncludeCurrency(undefined, netWorth)}</p>
			</div>
			<div className="bg-[#F6F7F8] rounded-md p-3">
				<p className="border-b border-[#C6D3D8] pb-1 md:pb-1.5 md:pt-2 text-sm md:text-[15px] zabalBoldFont text-fortcore-green">
					Common Financial Ratios
				</p>
				<div className="flex justify-between gap-5 text-[13px] md:text-[13.5px] text-[#393F42] border-b border-[#EEF2F3] py-4 md:py-5 zabalMedFont">
					<div className="space-x-1">
						Debt to Assets Ratio{" "}
						<span className="zabalRegFont text-xs">
							(Total Liabilities / Total Assets)
						</span>{" "}
						:
						<Toolip />
					</div>
					<p>63%</p>
				</div>
				<div className="flex justify-between gap-5 text-[13px] md:text-[13.5px] text-[#393F42] border-b border-[#EEF2F3] py-4 md:py-5 zabalMedFont">
					<div className="space-x-1">
						Basic Liquidity Ratio{" "}
						<span className="zabalRegFont text-xs">
							(Liquid Assets / Monthly Living Expenses)
						</span>{" "}
						:
						<Toolip />
					</div>
					<p>4</p>
				</div>
				<div className="flex justify-between gap-5 text-[13px] md:text-[13.5px] text-[#393F42] pt-4 md:pt-5 pb-2 zabalMedFont">
					<div className="space-x-1">
						Investment Assets to Net Worth Ratio{" "}
						<span className="zabalRegFont text-xs">
							(Investment Assets / Net Worth)
						</span>{" "}
						:
						<Toolip />
					</div>
					<p>114%</p>
				</div>
			</div>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || financialInfoLoading}
					isLoading={financialInfoLoading}
					onRequestClick={onFinanceInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || financialInfoLoading}
					isLoading={financialInfoLoading}
					onRequestClick={onFinanceInfoSave}
				/>
			</div>
		</div>
	);
};

export default FinancialInformation;

const FinanceInput = ({
	labelText,
	toolipText,
	id,
	value,
	handleChange,
	type,
}) => {
	return (
		<div className="flex flex-col sm:flex-row gap-2 sm:gap-7 md:gap-8 lg:gap-10">
			<div className="space-x-1 text-xs sm:text-[12.5px] md:text-[13px] w-full zabalMedFont sm:w-[40%] text-[#393F42]">
				<label htmlFor={id} className="capitalize">
					{labelText} :
				</label>
				<Toolip text={toolipText} />
			</div>
			<div className="sm:w-[60%]">
				<input
					className="bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5 h-[41px] text-end zabalRegFont"
					id={id}
					type={type ? "number" : "tel"}
					value={value}
					onChange={handleChange}
					placeholder="1.00"
					onInput={onInputAmount}
				/>
			</div>
		</div>
	);
};

FinanceInput.propTypes = {
	labelText: PropTypes.string,
	toolipText: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	type: PropTypes.string,
	handleChange: PropTypes.func,
};

const Header = ({ text }) => {
	return (
		<p className="border-b border-[#C6D3D8] pt-2 pb-1 md:pb-1.5 capitalize zabalMedFont text-[13.5px] md:text-sm text-[#393F42]">
			{text}
		</p>
	);
};

Header.propTypes = {
	text: PropTypes.string,
};
