import React, { useEffect, useRef } from "react";
import PinInput from "react-pin-input";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import PropTypes from "prop-types";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";

export const EnterPin = ({
	btnText,
	onCloseBtnClick,
	onRequestClick,
	onInputChange,
	isPinInvalid,
	isAllowedToProceed,
	isPinExist,
	proceedToCreatePin,
	isUpdateOldPin,
	isPaymentLoading,
}) => {
	const pinRef = useRef(null);
	const onClear = () => {
		onInputChange("");
		pinRef.current.clear();
	};

	useEffect(() => {
		onClear();
	}, []);

	return (
		<div className="bg-white grid gap-4 md:gap-5 rounded-xl pt-5 px-6 md:px-8 md:pt-6 pb-7 w-10/12 max-w-[292px] md:max-w-[310px] mx-auto">
			<button
				className="justify-self-end text-fortcore-green"
				onClick={onCloseBtnClick}
			>
				<MdOutlineClose size={20} />
			</button>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-2">
					<p className="zabalBoldFont text-sm text-fortcore-green">
						Enter Your Pin
					</p>
					{isPinInvalid && (
						<p className="text-xs zabalRegFont text-[#FF4A4A]">
							Input correct transaction pin.
						</p>
					)}
					{!isPinExist && (
						<p className="text-xs zabalRegFont text-red-500">
							Please <span className="zabalBoldFont">create</span> a pin for
							your transactions
						</p>
					)}
				</div>
				<PinInput
					length={4}
					initialValue=""
					ref={pinRef}
					secret
					// secretDelay={300}
					type="numeric"
					inputMode="numeric"
					inputStyle={{
						borderColor: isPinInvalid
							? "#FF4A4A"
							: isAllowedToProceed
							? "#00525D"
							: "#AABDC5",
						borderRadius: "6px",
						marginRight: "9px",
					}}
					onChange={(v) => onInputChange(v)}
					disabled={!isPinExist}
				/>
			</div>
			<div className="flex flex-col gap-3">
				<CustomGreenBtn
					text={btnText}
					onRequestClick={onRequestClick}
					fontSize="14px"
					isDisabled={isAllowedToProceed === false || isPaymentLoading}
					isLoading={isPaymentLoading}
				/>
				{isUpdateOldPin && isPinExist ? null : (
					<button
						className="self-center text-center text-[13.5px] zabalMedFont font-semibold text-fortcore-green"
						onClick={proceedToCreatePin}
						disabled={isPaymentLoading}
					>
						{!isPinExist ? "Create" : "Edit"} Access Pin?
					</button>
				)}
			</div>
		</div>
	);
};

export const CreatePin = ({
	btnText,
	onCloseBtnClick,
	onRequestClick,
	onInputChange,
	isAllowedToProceed,
}) => {
	const pinRef = useRef(null);
	const onClear = () => {
		onInputChange("");
		pinRef.current.clear();
	};

	useEffect(() => {
		onClear();
	}, []);

	return (
		<div className="bg-white grid gap-4 md:gap-5 rounded-xl pt-5 px-6 md:px-8 md:pt-6 pb-7 w-10/12 max-w-[292px] md:max-w-[310px] mx-auto">
			<button
				className="justify-self-end text-fortcore-green"
				onClick={onCloseBtnClick}
			>
				<MdOutlineClose size={20} />
			</button>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-2">
					<p className="zabalBoldFont text-sm text-fortcore-green">
						Enter New Pin
					</p>
				</div>
				<PinInput
					length={4}
					initialValue=""
					ref={pinRef}
					secret
					// secretDelay={300}
					type="numeric"
					inputMode="numeric"
					inputStyle={{
						borderColor: "#AABDC5",
						borderRadius: "6px",
						marginRight: "9px",
					}}
					inputFocusStyle={{ borderColor: "#00525D" }}
					onChange={(v) => onInputChange(v)}
				/>
			</div>
			<CustomGreenBtn
				text={btnText}
				onRequestClick={onRequestClick}
				fontSize="14px"
				isDisabled={isAllowedToProceed === false}
			/>
		</div>
	);
};

export const ConfirmPin = ({
	btnText,
	onCloseBtnClick,
	onRequestClick,
	onInputChange,
	isAllowedToProceed,
	isPinInvalid,
}) => {
	const { accessPinLoading } = useSelector((state) => state.auth);

	const pinRef = useRef(null);
	const onClear = () => {
		onInputChange("");
		pinRef.current.clear();
	};

	useEffect(() => {
		onClear();
	}, []);

	return (
		<div className="bg-white grid gap-4 md:gap-5 rounded-xl pt-5 px-6 md:px-8 md:pt-6 pb-7 w-10/12 max-w-[292px] md:max-w-[310px] mx-auto">
			<button
				className="justify-self-end text-fortcore-green"
				onClick={onCloseBtnClick}
			>
				<MdOutlineClose size={20} />
			</button>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-2">
					<p className="zabalBoldFont text-sm text-fortcore-green">
						Confirm New Pin
					</p>
					{isPinInvalid && (
						<p className="text-xs zabalRegFont text-[#FF4A4A]">
							Pin does not match
						</p>
					)}
				</div>
				<PinInput
					length={4}
					ref={pinRef}
					initialValue=""
					secret
					// secretDelay={300}
					type="numeric"
					inputMode="numeric"
					inputStyle={{
						borderColor: isPinInvalid
							? "#FF4A4A"
							: isAllowedToProceed
							? "#00525D"
							: "#AABDC5",
						borderRadius: "6px",
						marginRight: "9px",
					}}
					onChange={(v) => onInputChange(v)}
				/>
			</div>
			<CustomGreenBtn
				text={btnText}
				onRequestClick={onRequestClick}
				fontSize="14px"
				isDisabled={isAllowedToProceed === false || accessPinLoading}
				isLoading={accessPinLoading}
			/>
		</div>
	);
};

EnterPin.propTypes = {
	btnText: PropTypes.string,
	onCloseBtnClick: PropTypes.func,
	onRequestClick: PropTypes.func,
	onInputChange: PropTypes.func,
	isPinInvalid: PropTypes.bool,
	isAllowedToProceed: PropTypes.bool,
	isUpdateOldPin: PropTypes.bool,
	isPinExist: PropTypes.bool,
	proceedToCreatePin: PropTypes.func,
	isPaymentLoading: PropTypes.bool,
};
CreatePin.propTypes = {
	btnText: PropTypes.string,
	onCloseBtnClick: PropTypes.func,
	onRequestClick: PropTypes.func,
	onInputChange: PropTypes.func,
	isAllowedToProceed: PropTypes.bool,
};
ConfirmPin.propTypes = {
	btnText: PropTypes.string,
	onCloseBtnClick: PropTypes.func,
	onRequestClick: PropTypes.func,
	onInputChange: PropTypes.func,
	isAllowedToProceed: PropTypes.bool,
	isPinInvalid: PropTypes.bool,
};
