import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Navbar from "../../layout/Navbar";
import Balance from "../../components/Balance";
import Container from "../../layout/Container";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import {
	ActiveInvestmentHeader,
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../../layout/CustomButtons";
import Summary from "./chart/Summary";
import {
	deleteInactiveInvestment,
	fetchLeaseBoardData,
	updateInvestorCreatedAsset,
} from "../../redux/lease/leaseServices";
import EmptyData from "../../components/EmptyData";
import {
	clearPinCreationSuccess,
	clearStatesOnConfirmLease,
} from "../../redux/authRedux/authActions";
import ModalContainer from "../../components/ModalContainer";
import {
	closeActivateLeaseBoardModal,
	closeDeleteInactiveInvestmentModal,
	openActivateLeaseBoardModal,
	openDeleteInactiveInvestmentModal,
} from "../../redux/lease/leaseActions";
import {
	ConfirmPin,
	CreatePin,
	EnterPin,
} from "../../components/payments/PinComponent";
import {
	createAccessPin,
	logoutUser,
} from "../../redux/authRedux/authServices";
import {
	formatProductWeight,
	// getAmountWithDurationAndInterest,
	walletType,
} from "../../common/utils";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import {
	clearMoveFundsSuccess,
	clearPaymentDeductionSuccess,
} from "../../redux/payments/paymentActions";
import {
	deductPaymentFromUserWallet,
	moveUserFunds,
} from "../../redux/payments/paymentServices";
import Parse from "../../api/ApiConfig";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import LeaseRoi from "./LeaseRoi";

const LeaseInvestBoard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState(true);

	const [activeModal, setActiveModal] = useState(0);
	const [inputPin, setInputPin] = useState("");
	const [createPin, setCreatePin] = useState("");
	const [confirmPin, setConfirmPin] = useState("");
	const [investorPinStatus, setInvestorPinStatus] = useState("");
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [invalidConfirmPinError, setInvalidConfirmPinError] = useState(false);

	const currentSessionToken = Parse.User.current()?.getSessionToken();
	const user = Parse.User.current();

	const {
		leaseBoardDataLoading,
		leaseBoardDataSuccess,
		inactiveData,
		activeData,
		toggleActivateLeaseBoardInvestment,
		currentLeaseBoardInvestment,
		toggleDeleteInactiveInvestmentModal,
		inactiveAssetDeleteLoading,
		inactiveAssetDeleteSuccess,
		inactiveInvestmentToDelete,
		updateCreatedAssetLoading,
		updateCreatedAssetSuccess,
	} = useSelector((state) => state.lease);
	const { currentUser, accessPinSuccess } = useSelector((state) => state.auth);
	const {
		customerWallet,
		deductWalletLoading,
		deductWalletSuccess,
		moveFundsLoading,
		moveFundsSuccess,
		walletDetails,
	} = useSelector((state) => state.payment);

	useEffect(() => {
		dispatch(fetchLeaseBoardData());
		dispatch(clearStatesOnConfirmLease());
		dispatch(closeActivateLeaseBoardModal());
		dispatch(closeDeleteInactiveInvestmentModal());
	}, []);

	const leaseInvestmentWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.leaseInvestment
	);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (accessPinSuccess) {
			setActiveModal(2);
			setInvestorPinStatus("pin creation success");
		}
	}, [accessPinSuccess]);

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	useEffect(() => {
		if (confirmPin.length < 4) {
			return;
		} else if (confirmPin !== createPin) {
			setInvalidConfirmPinError(true);
			setTimeout(() => {
				setInvalidConfirmPinError(false);
			}, 1500);
		}
	}, [confirmPin]);

	const onCloseActivateLeaseModal = () => {
		dispatch(closeActivateLeaseBoardModal());
		setActiveModal(1);
	};

	const onProceedToEnterPin = () => {
		setActiveModal(2);
		setInvestorPinStatus("");
	};

	const onProceedToCreatePin = () => {
		setActiveModal(2);
		setInvestorPinStatus("create-pin");
	};

	const onCloseBtnClickFromCreatePin = () => {
		setActiveModal(2);
		setInvestorPinStatus("");
	};

	const onProceedToConfirmPin = () => {
		setActiveModal(2);
		setInvestorPinStatus("confirm-pin");
	};

	const onCreatePin = () => {
		if (user.id) {
			dispatch(createAccessPin({ accessPin: confirmPin }));
		} else {
			dispatch(logoutUser());
		}
	};

	const onPinCreationSuccess = () => {
		setActiveModal(2);
		setInvestorPinStatus("");
		dispatch(clearPinCreationSuccess());
	};

	const onCloseBtnClickFromConfirmPin = () => {
		setActiveModal(2);
		setInvestorPinStatus("create-pin");
	};

	const sortedInactiveAssets = inactiveData?.sort(
		(dataOne, dataTwo) =>
			new Date(dataTwo.createdAt).getTime() -
			new Date(dataOne.createdAt).getTime()
	);

	const sortedActiveAssets = activeData?.sort(
		(dataOne, dataTwo) =>
			new Date(dataTwo.createdAt).getTime() -
			new Date(dataOne.createdAt).getTime()
	);

	const onCloseDeleteAssetSuccess = () => {
		dispatch(closeDeleteInactiveInvestmentModal());
		dispatch(fetchLeaseBoardData());
	};

	const fee = process.env.NODE_ENV !== "production" ? 1 : 100;

	const investorInfo = currentLeaseBoardInvestment?.investorsArray?.find(
		(investor) => investor?.user === user?.id
	);

	const investmentAmount = investorInfo?.amountToInvest;
	const amountPayable = fee + investmentAmount;

	const activeInfoText =
		investorInfo?.slots < 4
			? `NGN ${amountPayable.toLocaleString()} ( N${fee} "activation fee" ) will be deducted from your wallet to partially activate this asset.`
			: `NGN ${amountPayable.toLocaleString()} ( N${fee} "activation fee" ) will be deducted from your wallet to fully activate and list this asset.`;

	const onProceedToDeductWallet = () => {
		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const details = {
			walletId: walletDetails?.id,
			amount: fee,
			narration: "asset-activation-fee",
		};

		if (user.id) {
			dispatch(
				deductPaymentFromUserWallet(
					details,
					"asset-activation-fee-successful",
					"asset activation"
				)
			);
		} else {
			dispatch(logoutUser());
		}
	};

	useEffect(() => {
		if (deductWalletSuccess === "asset-activation-fee-successful") {
			dispatch(clearPaymentDeductionSuccess());

			const details = {
				amount: String(investmentAmount),
				source: "main",
				destination: walletType.leaseInvestment,
				message: "investor-slot-payment-for-created-asset",
			};

			dispatch(moveUserFunds(currentSessionToken, details));
		} else {
			return;
		}
	}, [deductWalletSuccess]);

	const message = "Asset activation successful.";
	const metaData = {
		category: "Asset",
		linkToId: currentLeaseBoardInvestment?.objectId,
		moreDetails: `The amount of N${fee} has been deducted for activation and N${investmentAmount} has been invested in the asset.`,
	};

	useEffect(() => {
		if (moveFundsSuccess === "investor-slot-payment-for-created-asset") {
			dispatch(clearMoveFundsSuccess());

			const updatedInvestorArr =
				currentLeaseBoardInvestment?.investorsArray.map((a) => {
					if (a.user === user.id) {
						return { ...a, paid: true };
					} else {
						return a;
					}
				});

			const paidSlots =
				currentLeaseBoardInvestment?.paidSlots + investorInfo?.slots;

			const data = {
				investorsArray: updatedInvestorArr,
				paidSlots,
				status: paidSlots < 4 ? "inactive" : "active",
			};

			dispatch(
				updateInvestorCreatedAsset(
					currentLeaseBoardInvestment?.objectId,
					data,
					message,
					metaData,
					undefined
				)
			);
		} else {
			return;
		}
	}, [moveFundsSuccess]);

	useEffect(() => {
		if (updateCreatedAssetSuccess) {
			setActiveModal(3);
		} else {
			return;
		}
	}, [updateCreatedAssetSuccess]);

	const onCloseActivateAssetSuccess = () => {
		dispatch(fetchLeaseBoardData());
		dispatch(closeActivateLeaseBoardModal());
		setTimeout(() => {
			setActiveModal(1);
		}, 500);
	};

	return (
		<>
			{toggleActivateLeaseBoardInvestment && (
				<ModalContainer modal={toggleActivateLeaseBoardInvestment}>
					<>
						{activeModal === 1 && (
							<CustomConfirmModal
								isNotify={true}
								text={activeInfoText}
								greenBtnText="proceed"
								greenBtnClick={onProceedToEnterPin}
								onCloseBtnClick={onCloseActivateLeaseModal}
							/>
						)}
						{activeModal === 2 && investorPinStatus === "" && (
							<EnterPin
								btnText="proceed"
								onCloseBtnClick={onCloseActivateLeaseModal}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								isPinExist={currentUser?.accessPin !== undefined}
								proceedToCreatePin={onProceedToCreatePin}
								onRequestClick={onProceedToDeductWallet}
								isPaymentLoading={
									deductWalletLoading ||
									moveFundsLoading ||
									updateCreatedAssetLoading
								}
							/>
						)}
						{activeModal === 2 && investorPinStatus === "create-pin" && (
							<CreatePin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromCreatePin}
								onInputChange={setCreatePin}
								onRequestClick={onProceedToConfirmPin}
								isAllowedToProceed={createPin.length > 3}
							/>
						)}
						{activeModal === 2 && investorPinStatus === "confirm-pin" && (
							<ConfirmPin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromConfirmPin}
								onInputChange={setConfirmPin}
								onRequestClick={onCreatePin}
								isAllowedToProceed={
									confirmPin.length > 3 && createPin === confirmPin
								}
								isPinInvalid={invalidConfirmPinError}
							/>
						)}
						{activeModal === 2 &&
							investorPinStatus === "pin creation success" && (
								<SuccessCheckModal
									onRequestClick={onPinCreationSuccess}
									text={`Access pin has been ${
										accessPin ? "updated" : "created"
									} successfully!`}
									isShowBtn={true}
									btnText="okay"
								/>
							)}
						{activeModal === 3 && (
							<SuccessCheckModal
								text={`The asset with the id of ${
									currentLeaseBoardInvestment?.objectId
								} has been activated ${
									investorInfo?.slots < 4
										? "successfully. Other investors will be notified."
										: "and listed successfully."
								} `}
								onRequestClick={onCloseActivateAssetSuccess}
							/>
						)}
					</>
				</ModalContainer>
			)}
			{toggleDeleteInactiveInvestmentModal && (
				<ModalContainer modal={toggleDeleteInactiveInvestmentModal}>
					{inactiveAssetDeleteSuccess ? (
						<SuccessCheckModal
							text={`Asset "${inactiveInvestmentToDelete}" has been deleted successfully.`}
							onRequestClick={onCloseDeleteAssetSuccess}
						/>
					) : (
						<CustomConfirmModal
							text="Are you sure you want to delete this asset ?"
							onCloseBtnClick={() =>
								dispatch(closeDeleteInactiveInvestmentModal())
							}
							greenBtnText="Yes, Delete"
							isLoading={inactiveAssetDeleteLoading}
							greenBtnClick={() =>
								dispatch(deleteInactiveInvestment(inactiveInvestmentToDelete))
							}
						/>
					)}
				</ModalContainer>
			)}
			<MobileNavDetails text="lease investment board" />
			<Helmet>
				<title>Fortcore - Lease Investment Board</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="lease investment board" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() =>
								navigate("/leasing", { state: { currentTab: 2 } })
							}
						/>
						<UrlTracker text="lease investment board" isCurrentUrl />
					</div>
					<BackTracker
						text="lease investment board"
						onNavigate={() => navigate(-1)}
					/>
					<div className="mt-1 sm:mt-7"></div>
					<Balance
						isShowInvestBtn={true}
						onRequestClick={() =>
							navigate("/leasing/new-transaction/investor-market-place")
						}
						wallet={leaseInvestmentWallet}
						cardTitle="Lease Investment Balance"
					/>
					<div className="mt-6 md:hidden w-full max-w-[400px] sm:max-w-none">
						<CustomWhiteBtn
							text="invest"
							onRequestClick={() =>
								navigate("/leasing/new-transaction/investor-market-place")
							}
						/>
					</div>
					<div className="my-10 sm:mt-12 md:mt-14 lg:mt-16 space-y-6 md:space-y-7">
						{leaseBoardDataLoading ? (
							<CardSkeleton />
						) : (
							leaseBoardDataSuccess?.length > 0 &&
							inactiveData?.length > 0 && (
								<div className="space-y-2 md:space-y-3">
									<p className="text-[14.5px] md:text-base font-semibold text-fortcore-green zabalMedFont">
										Inactive Investments
									</p>
									<div className="flex gap-4 md:gap-5 lg:gap-6 scroll-wrapper">
										{sortedInactiveAssets.map((investment) => (
											<LeaseInvestmentCard
												key={investment?.objectId}
												investment={investment}
												setActiveModal={setActiveModal}
											/>
										))}
									</div>
								</div>
							)
						)}
						{leaseBoardDataLoading ? (
							<CardSkeleton />
						) : (
							leaseBoardDataSuccess?.length > 0 &&
							activeData?.length > 0 && (
								<div className="space-y-2 md:space-y-3">
									<div className="flex gap-4 sm:gap-6 items-center">
										<ActiveInvestmentHeader
											text="Active Investments"
											isActive={activeTab}
											onClick={() => setActiveTab(true)}
										/>
										<div className="border-r h-3"></div>
										<ActiveInvestmentHeader
											text="Return On Investments (ROI)"
											isActive={activeTab === false}
											onClick={() => setActiveTab(false)}
										/>
									</div>
									{activeTab ? (
										<div className="flex gap-4 md:gap-5 lg:gap-6 scroll-wrapper">
											{sortedActiveAssets.map((investment) => (
												<LeaseInvestmentCard
													key={investment?.objectId}
													investment={investment}
												/>
											))}
										</div>
									) : (
										<LeaseRoi data={sortedActiveAssets} />
									)}
								</div>
							)
						)}
						{activeData?.length > 0 && (
							<div className="space-y-2 md:space-y-3">
								<p className="text-[13.5px] md:text-sm font-semibold text-fortcore-green zabalMedFont">
									Balance Summary
								</p>
								<Summary />
							</div>
						)}
						{!leaseBoardDataLoading && leaseBoardDataSuccess?.length < 1 && (
							<div className="w-full h-[250px]">
								<EmptyData text="You do not have any investments" />
							</div>
						)}
					</div>
				</>
			</Container>
		</>
	);
};
export default LeaseInvestBoard;

const LeaseInvestmentCard = ({ investment, setActiveModal }) => {
	const dispatch = useDispatch();
	const user = Parse.User.current();

	const { productObj, amountToInvest } = investment;

	const onBeginActivationProcess = () => {
		dispatch(openActivateLeaseBoardModal(investment));
		setActiveModal(1);
	};
	const productWeight = formatProductWeight(productObj?.specifications);

	const investorInfo = investment?.investorsArray?.find(
		(investor) => investor?.user === user?.id
	);
	// console.log(investorInfo);

	return (
		<div
			className={`rounded-md px-3 py-3.5 sm:px-3.5 sm:py-4 border flex gap-3.5 md:gap-4 min-w-[340px] sm:min-w-[355px] md:min-w-[365px] relative ${
				investorInfo?.paid === true
					? "border-[#EEF2F3] bg-[#EFF8F9] indexBalance"
					: "bg-[#F6F8F980] border-[#D5DEE2]"
			}`}
		>
			{investorInfo?.paid !== true && (
				<button
					className="text-red-600 pr-1 absolute right-3 top-2"
					onClick={() =>
						dispatch(openDeleteInactiveInvestmentModal(investment?.objectId))
					}
				>
					<MdDelete size={18} />
				</button>
			)}
			<div
				className={`w-[calc(100%-92px)] grid mt-6 ${
					productWeight ? "gap-7" : "gap-10"
				}`}
			>
				<div className="relative">
					<p className="relative capitalize text-[#222627] text-[13px] sm:text-[13.5px] font-semibold zabalMedFont">
						{productObj?.productName}
					</p>
					<small className="text-[11px] text-[#8E9DA4] zabalRegFont">
						{productWeight}
					</small>
					{investorInfo?.paid === true && (
						<Link
							to={`/leasing/investments/${investment?.objectId}`}
							className="absolute mt-1 sm:mt-1.5 border-b-2 flex gap-0.5 items-center text-xs text-primary-color zabalMedFont tracking-wider blink"
							state={{ isLeaseReq: investment?.state }}
						>
							<span>View</span> <FaArrowCircleRight size={14} />
						</Link>
					)}
				</div>
				<div className="self-end flex gap-2.5 lg:gap-4 zabalMedFont">
					{investorInfo?.paid === true && (
						<p className="w-[52%] text-[#393F42] flex flex-col gap-1">
							<span className="text-[11px]">Asset Value</span>
							<span className="text-xs sm:text-[12.5px]">
								<span className="font-semibold">NGN </span>
								{Number(productObj?.amount).toLocaleString()}
							</span>
						</p>
					)}
					<p className="w-[48%] text-[#393F42] flex flex-col gap-1">
						<span className="text-[11px]">Principal</span>
						<span className="text-xs sm:text-[12.5px]">
							<span className="font-semibold">NGN </span>
							{investorInfo?.amountToInvest || amountToInvest}
						</span>
					</p>
					{investorInfo?.paid !== true && (
						<p className="w-[48%] text-[#393F42] flex flex-col gap-1">
							<span className="text-[11px]">Interest</span>
							<span className="text-xs sm:text-[12.5px]">
								<span className="font-semibold">NGN </span>
								{investorInfo?.interestToEarn?.toLocaleString(undefined, {
									maximumFractionDigits: 2,
								})}
							</span>
						</p>
					)}
				</div>
			</div>
			{investorInfo?.paid !== true ? (
				<div className="w-[92px] flex flex-col gap-1.5 justify-end items-end">
					<p className="rounded-md border border-[#EB948121] text-[13.5px] bg-white text-[#FF7676] flex items-center justify-center gap-2 px-1.5 py-2 w-[90px]">
						<BsFillExclamationTriangleFill size={17} />
						<span>Inactive</span>
					</p>
					<CustomGreenBtn
						text="activate"
						width="90px"
						height="37px"
						fontSize="12.25px"
						onRequestClick={onBeginActivationProcess}
					/>
				</div>
			) : (
				<div className="w-[92px] flex flex-col justify-between items-end zabalMedFont">
					<img
						src={productObj?.imageArr[0]}
						alt="asset-logo"
						className="w-auto h-[55px]"
					/>
					<p className="text-[#393F42] flex flex-col gap-1 w-full">
						<span className="text-[11px]">Interest</span>
						<span className="text-xs sm:text-[12.5px]">
							<span className="font-semibold">NGN </span>
							{investorInfo?.interestToEarn?.toLocaleString(undefined, {
								maximumFractionDigits: 2,
							})}
						</span>
					</p>
				</div>
			)}
		</div>
	);
};

LeaseInvestmentCard.propTypes = {
	investment: PropTypes.object,
	setActiveModal: PropTypes.func,
};

export const CardSkeleton = ({ showHeader = true }) => {
	return (
		<div className="flex flex-col gap-2.5">
			{showHeader && (
				<div>
					<Skeleton className="h-[19px] md:h-[20px] xl:h-[21px] w-3/5 !max-w-[200px] md:!max-w-[220px] lg:max-w-[230px] xl:max-w-[240px]" />
				</div>
			)}
			<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-4.5 xl:gap-5">
				<Skeleton className="h-[120px] lg:h-[122px] xl:h-[125px] w-full" />
				<div className="hidden sm:block">
					<Skeleton className="h-[120px] lg:h-[122px] xl:h-[125px] w-full" />
				</div>
				<div className="hidden md:block">
					<Skeleton className="h-[120px] lg:h-[122px] xl:h-[125px] w-full" />
				</div>
			</div>
		</div>
	);
};

CardSkeleton.propTypes = {
	showHeader: PropTypes.bool,
};
