import React, { useState } from 'react'
import { MobileNavDetails } from '../../layout/MobileNavbar'
import { Helmet } from 'react-helmet'
import Container from '../../layout/Container'
import Navbar from '../../layout/Navbar'
import { BackTracker, UrlTracker } from '../../layout/CustomComponents'
import { useNavigate } from 'react-router-dom'
import { MarketTab } from '../leasing/marketplace/MarketPlace'
import CompletePersonalForm from './sections/CompletePersonalForm'
import CompleteBusinessForm from './sections/CompleteBusinessForm'
import CompleteInvestorForm from './sections/CompleteInvestorForm'

const CompleteYourProfile = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(1)

  return (
    <>
      <MobileNavDetails text='profile' />
      <Helmet>
        <title>Fortcore - Profile</title>
      </Helmet>
      <Container>
        <>
          <Navbar text="dashboard" />
          <div className="sm:pt-9 md:pt-10 "></div>
          <div className="flex gap-1.5">
            <UrlTracker
              text="dashboard"
              onNavigate={() => navigate("/dashboard")}
            />
            <UrlTracker text="profile" onNavigate={() => navigate("/dashboard/profile")} />
            <UrlTracker text="complete your profile" isCurrentUrl />
            <BackTracker text='dashboard' onNavigate={() => navigate(-1)} />
          </div>
          <div className='rounded-lg p-4 sm:p-5 lg:p-6 bg-[#F6F7F8] space-y-5 sm:mt-6 mb-8'>
            <p className="chillaxFont font-bold text-primary-color text-[15px] md:text-base space-y-4 md:space-y-5">Complete Your Profile</p>
            <div className="border-b border-[#D4DEE2]">
              <div className='flex justify-between max-w-[400px] sm:md:max-w-[450px'>
                <MarketTab text='personal' isCurrentTab={activeTab === 1} onTabChange={() => setActiveTab(1)} />
                <MarketTab text='business' isCurrentTab={activeTab === 2} onTabChange={() => setActiveTab(2)} />
                <MarketTab text='investor' isCurrentTab={activeTab === 3} onTabChange={() => setActiveTab(3)} />
              </div>
            </div>
            {activeTab === 1 && <CompletePersonalForm />}
            {activeTab === 2 && <CompleteBusinessForm />}
            {activeTab === 3 && <CompleteInvestorForm />}
          </div>
        </>
      </Container>
    </>
  )
}

export default CompleteYourProfile