import React from "react"
import PropTypes from 'prop-types'

const Container = ({ children }) => {
  return (
    <div className="w-full relative sm:left-[155px] md:left-[170px] lg:left-[185px] xl:left-[200px] sm:w-[calc(100%-155px)] md:w-[calc(100%-170px)] lg:w-[calc(100%-185px)] xl:w-[calc(100%-200px)] relative px-4 sm:px-6 md:px-8 lg:px-10 py-4 sm:py-8 max-w-[1440px]">
      {children}
    </div>
  )
}

export default Container

Container.propTypes = {
  children: PropTypes.any
}