import React from "react";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { string } from "prop-types";

const Toolip = ({ text }) => {
	return (
		<div className="tooltip relative z-[5] inline-block top-0.5">
			<BsFillExclamationCircleFill color="#00525D" size={14} />
			<span className="tooltiptext invisible z-[5] absolute text-xs text-fortcore-green bg-fortcore-green text-white py-1.5 px-2.5 zabalRegFont rounded-md w-[170px] bottom-[120%] -left-[70px] ">
				{text}
			</span>
		</div>
	);
};

export default Toolip;

Toolip.propTypes = {
	text: string,
};
