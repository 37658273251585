import React from "react";
import { FatBackTracker } from "../../layout/CustomComponents";
import PropTypes from "prop-types";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import { useNavigate } from "react-router-dom";

const Criteria = ({ setActiveTab, signature, handleChange }) => {
	const date = new Date().toDateString();

	const navigate = useNavigate();

	const isBtnValid = () => {
		if (signature.length < 3) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className="space-y-4 sm:space-y-5">
			<div className="space-y-1.5">
				<FatBackTracker
					text="guarantor's criteria"
					onNavigate={() => navigate(-1)}
				/>
				<p className="text-xs md:text-[12.25px] text-[#717E83] zabalRegFont ">
					Please read and understand the certification below.
				</p>
			</div>
			<div className="text-sm rounded-md bg-[#F6F7F8] px-3 sm:px-5 pb-6 sm:pb-7 text-[#393F42] zabalRegFont text-justify leading-6 py-4 md:py-5 space-y-5">
				<div>
					<p>
						NOTE: the person to be used must be above 18 and must be any of the
						following:
					</p>
					<ul className="list-decimal px-4">
						<li>
							Senior civil servant not below grade 8 in the Public Service.
						</li>
						<li>A banker not less than a Senior Banking Officer.</li>
						<li>
							A practicing professional such as a Doctor, Pilot, Engineer, etc.
						</li>
						<li>A well known religious community leader acceptable to us.</li>
						<li>A trader registered with the appropriate trade union.</li>
						<li>Lawyers or any security personnel are not allowed.</li>
					</ul>
				</div>
				<div>
					<p className="sm:flex gap-1">
						<span className="zabalMedFont flex items-center text-red-500">
							<TbAlertTriangleFilled size={16} /> CAUTION:
						</span>{" "}
						<span>Do not use someone you do not know as a guarantor.</span>
					</p>
					<p>
						I HAVE READ THE CERTIFICATION ABOVE AND UNDERSTAND AND AGREE TO ITS
						TERMS.
					</p>
					<p className="text-red-500">Please type your full name and date.</p>
				</div>
				<div className="pt-5 w-[300px] space-y-1 relative">
					<input
						type="text"
						className={`bg-transparent px-1 placeholder:italic text-black text-[15px] w-full focus:border-fortcore-green pr-[125px] border-b-2`}
						placeholder="Please type your name"
						id="signature"
						value={signature}
						onChange={handleChange}
						// disabled={!!guarantorsFormSuccess?.get("signature")}
					/>
					<p className={`absolute right-3 text-sm zabalMedFont top-[18px]`}>
						{date}
					</p>
					<p className="text-[13px] md:text-sm zabalMedFont text-center">
						[Guarator&apos;s E-signature]
					</p>
				</div>
				<div className="sm:hidden pt-2">
					<CustomGreenBtn
						text="proceed"
						onRequestClick={() => setActiveTab(2)}
						isDisabled={isBtnValid()}
					/>
				</div>
				<div className="hidden sm:flex justify-end pt-4">
					<CustomGreenBtn
						text="proceed"
						width="110px"
						height="42px"
						fontSize="13.5px"
						onRequestClick={() => setActiveTab(2)}
						isDisabled={isBtnValid()}
					/>
				</div>
			</div>
		</div>
	);
};
export default Criteria;

Criteria.propTypes = {
	setActiveTab: PropTypes.func,
	handleChange: PropTypes.func,
	signature: PropTypes.string,
};
