/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import { Helmet } from "react-helmet";
import Navbar from "../../../../../layout/Navbar";
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	InputAdornment,
	Autocomplete,
} from "@mui/material";
// import Breadcrumb from "../../../../../components/breadcrumb/BreadCrumb";
import userContext from "../../../../../context/UserContext";
import { InfoIcon } from "../../../../../layout/assets";
import { HiLockClosed } from "react-icons/hi";
import theme from "../../../../../components/mui/theme";
import Toolip from "../../../../../components/Toolip";

import {
	formatDuration,
	formatDurationForProgramSavings,
	onBlurAmount,
	onInputAmount,
} from "../../../../../common/utils";
import { BiSolidLock } from "react-icons/bi";
import { FormFileUpload } from "./components/FormFileUpload";
// import { FinalStep } from "./components/FinalStep";
import Parse from "../../../../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import {
	BackTracker,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import useParameters from "../../../../../components/customhooks/useParameters";
import { useDispatch } from "react-redux";
import { onSaveUserFinanceRequest } from "../../../../../redux/financing/financeActions";
import { businessTypes, durationFrame } from "../../../../../common/utils";

const finance = [
	{
		value: "",
		label: "",
	},
	{
		value: "quick",
		label: "quick",
	},
	{
		value: "sponsored",
		label: "sponsored",
	},
];

const durationList = [];

for (let i = 1; i <= 24; i++) {
	durationList.push({
		value: i,
		label: i,
	});
}

function RequestButton() {
	const { requestFinance, setRequestFinance, afRate, setAfRate } =
		useContext(userContext);
	const parameters = useParameters();
	const dispatch = useDispatch();

	const afInterest = parameters?.find(
		(param) => param.parameter === "Base Interest rate Apprenticeship Finance"
	);
	const afProgramSavings = parameters?.find(
		(param) =>
			param.parameter === "Base Interest rate (Savings) Apprenticeship Finance"
	);

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		if (name === "amount") {
			// Calculate the commitment fee as 10% of the input amount
			const amount = parseFloat(value.replace(/,/g, ""));
			const fee = isNaN(amount) ? 0 : amount * 0.1;
			const formattedFee = fee.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			setRequestFinance((prevState) => ({
				...prevState,
				[name]: value,
				commitmentFee: formattedFee,
			}));
		} else if (name === "durationText") {
			setRequestFinance((prevState) => ({
				...prevState,
				durationText: value,
				tenure: `${prevState.durationTime} ${value}`,
			}));
		} else if (name === "durationTime") {
			setRequestFinance((prevState) => ({
				...prevState,
				durationTime: value,
				tenure: `${value} ${prevState.durationText}`,
			}));
		} else {
			setRequestFinance((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleAutocompleteChange = (event, newValue) => {
		setRequestFinance((prevState) => ({
			...prevState,
			businessType: newValue ? newValue.label : "",
		}));
	};

	useEffect(() => {
		const calculateInterestAmount = () => {
			const interestRate = parseFloat(afRate.interest?.value.replace(/%/g, ""));
			const amount = parseFloat(requestFinance.amount.replace(/,/g, "")) || 0;
			const calculatedInterestRate = (amount * interestRate) / 100;

			const formattedInterestRate = calculatedInterestRate.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}
			);

			setRequestFinance((prevState) => ({
				...prevState,
				interest: formattedInterestRate ? formattedInterestRate : 0,
			}));

			// eslint-disable-next-line no-undef
			console.log("interestRate: ", interestRate);
			// eslint-disable-next-line no-undef
			console.log("amount: ", amount);
		};

		calculateInterestAmount();
	}, [requestFinance.amount, parameters]);

	useEffect(() => {
		const calculateAmountRepayableAT = () => {
			const duration = `${requestFinance.durationTime} ${requestFinance.durationText}`;
			const getTime = formatDuration(duration);

			const amount =
				Number(requestFinance.interest?.replaceAll(",", "")) * getTime;

			const totalAmountRepayable =
				amount + Number(requestFinance.amount?.replaceAll(",", ""));

			const formattedAmount = totalAmountRepayable?.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			setRequestFinance((prevState) => ({
				...prevState,
				amountRepayableAfterTenure: totalAmountRepayable
					? formattedAmount
					: "0.00",
				interestRate: afRate.interest?.value,
			}));
		};

		calculateAmountRepayableAT();
	}, [
		requestFinance.amount,
		requestFinance.durationTime,
		requestFinance.durationText,
		requestFinance.interest,
	]);

	useEffect(() => {
		const calculateProgramSavings = () => {
			const amount =
				Number(
					requestFinance.amountRepayableAfterTenure?.replaceAll(",", "")
				) || 0;
			const duration = `${requestFinance.durationTime} ${requestFinance.durationText}`;
			const getTime = formatDurationForProgramSavings(duration);

			const addedFee = amount < 100 ? 0.02 : 0.05;

			const savings = amount / getTime + addedFee || 0;

			const formattedSavings = savings?.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			setRequestFinance((prevState) => ({
				...prevState,
				programSavings: formattedSavings,
			}));
		};

		calculateProgramSavings();
	}, [
		requestFinance?.amountRepayableAfterTenure,
		requestFinance.durationTime,
		requestFinance.durationText,
	]);

	const handleForm = (e) => {
		e.preventDefault();

		const currentUser = Parse.User.current();

		const data = {
			user: currentUser.toPointer(),
			type: requestFinance?.type,
			amount: requestFinance?.amount,
			commitmentFee: requestFinance?.commitmentFee,
			expectedProfit: requestFinance?.expectedProfit,
			tenure: requestFinance?.tenure,
			interest: requestFinance?.interest,
			programSavings: requestFinance?.programSavings,
			businessType: requestFinance?.businessType,
			businessDescription: requestFinance?.businessDescription,
			purpose: requestFinance?.purpose,
			totalAmountRepayable: requestFinance?.amountRepayableAfterTenure,
			transaction: "request",
			interestRate: afRate.interest?.value,
			requestType: "new",
		};

		dispatch(onSaveUserFinanceRequest(data));

		setRequestFinance((prev) => ({ ...prev, formStep: 1 }));
	};

	useEffect(() => {
		setAfRate((prevState) => ({
			...prevState,
			interest: afInterest,
			programSavings: afProgramSavings,
		}));
	}, [parameters]);

	// console.log(requestFinance);

	const onMobileBackBtnClick = () => {
		if (requestFinance.formStep === 0) {
			navigate(-1);
		} else {
			setRequestFinance((prev) => ({
				...prev,
				formStep: requestFinance.formStep - 1,
			}));
		}
	};

	return (
		<>
			<MobileNavDetails text="New Transaction" />
			<Container>
				<Helmet>
					<title>Fortcore - New Transaction</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="flex gap-1.5">
					<UrlTracker
						text="financing"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="new transaction"
						onNavigate={() => navigate("/financing/new-transaction")}
					/>
					<UrlTracker text="request finance" isCurrentUrl />
				</div>
				{/* 
				<Box pb={5}>
					<Breadcrumb items={breadcrumbItems} />
				</Box> */}

				{/* content  */}
				<BackTracker text="request finance" onNavigate={onMobileBackBtnClick} />
				<Box
					sx={{
						background: "#F6F7F8",
						padding: "20px",
						marginTop: "30px",
						borderRadius: "8px",
					}}
				>
					{requestFinance.formSteps === 2 ? null : (
						<Typography variant="h4" color="primary.main" mb={3}>
							Request Apprenticeship Finance
						</Typography>
					)}
					{/* Form  */}

					{requestFinance.formStep === 0 && (
						<Box width="100%">
							<form onSubmit={handleForm}>
								<Stack spacing={4}>
									{/* Business Type */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Business Type :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<Autocomplete
												disablePortal
												options={businessTypes}
												name="businessType"
												value={
													businessTypes.find(
														(option) =>
															option.label === requestFinance.businessType
													) || null
												}
												required
												onChange={handleAutocompleteChange}
												getOptionLabel={(option) => option.label}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder="Business Type"
														required
													/>
												)}
											/>
										</div>
									</div>

									{/*  Apprentice Financing Type*/}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Apprentice Financing Type :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<FormControl fullWidth>
												<Select
													name="type"
													placeholder="Id type"
													value={requestFinance.type}
													onChange={handleInputChange}
													sx={{ width: "100%", textTransform: "capitalize" }}
													required
												>
													{finance.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
															sx={{ textTransform: "capitalize" }}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</div>
									</div>

									{/* Amount To Raise */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Amount To Raise :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="tel"
												name="amount"
												value={requestFinance.amount}
												onChange={handleInputChange}
												placeholder="Amount"
												sx={{ width: "100%" }}
												inputMode="numeric"
												inputProps={{ min: "0" }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Typography sx={{ fontFamily: "ZabalMedium" }}>
																NGN
															</Typography>
														</InputAdornment>
													),
												}}
												onInput={onInputAmount}
												onBlur={(e) => onBlurAmount(e, requestFinance.amount)}
												required
											/>
										</div>
									</div>

									{/* Commitment Fee */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Commitment Fee :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="tel"
												name="commitmentFee"
												value={requestFinance.commitmentFee}
												onChange={handleInputChange}
												placeholder="Fee"
												InputProps={{
													readOnly: true,
													startAdornment: (
														<InputAdornment position="start">
															<Typography sx={{ fontFamily: "ZabalMedium" }}>
																NGN
															</Typography>
														</InputAdornment>
													),
													endAdornment: (
														<InputAdornment position="start">
															<HiLockClosed />
														</InputAdornment>
													),
												}}
												sx={{ width: "100%" }}
												required
												onInput={onInputAmount}
												onBlur={(e) =>
													onBlurAmount(e, requestFinance.commitmentFee)
												}
											/>
										</div>
									</div>

									{/* Duration Of Apprentice Financing */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Apprentice Finance Duration :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<Stack direction="row" alignItems="center" spacing={2}>
												<TextField
													select
													name="durationText"
													value={requestFinance.durationText}
													onChange={handleInputChange}
													sx={{ width: "100%", textTransform: "capitalize" }}
													required
												>
													{durationFrame.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
															sx={{ textTransform: "capitalize" }}
														>
															{option.label}
														</MenuItem>
													))}
												</TextField>

												<TextField
													select
													name="durationTime"
													value={requestFinance.durationTime}
													onChange={handleInputChange}
													sx={{ width: "100%", textTransform: "capitalize" }}
													required
												>
													{durationList.map((option) => (
														<MenuItem
															key={option.value}
															value={option.value}
															sx={{ textTransform: "capitalize" }}
														>
															{option.label}
														</MenuItem>
													))}
												</TextField>
											</Stack>
										</div>
									</div>

									{/* Expected Profit */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Expected Profit :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="tel"
												name="expectedProfit"
												value={requestFinance.expectedProfit}
												onChange={handleInputChange}
												placeholder="Amount"
												sx={{ width: "100%" }}
												inputMode="numeric"
												inputProps={{ min: "0" }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Typography sx={{ fontFamily: "ZabalMedium" }}>
																NGN
															</Typography>
														</InputAdornment>
													),
												}}
												onInput={onInputAmount}
												onBlur={(e) =>
													onBlurAmount(e, requestFinance.expectedProfit)
												}
												required
											/>
										</div>
									</div>

									{/* Interest Rate */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Interest :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="tel"
												name="interest"
												value={requestFinance.interest.toLocaleString()}
												onChange={handleInputChange}
												// placeholder="Automatically Generated"
												inputMode="numeric"
												inputProps={{ min: "0" }}
												sx={{ width: "100%" }}
												InputProps={{
													readOnly: true,
													startAdornment: (
														<InputAdornment position="start">
															<Typography sx={{ fontFamily: "ZabalMedium" }}>
																NGN
															</Typography>
														</InputAdornment>
													),
													endAdornment: (
														<InputAdornment position="start">
															<Stack
																direction="row"
																alignItems="center"
																spacing={0.5}
															>
																<Typography
																	color="primary"
																	sx={{ fontFamily: "ZabalMedium" }}
																>
																	({afRate?.interest?.value}P/A)
																</Typography>
																<BiSolidLock
																	size={15}
																	color={theme.palette.primary.main}
																/>
															</Stack>
														</InputAdornment>
													),
												}}
												required
											/>
										</div>
									</div>

									{/* Total Amount Repayable After Tenure */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Amount Repayable After Tenure :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="tel"
												name="amountRepayableAfterTenure"
												value={requestFinance.amountRepayableAfterTenure}
												onChange={handleInputChange}
												placeholder="Amount Repayable"
												InputProps={{
													readOnly: true,
													startAdornment: (
														<InputAdornment position="start">
															<Typography sx={{ fontFamily: "ZabalMedium" }}>
																NGN
															</Typography>
														</InputAdornment>
													),
													endAdornment: (
														<InputAdornment position="start">
															<HiLockClosed />
														</InputAdornment>
													),
												}}
												sx={{ width: "100%" }}
												required
												onInput={onInputAmount}
												onBlur={(e) =>
													onBlurAmount(
														e,
														requestFinance.amountRepayableAfterTenure
													)
												}
											/>
										</div>
									</div>

									{/* Program Savings  */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											<Stack direction="row" alignItems="start" gap={1}>
												Program Savings :{" "}
												<Toolip
													text={
														"This is the amount of money to be paid monthly"
													}
												/>
											</Stack>
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="tel"
												name="programSavings"
												value={requestFinance.programSavings}
												onChange={handleInputChange}
												// placeholder="Automatically Generated"
												inputMode="numeric"
												inputProps={{ min: "0" }}
												sx={{ width: "100%" }}
												InputProps={{
													readOnly: true,
													startAdornment: (
														<InputAdornment position="start">
															<Typography sx={{ fontFamily: "ZabalMedium" }}>
																NGN
															</Typography>
														</InputAdornment>
													),
													endAdornment: (
														<InputAdornment position="start">
															<Stack
																direction="row"
																alignItems="center"
																spacing={0.5}
															>
																{/* <Typography
																	color="primary"
																	sx={{ fontFamily: "ZabalMedium" }}
																>
																	({afRate?.programSavings?.value}P/A)
																</Typography> */}
																<BiSolidLock
																	size={15}
																	color={theme.palette.primary.main}
																/>
															</Stack>
														</InputAdornment>
													),
												}}
												onInput={onInputAmount}
												onBlur={(e) =>
													onBlurAmount(e, requestFinance.programSavings)
												}
												required
											/>
										</div>
									</div>

									{/* Description Of Your Business */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Description Of Your Business :
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="text"
												name="businessDescription"
												value={requestFinance.businessDescription}
												onChange={handleInputChange}
												placeholder="Description"
												sx={{ width: "100%" }}
												required
											/>
										</div>
									</div>

									{/* Purpose */}
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											<Stack direction="row" alignItems="center">
												Purpose : &nbsp; <InfoIcon />
											</Stack>
										</p>
										<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
											<TextField
												type="text"
												name="purpose"
												value={requestFinance.purpose}
												onChange={handleInputChange}
												placeholder="Purpose"
												sx={{ width: "100%" }}
												required
											/>
										</div>
									</div>
								</Stack>

								<Box
									pt={4}
									sx={{ display: "flex", justifyContent: "flex-end" }}
								>
									<Stack direction="row" alignItems="center" spacing={1}>
										{!loading ? (
											<Button
												type="submit"
												variant="contained"
												disabled={loading}
											>
												Proceed
											</Button>
										) : (
											<Button
												type="button"
												variant="contained"
												disabled={loading}
											>
												<CircularProgress size="20px" sx={{ color: "#fff" }} />
											</Button>
										)}
									</Stack>
								</Box>
							</form>
						</Box>
					)}

					{requestFinance.formStep === 1 && (
						<FormFileUpload
							// step={handleFormTwo}

							loading={loading}
							setLoading={setLoading}
						/>
					)}
				</Box>
			</Container>
		</>
	);
}

export default RequestButton;
