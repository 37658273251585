import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { BiError } from "react-icons/bi";
import { AiFillFileText } from "react-icons/ai";
import BrowseFormContext from "../BrowseFormContext";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import Navbar from "../../../../../layout/Navbar";
import { OtherUploadComponent } from "../RequestButton/UPLOAD/OtherUpload";
import { InfoPop } from "../../../../../layout/assets";
import Parse from "../../../../../api/ApiConfig";
import PaymentModal from "../../../paymentPin/PaymentPin";
import { Preloader } from "../../../../../components/globalStyled";
import {
	FatBackTracker,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import { useSelector } from "react-redux";
import {
	submitAppFinanceRequest,
	updateOngoingReq,
} from "../../../../../redux/financing/financeServices";
// import { onSaveUserFinanceRequest } from "../../../../../redux/financing/financeActions";

function FinalStep() {
	const navigate = useNavigate();
	// const dispatch = useDispatch();

	const { currentUser } = useSelector((state) => state.auth);
	const { userFinanceRequest } = useSelector((state) => state.finance);

	const [title, setTitle] = useState("");
	const [modal, setModal] = useState(false);
	const [payModal, setPayModal] = useState(false);
	const [btnText, setBtnText] = useState("");
	const [loadingSave, setLoadingSave] = useState(false);
	const { marketPlace, setMarketPlace } = useContext(BrowseFormContext);

	const handleFileUpload = (file) => {
		setMarketPlace((prevState) => ({
			...prevState,
			idCard: file,
		}));
	};

	const handleUtilityUpload = (file) => {
		setMarketPlace((prevState) => ({
			...prevState,
			utilityBill: file,
		}));
	};

	// const numberWithCommas = (value) => {
	// 	return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	// };

	const saveToParse = async () => {
		const currentUser = Parse.User.current();
		if (currentUser) {
			const data = {
				guarantor: userFinanceRequest?.guarantor,
				guarantorsInfo: userFinanceRequest?.guarantorsInfo,
				autoDebit: marketPlace?.autoDebit,
				insurance: marketPlace?.insurance,
				user: currentUser.toPointer(),
				paymentType: "",
				tenure: marketPlace?.tenure,
				type: userFinanceRequest?.type,
				category: "Apprentice Market",
				requestType: userFinanceRequest?.requestType || "old",
				amount: userFinanceRequest?.amount,
				amountToRaise: userFinanceRequest?.amount,
				interest: userFinanceRequest?.interest,
				interestRate: userFinanceRequest?.interestRate,
				purpose: userFinanceRequest?.purpose,
				businessDescription: userFinanceRequest?.businessDescription,
				businessType: userFinanceRequest?.businessType,
				commitmentFee: userFinanceRequest?.commitmentFee,
				commitmentFeeStatus: "pending",
				expectedProfit: userFinanceRequest?.expectedProfit,
				programSavings: userFinanceRequest?.programSavings,
				totalAmountRepayable: userFinanceRequest?.amountRepayable,
				attachmentDoc: userFinanceRequest?.attachmentFile,
				proofOfPurchaseFile: userFinanceRequest?.proofOfPurchaseFile,
				productPhotos: userFinanceRequest?.productPhoto,
				marketDetails:
					marketPlace?.marketDetails || userFinanceRequest?.marketDetails || "",
				documents: true,
				requestStatus: "submitted",
			};

			const message = "Finance Request Submitted";
			const moreDetails = `You finance request with the transaction ID of ${userFinanceRequest?.objectId} has been submitted successfully. You can view more information on the status of transaction.`;

			try {
				setLoadingSave(true);

				const response = await submitAppFinanceRequest(
					userFinanceRequest?.objectId,
					data,
					message,
					moreDetails
				);

				if (userFinanceRequest?.investorFinanceInfo) {
					const id = userFinanceRequest?.investorFinanceInfo?.financeId;

					const dataToUpdate = {
						apprentice: [
							...(userFinanceRequest?.investorFinanceInfo?.apprentice || []),
							{
								name: `${currentUser?.get("firstName")} ${currentUser?.get(
									"lastName"
								)}`,
								user: Parse.User.current(),
								transactionId: userFinanceRequest?.objectId,
								status: "pending",
								date: new Date().toISOString(),
								businessInfo: {
									description: userFinanceRequest?.businessDescription,
									purpose: userFinanceRequest?.purpose,
									type: userFinanceRequest?.businessType,
								},
								commitmentFee: {
									amount: userFinanceRequest?.commitmentFee,
									status: "pending",
								},
							},
						],
					};

					const message = "New Apprentice Request";
					const moreInfo = `A new apprentice has requested for a finance with the id of TRN-${id}. Kindly look into their details before approving a request.`;

					await updateOngoingReq(
						id,
						dataToUpdate,
						"AFBusinessCreate",
						message,
						moreInfo,
						userFinanceRequest?.investorFinanceInfo?.userId
					);
				}

				if (response) {
					toast.success("Finance request submitted successfully");

					setTimeout(() => {
						window.location.href = "/financing/submitted-transactions";
					}, 700);
				}
			} catch (error) {
				toast.error(error);
			} finally {
				setLoadingSave(false);
			}
		} else {
			setLoadingSave(false);
			Parse.User.logOut();
			navigate("/");
		}
	};

	const actualFeeToDeduct = Number(
		marketPlace?.amount?.replaceAll(",", "") / 10
	);

	const guarantorStatus =
		userFinanceRequest?.guarantor || marketPlace?.guarantor;

	const idCard = marketPlace.idCard?.name || currentUser?.idCard;
	const utilityBill = marketPlace.utilityBill?.name || currentUser?.utilityBill;

	const isBtnDisabled = () => {
		if (
			!marketPlace?.autoDebit ||
			!marketPlace?.insurance ||
			!guarantorStatus ||
			!idCard ||
			!utilityBill
		) {
			return true;
		} else {
			return false;
		}
	};

	// const [uLoading, setULoading] = useState(false);

	// const onDataUpdate = async () => {
	// 	try {
	// 		setULoading(true);
	// 		const dataToUpdate = {
	// 			commitmentFeeStatus: "paid",
	// 			paymentType: marketPlace?.paymentType,
	// 		};
	// 		const response = await updateOngoingReq(
	// 			marketPlace?.objectId,
	// 			dataToUpdate,
	// 			"ApprenticeshipFinance"
	// 		);

	// 		if (response) {
	// 			toast.success("Commitment fee paid and request updates successfully");
	// 			dispatch(onSaveUserFinanceRequest(response));
	// 		}
	// 	} catch (error) {
	// 		toast.error(error);
	// 	} finally {
	// 		setULoading(false);
	// 	}
	// };

	// useEffect(() => {
	// 	if (
	// 		marketPlace?.commitmentValid &&
	// 		userFinanceRequest?.commitmentFeeStatus !== "paid"
	// 	) {
	// 		onDataUpdate();
	// 	} else {
	// 		return;
	// 	}
	// 	console.log(2);
	// }, [marketPlace?.commitmentFee]);

	if (!userFinanceRequest?.objectId) {
		return <Navigate to="/financing/ongoing-transactions" />;
	}

	return (
		<>
			{/* <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={uLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}
			<MobileNavDetails text="Ongoing Trasactions" />

			<Container>
				<Helmet>
					<title>Fortcore - Ongoing Transactions</title>
				</Helmet>
				<Navbar text="Ongoing Transactions" />
				<div className="hidden sm:flex gap-1.5 sm:mt-10">
					<UrlTracker
						text="apprenticeship finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="ongoing transactions"
						onNavigate={() => navigate("/financing/ongoing-transactions")}
					/>
					<UrlTracker text="request finance" isCurrentUrl />
				</div>

				<div className="sm:hidden zabalMedFont">
					<FatBackTracker
						text="request finance"
						onNavigate={() => navigate("/financing/ongoing-transactions")}
					/>
				</div>

				<Box width="100%" sx={{ padding: "20px", marginTop: "20px" }}>
					<Stack spacing={3}>
						<Stack spacing={1}>
							<Typography variant="h2" color="primary">
								Final Step!
							</Typography>
							<Typography variant="caption" color="textsecondary">
								Complete the action required below to submit this transaction
							</Typography>
							<Stack direction="row">
								<Typography variant="body1" color="initial">
									Transaction ID:&nbsp;
								</Typography>
								<Typography variant="h5" color="primary">
									{marketPlace?.objectId}
								</Typography>
							</Stack>
						</Stack>

						<Stack spacing={2}>
							{/* Upload ID Card  */}
							{/* <FinalBox title="Upload ID Card" onClick={() => {}} /> */}
							<OtherUploadComponent
								title="Upload ID Card"
								onFileUpload={handleFileUpload}
								fileName={marketPlace.idCard?.url || currentUser?.idCard}
							/>

							<OtherUploadComponent
								title="Utility Bill"
								onFileUpload={handleUtilityUpload}
								fileName={
									marketPlace?.utilityBill?.url || currentUser?.utilityBill
								}
							/>

							{/* Auto Debit  */}
							<FinalBox
								title="Auto Debit"
								bool={marketPlace?.autoDebit}
								modal={() => {
									if (!marketPlace?.autoDebit) {
										setTitle("Authorize Auto Debit on your bank account?");
										setBtnText("Authorize");
										setModal(true);
									}
								}}
							/>

							{/* Insurance  */}
							<FinalBox
								title="Insurance"
								btnText="Proceed"
								bool={marketPlace?.insurance}
								modal={() => {
									if (!marketPlace?.insurance) {
										setTitle("Proceed to apply for insurance");
										setBtnText("Proceed");
										setModal(true);
									}
								}}
							/>

							{/* Guarantor  */}
							<FinalBox
								title="Guarantor"
								bool={guarantorStatus}
								modal={() => {
									if (!guarantorStatus) {
										navigate("/financing/new-transaction/request/guarantor", {
											state: { from: userFinanceRequest?.requestType },
										});
									}
								}}
							/>

							{/* Commitment Fee  */}
							{/* <FinalBox
								title="Commitment Fee"
								btnText="Proceed"
								bool={userFinanceRequest?.commitmentFeeStatus === "paid"}
								modal={() => {
									if (userFinanceRequest?.commitmentFeeStatus !== "paid") {
										setTitle(
											`A commitment fee of NGN ${numberWithCommas(
												String(actualFeeToDeduct)
											)} (10% of amount to raise) will be deducted from your account.`
										);
										setBtnText("Authorize");
										setModal(true);
									}
								}}
							/> */}
						</Stack>
					</Stack>

					<Box
						mt={5}
						sx={{
							display: "flex",
							justifyContent: { sm: "flex-end" },
						}}
					>
						{!loadingSave ? (
							<Button
								variant="contained"
								disabled={isBtnDisabled()}
								onClick={saveToParse}
								sx={{ width: { xs: "100%", sm: "" } }}
							>
								Submit
							</Button>
						) : (
							<Button
								variant="contained"
								type="button"
								sx={{ width: { xs: "100%", sm: "" } }}
								disabled={loadingSave}
							>
								<Preloader />
							</Button>
						)}
					</Box>
				</Box>
			</Container>

			{modal && (
				<ModalPop
					title={title}
					modal={modal}
					setModal={setModal}
					onClick={() => {
						if (title === "Authorize Auto Debit on your bank account?") {
							setMarketPlace((prevState) => ({
								...prevState,
								autoDebit: true,
							}));
							toast.success("Auto Debit Authorized");
						}
						if (title === "Proceed to apply for insurance") {
							setMarketPlace((prevState) => ({
								...prevState,
								insurance: true,
							}));
							toast.success("Insurance Enabled");
						}
						if (
							title ===
							`A commitment fee of NGN ${actualFeeToDeduct} (10% of amount to raise) will be deducted from your account.`
						) {
							setPayModal(true);
						}

						setModal(false);
					}}
					btnText={btnText}
				/>
			)}

			{payModal && (
				<PaymentModal
					payModal={payModal}
					setPayModal={setPayModal}
					narration="apprentice commitment fee"
					amountToDeduct={String(actualFeeToDeduct)}
					setState={() =>
						setMarketPlace((prevState) => ({
							...prevState,
							commitmentValid: true,
							commitmentFee: actualFeeToDeduct,
						}))
					}
					wallet={() => {
						setMarketPlace((prevState) => ({
							...prevState,
							paymentType: "wallet",
						}));
					}}
					card={() => {
						setMarketPlace((prevState) => ({
							...prevState,
							paymentType: "card",
						}));
					}}
				/>
			)}
		</>
	);
}

export default FinalStep;

const FinalBox = ({ title, onClick, bool, modal }) => (
	<Button
		sx={{
			border: "1px solid var(--grey-200, #E3E9EC)",
			borderRadius: "8px",
			width: "100%",
			height: "66px",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "0 8px",
			cursor: "pointer",
		}}
		onClick={modal}
	>
		<Typography variant="h4" sx={{ color: "#393F42" }}>
			{title}
		</Typography>
		<Stack direction="row" spacing={1}>
			{!bool && (
				<Typography
					variant="body1"
					color="primary"
					sx={{
						display: "flex",
						alignItems: "center",
						color: "#F00",
					}}
				>
					<BiError style={{ fontSize: "20px", marginRight: "5px" }} /> Action
					Required
				</Typography>
			)}

			{onClick && (
				<>
					<Button
						variant="outlined"
						onClick={onClick}
						sx={{
							display: { xs: "none", sm: "flex" },
							alignItems: "center",
						}}
					>
						<AiFillFileText />
						Add New File
					</Button>
					<Button
						variant="contained"
						onClick={onClick}
						sx={{
							display: { xs: "flex", sm: "none" },
							alignItems: "center",
						}}
					>
						<AiFillFileText />
					</Button>
				</>
			)}
		</Stack>
	</Button>
);
FinalBox.propTypes = {
	title: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	modal: PropTypes.func,
	bool: PropTypes.bool,
};

// eslint-disable-next-line react/prop-types
function ModalPop({ title, btnText, modal, setModal, onClick }) {
	const handleClose = () => setModal(false);

	return (
		<Modal open={modal} onClose={handleClose}>
			<Box sx={style}>
				<Box display="flex" justifyContent="center" mb={2}>
					<InfoPop />
				</Box>
				<Typography variant="body1" color="primary" align="center">
					{title}
				</Typography>
				<Box display="flex" justifyContent="center" mt={3}>
					<Button variant="contained" color="primary" onClick={onClick}>
						{btnText}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}

ModalPop.propTypes = {
	title: PropTypes.string.isRequired,
	btnText: PropTypes.string.isRequired,
	modal: PropTypes.bool.isRequired,
	setModal: PropTypes.func,
	onClick: PropTypes.func,
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "#fff",
	boxShadow: 24,
	borderRadius: "24px",
	p: 4,
};
