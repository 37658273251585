import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Onboarding from "../screens/onboarding/Onboarding";
import CreateAccount from "../auth/createAccount/CreateAccount";
import Dashboard from "../screens/dashboard/Dashboard";
import Index from "../screens/dashboard/Index";
import Leasing from "../screens/leasing/Leasing";
import Education from "../screens/education/Education";
import Login from "../auth/login/Login";
import ForgotPassword from "../auth/forgotPassword/ForgotPassword";
import Payment from "../screens/payment/Payment";
import NewTransaction from "../screens/leasing/NewTransaction";
import LeaseInvestBoard from "../screens/leasing/LeaseInvestBoard";
import PrivateRoute from "../auth/PrivateRoute";
import Travels from "../screens/travels/Travels";
import InvestorCreateLease from "../screens/leasing/investor/InvestorCreateLease";
import AssetBasedContainer from "../screens/leasing/assetbasedsavings/AssetBasedContainer";
import SavingOverview from "../screens/leasing/SavingOverview";
import NewFinanceTransaction from "../screens/finance/newTransaction/NewTransaction";
import MarketPlace from "../screens/finance/newTransaction/browse/marketplace/MarketPlace";
import MarketForm from "../screens/finance/newTransaction/browse/marketplace/MarketForm";
import RegularSavingsView from "../screens/leasing/regularsavings/RegularSavingsView";
import AssetBasedSavingsView from "../screens/leasing/assetbasedsavings/AssetBasedSavingsView";
import SubmittedTransactionsView from "../screens/leasing/submittedtransactions";
import FormUpload from "../screens/finance/newTransaction/browse/marketplace/FormUpload";
import Finance from "../screens/finance/Finance";
import FinalStep from "../screens/finance/newTransaction/browse/component/FinalStep";
import { BrowseFormProvider } from "../screens/finance/newTransaction/browse/BrowseFormContext";
import ConfirmTransactions from "../screens/leasing/ongoingtransactions/ConfirmTransactions";
import { UserContextProvider } from "../context/UserContext";
import LeaseRequest from "../screens/leasing/leaserequest/LeaseRequest";
import MarketPlaceContainer from "../screens/leasing/marketplace/MarketPlaceContainer";
import UserGuarantor from "../screens/leasing/ongoingtransactions/UserGuarantor";
import SubmittedTransactions from "../screens/finance/submitted/SubmittedTransactions";
import OngoingTransactions from "../screens/finance/ongoing/OngoingTransactions";
import FundLease from "../screens/leasing/investor/FundLease";
import InvestorMarketPlace from "../screens/leasing/investor/InvestorMarketPlace";
import InvestmentOptions from "../screens/leasing/investor/InvestmentOptions";
import RequestButton from "../screens/finance/newTransaction/browse/RequestButton/RequestButton";
import GuarantorRequest from "../screens/finance/newTransaction/browse/RequestButton/GuarantorRequest";
import GuarantorRequestForm from "../screens/finance/newTransaction/browse/RequestButton/GuarantorRequestForm";
import Profile from "../screens/profile/index";
import CompleteYourProfile from "../screens/profile/CompleteYourProfile";
import AfInvestorMarketPlace from "../screens/finance/newTransaction/investor/browse/marketplace/InvestorMarketPlace";
import AfInvestorSummary from "../screens/finance/newTransaction/investor/browse/marketplace/AfInvestorSummary";
// import CreateFreeInvestment from "../screens/leasing/riskfreeinvestment/CreateFreeInvestment";
// import ViewFreeInvestments from "../screens/leasing/riskfreeinvestment/ViewFreeInvestments";
import InvestmentPortfolio from "../screens/finance/accountTab/InvestmentPortfolio";
import NewApplication from "../screens/travels/newapplication/NewApplication";
import InvestorCreateAF from "../screens/finance/newTransaction/investor/create/InvestorCreateAF";
import SubmittedApplication from "../screens/travels/submittedapplication/SubmittedApplication";
import TravelAdvanceSavingsView from "../screens/travels/savings/TravelAdvanceSavingsView";
import LeaseRegularSavingsCreate from "../screens/leasing/regularsavings/LeaseRegularSavingsCreate";
import TravelAdvanceSavingsCreate from "../screens/travels/savings/TravelAdvanceSavingsCreate";
import AFRiskFreeInvestment from "../screens/finance/accountTab/riskFreeInvestment/AFRiskFreeInvestment";
import AFRiskFreeApply from "../screens/finance/accountTab/riskFreeInvestment/AFRiskFreeApply";
import LandingPage from "../screens/landing/LandingPage";
import LeaseAgreement from "../screens/leasing/submittedtransactions/LeaseAgreement";
import AccountType from "../auth/accountType/AccountType";
import OngoingLeaseTransactionsView from "../screens/leasing/ongoingtransactions/OngoingLeaseView";
import SingleLeaseRegularSavings from "../screens/leasing/regularsavings/SingleLeaseRegularSavings";
import SingleTravelAdvanceSavings from "../screens/travels/savings/SingleTravelAdvanceSavings";
import SingleAssetBasedSavings from "../screens/leasing/assetbasedsavings/SingleAssetBasedSavings";
import PartialFundedAsset from "../screens/noauth/PartialFundedAsset";
import PartialFundedRequest from "../screens/noauth/PartialFundedRequest";
import ActiveInvestment from "../screens/leasing/ActiveInvestment";
import FinalRequestStep from "../screens/finance/newTransaction/browse/RequestButton/components/FinalStep";
import ActiveFinanceInvestment from "../screens/finance/ActiveFinanceInvestment";
import FinanceAgreement from "../screens/finance/submitted/components/FinanceAgreement";

function RouterPage() {
	return (
		<>
			<UserContextProvider>
				<BrowseFormProvider>
					<Router>
						<AccountType />
						<Routes>
							{/* <Route exact path="/" element={<Onboarding />} /> */}
							<Route exact path="*" element={<LandingPage />} />
							<Route exact path="/" element={<LandingPage />} />
							<Route exact path="/create-account" element={<CreateAccount />} />
							<Route exact path="/login" element={<Login />} />
							<Route
								exact
								path="/forgot-password"
								element={<ForgotPassword />}
							/>
							<Route exact path="/*" element={<Onboarding />} />
							<Route element={<PrivateRoute />}>
								<Route element={<Index />}>
									<Route path="/dashboard" element={<Dashboard />} />
									<Route path="/financing" element={<Finance />} />
									<Route
										path="/financing/investment-portfolio"
										element={<InvestmentPortfolio />}
									/>
									<Route
										path="/financing/investments/:id"
										element={<ActiveFinanceInvestment />}
									/>
									<Route
										path="/financing/risk-free-investment"
										element={<AFRiskFreeInvestment />}
									/>
									<Route
										path="/financing/risk-free-investment-create"
										element={<AFRiskFreeApply />}
									/>
									<Route
										path="/financing/new-transaction"
										element={<NewFinanceTransaction />}
									/>

									<Route
										path="/financing/new-transaction/market-place"
										element={<MarketPlace />}
									/>
									<Route
										path="/financing/new-transaction/market-place/form"
										element={<MarketForm />}
									/>
									<Route
										path="/financing/new-transaction/request"
										element={<RequestButton />}
									/>
									<Route
										path="/financing/new-transaction/request/guarantor"
										element={<GuarantorRequest />}
									/>
									<Route
										path="/financing/new-transaction/request/guarantor/form"
										element={<GuarantorRequestForm />}
									/>
									<Route
										path="/financing/new-transaction/market-place/form/upload"
										element={<FormUpload />}
									/>

									{/* Investor Route AF  */}
									<Route
										path="/financing/new-transaction/investor-market-place"
										element={<AfInvestorMarketPlace />}
									/>
									<Route
										path="/financing/new-transaction/investor-market-place/summary"
										element={<AfInvestorSummary />}
									/>
									<Route
										path="/financing/new-transaction/investor-create"
										element={<InvestorCreateAF />}
									/>

									<Route
										path="/financing/ongoing-transactions"
										element={<OngoingTransactions />}
									/>
									<Route
										path="/financing/ongoing-transactions/request-finance"
										element={<FinalRequestStep />}
									/>
									<Route
										path="/financing/ongoing-transactions/market-finance"
										element={<FinalStep />}
									/>
									<Route
										path="/financing/submitted-transactions"
										element={<SubmittedTransactions />}
									/>

									<Route path="/leasing" element={<Leasing />} />
									<Route
										path="/leasing/asset-based-savings"
										element={<AssetBasedContainer />}
									/>
									<Route
										path="/leasing/asset-based-savings/:id"
										element={<SingleAssetBasedSavings />}
									/>
									<Route
										path="/leasing/investment-board"
										element={<LeaseInvestBoard />}
									/>
									{/* <Route
										path="/leasing/risk-free-investment"
										element={<CreateFreeInvestment />}
									/> */}
									{/* <Route
										path="/leasing/risk-free-investment/view"
										element={<ViewFreeInvestments />}
									/> */}
									<Route
										path="/leasing/savings-overview"
										element={<SavingOverview />}
									/>
									<Route
										path="/leasing/regular-savings"
										element={<LeaseRegularSavingsCreate />}
									/>
									<Route
										path="/leasing/regular-savings/view"
										element={<RegularSavingsView />}
									/>
									<Route
										path="/leasing/regular-savings/:id"
										element={<SingleLeaseRegularSavings />}
									/>
									<Route
										path="/leasing/asset-based-savings/view"
										element={<AssetBasedSavingsView />}
									/>
									<Route
										path="/leasing/new-transaction"
										element={<NewTransaction />}
									/>
									<Route
										path="/leasing/new-transaction/market-place"
										element={<MarketPlaceContainer />}
									/>
									<Route
										path="/leasing/new-transaction/investor-market-place"
										element={<InvestorMarketPlace />}
									/>
									<Route
										path="/leasing/new-transaction/request-lease"
										element={<LeaseRequest />}
									/>
									<Route
										path="/leasing/new-transaction/create-lease"
										element={<InvestorCreateLease />}
									/>
									<Route
										path="/leasing/new-transaction/fund-request"
										element={<FundLease />}
									/>
									<Route
										path="/leasing/fund-request/investment-options"
										element={<InvestmentOptions />}
									/>
									<Route
										path="/leasing/ongoing-transactions"
										element={<OngoingLeaseTransactionsView />}
									/>
									<Route
										path="/leasing/submitted-transactions"
										element={<SubmittedTransactionsView />}
									/>
									<Route
										path="/leasing/ongoing-transactions/confirm-lease"
										element={<ConfirmTransactions />}
									/>
									<Route
										path="/leasing/guarantors-information"
										element={<UserGuarantor />}
									/>
									<Route path="/travels" element={<Travels />} />
									<Route
										path="/travels/new-application"
										element={<NewApplication />}
									/>
									<Route
										path="/travels/regular-savings/view"
										element={<TravelAdvanceSavingsView />}
									/>
									<Route
										path="/travels/regular-savings"
										element={<TravelAdvanceSavingsCreate />}
									/>
									<Route
										path="/travels/regular-savings/:id"
										element={<SingleTravelAdvanceSavings />}
									/>
									<Route
										path="/travels/submitted-application"
										element={<SubmittedApplication />}
									/>

									<Route path="/education" element={<Education />} />
									<Route path="/education/video/:id" element={<Education />} />
									<Route path="/education/audio/:id" element={<Education />} />
									<Route path="/education/books/:id" element={<Education />} />
									<Route path="/payments" element={<Payment />} />
									<Route path="/dashboard/profile" element={<Profile />} />
									<Route
										path="/dashboard/profile/complete-your-profile"
										element={<CompleteYourProfile />}
									/>
									<Route
										path="/leasing/:id/lease-agreement"
										element={<LeaseAgreement />}
									/>
									<Route
										path="/leasing/:id/guarantors"
										element={<UserGuarantor />}
									/>
									<Route
										path="/leasing/investments/:id"
										element={<ActiveInvestment />}
									/>
									<Route
										path="/financing/:id/finance-agreement"
										element={<FinanceAgreement />}
									/>
								</Route>
							</Route>
							<Route
								path="/partial-funded-asset"
								element={<PartialFundedAsset />}
							/>
							<Route
								path="/partial-funded-request"
								element={<PartialFundedRequest />}
							/>
						</Routes>
					</Router>
				</BrowseFormProvider>
			</UserContextProvider>
		</>
	);
}

export default RouterPage;
