import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { MdOutlineClose } from "react-icons/md";
import { redirect } from "react-router-dom";
import moment from "moment";
import AddBvnModal from "../../../components/AddBvnModal";
import { SuccessCheckModal } from "../../../layout/CustomComponents";
import {
	createAccessPin,
	getWalletPhoneOtp,
	updateUserAndPersonalData,
	verifyWalletPhoneOtp,
} from "../../../redux/authRedux/authServices";
import {
	clearPinCreationSuccess,
	clearUpdateAccountSuccess,
	closeCustomAlert,
	logout,
	onToggleActivateWalletModal,
	openCustomeAlert,
} from "../../../redux/authRedux/authActions";
import Alert from "../../../layout/Alert";
import { Preloader } from "../../../components/globalStyled";
import CountDown from "../../../components/Countdown";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import {
	ConfirmPin,
	CreatePin,
} from "../../../components/payments/PinComponent";
import Parse from "../../../api/ApiConfig";

const ActivateWallet = () => {
	const dispatch = useDispatch();
	const {
		bvnSuccessMsg,
		accessPinSuccess,
		verifyBvnSuccess,
		currentUser,
		updatingUserAccountSuccess,
		homeAddresFromBVN,
	} = useSelector((state) => state.auth);

	const [activeTab, setActiveTab] = useState(1);
	const [createPin, setCreatePin] = useState("");
	const [confirmPin, setConfirmPin] = useState("");
	const [invalidConfirmPinError, setInvalidConfirmPinError] = useState(false);

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const data = {
		gender: verifyBvnSuccess?.gender,
		userId: user,
		user: user,
		phone: currentUser?.phone,
		DOB: moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY"),
		fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
		BVN: verifyBvnSuccess?.bvn,
		email: currentUser?.email,
		homeAddress: homeAddresFromBVN,
	};

	const dataForUserProfile = {
		gender: verifyBvnSuccess?.gender,
		DOB: moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY"),
		dateOfBirth: moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY"),
		bvn: verifyBvnSuccess?.bvn,
		homeAddress: homeAddresFromBVN,
	};

	const onSaveBVNDetails = () => {
		dispatch(
			updateUserAndPersonalData(
				data,
				dataForUserProfile,
				"form saved successfully"
			)
		);
	};

	const isBvnAddSuccess = () => {
		dispatch(clearUpdateAccountSuccess());
		setActiveTab(4);
	};

	const onCreatePin = () => {
		dispatch(createAccessPin({ accessPin: confirmPin }));
	};

	const onPinCreationSuccess = () => {
		dispatch(onToggleActivateWalletModal(false));
		dispatch(clearPinCreationSuccess());
		window.location.reload();
	};

	useEffect(() => {
		if (confirmPin.length < 4) {
			return;
		} else if (confirmPin !== createPin) {
			setInvalidConfirmPinError(true);
			setTimeout(() => {
				setInvalidConfirmPinError(false);
			}, 1500);
		}
	}, [confirmPin]);

	useEffect(() => {
		if (bvnSuccessMsg && activeTab === 1) {
			setActiveTab(2);
		} else {
			return;
		}
	}, [bvnSuccessMsg]);

	useEffect(() => {
		if (updatingUserAccountSuccess && activeTab === 2) {
			setActiveTab(3);
		} else {
			return;
		}
	}, [updatingUserAccountSuccess]);

	useEffect(() => {
		if (accessPinSuccess) {
			setActiveTab(6);
		}
	}, [accessPinSuccess]);

	return (
		<>
			{activeTab === 1 && <AddBvnModal activateWallet />}
			{activeTab === 2 && (
				<GetPinOtpVerification onRequestClick={onSaveBVNDetails} />
			)}
			{activeTab === 3 && (
				<SuccessCheckModal
					text="BVN verfication successful and profile saved successfully"
					onRequestClick={isBvnAddSuccess}
					isShowBtn={true}
					btnText="proceed"
				/>
			)}
			{activeTab === 4 && (
				<CreatePin
					btnText="proceed"
					onCloseBtnClick={() => dispatch(onToggleActivateWalletModal(false))}
					onInputChange={setCreatePin}
					onRequestClick={() => setActiveTab(5)}
					isAllowedToProceed={createPin.length > 3}
				/>
			)}
			{activeTab === 5 && (
				<ConfirmPin
					btnText="proceed"
					onCloseBtnClick={() => setActiveTab(4)}
					onInputChange={setConfirmPin}
					onRequestClick={onCreatePin}
					isAllowedToProceed={confirmPin.length > 3 && createPin === confirmPin}
					isPinInvalid={invalidConfirmPinError}
				/>
			)}
			{activeTab === 6 && (
				<SuccessCheckModal
					onRequestClick={onPinCreationSuccess}
					text="Access pin has been created successfully. Your wallet has been activated."
					isShowBtn={true}
					btnText="okay"
				/>
			)}
		</>
	);
};

export default ActivateWallet;

export const GetPinOtpVerification = ({ onRequestClick }) => {
	const dispatch = useDispatch();
	const { currentUser, toggleAlert, updatingUserAccountLoading } = useSelector(
		(state) => state.auth
	);

	const [loading, setLoading] = useState(false);
	const [otpSentSuccess, setOtpSentSuccess] = useState(null);
	const [otpVerficationSuccess, setOtpVerficationSuccess] = useState(null);
	const [text, setText] = useState("");
	const [alertMsg, setAlertMsg] = useState(null);

	const onClickGetOtpButton = async () => {
		setLoading(true);
		setOtpSentSuccess(null);
		setOtpVerficationSuccess(null);
		setAlertMsg(null);
		dispatch(closeCustomAlert());

		try {
			const result = await getWalletPhoneOtp(currentUser?.phone);
			setOtpSentSuccess(result);
			setAlertMsg({
				textOne: "OTP has been sent to your registered phone number.",
				textTwo: `+${currentUser?.phone}`,
			});
			if (result) {
				dispatch(openCustomeAlert());
			}
		} catch (error) {
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				return;
			}
			if (error?.response?.data?.error === "Invalid session token") {
				dispatch(logout());
				localStorage.clear();
				toast.error("This session has expired. Please Login again");
				dispatch(redirect("/login"));
				setTimeout(() => {
					window.location.reload();
				}, 1200);
				return;
			} else {
				toast.error("Failed to send OTP. Try again!");
			}
		} finally {
			setLoading(false);
		}
	};

	const onVerifyResetPinOtp = async () => {
		setLoading(true);
		try {
			const result = await verifyWalletPhoneOtp(text, currentUser?.phone);
			setOtpVerficationSuccess(result);
			setAlertMsg({ textOne: "OTP verification is successful", textTwo: "" });
			if (result) {
				dispatch(openCustomeAlert());
			}
		} catch (error) {
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
			} else if (error.response.data.error === "Invalid OTP") {
				toast.error("The OTP provided is incorrect. Please check!");
			} else if (
				error.response.data.error === "OTP expired. Request for a new one!"
			) {
				toast.error("OTP has expired. Please request a new one");
			} else {
				toast.error("OTP verfication failed. Try again!");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (otpVerficationSuccess) {
			setOtpSentSuccess(null);
			return;
		}
		if (text.length < 6) {
			return;
		} else if (isNaN(text)) {
			return;
		} else {
			onVerifyResetPinOtp();
		}
	}, [text, otpVerficationSuccess]);

	useEffect(() => {
		if (otpSentSuccess) {
			console.log(2);
			setTimeout(() => {
				console.log(3);
				setOtpSentSuccess(null);
			}, 300 * 1000);
		} else {
			return;
		}
	}, [otpSentSuccess]);

	const isButtonDisabled = () => {
		if (loading || otpSentSuccess || otpVerficationSuccess) {
			return true;
		} else {
			return false;
		}
	};

	const isInputFieldDisabled = () => {
		if (otpVerficationSuccess) {
			return true;
		} else {
			return false;
		}
	};

	const showCountDown = () => {
		if (otpSentSuccess) {
			return true;
		} else if (otpVerficationSuccess) {
			return false;
		} else {
			return false;
		}
	};

	return (
		<div className="text-[#393F42] rounded-lg bg-white p-5 md:p-6 w-11/12 space-y-4 md:space-y-5 max-w-[450px]">
			<div className="flex justify-between">
				<p className="text-sm sm:text-[15px] zabalBoldFont">OTP Verification</p>
				<button onClick={() => dispatch(onToggleActivateWalletModal(false))}>
					<MdOutlineClose color="#00525D" size={19} />
				</button>
			</div>
			<div className="space-y-3.5">
				{toggleAlert && (
					<Alert
						textOne={alertMsg?.textOne}
						textTwo={alertMsg?.textTwo || ""}
					/>
				)}
				<p className="text-[13.5px] zabalRegFont">
					Click on the <span className="font-semibold">Get Code</span> button to
					request OTP for wallet activattion.
				</p>
				<div className="flex flex-col gap-1.5">
					<p className="text-xs zabalRegFont">Verification</p>
					<div className="relative">
						<input
							type="tel"
							className={`w-full h-[45px] border border-[#96A0A5] px-2.5 rounded outline-none text-sm zabalRegFont focus:border-fortcore-green focus:border-2 ${
								isInputFieldDisabled() ? "bg-slate-300/70" : "bg-transparent"
							}`}
							placeholder="enter OTP"
							value={text}
							onChange={(e) =>
								isNaN(e.target.value) ? "" : setText(e.target.value)
							}
							maxLength={6}
						/>
						{loading ? (
							<div className="absolute right-1 top-0 h-full w-[55px] grid place-items-center">
								<Preloader />
							</div>
						) : (
							<button
								className={`absolute right-3 top-0 h-full grid place-items-center text-xs md:text-[12.5px] font-semibold zabalMedFont ${
									isButtonDisabled() ? "text-gray-200" : "text-[#393F42]"
								}`}
								onClick={onClickGetOtpButton}
								disabled={isButtonDisabled()}
							>
								Get OTP
							</button>
						)}
					</div>
					{showCountDown() && (
						<div className="text-xs flex gap-2 text-fortcore-green zabalMedFont">
							<p>Retry in</p> <CountDown timer={300} />
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col gap-3 pt-3 sm:hidden">
				<CustomGreenBtn
					fontSize="13px"
					text="proceed"
					isDisabled={!otpVerficationSuccess || updatingUserAccountLoading}
					onRequestClick={onRequestClick}
					isLoading={updatingUserAccountLoading}
				/>
			</div>
			<div className="hidden sm:flex justify-end gap-3 pt-3">
				<CustomGreenBtn
					width="100px"
					height="40px"
					fontSize="13px"
					text="proceed"
					isDisabled={!otpVerficationSuccess || updatingUserAccountLoading}
					onRequestClick={onRequestClick}
					isLoading={updatingUserAccountLoading}
				/>
			</div>
		</div>
	);
};

GetPinOtpVerification.propTypes = {
	onRequestClick: PropTypes.func,
};
