import { useEffect, useState } from "react";
import Parse from "../../api/ApiConfig";
import { toast } from "react-toastify";

const useParameters = () => {
	const [data, setData] = useState(null);

	const getParameters = async () => {
		try {
			const query = new Parse.Query("Parameters");
			const response = await query.find();
			const jsonData = response?.map((a) => a?.toJSON());
			setData(jsonData);
		} catch (error) {
			if (error?.message?.includes("Unable to connect to the Parse API")) {
				toast.error("Oops! You are not conected to the internet");
			} else {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		getParameters();
	}, []);

	return data;
};

export default useParameters;
