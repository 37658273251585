import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FatBackTracker } from "../../../layout/CustomComponents";
import {
	GridListSortItems,
	LeaseGridItem,
	LeaseListItem,
	RequestOrderGridSkeleton,
	RequestOrderListSkeleton,
} from "../requestlease/LeaseItems";
import PropTypes from "prop-types";
import { getProductsWithQuery } from "../../../redux/lease/leaseServices";
import EmptyData from "../../../components/EmptyData";
import { saveDataFromLeaseForm } from "../../../redux/lease/leaseActions";

const RequestLeaseItems = ({ setActiveTab }) => {
	const dispatch = useDispatch();
	const [itemStyle, setItemStyle] = useState("grid");
	const { fetchReqLeaseDataLoading, fetchReqLeaseDataSuccess, leaseFormData } =
		useSelector((state) => state.lease);
	const [chooseRecommended, setChooseRecommended] = useState(
		leaseFormData?.query || "-- select --"
	);

	// fetch products on first load
	useEffect(() => {
		// eslint-disable-next-line no-undef
		window.scrollTo({ top: 0, behavior: "smooth" });

		if (chooseRecommended === "-- select --") {
			const query = {
				category:
					leaseFormData?.product_category === "Gadgets"
						? "Gadget"
						: leaseFormData?.product_category,
				brand: leaseFormData?.brand,
				model: leaseFormData?.model,
			};

			dispatch(getProductsWithQuery(query));
		} else {
			return;
		}
	}, [itemStyle]);

	// fetch products on filter changes
	useEffect(() => {
		// eslint-disable-next-line no-undef
		window.scrollTo({ top: 0, behavior: "smooth" });

		if (chooseRecommended === "-- select --") {
			return;
		}

		const query = {
			category:
				chooseRecommended === "all products"
					? ""
					: chooseRecommended === "Gadgets"
					? "Gadget"
					: chooseRecommended,
		};
		dispatch(saveDataFromLeaseForm({ query: query.category }));
		dispatch(getProductsWithQuery(query));
	}, [chooseRecommended]);

	return (
		<div className="space-y-4 sm:space-y-5">
			<div className="space-y-1.5">
				<FatBackTracker
					text="request lease"
					onNavigate={() => setActiveTab(1)}
				/>
				<p className="text-xs text-[#717E83] zabalRegFont ">
					How would you like to explore the service
				</p>
			</div>
			<div className="rounded-md bg-[#F6F7F8] px-3 sm:px-5 space-y-4 md:space-y-5 pb-6 sm:pb-7">
				<GridListSortItems
					currentTab={itemStyle}
					onGridSelect={() => setItemStyle("grid")}
					onListSelect={() => setItemStyle("list")}
					recommend={chooseRecommended}
					onSelectRecommend={setChooseRecommended}
				/>
				{itemStyle === "grid" && (
					<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-x-3 sm:gap-x-4 gap-y-5">
						{fetchReqLeaseDataLoading ? (
							Array(8)
								.fill(0)
								.map((_, i) => <RequestOrderGridSkeleton key={i} />)
						) : fetchReqLeaseDataSuccess?.length > 0 ? (
							fetchReqLeaseDataSuccess.map((item) => {
								return (
									<LeaseGridItem
										item={item}
										key={item?.objectId}
										onTabChange={() => setActiveTab(3)}
									/>
								);
							})
						) : (
							<div className="col-span-2 sm:col-span-3 md:col-span-4 xl:col-span-5 w-full h-[320px] md:h-[270px]">
								<EmptyData text="No products exists in this category yet. Please select another." />
							</div>
						)}
					</div>
				)}
				{itemStyle === "list" && (
					<div className="flex flex-col gap-4">
						{fetchReqLeaseDataLoading ? (
							Array(2)
								.fill(0)
								.map((_, i) => <RequestOrderListSkeleton key={i} />)
						) : fetchReqLeaseDataSuccess?.length > 0 ? (
							fetchReqLeaseDataSuccess.map((item) => {
								return (
									<LeaseListItem
										item={item}
										key={item?.objectId}
										onTabChange={() => setActiveTab(3)}
									/>
								);
							})
						) : (
							<div className="col-span-2 sm:col-span-3 md:col-span-4 xl:col-span-5 w-full h-[320px] md:h-[270px]">
								<EmptyData text="No products exists in this category yet. Please select another." />
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
export default RequestLeaseItems;

RequestLeaseItems.propTypes = {
	setActiveTab: PropTypes.func,
};
