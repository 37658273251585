import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { onTogglePayDeliveryFeeModal } from "../../redux/lease/leaseActions";
import { formatAmountToIncludeCurrency, walletType } from "../../common/utils";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import { EnterPin } from "./PinComponent";
import { SuccessCheckModal } from "../../layout/CustomComponents";
import { payDeliveryFee } from "../../redux/payments/paymentServices";
import { toast } from "react-toastify";
import { updateOngoingLeaseInParse } from "../../redux/lease/leaseServices";
import { useNavigate } from "react-router-dom";
import { clearStatesOnConfirmLease } from "../../redux/authRedux/authActions";
import moment from "moment";
import Toolip from "../Toolip";
import { IsValidIcon } from "../../layout/assets";
import { updateOngoingReq } from "../../redux/financing/financeServices";

const DeliveryInfo = ({ leaseItem, context }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { currentUser } = useSelector((state) => state.auth);
	const { updateOngoingTransactionSuccess, updateOngoingTransactionLoading } =
		useSelector((state) => state.lease);
	const { customerWallet } = useSelector((state) => state.payment);

	const [activeTab, setActiveTab] = useState(1);
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");
	const [loading, setLoading] = useState(false);
	const [paymentSuccess, setPaymentSuccess] = useState(null);

	const accessPin = currentUser?.accessPin;
	const amount =
		process.env.NODE_ENV !== "production"
			? 1
			: Number(leaseItem?.deliveryInfo?.deliveryFee);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const onCloseModal = () => {
		dispatch(onTogglePayDeliveryFeeModal(false, null));
	};

	const onDeductWallet = async () => {
		try {
			setLoading(true);

			const response = await payDeliveryFee(amount);
			if (response) {
				setPaymentSuccess(response);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onPayDeliverySuccess = () => {
		dispatch(clearStatesOnConfirmLease());
		window.location.reload();
	};

	const isPayNowBtnDisbaled = () => {
		if (!leaseItem?.deliveryInfo) {
			return true;
		} else if (leaseItem?.deliveryInfo?.deliveryFeeStatus === "paid") {
			return true;
		} else {
			return false;
		}
	};

	const getDeliveryFeeStatus = () => {
		if (leaseItem?.deliveryInfo) {
			if (leaseItem?.deliveryInfo?.deliveryFeeStatus === "paid") {
				return { status: "Paid", color: "green" };
			} else {
				return { status: "Pending", color: "#FFC214" };
			}
		} else {
			return { status: "N/A", color: "black" };
		}
	};

	const onBeginFeepayment = () => {
		if (mainWallet?.availableBalance < amount) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		} else {
			setActiveTab(2);
		}
	};

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	const updatedDeliveryInfo = {
		...leaseItem?.deliveryInfo,
		deliveryFeeStatus: "paid",
	};

	console.log(leaseItem, leaseItem?.deliveryInfo);

	const onUpdateApprenticePayment = async () => {
		try {
			setLoading(true);

			const message = "Delivery Fee Payment";
			const moreInfo = `You have successfull paid the amount of NGN ${leaseItem?.deliveryInfo?.deliveryFee} for delivery fee. `;

			const response = await updateOngoingReq(
				leaseItem?.objectId,
				{ deliveryInfo: updatedDeliveryInfo },
				"ApprenticeshipFinance",
				message,
				moreInfo
			);

			if (response) {
				toast.success("Delivery fee payment is successfull");

				setTimeout(() => {
					window.location.reload();
				}, 700);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (paymentSuccess) {
			if (context === "lease") {
				dispatch(
					updateOngoingLeaseInParse(
						leaseItem?.objectId,
						{ deliveryInfo: updatedDeliveryInfo },
						"delivery-payment-successful"
					)
				);
			} else {
				onUpdateApprenticePayment();
			}
		}
	}, [paymentSuccess]);

	useEffect(() => {
		if (
			updateOngoingTransactionSuccess === "delivery-payment-successful" &&
			activeTab === 2
		) {
			setActiveTab(3);
		}
	}, [updateOngoingTransactionSuccess]);

	console.log(leaseItem);

	return (
		<>
			{activeTab === 1 && (
				<div className="space-y-4 bg-white rounded-xl sm:rounded-md p-5 w-[90%] max-w-[450px]">
					<div className="flex items-center justify-between text-fortcore-green zabalRegFont">
						<h2 className="text-[15px] font-semibold">Delivery Information</h2>
						<button onClick={onCloseModal}>
							<MdOutlineClose size={20} />
						</button>
					</div>
					<div className="space-y-3">
						{!leaseItem?.deliveryInfo && (
							<p className="zabalRegFont text-red-500 text-[12.5px]">
								NB: Payment button will be activated when delivery information
								is available
							</p>
						)}
						<p className="text-[12.5px]">
							<span className="text-gray-600">TransactionID:</span>{" "}
							<span>TRN-{leaseItem?.objectId}</span>
						</p>
						<p className="text-[12.5px]">
							<span className="text-gray-600">Delivery Location:</span>{" "}
							<span>
								{leaseItem?.deliveryAddress || currentUser?.homeAddress}
							</span>
						</p>
						<p className="text-[12.5px]">
							<span className="text-gray-600">Delivery Cost:</span>{" "}
							<span>
								{leaseItem?.deliveryInfo
									? formatAmountToIncludeCurrency(
											"NGN",
											leaseItem?.deliveryInfo?.deliveryFee
									  )
									: "N/A"}
							</span>
						</p>
						<div className="text-[12.5px]">
							<span className="text-gray-600">Estimated Delivery Date:</span>{" "}
							<span>
								{leaseItem?.deliveryInfo
									? moment(leaseItem?.deliveryInfo?.deliveryDate).format(
											"dddd,  Do MMM YYYY"
									  )
									: "N/A"}
							</span>{" "}
							{leaseItem?.deliveryInfo && (
								<Toolip text="The estimated date of delivery is subject to change." />
							)}
						</div>
						<p className="text-[12.5px]">
							<span className="text-gray-600">Delivery Agent Info:</span>{" "}
							<span>
								{leaseItem?.deliveryInfo
									? `${leaseItem?.deliveryInfo.deliveryAgent}, ${leaseItem?.deliveryInfo.agentNumber}`
									: "N/A"}
							</span>
						</p>
						<p className="text-[12.5px] text-gray-600 flex gap-1">
							<span>Payment Status:</span>{" "}
							<span
								className="font-semibold text-[13px]"
								style={{ color: getDeliveryFeeStatus()?.color }}
							>
								{getDeliveryFeeStatus()?.status}
							</span>
							{getDeliveryFeeStatus()?.status === "Paid" && (
								<span>
									<IsValidIcon width="15px" height="15px" />
								</span>
							)}
						</p>
						{context === "lease" ? (
							<div className="text-[12.5px] text-gray-600 flex flex-col gap-1">
								<p>Product Information:</p>
								<ul className="grid grid-cols-2 gap-1">
									<li className="list-disc ml-4">
										Category:{" "}
										<span className="text-black">
											{leaseItem?.productObj?.category}
										</span>
									</li>
									<li className="list-disc ml-4">
										Sub-Category:{" "}
										<span className="text-black">
											{leaseItem?.productObj?.subCategory}
										</span>
									</li>
									<li className="list-disc ml-4">
										Model:{" "}
										<span className="text-black">
											{leaseItem?.productObj?.model}
										</span>
									</li>
									<li className="list-disc ml-4">
										Cost:{" "}
										<span className="text-black">
											{formatAmountToIncludeCurrency(
												"NGN",
												leaseItem?.productObj?.amount
											)}
										</span>
									</li>
									<li className="list-disc ml-4">
										Shelf Life:{" "}
										<span className="text-black">
											{leaseItem?.productObj?.shelfLife}
										</span>
									</li>
									<li className="list-disc ml-4">
										Name:{" "}
										<span className="text-black">
											{leaseItem?.productObj?.productName}
										</span>
									</li>
								</ul>
							</div>
						) : null}
					</div>
					{leaseItem?.deliveryInfo?.deliveryFeeStatus !== "paid" && (
						<CustomGreenBtn
							text="pay now"
							isDisabled={isPayNowBtnDisbaled()}
							onRequestClick={onBeginFeepayment}
						/>
					)}
				</div>
			)}
			{activeTab === 2 && (
				<EnterPin
					btnText="Confirm to Pay"
					onCloseBtnClick={onCloseModal}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onDeductWallet}
					isPaymentLoading={loading || updateOngoingTransactionLoading}
				/>
			)}
			{activeTab === 3 && (
				<SuccessCheckModal
					text={`The delivery fee of N${amount} has been deducted from your wallet.`}
					onRequestClick={onPayDeliverySuccess}
				/>
			)}
		</>
	);
};

DeliveryInfo.propTypes = {
	leaseItem: PropTypes.object,
	context: PropTypes.string,
};

export default DeliveryInfo;
