import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Navbar from "../../../layout/Navbar";
import { InactiveCreatedFinance } from "./component/InActiveBox";
import { ActiveCreatedFinance } from "./component/ActiveBox";
import Container from "../../../layout/Container";
import Parse from "../../../api/ApiConfig";
import { toast } from "react-toastify";
import { TiInfo } from "react-icons/ti";
import BalanceSummary from "./component/BalanceSummary";
import { useDispatch, useSelector } from "react-redux";
import { walletType } from "../../../common/utils";
import Balance from "../../../components/Balance";
import {
	ActiveInvestmentHeader,
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { CardSkeleton } from "../../leasing/LeaseInvestBoard";
import ModalContainer from "../../../components/ModalContainer";
import {
	onToggleDeleteFinanceModal,
	selectFinanceToActivate,
} from "../../../redux/financing/financeActions";
import {
	deleteInactiveFinance,
	fetchInvestorInvestments,
	updateOngoingReq,
} from "../../../redux/financing/financeServices";
import { networkErrorTrue } from "../../../redux/authRedux/authActions";
import { EnterPin } from "../../../components/payments/PinComponent";
import {
	clearMoveFundsSuccess,
	clearPaymentDeductionSuccess,
} from "../../../redux/payments/paymentActions";
import {
	deductPaymentFromUserWallet,
	moveUserFunds,
} from "../../../redux/payments/paymentServices";
import { logoutUser } from "../../../redux/authRedux/authServices";
import AppFinancaROI from "./AppFinancaROI";

function InvestmentPortfolio() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [fetching, setFetching] = useState(true);

	const user = Parse.User.current();

	const {
		customerWallet,
		deductWalletLoading,
		deductWalletSuccess,
		moveFundsLoading,
		moveFundsSuccess,
		walletDetails,
	} = useSelector((state) => state.payment);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const financeInvestmentWallet = customerWallet?.find(
		(wallet) => wallet?.product === "apprentice-fixed"
	);

	const { toggleDeleteFinanceModal, currentFinanceReq } = useSelector(
		(state) => state.finance
	);

	const [activeTab, setActiveTab] = useState(true);

	const [deleteOngoingLoading, setDeleteOngoingLoading] = useState(false);
	const [updateReqLoading, setUpdateReqLoading] = useState(false);

	const [inActiveInvestments, setInActiveInvestments] = useState([]);
	const [activeInvestments, setActiveInvestments] = useState([]);

	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");

	const [toggleActivateRequest, setToggleActivateRequest] = useState({
		activeRequestTab: 1,
		toggleRequestModal: false,
	});

	const { activeRequestTab, toggleRequestModal } = toggleActivateRequest;

	const accessPin = user?.get("accessPin") || "";

	const fee = process.env.NODE_ENV !== "production" ? 1 : 100;

	const amountToInvest = parseFloat(
		currentFinanceReq?.amountToInvest?.replace(/,/g, "")
	);

	const amountPayable = fee + amountToInvest;

	const fetchInvestorsPortfolio = async () => {
		setFetching(true);
		try {
			const response = await fetchInvestorInvestments();

			setInActiveInvestments(response?.inActiveInvestments);
			setActiveInvestments(response?.activeInvestments);
		} catch (error) {
			if (error?.message?.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			} else {
				toast.error("Failed to fetch data");
			}
		} finally {
			setFetching(false);
		}
	};

	const handleDeleteInactiveReq = async () => {
		setDeleteOngoingLoading(true);
		try {
			const response = await deleteInactiveFinance(currentFinanceReq?.objectId);

			if (response) {
				toast.success(
					`Request with the id of ${currentFinanceReq?.id} has been deleted.`
				);

				setTimeout(() => {
					window.location.reload();
				}, 500);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setDeleteOngoingLoading(false);
		}
	};

	const onCloseActivateModal = () => {
		setToggleActivateRequest({
			toggleRequestModal: false,
			activeRequestTab: 1,
		});
	};

	const onBeginAssetActivation = (data) => {
		dispatch(selectFinanceToActivate(data));
		setToggleActivateRequest({ toggleRequestModal: true, activeRequestTab: 1 });
	};

	// deduct activation fee from wallet
	const onProceedToDeductWallet = () => {
		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const details = {
			walletId: walletDetails?.id,
			amount: fee,
			narration: "asset-activation-fee",
		};

		if (user.id) {
			dispatch(
				deductPaymentFromUserWallet(
					details,
					"asset-activation-fee-successful",
					"asset activation"
				)
			);
		} else {
			dispatch(logoutUser());
		}
	};

	// function that updates request on activation success
	const onUpdateFinanceRequest = async () => {
		const message = "Request activation success.";
		const moreInfo = `The amount of N${fee} has been deducted for request activation and N${amountToInvest} has been invested in the finance request.`;

		const dataToUpdate = {
			funded: true,
			status: "active",
		};

		try {
			setUpdateReqLoading(true);

			const response = await updateOngoingReq(
				currentFinanceReq?.objectId,
				dataToUpdate,
				"AFBusinessCreate",
				message,
				moreInfo
			);

			if (response) {
				setToggleActivateRequest((prev) => ({
					...prev,
					activeRequestTab: 3,
				}));
			} else {
				throw "Unable to update data";
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setUpdateReqLoading(false);
		}
	};

	const onCloseActivateReqSuccess = () => {
		window.location.reload();
	};

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	// move funds to finance wallet on activate fee success
	useEffect(() => {
		if (deductWalletSuccess === "asset-activation-fee-successful") {
			dispatch(clearPaymentDeductionSuccess());

			const details = {
				amount: currentFinanceReq?.amountToInvest?.replaceAll(",", ""),
				source: "main",
				destination: walletType.financeInvestment,
				message: "investor-activates-and-funds-request",
			};

			dispatch(moveUserFunds(undefined, details));
		} else {
			return;
		}
	}, [deductWalletSuccess]);

	// effect to update finance request
	useEffect(() => {
		if (moveFundsSuccess === "investor-activates-and-funds-request") {
			dispatch(clearMoveFundsSuccess());
			onUpdateFinanceRequest();
		}
	}, [moveFundsSuccess]);

	// fetch investor's investments
	useEffect(() => {
		fetchInvestorsPortfolio();
	}, []);

	return (
		<>
			{toggleDeleteFinanceModal && (
				<ModalContainer modal={toggleDeleteFinanceModal}>
					<CustomConfirmModal
						text="You are about to delete this request. Click the delete button to continue."
						greenBtnText="Delete"
						onCloseBtnClick={() =>
							dispatch(onToggleDeleteFinanceModal(false, null))
						}
						isLoading={deleteOngoingLoading}
						greenBtnClick={handleDeleteInactiveReq}
					/>
				</ModalContainer>
			)}

			{toggleRequestModal && (
				<ModalContainer modal={toggleRequestModal}>
					<>
						{activeRequestTab === 1 && (
							<CustomConfirmModal
								onCloseBtnClick={onCloseActivateModal}
								greenBtnText="proceed"
								text={`NGN ${amountPayable} ( N${fee} "activation fee" ) will be deducted from your wallet to fully activate and list this request.`}
								greenBtnClick={() =>
									setToggleActivateRequest((prev) => ({
										...prev,
										activeRequestTab: 2,
									}))
								}
							/>
						)}
						{activeRequestTab === 2 && (
							<EnterPin
								btnText="Confirm to Pay"
								onCloseBtnClick={() =>
									setToggleActivateRequest({
										toggleRequestModal: false,
										activeRequestTab: 1,
									})
								}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isPinExist={accessPin !== undefined}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								proceedToCreatePin={() =>
									navigate("/dashboard/profile", { state: { currentTab: 2 } })
								}
								onRequestClick={onProceedToDeductWallet}
								isPaymentLoading={
									deductWalletLoading || moveFundsLoading || updateReqLoading
								}
							/>
						)}
						{activeRequestTab === 3 && (
							<SuccessCheckModal
								text={`The request with the id of ${currentFinanceReq?.objectId} has been activated and listed successfully.`}
								onRequestClick={onCloseActivateReqSuccess}
							/>
						)}
					</>
				</ModalContainer>
			)}

			<MobileNavDetails text="Finance" />

			<Container>
				<Helmet>
					<title>Fortcore - Finance</title>
				</Helmet>
				<Navbar text="Apprenticeship Finance Investment Board" />

				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="flex gap-1.5">
					<UrlTracker
						text="financing"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker text="finance investment board" isCurrentUrl />
				</div>
				<BackTracker
					text="finance investment board"
					onNavigate={() => navigate(-1)}
				/>
				<div className="mt-1 sm:mt-7"></div>
				<Box>
					<Stack
						direction={{ sm: "row" }}
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={{ xs: 4, sm: 0 }}
					>
						<Box width="100%">
							<Balance
								wallet={financeInvestmentWallet}
								cardTitle="Finance Investment Balance"
								isShowInvestBtn={true}
								onRequestClick={() =>
									navigate("/financing/new-transaction/investor-market-place")
								}
							/>
						</Box>
					</Stack>
				</Box>

				<Box mt={8} mb={3}>
					<Stack spacing={4}>
						{/* Inactive Investment  */}

						<Box>
							<Typography variant="h3" color="primary" mb={3}>
								Inactive Investments
							</Typography>
							{fetching ? (
								<CardSkeleton showHeader={false} />
							) : (
								<>
									{inActiveInvestments.length > 0 ? (
										<div className="flex gap-5 lg:gap-6 scroll-wrapper">
											{inActiveInvestments.map((data) => (
												<Box key={data.objectId}>
													<InactiveCreatedFinance
														financeReq={data}
														onBeginActivationProcess={() =>
															onBeginAssetActivation(data)
														}
														onDeleteRequest={() =>
															dispatch(onToggleDeleteFinanceModal(true, data))
														}
													/>
												</Box>
											))}
										</div>
									) : (
										// </Box>
										<NoInvestment text="You have no Inactive Investment" />
									)}
								</>
							)}
						</Box>

						{/* Active Investments  */}
						<Box>
							{activeInvestments.length > 0 ? (
								<div className="flex gap-4 sm:gap-6 items-center">
									<ActiveInvestmentHeader
										text="Active Investments"
										isActive={activeTab}
										onClick={() => setActiveTab(true)}
									/>
									<div className="border-r h-3"></div>
									<ActiveInvestmentHeader
										text="Return On Investments (ROI)"
										isActive={activeTab === false}
										onClick={() => setActiveTab(false)}
									/>
								</div>
							) : (
								<Typography variant="h3" color="primary">
									Active Investments
								</Typography>
							)}

							{fetching ? (
								<CardSkeleton showHeader={false} />
							) : (
								<>
									{activeInvestments.length > 0 && activeTab ? (
										<div style={slide} className="mt-6 scroll-wrapper">
											{activeInvestments.map((data) => {
												return (
													<Box key={data.objectId} mr={3}>
														<ActiveCreatedFinance financeReq={data} />
													</Box>
												);
											})}
										</div>
									) : activeInvestments.length > 0 && !activeTab ? (
										<AppFinancaROI data={activeInvestments} />
									) : (
										<NoInvestment text="You have no Active Investment" />
									)}
								</>
							)}
						</Box>

						{/* Balance Summary  */}
						{activeInvestments?.length ? (
							<Box mb={5}>
								<Typography variant="h3" color="primary">
									Balance Summary
								</Typography>

								<BalanceSummary data={activeInvestments} />
							</Box>
						) : null}
					</Stack>
				</Box>
			</Container>
		</>
	);
}

export default InvestmentPortfolio;

const slide = {
	display: "flex",
	alignItems: "center",
	overflowX: "auto", // Corrected property name
	width: "100%",
	gap: 2,
	flexWrap: "nowrap", // Allow horizontal scrolling
};

const NoInvestment = ({ text }) => {
	return (
		<Box sx={style}>
			<TiInfo size={25} />
			<Typography variant="body1">{text}</Typography>
		</Box>
	);
};

NoInvestment.propTypes = {
	text: PropTypes.string,
};

const style = {
	border: "1px solid var(--grey-300, #D4DEE2)",
	background: "rgba(246, 248, 249, 0.50)",
	p: 4,
	height: "178px",
	width: "100%",
	minWidth: "400px",
	borderRadius: "8px",
	mt: 2,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
};
