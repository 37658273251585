import React, { useEffect } from "react";
import PropTypes from 'prop-types'

const ModalContainer = ({ children, modal, zndex, onClose }) => {
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [modal])

  return (
    <div className={`!mt-0 grid place-items-center fixed inset-0 bg-[#393F4266] backdrop-blur-[2px]`}
      style={{ zIndex: zndex ? zndex : '12' }} onClick={onClose}>      {children}
    </div>
  )
}
export default ModalContainer

ModalContainer.propTypes = {
  children: PropTypes.element,
  onClose: PropTypes.func,
  modal: PropTypes.bool,
  zndex: PropTypes.string
}

export const DesktopSideModal = ({ children, modal }) => {
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [modal])

  return (
    <div className="!mt-0 flex items-center fixed inset-0 bg-[#393F4266] backdrop-blur-[2px] z-[12]" >
      {children}
    </div>
  )
}


DesktopSideModal.propTypes = {
  children: PropTypes.element,
  modal: PropTypes.bool,
}