import {
	CLOSE_ADD_BANK_ACCOUNT_MODAL,
	CLOSE_ADD_BVN_MODAL,
	OPEN_ADD_BANK_ACCOUNT_MODAL,
	OPEN_ADD_BVN_MODAL,
	OPEN_MOBILE_MENU_MODAL,
	CLOSE_MOBILE_MENU_MODAL,
	GET_PHONE_OTP_LOADING,
	GET_PHONE_OTP_SUCCESS,
	GET_PHONE_OTP_ERROR,
	DISABLE_PHONE_BUTTON_WHILE_COUNT,
	VERIFY_PHONE_OTP_LOADING,
	VERIFY_PHONE_OTP_SUCCESS,
	VERIFY_PHONE_OTP_ERROR,
	GET_EMAIL_OTP_LOADING,
	GET_EMAIL_OTP_SUCCESS,
	GET_EMAIL_OTP_ERROR,
	VERIFY_EMAIL_OTP_LOADING,
	VERIFY_EMAIL_OTP_SUCCESS,
	VERIFY_EMAIL_OTP_ERROR,
	DISABLE_EMAIL_BUTTON_WHILE_COUNT,
	GET_CURRENT_USER,
	ADD_BVN_LOADING,
	ADD_BVN_SUCCESS,
	ADD_BVN_ERROR,
	OPEN_LOGOUT_MODAL,
	CLOSE_LOGOUT_MODAL,
	LOGOUT_USER,
	GET_CREDIT_SCORE_LOADING,
	GET_CREDIT_SCORE_SUCCESS,
	GET_CREDIT_SCORE_ERROR,
	GET_UPCOIMING_PAYMENTS_LOADING,
	GET_UPCOIMING_PAYMENTS_SUCCESS,
	GET_UPCOIMING_PAYMENTS_ERROR,
	OPEN_CUSTOM_ALERT,
	CLOSE_CUSTOM_ALERT,
	OPEN_AUTHORIZE_AUTODEBIT_MODAL,
	CLOSE_AUTHORIZE_AUTODEBIT_MODAL,
	OPEN_AUTHORIZE_INSURANCE_MODAL,
	CLOSE_AUTHORIZE_INSURANCE_MODAL,
	OPEN_AUTHORIZE_GUARANTOR_MODAL,
	CLOSE_AUTHORIZE_GUARANTOR_MODAL,
	OPEN_AUTHORIZE_CREDITASSESSMENT_MODAL,
	CLOSE_AUTHORIZE_CREDITASSESSMENT_MODAL,
	ADD_BANK_ACCOUNT_LOADING,
	ADD_BANK_ACCOUNT_SUCCESS,
	ADD_BANK_ACCOUNT_ERROR,
	ADD_BANK_TO_PAGE,
	CURRENT_USER_DATA,
	FETCH_GUARANTORS_DETAILS_LOADING,
	FETCH_GUARANTORS_DETAILS_SUCCESS,
	FETCH_GUARANTORS_DETAILS_ERROR,
	SAVE_GUARANTORS_DETAILS_LOADING,
	SAVE_GUARANTORS_DETAILS_ERROR,
	SAVE_GUARANTORS_DETAILS_SUCCESS,
	VERIFY_EMAIL_FOR_TRANSACTIONS_LOADING,
	VERIFY_EMAIL_FOR_TRANSACTIONS_SUCCESS,
	VERIFY_EMAIL_FOR_TRANSACTIONS_ERROR,
	CLEAR_EMAIL_VERIFICATION_ON_SUCCESS,
	CREATE_ACCESSPIN_LOADING,
	CREATE_ACCESSPIN_SUCCESS,
	CREATE_ACCESSPIN_ERROR,
	CLEAR_PIN_CREATION_SUCCESS,
	CLEAR_REDIRECT,
	REDIRECT,
	UPDATE_USER_ACCOUNT_ERROR,
	UPDATE_USER_ACCOUNT_LOADING,
	UPDATE_USER_ACCOUNT_SUCCESS,
	UPDATE_PASSWORD_LOADING,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_PASSWORD_ERROR,
	CLEAR_UPDATE_ACCOUNT_SUCCESS,
	FETCH_ALL_BANKS_LOADING,
	FETCH_ALL_BANKS_SUCCESS,
	FETCH_ALL_BANKS_ERROR,
	VERIFY_BANK_ACCOUNT_LOADING,
	VERIFY_BANK_ACCOUNT_SUCCESS,
	VERIFY_BANK_ACCOUNT_ERROR,
	CLEAR_VERIFY_ACCOUNT_SUCCESS,
	NETWORK_ERROR_TRUE,
	CLEAR_NETWORK_ERROR,
	OPEN_RESET_PIN_MODAL,
	CLOSE_RESET_PIN_MODAL,
	OPEN_MINI_LOGIN_MODAL,
	CLOSE_MINI_LOGIN_MODAL,
	TOGGLE_ACTIVATE_WALLET_MODAL,
	SAVE_HOME_ADDRESS,
} from "./authTypes";

const initialState = {
	currentUser: null,
	toggleAddBankAccountModal: false,
	toggleAddBvnModal: false,
	toggleMobileMenuModal: false,
	phoneOtpMessageLoading: false,
	phoneOtpMessageSuccess: null,
	verifyPhoneOtpLoading: false,
	verifyPhoneOtpSuccess: null,
	currentPhone: null,
	isShowPhoneTimer: false,
	emailOtpMessageLoading: false,
	emailOtpMessageSuccess: null,
	verifyEmailOtpLoading: false,
	verifyEmailOtpSuccess: null,
	currentEmail: null,
	isShowEmailTimer: false,
	verifyBvnLoading: false,
	verifyBvnSuccess: null,
	bankAccountLoading: false,
	bankAccountSuccess: null,
	toggleLogoutModal: false,
	creditScoreLoading: false,
	creditScoreSuccess: 0,
	upcomingPaymentsLoading: false,
	toggleAlert: false,
	upcomingPaymentsSuccess: null,
	toggleAuthorizeAutoDebitModal: false,
	toggleAuthorizeInsuranceModal: false,
	toggleAuthorizeGuarantorModal: false,
	toggleAuthorizeCreditAssessmentModal: false,
	fetchGuarantorsFormLoading: false,
	savingGuarantorsFormLoading: false,
	guarantorsFormSuccess: null,
	verifyEmailForTransactionsLoading: false,
	verifyEmailForTransactionsSuccess: null,
	accessPinLoading: false,
	accessPinSuccess: null,
	redirectUrl: null,
	updatingUserAccountLoading: false,
	updatingUserAccountSuccess: null,
	updatingPasswordLoading: false,
	updatingPasswordSuccess: "",
	customerWallet: null,
	toggleAddFundsModal: false,
	paymentAccountLoading: false,
	paymentAccountSuccess: null,
	fetchBanksLoading: false,
	fetchBanksSuccess: null,
	verifyBankLoading: false,
	verfiyBankSuccess: null,
	bvnSuccessMsg: null,
	networkError: null,
	toggleResetPinModal: false,
	toggleMiniLoginModal: false,
	toggleActivateWalletModal: false,
};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CURRENT_USER:
			return {
				...state,
				currentUser: action.payload,
				toggleLogoutModal: false,
			};
		case CURRENT_USER_DATA:
			return {
				...state,
				currentUser: action.payload?.toJSON(),
				toggleLogoutModal: false,
			};
		case OPEN_ADD_BANK_ACCOUNT_MODAL:
			return {
				...state,
				toggleAddBankAccountModal: true,
				verfiyBankSuccess: null,
			};
		case CLOSE_ADD_BANK_ACCOUNT_MODAL:
			return { ...state, toggleAddBankAccountModal: false };
		case OPEN_ADD_BVN_MODAL:
			return { ...state, toggleAddBvnModal: true };
		case CLOSE_ADD_BVN_MODAL:
			return { ...state, toggleAddBvnModal: false, bvnSuccessMsg: null };
		case OPEN_MOBILE_MENU_MODAL:
			return { ...state, toggleMobileMenuModal: true };
		case CLOSE_MOBILE_MENU_MODAL:
			return { ...state, toggleMobileMenuModal: false };
		case GET_PHONE_OTP_LOADING:
			return {
				...state,
				phoneOtpMessageLoading: true,
				isShowPhoneTimer: false,
				verifyPhoneOtpSuccess: null,
				currentPhone: null,
			};
		case GET_PHONE_OTP_SUCCESS:
			return {
				...state,
				phoneOtpMessageLoading: false,
				phoneOtpMessageSuccess: action.payload,
				isShowPhoneTimer: true,
			};
		case GET_PHONE_OTP_ERROR:
			return { ...state, phoneOtpMessageLoading: false };
		case VERIFY_PHONE_OTP_LOADING:
			return { ...state, verifyPhoneOtpLoading: true, isShowPhoneTimer: false };
		case VERIFY_PHONE_OTP_SUCCESS:
			return {
				...state,
				verifyPhoneOtpLoading: false,
				verifyPhoneOtpSuccess: action.payload.message,
				currentPhone: action.payload.phone,
				phoneOtpMessageSuccess: null,
				isShowPhoneTimer: false,
			};
		case VERIFY_PHONE_OTP_ERROR:
			return { ...state, verifyPhoneOtpLoading: false };
		case DISABLE_PHONE_BUTTON_WHILE_COUNT:
			return { ...state, isShowPhoneTimer: false };
		case GET_EMAIL_OTP_LOADING:
			return {
				...state,
				emailOtpMessageLoading: true,
				isShowEmailTimer: false,
				verifyEmailOtpSuccess: null,
				currentEmail: null,
			};
		case GET_EMAIL_OTP_SUCCESS:
			return {
				...state,
				emailOtpMessageLoading: false,
				emailOtpMessageSuccess: action.payload,
				isShowEmailTimer: true,
			};
		case GET_EMAIL_OTP_ERROR:
			return { ...state, emailOtpMessageLoading: false };
		case VERIFY_EMAIL_OTP_LOADING:
			return { ...state, verifyEmailOtpLoading: true, isShowEmailTimer: false };
		case VERIFY_EMAIL_OTP_SUCCESS:
			return {
				...state,
				verifyEmailOtpLoading: false,
				verifyEmailOtpSuccess: action.payload.message,
				currentEmail: action.payload.email,
				emailOtpMessageSuccess: null,
				isShowEmailTimer: false,
			};
		case VERIFY_EMAIL_OTP_ERROR:
			return { ...state, verifyEmailOtpLoading: false };
		case DISABLE_EMAIL_BUTTON_WHILE_COUNT:
			return { ...state, isShowEmailTimer: false };
		case ADD_BVN_LOADING:
			return { ...state, verifyBvnLoading: true };
		case ADD_BVN_SUCCESS:
			return {
				...state,
				verifyBvnLoading: false,
				verifyBvnSuccess: action.payload.data,
				bvnSuccessMsg: action.payload.message,
			};
		case ADD_BVN_ERROR:
			return { ...state, verifyBvnLoading: false };
		case ADD_BANK_ACCOUNT_LOADING:
			return { ...state, bankAccountLoading: true };
		case ADD_BANK_ACCOUNT_SUCCESS:
			return {
				...state,
				bankAccountLoading: false,
				bankAccountSuccess: action.payload,
			};
		case ADD_BANK_ACCOUNT_ERROR:
			return { ...state, bankAccountLoading: false };
		case OPEN_LOGOUT_MODAL:
			return {
				...state,
				toggleLogoutModal: true,
				toggleMobileMenuModal: false,
			};
		case CLOSE_LOGOUT_MODAL:
			return {
				...state,
				toggleLogoutModal: false,
				toggleMobileMenuModal: false,
			};
		case ADD_BANK_TO_PAGE:
			return { ...state, bankToPage: action.payload, bankAccountSuccess: null };
		case GET_CREDIT_SCORE_LOADING:
			return { ...state, creditScoreLoading: true };
		case GET_CREDIT_SCORE_SUCCESS:
			return {
				...state,
				creditScoreSuccess: action.payload,
				creditScoreLoading: false,
			};
		case GET_CREDIT_SCORE_ERROR:
			return { ...state, creditScoreLoading: false };
		case GET_UPCOIMING_PAYMENTS_LOADING:
			return { ...state, upcomingPaymentsLoading: true };
		case GET_UPCOIMING_PAYMENTS_SUCCESS:
			return {
				...state,
				upcomingPaymentsSuccess: action.payload,
				upcomingPaymentsLoading: false,
			};
		case GET_UPCOIMING_PAYMENTS_ERROR:
			return { ...state, upcomingPaymentsLoading: false };
		case OPEN_CUSTOM_ALERT:
			return { ...state, toggleAlert: true };
		case CLOSE_CUSTOM_ALERT:
			return { ...state, toggleAlert: false };
		case OPEN_AUTHORIZE_AUTODEBIT_MODAL:
			return { ...state, toggleAuthorizeAutoDebitModal: true };
		case CLOSE_AUTHORIZE_AUTODEBIT_MODAL:
			return { ...state, toggleAuthorizeAutoDebitModal: false };
		case OPEN_AUTHORIZE_INSURANCE_MODAL:
			return { ...state, toggleAuthorizeInsuranceModal: true };
		case CLOSE_AUTHORIZE_INSURANCE_MODAL:
			return { ...state, toggleAuthorizeInsuranceModal: false };
		case OPEN_AUTHORIZE_GUARANTOR_MODAL:
			return { ...state, toggleAuthorizeGuarantorModal: true };
		case CLOSE_AUTHORIZE_GUARANTOR_MODAL:
			return { ...state, toggleAuthorizeGuarantorModal: false };
		case OPEN_AUTHORIZE_CREDITASSESSMENT_MODAL:
			return { ...state, toggleAuthorizeCreditAssessmentModal: true };
		case CLOSE_AUTHORIZE_CREDITASSESSMENT_MODAL:
			return { ...state, toggleAuthorizeCreditAssessmentModal: false };
		case LOGOUT_USER:
			return {
				toggleLogoutModal: false,
				currentUser: null,
				redirect: null,
				toggleAuthorizeAutoDebitModal: false,
				toggleAuthorizeInsuranceModal: false,
				toggleAuthorizeGuarantorModal: false,
				toggleAuthorizeCreditAssessmentModal: false,
			};
		case FETCH_GUARANTORS_DETAILS_LOADING:
			return { ...state, fetchGuarantorsFormLoading: true };
		case FETCH_GUARANTORS_DETAILS_SUCCESS:
			return {
				...state,
				guarantorsFormSuccess: action.payload,
				fetchGuarantorsFormLoading: false,
			};
		case FETCH_GUARANTORS_DETAILS_ERROR:
			return { ...state, fetchGuarantorsFormLoading: false };
		case SAVE_GUARANTORS_DETAILS_LOADING:
			return { ...state, savingGuarantorsFormLoading: true };
		case SAVE_GUARANTORS_DETAILS_SUCCESS:
			return {
				...state,
				savingGuarantorsFormLoading: false,
				guarantorsFormSuccess: action.payload,
			};
		case SAVE_GUARANTORS_DETAILS_ERROR:
			return { ...state, savingGuarantorsFormLoading: false };
		case VERIFY_EMAIL_FOR_TRANSACTIONS_LOADING:
			return {
				...state,
				verifyEmailForTransactionsLoading: true,
				isShowEmailTimer: false,
			};
		case VERIFY_EMAIL_FOR_TRANSACTIONS_SUCCESS:
			return {
				...state,
				verifyEmailForTransactionsLoading: false,
				verifyEmailForTransactionsSuccess: action.payload.message,
				emailOtpMessageSuccess: null,
				isShowEmailTimer: false,
			};
		case VERIFY_EMAIL_FOR_TRANSACTIONS_ERROR:
			return { ...state, verifyEmailForTransactionsLoading: false };
		case CLEAR_EMAIL_VERIFICATION_ON_SUCCESS:
			return { ...state, verifyEmailForTransactionsSuccess: null };
		case CREATE_ACCESSPIN_LOADING:
			return { ...state, accessPinLoading: true };
		case CREATE_ACCESSPIN_SUCCESS:
			return {
				...state,
				currentUser: action.payload?.toJSON(),
				accessPinSuccess: "pin creation success",
				accessPinLoading: false,
			};
		case CREATE_ACCESSPIN_ERROR:
			return { ...state, accessPinLoading: false };
		case CLEAR_PIN_CREATION_SUCCESS:
			return { ...state, accessPinSuccess: null };
		case REDIRECT:
			return { ...state, redirectUrl: action.payload };
		case CLEAR_REDIRECT:
			return { ...state, redirectUrl: null };
		case UPDATE_USER_ACCOUNT_LOADING:
			return { ...state, updatingUserAccountLoading: true };
		case UPDATE_USER_ACCOUNT_SUCCESS:
			return {
				...state,
				currentUser: action.payload.parseUserData?.toJSON(),
				updatingUserAccountSuccess: action.payload?.successMsg,
				updatingUserAccountLoading: false,
			};
		case UPDATE_USER_ACCOUNT_ERROR:
			return { ...state, updatingUserAccountLoading: false };
		case UPDATE_PASSWORD_LOADING:
			return { ...state, updatingPasswordLoading: true };
		case UPDATE_PASSWORD_SUCCESS:
			return {
				...state,
				updatingPasswordSuccess: "password update success",
				currentUser: action.payload,
				updatingPasswordLoading: false,
			};
		case UPDATE_PASSWORD_ERROR:
			return { ...state, updatingPasswordLoading: false };
		case CLEAR_UPDATE_ACCOUNT_SUCCESS:
			return {
				...state,
				updatingPasswordSuccess: null,
				updatingUserAccountSuccess: null,
			};
		case FETCH_ALL_BANKS_LOADING:
			return { ...state, fetchBanksLoading: true, fetchBanksSuccess: null };
		case FETCH_ALL_BANKS_SUCCESS:
			return {
				...state,
				fetchBanksSuccess: action.payload,
				fetchBanksLoading: false,
			};
		case FETCH_ALL_BANKS_ERROR:
			return { ...state, fetchBanksLoading: false };
		case VERIFY_BANK_ACCOUNT_LOADING:
			return { ...state, verifyBankLoading: true, verfiyBankSuccess: null };
		case VERIFY_BANK_ACCOUNT_SUCCESS:
			return {
				...state,
				verfiyBankSuccess: action.payload,
				verifyBankLoading: false,
			};
		case CLEAR_VERIFY_ACCOUNT_SUCCESS:
			return { ...state, verfiyBankSuccess: null };
		case VERIFY_BANK_ACCOUNT_ERROR:
			return { ...state, verifyBankLoading: false };
		case NETWORK_ERROR_TRUE:
			return { ...state, networkError: action.payload };
		case OPEN_RESET_PIN_MODAL:
			return { ...state, toggleResetPinModal: true };
		case CLOSE_RESET_PIN_MODAL:
			return { ...state, toggleResetPinModal: false };
		case CLEAR_NETWORK_ERROR:
			return { ...state, networkError: null };
		case OPEN_MINI_LOGIN_MODAL:
			return { ...state, toggleMiniLoginModal: true };
		case CLOSE_MINI_LOGIN_MODAL:
			return { ...state, toggleMiniLoginModal: false };
		case TOGGLE_ACTIVATE_WALLET_MODAL:
			return { ...state, toggleActivateWalletModal: action.payload };
		case SAVE_HOME_ADDRESS:
			return { ...state, homeAddresFromBVN: action.payload };
		default:
			return state;
	}
};
