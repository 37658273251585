/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import {
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	MenuItem,
	Modal,
	Stack,
	TextField,
	Typography,
	IconButton,
} from "@mui/material";
import { HiLockClosed } from "react-icons/hi";
import PropTypes from "prop-types";
import { GrFormClose } from "react-icons/gr";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MobileNavDetails } from "../../../../../../layout/MobileNavbar";
import Container from "../../../../../../layout/Container";
import Navbar from "../../../../../../layout/Navbar";
import { CheckIcon, InfoIcon } from "../../../../../../layout/assets";
import userContext from "../../../../../../context/UserContext";
import { BiSolidLock } from "react-icons/bi";
import theme from "../../../../../../components/mui/theme";
import Parse from "../../../../../../api/ApiConfig";
import {
	businessTypes,
	durationFrame,
	getProfilePercentage,
	walletType,
} from "../../../../../../common/utils";
import {
	CustomConfirmModal,
	FatBackTracker,
	SuccessCheckModal,
	UrlTracker,
} from "../../../../../../layout/CustomComponents";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer, {
	DesktopSideModal,
} from "../../../../../../components/ModalContainer";
import {
	closeInvestorForm,
	openInvestorForm,
} from "../../../../../../redux/lease/leaseActions";
import InvestorForm from "../../../../../leasing/investor/InvestorForm";
import PaymentOption from "../../../../../../components/payments/PaymentOption";
import { EnterPin } from "../../../../../../components/payments/PinComponent";
import { clearMoveFundsSuccess } from "../../../../../../redux/payments/paymentActions";
import { moveUserFunds } from "../../../../../../redux/payments/paymentServices";
import { submitAppFinanceRequest } from "../../../../../../redux/financing/financeServices";
import { logoutUser } from "../../../../../../redux/authRedux/authServices";

const durationList = [];

for (let i = 1; i <= 24; i++) {
	durationList.push({
		value: i,
		label: i,
	});
}

function AfInvestorSummary() {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { updatingUserAccountSuccess } = useSelector((state) => state.auth);
	const { toggleInvestorForm } = useSelector((state) => state.lease);
	const { investorFinanceRequest } = useSelector((state) => state.finance);
	const { customerWallet, moveFundsLoading, moveFundsSuccess } = useSelector(
		(state) => state.payment
	);

	const { investorMarketForm, setInvestorMarketForm, currentUserData, afRate } =
		useContext(userContext);

	const [loading, setLoading] = useState(false);
	const [negotiateModal, setNegotiateModal] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");

	const [toggleFundRequest, setToggleFundRequest] = useState({
		activeFundTab: 1,
		toggleFundModal: false,
	});

	const { activeFundTab, toggleFundModal } = toggleFundRequest;

	const accessPin = currentUserData?.accessPin || "";

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	useEffect(() => {
		if (investorMarketForm.tenure) {
			const [time, text] = investorMarketForm.tenure.split(" ");

			setInvestorMarketForm((prevState) => ({
				...prevState,
				durationText: text,
				durationTime: time,
			}));
		}
	}, [investorMarketForm.tenure]);
	// eslint-disable-next-line no-undef

	// Function to handle form input changes
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setInvestorMarketForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleNegotiateForm = (e) => {
		e.preventDefault();
		setFormSubmitted(true);
		setNegotiateModal(false);
	};

	const investorProfileValidation = {
		phoneNumber: currentUserData?.phone,
		phoneNumberVerified: currentUserData?.phoneVerified,
		homeAddress: currentUserData?.homeAddress,
		bvn: currentUserData?.bvn,
		bankName: currentUserData?.bankName,
		accountNumber: currentUserData?.accountNumber,
		netWorth: currentUserData?.netWorth,
		investorSignature: currentUserData?.investorSignature,
		witnessSignature: currentUserData?.witnessSignature,
		idType: currentUserData?.idType,
		idNumber: currentUserData?.idNo,
	};

	const investorProfilePercentage = getProfilePercentage(
		investorProfileValidation
	);

	const handleFund = (e) => {
		e.preventDefault();

		if (investorProfilePercentage > 45) {
			setToggleFundRequest({ toggleFundModal: true, activeFundTab: 1 });
		} else {
			dispatch(openInvestorForm());
		}
	};

	const onInvestorFormSaveSuccess = () => {
		dispatch(closeInvestorForm());
		setToggleFundRequest({ toggleFundModal: true, activeFundTab: 1 });
	};

	const onCloseFundModal = () => {
		setToggleFundRequest({ toggleFundModal: false, activeFundTab: 1 });
	};

	const amountPayable = Number(
		investorFinanceRequest?.amountToRaise?.replaceAll(",", "")
	);

	const onFundFinanceRequest = () => {
		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const details = {
			amount: String(amountPayable),
			source: "main",
			destination: walletType.financeInvestment,
			message: "investor-funds-finance-request",
		};

		if (Parse.User.current().id) {
			dispatch(moveUserFunds(undefined, details));
		} else {
			dispatch(logoutUser());
		}
	};

	const id = investorFinanceRequest?.objectId;

	const moreDetails = `You have successfully invested the amount of NGN ${investorFinanceRequest?.amountToRaise} to the finance request with the trasaction ID of ${id}`;

	const onUpdateFinanceRequest = async () => {
		const dataToAdd = {
			investors: [Parse.User.current().id],
			investorsInfo: [
				{
					investorName: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
					investorPhone: currentUserData?.phone,
					investorEmail: currentUserData?.email,
					investorId: Parse.User.current().id,
					investorAddress: currentUserData?.homeAddress,
					amountPaid: investorFinanceRequest?.amountToRaise,
				},
			],
			funded: true,
		};

		const message = "Newly Funded Finance Request";

		setLoading(true);

		try {
			const response = await submitAppFinanceRequest(
				id,
				dataToAdd,
				message,
				moreDetails
			);

			if (response) {
				setToggleFundRequest((prev) => ({ ...prev, activeFundTab: 4 }));
			} else {
				throw "Unable to save information";
			}
		} catch (error) {
			toast.error;
		} finally {
			setLoading(false);
		}
	};

	const onProceedToPortfolio = () => {
		navigate("/financing/investment-portfolio");
		setTimeout(() => {
			setToggleFundRequest({ toggleFundModal: false, activeFundTab: 1 });
		}, 500);
	};

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	useEffect(() => {
		if (moveFundsSuccess === "investor-funds-finance-request") {
			dispatch(clearMoveFundsSuccess());

			onUpdateFinanceRequest();
		}
	}, [moveFundsSuccess]);

	if (!id) {
		return <Navigate to="/financing/new-transaction/investor-market-place" />;
	}

	return (
		<>
			{toggleInvestorForm && (
				<DesktopSideModal modal={toggleInvestorForm}>
					<>
						{updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onInvestorFormSaveSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							<InvestorForm
								onCloseForm={() => dispatch(closeInvestorForm())}
								onNoInvestorFormSubmit={onInvestorFormSaveSuccess}
							/>
						)}
					</>
				</DesktopSideModal>
			)}

			{toggleFundModal && (
				<ModalContainer modal={toggleFundModal}>
					<>
						{activeFundTab === 1 && (
							<CustomConfirmModal
								onCloseBtnClick={onCloseFundModal}
								greenBtnText="proceed"
								text={`You are about to fund this request with the amount of NGN ${investorFinanceRequest?.amountToRaise}. `}
								greenBtnClick={() =>
									setToggleFundRequest((prev) => ({
										...prev,
										activeFundTab: 2,
									}))
								}
							/>
						)}
						{activeFundTab === 2 && (
							<PaymentOption
								onClose={onCloseFundModal}
								onWalletSelect={() =>
									setToggleFundRequest((prev) => ({
										...prev,
										activeFundTab: 3,
									}))
								}
							/>
						)}
						{activeFundTab === 3 && (
							<EnterPin
								btnText="Confirm to Pay"
								onCloseBtnClick={() =>
									setToggleFundRequest((prev) => ({
										...prev,
										activeFundTab: 2,
									}))
								}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isPinExist={accessPin !== undefined}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								proceedToCreatePin={() =>
									navigate("/dashboard/profile", { state: { currentTab: 2 } })
								}
								onRequestClick={onFundFinanceRequest}
								isPaymentLoading={moveFundsLoading || loading}
							/>
						)}
						{activeFundTab === 4 && (
							<SuccessCheckModal
								text={moreDetails}
								onRequestClick={onProceedToPortfolio}
								isShowBtn={true}
								btnText="view portfolio"
							/>
						)}
					</>
				</ModalContainer>
			)}

			<MobileNavDetails text="Apprentice Financing" />
			<Container>
				<Helmet>
					<title>Fortcore - New Transaction</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="hidden sm:flex gap-1.5 sm:mt-10">
					<UrlTracker
						text="apprenticeship finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="investor market place"
						onNavigate={() =>
							navigate("/financing/new-transaction/investor-market-place")
						}
					/>
					<UrlTracker text="finance summary" isCurrentUrl />
				</div>

				<div className="mb-3 sm:hidden">
					<FatBackTracker
						text="transaction summary"
						onNavigate={() =>
							navigate("/financing/new-transaction/investor-market-place")
						}
					/>
				</div>

				<div className="sm:mt-10"></div>

				<Box
					sx={{ background: "#F6F7F8", padding: "20px", borderRadius: "8px" }}
				>
					<Typography variant="h4" color="primary.main">
						Summary
					</Typography>
					<div className="mt-3"></div>
					<Typography
						variant="caption"
						color="primary.main"
						sx={{ textTransform: "uppercase" }}
					>
						Transaction ID: {investorMarketForm.objectId}
					</Typography>

					<Box
						mt={3}
						mb={3}
						sx={{
							background: "var(--grey-100, #EEF2F3)",
							border: "1px solid var(--grey-300, #D4DEE2)",
							borderRadius: "10px",
							padding: "20px",
							display: { sm: "flex" },
							alignItems: "center",
						}}
					>
						<Box
							width={{ xs: "100%", sm: "216px" }}
							height="209px"
							mr={{ sm: 2 }}
							mb={{ xs: 2, sm: 0 }}
						>
							<img
								src={
									investorMarketForm?.productPhoto
										? investorMarketForm?.productPhoto[0]?.url
										: investorMarketForm?.productPhotos[0]?.url
								}
								alt=""
								style={{
									width: "100%",
									height: "209px",
									objectFit: "cover",
								}}
							/>
						</Box>
						<Stack spacing={1.5}>
							<Typography
								variant="h3"
								sx={{ color: "#222627", textTransform: "capitalize" }}
								component="span"
							>
								{investorMarketForm.businessType}
							</Typography>
							<Typography variant="body1" color="initial">
								Asset Value: &nbsp;
								<Typography variant="h4" color="primary" component="span">
									NGN {investorMarketForm.amount}
								</Typography>
							</Typography>
							<Typography
								variant="body1"
								color="initial"
								sx={{ textTransform: "capitalize" }}
							>
								Interest: {afRate.interest.value} P/A
							</Typography>

							<Typography variant="body1" color="initial">
								Tenure: {investorMarketForm.tenure}
							</Typography>

							<Box></Box>
						</Stack>
					</Box>

					<Box width="100%">
						<form onSubmit={handleFund}>
							<Stack spacing={4}>
								{/* Amount */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Amount :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="amount"
											value={investorMarketForm.amount}
											// onChange={handleInputChange}
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
											}}
											sx={{ width: "100%" }}
										/>
									</div>
								</div>

								{/* Expected Profit */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Expected Profit :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="expectedProfit"
											value={investorMarketForm.expectedProfit}
											// onChange={handleInputChange}
											placeholder="Amount"
											sx={{ width: "100%" }}
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>

								{/* Duration Of Apprentice Financing */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Duration :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<Stack direction="row" alignItems="center" spacing={2}>
											<TextField
												type="text"
												name="durationText"
												value={investorMarketForm.durationText}
												// onChange={handleInputChange}
												placeholder="Description"
												sx={{
													width: "100%",
													textTransform: "capitalize",
													".MuiInputBase-input, .MuiOutlinedInput-input, .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
														{
															textTransform: "capitalize",
														},
													color: "#393F42",
												}}
												required
												readOnly
											/>
											<TextField
												type="text"
												name="durationTime"
												value={investorMarketForm.durationTime}
												// onChange={handleInputChange}
												placeholder="Description"
												sx={{ width: "100%" }}
												required
												readOnly
											/>
										</Stack>
									</div>
								</div>

								{/* Interest Rate */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Interest:
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="interestRate"
											value={investorMarketForm.interest}
											// onChange={handleInputChange}
											placeholder="Automatically Generated"
											sx={{ width: "100%" }}
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="start">
														<Stack
															direction="row"
															alignItems="center"
															spacing={0.5}
														>
															<Typography
																color="primary"
																sx={{ fontFamily: "ZabalMedium" }}
															>
																({afRate.interest.value}P/A)
															</Typography>
															<BiSolidLock
																size={15}
																color={theme.palette.primary.main}
															/>
														</Stack>
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>

								{/* Program Savings  */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										<Stack direction="row" alignItems="center">
											Program Savings : &nbsp; <InfoIcon />
										</Stack>
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="programSavings"
											value={investorMarketForm.programSavings}
											// onChange={handleInputChange}
											placeholder="Automatically Generated"
											sx={{ width: "100%" }}
											inputMode="numeric"
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="start">
														<Stack
															direction="row"
															alignItems="center"
															spacing={0.5}
														>
															<BiSolidLock
																size={15}
																color={theme.palette.primary.main}
															/>
														</Stack>
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>

								{/* Business Type */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Business Type :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="businessDescription"
											value={investorMarketForm.businessType}
											// onChange={handleInputChange}
											placeholder="Description"
											sx={{ width: "100%" }}
											InputProps={{
												readOnly: true,
											}}
											required
										/>
									</div>
								</div>

								{/* Description Of Your Business */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Description Of Business :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="businessDescription"
											value={investorMarketForm.businessDescription}
											// onChange={handleInputChange}
											placeholder="Description"
											sx={{ width: "100%" }}
											required
										/>
									</div>
								</div>
							</Stack>

							<Box pt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
								<Stack direction="row" alignItems="center" spacing={1}>
									<Button
										type="button"
										variant="outlined"
										onClick={() => setNegotiateModal(true)}
									>
										Negotiate
									</Button>

									<Button
										type="submit"
										variant="contained"
										// disabled={
										//   Object.values(marketPlace).some(
										//     (value) => value === ""
										//   ) ||
										//   financeType === "" ||
										//   businessType === ""
										// }
									>
										{loading ? (
											<CircularProgress size="20px" sx={{ color: "#fff" }} />
										) : (
											"Fund"
										)}
									</Button>
								</Stack>
							</Box>
						</form>
					</Box>
				</Box>
			</Container>

			{negotiateModal ? (
				<NegotiateModal
					open={negotiateModal}
					close={() => setNegotiateModal(false)}
				>
					<Box component="form" onSubmit={handleNegotiateForm}>
						<Stack spacing={2}>
							{/* Lease Tenure */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Apprentice Finance Tenure :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="text"
										name="tenure"
										value={investorMarketForm.tenure}
										// onChange={handleInputChange}
										placeholder="Description"
										sx={{ width: "100%" }}
										InputProps={{
											readOnly: true,
											endAdornment: (
												<InputAdornment position="start">
													<HiLockClosed />
												</InputAdornment>
											),
										}}
										required
									/>
								</div>
							</div>

							{/* Make A proposal */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Make a Proposal :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									{/* <TextField
                    type="text"
                    name="negotiateTenure"
                    value={investorMarketForm.negotiateTenure}
                    onChange={handleInputChange}
                    sx={{ width: "100%" }}
                    required
                  /> */}

									<Stack direction="row" alignItems="center" spacing={2}>
										<TextField
											select
											name="durationText"
											value={investorMarketForm.negotiateTenureText}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
										>
											{durationFrame.map((option, index) => (
												<MenuItem
													key={index}
													value={option.value}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>

										<TextField
											select
											name="durationTime"
											value={investorMarketForm.negotiateTenureTime}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
										>
											{durationList.map((option, index) => (
												<MenuItem
													key={index}
													value={option.value}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Stack>
								</div>
							</div>

							{/* Interest Rate */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Interest Rate :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="text"
										name="interest"
										value={investorMarketForm.interest}
										// onChange={handleInputChange}
										sx={{ width: "100%" }}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">NGN</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="start">
													<Stack direction="row" alignItems="center">
														<Typography color="primary">(10%P/A)</Typography>
														<HiLockClosed />
													</Stack>
												</InputAdornment>
											),
										}}
										required
									/>
								</div>
							</div>

							{/* Make A proposal */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Make a Proposal :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="number"
										name="negotiateInterestRate"
										value={investorMarketForm.negotiateInterestRate}
										onChange={handleInputChange}
										sx={{ width: "100%" }}
										inputMode="numeric"
										required
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">%</InputAdornment>
											),
										}}
									/>
								</div>
							</div>

							{/* Business Interest */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Business Interest :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="text"
										name="tenure"
										value={investorMarketForm.businessType}
										// onChange={handleInputChange}
										sx={{ width: "100%" }}
										InputProps={{
											readOnly: true,
											style: {
												textTransform: "capitalize",
											},
											endAdornment: (
												<InputAdornment position="start">
													<HiLockClosed />
												</InputAdornment>
											),
										}}
										required
									/>
								</div>
							</div>

							{/* Make A proposal */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Make a Proposal :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									{/* <TextField
										type="text"
										name="negotiateStructure"
										value={investorMarketForm.negotiateStructure}
										onChange={handleInputChange}
										sx={{ width: "100%" }}
										required
									/> */}
									<TextField
										select
										name="businessType"
										placeholder="Id type"
										value={investorMarketForm.newBusinessType}
										onChange={handleInputChange}
										sx={{ width: "100%", textTransform: "capitalize" }}
										required
									>
										{businessTypes.map((option, index) => (
											<MenuItem
												key={index}
												value={option.value}
												sx={{ textTransform: "capitalize" }}
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>
						</Stack>

						<Box mt={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Button variant="contained" color="primary" type="submit">
								Proceed
							</Button>
						</Box>
					</Box>
				</NegotiateModal>
			) : null}

			{formSubmitted && (
				<AnyModal open={formSubmitted}>
					<Box>
						<Stack direction="row" justifyContent="flex-end">
							<></>
							<IconButton onClick={() => setFormSubmitted(false)}>
								<GrFormClose size={30} />
							</IconButton>
						</Stack>

						<Stack justifyContent="center" alignItems="center" spacing={2}>
							<CheckIcon />
							<Typography variant="body1" color="primary" align="center">
								Submitted, you will be notified if your proposal is considered
							</Typography>
							<Button
								variant="contained"
								color="primary"
								sx={{ width: "100%" }}
								onClick={() =>
									navigate("/financing/new-transaction/investor-market-place")
								}
							>
								Okay
							</Button>
						</Stack>
					</Box>
				</AnyModal>
			)}
		</>
	);
}

export default AfInvestorSummary;

function NegotiateModal({ open, close, children }) {
	return (
		<>
			<Modal open={open}>
				<Box sx={style}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Negotiate
						</Typography>
						<IconButton onClick={close}>
							<GrFormClose size={30} />
						</IconButton>
					</Stack>

					<Box mt={2}>{children}</Box>
				</Box>
			</Modal>
		</>
	);
}

NegotiateModal.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	children: PropTypes.any,
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	maxWidth: "618px",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: "8px",
};

function AnyModal({ children, open }) {
	return (
		<>
			<Modal open={open}>
				<Box sx={style2}>{children}</Box>
			</Modal>
		</>
	);
}

AnyModal.propTypes = {
	open: PropTypes.bool,
	children: PropTypes.any,
};

const style2 = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	maxWidth: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: "8px",
};
