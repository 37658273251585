import React from "react";
import PropTypes from "prop-types";
import { CustomGreenBtn, CustomWhiteBtn } from "./CustomButtons";
import { MdOutlineClose } from "react-icons/md";

const NotifyUser = ({
	header,
	title,
	boldTitle,
	children,
	onWhiteBtnClick,
	onGreenBtnClick,
	onCloseBtnClick,
	isGreenBtnDisabled,
	btnText,
	color,
}) => {
	return (
		<div className="bg-white rounded-lg p-5 md:p-6 pb-7 sm:pb-5 text-[#393F42] w-[90%] max-w-[400px] md:max-w-[450px] lg:max-w-[475px] mx-auto space-y-3 md:space-y-4">
			<div className="space-y-1">
				<div className="flex justify-between items-start gap-4">
					<h3 className="text-[14.5px] md:text-[15px] font-bold zabalBoldFont">
						{header}
					</h3>
					<button onClick={onCloseBtnClick}>
						<MdOutlineClose size={18} color="#00525D" />
					</button>
				</div>
				<p className="text-xs">
					<span className="zabalRegFont text-[#8E9DA4]">{title}</span>{" "}
					<span
						className="zabalMedFont"
						style={{ color: color ? color : "#393F42" }}
					>
						{boldTitle}
					</span>
				</p>
			</div>
			{children}
			<div className="pt-4 flex flex-col gap-3 sm:hidden">
				<CustomGreenBtn
					text={btnText ? btnText : "proceed"}
					onRequestClick={onGreenBtnClick}
					isDisabled={isGreenBtnDisabled}
				/>
				<CustomWhiteBtn text="cancel" onRequestClick={onWhiteBtnClick} />
			</div>
			<div className="hidden sm:flex pt-2 justify-end gap-2">
				<CustomWhiteBtn
					width="105px"
					height="37px"
					fontSize="13px"
					text="cancel"
					onRequestClick={onWhiteBtnClick}
				/>
				<CustomGreenBtn
					width="105px"
					height="37px"
					fontSize="13px"
					text={btnText ? btnText : "proceed"}
					onRequestClick={onGreenBtnClick}
					isDisabled={isGreenBtnDisabled}
				/>
			</div>
		</div>
	);
};

export default NotifyUser;

NotifyUser.propTypes = {
	header: PropTypes.string,
	title: PropTypes.string,
	boldTitle: PropTypes.string,
	btnText: PropTypes.string,
	children: PropTypes.element,
	onWhiteBtnClick: PropTypes.func,
	onGreenBtnClick: PropTypes.func,
	onCloseBtnClick: PropTypes.func,
	isGreenBtnDisabled: PropTypes.bool,
	color: PropTypes.string,
};
