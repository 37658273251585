import { styled } from "styled-components";

export const PageLayout = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const BtnStyle = styled.button`
  border: ${(props) => (props.outline ? "1px solid #003A50" : "none")};
  background: ${(props) => (props.outline ? "none" : "#003A50")};
  color: ${(props) => (props.outline ? "#003A50" : "#fff")};
  border-radius: 8px;
  width: 158px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
`;
export const LongBtn = styled.button`
  border: ${(props) => (props.outline ? "1px solid #003A50" : "none")};
  background: ${(props) => (props.outline ? "none" : "#003A50")};
  color: ${(props) => (props.outline ? "#003A50" : "#fff")};
  border-radius: 8px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  margin-top: 10px;
`;

export const HOne = styled.h1`
  font-family: "Chillax", sans-serif;
  font-weight: 500;
  font-size: 35px;
  color: #003a50;
`;
export const HTwo = styled.h1`
  font-family: "Chillax", sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: #393f42;
  line-height: 160%;
`;
export const SubText = styled.p`
  color: #5A6063;
  font-weight: 400;
  font-size: 13px;
  margin-top: -20px;
`;

export const Preloader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: transparent;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &::after {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 5px solid #8f058a;
    border-color: #fff transparent #aabdc5 transparent;
    animation: spin 0.7s ease-in-out infinite;
  }
`;
