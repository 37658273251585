import React from "react";
import PropTypes from "prop-types";
import "./miniLoader.css";

const MiniLoader = ({ size = "20px", borderWidth = "4px" }) => {
	return (
		<span
			className="mini-loader"
			style={{ width: size, height: size, borderWidth }}
		></span>
	);
};

export default MiniLoader;

MiniLoader.propTypes = {
	size: PropTypes.string,
	borderWidth: PropTypes.string,
};
