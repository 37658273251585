import React from "react";
import { Breadcrumbs } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  return (
    <Breadcrumbs separator="›">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.href}
          style={{
            textDecoration: "none",
            color: item.href ? "#00525D" : "#8E9DA4",
          }}
        >
          {item.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.any,
};
export default Breadcrumb;
