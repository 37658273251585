// import React from "react";
import { useEffect, useState } from "react";
import Parse from "../../api/ApiConfig";
import { toast } from "react-toastify";

export const useUploadFile = (fileToUpload) => {
	const [loading, setLoading] = useState(false);
	const [fileUploadSuccess, setFileUploadSuccess] = useState(null);
	const [fileSuccess, setFileSuccess] = useState(null);

	useEffect(() => {
		if (!fileToUpload) {
			return;
		}
		setLoading(true);
		const fileName = fileToUpload?.name;
		const parseFile = new Parse.File(fileName, fileToUpload);

		parseFile
			.save()
			.then((d) => {
				// eslint-disable-next-line no-undef
				console.log(d);
				setFileSuccess({ fileName, url: d?._url });
				setFileUploadSuccess(d?._url);
				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				toast.error("Failed to upload file");
			});
	}, [fileToUpload]);

	return { loading, fileUploadSuccess, fileSuccess };
};

export const useUploadMultipleFiles = (filesToUpload) => {
	const [loading, setLoading] = useState(false);
	const [fileUploadSuccess, setFileUploadSuccess] = useState([]);
	const [fileSuccess, setFileSuccess] = useState([]);

	useEffect(() => {
		if (!filesToUpload || filesToUpload.length === 0) {
			return;
		}

		setLoading(true);
		setFileSuccess([]);
		setFileUploadSuccess([]);
		// console.log(filesToUpload);

		const uploadPromises = filesToUpload.map((fileToUpload) => {
			return new Promise((resolve, reject) => {
				const fileName = fileToUpload?.name;
				const parseFile = new Parse.File(fileName, fileToUpload);

				parseFile
					.save()
					.then((result) => {
						const fileData = { fileName, url: result?._url };
						setFileSuccess((prev) => [...prev, fileData]);
						setFileUploadSuccess((prev) => [...prev, result?._url]);
						resolve(fileData);
					})
					.catch((error) => {
						// eslint-disable-next-line no-undef
						console.log(error);
						reject(error);
						toast.error(`Failed to upload file: ${fileName}`);
					});
			});
		});

		Promise.all(uploadPromises)
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, [filesToUpload]);

	console.log(fileSuccess);

	return { loading, fileUploadSuccess, fileSuccess };
};
