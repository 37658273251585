import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiFillPlayCircle } from "react-icons/ai";
import { useTheme } from "@mui/material/styles";
import { EducationContext } from "../../Context/EducationContext";

function BooksPage() {
	const { id } = useParams();
	const theme = useTheme();
	const [chapter, setChapter] = useState("");
	const [description, setDescription] = useState("");
	const [bookCoverPhoto, setBookCoverPhoto] = useState("");
	const [bookUrl, setBookUrl] = useState("");
	const { filteredData } = useContext(EducationContext);

	const data = filteredData.find((book) => book.objectId === id);

	// eslint-disable-next-line no-undef
	console.log("filteredData: ", data);

	useEffect(() => {
		if (data && data.chapters.length > 0) {
			if (!chapter) {
				const firstChapter = data.chapters[0];
				setChapter(firstChapter.chapter);
				setBookUrl(firstChapter.link);
				setDescription(firstChapter.description);
				setBookCoverPhoto(firstChapter.coverPhoto);
			} else {
				const selectedChapter = data.chapters.find(
					(ch) => ch.chapter === chapter
				);
				if (selectedChapter) {
					setBookUrl(selectedChapter.link);
					setDescription(selectedChapter.description);
					setChapter(selectedChapter.chapter);
					setBookCoverPhoto(selectedChapter.coverPhoto);
				}
			}
		} else if (data && data.chapters.length === 0) {
			setBookUrl(data.link);
			setDescription(data.description);
			setBookCoverPhoto(data.coverPhoto);
		}
	}, [data, chapter]);

	if (!data) {
		return <CircularProgress />;
	}

	// Calculate the number of pages in the current chapter
	// const totalPages = pages.length;

	// Calculate the starting and ending index of the current page
	// const startIndex = (currentPage - 1) * 1;
	// const endIndex = startIndex + 1;

	// const currentPageContent = pages.slice(startIndex, endIndex);
	return (
		<>
			<Box>
				<Box>
					<Typography
						color="primary"
						variant="h3"
						sx={{ textTransform: "capitalize" }}>
						{data.title}
					</Typography>
					<Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

					<>
						{/* Image  */}
						<Box>
							<Card
								sx={{
									width: "100%",
									height: "100%",
									padding: 0,
									borderRadius: "8px",
									position: "relative",
								}}>
								<CardMedia
									sx={{
										height: { xs: "200px", md: "300px" },
										backgroundSize: "cover",
										backgroundPosition: "center",
										objectFit: "cover",
									}}
									image={bookCoverPhoto}></CardMedia>
								<CardContent
									sx={{
										backgroundColor: "transparent",
										position: "absolute",
										top: 0,
										width: "100%",
										padding: "16px",
									}}>
									{data.chapters.length > 0 && (
										<Typography
											variant="h1"
											color="primary.light"
											sx={{ textTransform: "capitalize" }}>
											Chapter {chapter}
										</Typography>
									)}
								</CardContent>
							</Card>
						</Box>

						{/* Content  */}
						<Stack
							direction={{ xs: "column", md: "row" }}
							spacing={2}
							sx={{ marginTop: "30px" }}>
							{/* Description  */}
							<Box width={{ xs: "100%", md: "50%" }}>
								<Stack spacing={2}>
									<Typography variant="h3" color="primary">
										Description
									</Typography>
									<Typography variant="body1" color="secondary.light" paragraph>
										{description}
									</Typography>
									{bookUrl && (
										<a
											href={bookUrl}
											target="_blank"
											download={data.name}
											rel="noreferrer">
											<Button variant="contained" color="primary">
												Download Book
											</Button>
										</a>
									)}
								</Stack>
							</Box>

							{/* Chapter  */}
							<Box
								width={{ xs: "100%", md: "50%" }}
								sx={{
									border: "1px solid #E6E9EB",
									padding: "10px",
									borderRadius: "8px",
									cursor: "pointer",
									textTransform: "capitalize",
								}}>
								<Stack spacing={1}>
									<Typography variant="h3" color="primary">
										Chapter
									</Typography>
									<Divider
										sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }}
									/>
									{data.chapters.length > 0 ? (
										<>
											{data.chapters.map((data, index) => (
												<Box
													backgroundColor={
														chapter === data.chapter &&
														theme.palette.primary.main
													}
													sx={{
														borderBottom: "1px solid #EEF0F1",
														borderRadius: "8px",
													}}
													key={index}
													onClick={() => {
														setChapter(data.chapter);
														setDescription(data.description);
														setBookUrl(data.link);
														setBookCoverPhoto(data.coverPhoto);
														// eslint-disable-next-line no-undef
														window.scrollTo(80, 80);
													}}>
													<List>
														<ListItem>
															<ListItemAvatar>
																<AiFillPlayCircle
																	color={
																		chapter === data.chapter
																			? theme.palette.primary.light
																			: theme.palette.primary.main
																	}
																	style={{ fontSize: "30px" }}
																/>
															</ListItemAvatar>
															<ListItemText
																primaryTypographyProps={{
																	color:
																		chapter === data.chapter
																			? theme.palette.primary.light
																			: theme.palette.primary.main,
																}}
																secondaryTypographyProps={{
																	color:
																		chapter === data.chapter
																			? theme.palette.info.dark
																			: theme.palette.secondary.light,
																}}
																primary={
																	<Typography
																		sx={{
																			textTransform: "capitalize",
																			color:
																				chapter === data.chapter
																					? theme.palette.primary.light
																					: theme.palette.primary.main,
																		}}>
																		{data.name}
																	</Typography>
																}
																secondary={
																	<Box
																		sx={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "space-between",
																			gap: 1,
																			flexWrap: "wrap",
																		}}>
																		<Typography
																			variant="caption"
																			color={
																				chapter === data.chapter
																					? theme.palette.primary.light
																					: "#0000007a"
																			}>
																			Chapter {data.chapter}
																		</Typography>

																		{/* <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <BsFillClockFill
                                          color={
                                            chapter === data.chapter
                                              ? theme.palette.primary.light
                                              : "#0000007a"
                                          }
                                          style={{
                                            display: { xs: "none", sm: "flex" },
                                          }}
                                        />
                                      <Typography
                                        variant="caption"
                                        color={
                                          chapter === data.chapter
                                            ? theme.palette.primary.light
                                            : "#0000007a"
                                        }
                                      >
                                        &nbsp; {data.duration} Long
                                      </Typography>
                                    </Box> */}
																	</Box>
																}
															/>
														</ListItem>
													</List>
												</Box>
											))}
										</>
									) : (
										<Box
											backgroundColor={theme.palette.primary.main}
											sx={{
												borderBottom: "1px solid #EEF0F1",
												borderRadius: "8px",
											}}
											onClick={() => {
												setDescription(data.description);
												setBookUrl(data.link);
												// eslint-disable-next-line no-undef
												window.scrollTo(80, 80);
											}}>
											<List>
												<ListItem>
													<ListItemAvatar>
														<AiFillPlayCircle
															color={theme.palette.primary.light}
															style={{
																fontSize: "30px",
															}}
														/>
													</ListItemAvatar>
													<ListItemText
														primaryTypographyProps={{
															color: theme.palette.primary.light,
														}}
														secondaryTypographyProps={{
															color: theme.palette.info.main,
														}}
														primary={
															<Typography
																sx={{ textTransform: "capitalize" }}
																color="primary.light">
																{data.name}
															</Typography>
														}
														secondary={
															<Box
																sx={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-between",
																	gap: 1,
																	flexWrap: "wrap",
																}}>
																<Typography variant="caption">
																	{data.description}
																</Typography>
															</Box>
														}
													/>
												</ListItem>
											</List>
										</Box>
									)}
								</Stack>
							</Box>
						</Stack>
					</>
				</Box>
			</Box>
		</>
	);
}

export default BooksPage;
