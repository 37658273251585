import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
	BackTracker,
	CustomRadioInput,
	SuccessCheckModal,
} from "../../../layout/CustomComponents";
import { FaArrowLeft } from "react-icons/fa";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import {
	LeaseSummaryInput,
	LeaseSummaryItemDetails,
} from "../requestlease/LeaseSummary";
import { DesktopSideModal } from "../../../components/ModalContainer";
import BusinessForm from "../business/BusinessForm";
import {
	clearSubmittedNegotiation,
	closeBusinessReqLeaseModal,
	openBusinessReqLeaseModal,
	saveDataFromLeaseStructure,
} from "../../../redux/lease/leaseActions";
import {
	MonthlyPayment,
	OneOffPayment,
	ViewPaymentDetails,
} from "../requestlease/ViewPaymentDetails";
import { Navigate, useNavigate } from "react-router-dom";
import Parse from "../../../api/ApiConfig";
import { createLease } from "../../../redux/lease/leaseServices";
import Negotiate from "../../../components/payments/Negotiate";
import {
	formatAmountToIncludeCurrency,
	getAssetDetails,
	// getAmountWithDurationAndInterest,
	getInterestAmount,
	// getMaintenanceFee,
	getMonthlyPayment,
	getOneOffPayment,
	getProductsObjects,
	getProfilePercentage,
} from "../../../common/utils";
import { clearUpdateAccountSuccess } from "../../../redux/authRedux/authActions";
import { MdLock } from "react-icons/md";
import { deliveryTypeArr } from "../requestlease/LeaseStructure";

const BusinessRequestLeaseSummary = ({ setActiveTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { currentUser, updatingUserAccountSuccess } = useSelector(
		(state) => state.auth
	);

	const {
		leaseFormData,
		leaseStructureData,
		selectedReqLeaseItem,
		toggleBusinessReqLeaseModal,
		createLeaseLoading,
		createLeaseSuccess,
		negotiationSuccess,
		rentalFeeSuccess,
	} = useSelector((state) => state.lease);

	const [activeModal, setActiveModal] = useState(1);

	const [summaryData, setSummaryData] = useState({
		lease_type: selectedReqLeaseItem?.leaseType || leaseFormData?.leaseType,
		interest: selectedReqLeaseItem?.interest || leaseStructureData?.interest,
		product_category:
			selectedReqLeaseItem?.category ||
			selectedReqLeaseItem?.productObj?.category,
		brand:
			selectedReqLeaseItem?.brand || selectedReqLeaseItem?.productObj?.brand,
		model:
			selectedReqLeaseItem?.model || selectedReqLeaseItem?.productObj?.model,
		year: selectedReqLeaseItem?.year || selectedReqLeaseItem?.productObj?.year,
		condition:
			selectedReqLeaseItem?.condition ||
			selectedReqLeaseItem?.productObj?.condition,
		color:
			selectedReqLeaseItem?.color || selectedReqLeaseItem?.productObj?.color,
		lease_duration:
			selectedReqLeaseItem?.leaseDuration || leaseStructureData?.leaseDuration,
		lease_structure:
			selectedReqLeaseItem?.leaseStructure ||
			leaseStructureData?.leaseStructure,
		delivery_type: deliveryTypeArr.find(
			(a) => a.label === leaseStructureData?.deliveryType
		),
		delivery_address:
			leaseStructureData?.deliveryAddress || currentUser?.homeAddress || "",
		state: leaseStructureData?.state || "",
		lga: leaseStructureData?.lga || "",
		pickup_location:
			selectedReqLeaseItem?.pickupAddress ||
			selectedReqLeaseItem?.productObj?.pickupAddress ||
			"",
	});

	const handleChange = (e) =>
		setSummaryData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const [toggleAddress, setToggleAddress] = useState(true);

	const {
		lease_type,
		interest,
		product_category,
		brand,
		model,
		year,
		condition,
		color,
		lease_duration,
		lease_structure,
		delivery_type,
		delivery_address,
		state,
		lga,
		pickup_location,
	} = summaryData;

	useEffect(() => {
		if (delivery_type?.value === "pick-up") {
			setToggleAddress(false);
		} else {
			return;
		}
	}, [delivery_type]);

	const amount =
		selectedReqLeaseItem?.productObj?.amount || selectedReqLeaseItem?.amount;

	// const interestAmount = getAmountWithDurationAndInterest(
	// 	interest,
	// 	lease_duration,
	// 	amount
	// );

	const interestAmount = getInterestAmount(interest, amount);

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const Product = Parse.Object.extend("Products");
	const product = new Product();
	product.id =
		selectedReqLeaseItem?.productId?.objectId || selectedReqLeaseItem?.objectId;

	const monthly_payment = getMonthlyPayment(rentalFeeSuccess);

	const adh = { price: amount, deliveryType: delivery_type?.value };
	const one_off_payment = getOneOffPayment(adh);

	const productObj = getProductsObjects(
		selectedReqLeaseItem?.productObj || selectedReqLeaseItem
	);

	const assetObj = getAssetDetails(selectedReqLeaseItem);

	const firstPartOfLeaseData = {
		color,
		brand,
		model,
		condition,
		year,
		interest: Number(interest),
		leaseDuration: lease_duration,
		leaseType: lease_type,
		leaseStructure: lease_structure,
		deliveryType: delivery_type?.value,
		state: "ongoing",
		productCategory: product_category,
		userId: user,
		price: amount,
		productId: product,
		leaseFor: "business",
		leaseSource:
			leaseStructureData?.requestType === "old"
				? "market-place"
				: "products-catalogue",
		deliveryAddress: delivery_type.value !== "pick-up" ? delivery_address : "",
		pickupLocation: delivery_type.value === "pick-up" ? pickup_location : "",
		monthlyPayment: monthly_payment.toFixed(2),
		oneOffPayment: one_off_payment.toFixed(2),
		// assetId: leaseStructureData?.requestType === "old" ? asset : "",
		assetObj: leaseStructureData?.requestType === "old" ? assetObj : null,
		funded: leaseStructureData?.requestType === "old" ? true : false,
		type:
			leaseStructureData?.requestType === "old"
				? "full-investment"
				: "partial-investment",
		paidSlots: leaseStructureData?.requestType === "old" ? 4 : 0,
		productObj,
		investors: assetObj ? assetObj?.investors : [],
		investorsArray: assetObj ? assetObj?.investorsArray : [],
	};

	const dataValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		businessAddress: currentUser?.businessAddress,
		businessType: currentUser?.businessType,
		businessName: currentUser?.businessName,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	// check if delivery address is valid
	const isRequestLeaseBtnDisabled = () => {
		if (delivery_type.value !== "pick-up" && delivery_address === "") {
			return true;
		} else {
			return false;
		}
	};

	const percentageCompletionOfForm = getProfilePercentage(dataValidation);

	// open request lease modal
	const beginBusinessProcessModal = () => {
		dispatch(saveDataFromLeaseStructure({ delivery_address, state, lga }));
		dispatch(openBusinessReqLeaseModal());
		if (percentageCompletionOfForm < 48) {
			setActiveModal(1);
		} else {
			setActiveModal(2);
		}
	};

	const onProceedToTabTwo = () => {
		dispatch(clearUpdateAccountSuccess());
		setActiveModal(2);
	};

	//proceed to view amount to pay
	const onBackBtnClickOnPayment = () => {
		dispatch(clearUpdateAccountSuccess());
		if (percentageCompletionOfForm < 52) {
			setActiveModal(1);
		} else {
			dispatch(closeBusinessReqLeaseModal());
		}
	};

	const onProceedToConfirmTransaction = () => {
		if (leaseStructureData?.requestType === "new") {
			delete firstPartOfLeaseData.assetId;
		}
		dispatch(createLease(firstPartOfLeaseData));
	};

	const onNegotiateBtnClick = () => {
		dispatch(openBusinessReqLeaseModal());
		setActiveModal(5);
	};

	const onNegotiateSuccess = () => {
		dispatch(clearSubmittedNegotiation());
		dispatch(closeBusinessReqLeaseModal());

		navigate("/leasing/new-transaction/market-place");
	};

	if (createLeaseSuccess) {
		// return <Navigate to="/leasing/ongoing-transactions" replace />;
		return (
			<Navigate
				to={`/leasing/ongoing-transactions/confirm-lease?id=${createLeaseSuccess?.id}`}
				replace
			/>
		);
	}

	return (
		<>
			{toggleBusinessReqLeaseModal && (
				<DesktopSideModal modal={toggleBusinessReqLeaseModal}>
					<>
						{activeModal === 1 && updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onProceedToTabTwo}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							activeModal === 1 &&
							!updatingUserAccountSuccess && (
								<BusinessForm
									onCloseForm={() => dispatch(closeBusinessReqLeaseModal())}
								/>
							)
						)}
						{activeModal === 2 && (
							<ViewPaymentDetails
								data={firstPartOfLeaseData}
								onCloseBtnClick={() => dispatch(closeBusinessReqLeaseModal())}
								onBackBtnClick={onBackBtnClickOnPayment}
								onProceedBtnClick={onProceedToConfirmTransaction}
								oneOffPaymentDetailsClick={() => setActiveModal(3)}
								monthlyPaymentDetailsClick={() => setActiveModal(4)}
								isBtnDisabled={createLeaseLoading}
								isLoading={createLeaseLoading}
							/>
						)}
						{activeModal === 3 && (
							<OneOffPayment
								data={firstPartOfLeaseData}
								onCloseBtnClick={() => dispatch(closeBusinessReqLeaseModal())}
								onBackBtnClick={() => setActiveModal(2)}
							/>
						)}
						{activeModal === 4 && (
							<MonthlyPayment
								data={firstPartOfLeaseData}
								shelfLife={
									selectedReqLeaseItem?.productObj?.shelfLife
										.split(" ")
										.at(0) || selectedReqLeaseItem?.shelfLife.split(" ").at(0)
								}
								onCloseBtnClick={() => dispatch(closeBusinessReqLeaseModal())}
								onBackBtnClick={() => setActiveModal(2)}
							/>
						)}
						{activeModal === 5 && negotiationSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Submitted, you will be notified if your proposal is considered"
									onRequestClick={onNegotiateSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							activeModal === 5 &&
							!negotiationSuccess && (
								<Negotiate
									onClose={() => dispatch(closeBusinessReqLeaseModal())}
									lease_structure={lease_structure}
									lease_tenure={lease_duration}
									interest={Number(interest).toFixed(1)}
									interestAmount={interestAmount}
									assetAmount={amount}
								/>
							)
						)}
					</>
				</DesktopSideModal>
			)}
			<BackTracker text="Lease Summary" onNavigate={() => setActiveTab(3)} />
			<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
				<div className="hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
					<button className="zabalMedFont" onClick={() => setActiveTab(3)}>
						<FaArrowLeft />
					</button>
					<p>Lease Summary</p>
				</div>

				<LeaseSummaryItemDetails
					item={
						leaseStructureData?.requestType === "old"
							? selectedReqLeaseItem?.productObj
							: selectedReqLeaseItem
					}
					// interest={Number(interest).toFixed(1)}
					interestAmount={interestAmount}
				/>
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseSummaryInput input_value={lease_type} text="lease type" />
					<LeaseSummaryInput
						input_value={product_category}
						text="product category"
					/>
					<LeaseSummaryInput input_value={brand} text="brand" />
					<LeaseSummaryInput input_value={model} text="model" />
					<LeaseSummaryInput input_value={year} text="year" />
					<LeaseSummaryInput
						input_value={condition || "brand new"}
						text="condition"
					/>
					<LeaseSummaryInput input_value={color} text="color" />
					<LeaseSummaryInput
						input_value={lease_duration}
						text="lease duration"
					/>
					<LeaseSummaryInput
						input_value={lease_structure}
						text="lease structure"
					/>
					<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
							Interest Rate (NGN):
						</p>
						<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
							<div className="flex items-center justify-between w-full">
								<p>
									<span className="text-[#393F42] zabalMedFont">NGN </span>
									<span>
										{formatAmountToIncludeCurrency(undefined, interestAmount)}
									</span>
								</p>
								<p className="text-fortcore-green zabalMedFont">{`( ${interest}% P/A)`}</p>
							</div>
							<span className="h-full grid place-items-center">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>
					<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12">
							Delivery Type :
						</p>
						<div className="w-full h-[45px]  sm:w-[67%] md:w-4/6 lg:w-9/12 flex gap-y-2 gap-5 md:gap-8 zabalMedFont flex-wrap">
							{delivery_type.value === "door-delivery" && (
								<div
									className={`flex items-center gap-3 ${
										delivery_type.value === "door-delivery"
											? "text-fortcore-green"
											: "text-[#8E9DA4]"
									}`}
								>
									<div className="flex items-center gap-1.5 gap-y-2">
										<CustomRadioInput
											name="delivery_type"
											value="door-delivery"
											isChecked={delivery_type.value === "door-delivery"}
											isReadOnly={true}
										/>
										<p className="text-xs text-[#393F42]">Door Delivery</p>
									</div>
									<button
										className="rounded-md border border-[#AABDC5] px-4 py-1 zabalMedFont text-[11px]"
										onClick={() => setToggleAddress(!toggleAddress)}
									>
										Change Location
									</button>
								</div>
							)}
							{delivery_type.value === "pick-up" && (
								<div
									className={`flex items-center gap-1.5 gap-y-2 ${
										delivery_type.value === "pick-up"
											? "text-fortcore-green"
											: "text-[#8E9DA4]"
									}`}
								>
									<CustomRadioInput
										name="delivery_type"
										value="pick-up"
										isChecked={delivery_type.value === "pick-up"}
										isReadOnly={true}
									/>
									<p className="text-xs text-[#393F42]">Pick Up</p>
								</div>
							)}
						</div>
					</div>
					{delivery_type.value === "door-delivery" && (
						<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
							<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
								delivery address:
							</p>
							<div
								className={`w-full h-[45px] sm:w-[67%] md:w-4/6 lg:w-9/12 relative ${
									delivery_address.value === "" ? "mb-1.5" : "mb-0"
								}`}
							>
								<input
									type="text"
									className="h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] zabalRegFont capitalize focus:border-2 border-fortcore-green"
									value={delivery_address}
									name="delivery_address"
									onChange={handleChange}
									disabled={toggleAddress}
								/>
								{toggleAddress && (
									<span className="absolute right-2 h-full w-[30px] grid place-items-center top-0">
										<MdLock size={18} color="#717E83" />
									</span>
								)}
								{delivery_address === "" && (
									<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
										Delivery address is required
									</p>
								)}
							</div>
						</div>
					)}

					{delivery_type?.value === "pick-up" && (
						<LeaseSummaryInput
							input_value={pickup_location || "Not Available Yet."}
							text="pick up location"
						/>
					)}
					<div className="bg-[#E3E9EC] h-[1px] w-full hidden sm:block"></div>

					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						{leaseStructureData?.requestType === "old" && (
							<CustomWhiteBtn
								text="negotiate"
								width="115px"
								height="42px"
								fontSize="13.5px"
								onRequestClick={onNegotiateBtnClick}
							/>
						)}
						<CustomGreenBtn
							text="request"
							width="115px"
							height="42px"
							fontSize="13.5px"
							isDisabled={isRequestLeaseBtnDisabled()}
							onRequestClick={beginBusinessProcessModal}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						{leaseStructureData?.requestType === "old" && (
							<CustomWhiteBtn
								text="negotiate"
								onRequestClick={onNegotiateBtnClick}
							/>
						)}
						<CustomGreenBtn
							text="request"
							isDisabled={isRequestLeaseBtnDisabled()}
							onRequestClick={beginBusinessProcessModal}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default BusinessRequestLeaseSummary;

BusinessRequestLeaseSummary.propTypes = {
	setActiveTab: PropTypes.func,
};
