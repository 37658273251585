export const OPEN_ADD_BANK_ACCOUNT_MODAL = "OPEN_ADD_BANK_ACCOUNT_MODAL";
export const CLOSE_ADD_BANK_ACCOUNT_MODAL = "CLOSE_ADD_BANK_ACCOUNT_MODAL";
export const OPEN_ADD_BVN_MODAL = "OPEN_ADD_BVN_MODAL";
export const CLOSE_ADD_BVN_MODAL = "CLOSE_ADD_BVN_MODAL";
export const OPEN_MOBILE_MENU_MODAL = "OPEN_MOBILE_MENU_MODAL";
export const CLOSE_MOBILE_MENU_MODAL = "CLOSE_MOBILE_MENU_MODAL";
export const GET_PHONE_OTP_LOADING = "GET_PHONE_OTP_LOADING";
export const GET_PHONE_OTP_SUCCESS = "GET_PHONE_OTP_SUCCESS";
export const GET_PHONE_OTP_ERROR = "GET_PHONE_OTP_ERROR";
export const VERIFY_PHONE_OTP_LOADING = "VERIFY_PHONE_OTP_LOADING";
export const VERIFY_PHONE_OTP_SUCCESS = "VERIFY_PHONE_OTP_SUCCESS";
export const VERIFY_PHONE_OTP_ERROR = "VERIFY_PHONE_OTP_ERROR";
export const DISABLE_PHONE_BUTTON_WHILE_COUNT =
	"DISABLE_PHONE_BUTTON_WHILE_COUNT";
export const GET_EMAIL_OTP_LOADING = "GET_EMAIL_OTP_LOADING";
export const GET_EMAIL_OTP_SUCCESS = "GET_EMAIL_OTP_SUCCESS";
export const GET_EMAIL_OTP_ERROR = "GET_EMAIL_OTP_ERROR";
export const VERIFY_EMAIL_OTP_LOADING = "VERIFY_EMAIL_OTP_LOADING";
export const VERIFY_EMAIL_OTP_SUCCESS = "VERIFY_EMAIL_OTP_SUCCESS";
export const VERIFY_EMAIL_OTP_ERROR = "VERIFY_EMAIL_OTP_ERROR";
export const DISABLE_EMAIL_BUTTON_WHILE_COUNT =
	"DISABLE_EMAIL_BUTTON_WHILE_COUNT";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const ADD_BVN_LOADING = "ADD_BVN_LOADING";
export const ADD_BVN_SUCCESS = "ADD_BVN_SUCCESS";
export const ADD_BVN_ERROR = "ADD_BVN_ERROR";
export const ADD_BANK_ACCOUNT_LOADING = "ADD_BANK_ACCOUNT_LOADING";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_ERROR = "ADD_BANK_ACCOUNT_ERROR";
export const ADD_BANK_DETAILS = "ADD_BANK_DETAILS";
export const OPEN_LOGOUT_MODAL = "OPEN_LOGOUT_MODAL";
export const ADD_BANK_TO_PAGE = "ADD_BANK_TO_PAGE";
export const GET_CREDIT_SCORE_LOADING = "GET_CREDIT_SCORE_LOADING";
export const GET_CREDIT_SCORE_SUCCESS = "GET_CREDIT_SCORE_SUCCESS";
export const GET_CREDIT_SCORE_ERROR = "GET_CREDIT_SCORE_ERROR";
export const GET_UPCOIMING_PAYMENTS_LOADING = "GET_UPCOIMING_PAYMENTS_LOADING";
export const GET_UPCOIMING_PAYMENTS_SUCCESS = "GET_UPCOIMING_PAYMENTS_SUCCESS";
export const GET_UPCOIMING_PAYMENTS_ERROR = "GET_UPCOIMING_PAYMENTS_ERROR";
export const OPEN_CUSTOM_ALERT = "OPEN_CUSTOM_ALERT";
export const CLOSE_CUSTOM_ALERT = "CLOSE_CUSTOM_ALERT";
export const OPEN_AUTHORIZE_AUTODEBIT_MODAL = "OPEN_AUTHORIZE_AUTODEBIT_MODAL";
export const CLOSE_AUTHORIZE_AUTODEBIT_MODAL =
	"CLOSE_AUTHORIZE_AUTODEBIT_MODAL";
export const OPEN_AUTHORIZE_INSURANCE_MODAL = "OPEN_AUTHORIZE_INSURANCE_MODAL";
export const CLOSE_AUTHORIZE_INSURANCE_MODAL =
	"CLOSE_AUTHORIZE_INSURANCE_MODAL";
export const OPEN_AUTHORIZE_GUARANTOR_MODAL = "OPEN_AUTHORIZE_GUARANTOR_MODAL";
export const CLOSE_AUTHORIZE_GUARANTOR_MODAL =
	"CLOSE_AUTHORIZE_GUARANTOR_MODAL";
export const OPEN_AUTHORIZE_CREDITASSESSMENT_MODAL =
	"OPEN_AUTHORIZE_CREDITASSESSMENT_MODAL";
export const CLOSE_AUTHORIZE_CREDITASSESSMENT_MODAL =
	"CLOSE_AUTHORIZE_CREDITASSESSMENT_MODAL";
export const CLOSE_LOGOUT_MODAL = "CLOSE_LOGOUT_MODAL";
export const CURRENT_USER_DATA = "CURRENT_USER_DATA";
export const CLEAR_STATES_ON_CONFIRM_LEASE = "CLEAR_STATES_ON_CONFIRM_LEASE";
export const FETCH_GUARANTORS_DETAILS_LOADING =
	"FETCH_GUARANTORS_DETAILS_LOADING";
export const FETCH_GUARANTORS_DETAILS_SUCCESS =
	"FETCH_GUARANTORS_DETAILS_SUCCESS";
export const FETCH_GUARANTORS_DETAILS_ERROR = "FETCH_GUARANTORS_DETAILS_ERROR";
export const SAVE_GUARANTORS_DETAILS_LOADING =
	"SAVE_GUARANTORS_DETAILS_LOADING";
export const SAVE_GUARANTORS_DETAILS_SUCCESS =
	"SAVE_GUARANTORS_DETAILS_SUCCESS";
export const SAVE_GUARANTORS_DETAILS_ERROR = "SAVE_GUARANTORS_DETAILS_ERROR";
export const VERIFY_EMAIL_FOR_TRANSACTIONS_LOADING =
	"VERIFY_EMAIL_FOR_TRANSACTIONS_LOADING";
export const VERIFY_EMAIL_FOR_TRANSACTIONS_SUCCESS =
	"VERIFY_EMAIL_FOR_TRANSACTIONS_SUCCESS";
export const VERIFY_EMAIL_FOR_TRANSACTIONS_ERROR =
	"VERIFY_EMAIL_FOR_TRANSACTIONS_ERROR";
export const CLEAR_EMAIL_VERIFICATION_ON_SUCCESS =
	"CLEAR_EMAIL_VERIFICATION_ON_SUCCESS";
export const CREATE_ACCESSPIN_LOADING = "CREATE_ACCESSPIN_LOADING";
export const CREATE_ACCESSPIN_SUCCESS = "CREATE_ACCESSPIN_SUCCESS";
export const CREATE_ACCESSPIN_ERROR = "CREATE_ACCESSPIN_ERROR";
export const CLEAR_PIN_CREATION_SUCCESS = "CLEAR_PIN_CREATION_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const REDIRECT = "REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
export const UPDATE_USER_ACCOUNT_LOADING = "UPDATE_USER_ACCOUNT_LOADING";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_ERROR = "UPDATE_USER_ACCOUNT_ERROR";
export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
export const CLEAR_UPDATE_ACCOUNT_SUCCESS = "CLEAR_UPDATE_ACCOUNT_SUCCESS";
export const FETCH_ALL_BANKS_LOADING = "FETCH_ALL_BANKS_LOADING";
export const FETCH_ALL_BANKS_SUCCESS = "FETCH_ALL_BANKS_SUCCESS";
export const FETCH_ALL_BANKS_ERROR = "FETCH_ALL_BANKS_ERROR";
export const VERIFY_BANK_ACCOUNT_LOADING = "VERIFY_BANK_ACCOUNT_LOADING";
export const VERIFY_BANK_ACCOUNT_SUCCESS = "VERIFY_BANK_ACCOUNT_SUCCESS";
export const VERIFY_BANK_ACCOUNT_ERROR = "VERIFY_BANK_ACCOUNT_ERROR";
export const CLEAR_VERIFY_ACCOUNT_SUCCESS = "CLEAR_VERIFY_ACCOUNT_SUCCESS";
export const NETWORK_ERROR_TRUE = "NETWORK_ERROR_TRUE";
export const CLEAR_NETWORK_ERROR = "CLEAR_NETWORK_ERROR";
export const OPEN_RESET_PIN_MODAL = "OPEN_RESET_PIN_MODAL";
export const CLOSE_RESET_PIN_MODAL = "CLOSE_RESET_PIN_MODAL";
export const OPEN_MINI_LOGIN_MODAL = "OPEN_MINI_LOGIN_MODAL";
export const CLOSE_MINI_LOGIN_MODAL = "CLOSE_MINI_LOGIN_MODAL";
export const TOGGLE_ACTIVATE_WALLET_MODAL = "TOGGLE_ACTIVATE_WALLET_MODAL";
export const SAVE_HOME_ADDRESS = "SAVE_HOME_ADDRESS";
