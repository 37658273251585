import React from "react";
import { PlainSelect, SelectDropDown } from "../../../layout/CustomComponents";
import PropTypes from "prop-types";
import { FaArrowLeft } from "react-icons/fa";

export const LeaseFormHeader = ({ text, onRequestClick }) => {
	return (
		<div className="flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold">
			<button className="hidden sm:block zabalMedFont" onClick={onRequestClick}>
				<FaArrowLeft />
			</button>
			<p className="capitalize">{text}</p>
		</div>
	);
};

LeaseFormHeader.propTypes = {
	text: PropTypes.string,
	onRequestClick: PropTypes.func,
};

export const LeaseFormSelect = ({
	text,
	context,
	isOpened,
	currentText,
	handleToggle,
	arrOptions,
	onHandleSelect,
	isShowError,
	errorText,
	isLoading,
	emptyMsg,
	type,
}) => {
	return (
		<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
			<p
				className={`w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont ${
					type === "savings"
						? " sm:w-[28%] md:w-[25%] lg:w-1/5"
						: "sm:w-[33%] md:w-2/6 lg:w-3/12"
				} capitalize`}
			>
				{text} :
			</p>
			<div
				className={`w-full ${
					type === "savings"
						? "sm:w-[72%] md:w-[75%] lg:w-4/5"
						: "sm:w-[67%] md:w-4/6 lg:w-9/12"
				} relative ${isShowError && currentText === "" ? "mb-2" : "mb-0"}`}
			>
				{/* <PlainSelect /> */}
				<PlainSelect
					context={context}
					isOpened={isOpened}
					currentText={currentText}
					handleToggle={handleToggle}
				/>
				{isOpened && (
					<div className="absolute z-[2] w-full top-48px pb-8">
						<SelectDropDown
							arrOptions={arrOptions}
							onHandleSelect={onHandleSelect}
							isLoading={isLoading}
							emptyMsg={emptyMsg}
						/>
					</div>
				)}
				{isShowError && currentText === "" && (
					<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
						{errorText}
					</p>
				)}
			</div>
		</div>
	);
};

LeaseFormSelect.propTypes = {
	text: PropTypes.string,
	currentText: PropTypes.string,
	context: PropTypes.string,
	isOpened: PropTypes.bool,
	handleToggle: PropTypes.func,
	arrOptions: PropTypes.array,
	onHandleSelect: PropTypes.func,
	isShowError: PropTypes.bool,
	errorText: PropTypes.string,
	emptyMsg: PropTypes.string,
	isLoading: PropTypes.bool,
	type: PropTypes.string,
};

export const leaseTypeArr = ["Quick Lease", "Sponsored Lease"];
export const productTypeData = [
	{
		category: "Gadgets",
		brands: ["Apple", "Dell", "HP", "Tecno", "Samsung", "Infinix", "Umidigi"],
	},
	{
		category: "Vehicles",
		brands: ["Nissan", "Toyota", "Mercedez", "Honda"],
	},
	{
		category: "Appliances",
		brands: ["Samsung", "Toshiba", "Hisense", "LG"],
	},
];
export const mod = [
	{
		header: "Nissan",
		subHeader: ["Quest", "Armada", "Pathfinder", "Micra", "Xterra", "Murano"],
	},
	{
		header: "Toyota",
		subHeader: [
			"Hilux",
			"Camry",
			"Corolla",
			"Sienna",
			"Highlander",
			"Hiace",
			"Matrix",
		],
	},
	{ header: "Honda", subHeader: ["Accord", "Pilot", "Civic", "City", "CRV"] },
	{ header: "Mercedez", subHeader: ["Benz", "Sprinter", "G-Wagon", "Maybach"] },
	{
		header: "Tecno",
		subHeader: ["Camon series", "Phantom series", "Spark series", "M series"],
	},
	{ header: "Umidigi", subHeader: ["F series", "A series", "Bixon series"] },
	{
		header: "Apple",
		subHeader: [
			"iPhone 6 series",
			"iPhone 7 series",
			"iPhone 8 series",
			"iPhone 9 series",
			"iPhone X series",
			"Macbook Air",
			"Macbook Pro",
		],
	},
	{ header: "Samsung", subHeader: ["Galaxy series", "X series"] },
	{
		header: "Infinix",
		subHeader: ["Note series", "Hot series", "Smart series"],
	},
	{ header: "Dell", subHeader: ["Latitude Series", "Inspiron series"] },
	{
		header: "HP",
		subHeader: ["Notebook", "ProBook", "Spectre series", "Elite series"],
	},
	{
		header: "Hisense",
		subHeader: [
			"Refrigerator",
			"Television",
			"Air Conditioner",
			"Washing Machines",
		],
	},
	{
		header: "Samsung",
		subHeader: [
			"Refrigerator",
			"Television",
			"Air Conditioner",
			"Washing Machines",
		],
	},
	{
		header: "LG",
		subHeader: [
			"Refrigerator",
			"Television",
			"Air Conditioner",
			"Washing Machines",
		],
	},
	{
		header: "Toshiba",
		subHeader: [
			"Refrigerator",
			"Television",
			"Air Conditioner",
			"Washing Machines",
		],
	},
];

export const productYears = [
	{ label: "2000s", value: "2000-2009" },
	{ label: "2010s", value: "2010-2019" },
	{ label: "2020s", value: "2020-2030" },
];
export const productCondition = ["Brand New", "Used"];
export const productColors = ["White", "Black", "Gray", "Blue", "Red"];

export const DualLeaseFormSelect = ({
	type,
	label,
	dropDownOne,
	dropDownTwo,
	handleSelectOne,
	handleSelectTwo,
}) => {
	return (
		<div className="flex flex-col sm:items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
			<p
				className={`w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont ${
					type === "savings"
						? "sm:w-[28%] md:w-[25%] lg:w-1/5"
						: "sm:w-[33%] md:w-2/6 lg:w-3/12"
				}`}
			>
				{label} :
			</p>
			<div
				className={`w-full relative grid grid-cols-2 gap-3 md:gap-4 lg:gap-5 ${
					type === "savings"
						? "sm:w-[72%] md:w-[75%] lg:w-4/5"
						: "sm:w-[67%] md:w-4/6 lg:w-9/12"
				}`}
			>
				<div
					className={`relative ${
						dropDownOne?.isShowError && dropDownOne?.currentText === ""
							? "mb-2"
							: "mb-0"
					}`}
				>
					<PlainSelect
						context={dropDownOne.context}
						isOpened={dropDownOne.isOpened}
						currentText={dropDownOne.currentText}
						handleToggle={dropDownOne.handleToggle}
					/>
					{dropDownOne.isOpened && (
						<div className="absolute z-[2] w-full top-[48px] pb-8">
							<SelectDropDown
								arrOptions={dropDownOne.arrOptions}
								onHandleSelect={handleSelectOne}
							/>
						</div>
					)}
					{dropDownOne?.isShowError && dropDownOne.currentText === "" && (
						<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
							{dropDownOne?.errorText}
						</p>
					)}
				</div>
				<div
					className={`relative ${
						dropDownTwo?.isShowError && dropDownTwo?.currentText === ""
							? "mb-2"
							: "mb-0"
					}`}
				>
					<PlainSelect
						context={dropDownTwo.context}
						isOpened={dropDownTwo.isOpened}
						currentText={dropDownTwo.currentText}
						handleToggle={dropDownTwo.handleToggle}
					/>
					{dropDownTwo.isOpened && (
						<div className="absolute z-[2] w-full top-[48px] pb-8">
							<SelectDropDown
								arrOptions={dropDownTwo.arrOptions}
								onHandleSelect={handleSelectTwo}
							/>
						</div>
					)}
					{dropDownTwo?.isShowError && dropDownTwo.currentText === "" && (
						<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
							{dropDownTwo?.errorText}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

DualLeaseFormSelect.propTypes = {
	label: PropTypes.string.isRequired,
	type: PropTypes.string,
	dropDownOne: PropTypes.shape({
		context: PropTypes.string.isRequired,
		currentText: PropTypes.string,
		isOpened: PropTypes.bool,
		handleToggle: PropTypes.func,
		arrOptions: PropTypes.array,
		errorText: PropTypes.string,
		isShowError: PropTypes.bool,
	}),
	dropDownTwo: PropTypes.shape({
		context: PropTypes.string.isRequired,
		currentText: PropTypes.string,
		isOpened: PropTypes.bool,
		handleToggle: PropTypes.func,
		arrOptions: PropTypes.array,
		errorText: PropTypes.string,
		isShowError: PropTypes.bool,
	}),
	handleSelectOne: PropTypes.func,
	handleSelectTwo: PropTypes.func,
};
