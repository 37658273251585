import React, { useState } from "react";
import moment from "moment";
import {
	MdDelete,
	MdKeyboardArrowDown,
	MdKeyboardArrowRight,
	MdKeyboardArrowUp,
} from "react-icons/md";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { CustomCheckboxInput } from "../../../layout/CustomComponents";
import { formatAmountToIncludeCurrency } from "../../../common/utils";
import {
	FirstStatusTracker,
	SecondStatusTracker,
} from "../submittedtransactions";
import ProductImagesCarousel from "../../../layout/ProductImagesCarousel";
import { useDispatch } from "react-redux";
import { openDeleteLeaseModal } from "../../../redux/lease/leaseActions";
import Pagination from "../../../components/Pagination";
// import { TrackerRadio, TrackerText } from "../submittedtransactions";

export const OngoingLeaseItem = ({
	leaseItem,
	handleLeaseSelect,
	isChecked,
}) => {
	const data = leaseItem?.get("productObj");

	const imageArr = data?.imageArr;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<div className="flex gap-5 items-center">
			<div className="hidden sm:block">
				<CustomCheckboxInput
					handleChange={handleLeaseSelect}
					isChecked={isChecked}
					id={leaseItem?.id}
				/>
			</div>
			<div className="bg-[#FEFEFE] rounded p-2.5 sm:px-3 sm:py-4 flex flex-col md:px-4 lg:px-5 sm:flex-row gap-4 sm:gap-8 lg:gap-14 border border-[#ADB8BC] w-full">
				<div className="flex max-w-[273px] sm:max-w-[150px] md:max-w-[250px] lg:max-w-[300px] items-center">
					<ProductImagesCarousel
						imageArr={imageArr}
						productName={data?.productName || "product name"}
					/>
				</div>
				<div className="flex gap-6 items-center md:pr-3 lg:pr-8 justify-between w-full xl:w-11/12">
					<div className="flex flex-col gap-1.5 sm:gap-2 md:w-11/12">
						<p className="text-[13px] md:text-sm zabalRegFont">
							Transaction ID:{" "}
							<span className="zabalMedFont">TRN-{leaseItem?.id}</span>
						</p>
						<p className="capitalize text-[#222627] zabalMedFont text-[13.5px] md:text-[14.5px]">
							{data?.productName}
						</p>
						<p className="text-fortcore-green text-[13px] md:text-sm">
							<span className="zabalMedFont">
								<span className="text-[#393F42] zabalMedFont">
									Asset Value:{" "}
								</span>
								<span className="zabalRegFont font-semibold">NGN </span>
							</span>{" "}
							<span className="zabalMedFont">
								{formatAmountToIncludeCurrency(undefined, data?.amount)}
							</span>
						</p>
						<div className="flex gap-2 text-xs md:text-[13px]">
							{/* {loading ? } */}
							<p>
								<span className="zabalRegFont">Date: </span>
								<span className="zabalMedFont">
									{moment(leaseItem?.get("createdAt")).format("Do MMM YYYY")}
								</span>
							</p>
							<p>
								<span className="zabalRegFont">Time: </span>
								<span className="zabalMedFont">
									{moment(leaseItem?.get("createdAt")).format("hh:mmA")}
								</span>
							</p>
						</div>
					</div>
					<div className="flex flex-col justify-between sm:justify-center h-full gap-8 sm:gap-0">
						<button
							className="text-fortcore-green text-2xl"
							onClick={() => {
								navigate(
									`/leasing/ongoing-transactions/confirm-lease?id=${leaseItem?.id}`
								);
							}}
						>
							<MdKeyboardArrowRight />
						</button>
						<button
							className="text-red-600 pr-1 sm:hidden"
							onClick={() => dispatch(openDeleteLeaseModal([leaseItem?.id]))}
						>
							<MdDelete size={20} />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export const SubmittedLeaseItem = ({ leaseItem, handleShow, showCurrent }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = 2;

	const data = leaseItem?.productObj;

	const imageArr = data?.imageArr;

	const status = {
		autoDebit: leaseItem?.autoDebit,
		insurance: !!leaseItem?.insurance,
		funded: leaseItem?.leaseType.toLowerCase().includes("quick")
			? leaseItem?.funded
			: leaseItem?.paidSlots > 3,
		leaseAgreement: leaseItem?.leaseAgreement || false,
		basicApproval: leaseItem?.basicAdminApproval?.status || null,
		firstLineApproval: leaseItem?.firstLineApproval?.status || null,
		secondLineApproval: leaseItem?.secondLineApproval?.status || null,
		lastLineApproval: leaseItem?.finalApproval?.status || null,
		financeApproval: leaseItem?.financeApproval?.status || null,
		firstPayment: leaseItem?.firstPayment || false,
		assetDeployed: leaseItem?.logisticStatus === "deployed",
		requestApproved: leaseItem?.approvalStatus,
		requestStatus:
			leaseItem?.usersRequest?.find(
				(a) => a?.transactionId === leaseItem?.objectId
			) || null,
		investorsArray: leaseItem?.investorsArray || [],
		deliveryInfo: leaseItem?.deliveryInfo || null,
	};

	return (
		<div className="flex flex-col gap-5 sm:gap-6 md:gap-7 bg-[#FEFEFE] rounded p-3  md:p-4 border border-[#ADB8BC]">
			<div className="flex flex-col sm:flex-row gap-4 md:gap-6 lg:gap-10 w-full">
				<div className="flex max-w-[273px] sm:max-w-[150px] md:max-w-[250px] lg:max-w-[300px] items-center">
					<ProductImagesCarousel
						imageArr={imageArr}
						productName={data?.productName}
					/>
				</div>
				<div className="flex gap-6 items-center md:pr-3 lg:pr-8 justify-between w-full xl:w-11/12">
					<div className="flex flex-col gap-1.5 sm:gap-2 w-11/12">
						<p className="text-[12.5px] md:text-sm zabalRegFont">
							Transaction ID:{" "}
							<span className="zabalMedFont">{leaseItem?.objectId}</span>
						</p>
						<p className="text-[#222627] zabalMedFont text-[13.5px] md:text-[14.5px]">
							{data?.productName}
						</p>
						<p className="text-fortcore-green text-xs md:text-[13px]">
							<span className="zabalMedFont">
								<span className="text-[#393F42] zabalMedFont">
									Asset Value:{" "}
								</span>
								<span className="zabalRegFont font-semibold">NGN </span>
							</span>{" "}
							<span className="zabalMedFont">
								{formatAmountToIncludeCurrency(undefined, data?.amount)}
							</span>
						</p>
						<p className="text-xs md:text-[13px] zabalRegFont">
							Transaction Status:{" "}
							<span className="zabalMedFont">
								{leaseItem?.logisticStatus === "deployed" ? "Closed" : "Opened"}
							</span>
						</p>
						<div className="flex gap-2 text-xs md:text-[13px]">
							<p>
								<span className="zabalRegFont">Date Updated: </span>
								<span className="zabalMedFont">
									{moment(leaseItem?.updatedAt).format("Do MMM YYYY")}
								</span>
							</p>
							<p>
								<span className="zabalRegFont">Time: </span>
								<span className="zabalMedFont">
									{moment(leaseItem?.updatedAt).format("hh:mmA")}
								</span>
							</p>
						</div>
					</div>
					<button
						className="text-fortcore-green text-2xl"
						onClick={() => handleShow(leaseItem?.objectId)}
					>
						{showCurrent === leaseItem?.objectId ? (
							<MdKeyboardArrowUp />
						) : (
							<MdKeyboardArrowDown />
						)}
					</button>
				</div>
			</div>
			{showCurrent === leaseItem?.objectId && (
				<div className="rounded-md pl-3 py-5 pr-4 md:p-6 bg-[#fefefe] border space-y-4">
					{currentPage === 1 && (
						<FirstStatusTracker
							status={status}
							leaseFor={leaseItem?.leaseFor}
							leaseType={leaseItem?.leaseType}
							leaseSource={leaseItem?.leaseSource}
							leaseItem={leaseItem}
						/>
					)}
					{currentPage === 2 && (
						<SecondStatusTracker
							status={status}
							leaseFor={leaseItem?.leaseFor}
							leaseType={leaseItem?.leaseType}
							leaseSource={leaseItem?.leaseSource}
							leaseId={leaseItem?.objectId}
							deliveryType={leaseItem?.deliveryType}
						/>
					)}
					<Pagination
						currentPage={currentPage}
						totalPages={totalPages}
						onPrevClick={() => setCurrentPage((prev) => prev - 1)}
						onNextClick={() => setCurrentPage((prev) => prev + 1)}
						isPrevDisabled={currentPage === 1}
						isNextDisabled={currentPage === totalPages}
					/>
				</div>
			)}
		</div>
	);
};

OngoingLeaseItem.propTypes = {
	leaseItem: PropTypes.object,
	handleLeaseSelect: PropTypes.func,
	isChecked: PropTypes.bool,
};
SubmittedLeaseItem.propTypes = {
	leaseItem: PropTypes.object,
	handleShow: PropTypes.func,
	showCurrent: PropTypes.string,
};
