import React, { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PropTypes from "prop-types";
import { BsThreeDotsVertical } from "react-icons/bs";
import theme from "../../../../components/mui/theme";
import {
	AnyModal,
	AnyModal2,
} from "../../../finance/newTransaction/investor/create/InvestorCreateAF";
import Restructure from "./Restructure";
import Terminate from "./Terminante";

function BoxComponent({
	amount,
	contribution,
	tenure,
	interest,
	transactionStatus,
	status,
	data,
	setSubmitted,
	setDataToDelete,
}) {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);
	const [restructureModal, setRestructureModal] = useState(false);
	const [verify, setVerify] = useState(false);
	const [terminateModal, setTerminateModal] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked); // Toggle the checkbox state

		if (!isChecked) {
			// If the checkbox was checked, add data.objectId to dataToDelete
			setDataToDelete((prevData) => [...prevData, data.objectId]);
		} else {
			// If the checkbox was unchecked, remove data.objectId from dataToDelete
			setDataToDelete((prevData) =>
				prevData.filter((id) => id !== data.objectId)
			);
		}
	};
	return (
		<>
			<Box width="100%">
				<Stack direction="row" spacing={2} sx={{ width: "100%" }}>
					<Box width="5%">
						<FormControlLabel
							control={
								<Checkbox checked={isChecked} onChange={handleCheckboxChange} />
							}
							sx={{ width: "100%" }}
						/>
					</Box>

					<Box sx={style} onClick={() => setOpen(!open)}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between">
							<Box>
								<Stack spacing={1}>
									<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
										<Typography variant="body1" color="textSecondary">
											Request Amount:{" "}
										</Typography>
										<Typography variant="body1" color="primary">
											{" "}
											NGN {amount}
										</Typography>
									</Box>

									<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
										<Typography variant="body1" color="textSecondary">
											Equity Contribution:{" "}
										</Typography>
										<Typography variant="body1" color="secondary.dark">
											{" "}
											NGN {contribution}
										</Typography>
									</Box>

									<Stack
										direction="row"
										spacing={1}
										sx={{ textTransform: "capitalize" }}>
										<Typography variant="body1" color="textSecondary">
											Tenure: {tenure}
										</Typography>
									</Stack>

									<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
										<Typography variant="body1" color="textSecondary">
											Interest Rate: {interest}%
										</Typography>
									</Box>

									<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
										<Typography
											variant="body1"
											color="textSecondary"
											sx={{ textTransform: "capitalize" }}>
											Transaction Status:{" "}
										</Typography>
										<Typography
											variant="body1"
											sx={{
												textTransform: "capitalize",
												color:
													transactionStatus !== "cancelled"
														? "#003A50"
														: "#f00",
											}}>
											{transactionStatus}
										</Typography>
									</Box>
								</Stack>
							</Box>

							{open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
						</Stack>
					</Box>
				</Stack>

				{/* State Box  */}

				{open && (
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Box width="95%" mt={2}>
							<Box sx={style}>
								{data.transactionStatus !== "cancelled" && (
									<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
										<IconButton onClick={handleClick}>
											<BsThreeDotsVertical />
										</IconButton>
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={openMenu}
											onClose={handleClose}
											MenuListProps={{
												"aria-labelledby": "basic-button",
											}}>
											<MenuItem
												onClick={() => {
													setRestructureModal(true);
													handleClose();
												}}>
												Restructure
											</MenuItem>
											<MenuItem
												onClick={() => {
													setTerminateModal(true);
													handleClose();
												}}
												sx={{ color: theme.palette.error.main }}>
												Terminate
											</MenuItem>
										</Menu>
									</Box>
								)}

								{/* Progress  */}
								<Box mt={2}>
									{/* Lien Authorization  */}

									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}>
										<Box
											mr={2}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<IconButton
												sx={{
													border: [
														"lien authorization",
														"pnd authorization",
														"authorize direct debit",
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													width: "25px",
													height: "25px",
												}}>
												<IconButton
													sx={{
														background: [
															"lien authorization",
															"pnd authorization",
															"authorize direct debit",
															"application approved",
															"application submitted",
														].includes(status)
															? theme.palette.primary.main
															: "#C6D3D8",
														width: "10px",
														height: "10px",
													}}></IconButton>
											</IconButton>

											<Box
												sx={{
													borderLeft: [
														"lien authorization",
														"pnd authorization",
														"authorize direct debit",
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													height: { xs: "5.7vh", sm: "6vh" },
													marginBottom: { xs: "-4.5vh", sm: "-4.5vh" },
												}}></Box>
										</Box>

										{/* Text Box  */}
										<Box
											width="100%"
											mb={2}
											sx={{
												border: [
													"lien authorization",
													"pnd authorization",
													"authorize direct debit",
													"application approved",
													"application submitted",
												].includes(status)
													? "1px solid var(--grey-200, #00525D)"
													: "1px solid var(--grey-200, #E3E9EC)",
												borderRadius: "8px",
												p: 2,
											}}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center">
												<Typography
													variant="body1"
													sx={{
														color: [
															"lien authorization",
															"pnd authorization",
															"authorize direct debit",
															"application approved",
															"application submitted",
														].includes(status)
															? "#555F63"
															: theme.palette.secondary.dark,
													}}>
													Lien Authorization
												</Typography>

												<Button
													variant="contained"
													sx={{
														display: { xs: "none", sm: "flex" },
														background: "rgba(227, 233, 236, 1)",
														color: "rgba(0, 82, 93, 1)",
														boxShadow: "none",

														"&:hover": {
															background: "rgba(227, 233, 236, 1)",
															color: "rgba(0, 82, 93, 1)",
															boxShadow: "none",
														},
													}}>
													View Details
												</Button>
											</Stack>
										</Box>
									</Box>

									{/* PND Authorization  */}

									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}>
										<Box
											mr={2}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<IconButton
												sx={{
													border: [
														"pnd authorization",
														"authorize direct debit",
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													width: "25px",
													height: "25px",
												}}>
												<IconButton
													sx={{
														background: [
															"pnd authorization",
															"authorize direct debit",
															"application approved",
															"application submitted",
														].includes(status)
															? theme.palette.primary.main
															: "#C6D3D8",
														width: "10px",
														height: "10px",
													}}></IconButton>
											</IconButton>

											<Box
												sx={{
													borderLeft: [
														"pnd authorization",
														"authorize direct debit",
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													height: { xs: "5.7vh", sm: "6vh" },
													marginBottom: { xs: "-4.5vh", sm: "-4.5vh" },
												}}></Box>
										</Box>

										{/* Text Box  */}
										<Box
											width="100%"
											mb={2}
											sx={{
												border: [
													"pnd authorization",
													"authorize direct debit",
													"application approved",
													"application submitted",
												].includes(status)
													? "1px solid var(--grey-200, #00525D)"
													: "1px solid var(--grey-200, #E3E9EC)",
												borderRadius: "8px",
												p: 2,
											}}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center">
												<Typography
													variant="body1"
													sx={{
														color: [
															"pnd authorization",
															"authorize direct debit",
															"application approved",
															"application submitted",
														].includes(status)
															? "#555F63"
															: theme.palette.secondary.dark,
													}}>
													PND Authorization
												</Typography>

												<Button
													variant="contained"
													sx={{
														display: { xs: "none", sm: "flex" },
														background: "rgba(227, 233, 236, 1)",
														color: "rgba(0, 82, 93, 1)",
														boxShadow: "none",

														"&:hover": {
															background: "rgba(227, 233, 236, 1)",
															color: "rgba(0, 82, 93, 1)",
															boxShadow: "none",
														},
													}}>
													View Details
												</Button>
											</Stack>
										</Box>
									</Box>

									{/* Authorize Direct Debit  */}

									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}>
										<Box
											mr={2}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<IconButton
												sx={{
													border: [
														"authorize direct debit",
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													width: "25px",
													height: "25px",
												}}>
												<IconButton
													sx={{
														background: [
															"authorize direct debit",
															"application approved",
															"application submitted",
														].includes(status)
															? theme.palette.primary.main
															: "#C6D3D8",
														width: "10px",
														height: "10px",
													}}></IconButton>
											</IconButton>

											<Box
												sx={{
													borderLeft: [
														"authorize direct debit",
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													height: { xs: "5.7vh", sm: "6vh" },
													marginBottom: { xs: "-4.5vh", sm: "-4.5vh" },
												}}></Box>
										</Box>

										{/* Text Box  */}
										<Box
											width="100%"
											mb={2}
											sx={{
												border: [
													"authorize direct debit",
													"application approved",
													"application submitted",
												].includes(status)
													? "1px solid var(--grey-200, #00525D)"
													: "1px solid var(--grey-200, #E3E9EC)",
												borderRadius: "8px",
												p: 2,
											}}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center">
												<Typography
													variant="body1"
													sx={{
														color: [
															"authorize direct debit",
															"application approved",
															"application submitted",
														].includes(status)
															? "#555F63"
															: theme.palette.secondary.dark,
													}}>
													Authorize Direct Debit
												</Typography>

												<Button
													variant="contained"
													sx={{
														display: { xs: "none", sm: "flex" },
														background: "rgba(227, 233, 236, 1)",
														color: "rgba(0, 82, 93, 1)",
														boxShadow: "none",

														"&:hover": {
															background: "rgba(227, 233, 236, 1)",
															color: "rgba(0, 82, 93, 1)",
															boxShadow: "none",
														},
													}}>
													View Details
												</Button>
											</Stack>
										</Box>
									</Box>

									{/* Application Approved  */}

									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}>
										{/* Status Progress  */}
										<Box
											mr={2}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<IconButton
												sx={{
													border: [
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													width: "25px",
													height: "25px",
												}}>
												<IconButton
													sx={{
														background: [
															"application approved",
															"application submitted",
														].includes(status)
															? theme.palette.primary.main
															: "#C6D3D8",
														width: "10px",
														height: "10px",
													}}></IconButton>
											</IconButton>

											<Box
												sx={{
													borderLeft: [
														"application approved",
														"application submitted",
													].includes(status)
														? "2px solid var(--grey-200, #00525D)"
														: "2px solid var(--grey-400, #C6D3D8)",
													height: { xs: "5.7vh", sm: "6vh" },
													marginBottom: { xs: "-4.5vh", sm: "-4.5vh" },
												}}></Box>
										</Box>

										{/* Text Box  */}
										<Box
											width="100%"
											mb={2}
											sx={{
												border: [
													"application approved",
													"application submitted",
												].includes(status)
													? "1px solid var(--grey-200, #00525D)"
													: "1px solid var(--grey-200, #E3E9EC)",
												borderRadius: "8px",
												p: 2,
											}}>
											<Typography
												variant="body1"
												sx={{
													color: [
														"application approved",
														"application submitted",
													].includes(status)
														? "#555F63"
														: theme.palette.secondary.dark,
												}}>
												Application Approved
											</Typography>
										</Box>
									</Box>

									{/* Application Submitted  */}
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}>
										{/* Status Progress  */}
										<Box
											mt={-1}
											mr={2}
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<IconButton
												sx={{
													border:
														status === "application submitted"
															? "2px solid var(--grey-200, #00525D)"
															: "2px solid var(--grey-400, #C6D3D8)",
													width: "25px",
													height: "25px",
												}}>
												<IconButton
													sx={{
														background:
															status === "application submitted"
																? theme.palette.primary.main
																: "#C6D3D8",
														width: "10px",
														height: "10px",
													}}></IconButton>
											</IconButton>
										</Box>

										{/* Text Box  */}
										<Box
											width="100%"
											mb={2}
											sx={{
												border:
													status === "application submitted"
														? "1px solid var(--grey-200, #00525D)"
														: "1px solid var(--grey-200, #E3E9EC)",
												borderRadius: "8px",
												p: 2,
											}}>
											<Typography
												variant="body1"
												sx={{
													color:
														status === "application submitted"
															? "#555F63"
															: theme.palette.secondary.dark,
												}}>
												Application Submitted
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Box>

			<AnyModal2 open={restructureModal}>
				{/* Modal for Restructure submission */}
				<Restructure
					data={data}
					setRestructureModal={setRestructureModal}
					setVerify={setVerify}
					verify={verify}
				/>
			</AnyModal2>

			<AnyModal open={terminateModal}>
				<Terminate
					data={data}
					setTerminateModal={setTerminateModal}
					setSubmitted={setSubmitted}
				/>
			</AnyModal>
		</>
	);
}

BoxComponent.propTypes = {
	amount: PropTypes.any,
	contribution: PropTypes.any,
	tenure: PropTypes.string,
	interest: PropTypes.any,
	status: PropTypes.string,
	transactionStatus: PropTypes.string,
	data: PropTypes.any,
	setSubmitted: PropTypes.any,
	setDataToDelete: PropTypes.any,
};
export default BoxComponent;

const style = {
	borderRadius: "8px",
	border: "1px solid var(--grey-500, #B8C8CF)",
	background: "#FEFEFE",
	p: 2,
	width: "100%",
	cursor: "pointer",
};

export const BoxSkeleton = () => {
	return (
		<Box>
			<Stack
				direction="row"
				// alignItems="center"
				spacing={2}
				sx={{ width: "100%" }}>
				<Box width="5%">
					<Skeleton variant="rectangular" width={20} height={20} />
				</Box>

				<Box sx={style}>
					<Box>
						<Stack spacing={1}>
							<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

							<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

							<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

							<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

							<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
						</Stack>
					</Box>
				</Box>
			</Stack>
		</Box>
	);
};
