import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { MdLock } from "react-icons/md";
import { toast } from "react-toastify";
import { SummaryPageNav } from "../../layout/Navbar";
import {
	queryPartialFundedAsset,
	updateInvestorCreatedAsset,
} from "../../redux/lease/leaseServices";
import NoLoggedInUser from "../../components/networkerror/NetworkError";
import {
	closeMiniLoginModal,
	currentUserData,
} from "../../redux/authRedux/authActions";
import { RequestOrderListSkeleton } from "../leasing/requestlease/LeaseItems";
import Skeleton from "react-loading-skeleton";
import {
	LeaseSummaryInput,
	LeaseSummaryItemDetails,
} from "../leasing/requestlease/LeaseSummary";
import {
	formatAmountToIncludeCurrency,
	getInterestAmount,
} from "../../common/utils";
import { LeaseFormSelect } from "../leasing/requestlease/LeaseForm";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import ModalContainer from "../../components/ModalContainer";
import { SuccessCheckModal } from "../../layout/CustomComponents";
import EmptyData from "../../components/EmptyData";
import Parse from "../../api/ApiConfig";
import MiniLogin from "./MiniLogin";
import { clearActivateAssetSuccess } from "../../redux/lease/leaseActions";

const PartialFundedAsset = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { networkError, currentUser, toggleMiniLoginModal } = useSelector(
		(state) => state.auth
	);
	const {
		queryPartialAssetLoading,
		queryPartialAssetSuccess,
		updateCreatedAssetLoading,
		updateCreatedAssetSuccess,
	} = useSelector((state) => state.lease);

	const [toggleNumberOfSlots, setToggleNumberOfSlots] = useState(false);

	const [summaryData, setSummaryData] = useState({
		numberOfSlots: "",
		selectedSlots: null,
	});

	const { numberOfSlots, selectedSlots } = summaryData;

	const fetchUserInfo = async () => {
		try {
			const user = Parse.Object.extend("User");
			const query = new Parse.Query(user);
			const response = await query?.get(Parse.User.current()?.id);
			dispatch(currentUserData(response));
		} catch (error) {
			toast.error("You are not signed in to your account.");
		}
	};

	const [searchParams] = useSearchParams();
	const query = searchParams?.get("id");

	const interestAmount = getInterestAmount(
		queryPartialAssetSuccess?.interest,
		queryPartialAssetSuccess?.productObj?.amount
	);

	// const interestAmount = getAmountWithDurationAndInterest(
	// 	queryPartialAssetSuccess?.interest,
	// 	queryPartialAssetSuccess?.leaseDuration,
	// 	queryPartialAssetSuccess?.productObj?.amount
	// );

	const amount =
		Number(queryPartialAssetSuccess?.productObj?.amount?.replaceAll(",", "")) /
		4;
	const paidSlots = queryPartialAssetSuccess?.paidSlots;

	let arrOfAmount = [];

	for (let i = 1; i <= 4 - paidSlots; i++) {
		const a = {
			slotNum: i,
			price: amount * i,
			details: `${formatAmountToIncludeCurrency(
				"NGN",
				amount * i
			)} ( ${i} slot${i > 1 ? "s" : ""} )`,
		};

		arrOfAmount.push(a);
	}

	const onProceedFromNoAsset = () => {
		if (Parse.User.current()?.id) {
			navigate("/leasing/investment-board");
		} else {
			navigate("/login");
		}
	};

	const interestToEarn = (interestAmount / 4) * selectedSlots?.slotNum || 0;

	const onProceedToSubmit = () => {
		if (!Parse.User.current()?.id) {
			toast.error("Please login to your account to continue.");
			return;
		} else if (
			queryPartialAssetSuccess?.investors.includes(Parse.User.current()?.id)
		) {
			toast.error(
				"You cannot add this asset as it already exists in your portfolio."
			);
			return;
		}

		const message = "New asset added to portfolio.";
		const metaData = {
			category: "Asset",
			linkToId: queryPartialAssetSuccess?.objectId,
			moreDetails: `You have decided to purchase ${selectedSlots?.slotNum} slot(s) on this asset. This asset has been added to your portfolio as an inactive investment.`,
		};

		const data = {
			investors: [
				...(queryPartialAssetSuccess?.investors || []),
				Parse.User.current()?.id,
			],
			investorsArray: [
				...(queryPartialAssetSuccess?.investorsArray || []),
				{
					user: Parse.User.current()?.id,
					name: `${currentUser?.firstName} ${currentUser?.lastName}`,
					phone: currentUser?.phone,
					address: currentUser?.homeAddress,
					slots: selectedSlots?.slotNum,
					amountToInvest: selectedSlots?.price,
					interestToEarn,
					paid: false,
				},
			],
		};

		dispatch(
			updateInvestorCreatedAsset(
				queryPartialAssetSuccess?.objectId,
				data,
				message,
				metaData,
				undefined
			)
		);
	};

	const onProceedToPortfolio = () => {
		dispatch(clearActivateAssetSuccess());
		navigate("/leasing/investment-board");
	};

	useEffect(() => {
		const selected_slots = arrOfAmount?.find(
			(slot) => slot.details === numberOfSlots
		);
		setSummaryData((prev) => ({ ...prev, selectedSlots: selected_slots }));
	}, [numberOfSlots]);

	useEffect(() => {
		fetchUserInfo();
	}, []);

	useEffect(() => {
		dispatch(queryPartialFundedAsset(query));
	}, [query]);

	if (!query) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<>
			{networkError && <NoLoggedInUser />}
			{toggleMiniLoginModal && (
				<ModalContainer modal={toggleMiniLoginModal}>
					{currentUser ? (
						<SuccessCheckModal
							text="Login Success. You can now proceed with your transaction."
							onRequestClick={() => dispatch(closeMiniLoginModal())}
						/>
					) : (
						<MiniLogin />
					)}
				</ModalContainer>
			)}
			{updateCreatedAssetSuccess && (
				<ModalContainer modal={updateCreatedAssetSuccess}>
					<SuccessCheckModal
						text={`The asset with the id of ${query} has been added to your investent portfolio.`}
						isShowBtn={true}
						btnText="view your portfolio"
						onRequestClick={onProceedToPortfolio}
					/>
				</ModalContainer>
			)}
			<SummaryPageNav dataExist={!!queryPartialAssetSuccess} />
			<h6>Transaction Id:</h6>
			<div className="mt-[50px] max-w-[1400x] px-3 sm:px-8 md:px-12 lg:px-16 xl:px-20">
				<p className="text-sm md:text-[14.5px] zabalMedFont text-gray-500">
					TransactionID:{" "}
					<span
						className={
							queryPartialAssetSuccess ? "text-fortcore-green" : "text-red-500"
						}
					>
						TRN-{query}
					</span>
				</p>
				<div className="mt-6 md:mt-8">
					{queryPartialAssetLoading ? (
						<div className="space-y-6 md:space-y-8 mb-10">
							<div className="border rounded-md">
								<RequestOrderListSkeleton />
							</div>
							<div className="grid gap-4">
								<SingleLoader />
								<SingleLoader />
								<SingleLoader />
								<div className="hidden sm:block">
									<SingleLoader />
								</div>
								<div className="hidden sm:block">
									<SingleLoader />
								</div>
							</div>
						</div>
					) : queryPartialAssetSuccess ? (
						<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8 mb-10 sm:mb-14">
							<div className="text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
								<p>Asset Summary</p>
							</div>
							<LeaseSummaryItemDetails
								item={queryPartialAssetSuccess?.productObj}
								interestAmount={interestAmount}
								// interest={queryPartialAssetSuccess?.interest}
								availableSlots={4 - paidSlots || 0}
							/>
							<div className="grid gap-4 md:gap-5 lg:gap-6">
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.leaseType}
									text="lease type"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.leaseDuration}
									text="lease duration"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.productObj?.category}
									text="product category"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.productObj?.brand}
									text="brand"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.productObj?.model}
									text="model"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.productObj?.year}
									text="year"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.productObj?.condition}
									text="condition"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.productObj?.color}
									text="color"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.leaseStructure}
									text="lease structure"
								/>
								<LeaseSummaryInput
									input_value={queryPartialAssetSuccess?.waitingPeriod}
									text="waiting period"
								/>
								<LeaseFormSelect
									text="amount to invest"
									context="choose number of slots"
									isOpened={toggleNumberOfSlots}
									currentText={numberOfSlots}
									handleToggle={() =>
										setToggleNumberOfSlots(!toggleNumberOfSlots)
									}
									arrOptions={arrOfAmount.map((a) => a.details)}
									onHandleSelect={(d) => {
										setSummaryData((prev) => ({
											...prev,
											numberOfSlots: d,
										}));
										setTimeout(() => {
											setToggleNumberOfSlots(false);
										}, 100);
									}}
								/>
								<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
										Interest Rate (NGN):
									</p>
									<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
										<div className="flex items-center justify-between w-full">
											<p>
												<span className="text-[#393F42] zabalMedFont">
													NGN{" "}
												</span>
												<span>
													{formatAmountToIncludeCurrency(
														undefined,
														interestToEarn
													)}
												</span>
											</p>
											<p className="text-fortcore-green zabalMedFont">{`( ${queryPartialAssetSuccess?.interest}% P/A)`}</p>
										</div>
										<span className="h-full grid place-items-center">
											<MdLock size={18} color="#717E83" />
										</span>
									</div>
								</div>
								<div className="hidden sm:flex justify-end gap-4">
									<CustomGreenBtn
										text="add to portfolio"
										width="140px"
										height="42px"
										fontSize="13px"
										onRequestClick={onProceedToSubmit}
										isDisabled={
											numberOfSlots === "" ||
											updateCreatedAssetLoading ||
											queryPartialAssetSuccess?.paidSlots === 4
										}
										isLoading={updateCreatedAssetLoading}
									/>
								</div>
								<div className="flex flex-col gap-4 pt-4 sm:hidden">
									<CustomGreenBtn
										text="add to portfolio"
										onRequestClick={onProceedToSubmit}
										isDisabled={
											numberOfSlots === "" ||
											updateCreatedAssetLoading ||
											queryPartialAssetSuccess?.paidSlots === 4
										}
										isLoading={updateCreatedAssetLoading}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="h-[400px] flex flex-col items-center justify-center gap-3">
							<div className="w-full">
								<EmptyData
									text={`No asset with the above query is found. ${
										Parse.User.current()?.id
											? "Click the button below to view your investment portfolio"
											: "Login to your account to create a new asset"
									}.`}
								/>
							</div>
							<CustomGreenBtn
								width={Parse.User.current()?.id ? "130px" : "103px"}
								height="40px"
								text={Parse.User.current()?.id ? "view portfolio" : "login"}
								onRequestClick={onProceedFromNoAsset}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PartialFundedAsset;

const SingleLoader = () => (
	<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-3 sm:justify-between sm:items-center">
		<div className="w-[150px] md:w-[200px]">
			<Skeleton className="h-[22px] md:h-[25px]" />
		</div>
		<div className="w-full sm:w-2/3 md:w-4/6">
			<Skeleton className="h-[35px] md:h-[38px]" />
		</div>
	</div>
);
