import moment from 'moment'
import React from 'react'
import { MdClose } from 'react-icons/md'
import Container from '../../../layout/Container'
import { CustomGreenBtn } from '../../../layout/CustomButtons'
import PropTypes from 'prop-types'

const PowerOfAttorney = ({ investor_signature, witness_signature, handleChange, onClose, onHandleSubmit, isAttorneyBtnDisabled }) => {
  const disabled = isAttorneyBtnDisabled()

  return (
    <Container>
      <div className='bg-white rounded-md py-4 md:py-5 zabalRegFont w-[10/12] max-w-[450px] sm:max-w-[550px] mx-auto h-[80vh] max-h-[700px]'>
        <div className="pb-3 px-4 md:px-5 flex items-center justify-between text-fortcore-green">
          <p className="text-md chillaxFont font-semibold">Power of Attorney</p>
          <button className='text-fortcore-green' onClick={onClose}><MdClose size={18} /></button>
        </div>
        <div className="text-justify px-4 md:px-5 text-sm h-[87%] overflow-y-scroll text-[#393F42] scroll-wrapper space-y-2">
          <div className='space-y-1.5 '>
            <p className='leading-relaxed'>THIS POWER OF ATTORNEY (this &apos;<em>Agreement</em>&apos;) is executed and delivered as of <span>{moment(new Date()).format('MMMM Do, YYYY')}</span> (“Effective Date”) by the undersigned, (hereinafter referred to as &apos;Investor&apos;). For and in consideration of the mutual covenants contained herein, and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, Investor hereby constitutes and appoints Fortcore Technology Limited (“Fortcore”, a technology company, as Investor&apos;s true and lawful attorney-in-fact and agent, with full power of substitution and re-substitution, for Investor and in Investor&apos;s name, place and stead, in any and all capacities.</p>
            <p className='leading-relaxed'>You should read this Power of Attorney carefully.</p>
            <p>By signing this Power of Attorney, you are authorizing Fortcore to act for you, the Investor.</p>
            <p className='leading-relaxed'>Before you sign this Power of Attorney, you should know these important facts: </p>
            <ul className="list-decimal px-4 space-y-0.5 text-[13.5px]">
              <li> This document gives Fortcore the powers to manage, dispose of, sell, and convey your real and personal property.</li>
              <li>This document gives Fortcore the power to accept or receive any of your property, in trust or otherwise, as a gift. </li>
              <li>Fortcore will have the right to receive reasonable payment for services provided under this Power of Attorney.</li>
              <li> The powers you give Fortcore will continue to exist for your entire lifetime, unless you state that the Power of Attorney will last for a shorter period of time or unless you otherwise terminate the Power of Attorney. </li>
              <li>The powers you give Fortcore in this Power of Attorney will continue to exist even if you can no longer make your own decisions respecting the management of your property.</li>
              <li>You can amend or change this Power of Attorney only by executing a new Power of Attorney or by executing an amendment through the same formalities as an original. </li>
              <li> When effective, this Power of Attorney will give Fortcore the right to;
                <small className='block py-0.5 text-[12.5px]'>a. To transact all business and sign, execute, acknowledge and deliver all such instruments, agreements, and documents, and to take all such actions, as may be necessary or appropriate to effect the transactions described in this Agreement. </small>
                <small className='block py-0.5 text-[12.5px]'>b. To access, monitor and control Investor&apos;s portfolio of investments, including, without limitation, the power to buy and sell securities, transfer assets, and make decisions regarding portfolio management.</small>
                <small className='block py-0.5 text-[12.5px]'>c. To exercise all voting rights associated with the securities in Investor&apos;s portfolio.</small>
              </li>
              <li>This Power of Attorney shall be binding upon Investor and shall apply to the benefit of Investor&apos;s heirs, executors, administrators, successors, and assigns. </li>
              <li> Investor agrees that any person, corporation, or entity which relies upon the acts of Fortcore hereunder shall be fully protected and indemnified in relying upon the same. </li>
              <li>This Power of Attorney shall remain in full force and effect until revoked by investor.</li>
              <li>Investor may revoke this Power of Attorney by giving 1 (One) month written notice to Fortcore to this effect.</li>
            </ul>
          </div>
          <div className='space-y-3'>
            <p>In The Presence of which, Investor has signed this Agreement as of the date first above written.</p>
            <div className="flex justify-between flex-wrap gap-3 pt-5">
              <div className="flex flex-col gap-2">
                <p className='text-[13px] md:text-sm'>[Investor&apos;s E-Signature]</p>
                <input type="text" className='border-b-2 border-[#b8b8b8] focus:border-fortcore-green px-1.5 py-0.5 outline-none capitalize zabalMedFont' name='investor_signature' id='investor_signature' value={investor_signature} onChange={handleChange} />
                <p className='text-xs'>Please type your full name</p>
              </div>
              <div className="flex flex-col gap-2">
                <p className='text-[13px] md:text-sm'>[Witness&apos; E-Signature]</p>
                <input type="text" className='border-b-2 border-[#b8b8b8] focus:border-fortcore-green px-1.5 py-0.5 outline-none capitalize zabalMedFont' name='witness_signature' id='witness_signature' value={witness_signature} onChange={handleChange} />
                <p className='text-xs'>Please type your full name</p>
              </div>
            </div>
          </div>
          <div className="sm:hidden pt-2">
            <CustomGreenBtn text='proceed' onRequestClick={onHandleSubmit} isDisabled={disabled} />
          </div>
          <div className="hidden sm:flex justify-end pt-4">
            <CustomGreenBtn text='proceed' width='110px' height='42px' fontSize='13.5px' onRequestClick={onHandleSubmit} isDisabled={disabled} />
          </div>
        </div>
      </div>
    </Container>
  )
}
export default PowerOfAttorney

PowerOfAttorney.propTypes = {
  onClose: PropTypes.func,
  handleChange: PropTypes.func,
  witness_signature: PropTypes.string,
  investor_signature: PropTypes.string,
  onHandleSubmit: PropTypes.func,
  isAttorneyBtnDisabled: PropTypes.func,
}