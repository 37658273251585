import React, { useState, useEffect } from "react";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import { useSelector, useDispatch } from "react-redux";
import { ProfileInput } from "./PersonalInfo";
import { PlainSelect, SelectDropDown } from "../../../layout/CustomComponents";
import { BUSINESSTYPE, shortenLongText } from "../../../common/utils";
import { Preloader } from "../../../components/globalStyled";
import { useUploadFile } from "../../../components/customhooks/useUploadFile";
import GeneralInformation from "./GeneralInformation";
import { PersonalFormCardToggler } from "./CompletePersonalForm";
import Parse from "../../../api/ApiConfig";
import { updateUserAndBusinessData } from "../../../redux/authRedux/authServices";

const CompleteBusinessForm = () => {
	const dispatch = useDispatch();
	const { currentUser, updatingUserAccountLoading } = useSelector(
		(state) => state.auth
	);
	const [businessForm, setBusinessForm] = useState({
		bvn: currentUser?.bvn || "",
		business_name: currentUser?.businessName,
		business_address: currentUser?.businessAddress || "",
		incorporation_certificate: currentUser?.certificateOfIncorporation,
		incorp_cert: null,
		business_type: currentUser?.businessType || "",
	});

	const [toggleAdditionalInfo, setToggleAdditionalInfo] = useState(false);
	const [toggleBusinessType, setToggleBusinessType] = useState(false);

	const {
		business_name,
		business_address,
		incorporation_certificate,
		incorp_cert,
		business_type,
	} = businessForm;

	const handleChange = (e) =>
		setBusinessForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const { loading, fileUploadSuccess } = useUploadFile(incorp_cert);

	useEffect(() => {
		if (fileUploadSuccess) {
			setBusinessForm((prev) => ({
				...prev,
				incop_cert: null,
				incorporation_certificate: fileUploadSuccess,
			}));
		}
	}, [fileUploadSuccess]);

	const isBtnDisabled = () => {
		if (
			business_name === "" ||
			business_address === "" ||
			business_type === "" ||
			incorporation_certificate === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const dataForBusinessProfile = {
		businessName: business_name,
		businessAddress: business_address,
		businessType: business_type,
		user: user,
		certificateOfIncorporation: incorporation_certificate || "",
		fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
		email: currentUser?.email,
	};

	const dataForUserProfile = {
		businessName: business_name,
		businessType: business_type,
		businessAddress: business_address,
		certificateOfIncorporation: incorporation_certificate,
	};

	const onSubmitBusinessForm = () => {
		dispatch(
			updateUserAndBusinessData(dataForBusinessProfile, dataForUserProfile)
		);
	};

	return (
		<div className="space-y-3.5 md:space-y-4 lg:space-y-5">
			<GeneralInformation />
			<div className="space-y-2 space-y-2.5">
				<PersonalFormCardToggler
					text="additional information for asset leasing"
					isOpen={toggleAdditionalInfo}
					onClick={() => setToggleAdditionalInfo((prev) => !prev)}
				/>
				{toggleAdditionalInfo && (
					<div className="flex flex-col gap-3 md:gap-4 rounded bg-white p-3.5 md:p-4">
						<ProfileInput
							text="business name"
							id="business_name"
							type="text"
							value={business_name}
							handleChange={handleChange}
							color
							placeholderText="Enter business name"
						/>
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7 relative z-[1]">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Business Type:
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="choose business type"
									isOpened={toggleBusinessType}
									currentText={business_type}
									handleToggle={() => setToggleBusinessType((prev) => !prev)}
								/>
								{toggleBusinessType && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={BUSINESSTYPE}
											onHandleSelect={(d) => {
												setBusinessForm((prev) => ({
													...prev,
													business_type: d,
												}));
												setTimeout(() => {
													setToggleBusinessType(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<ProfileInput
							text="business address"
							id="business_address"
							type="text"
							value={business_address}
							handleChange={handleChange}
							color
							placeholderText="Enter business address"
						/>

						{/* handle certification add */}
						<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-5">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Certificate of Incorporation :
							</p>
							<div className="flex gap-4 items-center w-full sm:w-[65%] md:w-3/5 xl:w-4/6 text-fortcore-green font-semibold zabalMedFont text-[13px] lg:text-sm">
								{incorporation_certificate && (
									<a
										href={incorporation_certificate}
										target="_blank"
										rel="noreferrer"
										className="zabalMedFont text-xs md:text-[12.5px] text-[#393F42]"
									>
										{shortenLongText(incorporation_certificate)}
									</a>
								)}
								{loading ? (
									<Preloader />
								) : (
									<button
										onClick={() =>
											document.getElementById("addCertificate").click()
										}
									>
										{incorporation_certificate ? (
											"Change File"
										) : (
											<span>
												{" "}
												Add Certificate{" "}
												<span className="text-red-500 zabalMedFont sm:hidden">
													*
												</span>
											</span>
										)}
									</button>
								)}
								<input
									type="file"
									id="addCertificate"
									className="hidden"
									onChange={(e) =>
										setBusinessForm((prev) => ({
											...prev,
											incorp_cert: e.target.files[0],
										}))
									}
									accept="image/*,.pdf"
								/>
								{!incorporation_certificate && !loading && (
									<p className="hidden sm:block pt-0.5 text-[11.5px] zabalMedFont text-red-500">
										Please upload certificate of incoporation *
									</p>
								)}
							</div>
						</div>

						<div className="hidden sm:flex justify-end py-2">
							<CustomGreenBtn
								text="save"
								width="105px"
								height="42px"
								fontSize="13px"
								isDisabled={updatingUserAccountLoading || isBtnDisabled()}
								isLoading={updatingUserAccountLoading}
								onRequestClick={onSubmitBusinessForm}
							/>
						</div>
						<div className="sm:hidden pt-4 pb-2">
							<CustomGreenBtn
								text="save"
								isDisabled={updatingUserAccountLoading || isBtnDisabled()}
								isLoading={updatingUserAccountLoading}
								onRequestClick={onSubmitBusinessForm}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default CompleteBusinessForm;
