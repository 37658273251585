import React from "react";
import PropTypes from "prop-types";
import NotifyUser from "../../../layout/NotifyUser";
import { formatAmountToIncludeCurrency } from "../../../common/utils";

const ConfirmWithdrawal = ({
	setActiveWithdrawalTab,
	onCloseModal,
	amount,
	description,
	accountName,
	accountNumber,
	bankName,
	totalFee,
}) => {
	const total = Number(amount?.replaceAll(",", "")) + totalFee;

	return (
		<NotifyUser
			header="Confirm Transaction"
			btnText="confirm"
			onWhiteBtnClick={() => setActiveWithdrawalTab(2)}
			onGreenBtnClick={() => setActiveWithdrawalTab(4)}
			onCloseBtnClick={onCloseModal}
		>
			<div className="divide-y">
				<ConfirmCard
					textOne="From"
					textTwo="Transaction Fee"
					textThree="Balance"
					textFour={formatAmountToIncludeCurrency("NGN", totalFee)}
				/>
				<ConfirmCard
					textOne="To"
					textTwo="Amount"
					textThree={accountName}
					textFour={formatAmountToIncludeCurrency(
						"NGN",
						amount?.replaceAll(",", "")
					)}
				/>
				<ConfirmCard
					textOne="Bank Name"
					textTwo="Account Number"
					textThree={bankName}
					textFour={accountNumber}
				/>
				<ConfirmCard
					textOne="Description"
					textTwo="Total"
					textThree={description ? description : "None"}
					textFour={formatAmountToIncludeCurrency("NGN", total)}
				/>
			</div>
		</NotifyUser>
	);
};

export default ConfirmWithdrawal;

ConfirmWithdrawal.propTypes = {
	setActiveWithdrawalTab: PropTypes.func,
	onCloseModal: PropTypes.func,
	amount: PropTypes.string,
	description: PropTypes.string,
	accountName: PropTypes.string,
	accountNumber: PropTypes.string,
	bankName: PropTypes.string,
	totalFee: PropTypes.number,
};

const ConfirmCard = ({ textOne, textTwo, textThree, textFour }) => {
	return (
		<div className="py-3 space-y-3">
			<div className="flex justify-between gap-4 text-xs text-[#8E9DA4] zabalRegFont">
				<p>{textOne}</p>
				<p>{textTwo}</p>
			</div>
			<div className="flex justify-between gap-4 text-[13px] md:text-[13.5px] zabalMedFont">
				<p>{textThree}</p>
				<p>{textFour}</p>
			</div>
		</div>
	);
};

ConfirmCard.propTypes = {
	textOne: PropTypes.string,
	textTwo: PropTypes.string,
	textThree: PropTypes.string,
	textFour: PropTypes.string,
};
