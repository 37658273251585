import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Image from "../../../assets/leaseImage.png";

function LeaseSection() {
	return (
		<>
			<Container maxWidth="lg" id="leasing">
				<Box
					mt={{ xs: 10, md: 5 }}
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
						gap: { xs: 4, md: 5 },
						alignItems: "center",
					}}
				>
					<Stack
						spacing={2}
						sx={{
							order: {
								xs: 1,
								sm: 0,
							},
						}}
					>
						<Typography
							variant="h1"
							component="h2"
							color="primary"
							sx={{ fontWeight: 600, fontSize: { xs: "32px", sm: "60px" } }}
						>
							Lease an asset seamlessly
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							sx={{ fontWeight: 500, fontSize: { xs: "20px", sm: "22px" } }}
						>
							Easily access digitalized leasing options and invest in leasing
							assets.
						</Typography>

						<Button
							variant="outlined"
							type="button"
							sx={{ height: "50px", width: "270px" }}
						>
							Explore Lease Service
						</Button>
					</Stack>
					{/* Plane  */}

					<Box sx={{ position: "relative" }}>
						<img src={Image} alt="plane image" style={{ zIndex: 1 }} />
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default LeaseSection;
