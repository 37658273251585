import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Balance from "../../../components/Balance";
import EmptyData from "../../../components/EmptyData";
import {
	RegularSavingsCard,
	SavingsSkeleton,
} from "../../leasing/regularsavings/RegularSavingsView";
import { fetchTravelsRegularSavings } from "../../../redux/travels/travelServices";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";
import { clearPaymentDeductionSuccess } from "../../../redux/payments/paymentActions";
import {
	clearRegularSavings,
	clearRegularSavingsCreatedSuccess,
	openConfirmAutoDebitModal,
} from "../../../redux/lease/leaseActions";
import { walletType } from "../../../common/utils";

const TravelAdvanceSavingsView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { fetchSavingsLoading, fetchSavingsSuccess } = useSelector(
		(state) => state.travel
	);

	const { customerWallet } = useSelector((state) => state.payment);

	const regularSavingsWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.regularSavings
	);

	useEffect(() => {
		dispatch(fetchTravelsRegularSavings());
		dispatch(clearPaymentDeductionSuccess());
		dispatch(clearRegularSavingsCreatedSuccess());
		dispatch(clearRegularSavings());
	}, []);

	const sortedRegularSavings = fetchSavingsSuccess?.sort(
		(planOne, planTwo) =>
			new Date(planTwo.createdAt)?.getTime() -
			new Date(planOne.createdAt)?.getTime()
	);

	return (
		<>
			<MobileNavDetails text="travels savings" />
			<Helmet>
				<title>Fortcore - Regular Savings View</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="travel advance" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<BackTracker text="regular savings" onNavigate={() => navigate(-1)} />
					<div className="flex gap-1.5">
						<UrlTracker
							text="travel advance"
							onNavigate={() => navigate("/travels")}
						/>
						<UrlTracker text="regular savings" isCurrentUrl />
					</div>
					<div className="sm:mt-6"></div>
					<Balance
						cardTitle="Total Savings Balance"
						btnText="save now"
						isShowInvestBtn={true}
						onRequestClick={() => dispatch(openConfirmAutoDebitModal("travel"))}
						wallet={regularSavingsWallet}
					/>
					<div className="mt-[25px] sm:mt-[30px]"></div>
					<div
						className={`flex flex-col gap-4 md:gap-5 rounded-md p-4 sm:p-5 mb-10 ${
							!fetchSavingsLoading && fetchSavingsSuccess?.length === 0
								? "bg-white"
								: "bg-[#F6F7F8]"
						}`}
					>
						{fetchSavingsLoading ? (
							Array(2)
								.fill(0)
								.map((_, i) => <SavingsSkeleton key={i} />)
						) : fetchSavingsSuccess?.length > 0 ? (
							sortedRegularSavings.map((d) => (
								<RegularSavingsCard
									key={d.objectId}
									data={d}
									isShowBtn={true}
									baseRoute="/travels/regular-savings"
								/>
							))
						) : (
							<div className="w-full h-[270px] md:h-[250px]">
								<EmptyData text="You have not created any savings yet." />
							</div>
						)}
					</div>
				</>
			</Container>
		</>
	);
};

export default TravelAdvanceSavingsView;
