import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import SliderOne from "./components/SliderOne";
import { Box } from "@mui/material";

function Slider() {
	return (
		<>
			<Box sx={{ borderRadius: "12px", cursor: "pointer" }}>
				<Swiper
					pagination={{
						clickable: true,
					}}
					loop={true}
					grabCursor={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
					}}
					modules={[Pagination, Autoplay]}
					className="mySwiper">
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
					<SwiperSlide>
						<SliderOne />
					</SwiperSlide>
				</Swiper>
			</Box>
		</>
	);
}

export default Slider;
