import React, { useContext } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  // IconButton,
  Stack,
  Typography,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { EducationContext } from "../../Context/EducationContext";
import Skeleton from "react-loading-skeleton";
import { loadingData } from "../video/Video";
import BookFlip from "../../assets/book.svg";

function Books() {
  const navigate = useNavigate();
  // const theme = useTheme();
  const { filteredData, loading } = useContext(EducationContext);

  const courseType = filteredData.filter(
    (books) => books.courseType === "book"
  );
  const bookData = courseType.filter((book) => book.approve === true);
  // const openedArray = bookData.filter((audio) => audio.isOpened === true);

  // eslint-disable-next-line no-undef
  console.log("bookData:", courseType);
  return (
    <>
      <Box>
        {loading ? (
          <Box>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Box>
        ) : (
          <>
            {bookData.length > 0 ? (
              <Typography color="primary" variant="h3">
                Suggestions For You
              </Typography>
            ) : (
              <Typography color="primary" variant="h3">
                No Audio files at the moment
              </Typography>
            )}
          </>
        )}
        <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

        <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
          {loading ? (
            <>
              {loadingData.map((index) => (
                <>
                  <Skeleton
                    key={index}
                    variant="rounded"
                    sx={{
                      minWidth: { xs: "100%", sm: 345 },
                      maxWidth: 345,
                      height: "222px",
                      borderRadius: "8px",
                    }}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {bookData.length > 0 ? (
                <>
                  {bookData.map((data, index) => (
                    <>
                      <Card
                        key={index}
                        sx={{
                          minWidth: { xs: "100%", sm: 345 },
                          maxWidth: 345,
                          height: "222px",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/education/books/${data.objectId}`)
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            sx={{
                              height: "150px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              objectFit: "cover",
                            }}
                            image={data.coverPhoto}
                          ></CardMedia>
                          <CardContent sx={{ background: "primary.light" }}>
                            <Typography
                              gutterBottom
                              variant="h3"
                              component="div"
                              color="primary.main"
                            >
                              {data.name}
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="caption"
                                color="secondary.light"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {data.category}
                              </Typography>

                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <img src={BookFlip} alt="" />
                                {/* <Typography
                                  variant="body2"
                                  color="secondary.light"
                                >
                                  {data.totalPage} Pages
                                </Typography> */}
                              </Stack>
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Stack>
      </Box>

      <Box>
        {/* Suggestions For you  */}
        {/* <Box>
          <Typography color="primary" variant="h3">
            Suggestions For You
          </Typography>
          <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

          <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
            {audioData.map((data, index) => (
              <>
                <Card
                  key={index}
                  sx={{
                    minWidth: { xs: "100%", sm: 345 },
                    maxWidth: 345,
                    height: "222px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/education/books/${data.id}`)}
                >
                  <CardActionArea>
                    <CardMedia
                      sx={{
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        objectFit: "cover",
                      }}
                      image={data.image}
                    ></CardMedia>
                    <CardContent sx={{ background: "primary.light" }}>
                      <Typography
                        gutterBottom
                        variant="h3"
                        component="div"
                        color="primary.main"
                      >
                        {data.title}
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="caption"
                          color="secondary.light"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {data.category}
                        </Typography>

                        <Stack direction="row" alignItems="center" spacing={1}>
                          <img src={BookFlip} alt="" />
                          <Typography variant="body2" color="secondary.light">
                            {data.totalPage} Pages
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}
          </Stack>
        </Box> */}

        {/* Resume your course  */}
        {/* <Box sx={{ marginTop: "40px" }}>
          <Typography color="primary" variant="h3">
            Continue Reading
          </Typography>
          <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

          <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
            {category.map((data, index) => (
              <>
                <Card
                  key={index}
                  sx={{
                    minWidth: { xs: "100%", sm: 345 },
                    maxWidth: 345,
                    height: "222px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/education/books/${data.id}`)}
                >
                  <CardActionArea>
                    <CardMedia
                      sx={{
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        objectFit: "cover",
                      }}
                      image={data.image}
                    ></CardMedia>
                    <CardContent sx={{ background: "primary.light" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="primary.main"
                      >
                        {data.title}
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="caption"
                          color="secondary.light"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {data.category}
                        </Typography>

                        <Stack direction="row" alignItems="center" spacing={1}>
                          <img src={BookFlip} alt="" />{" "}
                          <Typography variant="body2" color="secondary.light">
                            {data.currentPage}/{data.totalPage} Pages
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}
          </Stack>
        </Box> */}
      </Box>
    </>
  );
}

export default Books;

const scrollableContainerStyles = {
  overflowX: "auto",
  pb: 1.5,
  // scrollbarWidth: "thin",
  // scrollbarColor: "transparent transparent",
  // "&::-webkit-scrollbar": {
  //   width: "6px",
  // },
  // "&::-webkit-scrollbar-track": {
  //   background: "transparent",
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   background: "transparent",
  //   borderRadius: "3px",
  // },
};
