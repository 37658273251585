import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Plane from "../../../assets/plane.png";

function PowerSection() {
	return (
		<>
			<Container maxWidth="lg" id="travel">
				<Box
					mt={{ xs: 10, md: 5 }}
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
						gap: { xs: 4, md: 5 },
						alignItems: "center",
					}}
				>
					<Stack
						spacing={2}
						sx={{
							order: {
								xs: 1,
								sm: 0,
							},
						}}
					>
						<Typography
							variant="h1"
							component="h2"
							color="primary"
							sx={{ fontWeight: 600, fontSize: { xs: "32px", sm: "60px" } }}
						>
							Power your journey abroad
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							sx={{ fontWeight: 500, fontSize: { xs: "20px", sm: "22px" } }}
						>
							Make your travel dreams a reality without the burden of financial
							constraints by exploring our revolutionary travel advance service.
						</Typography>

						<Button
							variant="outlined"
							type="button"
							sx={{ height: "50px", width: "178px" }}
						>
							Discover More
						</Button>
					</Stack>
					{/* Plane  */}

					<Box sx={{ position: "relative" }}>
						<Box
							sx={{
								background: "rgba(188, 212, 218, 0.50)",
								height: "100%",
								width: "100%",
								borderRadius: "50%",
								filter: "blur(71px)",
								position: "absolute",
								zIndex: -1,
							}}
						/>
						<img src={Plane} alt="plane image" style={{ zIndex: 1 }} />
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default PowerSection;
