import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AnyModal } from "../../finance/newTransaction/investor/create/InvestorCreateAF";
import {
	Box,
	Button,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import theme from "../../../components/mui/theme";
import userContext from "../../../context/UserContext";
import { useEffect } from "react";
import Parse from "../../../api/ApiConfig";
import baseURL from "../../../api/UrlConfig";
import axios from "axios";
import { useState } from "react";

function TransferDetails({ open, close, onClick }) {
	const { fundWallet, setFundWallet } = useContext(userContext);
	const [loadingDetails, setLoadingDetails] = useState(true);
	useEffect(() => {
		const currentUser = Parse.User.current();

		if (currentUser) {
			const session = currentUser.getSessionToken();

			const config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${baseURL}/functions/getAccountDetails`,
				headers: {
					// eslint-disable-next-line no-undef
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": session,
				},
			};

			axios
				.request(config)
				.then((response) => {
					// eslint-disable-next-line no-undef
					console.log(response.data.result);

					const result = response.data.result;

					setFundWallet((prevState) => ({
						...prevState,
						accountName: result.account_name,
						accountNumber: result.account_number,
					}));
					setLoadingDetails(false);
				})
				.catch((error) => {
					// eslint-disable-next-line no-undef
					console.log(error);
				});

			// eslint-disable-next-line no-undef
			console.log("Session token:", currentUser.getSessionToken());
		} else {
			// eslint-disable-next-line no-undef
			console.log("User is not logged in.");
		}
	}, []);

	return (
		<>
			<AnyModal open={open}>
				<Box>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography
							variant="h3"
							sx={{ fontFamily: "ZabalBold", fontSize: "15px" }}
							color="primary"
						>
							Transfer
						</Typography>
						<IconButton onClick={close}>
							<IoMdClose style={{ color: theme.palette.primary.main }} />
						</IconButton>
					</Stack>
					<Typography variant="caption" color="textSecondary">
						Kindly transfer into the account below then click on transfer done
						button to complete transaction.
					</Typography>

					<Box mt={2}>
						<Stack spacing={2}>
							{/* AMount  */}
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box minWidth="40%">
									<Typography color="textSecondary">
										Amount{" "}
										<Typography color="info.light" component="span">
											(NGN)
										</Typography>{" "}
										:
									</Typography>
								</Box>
								<Box width="100%">
									{loadingDetails ? (
										<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									) : (
										<Typography>{fundWallet.amount}</Typography>
									)}
								</Box>
							</Box>

							{/* Bank Name  */}
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box minWidth="40%">
									<Typography color="textSecondary">Bank Name :</Typography>
								</Box>
								<Box width="100%">
									{loadingDetails ? (
										<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									) : (
										<Typography>Providus Bank</Typography>
									)}
								</Box>
							</Box>

							{/* Account Number  */}
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box minWidth="40%">
									<Typography color="textSecondary">
										Account Number :
									</Typography>
								</Box>
								<Box width="100%">
									{loadingDetails ? (
										<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									) : (
										<Typography>{fundWallet.accountNumber}</Typography>
									)}
								</Box>
							</Box>

							{/* Account Name:  */}
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box minWidth="40%">
									<Typography color="textSecondary">Account Name :</Typography>
								</Box>
								<Box width="100%">
									{loadingDetails ? (
										<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									) : (
										<Typography>{fundWallet.accountName}</Typography>
									)}
								</Box>
							</Box>
						</Stack>

						<Box
							mt={2}
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
							width="100%"
						>
							<Stack direction="row" spacing={2}>
								<Button
									variant="outlined"
									onClick={close}
									type="button"
									sx={{ display: { xs: "none", sm: "flex" } }}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									type="button"
									onClick={onClick}
									sx={{ display: { xs: "none", sm: "flex" } }}
								>
									Transfer Done
								</Button>
							</Stack>
						</Box>

						{/* Mobile Buttons  */}
						<Box mt={2} width="100%" sx={{ display: { sm: "none" } }}>
							<Stack spacing={2}>
								<Button variant="contained" type="button" onClick={onClick}>
									Transfer Done
								</Button>
								<Button variant="outlined" onClick={close} type="button">
									Cancel
								</Button>
							</Stack>
						</Box>
					</Box>
				</Box>
			</AnyModal>
		</>
	);
}

TransferDetails.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onClick: PropTypes.func,
};
export default TransferDetails;
