import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import BankDetails from "./BankDetails";
import { closeWithdrawFundsModal } from "../../../redux/payments/paymentActions";
import WithdrawalAmount from "./WithdrawalAmount";
import ConfirmWithdrawal from "./ConfirmWithdrawal";
import { EnterPin } from "../../../components/payments/PinComponent";
import {
	CustomConfirmModal,
	SuccessCheckModal,
} from "../../../layout/CustomComponents";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../../components/ModalContainer";
import AddBankAccountModal from "../../../components/AddBankAccountModal";
import { closeAddBankAccountModal } from "../../../redux/authRedux/authActions";
import { walletType } from "../../../common/utils";
import Parse from "../../../api/ApiConfig";
import {
	fetchAllBanks,
	logoutUser,
} from "../../../redux/authRedux/authServices";
import { toast } from "react-toastify";
import { withdrawFundsFromWallet } from "../../../redux/payments/paymentServices";

const WithdrawFundsFlow = ({ activeWithdrawalTab, setActiveWithdrawalTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const transactionFee = 50;
	const VAT = 7;

	const totalFee = transactionFee + (VAT / 100) * 50;

	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const { currentUser, toggleAddBankAccountModal, fetchBanksSuccess } =
		useSelector((state) => state.auth);
	const { customerWallet, withdrawFundsLoading, withdrawFundsSuccess } =
		useSelector((state) => state.payment);

	const [inputPin, setInputPin] = useState("");
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [paymentDetails, setPaymentDetails] = useState({
		amount: "",
		description: "",
		accountNumber: currentUser?.accountNumber,
		accountName: currentUser?.accountName,
		bankName: currentUser?.bankName,
	});

	const handleChange = (e) =>
		setPaymentDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const accessPin = currentUser?.accessPin || "";

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	const { amount, description, accountName, accountNumber, bankName } =
		paymentDetails;

	const onCloseModal = () => {
		dispatch(closeWithdrawFundsModal());
	};

	const onProceedToProfile = () => {
		dispatch(closeWithdrawFundsModal());
		navigate("/dashboard/profile/complete-your-profile");
	};

	const addBankAccountDetails = (data) => {
		setPaymentDetails((prev) => ({
			...prev,
			accountName: data?.accountName,
			accountNumber: data?.accountNo,
			bankName: data?.bankName,
		}));
		dispatch(closeAddBankAccountModal());
	};

	const withdrawalAmountIncludingCharges =
		Number(amount?.replaceAll(",", "")) + totalFee;

	const onWithdrawFunds = () => {
		if (!Parse.User.current()?.id) {
			dispatch(logoutUser());
		}
		if (mainWallet?.availableBalance < withdrawalAmountIncludingCharges) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const details = {
			amount: Number(amount?.replaceAll(",", "")),
			accountNumber,
			bankUUID: fetchBanksSuccess?.find((bank) => bankName.includes(bank?.name))
				?.uuid,
		};

		dispatch(withdrawFundsFromWallet(details, currentSessionToken));
	};

	useEffect(() => {
		if (withdrawFundsSuccess) {
			setActiveWithdrawalTab(5);
		} else {
			return;
		}
	}, [withdrawFundsSuccess]);

	useEffect(() => {
		if (fetchBanksSuccess) {
			return;
		} else {
			dispatch(fetchAllBanks());
		}
	}, []);

	return (
		<>
			{toggleAddBankAccountModal && (
				<ModalContainer modal={toggleAddBankAccountModal}>
					<AddBankAccountModal
						onRequestClick={addBankAccountDetails}
						accountInfo={{ accountName, accountNumber, bankName }}
					/>
				</ModalContainer>
			)}
			{activeWithdrawalTab === 0 && (
				<CustomConfirmModal
					isNotify
					text="You need to complete your profile before you can withdraw funds from your account."
					greenBtnText="complete now ?"
					greenBtnClick={onProceedToProfile}
					onCloseBtnClick={() => dispatch(closeWithdrawFundsModal())}
				/>
			)}
			{activeWithdrawalTab === 1 && (
				<BankDetails
					setActiveWithdrawalTab={setActiveWithdrawalTab}
					onCloseModal={onCloseModal}
					accountName={accountName}
					accountNumber={accountNumber}
					bankName={bankName}
				/>
			)}
			{activeWithdrawalTab === 2 && (
				<WithdrawalAmount
					setActiveWithdrawalTab={setActiveWithdrawalTab}
					onCloseModal={onCloseModal}
					amount={amount}
					description={description}
					handleChange={handleChange}
				/>
			)}
			{activeWithdrawalTab === 3 && (
				<ConfirmWithdrawal
					setActiveWithdrawalTab={setActiveWithdrawalTab}
					onCloseModal={onCloseModal}
					amount={amount}
					description={description}
					accountName={accountName}
					bankName={bankName}
					accountNumber={accountNumber}
					totalFee={totalFee}
				/>
			)}
			{activeWithdrawalTab === 4 && (
				<EnterPin
					isPinExist={!!accessPin}
					isUpdateOldPin={true}
					btnText="proceed"
					onCloseBtnClick={() => setActiveWithdrawalTab((prev) => prev - 1)}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					isPaymentLoading={withdrawFundsLoading}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onWithdrawFunds}
				/>
			)}
			{activeWithdrawalTab === 5 && (
				<SuccessCheckModal
					text="Your withdrawal has been processed. The recipient account will be credited shortly."
					onRequestClick={() => window.location.reload()}
					isShowBtn
					btnText="Okay"
				/>
			)}
		</>
	);
};

export default WithdrawFundsFlow;

WithdrawFundsFlow.propTypes = {
	setActiveWithdrawalTab: PropTypes.func,
	activeWithdrawalTab: PropTypes.number,
};
