import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { ProfileInput } from "../../../profile/sections/PersonalInfo";
import {
	PlainSelect,
	SelectDropDown,
} from "../../../../layout/CustomComponents";
import { educationQualification } from "../../../../common/utils";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import { saveEducationInformation } from "../../../../redux/travels/travelServices";
import Parse from "../../../../api/ApiConfig";

const EduAndStudyInfo = () => {
	const dispatch = useDispatch();
	const { educationInfoLoading, educationInfoSuccess } = useSelector(
		(state) => state.travel
	);
	const [toggleQualification, setToggleQualification] = useState(null);
	const handleToggleQualification = (id) => {
		setToggleQualification((exp) => (exp === id ? null : id));
	};

	const [toggleDegree, setToggleDegree] = useState(null);
	const handleToggleDegree = (id) => {
		setToggleDegree((exp) => (exp === id ? null : id));
	};

	const firstEducation = {
		qualification: educationInfoSuccess?.highestQualification,
		name_of_institution: educationInfoSuccess?.institutionName,
		course_of_study: educationInfoSuccess?.courseOfStudy,
		degree_obtained: educationInfoSuccess?.degree,
		year_of_degree: educationInfoSuccess?.yearOfDegree,
	};

	const otherEducation = educationInfoSuccess?.otherEducations.map((data) => ({
		qualification: data?.highestQualification,
		name_of_institution: data?.institutionName,
		course_of_study: data?.courseOfStudy,
		degree_obtained: data?.degree,
		year_of_degree: data?.yearOfDegree,
	}));

	const [education, setEducation] = useState(
		educationInfoSuccess
			? [firstEducation, ...otherEducation]
			: [
					{
						qualification: "",
						name_of_institution: "",
						course_of_study: "",
						degree_obtained: "",
						year_of_degree: "",
					},
			  ]
	);

	const addEducation = () => {
		setEducation([
			...education,
			{
				qualification: "",
				name_of_institution: "",
				course_of_study: "",
				degree_obtained: "",
				year_of_degree: "",
			},
		]);
	};

	const removeEducation = (i) => {
		const formValues = [...education];
		formValues.splice(i, 1);
		setEducation(formValues);
	};

	const handleChange = (e, i) => {
		let formValues = [...education];

		formValues[i][e.target.id] = e.target.value;
		setEducation(formValues);
	};

	const qualifications = education.map((d) => d.qualification);
	const names_of_institutions = education.map((d) => d.name_of_institution);
	const courses_of_study = education.map((d) => d.course_of_study);
	const degrees_obtained = education.map((d) => d.degree_obtained);
	const year_of_degrees = education.map((d) => d.year_of_degree);

	const isBtnDisabled = () => {
		if (
			qualifications.includes("") ||
			names_of_institutions.includes("") ||
			courses_of_study.includes("") ||
			degrees_obtained.includes("") ||
			year_of_degrees.includes("")
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onSubmitEducationDetails = () => {
		const data = {
			userId: user,
			yearOfDegree: education[0].year_of_degree,
			institutionName: education[0].name_of_institution,
			highestQualification: education[0].qualification,
			degree: education[0].degree_obtained,
			courseOfStudy: education[0].course_of_study,
			otherEducations: [...education.slice(1)].map((data) => ({
				yearOfDegree: data.year_of_degree,
				institutionName: data.name_of_institution,
				highestQualification: data.qualification,
				degree: data.degree_obtained,
				courseOfStudy: data.course_of_study,
			})),
		};

		dispatch(saveEducationInformation(data));
	};

	return (
		<div className="bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF] space-y-3">
			<div className="space-y-4 md:space-y-5">
				{education.map((el, i) => (
					<div className="flex flex-col gap-2.5 md:gap-3" key={i}>
						<div className="flex items-center justify-between">
							<p className="text-[13px] md:text-sm text-fortcore-green chillaxFont font-semibold">
								Education {i + 1}
							</p>
							{i ? (
								<button
									className="text-red-600 pr-0.5"
									onClick={() => removeEducation(i)}
								>
									<MdDelete size={18} />
								</button>
							) : null}
						</div>
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont text-[#393F42]">
								Highest Qualification:
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="select highest qualification"
									isOpened={toggleQualification === i}
									currentText={el.qualification}
									handleToggle={() => handleToggleQualification(i)}
								/>
								{toggleQualification === i && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={educationQualification.map(
												(a) => a.schoolLevels
											)}
											onHandleSelect={(d) => {
												let formValues = [...education];
												formValues[i].qualification = d;
												setEducation(formValues);
												handleToggleQualification(i);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<ProfileInput
							type="text"
							id="name_of_institution"
							text="Name of Institution"
							value={el.name_of_institution}
							placeholderText="Name of institution"
							handleChange={(e) => handleChange(e, i)}
						/>
						<ProfileInput
							type="text"
							id="course_of_study"
							text="Course of Study"
							value={el.course_of_study}
							placeholderText="Course of Study"
							handleChange={(e) => handleChange(e, i)}
						/>
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont text-[#393F42]">
								Degree Obtained:
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="choose degree obtained"
									isOpened={toggleDegree === i}
									currentText={el.degree_obtained}
									handleToggle={() => {
										if (el.qualification === "") {
											toast.error("Please choose a qualification");
											return;
										}
										handleToggleDegree(i);
									}}
								/>
								{toggleDegree === i && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={
												educationQualification?.find(
													(d) => d.schoolLevels === el.qualification
												)?.degrees
											}
											onHandleSelect={(d) => {
												let formValues = [...education];
												formValues[i].degree_obtained = d;
												setEducation(formValues);
												handleToggleDegree(i);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<ProfileInput
							type="month"
							text="Year of Degree"
							id="year_of_degree"
							value={el.year_of_degree}
							placeholderText="Year of degree"
							handleChange={(e) => handleChange(e, i)}
						/>
					</div>
				))}
			</div>
			{education?.length < 3 && (
				<div>
					<button
						className="text-[13px] md:text-[13.5px] zabalMedFont font-semibold text-fortcore-green"
						onClick={addEducation}
					>
						Add Education
					</button>
				</div>
			)}
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled()}
					isLoading={educationInfoLoading || educationInfoLoading}
					onRequestClick={onSubmitEducationDetails}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled()}
					isLoading={educationInfoLoading || educationInfoLoading}
					onRequestClick={onSubmitEducationDetails}
				/>
			</div>
		</div>
	);
};

export default EduAndStudyInfo;
