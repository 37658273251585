export const OPEN_ADD_FUNDS_MODAL = "OPEN_ADD_FUNDS_MODAL";
export const CLOSE_ADD_FUNDS_MODAL = "CLOSE_ADD_FUNDS_MODAL";
export const GET_PAYMENT_ACCOUNT_LOADING = "GET_PAYMENT_BANK_ACCOUNT";
export const GET_PAYMENT_ACCOUNT_SUCCESS = "GET_PAYMENT_BANK_SUCCESS";
export const GET_PAYMENT_ACCOUNT_ERROR = "GET_PAYMENT_BANK_ERROR";
export const FETCH_CUSTOMER_WALLET_LOADING = "FETCH_CUSTOMER_WALLET_LOADING";
export const FETCH_CUSTOMER_WALLET_SUCCESS = "FETCH_CUSTOMER_WALLET_SUCCESS";
export const FETCH_CUSTOMER_WALLET_ERROR = "FETCH_CUSTOMER_WALLET_ERROR";
export const OPEN_WITHDRAW_FUNDS_MODAL = "OPEN_WITHDRAW_FUNDS_MODAL";
export const CLOSE_WITHDRAW_FUNDS_MODAL = "CLOSE_WITHDRAW_FUNDS_MODAL";
export const OPEN_MOVE_FUNDS_MODAL = "OPEN_MOVE_FUNDS_MODAL";
export const CLOSE_MOVE_FUNDS_MODAL = "CLOSE_MOVE_FUNDS_MODAL";
export const DEDUCT_PAYMENT_FROM_WALLET_LOADING =
	"DEDUCT_PAYMENT_FROM_WALLET_LOADING";
export const DEDUCT_PAYMENT_FROM_WALLET_SUCCESS =
	"DEDUCT_PAYMENT_FROM_WALLET_SUCCESS";
export const DEDUCT_PAYMENT_FROM_WALLET_ERROR =
	"DEDUCT_PAYMENT_FROM_WALLET_ERROR";
export const CONFIRM_PROVIDUS_DEPOSIT_LOADING =
	"CONFIRM_PROVIDUS_DEPOSIT_LOADING";
export const CONFIRM_PROVIDUS_DEPOSIT_SUCCESS =
	"CONFIRM_PROVIDUS_DEPOSIT_SUCCESS";
export const CONFIRM_PROVIDUS_DEPOSIT_ERROR = "CONFIRM_PROVIDUS_DEPOSIT_ERROR";
export const CLEAR_PAYMENT_DEDUCTION_SUCCESS =
	"CLEAR_PAYMENT_DEDUCTION_SUCCESS";
export const MOVE_FUNDS_LOADING = "MOVE_FUNDS_LOADING";
export const MOVE_FUNDS_SUCCESS = "MOVE_FUNDS_SUCCESS";
export const MOVE_FUNDS_ERROR = "MOVE_FUNDS_ERROR";
export const CLEAR_MOVE_FUNDS_SUCCESS = "CLEAR_MOVE_FUNDS_SUCCESS";
export const CONFIRM_PAGA_DEPOSIT_LOADING = "CONFIRM_PAGA_DEPOSIT_LOADING";
export const CONFIRM_PAGA_DEPOSIT_SUCCESS = "CONFIRM_PAGA_DEPOSIT_SUCCESS";
export const CONFIRM_PAGA_DEPOSIT_ERROR = "CONFIRM_PAGA_DEPOSIT_ERROR";
export const WITHDRAW_FROM_WALLET_LOADING = "WITHDRAW_FROM_WALLET_LOADING";
export const WITHDRAW_FROM_WALLET_SUCCESS = "WITHDRAW_FROM_WALLET_SUCCESS";
export const WITHDRAW_FROM_WALLET_ERROR = "WITHDRAW_FROM_WALLET_ERROR";
export const GET_LOGISTICS_ACCOUNT_LOADING = "GET_LOGISTICS_ACCOUNT_LOADING";
export const GET_LOGISTICS_ACCOUNT_SUCCESS = "GET_LOGISTICS_ACCOUNT_SUCCESS";
export const GET_LOGISTICS_ACCOUNT_ERROR = "GET_LOGISTICS_ACCOUNT_ERROR";
export const TOGGLE_UPCOMING_REPAYMENT = "TOGGLE_UPCOMING_REPAYMENT";
