import {
	CLEAR_MOVE_FUNDS_SUCCESS,
	CLEAR_PAYMENT_DEDUCTION_SUCCESS,
	CLOSE_ADD_FUNDS_MODAL,
	CLOSE_MOVE_FUNDS_MODAL,
	CLOSE_WITHDRAW_FUNDS_MODAL,
	CONFIRM_PAGA_DEPOSIT_ERROR,
	CONFIRM_PAGA_DEPOSIT_LOADING,
	CONFIRM_PAGA_DEPOSIT_SUCCESS,
	CONFIRM_PROVIDUS_DEPOSIT_ERROR,
	CONFIRM_PROVIDUS_DEPOSIT_LOADING,
	CONFIRM_PROVIDUS_DEPOSIT_SUCCESS,
	DEDUCT_PAYMENT_FROM_WALLET_ERROR,
	DEDUCT_PAYMENT_FROM_WALLET_LOADING,
	DEDUCT_PAYMENT_FROM_WALLET_SUCCESS,
	FETCH_CUSTOMER_WALLET_ERROR,
	FETCH_CUSTOMER_WALLET_LOADING,
	FETCH_CUSTOMER_WALLET_SUCCESS,
	GET_LOGISTICS_ACCOUNT_ERROR,
	GET_LOGISTICS_ACCOUNT_LOADING,
	GET_LOGISTICS_ACCOUNT_SUCCESS,
	GET_PAYMENT_ACCOUNT_ERROR,
	GET_PAYMENT_ACCOUNT_LOADING,
	GET_PAYMENT_ACCOUNT_SUCCESS,
	MOVE_FUNDS_ERROR,
	MOVE_FUNDS_LOADING,
	MOVE_FUNDS_SUCCESS,
	OPEN_ADD_FUNDS_MODAL,
	OPEN_MOVE_FUNDS_MODAL,
	OPEN_WITHDRAW_FUNDS_MODAL,
	TOGGLE_UPCOMING_REPAYMENT,
	WITHDRAW_FROM_WALLET_ERROR,
	WITHDRAW_FROM_WALLET_LOADING,
	WITHDRAW_FROM_WALLET_SUCCESS,
} from "./paymentTypes";

export const fetchCustomerWalletLoading = () => {
	return { type: FETCH_CUSTOMER_WALLET_LOADING };
};
export const fetchCustomerWalletSuccess = (data) => {
	return { type: FETCH_CUSTOMER_WALLET_SUCCESS, payload: data };
};
export const fetchCustomerWalletError = () => {
	return { type: FETCH_CUSTOMER_WALLET_ERROR };
};
export const openAddFundsModal = () => {
	return { type: OPEN_ADD_FUNDS_MODAL };
};
export const closeAddFundsModal = () => {
	return { type: CLOSE_ADD_FUNDS_MODAL };
};
export const getPaymentAccountLoading = () => {
	return { type: GET_PAYMENT_ACCOUNT_LOADING };
};
export const getPaymentAccountSuccess = (data) => {
	return { type: GET_PAYMENT_ACCOUNT_SUCCESS, payload: data };
};
export const getPaymentAccountError = () => {
	return { type: GET_PAYMENT_ACCOUNT_ERROR };
};
export const openWithdrawFundsModal = () => {
	return { type: OPEN_WITHDRAW_FUNDS_MODAL };
};
export const closeWithdrawFundsModal = () => {
	return { type: CLOSE_WITHDRAW_FUNDS_MODAL };
};
export const openMoveFundsModal = () => {
	return { type: OPEN_MOVE_FUNDS_MODAL };
};
export const closeMoveFundsModal = () => {
	return { type: CLOSE_MOVE_FUNDS_MODAL };
};
export const deductPaymentFromWalletLoading = () => {
	return { type: DEDUCT_PAYMENT_FROM_WALLET_LOADING };
};
export const deductPaymentFromWalletSuccess = (data) => {
	return { type: DEDUCT_PAYMENT_FROM_WALLET_SUCCESS, payload: data };
};
export const deductPaymentFromWalletError = () => {
	return { type: DEDUCT_PAYMENT_FROM_WALLET_ERROR };
};
export const confirmProvidusDepositLoading = () => {
	return { type: CONFIRM_PROVIDUS_DEPOSIT_LOADING };
};
export const confirmProvidusDepositSuccess = (data) => {
	return { type: CONFIRM_PROVIDUS_DEPOSIT_SUCCESS, payload: data };
};
export const confirmProvidusDepositError = (errorMsg) => {
	return { type: CONFIRM_PROVIDUS_DEPOSIT_ERROR, error: errorMsg };
};
export const clearPaymentDeductionSuccess = () => {
	return { type: CLEAR_PAYMENT_DEDUCTION_SUCCESS };
};
export const moveUserFundsLoading = () => {
	return { type: MOVE_FUNDS_LOADING };
};
export const moveUserFundsSuccess = (data) => {
	return { type: MOVE_FUNDS_SUCCESS, payload: data };
};
export const moveUserFundsError = () => {
	return { type: MOVE_FUNDS_ERROR };
};
export const clearMoveFundsSuccess = () => {
	return { type: CLEAR_MOVE_FUNDS_SUCCESS };
};
export const confirmPagaDepositLoading = () => {
	return { type: CONFIRM_PAGA_DEPOSIT_LOADING };
};
export const confirmPagaDepositSuccess = (data) => {
	return { type: CONFIRM_PAGA_DEPOSIT_SUCCESS, payload: data };
};
export const confirmPagaDepositError = (errorMsg) => {
	return { type: CONFIRM_PAGA_DEPOSIT_ERROR, error: errorMsg };
};
export const withdrawFromWalletLoading = () => {
	return { type: WITHDRAW_FROM_WALLET_LOADING };
};
export const withdrawFromWalletSuccess = (data) => {
	return { type: WITHDRAW_FROM_WALLET_SUCCESS, payload: data };
};
export const withdrawFromWalletError = () => {
	return { type: WITHDRAW_FROM_WALLET_ERROR };
};
export const getLogisticsAccountLoading = () => {
	return { type: GET_LOGISTICS_ACCOUNT_LOADING };
};
export const getLogisticsAccountSuccess = (data) => {
	return { type: GET_LOGISTICS_ACCOUNT_SUCCESS, payload: data };
};
export const getLogisticsAccountError = () => {
	return { type: GET_LOGISTICS_ACCOUNT_ERROR };
};
export const onToggleUpcomingPayments = (state, id = null) => {
	return { type: TOGGLE_UPCOMING_REPAYMENT, payload: { state, id } };
};
