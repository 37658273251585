import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useTheme } from "@mui/material/styles";

export const CustomCardDropDown = ({
	text,
	dropDown,
	setDropDown,
	buttonText1,
	buttonText2,
	onClick1,
	onClick2,
	dropClose,
}) => {
	const theme = useTheme();
	return (
		<>
			{!dropDown ? (
				<Button
					sx={{
						height: "120px",
						display: "flex",
						justifyContent: "space-between",
						padding: "0 20px",
						alignItems: "center",
						borderRadius: "8px",
						border: "1px solid var(--grey-100, #EEF2F3)",
						background:
							"linear-gradient(0deg, #EFF8F9 0%, rgba(239, 248, 249, 0.00) 100%)",
					}}
					onClick={() => setDropDown(true)}
				>
					<Typography variant="h5" color="primary.main">
						{text}
					</Typography>
					<IconButton>
						<MdOutlineKeyboardArrowDown
							style={{ color: theme.palette.primary.main }}
						/>
					</IconButton>
				</Button>
			) : (
				<Box
					sx={{
						border: "1px solid var(--grey-100, #EEF2F3)",
						background: theme.palette.secondary.main,
						borderRadius: "12px",
						boxShadow: "0px 4px 16px 0px #EEF2F3",
						padding: "20px",
					}}
				>
					<Box
						sx={{
							height: "100px",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							borderBottom: "1px solid var(--grey-100, #EEF2F3)",
							cursor: "pointer",
						}}
						onClick={dropClose}
					>
						<Typography variant="h5" color="primary.main">
							{text}
						</Typography>
						<IconButton onClick={dropClose}>
							<MdOutlineKeyboardArrowUp
								style={{ color: theme.palette.primary.main }}
							/>
						</IconButton>
					</Box>

					{/* Buttons  */}

					<Box pt={4}>
						<Stack spacing={2}>
							<Button
								variant="text"
								sx={{
									color: theme.palette.info.light,
									display: "inline-flex",
									justifyContent: "flex-start",
									borderRadius: "8px",
									border: "1px solid var(--secondary-color-200, #AABDC5)",
									height: "66px",

									"&:hover": {
										background: "transparent",
									},
								}}
								onClick={onClick1}
							>
								{buttonText1}
							</Button>

							<Button
								variant="text"
								sx={{
									color: theme.palette.info.light,
									display: "inline-flex",
									justifyContent: "flex-start",
									borderRadius: "8px",
									border: "1px solid var(--secondary-color-200, #AABDC5)",
									height: "66px",

									"&:hover": {
										background: "transparent",
									},
								}}
								onClick={onClick2}
							>
								{buttonText2}
							</Button>
						</Stack>
					</Box>
				</Box>
			)}
		</>
	);
};

CustomCardDropDown.propTypes = {
	text: PropTypes.node.isRequired,
	buttonText1: PropTypes.node.isRequired,
	buttonText2: PropTypes.node.isRequired,
	dropDown: PropTypes.bool.isRequired,
	setDropDown: PropTypes.func.isRequired,
	onClick1: PropTypes.func.isRequired,
	onClick2: PropTypes.func.isRequired,
	dropClose: PropTypes.func.isRequired,
};

export const CustomTwoGridBox = ({ children }) => {
	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" },
				rowGap: "20px",
				columnGap: "20px",
			}}
		>
			{children}
		</Box>
	);
};

CustomTwoGridBox.propTypes = {
	children: PropTypes.node.isRequired,
};
export const CustomFourGridBox = ({ children }) => {
	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateColumns: {
					xs: "1fr",
					sm: "1fr 1fr",
					md: "1fr 1fr 1fr",
					lg: "1fr 1fr 1fr 1fr",
				},
				rowGap: "20px",
				columnGap: "20px",
			}}
		>
			{children}
		</Box>
	);
};

CustomFourGridBox.propTypes = {
	children: PropTypes.node.isRequired,
};
