import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import NotifyUser from "../../../layout/NotifyUser";
import {
	generateAccountDetails,
	userConfirmPagaDeposit,
	userConfirmProvidusDeposit,
} from "../../../redux/payments/paymentServices";

const Account = ({
	onCloseModal,
	setActivePaymentTab,
	amountToDeposit,
	paymentChannel,
}) => {
	const dispatch = useDispatch();

	const { paymentAccountLoading, paymentAccountSuccess } = useSelector(
		(state) => state.payment
	);
	console.log({
		amount: amountToDeposit,
		accountNumber: paymentAccountSuccess?.paga?.accountNumber,
	});
	const onTransferComplete = () => {
		console.log(paymentChannel);
		setActivePaymentTab(4);
		if (paymentChannel === "providus-bank-transfer") {
			console.log("providus");
			setTimeout(() => {
				console.log("providus-late");
				console.log(2);
				dispatch(
					userConfirmProvidusDeposit({
						accountNumber: paymentAccountSuccess?.providus?.account_number,
					})
				);
			}, 4.8 * 60 * 1000);
		} else if (paymentChannel === "paga-bank-transfer") {
			console.log("paga");
			setTimeout(() => {
				console.log("paga-late");
				dispatch(
					userConfirmPagaDeposit({
						amount: amountToDeposit,
						accountNumber: paymentAccountSuccess?.paga?.accountNumber,
					})
				);
			}, 2 * 60 * 1000);
		}
	};

	useEffect(() => {
		if (paymentAccountSuccess) {
			return;
		} else {
			dispatch(generateAccountDetails());
		}
	}, []);

	return (
		<NotifyUser
			header="Transfer"
			title="Kindly transfer into the account below, then click on the completed button to complete transaction. A mail will be sent on confirmation."
			btnText="completed"
			onCloseBtnClick={onCloseModal}
			onWhiteBtnClick={() => setActivePaymentTab(3)}
			onGreenBtnClick={onTransferComplete}
			isGreenBtnDisabled={paymentAccountLoading}
		>
			<>
				{paymentChannel === "paga-bank-transfer" && (
					<AccountDetails
						amountToDeposit={amountToDeposit}
						accountNumber={paymentAccountSuccess?.paga?.accountNumber || "N/A"}
						accountName={
							`Fortcore Limited - ${paymentAccountSuccess?.paga?.accountName}` ||
							"N/A"
						}
						bankName="Paga"
					/>
				)}
				{paymentChannel === "providus-bank-transfer" && (
					<AccountDetails
						amountToDeposit={amountToDeposit}
						accountNumber={
							paymentAccountSuccess?.providus?.account_number || "N/A"
						}
						accountName={paymentAccountSuccess?.providus?.account_name || "N/A"}
						bankName="Providus"
					/>
				)}
			</>
		</NotifyUser>
	);
};

Account.propTypes = {
	onCloseModal: PropTypes.func,
	setActivePaymentTab: PropTypes.func,
	amountToDeposit: PropTypes.string,
	paymentChannel: PropTypes.string,
};

export default Account;

const AccountDetails = ({
	amountToDeposit,
	accountName,
	accountNumber,
	bankName,
}) => {
	const { paymentAccountLoading } = useSelector((state) => state.payment);

	return (
		<div className="space-y-3 text-[12.5px] sm:text-[13px]">
			<div className="flex gap-5 items-center">
				<p className="text-[#717E83] w-[130px] zabalRegFont">Amount (NGN):</p>
				<p className="text-[#393F42] zabalMedFont">{amountToDeposit}</p>
			</div>
			<div className="flex gap-5 items-center">
				<p className="text-[#717E83] w-[130px] zabalRegFont">Bank Name:</p>
				<p className="text-[#393F42] zabalMedFont">
					{paymentAccountLoading ? (
						<Skeleton className="!w-[100px] sm:!w-[120px] h-[21px]" />
					) : (
						bankName
					)}
				</p>
			</div>
			<div className="flex gap-5 items-center">
				<p className="text-[#717E83] w-[130px] zabalRegFont">Account Number:</p>
				<p className="text-[#393F42] zabalMedFont">
					{paymentAccountLoading ? (
						<Skeleton className="!w-[100px] sm:!w-[120px] h-[21px]" />
					) : (
						accountNumber
					)}
				</p>
			</div>
			<div className="flex gap-5 items-center">
				<p className="text-[#717E83] w-[130px] zabalRegFont">Account Name:</p>
				<p className="text-[#393F42] zabalMedFont">
					{paymentAccountLoading ? (
						<Skeleton className="!w-[100px] sm:!w-[120px] h-[21px]" />
					) : (
						accountName
					)}
				</p>
			</div>
		</div>
	);
};

AccountDetails.propTypes = {
	accountName: PropTypes.string,
	accountNumber: PropTypes.string,
	amountToDeposit: PropTypes.string,
	bankName: PropTypes.string,
};
