import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import { UrlTracker } from "../../../layout/CustomComponents";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import RequestLeaseForm from "./RequestLeaseForm";
import RequestLeaseItems from "./RequestLeaseItems";
import RequestLeaseStructure from "./RequestLeaseStructure";
// import RequestLeaseSummary from "./PersonalRequestLeaseSummary";
import PersonalRequestLeaseSummary from "./PersonalRequestLeaseSummary";
import BusinessRequestLeaseSummary from "./BusinessRequestLeaseSummary";
import { useSelector } from "react-redux";

const LeaseRequest = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { currentProfile } = useSelector((state) => state.lease);
	const [activeTab, setActiveTab] = useState(state?.currentTab || 1);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [activeTab]);

	if (!currentProfile) {
		return <Navigate to="/leasing/new-transaction" />;
	}

	return (
		<>
			<Helmet>
				<title>Fortcore - Request Lease</title>
			</Helmet>
			<MobileNavDetails text="request lease" />
			<Container>
				<>
					<Navbar text="new transaction" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="new transaction"
							onNavigate={() => navigate("/leasing/new-transaction")}
						/>
						<UrlTracker text="request lease" isCurrentUrl />
					</div>
					<div className="mb-10 sm:my-6">
						{activeTab === 1 && (
							<RequestLeaseForm setActiveTab={setActiveTab} />
						)}
						{activeTab === 2 && (
							<RequestLeaseItems setActiveTab={setActiveTab} />
						)}
						{activeTab === 3 && (
							<RequestLeaseStructure setActiveTab={setActiveTab} />
						)}
						{activeTab === 4 && currentProfile === "personal" && (
							<PersonalRequestLeaseSummary setActiveTab={setActiveTab} />
						)}
						{activeTab === 4 && currentProfile === "business" && (
							<BusinessRequestLeaseSummary setActiveTab={setActiveTab} />
						)}
					</div>
				</>
			</Container>
		</>
	);
};
export default LeaseRequest;
