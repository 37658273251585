import React, { useEffect, useState } from "react";
import { MdMenu, MdKeyboardArrowDown } from "react-icons/md";
import { ProfileDropDown, ProfilePercentage } from "./Navbar";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
	closeMobileMenuModal,
	openLogoutModal,
	openMobileMenuModal,
} from "../redux/authRedux/authActions";
import { FaTimes } from "react-icons/fa";
import Notifications from "../components/notification/Notifications";
import { useNavigate } from "react-router-dom";

const MobileNavbar = () => {
	const { currentUser } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [toggleModal, setToggleModal] = useState(false);
	useEffect(() => {
		if (toggleModal) {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "hidden";
		}
		return () => {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "auto";
		};
	}, [toggleModal]);

	const openLogout = () => {
		setToggleModal(false);
		dispatch(openLogoutModal());
	};

	const onProfileClick = () => {
		setToggleModal(false);
		navigate("/dashboard/profile");
	};

	return (
		<>
			{toggleModal && (
				<div
					className="fixed z-[13] inset-0 sm:hidden"
					onClick={() => setToggleModal(false)}
				>
					<div className="relative bg-[#393F4266] w-full h-full px-4 flex justify-end items-start">
						<ProfileDropDown
							onLogoutClick={openLogout}
							onProceedToProfile={onProfileClick}
						/>
					</div>
				</div>
			)}
			<nav className="fixed w-full top-0 left-0 z-[10] bg-white h-[60px] px-4 flex items-center justify-between zabalRegFont sm:hidden">
				<button onClick={() => dispatch(openMobileMenuModal())}>
					<MdMenu color="#00525D" size={30} />
				</button>
				<div className="flex gap-1.5 items-center">
					{/* <BellIcon width={25} height={25} /> */}
					<Notifications />
					<button
						className="flex items-center gap-0.5"
						onClick={() => setToggleModal(true)}
					>
						<div className="text-[11.5px] uppercase zabalBoldFont bg-[#BDBDBD66] text-fortcore-green rounded-full h-[27px] w-[27px] grid place-items-center p-1">
							<p className="h-[16px]">
								{currentUser?.firstName?.charAt(0)}
								{currentUser?.lastName?.charAt(0)}
							</p>
						</div>
						<span>
							<MdKeyboardArrowDown color="#00525D" size={20} />
						</span>
					</button>
				</div>
			</nav>
		</>
	);
};
export default MobileNavbar;

export const MobileNavSideBar = () => {
	const { toggleMobileMenuModal } = useSelector((state) => state.auth);

	useEffect(() => {
		if (toggleMobileMenuModal) {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "hidden";
		}
		return () => {
			// eslint-disable-next-line no-undef
			document.body.style.overflow = "auto";
		};
	}, [toggleMobileMenuModal]);

	const dispatch = useDispatch();
	return (
		<div
			className="fixed inset-0 bg-[#393F4266] backdrop-blur-[2px] z-[12] sm:hidden h-[lvh]"
			onClick={() => dispatch(closeMobileMenuModal())}
		>
			<div
				className="w-[65%] bg-fortcore-green max-w-[300px] h-full"
				onClick={(e) => e.stopPropagation()}
			>
				<Sidebar />
			</div>
			<button
				className="absolute top-2 right-3 rounded-full h-[42px] w-[42px] bg-fortcore-green flex items-center justify-center"
				onClick={() => dispatch(closeMobileMenuModal())}
			>
				<FaTimes color="#ffffff" size={20} />
			</button>
		</div>
	);
};

export const MobileNavDetails = ({ text }) => {
	const navigate = useNavigate();

	const onCompleteProfileClick = () => {
		navigate("/dashboard/profile/complete-your-profile");
	};

	return (
		<div className="sm:hidden">
			<div className="h-[60px]"></div>
			<div className="bg-[#EFF8F9] h-[60px] px-4 flex items-center justify-between gap-2.5">
				<p className="capitalize font-semibold chillaxFont">{text}</p>
				<ProfilePercentage onCompleteProfileClick={onCompleteProfileClick} />
			</div>
		</div>
	);
};

MobileNavDetails.propTypes = {
	text: PropTypes.string,
};

ProfileDropDown.propTypes = {
	onLogoutClick: PropTypes.func,
	onProceedToProfile: PropTypes.func,
};
ProfilePercentage.propTypes = {
	onCompleteProfileClick: PropTypes.func,
};
