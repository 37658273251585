import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdLock } from "react-icons/md";
import PropTypes from "prop-types";
import moment from "moment";
import {
	BackTracker,
	CustomRadioInput,
	PlainSelect,
	SelectDropDown,
	SuccessCheckModal,
} from "../../../layout/CustomComponents";
import {
	LeaseSummaryInput,
	LeaseSummaryItemDetails,
} from "../requestlease/LeaseSummary";
import { CustomGreenBtn, DayButton } from "../../../layout/CustomButtons";
import ModalContainer from "../../../components/ModalContainer";
import { isCreateAssetSavingsSuccess } from "../../../redux/lease/leaseActions";
import { createAssetBasedSavings } from "../../../redux/lease/leaseServices";
import { deliveryTypeArr } from "../requestlease/LeaseStructure";
import Parse from "../../../api/ApiConfig";
import {
	formatAmountToIncludeCurrency,
	getMaturityDate,
	getProductsObjects,
} from "../../../common/utils";
import { logoutUser } from "../../../redux/authRedux/authServices";

const AssetSavingsSummary = ({ setActiveTab }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		leaseFormData,
		leaseStructureData,
		selectedReqLeaseItem,
		createAssetBasedSavingsLoading,
		createAssetBasedSavingsSuccess,
	} = useSelector((state) => state.lease);

	const [summaryData, setSummaryData] = useState({
		frequency: "",
		day_of_the_month: "",
	});
	const { frequency, day_of_the_month } = summaryData;
	const [toggleFrequency, setToggleFrequency] = useState(false);
	const [calendar, setCalendar] = useState({
		toggleCalendar: false,
		currentDay: null,
	});
	const { toggleCalendar, currentDay } = calendar;

	const onChooseDay = (day) => {
		setCalendar((prev) => ({ ...prev, currentDay: day }));
	};

	const onSelectDay = () => {
		setSummaryData((prev) => ({ ...prev, day_of_the_month: currentDay }));
		setCalendar((prev) => ({ ...prev, toggleCalendar: false }));
	};

	useEffect(() => {
		if (toggleCalendar) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [toggleCalendar]);

	const maturityDate = getMaturityDate(leaseStructureData?.saving_tenor);

	const interestAmount =
		(Number(leaseStructureData?.interest) / 100) *
		Number(leaseStructureData?.amount_to_invest.replaceAll(",", ""));

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const Product = Parse.Object.extend("Products");
	const product = new Product();
	product.id = selectedReqLeaseItem?.objectId;

	const productObj = getProductsObjects(selectedReqLeaseItem?.productObj);

	const isProceedBtnValid = () => {
		if (frequency === "") {
			return true;
		}
		if (frequency === "Monthly" && day_of_the_month === "") {
			return true;
		} else {
			return false;
		}
	};

	const data = {
		userId: user,
		productToSaveFor: product,
		interestRate: leaseStructureData?.interest,
		tenure: leaseStructureData?.saving_tenor,
		deliveryType: deliveryTypeArr.find(
			(a) => a.label === leaseStructureData?.delivery_type
		)?.value,
		leaseStructure: leaseStructureData?.lease_structure,
		leaseDuration: leaseStructureData?.lease_duration,
		savingsType: "asset based",
		frequency,
		maturityDate,
		dayOfTheMonth: frequency === "Monthly" ? day_of_the_month : "",
		amountToStartWith: leaseStructureData?.amount_to_invest,
		amountToSave: selectedReqLeaseItem?.amount,
		amountSaved: "0",
		productObj,
	};

	const createAsset = () => {
		if (user.id) {
			dispatch(createAssetBasedSavings(data));
		} else {
			dispatch(logoutUser());
		}
	};

	const onAssetCreateSuccess = () => {
		navigate("/leasing/asset-based-savings/view");
		setTimeout(() => {
			dispatch(isCreateAssetSavingsSuccess());
		}, 300);
	};

	return (
		<>
			{createAssetBasedSavingsSuccess && (
				<ModalContainer modal={!!createAssetBasedSavingsSuccess}>
					<SuccessCheckModal
						text="Asset savings has been successfully created."
						isShowBtn={true}
						onRequestClick={onAssetCreateSuccess}
					/>
				</ModalContainer>
			)}
			{toggleCalendar && (
				<div
					className="fixed z-[13] inset-0 bg-[#393F4266] grid place-items-center"
					onClick={() => setCalendar(false)}
				>
					<DayButton
						onCancelBtnClick={() =>
							setCalendar((prev) => ({ ...prev, toggleCalendar: false }))
						}
						onChooseDay={onChooseDay}
						currentDay={currentDay}
						onOkBtnClick={onSelectDay}
					/>
				</div>
			)}
			<BackTracker text="Lease Summary" onNavigate={() => setActiveTab(3)} />
			<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
				<div className="hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
					<button className="zabalMedFont" onClick={() => setActiveTab(3)}>
						<FaArrowLeft />
					</button>
					<p>Lease Summary</p>
				</div>
				<LeaseSummaryItemDetails
					item={selectedReqLeaseItem}
					interest={Number(leaseStructureData?.interest).toFixed(1)}
					interestAmount={interestAmount}
				/>
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseSummaryInput
						input_value={leaseFormData?.lease_type}
						text="lease type"
					/>
					<LeaseSummaryInput
						input_value={selectedReqLeaseItem?.category}
						text="product category"
					/>
					<LeaseSummaryInput
						input_value={selectedReqLeaseItem?.brand}
						text="brand"
					/>
					<LeaseSummaryInput
						input_value={selectedReqLeaseItem?.model}
						text="model"
					/>
					<LeaseSummaryInput
						input_value={selectedReqLeaseItem?.year}
						text="year"
					/>
					<LeaseSummaryInput
						input_value={selectedReqLeaseItem?.condition}
						text="condition"
					/>
					<LeaseSummaryInput
						input_value={selectedReqLeaseItem?.color}
						text="color"
					/>
					<LeaseSummaryInput
						input_value={leaseStructureData?.lease_duration}
						text="lease duration"
					/>
					<LeaseSummaryInput
						input_value={leaseStructureData?.lease_structure}
						text="lease structure"
					/>
					<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
							Interest Rate:
						</p>
						<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
							<div className="flex items-center justify-between w-full">
								<p>
									<span className="text-[#393F42] zabalMedFont">NGN </span>
									<span>
										{formatAmountToIncludeCurrency(undefined, interestAmount)}
									</span>
								</p>
								<p className="text-fortcore-green zabalMedFont">{`( ${leaseStructureData?.interest}% P/A)`}</p>
							</div>
							<span className="h-full grid place-items-center">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>
					<LeaseSummaryInput
						input_value={leaseStructureData?.saving_tenor}
						text="savings tenor"
					/>
					<LeaseSummaryInput
						input_value={moment(maturityDate).format("MMMM Do YYYY")}
						text="maturity date"
					/>
					<LeaseSummaryInput
						input_value={`NGN ${leaseStructureData?.amount_to_invest}`}
						text="amount to save"
					/>
					<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12">
							Savings Frequency :
						</p>
						<div className="w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative">
							<PlainSelect
								context="choose saving frequency"
								isOpened={toggleFrequency}
								currentText={frequency}
								handleToggle={() => setToggleFrequency((prev) => !prev)}
							/>
							{toggleFrequency && (
								<div className="absolute z-[2] w-full top-48px mb-10">
									<SelectDropDown
										arrOptions={["Daily", "Weekly", "Bi-Weekly", "Monthly"]}
										onHandleSelect={(d) => {
											setSummaryData((prev) => ({ ...prev, frequency: d }));
											setTimeout(() => {
												setToggleFrequency(false);
											}, 100);
										}}
									/>
								</div>
							)}
						</div>
					</div>
					{frequency === "Monthly" && (
						<div className="flex flex-col sm:items-center sm:flex-row gap-2 sm:gap-5 md:gap-6 text-xs md:text-[13px]">
							<p className="text-[#393F42] text-xs md:text-[13px] break-normal zabalMedFont  sm:w-[33%] md:w-2/6 lg:w-3/12">
								What day of the month works for you? :
							</p>
							<div className="relative w-full text-sm h-[45px] flex items-center px-2.5 rounded border border-[#B8B8B8] sm:w-[67%] md:w-4/6 lg:w-9/12 relative">
								<p
									className={`zabalRegFont text-sm ${
										day_of_the_month === ""
											? "text-[#B8B8B8]"
											: "text-[#393F42]"
									}`}
								>
									{day_of_the_month === ""
										? "Choose a day for payment"
										: day_of_the_month === "last day"
										? "last day"
										: `Day ${day_of_the_month}`}
								</p>
								<button
									className="absolute right-2.5 h-full grid place-items-center"
									onClick={() =>
										setCalendar((prev) => ({ ...prev, toggleCalendar: true }))
									}
								>
									<FaCalendarAlt size={16} color="#00525D" />
								</button>
							</div>
						</div>
					)}
					<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12">
							Delivery Type :
						</p>
						<div className="w-full h-[45px] sm:w-[67%] md:w-4/6 lg:w-9/12 flex gap-y-2 gap-5 md:gap-8 zabalMedFont flex-wrap">
							<div className="flex items-center gap-1.5 gap-y-2 text-[#8E9DA4]">
								<CustomRadioInput
									name="delivery_type"
									isChecked={!!leaseStructureData?.delivery_type}
									isReadOnly={true}
								/>
								<p className="text-xs">{leaseStructureData?.delivery_type}</p>
							</div>
						</div>
					</div>
					<div className="bg-[#E3E9EC] h-[1px] w-full hidden sm:block"></div>

					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						<CustomGreenBtn
							text="create"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={createAsset}
							isDisabled={isProceedBtnValid() || createAssetBasedSavingsLoading}
							isLoading={createAssetBasedSavingsLoading}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						<CustomGreenBtn
							text="create"
							onRequestClick={createAsset}
							isDisabled={isProceedBtnValid() || createAssetBasedSavingsLoading}
							isLoading={createAssetBasedSavingsLoading}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default AssetSavingsSummary;

AssetSavingsSummary.propTypes = {
	setActiveTab: PropTypes.func,
};
