import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../layout/Navbar";
import Balance from "../../components/Balance";
import Container from "../../layout/Container";
import {
	InviteFriendCard,
	TransactionFinanceCards,
} from "../../layout/FinanceLeaseCards";
import { CustomTabSelector } from "../../layout/CustomComponents";
import { Helmet } from "react-helmet";
// import TabAccounts from "../../components/TabAccounts";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import { RiskAppetiteIcon, SavingsIcon } from "../../layout/assets";
import { BsArrowRight } from "react-icons/bs";
import PropTypes from "prop-types";
import userContext from "../../context/UserContext";
import { useSelector } from "react-redux";
import { walletType } from "../../common/utils";
import useParameters from "../../components/customhooks/useParameters";
import TabAccounts from "../../components/TabAccounts";

const Finance = () => {
	const [activeTab, setActiveTab] = useState(1);
	const parameters = useParameters();

	const afInterest = parameters?.find(
		(param) => param.parameter === "Base Interest rate Apprenticeship Finance"
	);
	const afProgramSavings = parameters?.find(
		(param) =>
			param.parameter === "Base Interest rate (Savings) Apprenticeship Finance"
	);

	const { customerWallet } = useSelector((state) => state.payment);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const { setAfRate } = useContext(userContext);

	useEffect(() => {
		setAfRate((prevState) => ({
			...prevState,
			interest: afInterest,
			programSavings: afProgramSavings,
		}));
	}, [parameters]);

	return (
		<>
			<MobileNavDetails text="Finance" />
			<Container>
				<Helmet>
					<title>Fortcore - Finance</title>
				</Helmet>
				<Navbar text="apprentice financing" />
				<div className="mt-1 sm:mt-[55px]"></div>
				<Balance
					wallet={mainWallet}
					showAddFunds
					showWithdrawFunds
					showMoveFunds
				/>
				<div className="my-8 lg:my-10">
					<CustomTabSelector
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
				</div>

				{activeTab === 1 && (
					<>
						<div className="space-y-1.5">
							<p className="text-fortcore-green text-[15px] font-semibold zabalRegFont">
								Apprentice Financing
							</p>
							<p className="text-[12.5px] text-[#393F42] zabalRegFont">
								It is always more financially viable to lease than to own.
								Eliminate the burden of ownership of assets and save significant
								fund when you lease.
							</p>
						</div>
						<TransactionFinanceCards urlArr={["new-transaction"]} />
						<hr className="hidden sm:block" />
						<div className="pt-5 pb-5 sm:pt-8 lg:py-10 grid gap-5 sm:gap-8 sm:grid-cols-2 xl:grid-cols-3">
							<SavingsCard
								icon={<RiskAppetiteIcon width={60} height={60} />}
								text="RISK APPETITE"
								textOne="Know your Risk Appetitie"
								textTwo="Let’s Go"
							/>
							<SavingsCard
								icon={<SavingsIcon width={60} height={60} />}
								text="savings"
								textOne="Explore Savings"
							/>
						</div>
						<div className="pt-2 pb-10 sm:pt-8 lg:py-10 grid gap-5 sm:gap-8 sm:grid-cols-2 xl:grid-cols-3">
							<InviteFriendCard />
						</div>
					</>
				)}

				{/* {activeTab === 2 && <TabAccounts />} */}
				{activeTab === 2 && <TabAccounts />}
			</Container>
		</>
	);
};
export default Finance;

const SavingsCard = ({ icon, text, textOne, textTwo, onClick }) => (
	<div style={{ cursor: "pointer" }} onClick={onClick}>
		<div className="rounded-md border border-[#E3E9EC] p-4 space-y-3">
			<p className="uppercase text-[11.5px] text-[#393F42] zabalBondFont">
				{text}
			</p>
			<div className="flex gap-3 items-center">
				{icon}
				<div className="space-y-1">
					<p className="text-[13.5px] text-[#8E9DA4]/90 tracking-wide zabalBoldFont">
						{textOne}
					</p>
					{textTwo && (
						<button
							className="text-fortcore-green flex items-center gap-2 text-xs zabalBoldFont"
							onClick={onClick}
						>
							<BsArrowRight size={17} />
							<span>{textTwo}</span>
						</button>
					)}
				</div>
			</div>
		</div>
	</div>
);

SavingsCard.propTypes = {
	text: PropTypes.string,
	icon: PropTypes.object,
	textOne: PropTypes.string,
	textTwo: PropTypes.string,
	onClick: PropTypes.func,
};
