import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "../../../../assets/logosvg.svg";

function Footer() {
	const aboutUs = useRef(null);
	const assetFinace = useRef(null);
	const appFinance = useRef(null);
	const travelAdvance = useRef(null);
	const savings = useRef(null);

	const scrollToAbout = () => {
		if (aboutUs) {
			aboutUs.current?.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	const scrollToAssetFinance = () => {
		if (assetFinace) {
			assetFinace.current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	const scrollToAppFinance = () => {
		if (appFinance) {
			appFinance.current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	const scrollToTravelAdvance = () => {
		if (travelAdvance) {
			travelAdvance.current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	const scrollToSavings = () => {
		if (savings) {
			savings.current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	return (
		<footer>
			<Container maxWidth="lg">
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr 1fr" },
						rowGap: 4,
					}}
				>
					{/* Company  */}
					<Stack spacing={2}>
						<Typography
							variant="h1"
							component="h3"
							sx={{ fontWeight: 600, fontSize: "20px" }}
							color="primary.main"
						>
							Company
						</Typography>

						<Box component="ul" sx={style}>
							<li onClick={scrollToAbout}>
								<Link to="/">About Us</Link>
							</li>
							<li>
								<Link to="/">Careers</Link>
							</li>
						</Box>
					</Stack>

					{/* Products  */}
					<Stack spacing={2}>
						<Typography
							variant="h1"
							component="h3"
							sx={{ fontWeight: 600, fontSize: "20px" }}
							color="primary.main"
						>
							Products
						</Typography>

						<Box component="ul" sx={style}>
							<li onClick={scrollToAssetFinance}>
								<Link to="/">Asset Financing</Link>
							</li>
							<li onClick={scrollToAppFinance}>
								<Link to="/">Apprenticeship Finance</Link>
							</li>
							<li onClick={scrollToTravelAdvance}>
								<Link to="/">Travel Advance</Link>
							</li>
							<li onClick={scrollToSavings}>
								<Link to="/">Savings</Link>
							</li>
						</Box>
					</Stack>

					{/* Resources  */}
					<Stack spacing={2}>
						<Typography
							variant="h1"
							component="h3"
							sx={{ fontWeight: 600, fontSize: "20px" }}
							color="primary.main"
						>
							Resources
						</Typography>

						<Box component="ul" sx={style}>
							<li>
								<Link to="/">FAQ</Link>
							</li>
							<li>
								<Link to="/">Media</Link>
							</li>
						</Box>
					</Stack>

					{/* Legal  */}
					<Stack spacing={2}>
						<Typography
							variant="h1"
							component="h3"
							sx={{ fontWeight: 600, fontSize: "20px" }}
							color="primary.main"
						>
							Legal
						</Typography>

						<Box component="ul" sx={style}>
							<li>
								<Link to="/">Privacy Policy</Link>
							</li>
							<li>
								<Link to="/">Terms Of Service</Link>
							</li>
						</Box>
					</Stack>

					{/* Contact  */}
					<Stack spacing={2}>
						<Typography
							variant="h1"
							component="h3"
							sx={{ fontWeight: 600, fontSize: "20px" }}
							color="primary.main"
						>
							Contact
						</Typography>

						<Box component="ul" sx={style}>
							<li>
								<a href="mailto:Admin@fortcoretech.com">
									Admin@fortcoretech.com
								</a>
							</li>
							<li>
								<Link to="/">Help Center</Link>
							</li>
						</Box>
					</Stack>
				</Box>

				<Stack mt={10} mb={10} spacing={2}>
					<img
						src={Logo}
						alt="fortcore logo"
						style={{ width: "143px" }}
						ref={aboutUs}
					/>

					<Stack spacing={3}>
						<Typography
							variant="body1"
							sx={{ color: "#393F42", fontFamily: "ZabalMedium" }}
						>
							11, ligali Ayorinde street, Victoria island, lagos.
						</Typography>
						<div style={{ display: "grid", gap: "1rem" }}>
							<HeaderText text="About Us" />
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								Fortcore Technology Limited is a financial service company
								enabling businesses and the growing population of underserved
								African Middle class to gain access to new ways of financing
								their operations and lifestyle. With a strong focus on
								accelerating the growth of the African middle class and SMEs, we
								offer a range of innovative financing solutions. Our services
								include Asset financing, Apprenticeship finance, Travel advance
								and other embedded value added services.
							</Typography>
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								These comprehensive solutions are designed to cater to the
								unique needs and aspirations of our diverse clients. Through our
								asset finance service, businesses and individuals can access the
								assets they need without the burden of ownership or upfront
								costs. This approach provides the flexibility required to manage
								operations and lifestyle effectively. Our Apprenticeship finance
								offering aims to support skills development and promote
								entrepreneurial opportunities, allowing individuals to achieve
								their full potential. At Fortcore Technology Limited, we
								understand the financial constraints that can hinder travel
								plans. That&apos;s why we offer Travel advance to assist
								individuals in exploring new destinations and creating cherished
								memories.
							</Typography>
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								We take pride in our commitment to exceptional customer service,
								ensuring that our clients receive personalized attention and
								tailored solutions. Our team of finance experts is dedicated to
								providing guidance and support throughout the entire process,
								ensuring a seamless and hassle-free experience. As a socially
								responsible company, we are actively involved in giving back to
								<span ref={assetFinace}>
									the communities we serve by supporting local initiatives and
									fostering economic growth. We believe in creating
									opportunities and making a positive impact on society.
								</span>
							</Typography>
						</div>
						<div style={{ display: "grid", gap: "1rem" }}>
							<HeaderText text="Asset Financing" />
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								Lease&apos;s: One of our service pillars is digital asset
								leasing service to businesses and the working-class population.
								We are lifting the burden of ownership of assets, and the
								upfront lump sum for outright purchase of assets, by allowing
								income earners and businesses to efficiently and optimally
								spread the financing of their operations and lifestyle, over a
								given tenor, instead of outright purchase. Assets available for
								financing include electronics, vehicles, home / office
								appliances etc..
							</Typography>
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								Fund Owners: Fortcore is thrilled to introduce our
								groundbreaking Asset Financing Service, designed to connect
								savvy fund owners like yourselves with individuals and
								businesses seeking to lease high-value assets. This innovative
								program allows you to purchase assets of your choice, and
								<span ref={appFinance}>
									Fortcore will skillfully match these assets with lessees who
									are eager to make monthly rentals at an attractive interest
									rate.
								</span>
							</Typography>
						</div>
						<div style={{ display: "grid", gap: "1rem" }}>
							<HeaderText text="Apprenticeship Financing" />
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								We enable and provide digitalized financing to support
								Apprentices and promote entrepreneurial opportunities. With our
								apprenticeship finance service, individuals can access funding
								to procure the much needed assets required to start their
								business. to foster apprenticeship excellence and gain valuable
								hands-on experience in their chosen field.{" "}
								<span ref={travelAdvance}>
									We are committed to empowering individuals and providing them
									with the resources they need to thrive.
								</span>
							</Typography>
						</div>
						<div style={{ display: "grid", gap: "1rem" }}>
							<HeaderText text="Travel Advance" />
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								It&apos;s the ultimate solution for those seeking financial
								support to make their travel goals a reality. This unique
								service allows individuals access funds in advance for their
								travel expenses. Whether it&apos;s for business or leisure,
								Fortcore offers convenient and flexible options to help you
								cover your travel costs.
							</Typography>
						</div>
						<div style={{ display: "grid", gap: "1rem" }} ref={savings}>
							<HeaderText text="Savings" />
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								We offer a range of services and products designed to help you
								save and grow your money. With our savings products, users are
								able to save towards the lease of an asset, set savings goals
								and much more. Additionally all accounts on Fortcore platform
								are NDIC insured.
							</Typography>
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								For those who prefer a more traditional savings approach, our
								Regular Savings option is a reliable and straightforward
								solution. Here&aos;s why it&apos;s worth considering: Set a
								personalized savings goal and contribute regularly to build a
								financial safety net. Benefit from competitive interest rates on
								your savings, ensuring that your money works harder for you.
								Enjoy the peace of mind that comes with having a disciplined
								savings routine.
							</Typography>
							<Typography
								variant="body1"
								sx={{ color: "#393F42", fontFamily: "ZabalRegular" }}
							>
								Whether you choose Asset-Based Savings for its investment
								potential or regular Savings for its simplicity, Fortcore is
								committed to helping you achieve your financial goals with
								unmatched flexibility and security. All user accounts on
								Fortcore platform are also NDIC insured.
							</Typography>
						</div>
					</Stack>
				</Stack>
			</Container>
		</footer>
	);
}

export default Footer;

const style = {
	li: {
		listStyleType: "none",
		marginBottom: "10px",
		a: {
			color: "#717E83",
			fontFamily: "ZabalRegular",
		},
	},
};

const HeaderText = ({ text }) => <p className="zabalBoldFont">{text}</p>;

HeaderText.propTypes = {
	text: PropTypes.string,
};
