import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdOutlineClose } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";

const InvestmentLists = ({ investments, onClose, onSelect }) => {
	const [search, setSearch] = useState("");

	const filteredData = investments?.filter((inv) =>
		inv.objectId.toLowerCase().includes(search.toLowerCase())
	);

	return (
		<div className="bg-white w-11/12 max-w-[450px] rounded-md py-4">
			<div className="flex text-primary-color items-center justify-between px-4">
				<p className="text-sm md:text-base zabalMedFont font-bold">
					Investments
				</p>
				<button onClick={onClose}>
					<MdOutlineClose size={22} />
				</button>
			</div>
			<div className="relative px-4 mt-4">
				<input
					type="text"
					className="rounded p-2.5 border text-sm w-full outline-none focus:border-primary-color placeholder:text-[#B8C8CF]"
					placeholder="Search for investment"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<span className="absolute right-[25px] top-2.5">
					<IoSearchOutline size={22} color="#B8C8CF" />
				</span>
			</div>
			<div className="px-4 mt-2 max-h-[300px] overflow-y-auto scroll-wrapper">
				{filteredData.length > 0 ? (
					filteredData.map((inv) => (
						<InvestmentList
							list={inv}
							key={inv?.objectId}
							onSelect={() => onSelect(inv)}
						/>
					))
				) : (
					<div className="zabalMedFont text-sm flex justify-center items-center h-[70px]">
						<p>No investment matches the search text</p>
					</div>
				)}
			</div>
		</div>
	);
};

InvestmentLists.propTypes = {
	investments: PropTypes.array,
	onClose: PropTypes.func,
	onSelect: PropTypes.func,
};

export default InvestmentLists;

const InvestmentList = ({ list, onSelect }) => (
	<div
		className="flex items-center justify-between hover:bg-[#EEF2F3] hover:rounded-md hover:px-3 py-3 cursor-pointer text-[#555F63] hover:text-primary-color"
		onClick={onSelect}
	>
		<p className="zabalMedFont text-sm uppercase">{list?.objectId}</p>
		<p className="zabalRegFont text-sm">Pending</p>
	</div>
);

InvestmentList.propTypes = {
	list: PropTypes.object,
	onSelect: PropTypes.func,
};
