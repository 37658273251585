import React, { useEffect, useState } from "react";
import Navbar from "../../layout/Navbar";
import Container from "../../layout/Container";
import { Helmet } from "react-helmet";
import {
	Box,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
// import { CustomTwoGridBox } from "../finance/components/newTransaction/browse/component/Components";
import PropTypes from "prop-types";
// import { BankIcon, CardIcon } from "../../layout/assets";
import { IoIosArrowDown } from "react-icons/io";
// import WalletCard from "../finance/accountTab/component/WalletCard";
// import userContext from "../../context/UserContext";
import PaymentAmount from "./components/PaymentAmount";
import PaymentMethod from "./components/PaymentMethod";
import PaymentName from "./components/PaymentName";
import TransferDetails from "./components/TransferDetails";
// import PaymentVerification from "./components/PaymentVerification";
import Balance from "../../components/Balance";
import { MobileNavDetails } from "../../layout/MobileNavbar";
// import userContext from "../../context/UserContext";
import { useSelector } from "react-redux";
import { getProfilePercentage, walletType } from "../../common/utils";
import { CustomConfirmModal } from "../../layout/CustomComponents";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../components/ModalContainer";
import { toast } from "react-toastify";
// import Parse from "../../api/ApiConfig";
// import { toast } from "react-toastify";

const Payment = () => {
	const navigate = useNavigate();
	const { currentUser } = useSelector((state) => state.auth);
	const { customerWallet } = useSelector((state) => state.payment);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	// const [activePaymentTab, setActivePaymentTab] = useState(1);

	const dataValidation = {
		workEmail: currentUser?.workEmail,
		workEmailVerified: currentUser?.workEmailVerified,
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		workStatus: currentUser?.workStatus,
		salaryRange: currentUser?.salaryRange,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const percentageCompletionOfForm = getProfilePercentage(dataValidation);
	const [isReady, setIsReady] = useState(false);
	const [addAmount, setAddAmount] = useState(false);
	const [payMethod, setPayMethod] = useState(false);
	const [payName, setPayName] = useState(false);
	const [transferDetails, setTransferDetails] = useState(false);
	// const [paymentLoading, setPaymentLoading] = useState(false);
	// const { setFundWallet } = useContext(userContext);
	// const walletId = "87010e63-0bc6-46fe-8a23-adde4902416a";

	useEffect(() => {
		fetchPaymentTransactions();
	}, []);

	const fetchPaymentTransactions = async () => {
		// try {
		// 	const currentUser = Parse.User.current();
		// 	if (currentUser) {
		// 		// const userData = currentUser.toJSON();
		// 		const query = new Parse.Query("WalletTransactions");
		// 		// query.equalTo("user", currentUser);
		// 		query.descending("updatedAt");
		// 		const results = await query.find();
		// 		const result = results.map((result) => result.toJSON());
		// 		const filtered = result.find((item) => item.walletId === walletId);
		// 		// eslint-disable-next-line no-undef
		// 		console.log("Transactions:", filtered);
		// 	}
		// } catch (error) {
		// 	// eslint-disable-next-line no-undef
		// 	console.log(error);
		// 	toast.error(error.message);
		// }
	};

	const isProfileReady = () => {
		if (percentageCompletionOfForm >= 30 && currentUser?.bvn !== "") {
			setAddAmount(true);
		} else {
			setIsReady(true);
		}
	};

	const handleAddAmount = () => {
		setPayMethod(true);
		setAddAmount(false);
	};
	const handlePayMethod = () => {
		setPayName(true);
		setPayMethod(false);
	};
	const handlePayName = () => {
		setTransferDetails(true);
		setPayName(false);
	};
	const handleTransfer = () => {
		// setPaymentLoading(true);
		setTransferDetails(false);
		toast.success("Transaction Processing..");
	};

	const onProceedToProfile = () => {
		setIsReady(false);
		navigate("/dashboard/profile/complete-your-profile");
	};

	return (
		<>
			<MobileNavDetails text="payments" />
			<Container>
				<Helmet>
					<title>Fortcore - Payments</title>
				</Helmet>
				<Navbar text="payments" />

				{/* Payments  */}

				<Box sx={{ paddingTop: { xs: "5px", sm: "50px" } }}>
					<Stack
						direction={{ sm: "row" }}
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={{ xs: 4, sm: 0 }}
					>
						{/* <WalletCard amount={wallet.balance} /> */}
						<Box width="100%">
							<Balance wallet={mainWallet} />
						</Box>

						<Button
							variant="contained"
							color="primary"
							sx={{
								minWidth: { xs: "100%", sm: "120px" },
								padding: "10px",
								fontFamily: "zabalMedium",
							}}
							onClick={isProfileReady}
							// onClick={() => setAddAmount(true)}
						>
							Fund Wallet
						</Button>
					</Stack>
				</Box>

				{/* Transaction History  */}
				<Box
					p={2}
					sx={{
						background: "#F6F7F8",
						marginTop: "50px",
						borderRadius: "8px",
						width: "100%",
						overflowX: "auto",
						// minWidth: { xs: "0px" },
					}}
				>
					<Typography
						variant="h3"
						sx={{ fontFamily: "Chillax" }}
						color="primary"
					>
						Payments Transactions
					</Typography>

					<Box
						mt={3}
						// sx={{ width: "100%", overflowX: "auto", minWidth: { xs: "400px" } }}
					>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Transaction ID</TableCell>
									<TableCell>Amount</TableCell>
									<TableCell>Time</TableCell>
									<TableCell>Date</TableCell>
									<TableCell>Type</TableCell>
								</TableRow>
							</TableHead>

							{/* Body  */}

							<TableBody>
								{transactions.length > 0 ? (
									<>
										{transactions.map((data, index) => (
											<TableRow
												key={index}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell
													component="th"
													scope="row"
													sx={{ textTransform: "uppercase" }}
												>
													{data.id}
												</TableCell>
												<TableCell
													sx={{
														color: data.type === "credit" ? "#1f8b15" : "#f00",
													}}
												>
													{data.type === "credit" ? "+" : "-"} ₦
													{data.amount.toLocaleString()}
												</TableCell>
												<TableCell>{data.time}</TableCell>
												<TableCell>{data.date}</TableCell>
												<TableCell
													sx={{
														textTransform: "capitalize",
														color: data.type === "credit" ? "#1f8b15" : "#f00",
													}}
												>
													{data.type}
												</TableCell>
											</TableRow>
										))}
									</>
								) : (
									<TableRow>
										<TableCell colSpan={5}>
											<Typography align="center">No Transactions</Typography>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</Box>
				</Box>
			</Container>

			{isReady && (
				<ModalContainer modal={isReady}>
					<CustomConfirmModal
						isNotify
						text="You need to have at least 30% of profile completion (bvn included)."
						greenBtnText="complete now ?"
						greenBtnClick={onProceedToProfile}
						onCloseBtnClick={() => setIsReady(false)}
					/>
				</ModalContainer>
			)}

			{addAmount && (
				<PaymentAmount
					open={addAmount}
					close={() => setAddAmount(false)}
					onClick={handleAddAmount}
				/>
			)}
			{payMethod && (
				<PaymentMethod
					open={payMethod}
					close={() => setPayMethod(false)}
					onClick={handlePayMethod}
				/>
			)}
			{payName && (
				<PaymentName
					open={payName}
					close={() => setPayName(false)}
					onClick={handlePayName}
				/>
			)}
			{transferDetails && (
				<TransferDetails
					open={transferDetails}
					close={() => setTransferDetails(false)}
					onClick={handleTransfer}
				/>
			)}
			{/* {paymentLoading && (
				<PaymentVerification
					open={paymentLoading}
					close={() => {
						setFundWallet({});
						setPaymentLoading(false);
					}}
				/>
			)} */}
		</>
	);
};
export default Payment;

function DropButtons({ title, subText, icon }) {
	return (
		<Button
			sx={{
				border: " 1px solid var(--grey-100, #EEF2F3)",
				borderRadius: "12px",
				background:
					"linear-gradient(0deg, #EFF8F9 0%, rgba(239, 248, 249, 0.00) 100%)",
				height: "142px",
				width: "100%",
				p: 4,
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Stack direction="row" alignItems="center" spacing={2}>
				{icon}
				<Stack>
					<Typography variant="body1" sx={{ color: "#393F42" }} align="start">
						{title}
					</Typography>
					<Typography variant="caption" sx={{ color: "#788084" }} align="start">
						{subText}
					</Typography>
				</Stack>
			</Stack>

			<IoIosArrowDown />
		</Button>
	);
}

DropButtons.propTypes = {
	title: PropTypes.string,
	subText: PropTypes.string,
	icon: PropTypes.any,
};

const transactions = [
	// {
	// 	id: "36CDC7TS8E8",
	// 	amount: 10000,
	// 	time: "2:04",
	// 	date: "07-02-2023",
	// 	type: "credit",
	// },
	// {
	// 	id: "36CDC7TS8E8",
	// 	amount: 10000,
	// 	time: "2:04",
	// 	date: "07-02-2023",
	// 	type: "debit",
	// },
	// {
	// 	id: "36CDC7TS8E8",
	// 	amount: 10000,
	// 	time: "2:04",
	// 	date: "07-02-2023",
	// 	type: "credit",
	// },
	// {
	// 	id: "36CDC7TS8E8",
	// 	amount: 10000,
	// 	time: "2:04",
	// 	date: "07-02-2023",
	// 	type: "debit",
	// },
];
