import React, { useState, useEffect, useTransition } from "react";
import Container from "../../../layout/Container";
import { Helmet } from "react-helmet";
import Navbar from "../../../layout/Navbar";
import { FatBackTracker, UrlTracker } from "../../../layout/CustomComponents";
// import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getMarketPlaceData } from "../../../redux/lease/leaseServices";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import { DesktopSideModal } from "../../../components/ModalContainer";
import ItemDescription from "./ItemDescription";
import { FaArrowLeft } from "react-icons/fa";
import {
	MarketDataCard,
	MarketOrderSkeleton,
	MarketPlaceInputBtns,
	MarketTab,
	MobileLeaseCartBtn,
} from "./MarketPlace";
import { useNavigate } from "react-router-dom";
import {
	saveDataFromLeaseStructure,
	selectedRequestLeaseItem,
} from "../../../redux/lease/leaseActions";
import EmptyData from "../../../components/EmptyData";
import CustomFilter from "../../../layout/CustomFilter";

const MarketPlaceContainer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [text, setText] = useState("");
	const [query, setQuery] = useState("");
	const [, startTransition] = useTransition();
	const [activeTab, setActiveTab] = useState("all");

	const [priceQuery, setPriceQuery] = useState({
		startPrice: "",
		endPrice: "",
	});
	const [categoryChecked, setCategoryChecked] = useState([]);
	const [colorChecked, setColorChecked] = useState([]);
	const { startPrice, endPrice } = priceQuery;

	const handleChange = (e) => {
		setText(e.target.value);
		startTransition(() => setQuery(e.target.value));
	};

	useEffect(() => {
		dispatch(getMarketPlaceData());
	}, [activeTab]);

	const {
		fetchMarketPlaceDataLoading,
		fetchMarketPlaceDataSuccess,
		toggleMarketItemsModal,
		currentProfile,
		toggleFilterModal,
	} = useSelector((state) => state.lease);

	// console.log(fetchMarketPlaceDataSuccess);

	const currentData = fetchMarketPlaceDataSuccess?.filter((data) => {
		if (activeTab === "all") {
			return data;
		} else if (activeTab === "gadgets") {
			return data?.productObj?.category.toLowerCase().includes("gadget");
		} else if (activeTab === "appliances") {
			return data?.productObj?.category.toLowerCase().includes("appliance");
		} else if (activeTab === "vehicles") {
			return data?.productObj?.category.toLowerCase().includes("vehicle");
		}
	});

	console.log(currentData);

	const filteredCurrentData = currentData?.filter((d) =>
		d?.productObj?.productName.toLowerCase().includes(query.toLowerCase())
	);

	const getData = () => {
		const filterDataWithStartPrice = filteredCurrentData?.filter((asset) => {
			if (startPrice === "") {
				return filteredCurrentData;
			} else if (
				asset?.productObj.amount >= Number(startPrice.replaceAll(",", ""))
			) {
				return asset;
			}
		});

		const filterDataWithEndPrice = filterDataWithStartPrice?.filter((asset) => {
			if (endPrice === "") {
				return filterDataWithStartPrice;
			} else if (
				asset?.productObj.amount >= Number(startPrice.replaceAll(",", "")) &&
				asset?.productObj.amount <= Number(endPrice.replaceAll(",", ""))
			) {
				return asset;
			}
		});

		const subCategoriesData = () => {
			if (categoryChecked.length < 1) {
				return filterDataWithEndPrice;
			} else {
				const filterByCategories = filterDataWithEndPrice
					?.map((data) => {
						return (
							categoryChecked.some((category) =>
								data?.productObj?.subCategory.includes(category)
							) && data
						);
					})
					.filter((a) => a);

				return filterByCategories;
			}
		};

		const colorsData = () => {
			if (colorChecked.length < 1) {
				return subCategoriesData();
			} else {
				const filterByColours = subCategoriesData()
					?.map((data) => {
						return (
							colorChecked.some((color) =>
								data?.productObj?.color
									.toLowerCase()
									.includes(color.toLowerCase())
							) && data
						);
					})
					.filter((a) => a);

				return filterByColours;
			}
		};

		return colorsData();
	};

	const onProceedToRequestLease = () => {
		if (currentProfile !== "investor") {
			dispatch(saveDataFromLeaseStructure({ requestType: "new" }));
			navigate("/leasing/new-transaction/request-lease", {
				state: { currentTab: 1 },
			});
		}
	};
	const proceedToRequestLeaseFromMarketPlace = (item) => {
		dispatch(selectedRequestLeaseItem(item));
		if (currentProfile !== "investor") {
			dispatch(saveDataFromLeaseStructure({ requestType: "old" }));
			navigate("/leasing/new-transaction/request-lease", {
				state: { currentTab: 3 },
			});
		}
	};

	return (
		<>
			{toggleMarketItemsModal && (
				<DesktopSideModal modal={toggleMarketItemsModal}>
					<ItemDescription />
				</DesktopSideModal>
			)}
			{toggleFilterModal && (
				<DesktopSideModal modal={toggleFilterModal}>
					<CustomFilter
						startPrice={startPrice}
						endPrice={endPrice}
						categoryChecked={categoryChecked}
						setCategoryChecked={setCategoryChecked}
						colorChecked={colorChecked}
						setColorChecked={setColorChecked}
						setPriceQuery={setPriceQuery}
						onBtnClick={getData}
					/>
				</DesktopSideModal>
			)}
			<MobileNavDetails text="new transaction" />
			<Helmet>
				<title>Fortcore - Market Place</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="new transaction" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="hidden sm:flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="new transaction"
							onNavigate={() => navigate("/leasing/new-transaction")}
						/>
						<UrlTracker text="market place" isCurrentUrl />
					</div>
					<div className="mb-3 flex items-center justify-between sm:hidden zabalMedFont">
						<FatBackTracker
							text="market place"
							onNavigate={() => navigate("/leasing/new-transaction")}
						/>
						<MobileLeaseCartBtn
							text={currentProfile === "investor" ? "create" : "new request"}
							onLeaseNavigate={onProceedToRequestLease}
						/>
					</div>
					<div className="sm:my-6 rounded-sm sm:bg-[#F6F7F8] sm:p-5 mb-10">
						<div className="gap-2 5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont hidden sm:flex mb-3">
							<button
								className="zabalMedFont"
								onClick={() => navigate("/leasing/new-transaction")}
							>
								<FaArrowLeft />
							</button>
							<p>Market Place</p>
						</div>
						<MarketPlaceInputBtns
							text={text}
							handleTextChange={handleChange}
							leaseBtnText={
								currentProfile === "investor" ? "create" : "new request"
							}
							leaseBtnRequestClick={onProceedToRequestLease}
						/>
						<div className="border-b border-[#B8C8CF] flex justify-between gap-1 sm:gap-3 mt-4 mb-3 md:mt-7 md:mb-4">
							<MarketTab
								text="all"
								isCurrentTab={activeTab === "all"}
								onTabChange={() => setActiveTab("all")}
							/>
							<MarketTab
								text="gadgets"
								isCurrentTab={activeTab === "gadgets"}
								onTabChange={() => setActiveTab("gadgets")}
							/>
							<MarketTab
								text="vehicles"
								isCurrentTab={activeTab === "vehicles"}
								onTabChange={() => setActiveTab("vehicles")}
							/>
							<MarketTab
								text="appliances"
								isCurrentTab={activeTab === "appliances"}
								onTabChange={() => setActiveTab("appliances")}
							/>
						</div>
						<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-3 xl:gap-4">
							{fetchMarketPlaceDataLoading ? (
								Array(8)
									.fill(0)
									.map((_, i) => <MarketOrderSkeleton key={i} />)
							) : getData()?.length > 0 ? (
								getData().map((data) => (
									<MarketDataCard
										data={data?.productObj}
										asset={data}
										key={data?.objectId}
										onReqClick={proceedToRequestLeaseFromMarketPlace}
									/>
								))
							) : fetchMarketPlaceDataSuccess?.length &&
							  getData()?.length === 0 ? (
								<div className="col-span-2 sm:col-span-3 md:col-span-4 xl:col-span-5 w-full h-[250px] md:h-[270px]">
									<EmptyData text="No item is available for this query." />
								</div>
							) : (
								<div className="col-span-2 sm:col-span-3 md:col-span-4 xl:col-span-5 w-full h-[250px] md:h-[270px]">
									<EmptyData text="No item exists in this market place." />
								</div>
							)}
						</div>
					</div>
				</>
			</Container>
		</>
	);
};
export default MarketPlaceContainer;
