import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { AiFillFileText } from "react-icons/ai";
import theme from "../../../../../../components/mui/theme";
import { Preloader } from "../../../../../../components/globalStyled";
import { useUploadFile } from "../../../../../../components/customhooks/useUploadFile";

const supportedFileTypes = ["application/pdf", "application/msword"];

export const AttachmentUploadComponent = ({ onFileUpload, fileName }) => {
	const [attachFile, setAttachFile] = useState(null);
	const attachmentInputRef = useRef(null);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file && supportedFileTypes.includes(file.type)) {
			setAttachFile(file);
			// eslint-disable-next-line no-undef
		} else {
			toast.error(
				"Unsupported file type. Please select a PDF, DOC, DOCX or TXT file."
			);
		}
	};

	const { loading, fileSuccess } = useUploadFile(attachFile);

	useEffect(() => {
		if (fileSuccess) {
			onFileUpload(fileSuccess);
		}
	}, [fileSuccess]);

	return (
		<Box
			width="100%"
			height="66px"
			sx={{
				borderRadius: "8px",
				border: "1px solid var(--grey-1100, #96A0A5)",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "0 5px",
			}}
			//   onClick={() => fileInputRef.current.click()}
		>
			<input
				type="file"
				ref={attachmentInputRef}
				style={{ display: "none" }}
				onChange={handleFileChange}
				accept=".pdf, .doc"
			/>
			<Typography
				variant="body1"
				color="textSecondary"
				sx={{
					fontStyle: "italic",
					textTransform: "capitalize",
				}}
			>
				{fileName}
			</Typography>

			<Button
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					border: "1px solid var(--grey-1100, #96A0A5)",
					borderRadius: "8px",
					padding: "8px",
					maxWidth: { xs: "45%", sm: "30%" },
				}}
				onClick={() => attachmentInputRef.current.click()}
				disabled={loading}
			>
				{loading ? (
					<Preloader />
				) : (
					<>
						<AiFillFileText style={{ color: theme.palette.primary.main }} />
						&nbsp;
						<Typography variant="body1" color="primary">
							Add New File
						</Typography>
					</>
				)}
			</Button>
		</Box>
	);
};

AttachmentUploadComponent.propTypes = {
	onFileUpload: PropTypes.any,
	fileName: PropTypes.string,
};
