import { MdOutlineClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import React from "react";
import { closeCustomAlert } from "../redux/authRedux/authActions";
import PropTyes from "prop-types";

const Alert = ({ textOne, textTwo, type }) => {
	const dispatch = useDispatch();

	return (
		<div className="border border-[#D4DEE2] rounded flex p-3 items-center justify-between bg-[#EEF2F3]">
			<div
				className={`${
					type === "info" ? "text-[#393F42]" : "text-fortcore-green"
				}`}
			>
				<p className="text-[13px] zabalMedFont">{textOne}</p>
				<small className="zabalMedFont opacity-75 text-xs leading-[5px]">
					{textTwo}
				</small>
			</div>
			<button
				className="font-semibold"
				onClick={() => dispatch(closeCustomAlert())}
			>
				<MdOutlineClose color="#00525D" size={17} />
			</button>
		</div>
	);
};
export default Alert;

Alert.propTypes = {
	textOne: PropTyes.string.isRequired,
	textTwo: PropTyes.string,
	type: PropTyes.string,
};
