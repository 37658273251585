import React, { useContext } from "react";
import { AnyModal } from "../../finance/newTransaction/investor/create/InvestorCreateAF";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import theme from "../../../components/mui/theme";
import userContext from "../../../context/UserContext";
import { inputStyle } from "./PaymentAmount";

function PaymentName({ open, close, onClick }) {
  const { fundWallet, setFundWallet } = useContext(userContext);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFundWallet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProceed = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <>
      <AnyModal open={open}>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3" color="primary">
              Transfer
            </Typography>
            <IconButton onClick={close}>
              <IoMdClose style={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>
          <Typography variant="caption" color="textSecondary">
            Enter Sender’s Details (<b>Amount</b>: NGN {fundWallet.amount})
          </Typography>

          <Box mt={2} component="form" onSubmit={handleProceed}>
            <Stack spacing={1}>
            <Typography
                variant="b"
                ody1sx={{ fontFamily: "ZabalMedium", fontSize: "14px" }}
                color="textSecondary"
              >
               Full Name
              </Typography>
              <input
                type="text"
                style={inputStyle}
                placeholder="Enter senders full name"
                name="name"
                value={fundWallet.name}
                onChange={handleInputChange}
                required
              />
            </Stack>

            <Box
              mt={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              width="100%"
            >
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  onClick={close}
                  type="button"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Proceed
                </Button>
              </Stack>
            </Box>

            {/* Mobile Buttons  */}
            <Box mt={2} width="100%" sx={{ display: { sm: "none" } }}>
              <Stack spacing={2}>
               
                <Button variant="contained" type="submit">
                  Proceed
                </Button>
                <Button variant="outlined" onClick={close} type="button">
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </AnyModal>
    </>
  );
}

PaymentName.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func,
};
export default PaymentName;
