import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MdOutlineFileUpload } from "react-icons/md";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import { Preloader } from "../../../../components/globalStyled";
import { shortenLongText } from "../../../../common/utils";
import { useUploadFile } from "../../../../components/customhooks/useUploadFile";
import Parse from "../../../../api/ApiConfig";
import { saveDocumentsInformation } from "../../../../redux/travels/travelServices";
import { updateUserDataInParse } from "../../../../redux/authRedux/authServices";

const DocumentsInformation = () => {
	const dispatch = useDispatch();
	const { currentUser } = useSelector((state) => state.auth);
	const { documentsInfoLoading, documentsInfoSuccess } = useSelector(
		(state) => state.travel
	);

	const [documentsInfo, setDocumentsInfo] = useState({
		international_passport: documentsInfoSuccess?.idCard
			? documentsInfoSuccess.idCard
			: currentUser?.idType.toLowerCase().includes("passport") &&
			  currentUser?.idCard !== undefined
			? currentUser?.idCard
			: "",
		utility_bill:
			documentsInfoSuccess?.utilityBill || currentUser?.utilityBill || "",
		bank_statement:
			documentsInfoSuccess?.bankStatement || currentUser?.bankStatement || "",
		intlPassport: null,
		utilityBill: null,
		statement: null,
	});

	const {
		international_passport,
		utility_bill,
		bank_statement,
		intlPassport,
		utilityBill,
		statement,
	} = documentsInfo;

	// upload intl passport
	const {
		loading: uploadPassportLoading,
		fileUploadSuccess: uploadPassportSuccess,
	} = useUploadFile(intlPassport);

	useEffect(() => {
		if (uploadPassportSuccess) {
			setDocumentsInfo((prev) => ({
				...prev,
				intlPassport: null,
				international_passport: uploadPassportSuccess,
			}));
		}
	}, [uploadPassportSuccess]);

	// upload utility bill
	const { loading: billUploadLoading, fileUploadSuccess: billUploadSuccess } =
		useUploadFile(utilityBill);

	useEffect(() => {
		if (billUploadSuccess) {
			setDocumentsInfo((prev) => ({
				...prev,
				utilityBill: null,
				utility_bill: billUploadSuccess,
			}));
		}
	}, [billUploadSuccess]);

	// upload bank statement
	const {
		loading: statementUploadLoading,
		fileUploadSuccess: statementUploadSuccess,
	} = useUploadFile(statement);

	useEffect(() => {
		if (statementUploadSuccess) {
			setDocumentsInfo((prev) => ({
				...prev,
				statement: null,
				bank_statement: statementUploadSuccess,
			}));
		}
	}, [statementUploadSuccess]);

	const isBtnDisabled = () => {
		if (
			international_passport === "" ||
			utility_bill === "" ||
			bank_statement === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onDocumentsInfoSave = () => {
		const data = {
			idCard: international_passport,
			utilityBill: utility_bill,
			bankStatement: bank_statement,
			userId: user,
		};

		const dataForUser = {
			idCard: international_passport,
			utilityBill: utility_bill,
			bankStatement: bank_statement,
		};

		dispatch(saveDocumentsInformation(data));
		dispatch(updateUserDataInParse(dataForUser));
	};

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<div className="flex flex-col sm:flex-row gap-1.5 sm:items-center zabalRegFont md:gap-7">
				<p className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5">
					International Passport:
				</p>
				<div className="relative w-full sm:w-[70%] md:w-4/5">
					<UploadFileCard
						placeholderText="Upload international passport"
						id="intl_passport"
						acceptedFile="image/*,.pdf"
						fileUrl={international_passport}
						isLoading={uploadPassportLoading}
						handleChange={(e) =>
							setDocumentsInfo((prev) => ({
								...prev,
								intlPassport: e.target.files[0],
							}))
						}
					/>
				</div>
			</div>
			<div className="flex flex-col sm:flex-row gap-1.5 sm:items-center zabalRegFont md:gap-7">
				<p className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5">
					Utility Bill:
				</p>
				<div className="relative w-full sm:w-[70%] md:w-4/5">
					<UploadFileCard
						placeholderText="Upload utility bill"
						id="utility_bill"
						acceptedFile="image/*,.pdf"
						fileUrl={utility_bill}
						isLoading={billUploadLoading}
						handleChange={(e) =>
							setDocumentsInfo((prev) => ({
								...prev,
								utilityBill: e.target.files[0],
							}))
						}
					/>
				</div>
			</div>
			<div className="flex flex-col sm:flex-row gap-1.5 sm:items-center zabalRegFont md:gap-7">
				<p className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5">
					Bank Statement:
				</p>
				<div className="relative w-full sm:w-[70%] md:w-4/5">
					<UploadFileCard
						placeholderText="Upload bank statement"
						id="bank_statement"
						acceptedFile=".pdf"
						fileUrl={bank_statement}
						isLoading={statementUploadLoading}
						handleChange={(e) =>
							setDocumentsInfo((prev) => ({
								...prev,
								statement: e.target.files[0],
							}))
						}
					/>
				</div>
			</div>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || documentsInfoLoading}
					isLoading={documentsInfoLoading}
					onRequestClick={onDocumentsInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || documentsInfoLoading}
					isLoading={documentsInfoLoading}
					onRequestClick={onDocumentsInfoSave}
				/>
			</div>
		</div>
	);
};

export default DocumentsInformation;

const UploadFileCard = ({
	placeholderText,
	handleChange,
	id,
	acceptedFile,
	fileUrl,
	isLoading,
}) => {
	return (
		<div className="w-full border border-[#B8B8B8] rounded flex justify-between items-center gap-3 h-[47px] pl-2.5 py-2 pr-1">
			{isLoading ? (
				<Preloader />
			) : fileUrl ? (
				<a
					href={fileUrl}
					target="_blank"
					rel="noreferrer"
					className="zabalMedFont text-[12.5px] md:text-[13px] text-blue-700"
				>
					{shortenLongText(fileUrl, 10)}
				</a>
			) : (
				<p className="zabalMedFont text-[#B8B8B8] text-[13px]">
					{placeholderText}
				</p>
			)}
			<input
				type="file"
				className="hidden"
				id={id}
				onChange={handleChange}
				accept={acceptedFile}
			/>
			<button
				className="zabalMedFont text-[12.5px] flex items-center gap-1 md:gap-2 text-fortcore-green border border-[#B8C8CF] px-3 md:px-4 py-2 rounded"
				onClick={() => document.getElementById(`${id}`).click()}
			>
				<MdOutlineFileUpload size={18} />
				<span>
					{fileUrl ? "Change" : "Upload"}{" "}
					<span className="hidden md:inline">File</span>
				</span>
			</button>
		</div>
	);
};

UploadFileCard.propTypes = {
	placeholderText: PropTypes.string,
	id: PropTypes.PropTypes.string,
	acceptedFile: PropTypes.PropTypes.string,
	handleChange: PropTypes.func,
	fileUrl: PropTypes.string,
	isLoading: PropTypes.bool,
};
