import React, { useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import {
	formatAmountToIncludeCurrency,
	getMaintenanceFee,
	getMonthlyPayment,
	getOneOffPayment,
} from "../../../common/utils";
import {
	// getDeliveryFee,
	getRentalFee,
} from "../../../redux/lease/leaseServices";
import Parse from "../../../api/ApiConfig";
import useParameters from "../../../components/customhooks/useParameters";

export const ViewPaymentDetails = ({
	data,
	onCloseBtnClick,
	onBackBtnClick,
	onProceedBtnClick,
	oneOffPaymentDetailsClick,
	monthlyPaymentDetailsClick,
	isLoading,
	isBtnDisabled,
}) => {
	const dispatch = useDispatch();
	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const {
		rentalFeeLoading,
		rentalFeeSuccess,
		deliveryFeeLoading,
		// deliveryFeeSuccess,
		// leaseStructureData,
	} = useSelector((state) => state.lease);

	// useEffect(() => {
	// 	if (data?.deliveryType === "pick-up") return;
	// 	if (deliveryFeeSuccess) return;

	// 	dispatch(
	// 		getDeliveryFee(
	// 			{ location: leaseStructureData?.lga.toLowerCase() },
	// 			currentSessionToken
	// 		)
	// 	);
	// }, []);

	useEffect(() => {
		if (rentalFeeSuccess) return;
		dispatch(
			getRentalFee({ assetValue: Number(data?.price) }, currentSessionToken)
		);
	}, []);

	const monthly_payment = getMonthlyPayment(rentalFeeSuccess);

	const adh = { price: data?.price, deliveryType: data?.deliveryType };
	const one_off_payment = getOneOffPayment(adh);

	return (
		<div className="w-[100vw] flex justify-center items-center">
			<div className="bg-white rounded-md p-4 sm:p-5 md:p-6 w-11/12 max-w-[500px] flex flex-col gap-5 md:gap-6">
				<div className="space-y-3 text-[#393F42]">
					<div className="flex justify-between">
						<p className="mt-1 text-sm md:text-[15px] zabalBoldFont capitalize">
							view payment details on transaction
						</p>
						<button onClick={onCloseBtnClick}>
							<MdOutlineClose size={19} color="#00525D" />
						</button>
					</div>
					<p className="text-xs zabalRegFont capitalize">
						Transaction ID: <span className="zabalMedFont">N/A</span>
					</p>
				</div>
				<div className="grid sm:grid-cols-2 gap-2.5 sm:gap-3 lg:gap-4 text-[#393F42]">
					<div className="rounded bg-[#F6F7F8] p-3 md:px-3.5 md:py-3 grid gap-2">
						<div className="space-y-1">
							<p className="text-[11px] zabalMedFont">One Off Payment</p>
							<h6 className="zabalBoldFont text-[13px] lg:text-[13.5px]">
								{deliveryFeeLoading ? (
									<Skeleton className="!w-[140px] h-[20px] rounded-sm" />
								) : (
									formatAmountToIncludeCurrency("NGN", one_off_payment)
								)}
							</h6>
						</div>
						<button
							className={`justify-self-end underline text-[11.5px] zabalMedFont ${
								deliveryFeeLoading ? "text-gray-500" : "text-fortcore-green"
							}`}
							onClick={oneOffPaymentDetailsClick}
							disabled={deliveryFeeLoading}
						>
							View Details
						</button>
					</div>
					<div className="rounded bg-[#F6F7F8] p-3 md:px-3.5 md:py-3 grid gap-2">
						<div className="space-y-1">
							<p className="text-[11px] zabalMedFont">Monthly Payment</p>
							<h6 className="zabalBoldFont text-[13px] lg:text-[13.5px]">
								{rentalFeeLoading ? (
									<Skeleton className="!w-[140px] h-[20px] rounded-sm" />
								) : (
									formatAmountToIncludeCurrency("NGN", monthly_payment)
								)}
							</h6>
						</div>
						<button
							className={`justify-self-end underline text-[11.5px] zabalMedFont ${
								rentalFeeLoading ? "text-gray-500" : "text-fortcore-green"
							}`}
							onClick={monthlyPaymentDetailsClick}
							disabled={rentalFeeLoading}
						>
							View Details
						</button>
					</div>
				</div>
				<div className="my-3 flex flex-col gap-3 sm:hidden">
					<CustomGreenBtn
						fontSize="13px"
						text="proceed"
						onRequestClick={onProceedBtnClick}
						isDisabled={
							isLoading ||
							rentalFeeLoading ||
							deliveryFeeLoading ||
							!rentalFeeSuccess
						}
						isLoading={isLoading}
					/>
					<CustomWhiteBtn
						fontSize="13px"
						text="back"
						onRequestClick={onBackBtnClick}
						isDisabled={isBtnDisabled}
					/>
				</div>
				<div className="hidden sm:flex justify-end gap-3">
					<CustomWhiteBtn
						width="100px"
						height="40px"
						fontSize="13px"
						text="back"
						onRequestClick={onBackBtnClick}
						isDisabled={isBtnDisabled}
					/>
					<CustomGreenBtn
						width="100px"
						height="40px"
						fontSize="13px"
						text="proceed"
						onRequestClick={onProceedBtnClick}
						isDisabled={
							isLoading ||
							rentalFeeLoading ||
							deliveryFeeLoading ||
							!rentalFeeSuccess
						}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};

ViewPaymentDetails.propTypes = {
	isBtnDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	handleChange: PropTypes.func,
	payment_type: PropTypes.string,
	data: PropTypes.object,
	onBackBtnClick: PropTypes.func,
	onCloseBtnClick: PropTypes.func,
	onProceedBtnClick: PropTypes.func,
	oneOffPaymentDetailsClick: PropTypes.func,
	monthlyPaymentDetailsClick: PropTypes.func,
};

export const OneOffPayment = ({ onCloseBtnClick, onBackBtnClick, data }) => {
	const isn = useParameters();

	const insuranceRate = isn?.find(
		(p) => p?.parameter?.toLocaleLowerCase().includes("insurance") && p?.value
	);

	const one_off_payment = Number(data?.price);
	const fixedOriginalFee = (2.5 / 100) * one_off_payment;
	const refundableFee = (10 / 100) * one_off_payment;
	const assetInsuranceFee =
		(Number(insuranceRate?.value?.replaceAll("%", "")) / 100) * one_off_payment;

	return (
		<div className="w-[100vw] flex justify-center items-center">
			<div className="text-[#393F42] rounded-md bg-white px-4 py-5 md:px-5 md:py-6 w-[85%] space-y-4 max-w-[400px]">
				<div className="flex justify-between pb-1.5 border-b border-[#EEF2F3]">
					<p className="text-sm sm:text-base zabalBoldFont">One off Payment</p>
					<button onClick={onCloseBtnClick}>
						<MdOutlineClose color="#00525D" size={19} />
					</button>
				</div>
				<div className="flex justify-between">
					<div className="space-y-1">
						<p className="text-[13px] sm:text-sm zabalMedFont">
							Refundable Caution Fee
						</p>
						<p className="text-xs font-normal zabalRegFont">
							(10% of asset value)
						</p>
					</div>
					<p className="text-[13px] sm:text-sm zabalBoldFont text-fortcore-green">
						{formatAmountToIncludeCurrency("NGN", refundableFee)}
					</p>
				</div>
				<div className="flex justify-between">
					<p className="text-[13px] sm:text-sm zabalMedFont">Delivery Cost</p>
					<p className="text-[13px] sm:text-sm zabalBoldFont text-fortcore-green">
						N/A
					</p>
				</div>
				<div className="flex justify-between">
					<div className="space-y-1">
						<p className="text-[13px] sm:text-sm zabalMedFont">
							Fixed Origination Fee
						</p>
						<p className="text-xs font-normal zabalRegFont">
							(2.5% of asset value)
						</p>
					</div>
					<p className="text-[13px] sm:text-sm zabalBoldFont text-fortcore-green">
						{formatAmountToIncludeCurrency("NGN", fixedOriginalFee)}
					</p>
				</div>
				<div className="flex justify-between">
					<div className="space-y-1">
						<p className="text-[13px] sm:text-sm zabalMedFont">
							Asset Insurance Fee
						</p>
						<p className="text-xs font-normal zabalRegFont">
							({insuranceRate?.value} of asset value)
						</p>
					</div>
					<p className="text-[13px] sm:text-sm zabalBoldFont text-fortcore-green">
						{formatAmountToIncludeCurrency("NGN", assetInsuranceFee)}
					</p>
				</div>
				<div className="sm:hidden py-2">
					<CustomWhiteBtn
						text="back"
						fontSize="13px"
						onRequestClick={onBackBtnClick}
					/>
				</div>
				<div className="hidden sm:flex justify-end">
					<CustomWhiteBtn
						text="back"
						width="100px"
						height="40px"
						fontSize="13px"
						onRequestClick={onBackBtnClick}
					/>
				</div>
			</div>
		</div>
	);
};

OneOffPayment.propTypes = {
	data: PropTypes.object,
	onBackBtnClick: PropTypes.func,
	onCloseBtnClick: PropTypes.func,
};

export const MonthlyPayment = ({
	onCloseBtnClick,
	onBackBtnClick,
	data,
	shelfLife,
}) => {
	const { rentalFeeSuccess } = useSelector((state) => state.lease);

	const maintenanceFee = getMaintenanceFee(
		data?.price,
		shelfLife,
		data?.productCategory
	);

	return (
		<div className="w-[100vw] flex justify-center items-center">
			<div className="text-[#393F42] rounded-md bg-white px-4 py-5 md:px-5 md:py-6 w-[85%] space-y-4 max-w-[350px]">
				<div className="flex justify-between pb-1.5 border-b border-[#EEF2F3]">
					<p className="text-sm sm:text-base zabalBoldFont">Monthly Payment</p>
					<button onClick={onCloseBtnClick}>
						<MdOutlineClose color="#00525D" size={19} />
					</button>
				</div>
				<div className="flex justify-between">
					<p className="text-[13px] sm:text-sm zabalMedFont">
						Monthly Rental Fee:
					</p>
					<p className="text-[13px] sm:text-sm zabalBoldFont text-fortcore-green">
						{formatAmountToIncludeCurrency("NGN", rentalFeeSuccess)}
					</p>
				</div>
				<div className="flex justify-between">
					<p className="text-[13px] sm:text-sm zabalMedFont">
						Monthly Maintenance Fee:
					</p>
					<p className="text-[13px] sm:text-sm zabalBoldFont text-fortcore-green">
						{data?.productCategory?.toLowerCase() === "vehicle"
							? formatAmountToIncludeCurrency("NGN", maintenanceFee)
							: "NGN 0.00"}
					</p>
				</div>
				<div className="sm:hidden py-2">
					<CustomWhiteBtn
						text="back"
						fontSize="13px"
						onRequestClick={onBackBtnClick}
					/>
				</div>
				<div className="hidden sm:flex justify-end">
					<CustomWhiteBtn
						text="back"
						width="100px"
						height="40px"
						fontSize="13px"
						onRequestClick={onBackBtnClick}
					/>
				</div>
			</div>
		</div>
	);
};

MonthlyPayment.propTypes = {
	data: PropTypes.object,
	onBackBtnClick: PropTypes.func,
	onCloseBtnClick: PropTypes.func,
	shelfLife: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
