import React from "react";
import PropTypes from "prop-types";
import { formatAmountToIncludeCurrency } from "../../common/utils";
import moment from "moment";

const MonthlyRepayments = ({ payments }) => {
	// console.log(payments);
	return (
		<>
			{payments.map((payment) => (
				<div
					className="grid grid-cols-3 gap-4 text-[12.5px] sm:text-[13px] md:text-[13.5px] w-full py-2.5 md:py-3 border-b"
					key={payment.date}
				>
					<p className="zabalRegFont">
						{moment(payment.date).format("MMM Do, YYYY")}
					</p>
					<p className="zabalRegFont text-center">
						{formatAmountToIncludeCurrency("NGN", payment.amount)}
					</p>
					<p
						className={`zabalMedFont text-end ${
							payment.status ? "text-green-500" : "text-red-500"
						}`}
					>
						{payment.status ? "Paid" : "Unpaid"}
					</p>
				</div>
			))}
		</>
	);
};

MonthlyRepayments.propTypes = {
	payments: PropTypes.array,
};

export default MonthlyRepayments;
