import * as financeTypes from "./financeTypes";

const initialState = {
	toggleFinanceModal: false,
	selectedFinanceOption: "",
	userFinanceRequest: null,
	investorFinanceRequest: null,
	toggleDeleteFinanceModal: false,
	currentFinanceReq: null,
	userSelectedCreatedFinance: null,
	toggleViewFinanceReqModal: false,
	togglePayCommitmentFeeModal: false,
};

export const financeReducer = (state = initialState, action) => {
	switch (action.type) {
		case financeTypes.TOGGLE_FINANCE_FORM_MODAL:
			return { ...state, toggleFinanceModal: action.payload };
		case financeTypes.SELECTED_FINANCE_OPTION:
			return { ...state, selectedFinanceOption: action.payload };
		case financeTypes.SAVE_FINANCE_USER_REQUEST:
			return {
				...state,
				userFinanceRequest: { ...state.userFinanceRequest, ...action.payload },
			};
		case financeTypes.CLEAR_USER_REQ_FINANCE_DATA:
			return { ...state, userFinanceRequest: null };
		case financeTypes.SAVE_INVESTOR_FINANCE_REQUEST:
			return {
				...state,
				investorFinanceRequest: {
					...state.investorFinanceRequest,
					...action.payload,
				},
			};
		case financeTypes.TOGGLE_DELETE_FINANCE_MODAL:
			return {
				...state,
				toggleDeleteFinanceModal: action.payload.state,
				currentFinanceReq: action.payload.data,
			};
		case financeTypes.SELECT_FINANCE_TO_ACTIVATE:
			return { ...state, currentFinanceReq: action.payload };
		case financeTypes.SAVE_APPRENTICE_SELECTED_REQUEST:
			return {
				...state,
				userFinanceRequest: { ...state.userFinanceRequest, ...action.payload },
			};
		case financeTypes.USER_CHOOSES_CREATED_FINANCE:
			return {
				...state,
				userSelectedCreatedFinance: {
					...state.userSelectedCreatedFinance,
					...action.payload,
				},
			};
		case financeTypes.TOGGLE_VIEW_FINANCE_REQUESTS_MODAL:
			return { ...state, toggleViewFinanceReqModal: action.payload };
		case financeTypes.TOGGLE_PAY_COMMITMENT_FEE:
			return {
				...state,
				togglePayCommitmentFeeModal: action.payload.state,
				currentFinanceReq: action.payload.data,
			};
		default:
			return state;
	}
};
