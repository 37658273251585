import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import {
	Box,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	Divider,
	Button,
} from "@mui/material";
import { BiSearch } from "react-icons/bi";
import { AiFillPlusCircle } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { PiSmileySadDuotone } from "react-icons/pi";
import { BsInfoCircleFill } from "react-icons/bs";
import MarketPlaceDescription from "./MarketPlaceDescription";
import { IoMdCart } from "react-icons/io";
import userContext from "../../../../../../context/UserContext";
import { MobileNavDetails } from "../../../../../../layout/MobileNavbar";
import Navbar from "../../../../../../layout/Navbar";
import { CustomFourGridBox } from "../../../browse/component/Components";
// import { NoDataIcon } from "../../../../../../layout/assets";
import Container from "../../../../../../layout/Container";
import { useDispatch } from "react-redux";
import { onSaveInvestorFinanceRequest } from "../../../../../../redux/financing/financeActions";
import {
	FatBackTracker,
	UrlTracker,
} from "../../../../../../layout/CustomComponents";
import { MobileLeaseCartBtn } from "../../../../../leasing/marketplace/MarketPlace";
import { fetchRequestsForInvestors } from "../../../../../../redux/financing/financeServices";
import { networkErrorTrue } from "../../../../../../redux/authRedux/authActions";
import { toast } from "react-toastify";
import EmptyData from "../../../../../../components/EmptyData";

function AfInvestorMarketPlace() {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const theme = useTheme();
	const [searchQuery, setSearchQuery] = useState("");

	const [loading, setLoading] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [modal, setModal] = useState(false);

	const {
		investorMarketPlace,
		setInvestorMarketPlace,
		investorMarketForm,
		setInvestorMarketForm,
	} = useContext(userContext);

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredData = investorMarketPlace.filter((item) =>
		item.businessType
			.toLocaleString()
			.toLowerCase()
			.includes(searchQuery.toLowerCase())
	);

	const handleSelectFund = (item) => {
		setInvestorMarketForm(item);

		dispatch(onSaveInvestorFinanceRequest(item));
		// eslint-disable-next-line no-undef
		console.log("investorMarketForm:", investorMarketForm);

		navigate("/financing/new-transaction/investor-market-place/summary");
	};

	const handleDescriptionClick = (item) => {
		setSelectedItem(item);
		setModal(true);
	};

	const fetchInvestorMarketPlaceData = async () => {
		setLoading(true);
		try {
			const response = await fetchRequestsForInvestors();

			setInvestorMarketPlace(response);
		} catch (error) {
			if (error.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			}
			if (error?.message?.includes("Invalid session token")) {
				localStorage.clear();
				toast.error("This session has expired. Please Login again");
				setTimeout(() => {
					window.location.href = "/login";
				}, 500);
				return;
			} else {
				toast.error(error?.message);
			}
		} finally {
			setLoading(false);
		}
	};

	// Effect to fetch data from Parse
	useEffect(() => {
		fetchInvestorMarketPlaceData();
	}, []);

	return (
		<>
			<MobileNavDetails text="Finance" />
			<Container>
				<Helmet>
					<title>Fortcore - Finance</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="hidden sm:flex gap-1.5 sm:mt-10">
					<UrlTracker
						text="apprenticeship finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="new transaction"
						onNavigate={() => navigate("/financing/new-transaction")}
					/>
					<UrlTracker text="market place" isCurrentUrl />
				</div>
				<div className="mb-3 flex items-center justify-between sm:hidden zabalMedFont">
					<FatBackTracker
						text="investor market place"
						onNavigate={() => navigate("/financing/new-transaction")}
					/>
					<MobileLeaseCartBtn
						text="new request"
						onLeaseNavigate={() =>
							navigate("/financing/new-transaction/request")
						}
					/>
				</div>
				<div className="mt-8 sm:mt-10"></div>
				<Box
					sx={{ background: "#F6F7F8", padding: "20px", borderRadius: "8px" }}
				>
					<Box pb={5}>
						<Stack direction="row" spacing={2}>
							<TextField
								placeholder="Search"
								value={searchQuery}
								onChange={handleSearchChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end" aria-label="search">
												<BiSearch />
											</IconButton>
										</InputAdornment>
									),
								}}
								sx={{ width: "100%" }}
							/>
							<IconButton
								sx={{
									display: { xs: "none", sm: "flex" },
									flexDirection: "column",
									alignItems: "center",

									"&:hover": {
										background: "transparent",
									},
								}}
								onClick={() =>
									navigate("/financing/new-transaction/investor-create")
								}
							>
								<AiFillPlusCircle
									size={24}
									style={{ color: theme.palette.primary.main }}
								/>
								<Typography variant="subtitle2" color="primary.main">
									New Request
								</Typography>
							</IconButton>
							<IconButton
								sx={{
									display: { xs: "none", sm: "flex" },
									flexDirection: "column",
									alignItems: "center",
									"&:hover": {
										background: "transparent",
									},
								}}
							>
								<IoMdCart
									size={24}
									style={{ color: theme.palette.primary.main }}
								/>
								<Typography variant="subtitle2" color="primary.main">
									Cart
								</Typography>
							</IconButton>
						</Stack>
					</Box>

					{/* Content  */}
					{loading ? (
						<CustomFourGridBox>
							<Box
								sx={{
									background: theme.palette.secondary.main,
									border: "1px solid var(--grey-500, #B8C8CF)",
									borderRadius: "8px",
									padding: "20px",
								}}
							>
								<Stack spacing={1.5}>
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

									<Box>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Stack>
												<Skeleton variant="circular" width={40} height={40} />
											</Stack>

											<Skeleton variant="circular" width={40} height={40} />
										</Stack>
									</Box>
								</Stack>
							</Box>

							<Box
								sx={{
									background: theme.palette.secondary.main,
									border: "1px solid var(--grey-500, #B8C8CF)",
									borderRadius: "8px",
									padding: "20px",
								}}
							>
								<Stack spacing={1.5}>
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

									<Box>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Stack>
												<Skeleton variant="circular" width={40} height={40} />
											</Stack>

											<Skeleton variant="circular" width={40} height={40} />
										</Stack>
									</Box>
								</Stack>
							</Box>

							<Box
								sx={{
									background: theme.palette.secondary.main,
									border: "1px solid var(--grey-500, #B8C8CF)",
									borderRadius: "8px",
									padding: "20px",
								}}
							>
								<Stack spacing={1.5}>
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

									<Box>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Stack>
												<Skeleton variant="circular" width={40} height={40} />
											</Stack>

											<Skeleton variant="circular" width={40} height={40} />
										</Stack>
									</Box>
								</Stack>
							</Box>

							<Box
								sx={{
									background: theme.palette.secondary.main,
									border: "1px solid var(--grey-500, #B8C8CF)",
									borderRadius: "8px",
									padding: "20px",
								}}
							>
								<Stack spacing={1.5}>
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
									<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

									<Box>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Stack>
												<Skeleton variant="circular" width={40} height={40} />
											</Stack>

											<Skeleton variant="circular" width={40} height={40} />
										</Stack>
									</Box>
								</Stack>
							</Box>
						</CustomFourGridBox>
					) : (
						<>
							{investorMarketPlace.length > 0 ? (
								<>
									{filteredData.length > 0 ? (
										<CustomFourGridBox>
											{filteredData.map((item, index) => (
												<Box key={index}>
													<Box
														sx={{
															background: theme.palette.secondary.main,
															border: "1px solid var(--grey-500, #B8C8CF)",
															borderRadius: "8px",
															// width: { xs: "100%", sm: "200px" },
															width: "100%",
														}}
													>
														<Stack spacing={2}>
															<Box
																sx={{
																	width: "100%",
																	height: "218px",
																}}
															>
																<img
																	src={
																		item?.productPhoto
																			? item?.productPhoto[0]?.url
																			: item?.productPhotos[0]?.url
																	}
																	alt=""
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "cover",
																		borderRadius: "8px 8px 0 0",
																	}}
																/>
															</Box>

															<Box p={1}>
																<Stack spacing={2}>
																	<Typography variant="h4" color="primary">
																		NGN {item.amount}
																	</Typography>
																	<Divider />
																	<Typography
																		variant="h4"
																		color="primary"
																		sx={{ textTransform: "capitalize" }}
																	>
																		{item.businessType}
																	</Typography>
																	<Typography
																		variant="body1"
																		color="textSecondary"
																	>
																		Interest: {item.interest}
																	</Typography>
																	<Typography
																		variant="body1"
																		color="textSecondary"
																		sx={{ textTransform: "capitalize" }}
																	>
																		Tenure: {item.tenure}
																	</Typography>

																	<Stack
																		direction="row"
																		alignItems="center"
																		justifyContent="space-between"
																	>
																		<Button
																			variant="text"
																			onClick={() =>
																				handleDescriptionClick(item)
																			}
																		>
																			<Stack alignItems="center">
																				<BsInfoCircleFill
																					size={20}
																					color="#AABDC5"
																				/>
																				Description
																			</Stack>
																		</Button>
																		<Button
																			variant="text"
																			onClick={() => handleSelectFund(item)}
																		>
																			<Stack alignItems="center">
																				<AiFillPlusCircle
																					size={20}
																					style={{
																						color: theme.palette.primary.main,
																					}}
																				/>
																				Fund Request
																			</Stack>
																		</Button>
																	</Stack>
																</Stack>
															</Box>
														</Stack>
													</Box>
												</Box>
											))}
										</CustomFourGridBox>
									) : (
										<div className="w-full h-[270px] sm:h-[300px] grid place-items-center">
											<div className="grid place-items-center mb-6">
												<PiSmileySadDuotone size={35} />
												<Typography variant="body1" color="textSecondary">
													What you are looking for is not available
												</Typography>
											</div>
										</div>
									)}
								</>
							) : (
								<div className="w-full h-[270px] sm:h-[300px]">
									<EmptyData text="No request is available for funding. Check back later." />
								</div>
							)}
						</>
					)}
				</Box>
			</Container>

			{modal && (
				<MarketPlaceDescription
					open={modal}
					close={() => setModal(false)}
					selectedItem={selectedItem}
				/>
			)}
		</>
	);
}

export default AfInvestorMarketPlace;
