import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { ProfileInput } from "../../../profile/sections/PersonalInfo";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import Parse from "../../../../api/ApiConfig";
import { saveContactInformation } from "../../../../redux/travels/travelServices";

const ContactInformation = () => {
	const dispatch = useDispatch();
	const { currentUser } = useSelector((state) => state.auth);
	const { contactInfoLoading, contactInfoSuccess } = useSelector(
		(state) => state.travel
	);

	const [contactInfo, setContactInfo] = useState({
		user_phone: contactInfoSuccess?.phone || currentUser?.phone || "",
		user_email: contactInfoSuccess?.email || currentUser?.phone || "",
		residential_address:
			contactInfoSuccess?.residentialAddress || currentUser?.homeAddress || "",
		permanent_address: contactInfoSuccess?.permanentAddress || "",
		kin_name: contactInfoSuccess?.nextOfKin?.name || "",
		kin_phone: contactInfoSuccess?.nextOfKin?.phone || "",
		kin_relationship: contactInfoSuccess?.nextOfKin?.relationship || "",
		kin_email: contactInfoSuccess?.nextOfKin?.email || "",
		kin_address: contactInfoSuccess?.nextOfKin?.address || "",
	});

	const {
		user_phone,
		user_email,
		residential_address,
		permanent_address,
		kin_name,
		kin_address,
		kin_email,
		kin_phone,
		kin_relationship,
	} = contactInfo;

	const handleChange = (e) =>
		setContactInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const isBtnDisabled = () => {
		if (
			user_phone === "" ||
			user_email === "" ||
			residential_address === "" ||
			permanent_address === "" ||
			kin_name === "" ||
			kin_address === "" ||
			kin_email === "" ||
			kin_phone === "" ||
			kin_relationship === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onContactInfoSave = () => {
		const data = {
			userId: user,
			phone: user_phone,
			email: user_email,
			residentialAddress: residential_address,
			permanentAddress: permanent_address,
			nextOfKin: {
				name: kin_name,
				email: kin_email,
				relationship: kin_relationship,
				address: kin_address,
				phone: kin_phone,
			},
		};
		dispatch(saveContactInformation(data));
	};

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<label
					htmlFor="phone"
					className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5 text-[#393F42]"
				>
					Phone Number:
				</label>
				<div className="relative w-full sm:w-[70%] md:w-4/5">
					<PhoneInput
						country={"ng"}
						inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded text-[#393F42] !border-[#B8B8B8] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green border-[#B8B8B8] zabalRegFont"
						containerClass="!text-[12.5px] lg:!text-[13px] !w-full rounded !h-[44px] md:!h-[45px] !border-[#B8B8B8] zabalRegFont focus:!border-2 focus:!border-fortcore-green"
						value={user_phone}
						onChange={(user_phone) =>
							setContactInfo({
								...contactInfo,
								user_phone,
							})
						}
						inputProps={{ maxLength: 19, id: "phone" }}
					/>
				</div>
			</div>
			<ProfileInput
				type="email"
				text="Email Address"
				value={user_email}
				id="user_email"
				placeholderText="Enter email address"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="Residential Address"
				value={residential_address}
				id="residential_address"
				placeholderText="Enter residential address"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="Permanent Address"
				value={permanent_address}
				id="permanent_address"
				placeholderText="Enter permanent address"
				handleChange={handleChange}
			/>
			<p className="text-[13px] md:text-sm text-fortcore-green zabalMedFont">
				Next of Kin
			</p>
			<ProfileInput
				type="text"
				text="Name of Next of Kin"
				value={kin_name}
				id="kin_name"
				placeholderText="Enter name of next of kin"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="relationship"
				value={kin_relationship}
				id="kin_relationship"
				placeholderText="Provide relationship with next of kin"
				handleChange={handleChange}
			/>
			<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<label
					htmlFor="kin_phone"
					className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
				>
					Phone Number:
				</label>
				<div className="relative w-full sm:w-[70%] md:w-4/5">
					<PhoneInput
						country={"ng"}
						inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded text-[#393F42] !border-[#B8B8B8] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green border-[#B8B8B8] zabalRegFont"
						containerClass="!text-[12.5px] lg:!text-[13px] !w-full rounded !h-[44px] md:!h-[45px] !border-[#B8B8B8] zabalRegFont focus:!border-2 focus:!border-fortcore-green"
						value={kin_phone}
						onChange={(kin_phone) =>
							setContactInfo({
								...contactInfo,
								kin_phone,
							})
						}
						inputProps={{
							maxLength: 19,
							id: "kin_phone",
						}}
					/>
				</div>
			</div>
			<ProfileInput
				type="text"
				text="email address"
				value={kin_email}
				id="kin_email"
				placeholderText="Enter next of kin email"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="address"
				value={kin_address}
				id="kin_address"
				placeholderText="Enter next of kin address"
				handleChange={handleChange}
			/>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || contactInfoLoading}
					isLoading={contactInfoLoading}
					onRequestClick={onContactInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || contactInfoLoading}
					isLoading={contactInfoLoading}
					onRequestClick={onContactInfoSave}
				/>
			</div>
		</div>
	);
};

export default ContactInformation;
