import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import RouterPage from "./router/RouterPage";
import "react-circular-progressbar/dist/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJs } from "chart.js/auto";
import theme from "./components/mui/theme";
import { ThemeProvider } from "@mui/material/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Parse from "./api/ApiConfig";

function App() {
	let idleTimer;
	const currentUser = Parse.User.current();

	useEffect(() => {
		AOS.init();
		// eslint-disable-next-line no-undef
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (window.location.pathname === "/login") {
			return;
		}
		// Check if currentUser exists
		const resetIdleTimer = () => {
			// eslint-disable-next-line no-undef
			clearTimeout(idleTimer);
			// eslint-disable-next-line no-undef
			idleTimer = setTimeout(logout, 5 * 60 * 1000);
		};

		const logout = () => {
			console.log(2);
			Parse.User.logOut();
			// eslint-disable-next-line no-undef
			localStorage.clear();
			if (window.location.href.includes("/partial-funded-asset")) {
				window.location.reload();
			} else {
				window.location.href = "/login";
			}
		};

		// eslint-disable-next-line no-undef
		document.addEventListener("mousemove", resetIdleTimer);
		// eslint-disable-next-line no-undef
		document.addEventListener("keydown", resetIdleTimer);

		resetIdleTimer();

		return () => {
			// eslint-disable-next-line no-undef
			document.removeEventListener("mousemove", resetIdleTimer);
			// eslint-disable-next-line no-undef
			document.removeEventListener("keydown", resetIdleTimer);
		};
	}, [currentUser]);
	return (
		<div className="max-w-[1440px] mx-auto">
			<ThemeProvider theme={theme}>
				<RouterPage />
			</ThemeProvider>
			<ToastContainer limit={2} autoClose={3000} />
		</div>
	);
}

export default App;
