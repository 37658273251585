import React, { useContext, useEffect, useState } from "react";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import { Helmet } from "react-helmet";
import Navbar from "../../../layout/Navbar";
import Background from "../../../components/mui/Background";
import {
	Box,
	Button,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import theme from "../../../components/mui/theme";
// import baseURL from "../../../api/UrlConfig";
// import axios from "axios";
import Parse from "../../../api/ApiConfig";

import { NoDataIcon } from "../../../layout/assets";
import OngoingCard from "./components/OngoingCard";
import { AnyModal } from "../newTransaction/investor/create/InvestorCreateAF";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import BrowseFormContext from "../newTransaction/browse/BrowseFormContext";
import userContext from "../../../context/UserContext";
import { deleteOngoingAf } from "../../../redux/financing/financeServices";
import { toast } from "react-toastify";
import { Preloader } from "../../../components/globalStyled";
import { useDispatch } from "react-redux";
import { onSaveUserFinanceRequest } from "../../../redux/financing/financeActions";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";

function OngoingTransactions() {
	const navigate = useNavigate();
	const [OngoingData, setOngoingData] = useState([]);
	const [continueModal, setContinueModal] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [fetching, setFetching] = useState(true);
	const { setMarketPlace, setBusinessType } = useContext(BrowseFormContext);
	const { setRequestFinance } = useContext(userContext);

	const dispatch = useDispatch();

	useEffect(() => {
		const handleOngoingTransactions = async () => {
			const currentUser = Parse.User.current();

			if (currentUser) {
				const query = new Parse.Query("ApprenticeshipFinance");
				query.equalTo("requestStatus", "ongoing");
				query.equalTo("user", currentUser);
				query.descending("updatedAt");

				try {
					const ongoing = await query.find();
					const ongoingJSON = ongoing.map((item) => item.toJSON());
					// eslint-disable-next-line no-undef
					console.log(ongoingJSON);
					setOngoingData(ongoingJSON);
					setFetching(false);
				} catch (error) {
					// eslint-disable-next-line no-undef
					console.log(error);
				}
			} else {
				Parse.User.logOut();
			}
		};

		handleOngoingTransactions();
	}, []);

	const handleContinue = (data) => {
		setContinueModal(true);
		setSelectedData(data);
		setBusinessType("");
	};

	// console.log(selectedData);

	const handleEditScreen = () => {
		dispatch(onSaveUserFinanceRequest(selectedData));
		if (selectedData.transaction === "market") {
			setMarketPlace({
				objectId: selectedData.objectId,
				formStep: 1,
				amount: selectedData.amount,
				fee: selectedData.commitmentFee,
				expectedProfit: selectedData.expectedProfit,
				interestAmount: selectedData.interest,
				programSavings: selectedData.programSavings,
				businessDescription: selectedData.businessDescription,
				tenure: selectedData.tenure,
				purpose: "",
				businessType: selectedData.businessType,
				durationText: "",
				durationTime: "",

				proofOfPurchase: selectedData.proofOfPurchase,
				businessProofFile: selectedData.proofOfPurchaseFile,
				attachmentFile: selectedData.attachmentFile,
				images: [],
				productPhoto: selectedData.productPhotos,

				idCard: {},
				utilityBill: {},
				duration: "",
				type: "",
				marketDetails: "",
				manualProof: "",
				imageURL: "",
				imageProofUpload: "",
				attachmentFileUpload: "",
				imagesUpload: [],
				signature: "",
				autoDebit: false,
				insurance: false,
				paymentType: false,
				guarantor: false,
			});
			navigate("/financing/ongoing-transactions/market-finance");
		} else if (selectedData.transaction === "request") {
			setRequestFinance({
				formStep: 2,
				objectId: selectedData.objectId,
				amount: selectedData.amount,
				businessInterest: "",
				interest: selectedData.interest,
				tenure: selectedData.tenure,
				commitmentFee: selectedData.commitmentFee,
				expectedProfit: selectedData.expectedProfit,
				durationText: "",
				durationTime: "",
				programSavings: selectedData.programSavings,
				programSavingsRate: "",
				type: selectedData.quick,
				businessType: selectedData.businessType,
				businessDescription: selectedData.businessDescription,
				purpose: selectedData.purpose,
				marketDetails: "",
				interestRate: "",

				proofOfPurchase: selectedData.proofOfPurchase,
				businessProofFile: selectedData.proofOfPurchaseFile,
				attachmentFile: selectedData.attachmentFile,
				productPhoto: selectedData.productPhotos,

				idCard: {
					name: "",
				},
				utilityBill: {
					name: "",
				},
				autoDebit: false,
				insurance: false,
				guarantor: false,
				paymentType: "",

				signature: "",
			});
			navigate("/financing/ongoing-transactions/request-finance");
		}
	};

	const [deleteLoading, setDeleteLoading] = useState(false);

	const handleDeleteOngoingAf = async () => {
		const id = selectedData?.objectId;

		try {
			setDeleteLoading(true);
			const response = await deleteOngoingAf(id);

			if (response) {
				toast.success(`Request with id ${id} has been deleted successfully`);

				setTimeout(() => {
					window.location.reload();
				}, 500);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setDeleteLoading(false);
		}
	};

	// eslint-disable-next-line no-undef
	// console.log(selectedData, OngoingData);
	return (
		<>
			<MobileNavDetails text="Ongoing Transactions" />
			<Container>
				<Helmet>
					<title>Fortcore - Ongoing Transactions</title>
				</Helmet>
				<Navbar text="Ongoing Transactions" />
				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="hidden sm:flex gap-1.5">
					<UrlTracker
						text="apprenticeship Finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker text="ongoing transactions" isCurrentUrl />
				</div>
				<BackTracker
					text="apprenticeship finance"
					onNavigate={() => navigate("/financing")}
				/>
				<Background>
					<Box p={{ xs: 2, sm: 4 }}>
						<Stack spacing={1}>
							<Typography variant="h2" color={theme.palette.primary.main}>
								Ongoing Transactions
							</Typography>
							{/* <Typography variant="body2" color={theme.palette.info.light}>
                Transaction ID: AC308495202121
              </Typography> */}
						</Stack>

						<Stack spacing={2} mt={3}>
							{fetching ? (
								<>
									<Skeleton variant="rounded" width="100%" height={241} />
									<Skeleton variant="rounded" width="100%" height={241} />
									<Skeleton variant="rounded" width="100%" height={241} />
								</>
							) : (
								<>
									{OngoingData.length > 0 ? (
										<>
											{OngoingData.map((data, index) => (
												<OngoingCard
													key={index}
													title={data.businessType}
													asset={data.amount}
													interest={data.interest}
													tenure={data.tenure}
													image={data.idCard}
													onClick={() => handleContinue(data)}
													businessType={data.businessType}
													objectId={data.objectId}
													firstImage={data?.productPhotos[0]}
												/>
											))}
										</>
									) : (
										<Box
											height="50vh"
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<NoDataIcon />
											<Typography mt={2} color={theme.palette.secondary.dark}>
												You have no ongoing transaction
											</Typography>
										</Box>
									)}
								</>
							)}
						</Stack>
					</Box>
				</Background>
			</Container>

			<AnyModal open={continueModal}>
				<Box>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography align="center" variant="h2" color="primary">
							Continue
						</Typography>
						<IconButton onClick={() => setContinueModal(false)}>
							<GrFormClose size={30} />
						</IconButton>
					</Stack>

					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						gap={2}
						mt={2}
					>
						<Button variant="contained" onClick={handleEditScreen} fullWidth>
							Edit &nbsp;
							<AiOutlineEdit />
						</Button>
						<button
							className="w-full bg-red-600 text-white zabalMedFont rounded-md py-3 flex items-center justify-center"
							onClick={handleDeleteOngoingAf}
							// sx={{ background: "red", color: "white" }}
						>
							{deleteLoading ? (
								<Preloader />
							) : (
								<>
									Delete &nbsp;
									<AiOutlineDelete />
								</>
							)}
						</button>
					</Stack>
				</Box>
			</AnyModal>
		</>
	);
}

export default OngoingTransactions;
