import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { onTogglePayCommitmentFeeModal } from "../../../../redux/financing/financeActions";
import {
	fetchSubmittedRequestsCopy,
	updateOngoingReq,
} from "../../../../redux/financing/financeServices";
import { NoRequestFound } from "../../../leasing/submittedtransactions/LeaseAgreement";
import { BackTracker, UrlTracker } from "../../../../layout/CustomComponents";
import NetworkError from "../../../../components/networkerror/NetworkError";
import { networkErrorTrue } from "../../../../redux/authRedux/authActions";
import { MobileNavDetails } from "../../../../layout/MobileNavbar";
import ModalContainer from "../../../../components/ModalContainer";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import { Preloader } from "../../../../components/globalStyled";
import Container from "../../../../layout/Container";
import PayCommitmentFee from "./PayCommitmentFee";
import Navbar from "../../../../layout/Navbar";
// import Parse from "../../../../api/ApiConfig";

const FinanceAgreement = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const { networkError, currentUser } = useSelector((state) => state.auth);
	const { togglePayCommitmentFeeModal } = useSelector((state) => state.finance);

	const userName = `${currentUser?.firstName} ${currentUser?.lastName}`;

	const [signature, setSignature] = useState(userName || "");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);

	const [saveAgreementLoading, setSaveAgreementLoading] = useState(false);

	const fetchRequestWithQuery = async () => {
		try {
			setError(null);
			setLoading(true);

			const res = await fetchSubmittedRequestsCopy();

			const actualData = res.find((req) => req?.objectId === params.id);

			setData(actualData);
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				dispatch(networkErrorTrue("Network Error"));
			}
			if (error?.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			} else if (error?.message?.includes("Invalid session token")) {
				toast.error("This session has expired. Please login.");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			} else {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	};

	const onProceedToLeaseAgreement = () => {
		if (data?.type === "quick") {
			if (
				data?.requestType === "new" &&
				data?.basicAdminApproval?.status?.includes("Approv") &&
				data?.firstLineApproval?.status?.includes("Approv") &&
				data?.secondLineApproval?.status?.includes("Approv") &&
				data?.finalApproval?.status?.includes("Approv") &&
				data?.financeApproval?.status?.includes("Approv") &&
				data?.funded
			) {
				return false;
			} else if (
				data?.requestType === "old" &&
				data?.basicAdminApproval?.status?.includes("Approv") &&
				data?.firstLineApproval?.status?.includes("Approv") &&
				data?.secondLineApproval?.status?.includes("Approv") &&
				data?.finalApproval?.status?.includes("Approv")
			) {
				return false;
			} else {
				return true;
			}
		} else if (data?.requestType === "old" && data?.type === "sponsored") {
			if (data?.approvalStatus === "approved") {
				return false;
			} else {
				return true;
			}
		} else if (data?.requestType === "new" && data?.type === "sponsored") {
			if (data?.funded) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const financeAgreement = { signature, date: new Date().toISOString() };

	const onUpdateRequests = async () => {
		const data = {
			financeAgreement,
		};

		const message = "Finance Agreement Signed";

		const info = `You have signed the finance agreement form. The funds will be disbursed to your account soonest`;

		try {
			setSaveAgreementLoading(true);

			const response = await updateOngoingReq(
				params.id,
				data,
				"ApprenticeshipFinance",
				message,
				info
			);

			if (response) {
				toast.success("Commitment fee payment is successful");

				setTimeout(() => {
					navigate("/financing/submitted-transactions");
				}, 500);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setSaveAgreementLoading(false);
		}
	};

	const onSubmit = () => {
		console.log(data);
		if (onProceedToLeaseAgreement()) {
			toast.error("You have not been authorized to sign this aggreement yet.");
			return;
		}
		if (data?.requestType === "new") {
			onUpdateRequests();
		} else {
			dispatch(onTogglePayCommitmentFeeModal(true, data));
		}
	};

	useEffect(() => {
		setSignature(
			data?.financeAgreement
				? data?.financeAgreement?.signature
				: userName
				? userName
				: ""
		);
	}, [data]);

	useEffect(() => {
		fetchRequestWithQuery();
	}, [params.id]);

	return (
		<>
			{networkError && <NetworkError />}
			{loading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}

			{togglePayCommitmentFeeModal && (
				<ModalContainer modal={togglePayCommitmentFeeModal}>
					<PayCommitmentFee request={data} agreement={financeAgreement} />
				</ModalContainer>
			)}

			<Helmet>
				<title>Fortcore - Finance Agreement</title>
			</Helmet>
			<MobileNavDetails text="submitted transactions" />
			<Container>
				<>
					<Navbar text="submitted transactions" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="financing"
							onNavigate={() => navigate("/financing")}
						/>
						<UrlTracker
							text="submitted transactions"
							onNavigate={() => navigate("/financing/submitted-transactions")}
						/>
						<UrlTracker text="finance agreement" isCurrentUrl />
					</div>
					<BackTracker
						text="finance agreement"
						onNavigate={() => navigate(-1)}
					/>
					<div className="sm:my-6"></div>
					{error ? (
						<NoRequestFound />
					) : (
						<div className="rounded-md p-4 sm:p-5 lg:p-6 bg-[#F6F7F8] space-y-4 sm:space-y-5">
							<p className="text-sm capitalize text-fortcore-green zabalMedFont">
								finance agreement
							</p>
							<div className="space-y-1.5 text-[14.5px] text-justify">
								<p className="leading-7">
									This Document (this &quot;Agreement&quot;) is executed and
									delivered as of the date of execution by undersigned,
									(hereinafter referred to as &quot;Investor&quot;). For and in
									consideration of the mutual covenants contained herein, and
									other good and valuable consideration, the receipt and
									sufficiency of which is hereby acknowledged, Investor hereby
									constitutes and appoints Fortcore, a technology company, as
									Investor&apos;s true and lawful attorney-in-fact and agent,
									with full power of substitution and resubstitution, for
									Investor and in Investor&apos;s name, place and stead, in any
									and all capacities, to perform the following acts and things:
								</p>
								<ol className="list-decimal px-4 text-sm leading-6">
									<li>
										To transact all business and sign, execute, acknowledge and
										deliver all such instruments, agreements, and documents, and
										to take all such actions, as may be necessary or appropriate
										to effect the transactions described in this Agreement.
									</li>
									<li>
										To access, monitor and control Investor&apos;s portfolio of
										investments, including, without limitation, the power to buy
										and sell securities, transfer assets, and make decisions
										regarding portfolio management.
									</li>
									<li>
										To exercise all voting rights associated with the securities
										in Investor&apos;s portfolio.
									</li>
								</ol>
								<p className="leading-7">
									This power of attorney shall be binding upon Investor and
									shall inure to the benefit of Investor&apos;s heirs,
									executors, administrators, successors, and assigns. Investor
									agrees that any person, corporation, or entity which relies
									upon the acts of Fortcore hereunder shall be fully protected
									and indemnified in relying upon the same.
								</p>
								<p className="leading-7">
									This power of attorney shall remain in full force and effect
									until revoked by Investor. Investor may revoke this power of
									attorney at any time by written notice to Fortcore.
								</p>
								<p className="leading-7">
									<span className="zabalBoldFont">IN WITNESS WHEREOF,</span>{" "}
									Investor has executed this Agreement as of the date first
									above written.
								</p>
							</div>
							<div className="pt-5 w-[300px] space-y-1 relative">
								<input
									type="text"
									className={`bg-transparent px-1 placeholder:italic text-black text-[15px] w-full focus:border-fortcore-green pr-[125px] ${
										signature
											? "border border-[#96A0A5] !text-[#B8B8B8] italic p-2 px-3 rounded"
											: "border-b-2"
									}`}
									placeholder="Please type your name"
									value={signature || userName}
									onChange={(e) => setSignature(e.target.value)}
									disabled={true}
								/>
								<p
									className={`absolute right-3 text-sm zabalMedFont ${
										signature
											? "italic text-[#B8B8B8] top-[27px]"
											: "top-[18px]"
									}`}
								>
									{data?.financeAgreement
										? new Date(`${data?.financeAgreement?.date}`).toDateString()
										: new Date().toDateString()}
								</p>
								<p className="text-[13px] md:text-sm zabalMedFont text-center">
									[Signature]
								</p>
							</div>
							<div className="hidden sm:flex justify-end">
								<CustomGreenBtn
									text="accept"
									width="95px"
									height="42px"
									fontSize="14.5px"
									onRequestClick={onSubmit}
									isDisabled={
										!!data?.financeAgreement?.signature || saveAgreementLoading
									}
									isLoading={saveAgreementLoading}
								/>
							</div>
							<div className="sm:hidden py-2">
								<CustomGreenBtn
									text="accept"
									fontSize="14px"
									onRequestClick={onSubmit}
									isDisabled={
										!!data?.financeAgreement?.signature || saveAgreementLoading
									}
									isLoading={saveAgreementLoading}
								/>
							</div>
						</div>
					)}
				</>
			</Container>
		</>
	);
};

export default FinanceAgreement;
