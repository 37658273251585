import React, { useEffect, useState } from "react";
import { MdClose, MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { onToggleApplyInsuranceModal } from "../../redux/lease/leaseActions";
import Container from "../../layout/Container";
import {
	CustomConfirmModal,
	CustomHeaderWithCloseBtn,
	PlainSelect,
	SelectDropDown,
} from "../../layout/CustomComponents";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import {
	formatAmountToIncludeCurrency,
	// getIdType,
	getLeaseId,
	// getVehicleType,
	// removeCountryCode,
	stateAndLga,
	walletType,
} from "../../common/utils";
import PaymentOption from "../../components/payments/PaymentOption";
import { EnterPin } from "../../components/payments/PinComponent";
import FileUpload from "../../components/FileUpload";
import { useUploadFile } from "../../components/customhooks/useUploadFile";
import {
	getCompanyInsuranceInfo,
	// investortApplyForInsurance,
	updateAssetOnRegistration,
	updateLeaseOnRegistration,
} from "../../redux/lease/leaseServices";
import { Preloader } from "../../components/globalStyled";
import useParameters from "../../components/customhooks/useParameters";
import { deductPaymentFromUserWallet } from "../../redux/payments/paymentServices";

const InsuranceApplication = ({ requestData, activeTab, setActiveTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const data = useParameters();

	const insuranceRate = data
		?.filter((p) =>
			p?.parameter?.toLocaleLowerCase().includes("insurance rate")
		)
		?.find((a) => !!a?.value);

	const { currentUser } = useSelector((state) => state.auth);
	const {
		customerWallet,
		walletDetails,
		deductWalletLoading,
		deductWalletSuccess,
	} = useSelector((state) => state.payment);

	// const { insuranceLoading, insuranceSuccess } = useSelector(
	// 	(state) => state.lease
	// );

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");
	const [info, setInfo] = useState({
		companyInfo: null,
		loading: false,
	});

	const accessPin = currentUser?.accessPin;

	const leaseId = getLeaseId(requestData);

	const isSingleInvestor = requestData?.investors?.length === 1;

	const formattedInsuranceFee = Number(insuranceRate?.value?.replace("%", ""));

	const insuranceFee =
		(formattedInsuranceFee / 100) *
		Number(requestData?.productObj?.amount?.replaceAll(",", ""));

	const [insuranceData, setInsuranceData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		gender: "",
		dob: "",
		occupation: "",
		street: "",
		streetNumber: "",
		country: "Nigeria",
		state: "",
		city: "",
		idType: "",
		idNumber: "",
		expiryDate: "",
		idCard: null,
		utilityBill: null,
		postalCode: "---",
	});

	const [submitLoading, setSubmitLoading] = useState(null);

	const actualFee = isSingleInvestor
		? insuranceFee
		: insuranceFee / requestData?.investors?.length;

	// console.log(requestData);

	const [docs, setDocs] = useState({
		idCard: null,
		utilityBill: null,
	});

	const [toggleState, setToggleState] = useState(false);

	const {
		firstName,
		lastName,
		email,
		phone,
		gender,
		occupation,
		street,
		streetNumber,
		dob,
		country,
		state,
		city,
		idType,
		idNumber,
		expiryDate,
		idCard,
		utilityBill,
		postalCode,
	} = insuranceData;

	const getPostalCode = () => {
		const selectedState = stateAndLga.find((d) => d.state === state);
		if (!selectedState) {
			return;
		} else {
			setInsuranceData((prev) => ({
				...prev,
				postalCode: selectedState?.postalCode,
			}));
		}
	};

	const handleChange = (e) => {
		if (e.target.id === "phone" && isNaN(e.target.value)) {
			return;
		}
		setInsuranceData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
	};

	const { loading: idCardLoading, fileSuccess: idCardSuccess } = useUploadFile(
		docs.idCard
	);

	const { loading: utilityLoading, fileSuccess: utilitySuccess } =
		useUploadFile(docs.utilityBill);

	const handleFileUpload = (e) =>
		setDocs((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));

	// FETCH COMPANY DETAILS ON MULTIPLE INVESTORS
	const fetchCompanyInfo = async () => {
		setInfo((prev) => ({ ...prev, loading: true }));
		try {
			const response = await getCompanyInsuranceInfo();

			if (response) {
				setInfo((prev) => ({
					...prev,
					companyInfo: response?.companyInsuranceInfo,
				}));
			} else {
				toast.error("Insurance information is not available.");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setInfo((prev) => ({ ...prev, loading: false }));
		}
	};

	const insuranceDetails = {
		...insuranceData,
		address: isSingleInvestor
			? `${streetNumber}, ${street}, ${city}, ${state} ${country}`
			: info?.companyInfo?.address,
	};

	const onBeginInsurance = () => {
		if (!insuranceRate) {
			toast.error(
				"Insurance fee is not available yet. Please check back later"
			);
			return;
		} else if (requestData?.investors?.length > 1 && !info.companyInfo) {
			toast.error(
				"Company information is not available yet. Please check back later"
			);
			return;
		} else if (!street || !streetNumber || !occupation || !state || !city) {
			toast.error("All fields are required");
			return;
		} else if (!requestData?.vehicleRegDetails?.registrationNum) {
			toast.error("Vehicle registration number is not available yet.");
			return;
		} else {
			// const dataToSubmit = {
			// 	firstName,
			// 	lastName,
			// 	dob: moment(`${dob}`, "DD-MM-YYYY").format("YYYY-MM-DD"),
			// 	gender: gender?.toLowerCase(),
			// 	phone: removeCountryCode(phone),
			// 	email,
			// 	idCardImgUrl: idCard?.fileUrl || idCard?.url,
			// 	utilityImgUrl: utilityBill?.fileUrl || utilityBill?.url,
			// 	address: insuranceDetails?.address,
			// 	number: idNumber,
			// 	expiry: moment(`${expiryDate}`, "DD-MM-YYYY").format("YYYY-MM-DD"),
			// 	type: getIdType(idType),
			// 	occupation,
			// 	city,
			// 	state,
			// 	country,
			// 	street,
			// 	streetNumber,
			// 	postCode: postalCode,
			// 	vehicleMake: requestData?.productObj?.brand,
			// 	vehicleModel: requestData?.productObj?.model,
			// 	vehicleYear: requestData?.productObj?.year,
			// 	vehicleChassis: requestData?.vehicleRegDetails?.chassisNum,
			// 	vehicleRegNo: requestData?.vehicleRegDetails?.registrationNum,
			// 	vehicleEngNo: requestData?.vehicleRegDetails?.engineNum,
			// 	vehicleValue: Number(vehicleValue?.replaceAll(",", "")),
			// 	vehicleUse:
			// 		requestData?.leaseStructure === "Operating Lease"
			// 			? "Commercial"
			// 			: "Private",
			// 	vehicleType: getVehicleType(requestData?.productObj?.subCategory),
			// };
			setActiveTab(2);
		}
	};

	const onPayForInsurance = () => {
		if (!insuranceRate) {
			toast.error(
				"Insurance fee is not available yet. Please check back later"
			);
			return;
		}
		if (mainWallet?.availableBalance < actualFee) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const details = {
			walletId: walletDetails?.id,
			amount: process.env.NODE_ENV !== "development" ? actualFee : 1,
			narration: "asset-insurance-fee",
		};

		dispatch(
			deductPaymentFromUserWallet(
				details,
				"insurance-fee-payment-successful",
				"insurance application"
			)
		);
	};

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	useEffect(() => {
		getPostalCode();
	}, [state]);

	useEffect(() => {
		if (isSingleInvestor) {
			return;
		} else {
			fetchCompanyInfo();
		}
	}, []);

	const insurancePaymentInfo = {
		percentage: insuranceRate?.value,
		totalAmount: insuranceFee,
		investors: [
			...(requestData?.insurancePaymentInfo?.investors || []),
			{
				name: `${currentUser?.firstName} ${currentUser?.lastName}`,
				userId: currentUser?.objectId,
				amountPaid: actualFee,
				status: "paid",
				dateOfPayment: new Date().toISOString(),
			},
		],
	};

	const onUpdateLeaseReq = async (context, message, data, toastMsg) => {
		setSubmitLoading(true);
		try {
			const res = await updateLeaseOnRegistration(
				leaseId,
				data,
				context,
				message
			);

			if (res) {
				toast.success(toastMsg);
				console.log(res);
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (error) {
			toast.error(error);
		} finally {
			setSubmitLoading(false);
		}
	};

	// SET INSURANCE DATA STATE BASED ON NUMBER OF INVESTORS
	useEffect(() => {
		if (isSingleInvestor) {
			setInsuranceData({
				firstName: currentUser?.firstName,
				lastName: currentUser?.lastName,
				email: currentUser?.email,
				phone: currentUser?.phone,
				gender: currentUser?.gender,
				dob: currentUser?.dateOfBirth,
				occupation: currentUser?.businessProfile?.businessType,
				street: requestData?.insuranceDetails?.street || "",
				streetNumber: requestData?.insuranceDetails?.streetNumber || "",
				country: "Nigeria",
				state: requestData?.insuranceDetails?.state || "",
				city: requestData?.insuranceDetails?.city || "",
				idType: currentUser?.idType,
				idNumber: currentUser?.idNo,
				expiryDate: currentUser?.idExpiryDate?.iso,
				idCard: { fileName: currentUser?.idCard, fileUrl: currentUser?.idCard },
				utilityBill: {
					fileName: currentUser?.utilityBill,
					fileUrl: currentUser?.utilityBill,
				},
				postalCode: "---",
			});
		} else {
			setInsuranceData({
				firstName: info?.companyInfo?.firstName,
				lastName: info?.companyInfo?.lastName,
				email: info?.companyInfo?.email,
				phone: info?.companyInfo?.phone,
				gender: info?.companyInfo?.gender,
				dob: info?.companyInfo?.dob,
				occupation: info?.companyInfo?.occupation,
				street: info?.companyInfo?.street,
				streetNumber: info?.companyInfo?.streetNum,
				country: "Nigeria",
				state: info?.companyInfo?.state,
				city: info?.companyInfo?.city,
				idType: info?.companyInfo?.idType,
				idNumber: info?.companyInfo?.idNumber,
				expiryDate: info?.companyInfo?.expiryDate,
				idCard: info?.companyInfo?.cacDoc,
				utilityBill: info?.companyInfo?.utilityDoc,
				postalCode: info?.companyInfo?.postalCode,
			});
		}
	}, [info, isSingleInvestor]);

	// ON SUCCESSFUL INSURANCE PAYMENT, UPDATE REQUEST INFO
	useEffect(() => {
		if (deductWalletSuccess === "insurance-fee-payment-successful") {
			const context = "Payment has been successfully deducted for insurance.";
			const message = "Asset insurance Payment.";
			const toastMsg = "Payment has been successfully deducted for insurance.";

			onUpdateLeaseReq(
				context,
				message,
				{ insurancePaymentInfo, insuranceDetails },
				toastMsg
			);

			if (location.state?.isLeaseReq === undefined) {
				dispatch(
					updateAssetOnRegistration(requestData?.objectId, {
						insurancePaymentInfo,
						insuranceDetails,
					})
				);
			}
		}
	}, [deductWalletSuccess]);

	// ON SUCCESSFUL INSURANCE APPLICATION, UPDATE REQUEST INFO
	// useEffect(() => {
	// 	if (insuranceSuccess) {
	// 		console.log(insuranceSuccess);
	// 		const context = "Your asset has been successfully insurance.";
	// 		const message = "Asset insurance completed.";
	// 		const toastMsg = "Asset has been insured successfully.";

	// 		onUpdateLeaseReq(context, message, { insuranceDetails }, toastMsg);

	// 		if (location.state?.isLeaseReq === undefined) {
	// 			dispatch(
	// 				updateAssetOnRegistration(requestData?.objectId, {
	// 					insuranceDetails,
	// 				})
	// 			);
	// 		}
	// 	}
	// }, [insuranceSuccess]);

	return (
		<>
			{info?.loading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}
			<Container>
				{activeTab === 1 && (
					<div className="rounded-lg py-4 md:py-5 bg-[#F6F7F8] w-full sm:w-11/12 md:10/12 max-w-[500px] sm:max-w-[700px] md:max-w-[1100px] mx-auto">
						<CustomHeaderWithCloseBtn
							text="insurance application"
							onClick={() => dispatch(onToggleApplyInsuranceModal(false))}
							isPadding
						/>
						<p className="text-[12.75px] text-[#393F42] px-4 md:px-5">
							Fill in the below details to apply for insurance.
						</p>
						<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-x-4 md:gap-x-5 lg:gap-x-6 mt-5 sm:gap-y-4 lg:gap-y-5 h-[57vh] md:h-[55vh] max-h-[450px] overflow-y-auto scroll-wrapper px-4 md:px-5 w-full">
							<InputField
								label="firstName"
								labelText="First Name"
								value={firstName}
								isDisabled
							/>
							<InputField
								label="lastName"
								labelText="Last Name"
								value={lastName}
								isDisabled
							/>
							<InputField
								label="dob"
								labelText="Date Of Birth (DOB)"
								// type="date"
								value={moment(`${dob}`, "DD-MM-YYYY").format("MMMM Do, YYYY")}
								isDisabled
							/>
							<InputField
								label="gender"
								labelText="Gender"
								value={gender}
								isDisabled
							/>
							<InputField
								label="phone"
								labelText="Phone No."
								value={phone}
								isDisabled
							/>
							<InputField
								label="occupation"
								labelText="Occupation"
								value={occupation}
								isDisabled={!isSingleInvestor}
								handleChange={handleChange}
							/>
							<InputField
								label="email"
								labelText="Email Address"
								value={email}
								isDisabled
							/>
							<InputField
								label="streetNumber"
								labelText="Street Number"
								value={streetNumber}
								handleChange={handleChange}
								isDisabled={!isSingleInvestor}
								placeholder="Enter street number"
							/>
							<InputField
								label="street"
								labelText="Street"
								value={street}
								handleChange={handleChange}
								placeholder="Enter street name"
								isDisabled={!isSingleInvestor}
							/>
							<InputField
								label="city"
								labelText="City"
								value={city}
								handleChange={handleChange}
								isDisabled={!isSingleInvestor}
								placeholder="Enter city"
							/>
							{isSingleInvestor ? (
								<SelectField
									label="State"
									labelText="State"
									context="select state"
									currentText={state}
									handleToggle={() => setToggleState((prev) => !prev)}
									isOpened={toggleState}
									arrOptions={stateAndLga?.map((d) => d.state).sort()}
									handleSelect={(d) => {
										setInsuranceData((prev) => ({
											...prev,
											state: d,
										}));
										setTimeout(() => {
											setToggleState(false);
										}, 100);
									}}
								/>
							) : (
								<InputField
									label="state"
									labelText="State"
									value={state}
									isDisabled
								/>
							)}
							<InputField
								label="country"
								labelText="Country"
								value={country}
								isDisabled
							/>
							<InputField
								label="postalCode"
								labelText="Postal Code"
								value={postalCode}
								isDisabled
							/>
							<InputField
								label="idType"
								labelText="ID Type"
								value={idType}
								isDisabled
							/>
							<InputField
								label="idNumber"
								labelText="ID Number"
								value={idNumber}
								isDisabled
							/>
							<InputField
								label="expiryDate"
								labelText="Expirty Date"
								value={new Date(`${expiryDate}`).toDateString()}
								isDisabled
							/>
							{!isSingleInvestor ? (
								<div className="flex flex-col gap-2 text-[#393F42] zabalMedFont w-full">
									<label htmlFor="cac" className="text-xs md:text-[12.5px]">
										CAC
									</label>
									<FileUpload fileInfo={idCard} name="cac" isDisabled />
								</div>
							) : (
								<div className="flex flex-col gap-2 text-[#393F42] zabalMedFont  w-full">
									<label htmlFor="idCard" className="text-xs md:text-[12.5px]">
										ID Card
									</label>
									<FileUpload
										fileInfo={idCardSuccess || idCard}
										name="idCard"
										isLoading={idCardLoading}
										handleChange={handleFileUpload}
									/>
								</div>
							)}
							<div className="flex flex-col gap-2 text-[#393F42] zabalMedFont">
								<label htmlFor="cac" className="text-xs md:text-[12.5px]">
									Utility Bill
								</label>
								{!isSingleInvestor ? (
									<FileUpload
										fileInfo={utilityBill}
										name="utilityBill"
										isDisabled
									/>
								) : (
									<FileUpload
										fileInfo={utilitySuccess || utilityBill}
										name="utilityBill"
										isLoading={utilityLoading}
										handleChange={handleFileUpload}
									/>
								)}
							</div>
							<InputField
								label="vehicleRegNo"
								labelText="Vehicle Reg. No."
								value={requestData?.vehicleRegDetails?.registrationNum || "---"}
								isDisabled
							/>
						</div>
						<div className="px-4 md:px-5 my-3 sm:hidden">
							<CustomGreenBtn
								text="Apply"
								isLoading={submitLoading}
								isDisabled={submitLoading}
								onRequestClick={onBeginInsurance}
							/>
						</div>
						<div className="px-4 md:px-5 mb-3 mt-5 hidden sm:flex justify-end">
							<CustomGreenBtn
								text="Apply"
								isLoading={submitLoading}
								isDisabled={submitLoading}
								width="115px"
								height="40px"
								onRequestClick={onBeginInsurance}
							/>
						</div>
					</div>
				)}
				{activeTab === 2 && (
					<div className="rounded-lg p-4 md:p-5 bg-[#F6F7F8] w-11/12 max-w-[450px] mx-auto">
						<div className="flex items-center justify-between text-fortcore-green mb-1">
							<h3 className="text-md md:text-[18px] font-semibold chillaxFont">
								Payment For Asset Insurance
							</h3>
							<button
								onClick={() => dispatch(onToggleApplyInsuranceModal(false))}
							>
								<MdClose size={20} />
							</button>
						</div>
						<p className="text-[12.75px] text-[#393F42]">
							{insuranceRate?.value || "0%"} of asset value is to be paid for
							insurance.
						</p>
						<div className="mt-4 space-y-2">
							<div className="flex justify-between text-[13px]">
								<p className="zabalMedFont">Asset Value:</p>
								<p className="zabalRegFont">
									{formatAmountToIncludeCurrency(
										"NGN",
										requestData?.productObj?.amount
									)}
								</p>
							</div>
							<div className="flex justify-between text-[13px]">
								<p className="zabalMedFont">
									Insurance Fee ({insuranceRate?.value || "0%"}):
								</p>
								<p className="zabalRegFont">
									{formatAmountToIncludeCurrency("NGN", actualFee || 0)}
								</p>
							</div>
						</div>
						<div className="mt-10 flex justify-between items-center">
							<p className="text-[12.5px] text-[#717E83]">
								Proceed to make payment
							</p>
							<CustomGreenBtn
								text="pay"
								width="80px"
								height="39px"
								onRequestClick={() => setActiveTab(3)}
							/>
						</div>
					</div>
				)}
				{activeTab === 3 && (
					<PaymentOption
						onClose={() => setActiveTab(2)}
						onWalletSelect={() => setActiveTab(4)}
						onCardSelect={() => {}}
					/>
				)}
				{activeTab === 4 && (
					<CustomConfirmModal
						text={`The amount of N${actualFee} will be deducted from your wallet for insurance application.`}
						greenBtnText="proceed"
						onCloseBtnClick={() => dispatch(onToggleApplyInsuranceModal(false))}
						greenBtnClick={() => setActiveTab(5)}
					/>
				)}
				{activeTab === 5 && (
					<EnterPin
						btnText="pay now"
						onCloseBtnClick={() => dispatch(onToggleApplyInsuranceModal(false))}
						onInputChange={setInputPin}
						isPinInvalid={invalidPinError}
						isPinExist={accessPin !== undefined}
						isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
						proceedToCreatePin={() =>
							navigate("/dashboard/profile", { state: { currentTab: 2 } })
						}
						onRequestClick={onPayForInsurance}
						isPaymentLoading={deductWalletLoading || submitLoading}
					/>
				)}
			</Container>
		</>
	);
};

export default InsuranceApplication;

export const InputField = ({
	label,
	value,
	isDisabled,
	labelText,
	type,
	handleChange,
	placeholder,
}) => {
	return (
		<div className="flex flex-col gap-2 text-[#393F42] zabalMedFont relative w-full">
			<label htmlFor={label} className="text-xs md:text-[12.5px]">
				{labelText}
			</label>
			<input
				type={type ? type : "text"}
				className={`border rounded-md px-2.5 py-2 bg-transparent outline-none focus:border-2 focus:border-fortcore-green text-[13.5px] placeholder:text-[#AABDC5] ${
					isDisabled ? "border-[#AABDC5] pr-6" : "border-[#717E83]"
				}`}
				disabled={isDisabled}
				value={value}
				onChange={handleChange}
				id={label}
				placeholder={placeholder}
			/>
			{isDisabled && (
				<span className="absolute z-2 right-1.5 bottom-2.5">
					<MdLock color="#AABDC5" size={18} />
				</span>
			)}
		</div>
	);
};

const SelectField = ({
	label,
	context,
	labelText,
	isOpened,
	currentText,
	handleToggle,
	arrOptions,
	handleSelect,
}) => {
	return (
		<div className="flex flex-col gap-2 text-[#393F42] zabalMedFont w-full">
			<label htmlFor={label} className="text-xs md:text-[12.5px]">
				{labelText}
			</label>
			<div className="w-full relative">
				<PlainSelect
					context={context}
					isOpened={isOpened}
					currentText={currentText}
					handleToggle={handleToggle}
					height="38px"
				/>
				{isOpened && (
					<div className="absolute z-[2] w-full top-48px">
						<SelectDropDown
							arrOptions={arrOptions}
							onHandleSelect={handleSelect}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

InsuranceApplication.propTypes = {
	requestData: PropTypes.object,
	activeTab: PropTypes.number,
	setActiveTab: PropTypes.func,
};

InputField.propTypes = {
	label: PropTypes.string,
	labelText: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	isDisabled: PropTypes.bool,
	type: PropTypes.string,
	name: PropTypes.string,
	handleChange: PropTypes.func,
};

SelectField.propTypes = {
	label: PropTypes.string,
	labelText: PropTypes.string,
	isOpened: PropTypes.bool,
	context: PropTypes.string,
	currentText: PropTypes.string,
	handleSelect: PropTypes.func,
	handleToggle: PropTypes.func,
	arrOptions: PropTypes.array,
};
