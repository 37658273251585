import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import InvestorForm from "./InvestorForm";
import {
	BackTracker,
	SuccessCheckModal,
} from "../../../layout/CustomComponents";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import {
	LeaseSummaryInput,
	LeaseSummaryItemDetails,
} from "../requestlease/LeaseSummary";
import {
	formatAmountToIncludeCurrency,
	getInterestAmount,
	getProductsObjects,
	getProfilePercentage,
} from "../../../common/utils";
import { DesktopSideModal } from "../../../components/ModalContainer";
import {
	closeInvestorReqLeaseModal,
	openInvestorReqLeaseModal,
} from "../../../redux/lease/leaseActions";
import Parse from "../../../api/ApiConfig";
import { investorCreateAsset } from "../../../redux/lease/leaseServices";
import { clearUpdateAccountSuccess } from "../../../redux/authRedux/authActions";
import { MdLock } from "react-icons/md";
import { logoutUser } from "../../../redux/authRedux/authServices";

const CreateLeaseSummary = ({ setActiveTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeModal, setActiveModal] = useState(1);

	const { updatingUserAccountSuccess, currentUser } = useSelector(
		(state) => state.auth
	);
	const {
		leaseFormData,
		leaseStructureData,
		selectedReqLeaseItem,
		toggleInvestorReqLeaseModal,
		createAssetLoading,
		createAssetSuccess,
	} = useSelector((state) => state.lease);

	const [summaryData] = useState({
		product_category: selectedReqLeaseItem?.category,
		brand: selectedReqLeaseItem?.brand,
		model: selectedReqLeaseItem?.model,
		year: selectedReqLeaseItem?.year,
		condition: selectedReqLeaseItem?.condition,
		color: selectedReqLeaseItem?.color,
		lease_type: leaseFormData?.lease_type,
		lease_duration: leaseStructureData?.lease_duration,
		lease_structure: leaseStructureData?.lease_structure,
		amount_to_invest: String(leaseStructureData?.selected_slots?.price),
		waiting_period: leaseStructureData?.waiting_period,
		interest: leaseStructureData?.interest,
	});

	const {
		product_category,
		brand,
		model,
		year,
		condition,
		color,
		lease_duration,
		lease_structure,
		amount_to_invest,
		lease_type,
		waiting_period,
		interest,
	} = summaryData;

	const interestAmount = getInterestAmount(
		interest,
		selectedReqLeaseItem?.amount
	);

	const interestToEarn =
		(interestAmount / 4) * leaseStructureData?.selected_slots?.slotNum || 0;

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const Product = Parse.Object.extend("Products");
	const product = new Product();
	product.id = selectedReqLeaseItem?.objectId;

	const productObj = getProductsObjects(selectedReqLeaseItem);

	const moreDetails =
		leaseStructureData?.selected_slots?.slotNum < 4
			? "You have created an asset with partial funding. You need to activate it to notify other investors for full funding. Thank you!"
			: "You have created an asset with full funding. You need to activate it for listing on market place. Thank you!";

	const assetData = {
		leaseDuration: lease_duration,
		leaseType: lease_type,
		leaseStructure: lease_structure,
		userId: user,
		productId: product,
		productObj,
		waitingPeriod: waiting_period,
		status: "inactive",
		// amountToInvest: amount_to_invest,
		interest,
		paidSlots: 0,
		type:
			leaseStructureData?.selected_slots?.slotNum < 4
				? "partial-investment"
				: "full-investment",
		investors: [user.id],
		investorsArray: [
			{
				user: user.id,
				name: `${currentUser?.firstName} ${currentUser?.lastName}`,
				phone: currentUser?.phone,
				address: currentUser?.homeAddress,
				slots: leaseStructureData?.selected_slots?.slotNum,
				amountToInvest: leaseStructureData?.selected_slots?.price,
				interestToEarn,
				paid: false,
			},
		],
	};

	// set active modal to asset created success
	useEffect(() => {
		if (createAssetSuccess) {
			dispatch(openInvestorReqLeaseModal());
			setActiveModal(2);
		} else {
			return;
		}
	}, [createAssetSuccess]);

	const dataValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		netWorth: currentUser?.netWorth,
		investorSignature: currentUser?.investorSignature,
		witnessSignature: currentUser?.witnessSignature,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const percentageCompletionOfForm = getProfilePercentage(dataValidation);

	// open create asset modal
	const beginInvestorModalProcess = () => {
		if (percentageCompletionOfForm < 48) {
			dispatch(openInvestorReqLeaseModal());
			setActiveModal(1);
			return;
		}
		if (user.id) {
			dispatch(investorCreateAsset(assetData, moreDetails));
		} else {
			dispatch(logoutUser());
		}
	};

	const onInvestorFormSaveSuccess = () => {
		dispatch(clearUpdateAccountSuccess());
		dispatch(closeInvestorReqLeaseModal());
	};

	if (!selectedReqLeaseItem) {
		setActiveTab(2);
	}

	const onProceedToLeaseBoard = () => {
		navigate("/leasing/investment-board", { replace: true });
		dispatch(closeInvestorReqLeaseModal());
	};

	return (
		<>
			{toggleInvestorReqLeaseModal && (
				<DesktopSideModal modal={toggleInvestorReqLeaseModal}>
					<>
						{activeModal === 1 && updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onInvestorFormSaveSuccess}
									isShowBtn={true}
									btnText="okay"
								/>
							</div>
						) : (
							activeModal === 1 &&
							!updatingUserAccountSuccess && (
								<InvestorForm
									onCloseForm={() => dispatch(closeInvestorReqLeaseModal())}
								/>
							)
						)}
						{activeModal === 2 && createAssetSuccess && (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Asset has been created successfully"
									onRequestClick={onProceedToLeaseBoard}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						)}
					</>
				</DesktopSideModal>
			)}
			<BackTracker
				text="Create Asset Summary"
				onNavigate={() => setActiveTab(3)}
			/>
			<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
				<div className="hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
					<button className="zabalMedFont" onClick={() => setActiveTab(3)}>
						<FaArrowLeft />
					</button>
					<p>Create Asset Summary</p>
				</div>
				<LeaseSummaryItemDetails
					item={selectedReqLeaseItem}
					interestAmount={interestAmount}
					// interest={interest}
				/>
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseSummaryInput input_value={lease_type} text="lease type" />
					<LeaseSummaryInput
						input_value={lease_duration}
						text="lease duration"
					/>
					<LeaseSummaryInput
						input_value={product_category}
						text="product category"
					/>
					<LeaseSummaryInput input_value={brand} text="brand" />
					<LeaseSummaryInput input_value={model} text="model" />
					<LeaseSummaryInput input_value={year} text="year" />
					<LeaseSummaryInput
						input_value={condition || "brand new"}
						text="condition"
					/>
					<LeaseSummaryInput input_value={color} text="color" />
					<LeaseSummaryInput
						input_value={lease_structure}
						text="lease structure"
					/>
					<LeaseSummaryInput
						input_value={formatAmountToIncludeCurrency("NGN", amount_to_invest)}
						text="amount to invest"
					/>

					<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
							Interest Rate (NGN):
						</p>
						<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
							<div className="flex items-center justify-between w-full">
								<p>
									<span className="text-[#393F42] zabalMedFont">NGN </span>
									<span>
										{formatAmountToIncludeCurrency(undefined, interestToEarn)}
									</span>
								</p>
								<p className="text-fortcore-green zabalMedFont">{`( ${interest}% P/A)`}</p>
							</div>
							<span className="h-full grid place-items-center">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>
					<LeaseSummaryInput
						input_value={waiting_period}
						text="waiting period"
					/>

					<div className="bg-[#E3E9EC] h-[1px] w-full hidden sm:block"></div>

					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						<CustomGreenBtn
							text="create asset"
							width="140px"
							height="42px"
							fontSize="13px"
							onRequestClick={beginInvestorModalProcess}
							isDisabled={createAssetLoading}
							isLoading={createAssetLoading}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						<CustomGreenBtn
							text="create asset"
							onRequestClick={beginInvestorModalProcess}
							isDisabled={createAssetLoading}
							isLoading={createAssetLoading}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateLeaseSummary;

CreateLeaseSummary.propTypes = {
	setActiveTab: PropTypes.func,
};
