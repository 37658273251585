import React from "react";
import {
	MdKeyboardArrowDown,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdKeyboardArrowUp,
	MdOutlineClose,
} from "react-icons/md";
import { FaArrowLeft, FaBoxOpen } from "react-icons/fa";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CustomGreenBtn } from "./CustomButtons";
import { Link } from "react-router-dom";
import { QuestionMarkIcon, SuccessCheckIcon, ExclamationIcon } from "./assets";
import { Preloader } from "../components/globalStyled";

export const UrlTracker = ({ text, isCurrentUrl, onNavigate }) => (
	<p
		className={`hidden sm:flex items-center gap-1 text-xs zabalMedFont capitalize ${
			isCurrentUrl
				? "text-[#8E9DA4] cursor-auto"
				: "text-fortcore-green cursor-pointer"
		}`}
		onClick={onNavigate}
	>
		<span>{text}</span>
		<MdKeyboardArrowRight size={14} />
	</p>
);

export const BackTracker = ({ text, onNavigate }) => (
	<button
		className="zabalMedFont mb-5 capitalize text-sm flex gap-1 items-center text-fortcore-green font-medium sm:hidden"
		onClick={onNavigate}
	>
		<MdKeyboardArrowLeft size={18} />
		<span className="-mt-0.5">{text}</span>
	</button>
);

export const FatBackTracker = ({ text, onNavigate }) => (
	<div className="text-[12.5px] sm:text-[13.5px] lg:text-sm flex gap-2 items-center text-primary-color sm:text-fortcore-green zabalMedFont">
		<button onClick={onNavigate}>
			<FaArrowLeft size={15} />
		</button>
		<span className="font-semibold capitalize">{text}</span>
	</div>
);

export const CustomBigSelect = ({
	text,
	subText,
	amount,
	toggleModal,
	onRequestToggle,
	children,
}) => {
	return (
		<div
			className={`rounded-md border border-[#EEF2F3] px-4 md:px-5 transition-colors transition-shadow ${
				toggleModal ? "bg-[#FEFEFE] bigSelectOpen" : "blueGradient"
			}`}
		>
			<div
				className={`flex items-center justify-between h-[80px] sm:h-[85px] md:h-[90px] lg:h-[95px] xl:h-[110px] text-[#393F42] cursor-pointer ${
					toggleModal && "border-b border-[#EEF2F3]"
				}`}
				onClick={onRequestToggle}
			>
				<div className="flex flex-col gap-1.5">
					<p className="zabalBoldFont text-sm">{text}</p>
					<p className="text-[10.5px] text-[#788084] zabalMedFont">
						{subText}
						{amount}
					</p>
				</div>
				{toggleModal ? (
					<MdKeyboardArrowUp size={22} />
				) : (
					<MdKeyboardArrowDown size={22} />
				)}
			</div>
			{children ? children : null}
		</div>
	);
};

export const PlainSelect = ({
	handleToggle,
	isOpened,
	height,
	currentText,
	context,
}) => {
	return (
		<div className="w-full">
			<div
				className={`w-full flex justify-between items-center px-2.5 cursor-pointer rounded rounded zabalRegFont text-[#393F42] ${
					isOpened
						? "border-2 border-fortcore-green"
						: "border border-[#B8B8B8]"
				}`}
				onClick={handleToggle}
				style={{ height: height ? height : "45px" }}
			>
				<p
					className={`text-[13px] md:text-[13.5px] ${
						currentText === "" ? "text-[#B8B8B8] capitalize" : "text-[#393F42]"
					}`}
				>
					{currentText === "" ? context : currentText}
				</p>
				{isOpened ? (
					<MdKeyboardArrowUp size={20} />
				) : (
					<MdKeyboardArrowDown size={20} />
				)}
			</div>
		</div>
	);
};

export const SelectDropDown = ({
	arrOptions,
	onHandleSelect,
	isLoading,
	emptyMsg,
}) => {
	return (
		<div className="drop-shadow-2xl shadow-lg w-full space-y-1 rounded md:rounded-sm bg-[#FEFEFE] py-2 md:py-2.5 max-h-[220px] md:max-h-[250px] overflow-hidden">
			<div className="max-h-[200px] md:max-h-[230px] overflow-y-auto scroll-wrapper">
				{isLoading ? (
					<div className="w-full h-[70px] grid place-items-center">
						<Preloader />
					</div>
				) : arrOptions?.length > 0 ? (
					arrOptions?.map((options, i) => (
						<p
							key={i}
							className="text-[13px] md:text-[13.5px] zabalMedFont px-3.5 md:px-4 py-1.5 cursor-pointer hover:bg-[#0000000a] text-[#000000de]"
							onClick={() => onHandleSelect(options)}
						>
							{options}
						</p>
					))
				) : (
					<div className="w-full h-[80px] grid place-items-center zabalRegFont">
						<div className="flex justify-center flex-col gap-1 items-center max-w-[250px] mx-auto">
							<FaBoxOpen size={32} color="#b5b5b5" />
							<p className="text-[12.5px] md:text-[13px] text-[#555555] text-center leading-4">
								{emptyMsg}
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

PlainSelect.propTypes = {
	isOpened: PropTypes.bool,
	handleToggle: PropTypes.func,
	currentText: PropTypes.string,
	context: PropTypes.string,
	height: PropTypes.string,
};

SelectDropDown.propTypes = {
	arrOptions: PropTypes.arrayOf(PropTypes.string),
	onHandleSelect: PropTypes.func,
	isLoading: PropTypes.bool,
	emptyMsg: PropTypes.string,
};

export const CustomTabSelector = ({ activeTab, setActiveTab }) => {
	return (
		<div className="text-sm p-2 sm:p-1.5 gap-1 grid grid-cols-2 border border-[#E3E9EC] bg-[#F5F5F5] rounded-[30px] w-full max-w-[400px] sm:w-[240px] sm:max-w-none">
			<button
				className={`py-2.5 sm:py-2 text-center rounded-3xl border transition-all ${
					activeTab === 1
						? "text-fortcore-green border-[#557C8A] bg-white zabalBoldFont"
						: "text-[#8E9DA4] border-transparent zabalMedFont"
				}`}
				onClick={() => setActiveTab(1)}
			>
				Overview
			</button>
			<button
				className={`py-2.5 sm:py-2 rounded-3xl border transition-all ${
					activeTab === 2
						? "text-fortcore-green border-[#557C8A] bg-white zabalBoldFont"
						: "text-[#8E9DA4] border-transparent zabalMedFont"
				}`}
				onClick={() => setActiveTab(2)}
			>
				Account
			</button>
		</div>
	);
};

export const FinanceType = ({ text, amount }) => (
	<div className="pt-7 pb-2.5 flex items-center justify-between text-[11.5px] text-[#393F42] zabalMedFont">
		<p className="text-[12.25px]">{text}</p>
		<p className="text-[#788084]">
			<span>NGN </span>
			<span>{amount.whole}.</span>
			<span className="text-[11.25px]">{amount.decimal}</span>
		</p>
	</div>
);

export const InvestmentType = ({ text, amount, url, isSave, onReqClick }) => (
	<div className="pt-6 pb-2 5 flex justify-between sm:justify-normal sm:flex-col gap-0.5 text-[11.5px] zabalMedFont gap-2">
		<div className="space-y-1">
			<p className="text-[12.25px] text-[#393F42]">{text}</p>
			<p className="text-[#788084]">
				<span>NGN </span>
				<span>{amount.whole}.</span>
				<span className="text-[11.25px]">{amount.decimal}</span>
			</p>
		</div>
		{isSave ? (
			<div className="self-end flex gap-3.5 items-center">
				<Link to={url} className="text-fortcore-green underline">
					View
				</Link>
				<button
					onClick={onReqClick}
					className="rounded-md py-2 px-5 text-white bg-primary-color"
				>
					Save
				</button>
			</div>
		) : (
			<div className="self-end flex gap-3.5 items-center">
				<Link to={url} className="text-fortcore-green underline">
					View
				</Link>
				<button
					className="rounded-md py-2 px-5 text-white bg-primary-color"
					onClick={onReqClick}
				>
					Invest
				</button>
			</div>
		)}
	</div>
);

export const CustomCheckboxInput = ({
	name,
	handleChange,
	isChecked,
	value,
	id,
	disabled,
}) => (
	<CustomCheckBoxDiv>
		<input
			type="checkbox"
			name={name}
			id={id}
			onChange={handleChange}
			checked={isChecked}
			value={value}
			disabled={disabled}
		/>
		<div className="custom-checkbox">
			<div></div>
		</div>
	</CustomCheckBoxDiv>
);

const CustomCheckBoxDiv = styled.div`
	position: relative;
	display: flex;

	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		appearance: none;
		z-index: 2;
		position: relative;
		background-color: transparent;

		&:checked ~ .custom-checkbox {
			background: #00525d;
			border-color: #00525d;
		}

		&:checked ~ .custom-checkbox div {
			position: relative;
			top: -2px;
			width: 6px;
			height: 11px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(43deg);
			-ms-transform: rotate(43deg);
			transform: rotate(43deg);
		}
	}

	.custom-checkbox {
		width: 16px;
		height: 16px;
		position: absolute;
		border-radius: 2px;
		border: 1px solid rgba(23, 24, 31, 0.4);
		display: grid;
		place-items: center;
	}
`;
export const CustomRadioInput = ({
	name,
	value,
	isChecked,
	handleChange,
	disabled,
	isReadOnly,
	id,
}) => (
	<CustomRadioDiv>
		<input
			type="radio"
			name={name}
			value={value}
			checked={isChecked}
			onChange={handleChange}
			disabled={disabled}
			readOnly={isReadOnly}
			id={id}
		/>
		<div
			className={`custom-radio ${disabled && "!border-[#B8B8B880]"} ${
				isReadOnly && "!border-[#B8B8B880]"
			}`}
		>
			<div
				className={`${disabled && "!bg-[#B8B8B880]"} ${
					isReadOnly && "!bg-[#B8B8B880]"
				}`}
			></div>
		</div>
	</CustomRadioDiv>
);

const CustomRadioDiv = styled.div`
	position: relative;
	width: 14px;
	height: 14px;

	input {
		position: absolute;
		width: 14px;
		height: 14px;
		appearance: none;
		z-index: 5;

		&:checked ~ .custom-radio {
			border-color: #00525d;
		}

		&:checked ~ .custom-radio div {
			background: #00525d;
		}
	}

	.custom-radio {
		position: absolute;
		width: 14px;
		height: 14px;
		border-radius: 9999px;
		border: 1px solid gray;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 1px;
		transition: all 0.05s ease-in-out;

		> div {
			width: 8px;
			height: 8px;
			border-radius: 9999px;
			transition: all 0.05s ease-in-out;
		}
	}
`;

export const SuccessCheckModal = ({
	text,
	onRequestClick,
	isShowBtn,
	btnText,
}) => {
	return (
		<div className="pt-5 pb-6 px-6 bg-white w-[300px] rounded-2xl grid gap-4 zabalRegFont">
			<div className="flex justify-end">
				<button onClick={onRequestClick}>
					<MdOutlineClose color="#00525D" size={20} />
				</button>
			</div>
			<div className="space-y-1.5 justify-self-center">
				<SuccessCheckIcon width={55} height={55} />
			</div>
			<p className="text-primary-color text-sm text-center w-[90%] mx-auto zabalMedFont">
				{text}
			</p>
			{isShowBtn && (
				<CustomGreenBtn
					text={btnText ? btnText : "okay"}
					fontSize="14px"
					onRequestClick={onRequestClick}
					height="45px"
				/>
			)}
		</div>
	);
};

export const CustomConfirmModal = ({
	text,
	greenBtnText,
	onCloseBtnClick,
	greenBtnClick,
	isNotify,
	isLoading,
}) => {
	return (
		<div className="bg-white grid gap-3.5 md:gap-4 rounded-2xl p-5 md:p-6 w-10/12 max-w-[300px] mx-auto">
			<button
				className="justify-self-end text-fortcore-green"
				onClick={onCloseBtnClick}
			>
				<MdOutlineClose size={20} />
			</button>
			<div className="justify-self-center">
				{isNotify ? (
					<ExclamationIcon width={55} height={55} />
				) : (
					<QuestionMarkIcon width={55} height={55} />
				)}
			</div>
			<p className="w-11/12 mx-auto text-[#222627] zabalRegFont text-center text-[13.5px] md:text-sm">
				{text}
			</p>
			<div className="flex gap-4">
				{/* <CustomWhiteBtn text={whiteBtnText} onRequestClick={whiteBtnClick} fontSize='14.5px' /> */}
				<CustomGreenBtn
					text={greenBtnText}
					onRequestClick={greenBtnClick}
					fontSize="14.5px"
					isLoading={isLoading}
					isDisabled={isLoading}
				/>
			</div>
		</div>
	);
};

export const SavingsButtonNavigation = ({ text, onClick }) => {
	return (
		<button
			className="blueGradient rounded-md border border-[#EEF2F3] flex justify-between items-center px-6 py-3 h-[80px] sm:h-[85px] md:h-[90px] lg:h-[95px] xl:h-[100px]"
			onClick={onClick}
		>
			<p className="text-[#393F42] zabalMedFont text-sm md:text-[14px] capitalize">
				{text}
			</p>
			<span className="text-primary-color">
				<MdKeyboardArrowRight size={25} />
			</span>
		</button>
	);
};

export const FileUploadWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	height: 38.5px;
	border-radius: 6px;
	border: 1px solid #b8c8cf;
	gap: 0.75rem;
	width: 100%;

	a,
	p {
		font-size: 14px;
		font-weight: 700;
		color: #003a50;
	}

	> button {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #b8c8cf;
		height: 30px;
		min-width: 35px;
		border-radius: 5px;
	}
`;

CustomConfirmModal.propTypes = {
	text: PropTypes.string.isRequired,
	// whiteBtnText: PropTypes.string,
	greenBtnText: PropTypes.string,
	onCloseBtnClick: PropTypes.func,
	isNotify: PropTypes.bool,
	greenBtnClick: PropTypes.func,
	isLoading: PropTypes.bool,
};

UrlTracker.propTypes = {
	text: PropTypes.string,
	onNavigate: PropTypes.func,
	isCurrentUrl: PropTypes.bool,
};
BackTracker.propTypes = {
	text: PropTypes.string,
	onNavigate: PropTypes.func,
};
FatBackTracker.propTypes = {
	text: PropTypes.string,
	onNavigate: PropTypes.func,
};
CustomBigSelect.propTypes = {
	text: PropTypes.string,
	subText: PropTypes.string,
	toggleModal: PropTypes.bool,
	children: PropTypes.element,
	amount: PropTypes.element,
	onRequestToggle: PropTypes.func,
};
CustomTabSelector.propTypes = {
	activeTab: PropTypes.number,
	setActiveTab: PropTypes.func,
};
FinanceType.propTypes = {
	text: PropTypes.string,
	amount: PropTypes.object,
};
InvestmentType.propTypes = {
	text: PropTypes.string,
	amount: PropTypes.object,
	url: PropTypes.string,
	tab: PropTypes.array,
	isSave: PropTypes.bool,
	onReqClick: PropTypes.func,
};
CustomCheckboxInput.propTypes = {
	value: PropTypes.string,
	handleChange: PropTypes.func,
	isChecked: PropTypes.bool,
	name: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
};
CustomRadioInput.propTypes = {
	isReadOnly: PropTypes.bool,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	isChecked: PropTypes.bool,
	name: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
};
SuccessCheckModal.propTypes = {
	text: PropTypes.string.isRequired,
	onRequestClick: PropTypes.func.isRequired,
	isShowBtn: PropTypes.bool,
	btnText: PropTypes.string,
};
SavingsButtonNavigation.propTypes = {
	text: PropTypes.string,
	onClick: PropTypes.func,
};

export const ActiveInvestmentHeader = ({ text, isActive, onClick }) => (
	<p
		className={`${
			isActive
				? "zabalMedFont text-[14.5px] md:text-base font-semibold text-fortcore-green cursor-auto"
				: "zabalRegFont text-[13px] md:text-sm text-[#557C8A] underline cursor-pointer"
		}`}
		onClick={onClick}
	>
		{text}
	</p>
);

ActiveInvestmentHeader.propTypes = {
	text: PropTypes.string,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
};

export const CustomHeaderWithCloseBtn = ({ text, onClick, isPadding }) => (
	<div
		className={`flex items-center justify-between text-fortcore-green mb-1 ${
			isPadding ? "px-4 md:px-5" : ""
		}`}
	>
		<h3 className="text-md md:text-[18px] font-semibold chillaxFont capitalize">
			{text}
		</h3>
		<button onClick={onClick}>
			<MdOutlineClose size={20} />
		</button>
	</div>
);
CustomHeaderWithCloseBtn.propTypes = {
	text: PropTypes.string,
	onClick: PropTypes.func,
	isPadding: PropTypes.bool,
};
