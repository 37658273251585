import React from "react";
import { CustomWhiteBtn } from "./CustomButtons";
import { closeLogoutModal } from "../redux/authRedux/authActions";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/authRedux/authServices";

const Logout = () => {
	const dispatch = useDispatch();

	return (
		<div className="w-10/12 max-w-[400px] rounded-lg p-6 pb-8 space-y-8 bg-white">
			<div className="space-y-1">
				<h3 className="zabalBoldFont font-semibold text-xl">Log Out ?</h3>
				<p className="zabalMedFont text-[#645786] text-sm">
					Are you sure you want to logout ?
				</p>
			</div>
			<div className="flex flex-col gap-2.5">
				<button
					className="bg-red-700 w-full h-[45px] text-sm rounded-md text-white zabalMedFont"
					onClick={() => dispatch(logoutUser())}
				>
					Log Out
				</button>
				<CustomWhiteBtn
					text="cancel"
					onRequestClick={() => dispatch(closeLogoutModal())}
					isDisabled={false}
				/>
			</div>
		</div>
	);
};
export default Logout;
