import React, { useEffect, useState } from "react";
import { createContext } from "react";
import PropTypes from "prop-types";
import Parse from "../api/ApiConfig";
import { formatDateToString } from "../screens/finance/DateFormat";
// import data from "../screens/finance/newTransaction/investor/browse/marketplace/data";

const userContext = createContext();

export function UserContextProvider({ children }) {
	const [currentUserData, setCurrentUserData] = useState({});
	const [createAccountModal, setCreateAccountModal] = useState(false);

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const currentUser = Parse.User.current();
				if (currentUser) {
					const userData = currentUser?.toJSON();

					const dateString = userData?.idExpiryDate;
					const formattedDateString = formatDateToString(dateString);
					setCurrentUserData(currentUser.toJSON());
					setCurrentUserData((prevState) => ({
						...prevState,
						idExpiryDate: formattedDateString,
					}));
				} else {
					Parse.User.logOut();
				}
			} catch (error) {
				// eslint-disable-next-line no-undef
				console.log(error.message);
			}
		};
		fetchUser();
	}, []);

	useEffect(() => {
		const getParameters = async () => {
			try {
				const currentUser = Parse.User.current();

				if (currentUser) {
					const ParametersClass = Parse.Object.extend("Parameters");
					const query = new Parse.Query(ParametersClass);

					try {
						const results = await query.find();
						const data = results.map((result) => result.toJSON());

						// Extract parameter and value fields from the data array
						const parameters = data.map((item) => ({
							parameter: item.parameter,
							value: item.value,
						}));

						const afInterest = parameters.find(
							(param) =>
								param.parameter === "Base Interest rate Apprenticeship Finance"
						);
						const afProgramSavings = parameters.find(
							(param) =>
								param.parameter ===
								"Base Interest rate (Savings) Apprenticeship Finance"
						);

						if (afInterest) {
							// eslint-disable-next-line no-undef
							// console.log(
							// 	"Interest rate Apprenticeship Finance: ",
							// 	afInterest.value
							// );
							setAfRate((prevState) => ({
								...prevState,
								interest: afInterest,
							}));
						}
						if (afProgramSavings) {
							// eslint-disable-next-line no-undef
							// console.log(
							// 	"Program Savings Apprenticeship Finance: ",
							// 	afProgramSavings.value
							// );
							setAfRate((prevState) => ({
								...prevState,
								programSavings: afProgramSavings,
							}));
						}

						// eslint-disable-next-line no-undef
						// console.log("parameters: ", parameters);
					} catch (error) {
						// eslint-disable-next-line no-undef
						console.error("Error fetching parameters: ", error);
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-undef
				console.log(this);
			}
		};

		getParameters();
	}, []);

	// eslint-disable-next-line no-undef
	// console.log("User1: ", currentUserData);

	const [wallet, setWallet] = useState({
		balance: "00.00",
	});

	// Apprentice Finance Request

	const [requestFinance, setRequestFinance] = useState({
		formStep: 0,
		amount: "",
		businessInterest: "",
		interest: "",
		tenure: "",
		commitmentFee: "",
		expectedProfit: "",
		durationText: "",
		durationTime: "",
		programSavings: "",
		programSavingsRate: "",
		type: "",
		businessType: "",
		businessDescription: "",
		purpose: "",
		marketDetails: "",
		interestRate: "",
		amountRepayableAfterTenure: "",

		proofOfPurchase: "",
		proofOfPurchaseDoc: null,
		businessProofFile: {
			name: "",
		},
		attachmentFile: {
			name: "",
		},
		productPhoto: [],

		idCard: {
			name: "",
		},
		utilityBill: {
			name: "",
		},
		autoDebit: false,
		insurance: false,
		guarantor: false,
		paymentType: "",

		signature: "",
	});
	const [phoneNumber1, setPhoneNumber1] = useState("");
	const [phoneNumber2, setPhoneNumber2] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [phonSteps, setPhonSteps] = useState(0);

	const [investorProfileDetails, setInvestorProfileDetails] = useState({});
	const [investorMarketPlace, setInvestorMarketPlace] = useState([]);
	const [investorMarketForm, setInvestorMarketForm] = useState({
		durationText: "",
		durationTime: "",
	});
	const [investorCreateForm, setInvestorCreateForm] = useState({
		interest: "",
		amountToInvest: "",
		amountToRepay: "",
		durationTime: "",
		durationText: "",
		waitingTime: "",
		waitingText: "",
		businessType: "",
	});

	// Travel Advance Ongoing Transaction
	const [onGoingForm, setOnGoingForm] = useState({});

	const [afRate, setAfRate] = useState({});

	// Payment Screen (Add Funds)

	const [fundWallet, setFundWallet] = useState({
		// amount: "0",
		name: "",
	});
	const [newNotifications, setNewNotifications] = useState([]);

	const value = {
		currentUserData,
		setCurrentUserData,

		requestFinance,
		setRequestFinance,
		selectedFile,
		setSelectedFile,
		phoneNumber1,
		setPhoneNumber1,
		phoneNumber2,
		setPhoneNumber2,

		phonSteps,
		setPhonSteps,

		investorProfileDetails,
		setInvestorProfileDetails,

		investorMarketPlace,
		setInvestorMarketPlace,

		investorMarketForm,
		setInvestorMarketForm,
		investorCreateForm,
		setInvestorCreateForm,

		onGoingForm,
		setOnGoingForm,

		wallet,
		setWallet,

		fundWallet,
		setFundWallet,

		afRate,
		setAfRate,

		createAccountModal,
		setCreateAccountModal,

		newNotifications,
		setNewNotifications,
	};
	return <userContext.Provider value={value}>{children}</userContext.Provider>;
}

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
export default userContext;
