import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { GridContainer, OnboardStyle } from "./styled";
import Image from "./assets/image.png";
import { HOne, SubText } from "../../components/globalStyled";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Parse from "../../api/ApiConfig";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";

function Onboarding() {
  // eslint-disable-next-line no-undef
  const defaultEmail = localStorage.getItem("email");
  const [email, setEmail] = useState(defaultEmail);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      navigate("/dashboard");
    }
  }, []);

  const handleExplore = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    localStorage.setItem("email", email);
    navigate("/login");
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  };
  return (
    <OnboardStyle>
      <Header />
      <Helmet>
        <title>Fortcore - Home</title>
      </Helmet>
      <GridContainer>
        <div className="left">
          <img src={Image} alt="" />
        </div>

        <div className="right zabalRegFont">
          <HOne>Get Started Today!</HOne>
          <SubText>
            The current state of the economy offers those who dare to grow
            little or no chance of financial growth, we are here to lend a
            helping hand through affordable and alternative financing.
          </SubText>

          <form onSubmit={handleExplore}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your E-mail"
              required
            />
            <Button
              variant="contained"
              sx={{
                background: theme.palette.primary.dark,
                borderRadius: "8px",
                width: "158px",
                height: "50px",
                textTransform: "capitalize",
              }}
              type="submit"
            >
              Explore
            </Button>
          </form>
        </div>
      </GridContainer>
    </OnboardStyle>
  );
}

export default Onboarding;
