import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	SavingsButtonNavigation,
	UrlTracker,
} from "../../../layout/CustomComponents";
import {
	clearRegularSavingsCreatedSuccess,
	openMoveFundsFromRegSavingsModal,
} from "../../../redux/lease/leaseActions";
import {
	fetchSingleRegularSavings,
	updateSingleRegularSavings,
} from "../../../redux/travels/travelServices";
import { Preloader } from "../../../components/globalStyled";
import { RegularSavingsCard } from "./RegularSavingsView";
import SingleSavingsEmpty from "../../../components/regularsavingscomp/SingleSavingsEmpty";
import ModalContainer from "../../../components/ModalContainer";
import AddFundsToRegularSavingsFlow from "../../../components/regularsavingscomp/AddFundsToRegularSavingsFlow";
import {
	clearUpdateRegularSavingsSuccess,
	closeRegularSavingsAddFundsModal,
	openRegularSavingsAddFundsModal,
} from "../../../redux/travels/travelActions";
import { walletType } from "../../../common/utils";
import Parse from "../../../api/ApiConfig";
import { moveUserFunds } from "../../../redux/payments/paymentServices";
import { clearMoveFundsSuccess } from "../../../redux/payments/paymentActions";
import { logoutUser } from "../../../redux/authRedux/authServices";
import MoveFundsFromRegularSavings from "../../../components/regularsavingscomp/MoveFundsFromRegularSavings";

const SingleLeaseRegularSavings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const user = Parse.User.current();

	const [activeModal, setActiveModal] = useState(1);
	const [amountToSave, setAmountToSave] = useState("");
	const [amountToMove, setAmountToMove] = useState("");
	const [activeMoveFundsModal, setActiveMoveFundsModal] = useState(false);

	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const savingsSource = "lease";

	const { customerWallet, moveFundsSuccess } = useSelector(
		(state) => state.payment
	);

	const { toggleMoveFundsFromRegSavingsModal } = useSelector(
		(state) => state.lease
	);

	const {
		fetchSingleRegSavingsLoading,
		fetchSingleRegSavingsSuccess,
		toggleRegularSavingsAddFundsModal,
		updateRegularSavingsSuccess,
	} = useSelector((state) => state.travel);

	const isPlanExpired =
		Date.now() > new Date(`${fetchSingleRegSavingsSuccess?.maturityDate}`);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	// move funds from main wallet to savings to fund a plan
	const onProceedToDeductWallet = () => {
		const amountPayable = Number(amountToSave?.replaceAll(",", ""));

		const details = {
			amount: amountPayable,
			source: "main",
			destination: walletType.regularSavings,
			message: "lease-savings-funds-moved",
		};

		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		if (user.id) {
			dispatch(moveUserFunds(currentSessionToken, details));
		} else {
			dispatch(logoutUser());
		}
	};

	const clearUpdateSavingsSuccess = () => {
		dispatch(clearMoveFundsSuccess());
		dispatch(closeRegularSavingsAddFundsModal());
		dispatch(clearUpdateRegularSavingsSuccess());
	};

	// open add funds modal
	const onOpenAddFundsModal = () => {
		if (isPlanExpired) {
			toast.error("You cannot add funds to expired plan.");
		} else {
			dispatch(openRegularSavingsAddFundsModal());
			setActiveModal(1);
		}
	};

	//open move funds modal
	const onOpenMoveFundsModal = () => {
		if (
			!fetchSingleRegSavingsSuccess?.amountSaved ||
			Number(fetchSingleRegSavingsSuccess?.amountSaved?.replaceAll(",", "")) < 1
		) {
			toast.error("You do not have any funds available.");
			return;
		}
		dispatch(openMoveFundsFromRegSavingsModal());
		setActiveMoveFundsModal(1);
	};

	// add the amount to the savings plan
	useEffect(() => {
		if (moveFundsSuccess === "lease-savings-funds-moved") {
			dispatch(clearMoveFundsSuccess());

			const oldAmount = fetchSingleRegSavingsSuccess?.amountSaved
				? Number(fetchSingleRegSavingsSuccess?.amountSaved?.replaceAll(",", ""))
				: 0;
			const updatedAmount =
				oldAmount + Number(amountToSave?.replaceAll(",", ""));
			const message = "Regular savings plan top-up";
			const metaData = {
				category: "Savings",
				linkToId: fetchSingleRegSavingsSuccess?.objectId,
				moreDetails: `The amount of N${amountToSave} has been added to your ${
					fetchSingleRegSavingsSuccess?.purpose || "General"
				} Regular Savings Plan. Keep up the good work.`,
			};

			dispatch(
				updateSingleRegularSavings(
					params.id,
					{ amountSaved: updatedAmount?.toString() },
					{
						message,
						metaData,
					}
				)
			);
		} else {
			return;
		}
	}, [moveFundsSuccess]);

	// change modal to success on add funds success
	useEffect(() => {
		if (updateRegularSavingsSuccess) {
			setActiveModal(4);
		} else {
			return;
		}
	}, [updateRegularSavingsSuccess]);

	useEffect(() => {
		dispatch(clearRegularSavingsCreatedSuccess());
		dispatch(fetchSingleRegularSavings(params.id, savingsSource));
	}, [params]);

	return (
		<>
			{toggleRegularSavingsAddFundsModal && (
				<ModalContainer modal={toggleRegularSavingsAddFundsModal}>
					<AddFundsToRegularSavingsFlow
						activeModal={activeModal}
						setActiveModal={setActiveModal}
						onDeductWallet={onProceedToDeductWallet}
						amountToSave={amountToSave}
						setAmountToSave={setAmountToSave}
						onDeductWalletSuccess={clearUpdateSavingsSuccess}
					/>
				</ModalContainer>
			)}
			{toggleMoveFundsFromRegSavingsModal && (
				<ModalContainer modal={toggleMoveFundsFromRegSavingsModal}>
					<MoveFundsFromRegularSavings
						activeMoveFundsModal={activeMoveFundsModal}
						setActiveMoveFundsModal={setActiveMoveFundsModal}
						amountToMove={amountToMove}
						setAmountToMove={setAmountToMove}
						amountSaved={fetchSingleRegSavingsSuccess?.amountSaved}
					/>
				</ModalContainer>
			)}
			<MobileNavDetails text="savings" />
			<Helmet>
				<title>Fortcore - Regular Savings</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="leasing" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<BackTracker text="regular savings" onNavigate={() => navigate(-1)} />
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="regular savings"
							onNavigate={() => navigate("/leasing/regular-savings/view")}
						/>
						<UrlTracker text={params?.id} isCurrentUrl />
					</div>
					<div className="sm:mt-6"></div>
					{fetchSingleRegSavingsLoading ? (
						<div className="h-[350px] sm:h-[400px] grid place-items-center w-full">
							<Preloader />
						</div>
					) : fetchSingleRegSavingsSuccess ? (
						<div className="space-y-5 sm:space-y-6 md:space-y-7 mb-8">
							<RegularSavingsCard
								data={fetchSingleRegSavingsSuccess}
								showMaturityDate
							/>
							<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-5">
								<SavingsButtonNavigation
									text={
										Number(
											fetchSingleRegSavingsSuccess?.amountSaved?.replaceAll(
												",",
												""
											)
										) > 0
											? "add more funds"
											: "start saving"
									}
									onClick={onOpenAddFundsModal}
								/>
								<SavingsButtonNavigation
									text="move funds"
									onClick={onOpenMoveFundsModal}
								/>
								<SavingsButtonNavigation text="history" />
							</div>
						</div>
					) : (
						<SingleSavingsEmpty query={params?.id} savingUrl={savingsSource} />
					)}
				</>
			</Container>
		</>
	);
};

export default SingleLeaseRegularSavings;
