import React from "react";
import PropTypes from "prop-types";
import { MdDelete } from "react-icons/md";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";

export const InactiveCreatedFinance = ({
	financeReq,
	onBeginActivationProcess,
	onDeleteRequest,
}) => {
	return (
		<div
			className={`rounded-md px-3 py-3.5 sm:px-3.5 sm:py-4 border flex gap-3.5 md:gap-4 min-w-[340px] sm:min-w-[355px] md:min-w-[365px] relative bg-[#F6F8F980] border-[#D5DEE2]`}
		>
			<button
				className="text-red-600 pr-1 absolute right-3 top-3"
				onClick={onDeleteRequest}
			>
				<MdDelete size={18} />
			</button>
			<div className={`w-[calc(100%-92px)] grid gap-12`}>
				<div className="relative">
					<p className="relative capitalize text-[#222627] text-[13px] sm:text-[13.5px] font-semibold zabalMedFont">
						{financeReq?.businessType}
					</p>
				</div>
				<div className="self-end flex gap-2.5 lg:gap-4 zabalMedFont">
					<p className="w-[48%] text-[#393F42] flex flex-col gap-1">
						<span className="text-[11px]">Principal</span>
						<span className="text-xs sm:text-[12.5px]">
							<span className="font-semibold">NGN </span>
							{financeReq?.amountToInvest}
						</span>
					</p>

					<p className="w-[48%] text-[#393F42] flex flex-col gap-1">
						<span className="text-[11px]">Interest</span>
						<span className="text-xs sm:text-[12.5px]">
							<span className="font-semibold">NGN </span>
							{financeReq?.interest}
						</span>
					</p>
				</div>
			</div>

			<div className="w-[92px] flex flex-col gap-1.5 justify-end items-end">
				<p className="rounded-md border border-[#EB948121] text-[13.5px] bg-white text-[#FF7676] flex items-center justify-center gap-2 px-1.5 py-2 w-[90px]">
					<BsFillExclamationTriangleFill size={17} />
					<span>Inactive</span>
				</p>
				<CustomGreenBtn
					text="activate"
					width="90px"
					height="37px"
					fontSize="12.25px"
					onRequestClick={() => onBeginActivationProcess(financeReq)}
				/>
			</div>
		</div>
	);
};

InactiveCreatedFinance.propTypes = {
	financeReq: PropTypes.object,
	onBeginActivationProcess: PropTypes.func,
	onDeleteRequest: PropTypes.func,
};
