import { useState, useEffect, useRef } from "react";
import Parse from "../api/ApiConfig";
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from "../redux/authRedux/authServices";

export const useAuthStatus = () => {
	const isMounted = useRef(true);
	const [loggedIn, setLoggedIn] = useState(false);
	const [checkingStatus, setCheckingStatus] = useState(true);
	const dispatch = useDispatch();

	const currentUser = Parse.User.current();

	useEffect(() => {
		if (isMounted) {
			if (currentUser) {
				dispatch(fetchCurrentUser());
				setLoggedIn(true);
			} else {
				setLoggedIn(false);
			}
			setCheckingStatus(false);
		}

		return () => {
			isMounted.current = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	return { loggedIn, checkingStatus };
};
