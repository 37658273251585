import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { BiError } from "react-icons/bi";
import { AiFillFileText } from "react-icons/ai";

export function FinalBox({ title, onClick, bool, modal }) {
	return (
		<Button
			sx={{
				border: "1px solid var(--grey-200, #E3E9EC)",
				borderRadius: "8px",
				width: "100%",
				height: "66px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "0 8px",
				cursor: "pointer",
			}}
			onClick={modal}>
			<Typography variant="h4" sx={{ color: "#393F42" }}>
				{title}
			</Typography>
			<Stack direction="row" spacing={1}>
				{!bool && (
					<Typography
						variant="body1"
						color="primary"
						sx={{
							display: "flex",
							alignItems: "center",
							color: "#F00",
						}}>
						<BiError style={{ fontSize: "20px", marginRight: "5px" }} /> Action
						Required
					</Typography>
				)}

				{onClick && (
					<>
						<Button
							variant="outlined"
							onClick={onClick}
							sx={{
								display: { xs: "none", sm: "flex" },
								alignItems: "center",
							}}>
							<AiFillFileText />
							Add New File
						</Button>
						<Button
							variant="contained"
							onClick={onClick}
							sx={{
								display: { xs: "flex", sm: "none" },
								alignItems: "center",
							}}>
							<AiFillFileText />
						</Button>
					</>
				)}
			</Stack>
		</Button>
	);
}
FinalBox.propTypes = {
	title: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	modal: PropTypes.func,
	bool: PropTypes.bool,
};
