import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Account from "./Account";
import Amount from "./Amount";
import Channel from "./Channel";
// import SenderName from "./SenderName";
import { closeAddFundsModal } from "../../../redux/payments/paymentActions";
import {
	CustomConfirmModal,
	SuccessCheckModal,
} from "../../../layout/CustomComponents";
import VerifyDeposit from "./VerifyDeposit";

const DepositFundsFlow = ({ activePaymentTab, setActivePaymentTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		providusDepositConfirmSuccess,
		providusDepositConfirmError,
		pagaDepositConfirmSuccess,
		pagaDepositConfirmError,
	} = useSelector((state) => state.payment);

	const [paymentDetails, setPaymentDetails] = useState({
		amountToDeposit: "",
		paymentChannel: "",
		sendersName: "",
	});
	const { amountToDeposit, paymentChannel /*sendersName*/ } = paymentDetails;

	const handleChange = (e) =>
		setPaymentDetails((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const onCloseModal = () => {
		dispatch(closeAddFundsModal());
		setPaymentDetails({
			paymentChannel: "",
			sendersName: "",
			amountToDeposit: "",
		});
	};

	const onProceedToProfile = () => {
		dispatch(closeAddFundsModal());
		navigate("/dashboard/profile/complete-your-profile");
	};

	const onPaymentConfirmed = () => {
		dispatch(closeAddFundsModal());
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	useEffect(() => {
		if (providusDepositConfirmSuccess || pagaDepositConfirmSuccess) {
			setActivePaymentTab(5);
		} else {
			return;
		}
	}, [providusDepositConfirmSuccess, pagaDepositConfirmSuccess]);

	useEffect(() => {
		if (providusDepositConfirmError || pagaDepositConfirmError) {
			setActivePaymentTab(6);
		} else {
			return;
		}
	}, [providusDepositConfirmError, pagaDepositConfirmError]);

	return (
		<>
			{activePaymentTab === 0 && (
				<CustomConfirmModal
					isNotify
					text="You need to complete your personal profile before funds deposition on the platform."
					greenBtnText="complete now ?"
					greenBtnClick={onProceedToProfile}
					onCloseBtnClick={() => dispatch(closeAddFundsModal())}
				/>
			)}
			{activePaymentTab === 1 && (
				<Amount
					amountToDeposit={amountToDeposit}
					handleChange={handleChange}
					onCloseModal={onCloseModal}
					setActivePaymentTab={setActivePaymentTab}
				/>
			)}
			{activePaymentTab === 2 && (
				<Channel
					setActivePaymentTab={setActivePaymentTab}
					onCloseModal={onCloseModal}
					paymentChannel={paymentChannel}
					onSelect={(mode) =>
						setPaymentDetails((prev) => ({
							...prev,
							paymentChannel: mode,
						}))
					}
				/>
			)}
			{/* {activePaymentTab === 3 && (
				<SenderName
					setActivePaymentTab={setActivePaymentTab}
					onCloseModal={onCloseModal}
					amountToDeposit={amountToDeposit}
					sendersName={sendersName}
					handleChange={handleChange}
				/>
			)} */}
			{activePaymentTab === 3 && (
				<Account
					setActivePaymentTab={setActivePaymentTab}
					onCloseModal={onCloseModal}
					amountToDeposit={amountToDeposit}
					paymentChannel={paymentChannel}
				/>
			)}
			{activePaymentTab === 4 && <VerifyDeposit />}
			{activePaymentTab === 5 && (
				<div className="grid place-items-center w-full">
					<SuccessCheckModal
						text={`Payment ${
							providusDepositConfirmSuccess === "Money confirmed" ? "has" : "is"
						} being confirmed. Reload page for balance update.`}
						btnText="Ok!"
						isShowBtn
						onRequestClick={onPaymentConfirmed}
					/>
				</div>
			)}
			{activePaymentTab === 6 && <confirmProvidusDepositError />}
		</>
	);
};

DepositFundsFlow.propTypes = {
	activePaymentTab: PropTypes.number,
	setActivePaymentTab: PropTypes.func,
};

export default DepositFundsFlow;
