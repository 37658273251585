import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { BsPlayFill } from "react-icons/bs";
// import AudioM from "../../assets/audio.mp3";
import { useNavigate } from "react-router-dom";
import { EducationContext } from "../../Context/EducationContext";
import { loadingData } from "../video/Video";

function Audio() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { filteredData, loading } = useContext(EducationContext);

  const courseType = filteredData.filter(
    (audio) => audio.courseType === "audio"
  );
  const audioData = courseType.filter((audio) => audio.approve === true);
  const openedArray = audioData.filter((audio) => audio.isOpened === true);

  // eslint-disable-next-line no-undef
  console.log("audioData:", courseType);
  return (
    <>
      <Box>
        {loading ? (
          <Box>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Box>
        ) : (
          <>
            {audioData.length > 0 ? (
              <Typography color="primary" variant="h3">
                Suggestions For You
              </Typography>
            ) : (
              <Typography color="primary" variant="h3">
                No Audio files at the moment
              </Typography>
            )}
          </>
        )}
        <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

        <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
          {loading ? (
            <>
              {loadingData.map((index) => (
                <>
                  <Skeleton
                    key={index}
                    variant="rounded"
                    sx={{
                      minWidth: { xs: "100%", sm: 345 },
                      maxWidth: 345,
                      height: "222px",
                      borderRadius: "8px",
                    }}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {audioData.length > 0 ? (
                <>
                  {audioData.map((data, index) => (
                    <>
                      <Card
                        key={index}
                        sx={{
                          minWidth: { xs: "100%", sm: 345 },
                          maxWidth: 345,
                          height: "222px",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/education/audio/${data.objectId}`)
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            sx={{
                              height: "150px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              objectFit: "cover",
                            }}
                            image={data.coverPhoto}
                          ></CardMedia>

                          <CardContent sx={{ background: "primary.light" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Stack spacing={1}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  color="primary.main"
                                >
                                  {data.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="secondary.light"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {data.category}
                                </Typography>
                              </Stack>

                              <Stack
                                direction="column"
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton
                                  size="small"
                                  sx={{
                                    background: `${theme.palette.primary.main}`,
                                    "&:hover": {
                                      background: theme.palette.primary.main,
                                    },
                                  }}
                                >
                                  <BsPlayFill
                                    color={theme.palette.primary.light}
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconButton>
                                <Typography
                                  variant="caption"
                                  color="secondary.light"
                                >
                                  {data.totalDuration}
                                </Typography>
                              </Stack>
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Stack>
      </Box>

      {/* Continue Listening */}
      <Box sx={{ marginTop: "40px" }}>
        {loading ? (
          <Box>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Box>
        ) : (
          <>
            {audioData.length > 0 ? (
              <>
                {openedArray.length > 0 ? (
                  <Typography color="primary" variant="h3">
                    Continue Listening
                  </Typography>
                ) : (
                  <Typography color="primary" variant="h3">
                    You have no audio played
                  </Typography>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

        <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
          <>
            {audioData.map((data, index) => (
              <>
                <Card
                  key={index}
                  sx={{
                    minWidth: { xs: "100%", sm: 345 },
                    maxWidth: 345,
                    height: "222px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/education/audio/${data.id}`)}
                >
                  <CardActionArea>
                    <CardMedia
                      sx={{
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        objectFit: "cover",
                      }}
                      image={data.coverPhoto}
                    ></CardMedia>

                    <CardContent sx={{ background: "primary.light" }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack spacing={1}>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            color="primary.main"
                          >
                            {data.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="secondary.light"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {data.category}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="column"
                          alignItems="center"
                          spacing={1}
                        >
                          <IconButton
                            size="small"
                            sx={{
                              background: `${theme.palette.primary.main}`,
                              "&:hover": {
                                background: theme.palette.primary.main,
                              },
                            }}
                          >
                            <BsPlayFill
                              color={theme.palette.primary.light}
                              style={{ fontSize: "20px" }}
                            />
                          </IconButton>
                          <Typography variant="caption" color="secondary.light">
                            {data.totalDuration}
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}
          </>
        </Stack>
      </Box>
    </>
  );
}

export default Audio;

const scrollableContainerStyles = {
  overflowX: "auto",
  pb: 1.5,
  // scrollbarWidth: "thin",
  // scrollbarColor: "transparent transparent",
  // paddingBottom: "20px",
  // "&::-webkit-scrollbar": {
  //   width: "6px",
  // },
  // "&::-webkit-scrollbar-track": {
  //   background: "transparent",
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   background: "transparent",
  //   borderRadius: "3px",
  // },
};
