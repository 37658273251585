import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import PropTypes from "prop-types";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IoCloseCircle } from "react-icons/io5";

const ProductImagesCarousel = ({ imageArr, productName }) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const updateWindowDimension = () => setWindowWidth(window.innerWidth);

	useEffect(() => {
		window.addEventListener("resize", updateWindowDimension);
		return () => window.removeEventListener("resize", updateWindowDimension);
	}, []);

	const [showLargeImage, setLargeImage] = useState(false);

	const onClick = () => setLargeImage(true);

	useEffect(() => {
		if (showLargeImage) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [showLargeImage]);

	return (
		<>
			{showLargeImage && (
				<div
					className="!mt-0 grid place-items-center fixed inset-0 bg-[#393F4285] z-[12] backdrop-blur-[2px]"
					onClick={() => setLargeImage(false)}
				>
					<button
						className="absolute z-10 top-10 right-3 md:right-10"
						onClick={() => setLargeImage(false)}
					>
						<IoCloseCircle color="white" size={34} />
					</button>
					<ShowImage imageArr={imageArr} />
				</div>
			)}
			<div className="w-full relative">
				<Swiper
					slidesPerView={
						windowWidth > 276 && windowWidth < 440
							? 2.25
							: windowWidth < 440
							? 2
							: windowWidth > 440 && windowWidth < 476
							? 2.25
							: windowWidth > 476 && windowWidth < 525
							? 2.3
							: windowWidth > 525 && windowWidth < 548
							? 2.35
							: windowWidth > 548 && windowWidth < 640
							? 2
							: windowWidth > 640 && windowWidth < 750
							? 1
							: windowWidth > 750 && windowWidth < 864
							? 1.25
							: windowWidth > 864 && windowWidth < 1096
							? 1.75
							: windowWidth > 1096
							? 2
							: 2
					}
					modules={[Navigation, Autoplay]}
					spaceBetween={25}
					navigation
					autoplay={true}
				>
					{imageArr.map((image, i) => (
						<SwiperSlide
							key={i}
							className="w-full h-auto max-w-[125px] max-h-[110px] md:max-w-[135px] md:max-h-[120px] lg:max-h-[135px] lg:max-w-[145px]"
						>
							<img
								src={image}
								alt={productName}
								onClick={onClick}
								className="cursor-pointer"
								// onClick={() => onClick(image)}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</>
	);
};

export default ProductImagesCarousel;

const ShowImage = ({ imageArr }) => {
	return (
		<div
			className="w-full max-w-[340px] md:max-w-[400px] flex justify-center relative"
			onClick={(e) => e.stopPropagation()}
		>
			<Swiper modules={[Navigation]} spaceBetween={25} navigation>
				{imageArr?.map((image, i) => (
					<SwiperSlide
						key={i}
						className="rounded-lg h-[350px] md:h-auto w-auto md:w-[330px] max-w-[400px] max-h-[400px]"
					>
						{/* <SwiperSlide key={i} className="rounded-lg"> */}
						<img
							src={image}
							className="w-full h-full rounded-lg mx-auto object-contain"
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

ShowImage.propTypes = {
	imageArr: PropTypes.array,
};

ProductImagesCarousel.propTypes = {
	imageArr: PropTypes.arrayOf(PropTypes.string).isRequired,
	productName: PropTypes.string,
};
