import React, { useEffect, useState } from "react";
import { GridContainer, OnboardStyle } from "../../screens/onboarding/styled";
import { Helmet } from "react-helmet";
import { ContentBox, FormStyle, Layout, Right } from "../createAccount/styled";
import {
	BtnStyle,
	HOne,
	LongBtn,
	Preloader,
	SubText,
} from "../../components/globalStyled";
import { Link, useNavigate } from "react-router-dom";
import Image from "./assets/image.png";
import Image2 from "./assets/image2.png";
import {
	AiFillCheckCircle,
	AiFillCloseCircle,
	AiOutlineClose,
} from "react-icons/ai";
import axios from "axios";
import baseURL from "../../api/UrlConfig";
import { toast } from "react-toastify";
import Check from "../createAccount/assets/check.svg";
import Parse from "../../api/ApiConfig";
import HeaderNav from "../../components/header/HeaderNav";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import theme from "../../components/mui/theme";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function ForgotPassword() {
	const navigate = useNavigate();
	const [tab, setTab] = useState(1);
	const [loading, setLoading] = useState(false);

	//STEP ONE STATES
	const [email, setEmail] = useState("");
	//END OF STEP ONE STATES

	//STEP TWO STATES
	const [code, setCode] = useState("");
	const [active, setActive] = useState(false);
	const [resend, setResend] = useState(false);
	const [countdown, setCountdown] = useState(300);
	//END OF STEP TWO STATES

	//STEP THREE STATES
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordCheck, setPasswordCheck] = useState(false);
	const [success, setSuccess] = useState(false);
	//END OF STEP THREE STATES

	useEffect(() => {
		const currentUser = Parse.User.current();
		if (currentUser) {
			navigate("/dashboard");
		}
	}, []);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		const timer = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown <= 0) {
					// eslint-disable-next-line no-undef
					clearInterval(timer);
					return 0;
				}
				return prevCountdown - 1;
			});
		}, 1000);

		return () => {
			// eslint-disable-next-line no-undef
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		if (code.length !== 6) {
			setActive(false);
		} else {
			setActive(true);
		}
	}, [code]);

	useEffect(() => {
		if (password === confirmPassword) {
			setPasswordCheck(true);
		} else {
			setPasswordCheck(false);
		}
	}, [password, confirmPassword]);

	const handleResend = async () => {
		setLoading(true);

		const data = JSON.stringify({
			email: email,
		});

		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/forgotPassword`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				// eslint-disable-next-line no-undef
				console.log(JSON.stringify(response.data));
				setResend(true);
				setLoading(false);
				setCountdown(300);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				if (error.message === "Network Error") {
					toast.error("Connect to an internet");
				} else {
					toast.error(error.response.data.error);
				}
			});
	};

	const handleStepOne = async (e) => {
		e.preventDefault();
		setLoading(true);

		const data = JSON.stringify({
			email: email,
		});

		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/forgotPassword`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				// eslint-disable-next-line no-undef
				console.log(JSON.stringify(response.data));
				setTab(2);
				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				if (error.message === "Network Error") {
					toast.error("Connect to an internet");
				} else {
					toast.error(error.response.data.error);
				}
			});
	};
	const handleStepTwo = async (e) => {
		e.preventDefault();
		setLoading(true);

		const data = JSON.stringify({
			email: email,
			otp: code,
		});

		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/verifyForgotPasswordOtp`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				// eslint-disable-next-line no-undef
				console.log(response);
				setTab(3);
				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				if (error.message === "Network Error") {
					toast.error("Connect to an internet");
				} else {
					toast.error(error.response.data.error);
				}
			});
	};
	const handleStepThree = async (e) => {
		e.preventDefault();

		if (password === confirmPassword) {
			setLoading(true);
			const data = JSON.stringify({
				email: email,
				password: password,
			});

			const config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${baseURL}/functions/changePassword`,
				headers: {
					// eslint-disable-next-line no-undef
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios
				.request(config)
				.then((response) => {
					// eslint-disable-next-line no-undef
					console.log(response);
					setLoading(false);
					setSuccess(true);
					// eslint-disable-next-line no-undef
					localStorage.clear();
				})
				.catch((error) => {
					// eslint-disable-next-line no-undef
					console.log(error);
					setLoading(false);
					if (error.message === "Network Error") {
						toast.error("Connect to an internet");
					} else {
						toast.error(error.response.data.error);
					}
				});
		} else {
			toast.error("Passwords do not match");
		}
	};

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;

		return `${minutes.toString().padStart(2, "0")}:${seconds
			.toString()
			.padStart(2, "0")}`;
	};

	const getTextColor = () => {
		if (countdown <= 10) {
			return "red";
		}
		return "black";
	};

	// eslint-disable-next-line no-unused-vars
	const renderCountdown = () => {
		if (countdown <= 0) {
			return "Expired";
		}
	};

	const hasNumber = /\d/.test(password);
	const hasSpecialCharacter = /[!@#=$%^&*()_+{}\\[\]:;<>,.?~\\/-]/.test(
		password
	);
	const hasLowerCase = /[a-z]/.test(password);
	const hasUpperCase = /[A-Z]/.test(password);
	return (
		<>
			<HeaderNav />
			<OnboardStyle>
				<Helmet>
					<title>Fortcore - Forgot Password</title>
				</Helmet>

				<GridContainer>
					<div className="left">
						{(tab === 1 || tab === 2) && <img src={Image} alt="" />}
						{tab === 3 && <img src={Image2} alt="" />}
					</div>

					<div className="loginContainer">
						<div className="login">
							{tab === 1 && (
								<Right>
									<Link to="/login">
										<BtnStyle
											className="back"
											outline={true.toString()}
											disabled={loading}>
											Back
										</BtnStyle>
									</Link>
									<HOne>Forgot Password</HOne>
									<SubText>Please enter your registered Email Address </SubText>

									<FormStyle onSubmit={handleStepOne}>
										<label htmlFor="email">Email Address</label>

										<input
											type="email"
											value={email}
											name="email"
											onChange={(e) => setEmail(e.target.value)}
											placeholder="Enter your E-mail"
											required
										/>

										<LongBtn type="submit" disabled={loading}>
											{!loading ? "Proceed" : <Preloader />}
										</LongBtn>
									</FormStyle>

									<div className="center">
										<p className="link">
											Remember Password? <Link to="/login">Login</Link>
										</p>
									</div>
								</Right>
							)}

							{tab === 2 && (
								<Right>
									{resend && (
										<div className="resend">
											<p>A new set of code has been sent to you</p>
											<AiOutlineClose
												className="icon"
												onClick={() => setResend(false)}
											/>
										</div>
									)}
									<BtnStyle
										className="back"
										outline={true.toString()}
										onClick={() => setTab(1)}
										disabled={loading}>
										Back
									</BtnStyle>
									<HOne>Forgot Password</HOne>
									<SubText>
										A verification code has been sent to your Email Address
									</SubText>

									<FormStyle onSubmit={handleStepTwo}>
										<label htmlFor="code">Input Verification Code</label>
										<input
											type="tel"
											value={code}
											name="code"
											onChange={(e) => setCode(e.target.value)}
											required
											maxLength={6}
										/>
										<div className="center" style={{ color: getTextColor() }}>
											<p>{formatTime(countdown)}</p>
										</div>
										<LongBtn
											type="submit"
											className={!active ? "inactive" : ""}
											disabled={!active || loading}>
											{!loading ? "Reset Password" : <Preloader />}
										</LongBtn>
									</FormStyle>

									<div className="center">
										<p className="link">
											Didn’t get OTP?{" "}
											<Link onClick={handleResend}>Resend Code</Link>
										</p>
									</div>
								</Right>
							)}

							{tab === 3 && (
								<Right>
									<BtnStyle
										className="back"
										outline={true.toString()}
										onClick={() => setTab(2)}
										disabled={tab === 3}>
										Back
									</BtnStyle>
									<HOne>Reset Password</HOne>
									<SubText>Enter a new password</SubText>

									<Box
										sx={{ display: "flex", flexDirection: "column", gap: 3 }}
										width="100%"
										component="form"
										onSubmit={handleStepThree}>
										{/* Password  */}
										<Stack spacing={1.5}>
											<Typography variant="body1" color="info.light">
												Password
											</Typography>

											<Tooltip
												title={
													<Box
														sx={{
															background: "#fff",
															margin: "-4px -8px",
															padding: "4px 8px",
															borderRadius: "2px",
															boxShadow: theme.shadows[1],
														}}>
														<Stack>
															<Typography variant="h5" color="primary">
																Password must contain:
															</Typography>
															<Box
																component="ul"
																sx={{
																	li: {
																		marginTop: "5px",
																		marginBottom: "5px",
																	},
																}}>
																<li>
																	<Stack
																		direction="row"
																		alignItems="center"
																		spacing={1}>
																		{password.length > 7 ? (
																			<AiFillCheckCircle color="#03f62c" />
																		) : (
																			<AiFillCloseCircle color="#7e0505" />
																		)}{" "}
																		<Typography
																			variant="caption"
																			color={theme.palette.secondary.light}
																			sx={{ lineHeight: "100%" }}>
																			Between 8 and 250 characters
																		</Typography>
																	</Stack>
																</li>
																<li>
																	<Stack
																		direction="row"
																		alignItems="center"
																		spacing={1}>
																		{hasUpperCase ? (
																			<AiFillCheckCircle color="#03f62c" />
																		) : (
																			<AiFillCloseCircle color="#7e0505" />
																		)}{" "}
																		<Typography
																			variant="caption"
																			color={theme.palette.secondary.light}
																			sx={{ lineHeight: "100%" }}>
																			At least 1 uppercase letter
																		</Typography>
																	</Stack>
																</li>

																<li>
																	<Stack
																		direction="row"
																		alignItems="center"
																		spacing={1}>
																		{hasLowerCase ? (
																			<AiFillCheckCircle color="#03f62c" />
																		) : (
																			<AiFillCloseCircle color="#7e0505" />
																		)}{" "}
																		<Typography
																			variant="caption"
																			color={theme.palette.secondary.light}
																			sx={{ lineHeight: "100%" }}>
																			At least 1 lowercase letter
																		</Typography>
																	</Stack>
																</li>

																<li>
																	<Stack
																		direction="row"
																		alignItems="center"
																		spacing={1}>
																		{hasSpecialCharacter ? (
																			<AiFillCheckCircle color="#03f62c" />
																		) : (
																			<AiFillCloseCircle color="#7e0505" />
																		)}{" "}
																		<Typography
																			variant="caption"
																			color={theme.palette.secondary.light}
																			sx={{ lineHeight: "100%" }}>
																			At least 1 special character
																		</Typography>
																	</Stack>
																</li>

																<li>
																	<Stack
																		direction="row"
																		alignItems="center"
																		spacing={1}>
																		{hasNumber ? (
																			<AiFillCheckCircle color="#03f62c" />
																		) : (
																			<AiFillCloseCircle color="#7e0505" />
																		)}{" "}
																		<Typography
																			variant="caption"
																			color={theme.palette.secondary.light}
																			sx={{ lineHeight: "100%" }}>
																			At least 1 number
																		</Typography>
																	</Stack>
																</li>
															</Box>
														</Stack>
													</Box>
												}
												placement="left">
												<TextField
													fullWidth
													type={showPassword ? "text" : "password"}
													name="password"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
													placeholder="At least 8 Characters with one character"
													InputProps={{
														style: {
															borderRadius: "8px",
														},
														endAdornment: (
															<InputAdornment
																position="end"
																sx={{ padding: 0 }}>
																{showPassword ? (
																	<IconButton
																		onClick={() => setShowPassword(false)}>
																		<BsFillEyeFill
																			color={theme.palette.primary.main}
																		/>
																	</IconButton>
																) : (
																	<IconButton
																		onClick={() => setShowPassword(true)}>
																		<BsFillEyeSlashFill
																			color={theme.palette.primary.main}
																		/>
																	</IconButton>
																)}
															</InputAdornment>
														),
													}}
													required
												/>
											</Tooltip>
										</Stack>

										{/* Confirm Password  */}
										<Stack spacing={1.5}>
											<Typography variant="body1" color="info.light">
												Confirm Password
											</Typography>

											<TextField
												error={confirmPassword !== password}
												type={showConfirmPassword ? "text" : "password"}
												name="confirmPassword"
												value={confirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
												placeholder="Re-enter Password"
												helperText={
													confirmPassword !== password &&
													"Confirm Password must match password"
												}
												InputProps={{
													style: {
														borderRadius: "8px",
													},
													endAdornment: (
														<InputAdornment position="end" sx={{ padding: 0 }}>
															{showConfirmPassword ? (
																<IconButton
																	onClick={() => setShowConfirmPassword(false)}>
																	<BsFillEyeFill
																		color={theme.palette.primary.main}
																	/>
																</IconButton>
															) : (
																<IconButton
																	onClick={() => setShowConfirmPassword(true)}>
																	<BsFillEyeSlashFill
																		color={theme.palette.primary.main}
																	/>
																</IconButton>
															)}
														</InputAdornment>
													),
												}}
												required
											/>
										</Stack>

										{/* <LongBtn
											type="submit"
											className={!passwordCheck ? "inactive" : ""}
											disabled={!passwordCheck || loading}>
											{!loading ? "Done" : <Preloader />}
										</LongBtn> */}

										{loading ? (
											<Button type="button" variant="contained" fullWidth>
												<Preloader />
											</Button>
										) : (
											<Button
												type="submit"
												fullWidth
												variant="contained"
												disabled={
													!passwordCheck ||
													password.length < 7 ||
													!hasUpperCase ||
													!hasLowerCase ||
													!hasSpecialCharacter ||
													!hasNumber
												}>
												Done
											</Button>
										)}
									</Box>
								</Right>
							)}
						</div>
					</div>
				</GridContainer>

				{success && (
					<Layout>
						<ContentBox>
							<img src={Check} alt="" />
							<p>Password Successfully Changed</p>
							<BtnStyle
								onClick={() => {
									navigate("/login");
									// eslint-disable-next-line no-undef
									window.scrollTo(0, 0);
								}}>
								Login
							</BtnStyle>
						</ContentBox>
					</Layout>
				)}
			</OnboardStyle>
		</>
	);
}

export default ForgotPassword;
