import React, { useState, useEffect } from "react";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import { Helmet } from "react-helmet";
import Navbar from "../../../layout/Navbar";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";
import { useNavigate } from "react-router-dom";
import { MdFilterAlt, MdSearch } from "react-icons/md";
// import { items } from '../../../assets/data'
import PropTypes from "prop-types";
// import { getSubmittedLeaseStatus } from "../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../../components/EmptyData";
import { OngoingLeaseSkeleton } from "../requestlease/LeaseItems";
import { fetchSubmittedLease } from "../../../redux/lease/leaseServices";
import { SubmittedLeaseItem } from "../requestlease/LeaseTransaction";
import { FaArrowRight } from "react-icons/fa";
import ModalContainer from "../../../components/ModalContainer";
import DeliveryInfo from "../../../components/payments/DeliveryInfo";
import { onTogglePayDeliveryFeeModal } from "../../../redux/lease/leaseActions";
import { toast } from "react-toastify";
import UpcomingPayments from "../../../components/payments/UpcomingPayments";
import { onToggleUpcomingPayments } from "../../../redux/payments/paymentActions";

const SubmittedLeaseTransactionsView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { currentUser } = useSelector((state) => state.auth);
	const {
		fetchSubmittedLeaseLoading,
		fetchSubmittedLeaseSuccess,
		toggleDeliveryFeeModal,
		currentLeaseId,
	} = useSelector((state) => state.lease);

	const { toggleUpcomingPayments, currentItemToRepay } = useSelector(
		(state) => state.payment
	);

	const [showCurrent, setShowCurrent] = useState(null);
	const handleShow = (id) => setShowCurrent((exp) => (exp === id ? null : id));

	useEffect(() => {
		dispatch(fetchSubmittedLease());
	}, [currentUser?.objectId]);

	const sortedSubmittedTransactions = fetchSubmittedLeaseSuccess?.sort(
		(leaseOne, leaseTwo) =>
			new Date(leaseTwo?.updatedAt).getTime() -
			new Date(leaseOne?.updatedAt).getTime()
	);

	const currentLeaseItem = fetchSubmittedLeaseSuccess?.find(
		(a) => a?.objectId === currentLeaseId
	);

	const itemToRepay = fetchSubmittedLeaseSuccess?.find(
		(a) => a?.objectId === currentItemToRepay
	);

	const onClosePaymentsModal = () => dispatch(onToggleUpcomingPayments(false));

	return (
		<>
			{toggleDeliveryFeeModal && (
				<ModalContainer modal={toggleDeliveryFeeModal}>
					<DeliveryInfo leaseItem={currentLeaseItem} context={"lease"} />
				</ModalContainer>
			)}
			{toggleUpcomingPayments && (
				<ModalContainer modal={toggleUpcomingPayments}>
					<UpcomingPayments
						requestData={itemToRepay}
						onCloseModal={onClosePaymentsModal}
						context="lease"
					/>
				</ModalContainer>
			)}
			<MobileNavDetails text="submitted transactions" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Submitted Transactions</title>
					</Helmet>
					<Navbar text="submitted transactions" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker text="submitted transactions" isCurrentUrl />
					</div>
					<BackTracker text="leasing" onNavigate={() => navigate(-1)} />
					<div className="bg-[#F6F7F8] rounded-lg my-5 md:my-7 p-4 sm:p-5">
						<div className="flex justify-between items-center">
							<p className="text-[13.5px] sm:text-sm md:text-[14.5px] md:text-fortcore-green zabalBoldFont">
								Transaction Status
							</p>
							<div className="flex gap-2 items-center">
								<button>
									<MdSearch color="#8E9DA4" size={20} />
								</button>
								<button>
									<MdFilterAlt color="#8E9DA4" size={20} />
								</button>
							</div>
						</div>
						<div className="grid gap-4 md:gap-5 mt-5 mb-2">
							{fetchSubmittedLeaseLoading ? (
								Array(3)
									.fill(0)
									.map((_, i) => <OngoingLeaseSkeleton key={i} />)
							) : fetchSubmittedLeaseSuccess?.length > 0 ? (
								sortedSubmittedTransactions.map((item) => (
									<SubmittedLeaseItem
										leaseItem={item}
										handleShow={handleShow}
										showCurrent={showCurrent}
										key={item?.objectId}
									/>
								))
							) : (
								<div className="w-full h-[370px] md:h-[370px]">
									<EmptyData text="You have not completed any transactions yet." />
								</div>
							)}
						</div>
					</div>
				</>
			</Container>
		</>
	);
};

export default SubmittedLeaseTransactionsView;

export const FirstStatusTracker = ({
	status,
	leaseFor,
	leaseSource,
	leaseType,
	// leaseItem,
}) => {
	const { currentUser, guarantorsFormSuccess } = useSelector(
		(state) => state.auth
	);
	const navigate = useNavigate();

	return (
		<>
			<div className="relative">
				<div className="mt-2.5 md:mt-3 absolute">
					<TrackerRadio currentStatus={status.autoDebit} />
					<TrackerRadio currentStatus={status.insurance} />
					<TrackerRadio currentStatus={!!currentUser?.creditAssessment} />
					{leaseFor === "personal" && (
						<TrackerRadio currentStatus={!!guarantorsFormSuccess} />
					)}
					{leaseType.toLowerCase().includes("sponsored") &&
						leaseSource === "market-place" && (
							<TrackerRadio currentStatus={status.funded} />
						)}
					{leaseType.toLowerCase().includes("quick") &&
						leaseSource === "market-place" && (
							<TrackerRadio currentStatus={status.funded} />
						)}
					<TrackerRadio
						currentStatus={true}
						isEnd={
							leaseType.toLowerCase().includes("quick") &&
							leaseSource === "market-place"
								? false
								: leaseType.toLowerCase().includes("quick")
								? false
								: true
						}
					/>
					{leaseType.toLowerCase().includes("quick") &&
						leaseSource !== "market-place" && (
							<>
								<TrackerRadio currentStatus={!!status.basicApproval} />
								<TrackerRadio
									currentStatus={!!status.firstLineApproval?.includes("Approv")}
									statusRejected={
										!!status.firstLineApproval?.includes("Reject")
									}
								/>
								<TrackerRadio
									currentStatus={
										!!status.secondLineApproval?.includes("Approv")
									}
									statusRejected={
										!!status.secondLineApproval?.includes("Reject")
									}
								/>
								<TrackerRadio
									currentStatus={!!status.lastLineApproval?.includes("Approv")}
									statusRejected={!!status.lastLineApproval?.includes("Reject")}
								/>
								<TrackerRadio
									currentStatus={!!status.financeApproval?.includes("Approv")}
									statusRejected={!!status.financeApproval?.includes("Reject")}
									isEnd
								/>
							</>
						)}
					{leaseType.toLowerCase().includes("quick") &&
						leaseSource === "market-place" && (
							<>
								<TrackerRadio currentStatus={!!status.basicApproval} />
								<TrackerRadio
									currentStatus={!!status.firstLineApproval?.includes("Approv")}
									statusRejected={
										!!status.firstLineApproval?.includes("Reject")
									}
								/>
								<TrackerRadio
									currentStatus={
										!!status.secondLineApproval?.includes("Approv")
									}
									statusRejected={
										!!status.secondLineApproval?.includes("Reject")
									}
								/>
								<TrackerRadio
									currentStatus={!!status.lastLineApproval?.includes("Approv")}
									statusRejected={!!status.lastLineApproval?.includes("Reject")}
									isEnd
								/>
							</>
						)}
				</div>
				<div className="flex justify-end">
					<div className="grid gap-3 md:gap-4 w-[calc(100%-32px)] md:w-[calc(100%-37px)] lg:w-[calc(100%-42px)]">
						<TrackerText text="auto debit" currentStatus={status.autoDebit} />
						<TrackerText text="insurance" currentStatus={status.insurance} />
						<TrackerText
							text="credit assessment"
							currentStatus={!!currentUser?.creditAssessment}
						/>
						{leaseType.toLowerCase().includes("quick") &&
							leaseSource === "market-place" && (
								<TrackerText
									text="asset funded"
									currentStatus={status.funded}
								/>
							)}
						{leaseFor === "personal" && (
							<TrackerText
								text="guarantor"
								currentStatus={!!guarantorsFormSuccess}
								showPending={!guarantorsFormSuccess}
								showBtns
								btnText={!guarantorsFormSuccess ? "Save" : "Edit"}
								onNavigate={() => navigate("/leasing/guarantors-information")}
							/>
						)}
						{leaseType.toLowerCase().includes("sponsored") &&
							leaseSource === "market-place" && (
								<TrackerText
									text="asset funded"
									currentStatus={status.funded}
								/>
							)}
						<TrackerText text="request submitted" currentStatus={true} />
						{leaseType.toLowerCase().includes("quick") &&
							leaseSource !== "market-place" && (
								<>
									<TrackerText
										text="basic approval"
										currentStatus={!!status.basicApproval}
									/>
									<TrackerText
										text="first line approval"
										currentStatus={
											!!status.firstLineApproval?.includes("Approv")
										}
										statusRejected={
											!!status.firstLineApproval?.includes("Reject")
										}
									/>
									<TrackerText
										text="second line approval"
										currentStatus={
											!!status.secondLineApproval?.includes("Approv")
										}
										statusRejected={
											!!status.secondLineApproval?.includes("Reject")
										}
									/>
									<TrackerText
										text="final approval"
										currentStatus={
											!!status.lastLineApproval?.includes("Approv")
										}
										statusRejected={
											!!status.lastLineApproval?.includes("Reject")
										}
									/>
									<TrackerText
										text="finance approval"
										currentStatus={!!status.financeApproval?.includes("Approv")}
										statusRejected={
											!!status.financeApproval?.includes("Reject")
										}
									/>
								</>
							)}
						{leaseType.toLowerCase().includes("quick") &&
							leaseSource === "market-place" && (
								<>
									<TrackerText
										text="basic approval"
										currentStatus={!!status.basicApproval}
									/>
									<TrackerText
										text="first line approval"
										currentStatus={
											!!status.firstLineApproval?.includes("Approv")
										}
										statusRejected={
											!!status.firstLineApproval?.includes("Reject")
										}
									/>
									<TrackerText
										text="second line approval"
										currentStatus={
											!!status.secondLineApproval?.includes("Approv")
										}
										statusRejected={
											!!status.secondLineApproval?.includes("Reject")
										}
									/>
									<TrackerText
										text="final approval"
										currentStatus={
											!!status.lastLineApproval?.includes("Approv")
										}
										statusRejected={
											!!status.lastLineApproval?.includes("Reject")
										}
									/>
								</>
							)}
					</div>
				</div>
			</div>
		</>
	);
};

export const SecondStatusTracker = ({
	status,
	leaseType,
	leaseSource,
	leaseId,
	deliveryType,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const onProceedToLeaseAgreement = () => {
		if (leaseType.toLowerCase().includes("quick")) {
			if (
				leaseSource === "market-place" &&
				status.basicApproval?.includes("Approv") &&
				status.firstLineApproval?.includes("Approv") &&
				status.secondLineApproval?.includes("Approv") &&
				status.lastLineApproval?.includes("Approv") &&
				status.financeApproval?.includes("Approv") &&
				status.funded
			) {
				return false;
			} else if (
				leaseSource !== "market-place" &&
				status.basicApproval?.includes("Approv") &&
				status.firstLineApproval?.includes("Approv") &&
				status.secondLineApproval?.includes("Approv") &&
				status.lastLineApproval?.includes("Approv") &&
				status.funded
			) {
				return false;
			} else {
				return true;
			}
		} else if (
			leaseSource === "market-place" &&
			leaseType.toLowerCase().includes("sponsored")
		) {
			if (status.funded && getApprovalStatus() === "approved") {
				return false;
			} else {
				return true;
			}
		} else if (
			leaseSource === "products-catalogue" &&
			leaseType.toLowerCase().includes("sponsored")
		) {
			if (status.funded) {
				// console.log(status, "here");
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const getApprovalStatus = () => {
		const approvedReq = status?.requestStatus?.investor?.filter(
			(a) => a?.status === "approved"
		);
		const rejectedReq = status?.requestStatus?.investor?.filter(
			(a) => a?.status === "rejected"
		);

		if (leaseSource === "products-catalogue") {
			return status?.requestApproved;
		}

		if (leaseSource === "market-place" && leaseType === "Quick Lease") {
			return status?.requestApproved;
		}

		// console.log(leaseId, status?.investorsArray, status);

		if (status?.investorsArray?.length < 2) {
			return status?.requestStatus?.status;
		} else if (status?.investorsArray?.length === 2) {
			if (approvedReq?.length + rejectedReq?.length === 2) {
				if (approvedReq?.length > rejectedReq?.length) {
					return "approved";
				} else if (approvedReq?.length === rejectedReq?.length) {
					return "undecided";
				} else {
					return "rejected";
				}
			} else {
				return "pending";
			}
		} else if (status?.investorsArray?.length === 3) {
			if (approvedReq?.length + rejectedReq?.length > 1) {
				if (approvedReq?.length > rejectedReq?.length) {
					return "approved";
				} else if (approvedReq?.length < rejectedReq?.length) {
					return "rejected";
				} else {
					return "pending";
				}
			} else {
				return "pending";
			}
		} else if (status?.investorsArray?.length === 4) {
			if (approvedReq?.length + rejectedReq?.length > 2) {
				if (approvedReq?.length > rejectedReq?.length) {
					return "approved";
				} else if (approvedReq?.length < rejectedReq?.length) {
					return "rejected";
				} else if (approvedReq?.length === rejectedReq?.length) {
					return "undecided";
				} else {
					return "pending";
				}
			} else {
				return "pending";
			}
		}
	};

	const showPendingOnLA = () => {
		if (!onProceedToLeaseAgreement() && !status?.leaseAgreement) {
			return true;
		} else {
			return false;
		}
	};

	const onViewDeliveryInfo = () => {
		if (status?.leaseAgreement) {
			dispatch(onTogglePayDeliveryFeeModal(true, leaseId));
		} else {
			toast.error(
				"You need to sign the lease agreement to perform this action"
			);
		}
	};
	const showPendingOnDelivery = () => {
		if (getApprovalStatus() === "rejected") {
			console.log("yes");
			return false;
		}
		if (status?.requestApproved === "rejected") {
			// console.log("yes");
			return false;
		} else if (status?.deliveryInfo?.deliveryFeeStatus === "paid") {
			// console.log("yes");
			return false;
		} else if (!status?.deliveryInfo?.deliveryFee) {
			// console.log("yes");
			return true;
		} else {
			// console.log("yes");
			return true;
		}
	};

	const onViewUpcomingPayments = () => {
		dispatch(onToggleUpcomingPayments(true, leaseId));
	};

	return (
		<>
			<div className="relative">
				<div className="mt-2.5 md:mt-3 absolute">
					{leaseType.toLowerCase().includes("quick") ? (
						<TrackerRadio
							currentStatus={status?.requestApproved === "approved"}
							statusRejected={status?.requestApproved === "rejected"}
						/>
					) : (
						<TrackerRadio
							currentStatus={getApprovalStatus() === "approved"}
							statusRejected={
								getApprovalStatus() === "rejected" ||
								getApprovalStatus() === "undecided"
							}
						/>
					)}
					{leaseType.toLowerCase().includes("quick") &&
						leaseSource !== "market-place" && (
							<TrackerRadio currentStatus={status.funded} />
						)}
					{leaseType.toLowerCase().includes("sponsored") &&
						leaseSource === "products-catalogue" && (
							<TrackerRadio currentStatus={status.funded} />
						)}
					<TrackerRadio currentStatus={!!status.leaseAgreement?.signature} />
					<TrackerRadio currentStatus={!!status?.firstPayment?.paid} />
					{deliveryType === "door-delivery" && (
						<TrackerRadio
							currentStatus={status?.deliveryInfo?.deliveryFeeStatus === "paid"}
						/>
					)}
					{deliveryType === "door-delivery" &&
						status.deliveryInfo?.deliveryStatus === "delivered" && (
							<TrackerRadio
								currentStatus={!!status.deliveryInfo?.deliveryStatus}
							/>
						)}
					<TrackerRadio currentStatus={status.assetDeployed} />
					<TrackerRadio currentStatus={status.assetDeployed} isEnd />
				</div>
				<div className="flex justify-end">
					<div className="grid gap-3 md:gap-4 w-[calc(100%-32px)] md:w-[calc(100%-37px)] lg:w-[calc(100%-42px)]">
						{leaseType.toLowerCase().includes("quick") ? (
							<TrackerText
								text={
									status?.requestApproved === "pending"
										? "awaiting approval from admin"
										: status?.requestApproved === "approved"
										? "request approved"
										: status?.requestApproved === "rejected"
										? "request rejected"
										: "undecided"
								}
								currentStatus={status?.requestApproved === "approved"}
								statusRejected={status?.requestApproved === "rejected"}
							/>
						) : (
							<TrackerText
								text={
									getApprovalStatus() === "pending"
										? "awaiting approval from investor"
										: getApprovalStatus() === "approved"
										? "request approved"
										: getApprovalStatus() === "rejected"
										? "request rejected"
										: "undecided"
								}
								currentStatus={getApprovalStatus() === "approved"}
								statusRejected={
									getApprovalStatus() === "rejected" ||
									getApprovalStatus() === "undecided"
								}
							/>
						)}
						{leaseType.toLowerCase().includes("sponsored") &&
							leaseSource === "products-catalogue" && (
								<TrackerText
									text="asset funded"
									currentStatus={status.funded}
								/>
							)}
						{leaseType.toLowerCase().includes("quick") &&
							leaseSource !== "market-place" && (
								<TrackerText
									text="asset funded"
									currentStatus={status.funded}
								/>
							)}
						<TrackerText
							text="lease agreement"
							currentStatus={!!status?.leaseAgreement}
							showBtns={showPendingOnLA()}
							showPending={showPendingOnLA()}
							btnText="View Details"
							isBtnDisabled={onProceedToLeaseAgreement()}
							onNavigate={() => navigate(`/leasing/${leaseId}/lease-agreement`)}
						/>
						<TrackerText
							text="payment deducted"
							currentStatus={!!status?.firstPayment?.paid}
						/>
						{deliveryType === "door-delivery" && (
							<TrackerText
								text="delivery information"
								currentStatus={
									status?.deliveryInfo?.deliveryFeeStatus === "paid"
								}
								showBtns={!!status?.leaseAgreement}
								btnText="View Details"
								showPending={showPendingOnDelivery()}
								onNavigate={onViewDeliveryInfo}
							/>
						)}
						{deliveryType === "door-delivery" &&
							status.deliveryInfo?.deliveryStatus === "delivered" && (
								<TrackerText
									text={`delivery status (${
										status?.deliveryInfo?.deliveryStatus || "pending"
									})`}
									currentStatus={!!status?.deliveryInfo?.deliveryStatus}
								/>
							)}
						<TrackerText
							text="asset deployed"
							currentStatus={status.assetDeployed}
						/>
						<TrackerText
							text="Upcoming Repayments"
							currentStatus={status.assetDeployed}
							showBtns
							btnText="View Details"
							onNavigate={onViewUpcomingPayments}
							isBtnDisabled={!status.assetDeployed}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const TrackerText = ({
	text,
	currentStatus,
	showPending,
	showBtns,
	btnText,
	onNavigate,
	isBtnDisabled,
	statusRejected,
}) => {
	// console.log(currentStatus);
	return (
		<div
			className={`flex justify-between items-center h-[42px] md:h-[45px] w-full capitalize rounded-md px-2 sm:px-3 text-[12.5px] md:text-[13.5px] zabalMedFont border flex items-center ${
				currentStatus
					? "text-fortcore-green border-fortcore-green"
					: statusRejected
					? "text-red-500 border-red-500"
					: "border-[#E3E9EC] text-[#717E83]"
			}`}
		>
			<p>{text}</p>
			<div className="flex items-center gap-3 md:gap-6">
				{showPending && (
					<small className="italic text-[#FFC214] zabalRegFont tracking-wide">
						pending
					</small>
				)}
				{showBtns && (
					<>
						<button
							className={`hidden sm:block px-3.5 p-2 rounded-md text-xs zabalMedFont ${
								isBtnDisabled
									? "bg-gray-100 text-gray-400"
									: "bg-[#E3E9EC] text-fortcore-green"
							}`}
							onClick={onNavigate}
							disabled={isBtnDisabled}
						>
							{btnText}
						</button>
						<button
							className={`sm:hidden ${
								isBtnDisabled ? "text-gray-300" : "text-fortcore-green"
							}`}
							onClick={onNavigate}
							disabled={isBtnDisabled}
						>
							<FaArrowRight size={16} />
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export const TrackerRadio = ({ isEnd, currentStatus, statusRejected }) => {
	// console.log(currentStatus);
	return (
		<div className="flex flex-col items-center">
			<div
				className={`rounded-full border h-3.5 md:h-4 w-[14px] md:w-[16px] p-0.5 flex items-center justify-center border ${
					currentStatus
						? "border-fortcore-green"
						: statusRejected
						? "border-red-500"
						: "border-[#C6D3D8]"
				}`}
			>
				<div
					className={`w-full h-full rounded-full ${
						currentStatus
							? "bg-fortcore-green"
							: statusRejected
							? "bg-red-500"
							: "bg-[#C6D3D8]"
					}`}
				></div>
			</div>
			{!isEnd && (
				<div
					className={`w-[2px] h-[40.75px] md:h-[45px] ${
						currentStatus
							? "bg-fortcore-green"
							: statusRejected
							? "bg-red-500"
							: "bg-[#C6D3D8]"
					}`}
				></div>
			)}
		</div>
	);
};

FirstStatusTracker.propTypes = {
	status: PropTypes.object,
	leaseFor: PropTypes.string,
	leaseType: PropTypes.string,
	leaseSource: PropTypes.string,
	leaseItem: PropTypes.object,
};
SecondStatusTracker.propTypes = {
	status: PropTypes.object,
	leaseFor: PropTypes.string,
	leaseType: PropTypes.string,
	leaseSource: PropTypes.string,
	leaseId: PropTypes.string,
	deliveryType: PropTypes.string,
};
TrackerText.propTypes = {
	text: PropTypes.string.isRequired,
	currentStatus: PropTypes.bool.isRequired,
	showPending: PropTypes.bool,
	btnText: PropTypes.string,
	onNavigate: PropTypes.func,
	showBtns: PropTypes.bool,
	isBtnDisabled: PropTypes.bool,
	statusRejected: PropTypes.bool,
};
TrackerRadio.propTypes = {
	isEnd: PropTypes.bool,
	currentStatus: PropTypes.bool.isRequired,
	statusRejected: PropTypes.bool,
};
