export const bardata = [
  { investment: 350000, interest: 100000, month: 'Jan' },
  { investment: 555000, interest: 210000, month: 'Feb' },
  { investment: 720000, interest: 250000, month: 'Mar' },
  { investment: 450000, interest: 50000, month: 'Apr' },
  { investment: 450000, interest: 100000, month: 'May' },
  { investment: 250000, interest: 90000, month: 'Jun' },
  { investment: 300000, interest: 145000, month: 'Jul' },
  { investment: 570000, interest: 200000, month: 'Aug' },
  { investment: 470000, interest: 115000, month: 'Sep' },
  { investment: 200000, interest: 80000, month: 'Oct' },
  { investment: 500000, interest: 250000, month: 'Nov' },
  { investment: 600000, interest: 150000, month: 'Dec' },
];

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        min: 0,
        max: 1000000,
        stepSize: 100000,
        font: { size: 11, family: "'ZabalRegular', sans-serif" },
      },
      grid: {
        tickBorderDash: [500, 20],
        color: '#C6D3D8',
        // tickLength: 10,
        tickColor: 'white',
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: { size: 11, family: "'ZabalRegular', sans-serif" },
      },
    },
  },
};
