import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotifyUser from "../../layout/NotifyUser";
import { closeRegularSavingsAddFundsModal } from "../../redux/travels/travelActions";
import {
	CustomConfirmModal,
	SuccessCheckModal,
} from "../../layout/CustomComponents";
import { EnterPin } from "../payments/PinComponent";
import { onInputAmount } from "../../common/utils";

const AddFundsToRegularSavingsFlow = ({
	activeModal,
	setActiveModal,
	onDeductWallet,
	onDeductWalletSuccess,
	amountToSave,
	setAmountToSave,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [invalidPinError, setInvalidPinError] = useState(false);

	const [inputPin, setInputPin] = useState("");
	const { currentUser } = useSelector((state) => state.auth);
	const { moveFundsLoading } = useSelector((state) => state.payment);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	return (
		<>
			{activeModal === 1 && (
				<AmountToFund
					amountToSave={amountToSave}
					handleChange={(e) => setAmountToSave(e.target.value)}
					onGreenBtnClick={() => setActiveModal(2)}
				/>
			)}
			{activeModal === 2 && (
				<CustomConfirmModal
					text={`The amount of N${amountToSave} will be deducted from your wallet. Click the button to continue.`}
					greenBtnText="Proceed"
					greenBtnClick={() => setActiveModal(3)}
					onCloseBtnClick={() => dispatch(closeRegularSavingsAddFundsModal())}
				/>
			)}
			{activeModal === 3 && (
				<EnterPin
					btnText="Confirm to Pay"
					onCloseBtnClick={() => setActiveModal(2)}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={currentUser?.accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onDeductWallet}
					isPaymentLoading={moveFundsLoading}
				/>
			)}
			{activeModal === 4 && (
				<SuccessCheckModal
					text={`The amount of N${amountToSave} has been added to your savings plan. Keep up the good work.`}
					onRequestClick={onDeductWalletSuccess}
				/>
			)}
		</>
	);
};

export default AddFundsToRegularSavingsFlow;

AddFundsToRegularSavingsFlow.propTypes = {
	activeModal: PropTypes.number,
	setActiveModal: PropTypes.func,
	onDeductWallet: PropTypes.func,
	onDeductWalletSuccess: PropTypes.func,
	amountToSave: PropTypes.string,
	setAmountToSave: PropTypes.func,
};

const AmountToFund = ({ amountToSave, handleChange, onGreenBtnClick }) => {
	const dispatch = useDispatch();

	const minAmount = process.env.NODE_ENV !== "production" ? 1 : 100;

	return (
		<NotifyUser
			header="Add Funds"
			title="Enter amount you want to add to your savings. This amount may be greater than the already stated limit."
			onCloseBtnClick={() => dispatch(closeRegularSavingsAddFundsModal())}
			onWhiteBtnClick={() => dispatch(closeRegularSavingsAddFundsModal())}
			onGreenBtnClick={onGreenBtnClick}
			isGreenBtnDisabled={Number(amountToSave.replaceAll(",", "")) < minAmount}
		>
			<div className="space-y-1 zabalMedFont">
				<p className="text-sm">NGN</p>
				<input
					type="tel"
					className="h-[45px] bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
					placeholder="minimum amount (100)"
					id="amountToDeposit"
					value={amountToSave}
					onChange={handleChange}
					onInput={onInputAmount}
				/>
			</div>
		</NotifyUser>
	);
};

AmountToFund.propTypes = {
	amountToSave: PropTypes.string,
	handleChange: PropTypes.func,
	onGreenBtnClick: PropTypes.func,
};
