import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	InputForAmount,
	ProfileInput,
} from "../../../profile/sections/PersonalInfo";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import { formatAmountToIncludeCurrency } from "../../../../common/utils";
import { saveRequestInformation } from "../../../../redux/travels/travelServices";
import Parse from "../../../../api/ApiConfig";
import { fetchCurrentInterest } from "../../../../redux/lease/leaseServices";

const RequestInformation = () => {
	const dispatch = useDispatch();
	const { requestInfoLoading, requestInfoSuccess } = useSelector(
		(state) => state.travel
	);
	const { currentGeneratedinterest } = useSelector((state) => state.lease);

	const [requestInfo, setRequestInfo] = useState({
		request_amount: requestInfoSuccess?.amount || "",
		equity_contribution: requestInfoSuccess?.equityContribution || "",
		monthly_interest: requestInfoSuccess?.monthlyInterest || "",
		programmed_liquidation: requestInfoSuccess?.programmedLiquidation || "",
		funds_inflow: requestInfoSuccess?.fundsInflowNarration || "",
	});

	useEffect(() => {
		dispatch(fetchCurrentInterest("Base Interest rate Travel Advance"));
	}, []);

	const {
		request_amount,
		equity_contribution,
		monthly_interest,
		programmed_liquidation,
		funds_inflow,
	} = requestInfo;

	const handleChange = (e) =>
		setRequestInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const isBtnDisabled = () => {
		if (request_amount === "" || funds_inflow === "") {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (currentGeneratedinterest) {
			setRequestInfo((prev) => ({
				...prev,
				monthly_interest: currentGeneratedinterest?.value?.replace("%", ""),
			}));
		}
	}, [currentGeneratedinterest]);

	useEffect(() => {
		const equity = (20 / 100) * Number(request_amount?.replaceAll(",", ""));
		const liquidation = (5 / 100) * Number(request_amount?.replaceAll(",", ""));
		setRequestInfo((prev) => ({
			...prev,
			equity_contribution: formatAmountToIncludeCurrency(undefined, equity),
			programmed_liquidation: formatAmountToIncludeCurrency(
				undefined,
				liquidation
			),
		}));
	}, [request_amount]);

	const interestAmount =
		(monthly_interest / 100) * Number(request_amount.replaceAll(",", ""));

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onRequestInfoSave = () => {
		const data = {
			userId: user,
			amount: request_amount,
			equityContribution: equity_contribution,
			monthlyInterest: monthly_interest,
			programmedLiquidation: programmed_liquidation,
			fundsInflowNarration: funds_inflow,
		};
		dispatch(saveRequestInformation(data));
	};

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<InputForAmount
				labelText="Funding Request Amount"
				id="request_amount"
				value={request_amount}
				handleChange={handleChange}
				placeholderText="Enter funding request amount"
				type="tel"
			/>
			<InputForAmount
				labelText="Equity Contribution"
				id="equity_contribution"
				value={`${equity_contribution} at %${Number("20").toFixed(2)}`}
				handleChange={handleChange}
				placeholderText="20% of amount funded"
				isDisabled
				color
			/>
			<ProfileInput
				type="text"
				text="Monthly Interest"
				value={`${formatAmountToIncludeCurrency(
					"NGN",
					interestAmount
				)} at %${Number(monthly_interest).toFixed(2)}`}
				placeholderText="Auto generated"
				isFieldDisabled
				color
			/>
			<InputForAmount
				labelText="Programmed Liquidation"
				value={programmed_liquidation}
				id="programmed_liquidation"
				handleChange={handleChange}
				placeholderText="Auto generated"
				isDisabled={true}
				color
			/>
			<ProfileInput
				type="text"
				text="Funds Inflow Narration"
				value={funds_inflow}
				id="funds_inflow"
				placeholderText="Prove funds inflow narration"
				handleChange={handleChange}
				isToolip={true}
				toolipText="How your income is being generated."
			/>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || requestInfoLoading}
					isLoading={requestInfoLoading}
					onRequestClick={onRequestInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || requestInfoLoading}
					isLoading={requestInfoLoading}
					onRequestClick={onRequestInfoSave}
				/>
			</div>
		</div>
	);
};

export default RequestInformation;
