import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FaBoxOpen } from "react-icons/fa";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import Parse from "../../../api/ApiConfig";
import { networkErrorTrue } from "../../../redux/authRedux/authActions";
import { Preloader } from "../../../components/globalStyled";
import NetworkError from "../../../components/networkerror/NetworkError";
import ModalContainer from "../../../components/ModalContainer";
import { EnterPin } from "../../../components/payments/PinComponent";
import { logoutUser } from "../../../redux/authRedux/authServices";
import { walletType } from "../../../common/utils";
import { deductPaymentFromUserWallet } from "../../../redux/payments/paymentServices";
import {
	updateInvestorCreatedAsset,
	updateOngoingLeaseInParse,
} from "../../../redux/lease/leaseServices";
import { clearPaymentDeductionSuccess } from "../../../redux/payments/paymentActions";
import { clearUpdateLeaseSuccess } from "../../../redux/lease/leaseActions";

const LeaseAgreement = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { networkError, currentUser } = useSelector((state) => state.auth);
	const { updateOngoingTransactionLoading, updateOngoingTransactionSuccess } =
		useSelector((state) => state.lease);
	const {
		customerWallet,
		walletDetails,
		deductWalletLoading,
		deductWalletSuccess,
	} = useSelector((state) => state.payment);

	const userName = `${currentUser?.firstName} ${currentUser?.lastName}`;

	const [data, setData] = useState(null);
	const [signature, setSignature] = useState(userName || "");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");
	const [signAgreementFlow, setSignAgreementFlow] = useState({
		toggleModal: false,
		currentTab: 1,
	});

	const { toggleModal, currentTab } = signAgreementFlow;

	const accessPin = currentUser?.accessPin || "";

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const fetchLeaseWithQuery = async () => {
		// console.log("before fetch", Parse.User.current().getSessionToken());
		try {
			setLoading(true);
			setError(null);

			const query = new Parse.Query("Lease");
			query
				.equalTo("userId", Parse.User.current()?.id)
				.equalTo("objectId", params.id);
			const response = await query.find();
			// console.log("after fetch", Parse.User.current().getSessionToken());
			setData(response[0]?.toJSON());
		} catch (error) {
			if (error?.message === "Network Error") {
				dispatch(networkErrorTrue("Network Error"));
			}
			console.log(error);
			if (error.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			} else if (error?.message?.includes("Invalid session token")) {
				toast.error("This session has expired. Please login.");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			} else {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setSignature(data?.leaseAgreement?.signature || "");
	}, [data]);

	const currentRequest = data?.usersRequest?.find(
		(a) => a?.transactionId === params?.id
	);

	const onProceedToLeaseAgreement = () => {
		if (data?.leaseType.toLowerCase().includes("quick")) {
			if (
				data?.leaseSource === "market-place" &&
				data?.basicAdminApproval?.status?.includes("Approv") &&
				data?.firstLineApproval?.status?.includes("Approv") &&
				data?.secondLineApproval?.status?.includes("Approv") &&
				data?.finalApproval?.status?.includes("Approv") &&
				data?.financeApproval?.status?.includes("Approv") &&
				data?.funded
			) {
				return false;
			} else if (
				data?.leaseSource !== "market-place" &&
				data?.basicAdminApproval?.status?.includes("Approv") &&
				data?.firstLineApproval?.status?.includes("Approv") &&
				data?.secondLineApproval?.status?.includes("Approv") &&
				data?.finalApproval?.status?.includes("Approv") &&
				data?.funded
			) {
				return false;
			} else {
				return true;
			}
		} else if (
			data?.leaseSource === "market-place" &&
			data?.leaseType.toLowerCase().includes("sponsored")
		) {
			if (data?.funded && currentRequest?.status === "approved") {
				return false;
			} else {
				return true;
			}
		} else if (
			data?.leaseSource === "products-catalogue" &&
			data?.leaseType.toLowerCase().includes("sponsored")
		) {
			if (data?.funded) {
				// console.log(data?, "here");
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const onModalClose = () => {
		setSignAgreementFlow((prev) => ({
			...prev,
			toggleModal: false,
		}));
	};

	const monPayment = Number(data?.monthlyPayment) || 0;
	const oofPayment = Number(data?.oneOffPayment) || 0;

	const amountToPay = monPayment + oofPayment;

	const amount = process.env.NODE_ENV !== "production" ? 1 : amountToPay;

	const confirmModalText = `You have accepted the terms of lease. The amount of N${amount.toLocaleString()} will be deducted for your first payment.`;

	const onSubmit = () => {
		if (onProceedToLeaseAgreement()) {
			toast.error("You have not been authorized to sign this aggreement yet.");
			return;
		}
		setSignAgreementFlow((prev) => ({
			...prev,
			toggleModal: true,
			currentTab: 1,
		}));
	};

	const onProceedToMakePayment = () => {
		if (!Parse.User.current()?.id) {
			dispatch(logoutUser());
			return;
		}

		if (mainWallet?.availableBalance < amountToPay) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const details = {
			walletId: walletDetails?.id,
			amount: amount,
			narration: "first-lease-payment",
		};

		dispatch(
			deductPaymentFromUserWallet(
				details,
				"first-lease-payment-success",
				"for your first lease payment"
			)
		);
	};

	// show error on invalid access pin
	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	// clear success and proceed
	const onProceedToSubmittedTransactions = () => {
		dispatch(clearUpdateLeaseSuccess());
		navigate("/leasing/submitted-transactions");
	};

	// fetch lease with query
	useEffect(() => {
		fetchLeaseWithQuery();
	}, [params.id]);

	const asset = data?.assetObj;

	// update lease in parse
	useEffect(() => {
		if (deductWalletSuccess === "first-lease-payment-success") {
			dispatch(clearPaymentDeductionSuccess());

			const data = {
				leaseAgreement: { signature, date: new Date().toISOString() },
				firstPayment: { amount: amountToPay, paid: true },
			};

			dispatch(
				updateOngoingLeaseInParse(
					params.id,
					data,
					"Agreement signed and payment deduction",
					"agreement-signed-and-payment-deduction"
				)
			);

			if (asset) {
				const User = Parse.Object.extend("_User");
				const user = new User();
				user.id = asset?.userId?.objectId || asset?.userId;

				const message = "Lease agreement signed and payment made";
				const metaData = {
					category: "Asset",
					linkToId: asset?.id,
					moreDetails: `${currentRequest?.name} has signed the lease agreement and has made the first monthly payment`,
				};
				dispatch(
					updateInvestorCreatedAsset(
						asset?.id,
						data,
						message,
						metaData,
						asset?.userId
					)
				);
			}
		} else {
			return;
		}
	}, [deductWalletSuccess]);

	// set modal to success message on complete transaction
	useEffect(() => {
		if (
			updateOngoingTransactionSuccess ===
			"Agreement signed and payment deduction"
		) {
			setSignAgreementFlow((prev) => ({
				...prev,
				currentTab: 3,
			}));
		} else {
			return;
		}
	}, [updateOngoingTransactionSuccess]);

	useEffect(() => {
		setSignature(
			data?.leaseAgreement
				? data?.leaseAgreement?.signature
				: userName
				? userName
				: ""
		);
	}, [data]);

	return (
		<>
			{networkError && <NetworkError />}
			{loading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}
			<>
				{toggleModal && (
					<ModalContainer modal={toggleModal}>
						<>
							{currentTab === 1 && (
								<CustomConfirmModal
									onCloseBtnClick={onModalClose}
									text={confirmModalText}
									greenBtnText="proceed"
									greenBtnClick={() =>
										setSignAgreementFlow((prev) => ({
											...prev,
											currentTab: 2,
										}))
									}
								/>
							)}
							{currentTab === 2 && (
								<EnterPin
									btnText="proceed"
									onCloseBtnClick={onModalClose}
									onInputChange={setInputPin}
									isPinInvalid={invalidPinError}
									isAllowedToProceed={
										inputPin.length > 3 && inputPin === accessPin
									}
									isPinExist={currentUser?.accessPin !== undefined}
									proceedToCreatePin={() =>
										navigate("/dashboard/profile", { state: { currentTab: 2 } })
									}
									isPaymentLoading={
										deductWalletLoading || updateOngoingTransactionLoading
									}
									onRequestClick={onProceedToMakePayment}
								/>
							)}
							{currentTab === 3 && (
								<SuccessCheckModal
									text="Lease Agreement has been successfully signed and payment has been deducted from your wallet."
									isShowBtn={true}
									btnText="okay"
									onRequestClick={onProceedToSubmittedTransactions}
								/>
							)}
						</>
					</ModalContainer>
				)}
			</>
			<Helmet>
				<title>Fortcore - Lease Agreement</title>
			</Helmet>
			<MobileNavDetails text="submitted transactions" />
			<Container>
				<>
					<Navbar text="submitted transactions" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="submitted transactions"
							onNavigate={() => navigate("/leasing/submitted-transactions")}
						/>
						<UrlTracker text="lease agreement" isCurrentUrl />
					</div>
					<BackTracker text="lease agreement" onNavigate={() => navigate(-1)} />
					<div className="sm:my-6"></div>
					{error ? (
						<NoRequestFound />
					) : (
						<div className="rounded-md p-4 sm:p-5 lg:p-6 bg-[#F6F7F8] space-y-4 sm:space-y-5">
							<p className="text-sm capitalize text-fortcore-green zabalMedFont">
								lease agreement
							</p>
							<div className="space-y-1.5 text-[14.5px] text-justify">
								<p className="leading-7">
									This Document (this &quot;Agreement&quot;) is executed and
									delivered as of the date of execution by undersigned,
									(hereinafter referred to as &quot;Investor&quot;). For and in
									consideration of the mutual covenants contained herein, and
									other good and valuable consideration, the receipt and
									sufficiency of which is hereby acknowledged, Investor hereby
									constitutes and appoints Fortcore, a technology company, as
									Investor&apos;s true and lawful attorney-in-fact and agent,
									with full power of substitution and resubstitution, for
									Investor and in Investor&apos;s name, place and stead, in any
									and all capacities, to perform the following acts and things:
								</p>
								<ol className="list-decimal px-4 text-sm leading-6">
									<li>
										To transact all business and sign, execute, acknowledge and
										deliver all such instruments, agreements, and documents, and
										to take all such actions, as may be necessary or appropriate
										to effect the transactions described in this Agreement.
									</li>
									<li>
										To access, monitor and control Investor&apos;s portfolio of
										investments, including, without limitation, the power to buy
										and sell securities, transfer assets, and make decisions
										regarding portfolio management.
									</li>
									<li>
										To exercise all voting rights associated with the securities
										in Investor&apos;s portfolio.
									</li>
								</ol>
								<p className="leading-7">
									This power of attorney shall be binding upon Investor and
									shall inure to the benefit of Investor&apos;s heirs,
									executors, administrators, successors, and assigns. Investor
									agrees that any person, corporation, or entity which relies
									upon the acts of Fortcore hereunder shall be fully protected
									and indemnified in relying upon the same.
								</p>
								<p className="leading-7">
									This power of attorney shall remain in full force and effect
									until revoked by Investor. Investor may revoke this power of
									attorney at any time by written notice to Fortcore.
								</p>
								<p className="leading-7">
									<span className="zabalBoldFont">IN WITNESS WHEREOF,</span>{" "}
									Investor has executed this Agreement as of the date first
									above written.
								</p>
							</div>
							<div className="pt-5 w-[300px] space-y-1 relative">
								<input
									type="text"
									className={`bg-transparent px-1 placeholder:italic text-black text-[15px] w-full focus:border-fortcore-green pr-[125px] ${
										data?.leaseAgreement?.signature
											? "border border-[#96A0A5] !text-[#B8B8B8] italic p-2 px-3 rounded"
											: "border-b-2"
									}`}
									placeholder="Please type your name"
									value={signature || userName}
									onChange={(e) => setSignature(e.target.value)}
									disabled={true}
								/>
								<p
									className={`absolute right-3 text-sm zabalMedFont ${
										data?.leaseAgreement?.signature
											? "italic text-[#B8B8B8] top-[25px]"
											: "top-[18px]"
									}`}
								>
									{data?.leaseAgreement
										? new Date(`${data?.leaseAgreement?.date}`).toDateString()
										: new Date().toDateString()}
								</p>
								<p className="text-[13px] md:text-sm zabalMedFont text-center">
									[Signature]
								</p>
							</div>
							<div className="hidden sm:flex justify-end">
								<CustomGreenBtn
									text="accept"
									width="95px"
									height="42px"
									fontSize="14.5px"
									onRequestClick={onSubmit}
									isDisabled={!!data?.leaseAgreement?.signature}
								/>
							</div>
							<div className="sm:hidden py-2">
								<CustomGreenBtn
									text="accept"
									fontSize="14px"
									onRequestClick={onSubmit}
									isDisabled={!!data?.leaseAgreement?.signature}
								/>
							</div>
						</div>
					)}
				</>
			</Container>
		</>
	);
};

export default LeaseAgreement;

export const NoRequestFound = () => {
	const navigate = useNavigate();

	return (
		<div className="bg-white h-[400px] md:h-[450px] w-full grid place-items-center">
			<div className="flex flex-col justify-center items-center gap-4 text-center max-w-[320px] md:max-w-[330px]">
				<div className="flex flex-col justify-center items-center gap-3">
					<FaBoxOpen size={40} color="#00525D" />
					<h5 className="font-semibold">No Request Found.</h5>
				</div>
				<div className="flex flex-col justify-center items-center gap-5 text-sm md:text-[14.5px]">
					<p>
						No request matches the query provided. Please confirm the query or
						proceed to a create a new transaction.
					</p>
					<CustomGreenBtn
						text="proceed"
						onRequestClick={() => navigate("/leasing/new-transaction")}
						width="105px"
						height="40px"
						fontSize="14.5px"
					/>
				</div>
			</div>
		</div>
	);
};
