import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	Skeleton,
	Stack,
	TextField,
	Typography,
	Tooltip,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { BiSearch } from "react-icons/bi";
import { AiFillPlusCircle } from "react-icons/ai";
import { useTheme } from "@mui/material/styles";
import { CustomTwoGridBox } from "../component/Components";
import { useNavigate } from "react-router-dom";
import { PiSmileySadDuotone } from "react-icons/pi";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoMdCart } from "react-icons/io";
import BrowseFormContext from "../BrowseFormContext";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import Navbar from "../../../../../layout/Navbar";
import { NoDataIcon } from "../../../../../layout/assets";
import {
	FatBackTracker,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import { MobileLeaseCartBtn } from "../../../../leasing/marketplace/MarketPlace";
import { fetchUserMarketPlaceData } from "../../../../../redux/financing/financeServices";
import { useDispatch } from "react-redux";
import { networkErrorTrue } from "../../../../../redux/authRedux/authActions";
import { toast } from "react-toastify";
import {
	onClearUserFinanceRequest,
	onSaveDataFromMarketPlace,
} from "../../../../../redux/financing/financeActions";
import userContext from "../../../../../context/UserContext";

function MarketPlace() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const [searchQuery, setSearchQuery] = useState("");
	const [loading, setLoading] = useState(true);

	const { afRate } = useContext(userContext);
	const { afBusinessClass, setAfBusinessClass, setMarketPlace } =
		useContext(BrowseFormContext);

	const getFinanceMarketPlaceData = async () => {
		setLoading(true);
		try {
			const response = await fetchUserMarketPlaceData();

			// const data = response.filter((req) => req?.investorInfo?.);

			console.log(response);
			setAfBusinessClass(response);
		} catch (error) {
			if (error.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			}
			if (error?.message?.includes("Invalid session token")) {
				localStorage.clear();
				toast.error("This session has expired. Please Login again");
				setTimeout(() => {
					window.location.href = "/login";
				}, 500);
				return;
			} else {
				toast.error(error?.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleSelectRequest = (item) => {
		dispatch(onSaveDataFromMarketPlace(item));
		setMarketPlace((prevMarketPlace) => ({
			...prevMarketPlace,

			amount: item?.amountToInvest,
			businessType: item?.businessType,
			interest: item.interest,
			duration: item.tenure,
			commitmentFee: item.commitmentFee,
			expectedProfit: item.expectedProfit,
			programSavings: item.programSavings,
			programSavingsRate: item.programSavingsRate,
			type: item.type,
			interestRate: item.interestRate,
		}));

		navigate("/financing/new-transaction/market-place/form");
	};

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredData = afBusinessClass.filter((item) =>
		item.businessType
			.toLocaleString()
			.toLowerCase()
			.includes(searchQuery.toLowerCase())
	);

	useEffect(() => {
		getFinanceMarketPlaceData();
		dispatch(onClearUserFinanceRequest());
	}, []);

	return (
		<>
			<MobileNavDetails text="Finance" />
			<Container>
				<Helmet>
					<title>Fortcore - Finance</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="hidden sm:flex gap-1.5 sm:mt-10">
					<UrlTracker
						text="apprenticeship finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="new transaction"
						onNavigate={() => navigate("/financing/new-transaction")}
					/>
					<UrlTracker text="market place" isCurrentUrl />
				</div>
				<div className="mb-3 flex items-center justify-between sm:hidden zabalMedFont">
					<FatBackTracker
						text="market place"
						onNavigate={() => navigate("/financing/new-transaction")}
					/>
					<MobileLeaseCartBtn
						text="new request"
						onLeaseNavigate={() =>
							navigate("/financing/new-transaction/request")
						}
					/>
				</div>
				<div className="mt-8 sm:mt-10"></div>

				<Box sx={{ background: "#F6F7F8", padding: "20px" }}>
					<Box pb={5}>
						<Stack direction="row" spacing={2}>
							<TextField
								placeholder="Search"
								value={searchQuery}
								onChange={handleSearchChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end" aria-label="search">
												<BiSearch />
											</IconButton>
										</InputAdornment>
									),
								}}
								sx={{ width: "100%" }}
							/>
							<IconButton
								sx={{
									display: { xs: "none", sm: "flex" },
									flexDirection: "column",
									alignItems: "center",

									"&:hover": {
										background: "transparent",
									},
								}}
								onClick={() => navigate("/financing/new-transaction/request")}
							>
								<AiFillPlusCircle
									size={24}
									style={{ color: theme.palette.primary.main }}
								/>
								<Typography variant="subtitle2" color="primary.main">
									New Request
								</Typography>
							</IconButton>
							<IconButton
								sx={{
									display: { xs: "none", sm: "flex" },
									flexDirection: "column",
									alignItems: "center",
									"&:hover": {
										background: "transparent",
									},
								}}
							>
								<IoMdCart
									size={24}
									style={{ color: theme.palette.primary.main }}
								/>
								<Typography variant="subtitle2" color="primary.main">
									Cart
								</Typography>
							</IconButton>
						</Stack>
					</Box>

					{/* Content  */}
					{loading ? (
						<CustomTwoGridBox>
							<Skeleton
								variant="rounded"
								width={{ xs: "100%", sm: 290 }}
								height={233}
							/>
							<Skeleton
								variant="rounded"
								width={{ xs: "100%", sm: 290 }}
								height={233}
							/>
							<Skeleton
								variant="rounded"
								width={{ xs: "100%", sm: 290 }}
								height={233}
							/>
							<Skeleton
								variant="rounded"
								width={{ xs: "100%", sm: 290 }}
								height={233}
							/>
						</CustomTwoGridBox>
					) : (
						<>
							{afBusinessClass.length > 0 ? (
								<>
									{filteredData.length > 0 ? (
										<CustomTwoGridBox>
											{filteredData.map((item, index) => (
												<Box key={index}>
													<Box
														sx={{
															background: theme.palette.secondary.main,
															border: "1px solid var(--grey-500, #B8C8CF)",
															borderRadius: "8px",
															padding: "20px",
														}}
													>
														<Stack spacing={1.5}>
															<Typography variant="body1" color="initial">
																Amount: &nbsp;
																<Typography
																	variant="h4"
																	color="initial"
																	component="span"
																>
																	NGN {item?.amountToInvest}
																</Typography>
															</Typography>
															<Typography
																variant="h4"
																color="initial"
																sx={{ textTransform: "capitalize" }}
															>
																Business Interest: {item.businessType}
															</Typography>
															<Typography
																variant="body1"
																color="initial"
																sx={{ textTransform: "capitalize" }}
															>
																Interest: NGN{" "}
																{item?.interestRate || afRate?.interest?.value}
															</Typography>
															<Typography variant="body1" color="initial">
																Tenure: {item?.duration}
															</Typography>
															<Box>
																<Stack
																	direction="row"
																	justifyContent="space-between"
																	alignItems="center"
																>
																	<Tooltip
																		title="More details about this finance"
																		placement="top"
																		arrow
																	>
																		<Stack
																			direction="row"
																			alignItems="center"
																			spacing={1}
																		>
																			<BsInfoCircleFill color="#AABDC5" />
																			<Typography
																				variant="caption"
																				color="primary"
																			>
																				Details
																			</Typography>
																		</Stack>
																	</Tooltip>

																	<IconButton
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			alignItems: "center",

																			"&:hover": {
																				background: "transparent",
																			},
																		}}
																		onClick={() => handleSelectRequest(item)}
																	>
																		<AiFillPlusCircle
																			size={24}
																			style={{
																				color: theme.palette.primary.main,
																			}}
																		/>
																		<Typography
																			variant="subtitle2"
																			color="primary.main"
																		>
																			Request
																		</Typography>
																	</IconButton>
																</Stack>
															</Box>
														</Stack>
													</Box>
												</Box>
											))}
										</CustomTwoGridBox>
									) : (
										<Box>
											<Stack
												alignItems="center"
												spacing={2}
												height="30vh"
												justifyContent="center"
											>
												<PiSmileySadDuotone size={35} />
												<Typography variant="body1" color="textSecondary">
													What you are looking for is not available
												</Typography>
											</Stack>
										</Box>
									)}
								</>
							) : (
								<Box>
									<Stack
										alignItems="center"
										spacing={2}
										height="45vh"
										justifyContent="center"
									>
										<NoDataIcon />
										<Typography variant="body1" color="textSecondary">
											This place is empty
										</Typography>
									</Stack>
								</Box>
							)}
						</>
					)}
				</Box>
			</Container>
		</>
	);
}

export default MarketPlace;
