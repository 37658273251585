import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Navbar from "../../../layout/Navbar";
import {
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import Container from "../../../layout/Container";
import { useNavigate, useParams } from "react-router-dom";
import Criteria from "../../../components/guarantor/Criteria";
import Form from "../../../components/guarantor/Form";
import ModalContainer from "../../../components/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
	closeAuthorizeGuarantorModal,
	networkErrorTrue,
} from "../../../redux/authRedux/authActions";
import { getGuarantorsDataFromParse } from "../../../redux/authRedux/authServices";
import { Preloader } from "../../../components/globalStyled";
import Parse from "../../../api/ApiConfig";
import NetworkError from "../../../components/networkerror/NetworkError";
import { NoRequestFound } from "../submittedtransactions/LeaseAgreement";

const UserGuarantor = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const {
		toggleAuthorizeGuarantorModal,
		guarantorsFormSuccess,
		// fetchGuarantorsFormLoading,
		networkError,
	} = useSelector((state) => state.auth);

	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(1);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchLeaseWithQuery = async () => {
		try {
			setLoading(true);
			const query = new Parse.Query("Lease");
			query
				.equalTo("userId", Parse.User.current()?.id)
				.equalTo("objectId", params.id);
			const response = await query.find();
			setData(response[0]?.toJSON());
		} catch (error) {
			if (error?.message === "Network Error") {
				dispatch(networkErrorTrue("Network Error"));
			}
			console.log(error);
			if (error.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			} else {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line no-undef
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [activeTab]);

	const onCloseSuccessModal = () => {
		navigate(-1);
		setTimeout(() => {
			dispatch(closeAuthorizeGuarantorModal());
		}, 500);
	};

	console.log(data);

	console.log(guarantorsFormSuccess?.get("signature"));

	const [information, setInformation] = useState({
		signature: "",
		name_one: "",
		name_two: "",
		email_one: "",
		email_two: "",
		phone_one: "",
		phone_two: "",
	});

	const handleChange = (e) =>
		setInformation((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	// fetch lease with query and guaranntor
	useEffect(() => {
		dispatch(getGuarantorsDataFromParse());
		fetchLeaseWithQuery();
	}, [params.id]);

	return (
		<>
			{networkError && <NetworkError />}
			{loading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}
			{toggleAuthorizeGuarantorModal && (
				<ModalContainer modal={toggleAuthorizeGuarantorModal}>
					<SuccessCheckModal
						text="Guarantors Submitted Successfully"
						isShowBtn={true}
						btnText="proceed"
						onRequestClick={onCloseSuccessModal}
					/>
				</ModalContainer>
			)}
			<Helmet>
				<title>Fortcore - Ongoing Transactions</title>
			</Helmet>
			<MobileNavDetails text="Guarantor" />
			<Container>
				<>
					<Navbar text="ongoing transactions" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="ongoing transactions"
							onNavigate={() => navigate("/leasing/ongoing-transactions")}
						/>
						<UrlTracker text="guarantor" isCurrentUrl />
					</div>
					<div className="mb-10 sm:my-6 relative">
						{error ? (
							<NoRequestFound />
						) : (
							<>
								{activeTab === 1 && (
									<Criteria
										setActiveTab={setActiveTab}
										handleChange={handleChange}
										signature={information?.signature}
									/>
								)}
								{activeTab === 2 && (
									<Form
										setActiveTab={setActiveTab}
										handleChange={handleChange}
										information={information}
										setInformation={setInformation}
										query={params.id}
									/>
								)}
							</>
						)}
					</div>
				</>
			</Container>
		</>
	);
};
export default UserGuarantor;
