import React from "react";
import PropTypes from "prop-types";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { QuestionMarkIcon } from "../../../../layout/assets";
import Parse from "../../../../api/ApiConfig";
import { toast } from "react-toastify";

function Terminate({ data, setTerminateModal, setSubmitted }) {
	const fetchSubmittedTravel = async () => {
		try {
			const currentUser = Parse.User.current();

			if (currentUser) {
				// const userData = currentUser.toJSON();
				const query = new Parse.Query("TravelAdvance");
				query.equalTo("userId", currentUser);
				query.include("personal");
				query.include("detailsOfRequest");
				query.include("travel");
				query.descending("updatedAt");
				const results = await query.find();
				const applications = results.map((result) => result.toJSON());

				// eslint-disable-next-line no-undef
				console.log("Travel:", applications);
				setSubmitted(applications);
			}
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
			toast.error(error.message);
		}
	};

	const terminateBtn = async () => {
		const currentUser = Parse.User.current();

		if (currentUser) {
			try {
				let TravelAdvance = new Parse.Object("TravelAdvance");
				TravelAdvance.set("objectId", data.objectId);

				TravelAdvance.set("transactionStatus", "cancelled");
				await TravelAdvance.save();
				setTerminateModal(false);
				toast.success("Request Terminated");
				fetchSubmittedTravel();
			} catch (error) {
				toast.error(error.message);
			} finally {
				setTerminateModal(false);
			}
		}
	};

	// eslint-disable-next-line no-undef
	// console.log(data.transactionStatus);
	return (
		<>
			<Box sx={{ overflow: "auto" }}>
				<Stack direction="row" alignItems="center" justifyContent="flex-end">
					<IconButton
						onClick={() => {
							setTerminateModal(false);
							// setVerify(false);
						}}
						color="primary">
						<MdClose />
					</IconButton>
				</Stack>

				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={2}>
					<QuestionMarkIcon />
					<Stack spacing={1}>
						<Typography variant="body1" align="center">
							Are you sure you want to terminate this request?
						</Typography>
						<Typography variant="body1" align="center">
							This action can&apos;t be undone.
						</Typography>
					</Stack>
					<Button
						fullWidth
						variant="contained"
						color="error"
						type="button"
						sx={{ "&:hover": { background: "#D32F2E" } }}
						onClick={terminateBtn}>
						Yes, Terminate
					</Button>
				</Stack>
			</Box>
		</>
	);
}

Terminate.propTypes = {
	data: PropTypes.any,
	setTerminateModal: PropTypes.func,
	setSubmitted: PropTypes.array,
};

export default Terminate;
