import React, { useEffect } from "react";
import HeroSection from "./components/HeroSection";
import PowerSection from "./components/Power";
import SecureSection from "./components/SecureSection";
import LeaseSection from "./components/LeaseSection";
import AccountProcess from "./components/AccountProcess";
import AccessSection from "./components/AccessSection";
import Cta from "./components/Cta";
import Footer from "./components/Footer/Footer";
import HeaderNav from "../../components/header/HeaderNav";

function LandingPage() {
	useEffect(() => {
		// eslint-disable-next-line no-undef
		// window.scrollTo(0, 0);
		window.scrollTo({
			behavior: "smooth",
			top: 0,
		});
	}, []);

	return (
		<>
			<HeaderNav />
			<HeroSection />
			<PowerSection />
			<SecureSection />
			<LeaseSection />
			<AccountProcess />
			<AccessSection />
			<Cta />
			<Footer />
		</>
	);
}

export default LandingPage;
