import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import { FiUpload, FiX } from "react-icons/fi"; // Import the delete icon
import theme from "../../../../../../components/mui/theme";
import { toast } from "react-toastify";
import { useUploadMultipleFiles } from "../../../../../../components/customhooks/useUploadFile";
import { Preloader } from "../../../../../../components/globalStyled";

const supportedFileTypes = ["image/jpg", "image/png", "image/jpeg"];

export const BusinessPhotoComponent = ({ onFileUpload, images }) => {
	const fileInputRef = useRef(null);
	const [businessPhotos, setBusinessPhotos] = useState([]);

	const handleFileChange = (e) => {
		const files = e.target.files;
		const selectedFiles = [...images]; // Create a copy of the existing images

		// console.log(files);
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (supportedFileTypes.includes(file.type)) {
				if (selectedFiles.length < 4) {
					selectedFiles.push(file);
				} else {
					toast.error("Maximum image upload limit reached (4 images).");
					fileInputRef.current.disabled = true;
				}
			} else {
				toast.error(
					"Unsupported file type. Please select only JPEG or PNG files."
				);
			}
		}

		setBusinessPhotos(selectedFiles);

		// onFileUpload(selectedFiles);
	};

	console.log(businessPhotos);

	const handleImageDelete = (index) => {
		const selectedFiles = [...images]; // Create a copy of the existing images

		const newF = selectedFiles.filter((a) => a?.url !== index); // Remove the image at the specified index
		// selectedFiles.splice(index, 1); // Remove the image at the specified index
		onFileUpload(newF);
		// onFileUpload(selectedFiles);
		// setBusinessPhotos(selectedFiles);
	};

	const { loading, fileSuccess } = useUploadMultipleFiles(businessPhotos);

	useEffect(() => {
		if (fileSuccess) {
			onFileUpload(fileSuccess);
			setBusinessPhotos([]);
		}
	}, [fileSuccess]);

	return (
		<Box
			width="100%"
			height="66px"
			sx={{
				borderRadius: "8px",
				border: "1px solid var(--grey-1100, #96A0A5)",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "10px",
			}}
		>
			<Box width="100%" sx={{ overflow: "auto" }}>
				{images.length > 0 && (
					<Box
						height="60px"
						maxWidth="100%"
						sx={{ display: "flex", flexWrap: "wrap" }}
					>
						{images.map((image, index) => (
							<Box key={index} m={1} position="relative">
								<img
									// eslint-disable-next-line no-undef
									src={image?.url}
									// src={URL.createObjectURL(image)}
									alt={`Image ${index + 1}`}
									style={{
										height: "45px",
										width: "100%",
										objectFit: "contain",
										borderRadius: 2,
									}}
								/>
								<Box
									position="absolute"
									top="-7px"
									right="-6px"
									onClick={() => handleImageDelete(image?.url)}
									// onClick={() => handleImageDelete(index)}
									style={{ cursor: "pointer" }}
								>
									<FiX style={{ color: theme.palette.error.main }} />
								</Box>
							</Box>
						))}
					</Box>
				)}
			</Box>

			<Button
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					border: "1px solid var(--grey-1100, #96A0A5)",
					borderRadius: "8px",
					padding: "8px",
					width: { xs: "80px", sm: "170px" },
				}}
				onClick={() => fileInputRef.current.click()}
				disabled={images.length === 4}
			>
				{loading ? (
					<Preloader />
				) : (
					<>
						<FiUpload style={{ color: theme.palette.primary.main }} size={20} />
						&nbsp;
						<span className="hidden sm:inline zabalMedFont text-[13px]">
							Upload Image
						</span>
					</>
				)}
			</Button>

			<input
				type="file"
				ref={fileInputRef}
				style={{ display: "none" }}
				onChange={handleFileChange}
				accept=".jpg, .png"
				multiple
				max="4"
			/>
		</Box>
	);
};

BusinessPhotoComponent.propTypes = {
	onFileUpload: PropTypes.func,
	images: PropTypes.array,
};
