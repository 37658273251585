import React, { useEffect } from "react";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Balance from "../../../components/Balance";
import { useNavigate } from "react-router-dom";
import {
	formatAmountToIncludeCurrency,
	walletType,
} from "../../../common/utils";
import { MdKeyboardArrowRight } from "react-icons/md";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeaseRegularSavings } from "../../../redux/lease/leaseServices";
import EmptyData from "../../../components/EmptyData";
import { CustomWhiteBtn } from "../../../layout/CustomButtons";
import moment from "moment";
import { clearPaymentDeductionSuccess } from "../../../redux/payments/paymentActions";
import {
	clearRegularSavings,
	clearRegularSavingsCreatedSuccess,
	openConfirmAutoDebitModal,
} from "../../../redux/lease/leaseActions";

const RegularSavingsView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { regularSavingsLoading, regularSavingsSuccess } = useSelector(
		(state) => state.lease
	);
	const { customerWallet } = useSelector((state) => state.payment);

	const regularSavingsWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.regularSavings
	);

	useEffect(() => {
		dispatch(fetchLeaseRegularSavings());
		dispatch(clearPaymentDeductionSuccess());
		dispatch(clearRegularSavingsCreatedSuccess());
		dispatch(clearRegularSavings());
	}, []);

	const sortedRegularSavings = regularSavingsSuccess?.sort(
		(planOne, planTwo) =>
			new Date(planTwo.createdAt)?.getTime() -
			new Date(planOne.createdAt)?.getTime()
	);

	return (
		<>
			<MobileNavDetails text="savings" />
			<Helmet>
				<title>Fortcore - Regular Savings View</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="leasing" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<BackTracker text="regular savings" onNavigate={() => navigate(-1)} />
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker text="regular savings" isCurrentUrl />
					</div>
					<div className="sm:mt-6"></div>
					<Balance
						btnText="save now"
						isShowInvestBtn={true}
						onRequestClick={() => dispatch(openConfirmAutoDebitModal("lease"))}
						wallet={regularSavingsWallet}
						cardTitle="Savings Balance"
					/>
					<div className="mt-6 my-3 md:hidden w-full max-w-[400px] sm:max-w-none">
						<CustomWhiteBtn
							text="save now"
							onRequestClick={() => navigate("/leasing/regular-savings")}
						/>
					</div>
					<div className="mt-[25px] sm:mt-[30px]"></div>
					<div
						className={`flex flex-col gap-4 md:gap-5 rounded-md p-4 sm:p-5 mb-10 ${
							!regularSavingsLoading && regularSavingsSuccess?.length === 0
								? "bg-white"
								: "bg-[#F6F7F8]"
						}`}
					>
						{regularSavingsLoading ? (
							Array(2)
								.fill(0)
								.map((_, i) => <SavingsSkeleton key={i} />)
						) : regularSavingsSuccess?.length > 0 ? (
							sortedRegularSavings.map((d) => (
								<RegularSavingsCard
									key={d.objectId}
									data={d}
									isShowBtn={true}
									baseRoute="/leasing/regular-savings"
								/>
							))
						) : (
							<div className="w-full h-[270px] md:h-[250px]">
								<EmptyData text="You have not created any savings yet." />
							</div>
						)}
					</div>
				</>
			</Container>
		</>
	);
};

export default RegularSavingsView;

export const RegularSavingsCard = ({
	data,
	isShowBtn,
	baseRoute,
	showMaturityDate,
}) => {
	const navigate = useNavigate();

	const isPlanExpired = Date.now() > new Date(`${data?.maturityDate}`);

	return (
		<div className="rounded border border-[#B8C8CF] bg-[#fefefe] p-3.5 md:p-5">
			<div
				className={`${
					isShowBtn ? "md:w-11/12 " : "md:w-10/12"
				} flex flex-wrap gap-3 justify-between`}
			>
				<p className="zabalBoldFont text-sm md:text-base text-[#222627]">
					{formatAmountToIncludeCurrency("NGN", data?.amountSaved || 0)}
				</p>
				<div className="flex gap-5 gap-8 lg:gap-20 items-center text-[#393F42]">
					<div className="space-y-2 md:space-y-2.5 zabalRegFont text-xs xl:text-[12.5px]">
						<p>
							Amount to Save: NGN{" "}
							<span className="zabalMedFont text-[13px] xl:text-sm">
								{formatAmountToIncludeCurrency(
									undefined,
									data?.amountToSave?.replaceAll(",", "")
								)}
							</span>
						</p>
						<p>
							Purpose:{" "}
							<span className="zabalMedFont font-medium text-[13px]">
								{data?.purpose || "General"}
							</span>
						</p>
						<p>
							Frequency:{" "}
							<span className="zabalMedFont font-medium text-[13px]">
								{data?.frequency}
							</span>
						</p>
						<p>
							Tenor:{" "}
							<span className="capitalize zabalMedFont font-medium text-[13px]">
								{data?.tenure}
							</span>
						</p>
						<p>
							Interest:{" "}
							<span className="zabalMedFont font-medium text-[13px]">
								{data?.interestRate}%
							</span>
						</p>
						<div className="flex gap-2 text-[11px] xl:text-[11.5px]">
							<p>
								<span className="zabalRegFont">Created At: </span>
								<span className="zabalMedFont">
									{moment(data?.createdAt).format("Do MMM YYYY")}
								</span>
							</p>
							<p>
								<span className="zabalRegFont">Time: </span>
								<span className="zabalMedFont">
									{moment(data?.createdAt).format("hh:mmA")}
								</span>
							</p>
						</div>
						{showMaturityDate && (
							<div className="flex gap-2 text-[11px] xl:text-[11.5px]">
								<p>
									<span className="zabalRegFont">Maturity Date: </span>
									<span className="zabalMedFont">
										{moment(data?.maturityDate).format("Do MMMM YYYY")}
									</span>
								</p>
							</div>
						)}
						<p>
							<span className="zabalRegFont">Status: </span>
							<span
								className={`zabalMedFont ${
									isPlanExpired ? "text-red-500" : "text-green-600"
								}`}
							>
								{isPlanExpired ? "Closed" : "Open"}
							</span>
						</p>
					</div>
					{isShowBtn && (
						<button onClick={() => navigate(`${baseRoute}/${data?.objectId}`)}>
							<MdKeyboardArrowRight color="#00525D" size={24} />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

RegularSavingsCard.propTypes = {
	data: PropTypes.object,
	isShowBtn: PropTypes.bool,
	baseRoute: PropTypes.string,
	showMaturityDate: PropTypes.bool,
};

export const SavingsSkeleton = () => (
	<div className="bg-[#FEFEFE] border border-[#EEF2F3] sm:border-none rounded p-2.5 sm:px-3 sm:py-4 flex flex-col sm:grid md:px-4 lg:px-5 sm:grid-cols-2 lg:flex lg:flex-row gap-4 md:gap-6">
		<div className="grid gap-3 grid-cols-2 w-full max-w-[300px]">
			<Skeleton className="w-full h-[22px] max-w-[200px]" />
		</div>
		<div className="space-y-3 w-full">
			<div className="flex flex-col gap-1">
				<Skeleton className="h-[19px] !w-full max-w-[280px] lg:max-w-[330px]" />
				<Skeleton className="h-[19px] w-full max-w-[300px] lg:!max-w-[330px]" />
				<Skeleton className="h-[18px] w-2/3 max-w-[180px] lg:!max-w-[180px]" />
			</div>
		</div>
	</div>
);
