import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import NotifyUser from "../../../layout/NotifyUser";
import { closeAddFundsModal } from "../../../redux/payments/paymentActions";
import { onInputAmount } from "../../../common/utils";

const Amount = ({
	onCloseModal,
	handleChange,
	amountToDeposit,
	setActivePaymentTab,
}) => {
	const dispatch = useDispatch();

	return (
		<NotifyUser
			header="Amount"
			title="Enter amount you want to fund your wallet"
			onCloseBtnClick={onCloseModal}
			onWhiteBtnClick={() => dispatch(closeAddFundsModal())}
			onGreenBtnClick={() => setActivePaymentTab(2)}
			isGreenBtnDisabled={Number(amountToDeposit.replaceAll(",", "")) < 100}
			btnText="proceed"
		>
			<div className="space-y-1 zabalMedFont">
				<p className="text-sm">NGN</p>
				<input
					type="tel"
					className="h-[45px] bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
					placeholder="enter deposit amount ( min N100 )"
					id="amountToDeposit"
					value={amountToDeposit}
					onChange={handleChange}
					onInput={onInputAmount}
				/>
			</div>
		</NotifyUser>
	);
};

export default Amount;

Amount.propTypes = {
	onCloseModal: PropTypes.func,
	handleChange: PropTypes.func,
	amountToDeposit: PropTypes.string,
	setActivePaymentTab: PropTypes.func,
};
