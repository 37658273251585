import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function Background({ children }) {
	return (
		<Box
			mt={4}
			sx={{
				background: "#F6F7F8",
				borderRadius: "5px",
				width: "100%",
			}}
		>
			{children}
		</Box>
	);
}

Background.propTypes = {
	children: PropTypes.node.isRequired,
};
export default Background;
