import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PaymentOption from "../payments/PaymentOption";
import { onInputAmount } from "../../common/utils";
import NotifyUser from "../../layout/NotifyUser";
import { useSelector } from "react-redux";
import { EnterPin } from "../payments/PinComponent";
import { useNavigate } from "react-router-dom";
import { SuccessCheckModal } from "../../layout/CustomComponents";

const AddFundsToAssetSavingsFlow = ({
	currentTab,
	setCurrentTab,
	onCloseModal,
	amountToSave,
	setAmountToSave,
	isSavingsStarted,
	onDeductWallet,
	onDeductWalletSuccess,
}) => {
	const navigate = useNavigate();
	const [invalidPinError, setInvalidPinError] = useState(false);

	const [inputPin, setInputPin] = useState("");
	const { currentUser } = useSelector((state) => state.auth);
	const { moveFundsLoading } = useSelector((state) => state.payment);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	return (
		<>
			{currentTab === 1 && (
				<PaymentOption
					onWalletSelect={() => setCurrentTab(2)}
					onClose={onCloseModal}
				/>
			)}
			{currentTab === 2 && (
				<AmountToFund
					amountToSave={amountToSave}
					handleChange={(e) => setAmountToSave(e.target.value)}
					setCurrentTab={setCurrentTab}
					isSavingsStarted={isSavingsStarted}
					onClose={onCloseModal}
				/>
			)}
			{currentTab === 3 && (
				<EnterPin
					btnText="Confirm to Pay"
					onCloseBtnClick={() => setCurrentTab(2)}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={currentUser?.accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={() => onDeductWallet(amountToSave)}
					isPaymentLoading={moveFundsLoading}
				/>
			)}
			{currentTab === 4 && (
				<SuccessCheckModal
					text={`The amount of N${amountToSave} has been added to your savings plan. Keep up the good work.`}
					onRequestClick={onDeductWalletSuccess}
				/>
			)}
		</>
	);
};

export default AddFundsToAssetSavingsFlow;

AddFundsToAssetSavingsFlow.propTypes = {
	currentTab: PropTypes.number,
	setCurrentTab: PropTypes.func,
	onCloseModal: PropTypes.func,
	amountToSave: PropTypes.string,
	setAmountToSave: PropTypes.func,
	isSavingsStarted: PropTypes.bool,
	onDeductWallet: PropTypes.func,
	onDeductWalletSuccess: PropTypes.func,
};

const AmountToFund = ({
	amountToSave,
	handleChange,
	onClose,
	setCurrentTab,
	isSavingsStarted,
}) => {
	const minAmount = process.env.NODE_ENV !== "production" ? 1 : 100;
	const { fetchSingleAssetSavingsSuccess } = useSelector(
		(state) => state.lease
	);

	return (
		<NotifyUser
			header="Add Funds"
			title={`Enter amount you want to add to your savings. ${
				!isSavingsStarted
					? `You've specified to start with NGN ${Number(
							fetchSingleAssetSavingsSuccess?.amountToStartWith
					  )}. You may choose to save other amount.`
					: ""
			}`}
			onCloseBtnClick={onClose}
			onWhiteBtnClick={() => setCurrentTab(1)}
			onGreenBtnClick={() => setCurrentTab(3)}
			isGreenBtnDisabled={Number(amountToSave?.replaceAll(",", "")) < minAmount}
		>
			<div className="space-y-1 zabalMedFont">
				<p className="text-sm">NGN</p>
				<input
					type="tel"
					className="h-[45px] bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
					placeholder="minimum amount (100)"
					id="amountToDeposit"
					value={amountToSave}
					onChange={handleChange}
					onInput={onInputAmount}
				/>
			</div>
		</NotifyUser>
	);
};

AmountToFund.propTypes = {
	amountToSave: PropTypes.string,
	handleChange: PropTypes.func,
	onClose: PropTypes.func,
	setCurrentTab: PropTypes.func,
	isSavingsStarted: PropTypes.bool,
};
