import React from "react";
import PropTypes from "prop-types";
import { Preloader } from "../components/globalStyled";
// import { MdKeyboardArrowLeft } from "react-icons/md";

export const CustomWhiteBtn = ({
	isDisabled,
	text,
	onRequestClick,
	width,
	height,
	fontSize,
}) => (
	<button
		className={`flex  items-center justify-center border capitalize tracking-wide rounded-md font-medium zabalMedFont ${
			isDisabled
				? "border-gray-300 text-gray-300"
				: "border-fortcore-green text-fortcore-green"
		}`}
		onClick={onRequestClick}
		style={{
			width: width ? width : "100%",
			height: height ? height : "45px",
			fontSize: fontSize ? fontSize : "13.75px",
		}}
		disabled={isDisabled ? isDisabled : false}
	>
		{text}
	</button>
);

export const CustomGreenBtn = ({
	text,
	onRequestClick,
	width,
	height,
	fontSize,
	isDisabled,
	isLoading,
}) => (
	<button
		className={`flex items-center justify-center text-[#EEF2F3]  capitalize tracking-wide rounded-md font-medium zabalMedFont ${
			isDisabled ? "bg-[#8E9DA4] cursor-auto" : "bg-fortcore-green"
		}`}
		onClick={onRequestClick}
		style={{
			width: width ? width : "100%",
			height: height ? height : "45px",
			fontSize: fontSize ? fontSize : "13.75px",
		}}
		disabled={isDisabled}
	>
		{isLoading ? <Preloader /> : text}
	</button>
);

CustomWhiteBtn.propTypes = {
	text: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	fontSize: PropTypes.string,
	onRequestClick: PropTypes.func,
	isDisabled: PropTypes.bool,
};

CustomGreenBtn.propTypes = {
	text: PropTypes.string,
	width: PropTypes.string,
	isDisabled: PropTypes.bool,
	height: PropTypes.string,
	fontSize: PropTypes.string,
	isLoading: PropTypes.bool,
	onRequestClick: PropTypes.func,
};

const numbers = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
	23, 24, 25, 26, 27, 28, 29, 30,
];

export const DayButton = ({
	onCancelBtnClick,
	onOkBtnClick,
	onChooseDay,
	currentDay,
}) => {
	return (
		<div
			className="z-20 top-[10px] right-10 rounded-md bg-white p-3.5 w-[300px] space-y-3"
			onClick={(e) => e.stopPropagation()}
		>
			<p className="text-center text-[13px] zabalMedFont">
				Select day of the month
			</p>
			<div className="grid grid-cols-7 gap-1.5 gap-y-2 text-xs zabalMedFont">
				{numbers.map((number) => (
					<button
						key={number}
						className={`p-1 hover:bg-blue-50 ${
							currentDay === String(number) &&
							"text-fortcore-green rounded-lg bg-blue-100 font-semibold"
						}`}
						onClick={() => onChooseDay(String(number))}
					>
						{number}
					</button>
				))}
				<button
					className={`col-span-2 px-1 py-0.5 hover:bg-blue-50 ${
						currentDay === "last day" &&
						"text-fortcore-green rounded-lg bg-blue-100 font-semibold"
					}`}
					onClick={() => onChooseDay("last day")}
				>
					Last Day
				</button>
			</div>
			<div className="grid grid-cols-2 gap-3">
				<CustomWhiteBtn
					height="33px"
					fontSize="11px"
					text="cancel"
					onRequestClick={onCancelBtnClick}
				/>
				<CustomGreenBtn
					height="33px"
					fontSize="11px"
					text="ok"
					onRequestClick={onOkBtnClick}
				/>
			</div>
		</div>
	);
};
DayButton.propTypes = {
	onCancelBtnClick: PropTypes.func,
	onOkBtnClick: PropTypes.func,
	onChooseDay: PropTypes.func,
	currentDay: PropTypes.string,
};
