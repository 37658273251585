import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { MdLock } from "react-icons/md";
import { toast } from "react-toastify";
import { SummaryPageNav } from "../../layout/Navbar";
import {
	queryPartialFundedRequest,
	updateOngoingLeaseInParse,
} from "../../redux/lease/leaseServices";
import NetworkError from "../../components/networkerror/NetworkError";
import {
	closeMiniLoginModal,
	currentUserData,
} from "../../redux/authRedux/authActions";
import { RequestOrderListSkeleton } from "../leasing/requestlease/LeaseItems";
import Skeleton from "react-loading-skeleton";
import {
	LeaseSummaryInput,
	LeaseSummaryItemDetails,
} from "../leasing/requestlease/LeaseSummary";
import {
	formatAmountToIncludeCurrency,
	getInterestAmount,
	walletType,
} from "../../common/utils";
import { LeaseFormSelect } from "../leasing/requestlease/LeaseForm";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import ModalContainer from "../../components/ModalContainer";
import {
	CustomConfirmModal,
	SuccessCheckModal,
} from "../../layout/CustomComponents";
import EmptyData from "../../components/EmptyData";
import Parse from "../../api/ApiConfig";
import MiniLogin from "./MiniLogin";
import { clearUpdateLeaseSuccess } from "../../redux/lease/leaseActions";
import { moveUserFunds } from "../../redux/payments/paymentServices";
import { EnterPin } from "../../components/payments/PinComponent";
import { logoutUser } from "../../redux/authRedux/authServices";
import { clearMoveFundsSuccess } from "../../redux/payments/paymentActions";

const PartialFundedRequest = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const { networkError, currentUser, toggleMiniLoginModal } = useSelector(
		(state) => state.auth
	);
	const {
		queryPartialRequestLoading,
		queryPartialRequestSuccess,
		updateOngoingTransactionLoading,
		updateOngoingTransactionSuccess,
	} = useSelector((state) => state.lease);

	const { customerWallet, moveFundsLoading, moveFundsSuccess } = useSelector(
		(state) => state.payment
	);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const accessPin = currentUser?.accessPin || "";

	const [toggleNumberOfSlots, setToggleNumberOfSlots] = useState(false);
	const [toggleAddMoreSlotsModal, setToggleAddMoreSlotsModal] = useState(false);
	const [currentPaymentTab, setCurrentPaymentTab] = useState(1);
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");

	const [summaryData, setSummaryData] = useState({
		numberOfSlots: "",
		selectedSlots: null,
	});

	const { numberOfSlots, selectedSlots } = summaryData;

	const fetchUserInfo = async () => {
		try {
			const user = Parse.Object.extend("User");
			const query = new Parse.Query(user);
			const response = await query?.get(Parse.User.current()?.id);
			dispatch(currentUserData(response));
		} catch (error) {
			toast.error("You are not signed in to your account.");
		}
	};

	const [searchParams] = useSearchParams();
	const query = searchParams?.get("id");

	const interestAmount = getInterestAmount(
		queryPartialRequestSuccess?.interest,
		queryPartialRequestSuccess?.productObj?.amount
	);
	// const interestAmount = getAmountWithDurationAndInterest(
	// 	queryPartialRequestSuccess?.interest,
	// 	queryPartialRequestSuccess?.leaseDuration,
	// 	queryPartialRequestSuccess?.productObj?.amount
	// );

	const amount =
		Number(
			queryPartialRequestSuccess?.productObj?.amount?.replaceAll(",", "")
		) / 4;
	const paidSlots = queryPartialRequestSuccess?.paidSlots;

	let arrOfAmount = [];

	for (let i = 1; i <= 4 - paidSlots; i++) {
		const a = {
			slotNum: i,
			price: amount * i,
			details: `${formatAmountToIncludeCurrency(
				"NGN",
				amount * i
			)} ( ${i} slot${i > 1 ? "s" : ""} )`,
		};

		arrOfAmount.push(a);
	}

	const onProceedFromNoRequest = () => {
		if (Parse.User.current()?.id) {
			navigate("/leasing/new-transaction/investor-market-place");
		} else {
			navigate("/login");
		}
	};

	const onOpenPaymentModal = () => {
		if (!Parse.User.current()?.id) {
			toast.error("Please log in to your account");
		}
		if (mainWallet?.availableBalance < selectedSlots?.price) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		setToggleAddMoreSlotsModal(true);
		setCurrentPaymentTab(1);
	};

	const customModalText = queryPartialRequestSuccess?.investors?.includes(
		Parse.User.current()?.id
	)
		? `You are about to add ${selectedSlots?.slotNum} more slot(s) to your already purchased slots on this asset.`
		: `The amount of N${selectedSlots?.price} will be deducted from your wallet for a partial investment on this asset.`;

	const interestToEarn = (interestAmount / 4) * selectedSlots?.slotNum || 0;

	const onProceedToFundRequest = () => {
		if (!Parse.User.current()?.id) {
			dispatch(logoutUser());
			return;
		}

		const details = {
			amount: String(selectedSlots?.price),
			source: "main",
			destination: walletType.leaseInvestment,
			message: "investor-slot-payment-for-created-lease-request",
		};

		dispatch(moveUserFunds(currentSessionToken, details));
	};

	const findOldInvestor = queryPartialRequestSuccess?.investorsArray?.find(
		(a) => a.user === Parse.User.current()?.id
	);

	const newInvestor = findOldInvestor ? null : Parse.User.current()?.id;

	const updatedInvestorArr = queryPartialRequestSuccess?.investorsArray?.map(
		(a) => {
			if (a.user === Parse.User.current()?.id) {
				return {
					...a,
					slots: a.slots + selectedSlots?.slotNum,
					amountToInvest: a.amountToInvest + selectedSlots?.price,
					interestToEarn: a.interestToEarn + interestToEarn,
				};
			} else {
				return a;
			}
		}
	);

	const onUpdateRequest = () => {
		if (!Parse.User.current()?.id) {
			toast.error("Please login to your account to continue.");
			return;
		}

		const updatedData = {
			funded: true,
			investors: [
				...(queryPartialRequestSuccess?.investors || []),
				newInvestor,
			].filter((a) => a),

			investorsArray: findOldInvestor
				? updatedInvestorArr
				: [
						...(queryPartialRequestSuccess?.investorsArray || []),
						{
							user: Parse.User.current()?.id,
							name: `${currentUser?.firstName} ${currentUser?.lastName}`,
							phone: currentUser?.phone,
							address: currentUser?.homeAddress,
							slots: selectedSlots?.slotNum,
							amountToInvest: selectedSlots?.price,
							interestToEarn,
							paid: true,
						},
				  ],
			paidSlots: paidSlots + selectedSlots?.slotNum,
			type:
				paidSlots + selectedSlots?.slotNum === 4
					? "full-investment"
					: "partial-investment",
		};

		dispatch(
			updateOngoingLeaseInParse(
				queryPartialRequestSuccess?.objectId,
				updatedData,
				undefined,
				"update-investor-paid-slots"
			)
		);
	};

	const onProceedToPortfolio = () => {
		dispatch(clearUpdateLeaseSuccess());
		navigate("/leasing/investment-board");
	};

	// change selected slots details
	useEffect(() => {
		const selected_slots = arrOfAmount?.find(
			(slot) => slot.details === numberOfSlots
		);
		setSummaryData((prev) => ({ ...prev, selectedSlots: selected_slots }));
	}, [numberOfSlots]);

	// fetch user info to check if logged in
	useEffect(() => {
		fetchUserInfo();
	}, []);

	// show error on invalid access pin
	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	// fetch lease request with query
	useEffect(() => {
		dispatch(queryPartialFundedRequest(query));
	}, [query]);

	// update lease in parse on funds success
	useEffect(() => {
		if (
			moveFundsSuccess === "investor-slot-payment-for-created-lease-request"
		) {
			dispatch(clearMoveFundsSuccess());
			onUpdateRequest();
		} else {
			return;
		}
	}, [moveFundsSuccess]);

	// set modal to success message on complete transaction
	useEffect(() => {
		if (updateOngoingTransactionSuccess === "update-investor-paid-slots") {
			setCurrentPaymentTab(3);
		} else {
			return;
		}
	}, [updateOngoingTransactionSuccess]);

	if (!query) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<>
			{networkError && <NetworkError />}
			{toggleMiniLoginModal && (
				<ModalContainer modal={toggleMiniLoginModal}>
					{currentUser ? (
						<SuccessCheckModal
							text="Login Success. You can now proceed with your transaction."
							onRequestClick={() => dispatch(closeMiniLoginModal())}
						/>
					) : (
						<MiniLogin />
					)}
				</ModalContainer>
			)}
			{toggleAddMoreSlotsModal && (
				<ModalContainer modal={toggleAddMoreSlotsModal}>
					<>
						{currentPaymentTab === 1 && (
							<CustomConfirmModal
								text={customModalText}
								greenBtnText="continue"
								onCloseBtnClick={() => setToggleAddMoreSlotsModal(false)}
								greenBtnClick={() => setCurrentPaymentTab(2)}
								isNotify
							/>
						)}
						{currentPaymentTab === 2 && (
							<EnterPin
								btnText="proceed"
								onCloseBtnClick={() => setToggleAddMoreSlotsModal(false)}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								isPinExist={currentUser?.accessPin !== undefined}
								proceedToCreatePin={() =>
									navigate("/dashboard/profile", { state: { currentTab: 2 } })
								}
								onRequestClick={onProceedToFundRequest}
								isPaymentLoading={
									moveFundsLoading || updateOngoingTransactionLoading
								}
							/>
						)}
						{currentPaymentTab === 3 && (
							<SuccessCheckModal
								text={`The asset with the id of ${query} has been added to your investent portfolio.`}
								isShowBtn={true}
								btnText="view your portfolio"
								onRequestClick={onProceedToPortfolio}
							/>
						)}
					</>
				</ModalContainer>
			)}
			<SummaryPageNav dataExist={!!queryPartialRequestSuccess} />
			<h6>Transaction Id:</h6>
			<div className="mt-[50px] max-w-[1400x] px-3 sm:px-8 md:px-12 lg:px-16 xl:px-20">
				<p className="text-sm md:text-[14.5px] zabalMedFont text-gray-500">
					TransactionID:{" "}
					<span
						className={
							queryPartialRequestSuccess
								? "text-fortcore-green"
								: "text-red-500"
						}
					>
						TRN-{query}
					</span>
				</p>
				<div className="mt-6 md:mt-8">
					{queryPartialRequestLoading ? (
						<div className="space-y-6 md:space-y-8 mb-10">
							<div className="border rounded-md">
								<RequestOrderListSkeleton />
							</div>
							<div className="grid gap-4">
								<SingleLoader />
								<SingleLoader />
								<SingleLoader />
								<div className="hidden sm:block">
									<SingleLoader />
								</div>
								<div className="hidden sm:block">
									<SingleLoader />
								</div>
							</div>
						</div>
					) : queryPartialRequestSuccess ? (
						<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8 mb-10 sm:mb-14">
							<div className="text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
								<p>Lease Request Summary</p>
							</div>
							<LeaseSummaryItemDetails
								item={queryPartialRequestSuccess?.productObj}
								interestAmount={interestAmount}
								// interest={queryPartialRequestSuccess?.interest}
								availableSlots={4 - paidSlots || 0}
							/>
							<div className="grid gap-4 md:gap-5 lg:gap-6">
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.leaseType}
									text="lease type"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.leaseDuration}
									text="lease duration"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.productObj?.category}
									text="product category"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.productObj?.brand}
									text="brand"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.productObj?.model}
									text="model"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.productObj?.year}
									text="year"
								/>
								<LeaseSummaryInput
									input_value={
										queryPartialRequestSuccess?.productObj?.condition
									}
									text="condition"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.productObj?.color}
									text="color"
								/>
								<LeaseSummaryInput
									input_value={queryPartialRequestSuccess?.leaseStructure}
									text="lease structure"
								/>
								<LeaseFormSelect
									text="amount to invest"
									context="choose number of slots"
									isOpened={toggleNumberOfSlots}
									currentText={numberOfSlots}
									handleToggle={() =>
										setToggleNumberOfSlots(!toggleNumberOfSlots)
									}
									arrOptions={arrOfAmount.map((a) => a.details)}
									onHandleSelect={(d) => {
										setSummaryData((prev) => ({
											...prev,
											numberOfSlots: d,
										}));
										setTimeout(() => {
											setToggleNumberOfSlots(false);
										}, 100);
									}}
								/>
								<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
										Interest Rate (NGN):
									</p>
									<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
										<div className="flex items-center justify-between w-full">
											<p>
												<span className="text-[#393F42] zabalMedFont">
													NGN{" "}
												</span>
												<span>
													{formatAmountToIncludeCurrency(
														undefined,
														interestToEarn
													)}
												</span>
											</p>
											<p className="text-fortcore-green zabalMedFont">{`( ${queryPartialRequestSuccess?.interest}% P/A)`}</p>
										</div>
										<span className="h-full grid place-items-center">
											<MdLock size={18} color="#717E83" />
										</span>
									</div>
								</div>
								<div className="hidden sm:flex justify-end gap-4">
									<CustomGreenBtn
										text="proceed"
										width="115px"
										height="42px"
										fontSize="13px"
										onRequestClick={onOpenPaymentModal}
										isDisabled={
											numberOfSlots === "" ||
											queryPartialRequestSuccess?.paidSlots === 4
										}
									/>
								</div>
								<div className="flex flex-col gap-4 pt-4 sm:hidden">
									<CustomGreenBtn
										text="proceed"
										onRequestClick={onOpenPaymentModal}
										isDisabled={
											numberOfSlots === "" ||
											queryPartialRequestSuccess?.paidSlots === 4
										}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="h-[400px] flex flex-col items-center justify-center gap-3">
							<div className="w-full">
								<EmptyData
									text={`No asset with the above query is found. ${
										Parse.User.current()?.id
											? "Click the button below to view your investment portfolio"
											: "Login to your account to create a new asset"
									}.`}
								/>
							</div>
							<CustomGreenBtn
								width={Parse.User.current()?.id ? "130px" : "103px"}
								height="40px"
								text={Parse.User.current()?.id ? "view portfolio" : "login"}
								onRequestClick={onProceedFromNoRequest}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PartialFundedRequest;

const SingleLoader = () => (
	<div className="flex flex-col sm:flex-row gap-1.5 sm:gap-3 sm:justify-between sm:items-center">
		<div className="w-[150px] md:w-[200px]">
			<Skeleton className="h-[22px] md:h-[25px]" />
		</div>
		<div className="w-full sm:w-2/3 md:w-4/6">
			<Skeleton className="h-[35px] md:h-[38px]" />
		</div>
	</div>
);
