import Parse from "../../api/ApiConfig";
import {
	CLEAR_STATES_ON_CONFIRM_LEASE,
	LOGOUT_USER,
} from "../authRedux/authTypes";
import {
	BUSINESS_FORM_DATA_FROM_PARSE,
	PERSONAL_FORM_DATA_FROM_PARSE,
	CLOSE_BUSINESS_FORM,
	CLOSE_BUSINESS_REQUEST_LEASE_MODAL,
	CLOSE_CONFIRM_AUTODEBIT_MODAL,
	CLOSE_INVESTOR_FORM,
	CLOSE_INVESTOR_REQUEST_LEASE_MODAL,
	CLOSE_MARKET_ITEMS_DETAILS,
	CLOSE_PERSONAL_FORM,
	CLOSE_PERSONAL_REQUEST_LEASE_MODAL,
	DATA_FROM_LEASE_FORM,
	DATA_FROM_LEASE_STRUCTURE,
	FETCH_LEASE_WITH_QUERY_ERROR,
	FETCH_LEASE_WITH_QUERY_LOADING,
	FETCH_LEASE_WITH_QUERY_SUCCESS,
	FETCH_MARKET_PLACE_ERROR,
	FETCH_MARKET_PLACE_LOADING,
	FETCH_MARKET_PLACE_SUCCESS,
	FETCH_ONGOING_LEASE_ERROR,
	FETCH_ONGOING_LEASE_LOADING,
	FETCH_ONGOING_LEASE_SUCCESS,
	FETCH_REQUEST_LEASE_DATA_ERROR,
	FETCH_REQUEST_LEASE_DATA_LOADING,
	FETCH_REQUEST_LEASE_DATA_SUCCESS,
	INVESTOR_FORM_DATA_FROM_PARSE,
	OPEN_BUSINESS_FORM,
	OPEN_BUSINESS_REQUEST_LEASE_MODAL,
	OPEN_CONFIRM_AUTODEBIT_MODAL,
	OPEN_INVESTOR_FORM,
	OPEN_INVESTOR_REQUEST_LEASE_MODAL,
	OPEN_MARKET_ITEMS_DETAILS,
	OPEN_PERSONAL_FORM,
	OPEN_PERSONAL_REQUEST_LEASE_MODAL,
	SAVE_BUSINESS_FORM_SUCCESS,
	SAVE_INVESTOR_FORM_SUCCESS,
	SAVE_PERSONAL_FORM_SUCCESS,
	SELECTED_REQUEST_LEASE_ITEM,
	FETCH_SUBMITTED_LEASE_LOADING,
	FETCH_SUBMITTED_LEASE_SUCCESS,
	FETCH_SUBMITTED_LEASE_ERROR,
	SUBMIT_NEGOTIATION_LOADING,
	SUBMIT_NEGOTIATION_SUCCESS,
	SUBMIT_NEGOTIATION_ERROR,
	CLEAR_SUBMITTED_NEGOTIATION,
	SELECTED_PROFILE,
	FETCH_LEASE_BOARD_DATA_LOADING,
	FETCH_LEASE_BOARD_DATA_SUCCESS,
	FETCH_LEASE_BOARD_DATA_ERROR,
	FETCH_INVESTOR_MARKET_PLACE_LOADING,
	FETCH_INVESTOR_MARKET_PLACE_SUCCESS,
	FETCH_INVESTOR_MARKET_PLACE_ERROR,
	SELECTED_PAYMENT_METHOD,
	OPEN_PAY_WITH_WALLET_MODAL,
	CLOSE_PAY_WITH_WALLET_MODAL,
	OPEN_ACTIVATE_LEASE_BOARD_MODAL,
	CLOSE_ACTIVATE_LEASE_BOARD_MODAL,
	FETCH_REGULAR_SAVINGS_LOADING,
	FETCH_REGULAR_SAVINGS_SUCCESS,
	FETCH_REGULAR_SAVINGS_ERROR,
	REGULAR_SAVINGS_FORM,
	FETCH_ASSET_BASED_SAVINGS_LOADING,
	FETCH_ASSET_BASED_SAVINGS_SUCCESS,
	FETCH_ASSET_BASED_SAVINGS_ERROR,
	CREATE_ASSET_BASED_SAVINGS_LOADING,
	CREATE_ASSET_BASED_SAVINGS_SUCCESS,
	CREATE_ASSET_BASED_SAVINGS_ERROR,
	IS_CREATE_ASSET_SAVINGS_SUCCESS,
	CLEAR_SAVE_FORM_SUCCESS,
	FETCH_RISK_INVESTMENTS_LOADING,
	FETCH_RISK_INVESTMENTS_SUCCESS,
	FETCH_RISK_INVESTMENTS_ERROR,
	INVESTOR_CREATE_ASSET_LOADING,
	INVESTOR_CREATE_ASSET_SUCCESS,
	INVESTOR_CREATE_ASSET_ERROR,
	FETCH_CATEGORIES_LOADING,
	FETCH_CATEGORIES_SUCCESS,
	FETCH_CATEGORIES_ERROR,
	FETCH_BRANDS_LOADING,
	FETCH_BRANDS_SUCCESS,
	FETCH_BRANDS_ERROR,
	GET_RENTAL_FEE_SUCCESS,
	GET_RENTAL_FEE_LOADING,
	GET_RENTAL_FEE_ERROR,
	GET_DELIVERY_FEE_LOADING,
	GET_DELIVERY_FEE_SUCCESS,
	GET_DELIVERY_FEE_ERROR,
	GET_CURRENT_CONTEXT_INTEREST,
	CLEAR_REGULAR_SAVINGS,
	CREATE_LEASE_LOADING,
	CREATE_LEASE_ERROR,
	CREATE_LEASE_SUCCESS,
	UPDATE_ONGOING_LEASE_LOADING,
	UPDATE_ONGOING_LEASE_SUCCESS,
	UPDATE_ONGOING_LEASE_ERROR,
	CLEAR_UPDATE_LEASE_SUCCESS,
	OPEN_FILTER_MODAL,
	CLOSE_FILTER_MODAL,
	OPEN_DELETE_ONGOING_LEASE_MODAL,
	CLOSE_DELETE_ONGOING_LEASE_MODAL,
	DELETE_ONGOING_LEASE_ERROR,
	DELETE_ONGOING_LEASE_LOADING,
	DELETE_ONGOING_LEASE_SUCCESS,
	OPEN_DELETE_INACTIVE_INVESTMENT_MODAL,
	CLOSE_DELETE_INACTIVE_INVESTMENT_MODAL,
	DELETE_INACTIVE_ASSET_LOADING,
	DELETE_INACTIVE_ASSET_SUCCESS,
	DELETE_INACTIVE_ASSET_ERROR,
	CREATE_REGULAR_SAVINGS_LOADING,
	CREATE_REGULAR_SAVINGS_SUCCESS,
	CREATE_REGULAR_SAVINGS_ERROR,
	CLEAR_REGULAR_SAVINGS_CREATED_SUCCESS,
	SUBMIT_ONGOING_LEASE_SUCCESS,
	CREATE_RISK_INVESTMENTS_LOADING,
	CREATE_RISK_INVESTMENTS_SUCCESS,
	CREATE_RISK_INVESTMENTS_ERROR,
	ACTIVATE_ASSET_LOADING,
	ACTIVATE_ASSET_SUCCESS,
	ACTIVATE_ASSET_ERROR,
	FETCH_SINGLE_ASSET_BASED_SAVINGS_ERROR,
	FETCH_SINGLE_ASSET_BASED_SAVINGS_SUCCESS,
	FETCH_SINGLE_ASSET_BASED_SAVINGS_LOADING,
	UPDATE_SINGLE_ASSET_BASED_SAVINGS,
	CLEAR_UPDATE_ASSET_SAVINGS_SUCCESS,
	OPEN_ADD_FUNDS_TO_ASSET_SAVINGS,
	CLOSE_ADD_FUNDS_TO_ASSET_SAVINGS,
	CLEAR_LEASE_STRUCTURE_DATA,
	QUERY_PARTIAL_FUNDED_ASSET_ERROR,
	QUERY_PARTIAL_FUNDED_ASSET_LOADING,
	QUERY_PARTIAL_FUNDED_ASSET_SUCCESS,
	CLEAR_ACTIVATE_ASSET_SUCCESS,
	OPEN_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL,
	CLOSE_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL,
	OPEN_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL,
	CLOSE_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL,
	QUERY_PARTIAL_FUNDED_REQUEST_ERROR,
	QUERY_PARTIAL_FUNDED_REQUEST_LOADING,
	QUERY_PARTIAL_FUNDED_REQUEST_SUCCESS,
	INVESTOR_UPDATE_REQUEST_STATUS,
	TOGGLE_PAY_DELIVERY_FEE_MODAL,
	TOGGLE_APPLY_INSURANCE_MODAL,
	TOGGLE_REGISTER_ASSET_MODAL,
	REGISTER_ASSET_LOADING,
	REGISTER_ASSET_ERROR,
	REGISTER_ASSET_SUCCESS,
	ACTIVATE_INSURANCE_LOADING,
	ACTIVATE_INSURANCE_SUCCESS,
	ACTIVATE_INSURANCE_ERROR,
	CREDIT_ASSESSMENT_LOADING,
	CREDIT_ASSESSMENT_SUCCESS,
	CREDIT_ASSESSMENT_ERROR,
	CLEAR_CREDIT_ASSESSMENT_SUCCESS,
} from "./leaseTypes";

const initiateState = {
	fetchMarketPlaceDataLoading: false,
	fetchMarketPlaceDataSuccess: [],
	fetchInvestorMarketPlaceDataLoading: false,
	fetchInvestorMarketPlaceDataSuccess: [],
	fetchReqLeaseDataLoading: false,
	fetchReqLeaseDataSuccess: null,
	togglePersonalForm: false,
	toggleBusinessForm: false,
	toggleInvestorForm: false,
	toggleMarketItemsModal: false,
	currentMarketPlaceItem: null,
	selectedReqLeaseItem: null,
	leaseFormData: null,
	leaseStructureData: null,
	togglePersonalReqLeaseModal: false,
	toggleBusinessReqLeaseModal: false,
	toggleInvestorReqLeaseModal: false,
	toggleAutoDebitModal: false,
	savingsType: null,
	savingPersonalFormSuccess: null,
	savingBusinessFormSuccess: null,
	savingInvestorFormSuccess: null,
	ongoingLeaseTransactionsLoading: false,
	ongoingLeaseTransactionsSuccess: null,
	fetchLeaseWithQueryLoading: false,
	fetchLeaseWithQuerySuccess: null,
	fetchSubmittedLeaseLoading: false,
	fetchSubmittedLeaseSuccess: null,
	negotiationLoading: false,
	negotiationSuccess: null,
	leaseBoardDataLoading: false,
	leaseBoardDataSuccess: [],
	currentProfile: null,
	payment: null,
	togglePayWithWalletModal: false,
	toggleActivateLeaseBoardInvestment: false,
	currentLeaseBoardInvestment: null,
	regularSavingsLoading: false,
	regularSavingsSuccess: [],
	assetBasedSavingsLoading: false,
	assetBasedSavingsSuccess: null,
	regularSavingsForm: {},
	createAssetBasedSavingsLoading: false,
	createAssetBasedSavingsSuccss: null,
	riskInvestmentsLoading: false,
	riskInvestmentsSuccss: null,
	isFormSavedSuccess: false,
	createAssetLoading: false,
	createAssetSuccess: null,
	categoriesLoading: false,
	categoriesSuccess: [],
	brandsLoading: false,
	brandsSuccess: [],
	rentalFeeLoading: false,
	rentalFeeSuccess: null,
	deliveryFeeLoading: false,
	deliveryFeeSuccess: null,
	currentGeneratedinterest: null,
	createLeaseLoading: false,
	createLeaseSuccess: null,
	updateOngoingTransactionLoading: false,
	updateOngoingTransactionSuccess: null,
	toggleFilterModal: false,
	toggleDeleteOngoingLeaseModal: false,
	ongoingLeaseToDelete: null,
	ongoingLeaseDeleteLoading: false,
	ongoingLeaseDeleteSuccess: null,
	toggleDeleteInactiveInvestmentModal: false,
	inactiveInvestmentToDelete: null,
	inactiveAssetDeleteLoading: false,
	inactiveAssetDeleteSuccess: null,
	createRegularSavingsLoading: false,
	createRegularSavingsSuccess: null,
	ongoingLeaseSubmitSuccess: null,
	createRiskFreeInvestmentLoading: false,
	createRiskFreeInvestmentSuccess: null,
	updateCreatedAssetLoading: false,
	updateCreatedAssetSuccess: null,
	fetchSingleAssetSavingsLoading: false,
	fetchSingleAssetSavingsSuccess: null,
	updateAssetSavingsSuccess: null,
	toggleAddFundsToAssetSavings: false,
	queryPartialAssetLoading: false,
	queryPartialAssetSuccess: null,
	toggleMoveFundsFromRegSavingsModal: false,
	toggleMoveFundsFromAssetSavingsModal: false,
	queryPartialRequestLoading: false,
	queryPartialRequestSuccess: null,
	toggleDeliveryFeeModal: false,
	currentLeaseId: null,
	insuranceLoading: false,
	insuranceSuccess: null,
};

export const leaseReducer = (state = initiateState, action) => {
	switch (action.type) {
		case CLEAR_SAVE_FORM_SUCCESS:
			return { ...state, isFormSavedSuccess: false };
		case FETCH_MARKET_PLACE_LOADING:
			return {
				...state,
				fetchMarketPlaceDataLoading: true,
				leaseStructureData: null,
				savingOngoingTransactionSuccess: null,
				fetchMarketPlaceDataSuccess: [],
			};
		case FETCH_MARKET_PLACE_SUCCESS:
			return {
				...state,
				fetchMarketPlaceDataLoading: false,
				fetchMarketPlaceDataSuccess: action.payload,
			};
		case FETCH_MARKET_PLACE_ERROR:
			return { ...state, fetchMarketPlaceDataLoading: false };
		case FETCH_INVESTOR_MARKET_PLACE_LOADING:
			return {
				...state,
				fetchInvestorMarketPlaceDataLoading: true,
				fetchInvestorMarketPlaceDataSuccess: [],
			};
		case FETCH_INVESTOR_MARKET_PLACE_SUCCESS:
			return {
				...state,
				fetchInvestorMarketPlaceDataLoading: false,
				fetchInvestorMarketPlaceDataSuccess: action.payload,
			};
		case FETCH_INVESTOR_MARKET_PLACE_ERROR:
			return { ...state, fetchInvestorMarketPlaceDataLoading: false };
		case FETCH_REQUEST_LEASE_DATA_LOADING:
			return { ...state, fetchReqLeaseDataLoading: true };
		case FETCH_REQUEST_LEASE_DATA_SUCCESS:
			return {
				...state,
				fetchReqLeaseDataLoading: false,
				fetchReqLeaseDataSuccess: action.payload,
			};
		case FETCH_REQUEST_LEASE_DATA_ERROR:
			return { ...state, fetchReqLeaseDataLoading: false };
		case OPEN_PERSONAL_FORM:
			return { ...state, togglePersonalForm: true };
		case CLOSE_PERSONAL_FORM:
			return { ...state, togglePersonalForm: false };
		case OPEN_BUSINESS_FORM:
			return { ...state, toggleBusinessForm: true };
		case CLOSE_BUSINESS_FORM:
			return { ...state, toggleBusinessForm: false };
		case OPEN_INVESTOR_FORM:
			return { ...state, toggleInvestorForm: true };
		case CLOSE_INVESTOR_FORM:
			return { ...state, toggleInvestorForm: false };
		case OPEN_MARKET_ITEMS_DETAILS:
			return {
				...state,
				toggleMarketItemsModal: true,
				currentMarketPlaceItem: action.payload,
			};
		case CLOSE_MARKET_ITEMS_DETAILS:
			return {
				...state,
				toggleMarketItemsModal: false,
				currentMarketPlaceItem: null,
			};
		case SELECTED_REQUEST_LEASE_ITEM:
			return { ...state, selectedReqLeaseItem: action.payload };
		case DATA_FROM_LEASE_FORM:
			return {
				...state,
				leaseFormData: { ...state.leaseFormData, ...action.payload },
			};
		case DATA_FROM_LEASE_STRUCTURE:
			return {
				...state,
				leaseStructureData: { ...state.leaseStructureData, ...action.payload },
			};
		case OPEN_PERSONAL_REQUEST_LEASE_MODAL:
			return { ...state, togglePersonalReqLeaseModal: true };
		case CLOSE_PERSONAL_REQUEST_LEASE_MODAL:
			return {
				...state,
				togglePersonalReqLeaseModal: false,
				deliveryFeeSuccess: null,
				rentalFeeSuccess: null,
			};
		case OPEN_BUSINESS_REQUEST_LEASE_MODAL:
			return { ...state, toggleBusinessReqLeaseModal: true };
		case CLOSE_BUSINESS_REQUEST_LEASE_MODAL:
			return {
				...state,
				toggleBusinessReqLeaseModal: false,
				deliveryFeeSuccess: null,
				rentalFeeSuccess: null,
			};
		case OPEN_INVESTOR_REQUEST_LEASE_MODAL:
			return { ...state, toggleInvestorReqLeaseModal: true };
		case CLOSE_INVESTOR_REQUEST_LEASE_MODAL:
			return { ...state, toggleInvestorReqLeaseModal: false };
		case OPEN_CONFIRM_AUTODEBIT_MODAL:
			return {
				...state,
				toggleAutoDebitModal: true,
				savingsType: action.payload,
				regularSavingsForm: null,
			};
		case CLOSE_CONFIRM_AUTODEBIT_MODAL:
			return { ...state, toggleAutoDebitModal: false };
		case PERSONAL_FORM_DATA_FROM_PARSE:
			return { ...state, savingPersonalFormSuccess: action.payload };
		case SAVE_PERSONAL_FORM_SUCCESS:
			return { ...state, savingPersonalFormSuccess: action.payload };
		case BUSINESS_FORM_DATA_FROM_PARSE:
			return { ...state, savingBusinessFormSuccess: action.payload };
		case SAVE_BUSINESS_FORM_SUCCESS:
			return { ...state, savingBusinessFormSuccess: action.payload };
		case INVESTOR_FORM_DATA_FROM_PARSE:
			return { ...state, savingInvestorFormSuccess: action.payload };
		case SAVE_INVESTOR_FORM_SUCCESS:
			return { ...state, savingInvestorFormSuccess: action.payload };
		case FETCH_ONGOING_LEASE_LOADING:
			return { ...state, ongoingLeaseTransactionsLoading: true };
		case FETCH_ONGOING_LEASE_SUCCESS:
			return {
				...state,
				ongoingLeaseTransactionsLoading: false,
				ongoingLeaseTransactionsSuccess: action.payload,
			};
		case FETCH_ONGOING_LEASE_ERROR:
			return { ...state, ongoingLeaseTransactionsLoading: false };
		case CREATE_LEASE_LOADING:
			return { ...state, createLeaseLoading: true };
		case CREATE_LEASE_SUCCESS:
			return {
				...state,
				createLeaseLoading: false,
				createLeaseSuccess: action.payload,
			};
		case CREATE_LEASE_ERROR:
			return { ...state, createLeaseLoading: false };
		case FETCH_LEASE_WITH_QUERY_LOADING:
			return { ...state, fetchLeaseWithQueryLoading: true };
		case FETCH_LEASE_WITH_QUERY_SUCCESS:
			return {
				...state,
				fetchLeaseWithQueryLoading: false,
				fetchLeaseWithQuerySuccess: action.payload,
			};
		case FETCH_LEASE_WITH_QUERY_ERROR:
			return { ...state, fetchLeaseWithQueryLoading: false };
		case FETCH_SUBMITTED_LEASE_LOADING:
			return {
				...state,
				fetchSubmittedLeaseLoading: true,
				ongoingLeaseSubmitSuccess: null,
				toggleDeliveryFeeModal: false,
			};
		case FETCH_SUBMITTED_LEASE_SUCCESS:
			return {
				...state,
				fetchSubmittedLeaseLoading: false,
				fetchSubmittedLeaseSuccess: action.payload,
			};
		case FETCH_SUBMITTED_LEASE_ERROR:
			return {
				...state,
				fetchSubmittedLeaseLoading: false,
				fetchSubmittedLeaseSuccess: null,
			};
		case CLEAR_STATES_ON_CONFIRM_LEASE:
			return {
				...state,
				selectedReqLeaseItem: null,
				leaseStructureData: null,
				leaseFormData: null,
				regularSavingsForm: null,
				deliveryFeeSuccess: null,
				rentalFeeSuccess: null,
				currentGeneratedinterest: null,
				createLeaseSuccess: null,
				ongoingLeaseSubmitSuccess: null,
			};
		case SUBMIT_NEGOTIATION_LOADING:
			return { ...state, negotiationLoading: true };
		case SUBMIT_NEGOTIATION_SUCCESS:
			return {
				...state,
				negotiationLoading: false,
				negotiationSuccess: "submitted",
			};
		case SUBMIT_NEGOTIATION_ERROR:
			return { ...state, negotiationLoading: false };
		case CLEAR_SUBMITTED_NEGOTIATION:
			return { ...state, negotiationSuccess: null };
		case SELECTED_PROFILE:
			return { ...state, currentProfile: action.payload };
		case FETCH_LEASE_BOARD_DATA_LOADING:
			return {
				...state,
				leaseBoardDataLoading: true,
				createAssetSuccess: null,
			};
		case FETCH_LEASE_BOARD_DATA_SUCCESS:
			return {
				...state,
				leaseBoardDataLoading: false,
				leaseBoardDataSuccess: action.payload,
				inactiveData: action.payload?.filter(
					(item) =>
						item.investorsArray.find(
							(investor) => investor?.user === Parse.User.current().id
						).paid === false
				),
				activeData: action.payload?.filter(
					(item) =>
						item.investorsArray.find(
							(investor) => investor?.user === Parse.User.current().id
						).paid === true
				),
			};
		case FETCH_LEASE_BOARD_DATA_ERROR:
			return {
				...state,
				leaseBoardDataLoading: false,
				leaseBoardDataSuccess: [],
			};
		case SELECTED_PAYMENT_METHOD:
			return { ...state, payment: { ...state.payment, ...action.payload } };
		case OPEN_PAY_WITH_WALLET_MODAL:
			return { ...state, togglePayWithWalletModal: true };
		case CLOSE_PAY_WITH_WALLET_MODAL:
			return { ...state, togglePayWithWalletModal: false };
		case OPEN_ACTIVATE_LEASE_BOARD_MODAL:
			return {
				...state,
				toggleActivateLeaseBoardInvestment: true,
				currentLeaseBoardInvestment: action.payload,
			};
		case CLOSE_ACTIVATE_LEASE_BOARD_MODAL:
			return {
				...state,
				toggleActivateLeaseBoardInvestment: false,
				currentLeaseBoardInvestment: null,
				updateCreatedAssetSuccess: null,
			};
		case FETCH_REGULAR_SAVINGS_LOADING:
			return {
				...state,
				regularSavingsLoading: true,
				createRegularSavingsSuccess: null,
			};
		case FETCH_REGULAR_SAVINGS_SUCCESS:
			return {
				...state,
				regularSavingsLoading: false,
				regularSavingsSuccess: action.payload,
			};
		case FETCH_REGULAR_SAVINGS_ERROR:
			return { ...state, regularSavingsLoading: false };
		case FETCH_ASSET_BASED_SAVINGS_LOADING:
			return { ...state, assetBasedSavingsLoading: true };
		case FETCH_ASSET_BASED_SAVINGS_SUCCESS:
			return {
				...state,
				assetBasedSavingsLoading: false,
				assetBasedSavingsSuccess: action.payload,
			};
		case FETCH_ASSET_BASED_SAVINGS_ERROR:
			return { ...state, assetBasedSavingsLoading: false };
		case REGULAR_SAVINGS_FORM:
			return {
				...state,
				regularSavingsForm: { ...state.regularSavingsForm, ...action.payload },
			};
		case CREATE_ASSET_BASED_SAVINGS_LOADING:
			return { ...state, createAssetBasedSavingsLoading: true };
		case CREATE_ASSET_BASED_SAVINGS_SUCCESS:
			return {
				...state,
				createAssetBasedSavingsLoading: false,
				createAssetBasedSavingsSuccess: action.payload,
			};
		case CREATE_ASSET_BASED_SAVINGS_ERROR:
			return { ...state, createAssetBasedSavingsLoading: false };
		case IS_CREATE_ASSET_SAVINGS_SUCCESS:
			return { ...state, createAssetBasedSavingsSuccess: null };
		case FETCH_RISK_INVESTMENTS_LOADING:
			return {
				...state,
				riskInvestmentsLoading: true,
				createRiskFreeInvestmentSuccess: null,
			};
		case FETCH_RISK_INVESTMENTS_SUCCESS:
			return {
				...state,
				riskInvestmentsLoading: false,
				riskInvestmentsSuccess: action.payload,
			};
		case FETCH_RISK_INVESTMENTS_ERROR:
			return { ...state, riskInvestmentsLoading: false };
		case INVESTOR_CREATE_ASSET_LOADING:
			return { ...state, createAssetLoading: true };
		case INVESTOR_CREATE_ASSET_SUCCESS:
			return {
				...state,
				createAssetLoading: false,
				createAssetSuccess: action.payload,
			};
		case INVESTOR_CREATE_ASSET_ERROR:
			return { ...state, createAssetLoading: false };
		case FETCH_CATEGORIES_LOADING:
			return { ...state, categoriesLoading: true };
		case FETCH_CATEGORIES_SUCCESS:
			return {
				...state,
				categoriesSuccess: action.payload,
				categoriesLoading: false,
			};
		case FETCH_CATEGORIES_ERROR:
			return { ...state, categoriesLoading: false };
		case FETCH_BRANDS_LOADING:
			return { ...state, brandsLoading: true };
		case FETCH_BRANDS_SUCCESS:
			return {
				...state,
				brandsSuccess: action.payload,
				brandsLoading: false,
			};
		case FETCH_BRANDS_ERROR:
			return { ...state, brandsLoading: false };
		case GET_RENTAL_FEE_LOADING:
			return { ...state, rentalFeeLoading: true };
		case GET_RENTAL_FEE_SUCCESS:
			return {
				...state,
				rentalFeeSuccess: action.payload,
				rentalFeeLoading: false,
			};
		case GET_RENTAL_FEE_ERROR:
			return { ...state, rentalFeeLoading: false };
		case GET_DELIVERY_FEE_LOADING:
			return { ...state, deliveryFeeLoading: true };
		case GET_DELIVERY_FEE_SUCCESS:
			return {
				...state,
				deliveryFeeSuccess: action.payload,
				deliveryFeeLoading: false,
			};
		case GET_DELIVERY_FEE_ERROR:
			return { ...state, deliveryFeeLoading: false };
		case GET_CURRENT_CONTEXT_INTEREST:
			return { ...state, currentGeneratedinterest: action.payload };
		case CLEAR_REGULAR_SAVINGS:
			return { ...state, regularSavingsForm: null };
		case UPDATE_ONGOING_LEASE_LOADING:
			return {
				...state,
				updateOngoingTransactionLoading: true,
				updateOngoingTransactionSuccess: null,
			};
		case UPDATE_ONGOING_LEASE_SUCCESS:
			return {
				...state,
				fetchLeaseWithQuerySuccess: action.payload.data,
				updateOngoingTransactionSuccess: action.payload.message,
				updateOngoingTransactionLoading: false,
			};
		case SUBMIT_ONGOING_LEASE_SUCCESS:
			return {
				...state,
				ongoingLeaseSubmitSuccess: action.payload,
				updateOngoingTransactionLoading: false,
			};
		case UPDATE_ONGOING_LEASE_ERROR:
			return {
				...state,
				updateOngoingTransactionLoading: false,
				updateOngoingTransactionSuccess: null,
			};
		case CLEAR_UPDATE_LEASE_SUCCESS:
			return { ...state, updateOngoingTransactionSuccess: null };
		case OPEN_FILTER_MODAL:
			return { ...state, toggleFilterModal: true };
		case CLOSE_FILTER_MODAL:
			return { ...state, toggleFilterModal: false };
		case OPEN_DELETE_ONGOING_LEASE_MODAL:
			return {
				...state,
				toggleDeleteOngoingLeaseModal: true,
				ongoingLeaseToDelete: action.payload,
			};
		case CLOSE_DELETE_ONGOING_LEASE_MODAL:
			return {
				...state,
				toggleDeleteOngoingLeaseModal: false,
				ongoingLeaseToDelete: null,
				ongoingLeaseDeleteSuccess: null,
			};
		case DELETE_ONGOING_LEASE_LOADING:
			return { ...state, ongoingLeaseDeleteLoading: true };
		case DELETE_ONGOING_LEASE_SUCCESS:
			return {
				...state,
				ongoingLeaseTransactionsSuccess: [
					...state.ongoingLeaseTransactionsSuccess.filter(
						(lease) => !state.ongoingLeaseToDelete?.includes(lease.id)
					),
				],
				ongoingLeaseDeleteLoading: false,
				ongoingLeaseDeleteSuccess: "delete success",
			};
		case DELETE_ONGOING_LEASE_ERROR:
			return { ...state, ongoingLeaseDeleteLoading: false };
		case OPEN_DELETE_INACTIVE_INVESTMENT_MODAL:
			return {
				...state,
				toggleDeleteInactiveInvestmentModal: true,
				inactiveInvestmentToDelete: action.payload,
			};
		case CLOSE_DELETE_INACTIVE_INVESTMENT_MODAL:
			return {
				...state,
				toggleDeleteInactiveInvestmentModal: false,
				inactiveInvestmentToDelete: null,
				inactiveAssetDeleteSuccess: null,
			};
		case DELETE_INACTIVE_ASSET_LOADING:
			return { ...state, inactiveAssetDeleteLoading: true };
		case DELETE_INACTIVE_ASSET_SUCCESS:
			return {
				...state,
				inactiveAssetDeleteLoading: false,
				inactiveAssetDeleteSuccess: "delete success",
				inactiveData: [
					...state.inactiveData.filter(
						(data) => data.objectId !== state.inactiveInvestmentToDelete
					),
				],
			};
		case DELETE_INACTIVE_ASSET_ERROR:
			return { ...state, inactiveAssetDeleteLoading: false };
		case CREATE_REGULAR_SAVINGS_LOADING:
			return { ...state, createRegularSavingsLoading: true };
		case CREATE_REGULAR_SAVINGS_SUCCESS:
			return {
				...state,
				createRegularSavingsLoading: false,
				createRegularSavingsSuccess: action.payload,
			};
		case CREATE_REGULAR_SAVINGS_ERROR:
			console.log(22);
			return { ...state, createRegularSavingsLoading: false };
		case CLEAR_REGULAR_SAVINGS_CREATED_SUCCESS:
			return { ...state, createRegularSavingsSuccess: null };
		case CREATE_RISK_INVESTMENTS_LOADING:
			return { ...state, createRiskFreeInvestmentLoading: true };
		case CREATE_RISK_INVESTMENTS_SUCCESS:
			return {
				...state,
				createRiskFreeInvestmentLoading: false,
				createRiskFreeInvestmentSuccess: action.payload,
			};
		case CREATE_RISK_INVESTMENTS_ERROR:
			return { ...state, createRiskFreeInvestmentLoading: false };
		case ACTIVATE_ASSET_LOADING:
			return { ...state, updateCreatedAssetLoading: true };
		case ACTIVATE_ASSET_SUCCESS:
			return {
				...state,
				updateCreatedAssetLoading: false,
				updateCreatedAssetSuccess: action.payload,
			};
		case ACTIVATE_ASSET_ERROR:
			return { ...state, updateCreatedAssetLoading: false };
		case FETCH_SINGLE_ASSET_BASED_SAVINGS_LOADING:
			return { ...state, fetchSingleAssetSavingsLoading: true };
		case FETCH_SINGLE_ASSET_BASED_SAVINGS_SUCCESS:
			return {
				...state,
				fetchSingleAssetSavingsLoading: false,
				fetchSingleAssetSavingsSuccess: action.payload,
			};
		case FETCH_SINGLE_ASSET_BASED_SAVINGS_ERROR:
			return { ...state, fetchSingleAssetSavingsLoading: false };
		case UPDATE_SINGLE_ASSET_BASED_SAVINGS:
			return {
				...state,
				updateAssetSavingsSuccess: "asset-savings-updated",
				fetchSingleAssetSavingsSuccess: action.payload,
			};
		case CLEAR_UPDATE_ASSET_SAVINGS_SUCCESS:
			return { ...state, updateAssetSavingsSuccess: null };
		case OPEN_ADD_FUNDS_TO_ASSET_SAVINGS:
			return { ...state, toggleAddFundsToAssetSavings: true };
		case CLOSE_ADD_FUNDS_TO_ASSET_SAVINGS:
			return { ...state, toggleAddFundsToAssetSavings: false };
		case CLEAR_LEASE_STRUCTURE_DATA:
			return { ...state, leaseStructureData: null };
		case QUERY_PARTIAL_FUNDED_ASSET_LOADING:
			return { ...state, queryPartialAssetLoading: true };
		case QUERY_PARTIAL_FUNDED_ASSET_SUCCESS:
			return {
				...state,
				queryPartialAssetLoading: false,
				queryPartialAssetSuccess: action.payload,
			};
		case QUERY_PARTIAL_FUNDED_ASSET_ERROR:
			return { ...state, queryPartialAssetLoading: false };
		case CLEAR_ACTIVATE_ASSET_SUCCESS:
			return { ...state, updateCreatedAssetSuccess: null };
		case OPEN_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL:
			return { ...state, toggleMoveFundsFromRegSavingsModal: true };
		case CLOSE_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL:
			return { ...state, toggleMoveFundsFromRegSavingsModal: false };
		case OPEN_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL:
			return { ...state, toggleMoveFundsFromAssetSavingsModal: true };
		case CLOSE_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL:
			return { ...state, toggleMoveFundsFromAssetSavingsModal: false };
		case QUERY_PARTIAL_FUNDED_REQUEST_LOADING:
			return { ...state, queryPartialRequestLoading: true };
		case QUERY_PARTIAL_FUNDED_REQUEST_SUCCESS:
			return {
				...state,
				queryPartialRequestLoading: false,
				queryPartialRequestSuccess: action.payload,
			};
		case QUERY_PARTIAL_FUNDED_REQUEST_ERROR:
			return { ...state, queryPartialRequestLoading: false };
		case INVESTOR_UPDATE_REQUEST_STATUS:
			var indexToUpdate = state?.activeData.findIndex(
				(obj) => obj?.objectId === action.payload?.objectId
			);

			if (indexToUpdate !== -1) {
				const updatedData = [...state.activeData]; // Create a shallow copy
				updatedData.splice(indexToUpdate, 1, action.payload);

				return { ...state, activeData: updatedData };
			}

			return state;
		case TOGGLE_PAY_DELIVERY_FEE_MODAL:
			return {
				...state,
				toggleDeliveryFeeModal: action.payload?.state,
				currentLeaseId: action?.payload?.id,
			};
		case TOGGLE_APPLY_INSURANCE_MODAL:
			return {
				...state,
				toggleApplyForInsuranceModal: action.payload,
			};
		case TOGGLE_REGISTER_ASSET_MODAL:
			return {
				...state,
				toggleRegisterAssetModal: action.payload,
			};
		case REGISTER_ASSET_LOADING:
			return { ...state, assetRegLoading: true };
		case REGISTER_ASSET_SUCCESS:
			return {
				...state,
				assetRegLoading: false,
				assetRegSuccess: action.payload,
			};
		case REGISTER_ASSET_ERROR:
			return { ...state, assetRegLoading: false };
		case ACTIVATE_INSURANCE_LOADING:
			return { ...state, insuranceLoading: true };
		case ACTIVATE_INSURANCE_SUCCESS:
			return {
				...state,
				insuranceLoading: false,
				insuranceSuccess: action.payload,
			};
		case ACTIVATE_INSURANCE_ERROR:
			return { ...state, insuranceLoading: false };
		case CREDIT_ASSESSMENT_LOADING:
			return { ...state, credAssessLoading: true };
		case CREDIT_ASSESSMENT_SUCCESS:
			return {
				...state,
				credAssessLoading: false,
				credAssessSuccess: action.payload,
				creditAssessMsg: "credit assessment success",
			};
		case CREDIT_ASSESSMENT_ERROR:
			return { ...state, credAssessLoading: false };
		case CLEAR_CREDIT_ASSESSMENT_SUCCESS:
			return { ...state, creditAssessMsg: "" };
		case LOGOUT_USER:
			return { toggleAutoDebitModal: false };
		default:
			return state;
	}
};
