import React from "react";
import PropTypes from "prop-types";
import { ExchangeOneIcon, ExchangeOneThree, ExchangeOneTwo } from "./assets";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const TransactionCards = ({ urlArr, isApplication }) => {
	return (
		<div
			className={`grid sm:grid-cols-2 ${
				!isApplication && "lg:grid-cols-3"
			} gap-4 py-6 lg:py-8`}
		>
			<TransactionCard
				icon={<ExchangeOneIcon width={40} height={40} />}
				cardTitle={isApplication ? "new application" : "new transaction"}
				cardSubtitle="Create new transaction"
				url={urlArr[0]}
			/>
			{!isApplication && (
				<TransactionCard
					icon={<ExchangeOneTwo width={40} height={40} />}
					cardTitle={
						isApplication ? "ongoing applications" : "ongoing transactions"
					}
					cardSubtitle="Your current transactions"
					url={urlArr[1]}
				/>
			)}
			<TransactionCard
				icon={<ExchangeOneThree width={40} height={40} />}
				cardTitle={
					isApplication ? "submitted applications" : "submitted transactions"
				}
				cardSubtitle="Your processed transactions"
				url={urlArr[2]}
			/>
		</div>
	);
};

// eslint-disable-next-line react/prop-types
export const TransactionFinanceCards = () => {
	return (
		<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-5 xl:gap-6 py-6 lg:py-8">
			<TransactionCard
				icon={<ExchangeOneIcon width={40} height={40} />}
				cardTitle="new transaction"
				cardSubtitle="Create new transaction"
				url="/financing/new-transaction"
			/>
			<TransactionCard
				icon={<ExchangeOneTwo width={40} height={40} />}
				cardTitle="ongoing transactions"
				cardSubtitle="Your current transactions"
				url="/financing/ongoing-transactions"
			/>
			<TransactionCard
				icon={<ExchangeOneThree width={40} height={40} />}
				cardTitle="submitted transactions"
				cardSubtitle="Your processed transactions"
				url="/financing/submitted-transactions"
			/>
		</div>
	);
};

export const RiskAppetiteCard = ({
	text,
	icon,
	textOne,
	textTwo,
	onReqClick,
}) => (
	<div
		className={`rounded-md border border-[#E3E9EC] p-4 space-y-3 ${
			textTwo ? "cursor-auto" : "cursor-pointer"
		}`}
		onClick={onReqClick}
	>
		<p className="uppercase text-[11.5px] text-[#393F42] font-semibold zabalRegFont">
			{text}
		</p>
		<div className="flex gap-3 items-center">
			<div>{icon}</div>
			<div className="space-y-1">
				<p className="text-[13.5px] text-[#8E9DA4]/90 tracking-wide zabalBoldFont">
					{textOne}
				</p>
				{textTwo && (
					<button
						className="text-fortcore-green flex items-center gap-2 text-xs zabalBoldFont"
						onClick={onReqClick}
					>
						<BsArrowRight size={17} />
						<span>{textTwo}</span>
					</button>
				)}
			</div>
		</div>
	</div>
);
RiskAppetiteCard.propTypes = {
	text: PropTypes.string,
	icon: PropTypes.object,
	textOne: PropTypes.string,
	textTwo: PropTypes.string,
	onReqClick: PropTypes.func,
};

export const InviteFriendCard = () => {
	return (
		<div className="space-y-3 border border-[#EEF2F3] bg-[#EEF2F3] rounded-md px-4 py-6  zabalBoldFont">
			<p className="max-w-[200px] text-sm text-[#555F63] leading-5">
				Invite a friend and get good Interest Deals
			</p>
			<button className="font-bold text-[11px] flex gap-1.5">
				<span className="text-fortcore-green">GET YOUR CODE</span>
				<div className="h-1.5 w-1.5 rounded-full bg-[#B8C8CF] self-start"></div>
			</button>
		</div>
	);
};

export const TransactionCard = ({ icon, cardTitle, cardSubtitle, url }) => {
	const navigate = useNavigate();

	return (
		<div
			className="border border-[#EEF2F3] rounded-md h-[105px] sm:h-[110px] md:h-[120px] lg:h-[125px] flex items-center px-5 lg:px-6 cursor-pointer blueGradient"
			onClick={() => navigate(url)}
		>
			<div className="flex gap-3 items-center">
				<div>{icon}</div>
				<div className="space-y-1">
					<p
						className={`text-[13.5px] capitalize zabalBoldFont ${
							cardTitle === "new transaction"
								? "text-fortcore-green"
								: "text-[#393F42]"
						}`}
					>
						{cardTitle}
					</p>
					<p className="text-[#788084] text-[11.5px] tracking-wide zabalRegFont">
						{cardSubtitle}
					</p>
				</div>
			</div>
		</div>
	);
};

TransactionCards.propTypes = {
	urlArr: PropTypes.arrayOf(PropTypes.string),
	isApplication: PropTypes.bool,
};

TransactionCard.propTypes = {
	icon: PropTypes.object,
	cardTitle: PropTypes.string,
	cardSubtitle: PropTypes.string,
	url: PropTypes.string,
};
