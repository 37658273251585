import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import NotifyUser from "../../layout/NotifyUser";
import { onInputAmount } from "../../common/utils";
import { closeMoveFundsFromRegSavingsModal } from "../../redux/lease/leaseActions";
import {
	CustomConfirmModal,
	SuccessCheckModal,
} from "../../layout/CustomComponents";
import { EnterPin } from "../payments/PinComponent";

const MoveFundsFromRegularSavings = ({
	activeMoveFundsModal,
	setActiveMoveFundsModal,
	amountToMove,
	setAmountToMove,
	amountSaved,
	onMoveFundsToWallet,
	onMoveFundsToWalletSuccess,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [invalidPinError, setInvalidPinError] = useState(false);

	const [inputPin, setInputPin] = useState("");
	const { currentUser } = useSelector((state) => state.auth);
	const { moveFundsLoading } = useSelector((state) => state.payment);

	const accessPin = currentUser?.accessPin || "";

	const minAmount = process.env.NODE_ENV !== "production" ? 1 : 100;

	const isGreenBtnDisabled = () => {
		if (
			Number(
				amountToMove.replaceAll(",", "") < minAmount ||
					Number(
						amountToMove.replaceAll(",", "") >
							Number(amountSaved?.replaceAll(",", ""))
					)
			)
		) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	return (
		<>
			{activeMoveFundsModal === 1 && (
				<NotifyUser
					header="Move funds from your savings"
					title="Your funds will be moved to your main wallet. Amount must be greater than NGN 100 and less than funds in your savings."
					onCloseBtnClick={() => dispatch(closeMoveFundsFromRegSavingsModal())}
					onWhiteBtnClick={() => dispatch(closeMoveFundsFromRegSavingsModal())}
					onGreenBtnClick={() => setActiveMoveFundsModal(2)}
					isGreenBtnDisabled={isGreenBtnDisabled()}
				>
					<div className="space-y-1 zabalMedFont">
						<p className="text-sm">NGN</p>
						<input
							type="tel"
							className="h-[45px] bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
							placeholder="minimum amount (100)"
							id="amountToMove"
							value={amountToMove}
							onChange={(e) => setAmountToMove(e.target.value)}
							onInput={onInputAmount}
						/>
						{isGreenBtnDisabled() && (
							<p className="text-[12.5px] text-red-500 zabalMedFont">
								Amount cannot be less than N100 or greater than N{amountSaved}
							</p>
						)}
					</div>
				</NotifyUser>
			)}
			{activeMoveFundsModal === 2 && (
				<CustomConfirmModal
					text={`You will move the amount of N${amountToMove} from your savings plan to your wallet.`}
					greenBtnText="Proceed"
					greenBtnClick={() => setActiveMoveFundsModal(3)}
					onCloseBtnClick={() => dispatch(closeMoveFundsFromRegSavingsModal())}
				/>
			)}
			{activeMoveFundsModal === 3 && (
				<EnterPin
					btnText="Confirm to Pay"
					onCloseBtnClick={() => setActiveMoveFundsModal(2)}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={currentUser?.accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onMoveFundsToWallet}
					isPaymentLoading={moveFundsLoading}
				/>
			)}
			{activeMoveFundsModal === 4 && (
				<SuccessCheckModal
					text={`The amount of N${amountToMove} has been moved from your savings plan to your wallet.`}
					onRequestClick={onMoveFundsToWalletSuccess}
				/>
			)}
		</>
	);
};

export default MoveFundsFromRegularSavings;

MoveFundsFromRegularSavings.propTypes = {
	activeMoveFundsModal: PropTypes.number,
	setActiveMoveFundsModal: PropTypes.func,
	amountToMove: PropTypes.string,
	setAmountToMove: PropTypes.func,
	amountSaved: PropTypes.string,
	onMoveFundsToWallet: PropTypes.func,
	onMoveFundsToWalletSuccess: PropTypes.func,
};
