import {
	CLOSE_ADD_BANK_ACCOUNT_MODAL,
	OPEN_ADD_BANK_ACCOUNT_MODAL,
	OPEN_ADD_BVN_MODAL,
	CLOSE_ADD_BVN_MODAL,
	OPEN_MOBILE_MENU_MODAL,
	CLOSE_MOBILE_MENU_MODAL,
	GET_PHONE_OTP_LOADING,
	GET_PHONE_OTP_SUCCESS,
	GET_PHONE_OTP_ERROR,
	VERIFY_PHONE_OTP_LOADING,
	VERIFY_PHONE_OTP_SUCCESS,
	VERIFY_PHONE_OTP_ERROR,
	DISABLE_PHONE_BUTTON_WHILE_COUNT,
	GET_EMAIL_OTP_LOADING,
	GET_EMAIL_OTP_SUCCESS,
	GET_EMAIL_OTP_ERROR,
	VERIFY_EMAIL_OTP_LOADING,
	VERIFY_EMAIL_OTP_ERROR,
	DISABLE_EMAIL_BUTTON_WHILE_COUNT,
	VERIFY_EMAIL_OTP_SUCCESS,
	GET_CURRENT_USER,
	ADD_BVN_LOADING,
	ADD_BVN_SUCCESS,
	ADD_BVN_ERROR,
	ADD_BANK_ACCOUNT_LOADING,
	ADD_BANK_ACCOUNT_SUCCESS,
	ADD_BANK_ACCOUNT_ERROR,
	OPEN_LOGOUT_MODAL,
	CLOSE_LOGOUT_MODAL,
	LOGOUT_USER,
	GET_CREDIT_SCORE_LOADING,
	GET_CREDIT_SCORE_SUCCESS,
	GET_CREDIT_SCORE_ERROR,
	GET_UPCOIMING_PAYMENTS_LOADING,
	GET_UPCOIMING_PAYMENTS_SUCCESS,
	GET_UPCOIMING_PAYMENTS_ERROR,
	OPEN_CUSTOM_ALERT,
	CLOSE_CUSTOM_ALERT,
	OPEN_AUTHORIZE_AUTODEBIT_MODAL,
	CLOSE_AUTHORIZE_AUTODEBIT_MODAL,
	CLOSE_AUTHORIZE_CREDITASSESSMENT_MODAL,
	OPEN_AUTHORIZE_CREDITASSESSMENT_MODAL,
	CLOSE_AUTHORIZE_GUARANTOR_MODAL,
	OPEN_AUTHORIZE_GUARANTOR_MODAL,
	OPEN_AUTHORIZE_INSURANCE_MODAL,
	CLOSE_AUTHORIZE_INSURANCE_MODAL,
	ADD_BANK_TO_PAGE,
	CURRENT_USER_DATA,
	CLEAR_STATES_ON_CONFIRM_LEASE,
	FETCH_GUARANTORS_DETAILS_LOADING,
	FETCH_GUARANTORS_DETAILS_SUCCESS,
	FETCH_GUARANTORS_DETAILS_ERROR,
	SAVE_GUARANTORS_DETAILS_LOADING,
	SAVE_GUARANTORS_DETAILS_ERROR,
	SAVE_GUARANTORS_DETAILS_SUCCESS,
	VERIFY_EMAIL_FOR_TRANSACTIONS_ERROR,
	VERIFY_EMAIL_FOR_TRANSACTIONS_LOADING,
	VERIFY_EMAIL_FOR_TRANSACTIONS_SUCCESS,
	CLEAR_EMAIL_VERIFICATION_ON_SUCCESS,
	CREATE_ACCESSPIN_LOADING,
	CREATE_ACCESSPIN_SUCCESS,
	CREATE_ACCESSPIN_ERROR,
	CLEAR_PIN_CREATION_SUCCESS,
	REDIRECT,
	CLEAR_REDIRECT,
	UPDATE_USER_ACCOUNT_ERROR,
	UPDATE_USER_ACCOUNT_LOADING,
	UPDATE_USER_ACCOUNT_SUCCESS,
	UPDATE_PASSWORD_LOADING,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_PASSWORD_ERROR,
	CLEAR_UPDATE_ACCOUNT_SUCCESS,
	FETCH_ALL_BANKS_LOADING,
	FETCH_ALL_BANKS_SUCCESS,
	FETCH_ALL_BANKS_ERROR,
	VERIFY_BANK_ACCOUNT_LOADING,
	VERIFY_BANK_ACCOUNT_SUCCESS,
	VERIFY_BANK_ACCOUNT_ERROR,
	CLEAR_VERIFY_ACCOUNT_SUCCESS,
	NETWORK_ERROR_TRUE,
	CLEAR_NETWORK_ERROR,
	OPEN_RESET_PIN_MODAL,
	CLOSE_RESET_PIN_MODAL,
	OPEN_MINI_LOGIN_MODAL,
	CLOSE_MINI_LOGIN_MODAL,
	TOGGLE_ACTIVATE_WALLET_MODAL,
	SAVE_HOME_ADDRESS,
} from "./authTypes";

export const openAddBankAccountModal = () => {
	return { type: OPEN_ADD_BANK_ACCOUNT_MODAL };
};
export const closeAddBankAccountModal = () => {
	return { type: CLOSE_ADD_BANK_ACCOUNT_MODAL };
};
export const openAddBvnModal = () => {
	return { type: OPEN_ADD_BVN_MODAL };
};
export const closeAddBvnModal = () => {
	return { type: CLOSE_ADD_BVN_MODAL };
};
export const openMobileMenuModal = () => {
	return { type: OPEN_MOBILE_MENU_MODAL };
};
export const closeMobileMenuModal = () => {
	return { type: CLOSE_MOBILE_MENU_MODAL };
};
export const getPhoneOtpLoading = () => {
	return { type: GET_PHONE_OTP_LOADING };
};
export const getPhoneOtpSuccess = (message) => {
	return { type: GET_PHONE_OTP_SUCCESS, payload: message };
};
export const getPhoneOtpError = () => {
	return { type: GET_PHONE_OTP_ERROR };
};
export const verifyPhoneOtpLoading = () => {
	return { type: VERIFY_PHONE_OTP_LOADING };
};
export const verifyPhoneOtpSuccess = (message) => {
	return { type: VERIFY_PHONE_OTP_SUCCESS, payload: message };
};
export const verifyPhoneOtpError = () => {
	return { type: VERIFY_PHONE_OTP_ERROR };
};
export const disablePhoneButtonWhileCount = () => {
	return { type: DISABLE_PHONE_BUTTON_WHILE_COUNT };
};
export const getEmailOtpLoading = () => {
	return { type: GET_EMAIL_OTP_LOADING };
};
export const getEmailOtpSuccess = (message) => {
	return { type: GET_EMAIL_OTP_SUCCESS, payload: message };
};
export const getEmailOtpError = () => {
	return { type: GET_EMAIL_OTP_ERROR };
};
export const verifyEmailOtpLoading = () => {
	return { type: VERIFY_EMAIL_OTP_LOADING };
};
export const verifyEmailOtpSuccess = (message) => {
	return { type: VERIFY_EMAIL_OTP_SUCCESS, payload: message };
};
export const verifyEmailOtpError = () => {
	return { type: VERIFY_EMAIL_OTP_ERROR };
};
export const disableEmailButtonWhileCount = () => {
	return { type: DISABLE_EMAIL_BUTTON_WHILE_COUNT };
};
export const getCurrentUser = (user) => {
	return { type: GET_CURRENT_USER, payload: user };
};
export const addBnvLoading = () => {
	return { type: ADD_BVN_LOADING };
};
export const addBvnSuccess = (data) => {
	return { type: ADD_BVN_SUCCESS, payload: data };
};
export const addBvnError = () => {
	return { type: ADD_BVN_ERROR };
};
export const addBankAccountLoading = () => {
	return { type: ADD_BANK_ACCOUNT_LOADING };
};
export const addBankAccountSuccess = (data) => {
	return { type: ADD_BANK_ACCOUNT_SUCCESS, payload: data };
};
export const addBankAccountError = () => {
	return { type: ADD_BANK_ACCOUNT_ERROR };
};
export const openLogoutModal = () => {
	return { type: OPEN_LOGOUT_MODAL };
};
export const closeLogoutModal = () => {
	return { type: CLOSE_LOGOUT_MODAL };
};
export const addBankAccToPage = (data) => {
	return { type: ADD_BANK_TO_PAGE, payload: data };
};
export const getCreditScoreLoading = () => {
	return { type: GET_CREDIT_SCORE_LOADING };
};
export const getCreditScoreSuccess = (data) => {
	return { type: GET_CREDIT_SCORE_SUCCESS, payload: data };
};
export const getCreditScoreError = () => {
	return { type: GET_CREDIT_SCORE_ERROR };
};
export const getUpcomingPaymentsLoading = () => {
	return { type: GET_UPCOIMING_PAYMENTS_LOADING };
};
export const getUpcomingPaymentsSuccess = (data) => {
	return { type: GET_UPCOIMING_PAYMENTS_SUCCESS, payload: data };
};
export const getUpcomingPaymentsError = () => {
	return { type: GET_UPCOIMING_PAYMENTS_ERROR };
};
export const openCustomeAlert = () => {
	return { type: OPEN_CUSTOM_ALERT };
};
export const openAuthorizeAutoDebitModal = () => {
	return { type: OPEN_AUTHORIZE_AUTODEBIT_MODAL };
};
export const closeAuthorizeAutoDebitModal = () => {
	return { type: CLOSE_AUTHORIZE_AUTODEBIT_MODAL };
};
export const openAuthorizeInsuranceModal = () => {
	return { type: OPEN_AUTHORIZE_INSURANCE_MODAL };
};
export const closeAuthorizeInsuranceModal = () => {
	return { type: CLOSE_AUTHORIZE_INSURANCE_MODAL };
};
export const openAuthorizeGuarantorModal = () => {
	return { type: OPEN_AUTHORIZE_GUARANTOR_MODAL };
};
export const closeAuthorizeGuarantorModal = () => {
	return { type: CLOSE_AUTHORIZE_GUARANTOR_MODAL };
};
export const openAuthorizeCreditAssessmentModal = () => {
	return { type: OPEN_AUTHORIZE_CREDITASSESSMENT_MODAL };
};
export const closeAuthorizeCreditAssessmentModal = () => {
	return { type: CLOSE_AUTHORIZE_CREDITASSESSMENT_MODAL };
};
export const currentUserData = (data) => {
	return { type: CURRENT_USER_DATA, payload: data };
};
export const closeCustomAlert = () => {
	return { type: CLOSE_CUSTOM_ALERT };
};
export const clearStatesOnConfirmLease = () => {
	return { type: CLEAR_STATES_ON_CONFIRM_LEASE };
};
export const fetchGuarantorsFormLoading = () => {
	return { type: FETCH_GUARANTORS_DETAILS_LOADING };
};
export const fetchGuarantorsFormSuccess = (data) => {
	return { type: FETCH_GUARANTORS_DETAILS_SUCCESS, payload: data };
};
export const fetchGuarantorsFormError = () => {
	return { type: FETCH_GUARANTORS_DETAILS_ERROR };
};
export const savingGuarantorsFormLoading = () => {
	return { type: SAVE_GUARANTORS_DETAILS_LOADING };
};
export const savingGuarantorsFormSuccess = (data) => {
	return { type: SAVE_GUARANTORS_DETAILS_SUCCESS, payload: data };
};
export const savingGuarantorsFormError = () => {
	return { type: SAVE_GUARANTORS_DETAILS_ERROR };
};
export const verifyEmailForTransactionsLoading = () => {
	return { type: VERIFY_EMAIL_FOR_TRANSACTIONS_LOADING };
};
export const verifyEmailForTransactionsSuccess = (message) => {
	return { type: VERIFY_EMAIL_FOR_TRANSACTIONS_SUCCESS, payload: message };
};
export const verifyEmailForTransactionsError = () => {
	return { type: VERIFY_EMAIL_FOR_TRANSACTIONS_ERROR };
};
export const clearEmailVerrificationOnSuccess = () => {
	return { type: CLEAR_EMAIL_VERIFICATION_ON_SUCCESS };
};
export const createAccessPinLoading = () => {
	return { type: CREATE_ACCESSPIN_LOADING };
};
export const createAccessPinSuccess = (data) => {
	return { type: CREATE_ACCESSPIN_SUCCESS, payload: data };
};
export const createAccessPinError = () => {
	return { type: CREATE_ACCESSPIN_ERROR };
};
export const clearPinCreationSuccess = () => {
	return { type: CLEAR_PIN_CREATION_SUCCESS };
};
export const logout = () => {
	return { type: LOGOUT_USER };
};
export const redirect = (path) => {
	return { type: REDIRECT, payload: path };
};
export const clearRedirect = () => {
	return { type: CLEAR_REDIRECT };
};
export const updateUserAccountLoading = () => {
	return { type: UPDATE_USER_ACCOUNT_LOADING };
};
export const updateUserAccountSuccess = (data) => {
	return { type: UPDATE_USER_ACCOUNT_SUCCESS, payload: data };
};
export const updateUserAccountError = () => {
	return { type: UPDATE_USER_ACCOUNT_ERROR };
};
export const updatePasswordLoading = () => {
	return { type: UPDATE_PASSWORD_LOADING };
};
export const updatePasswordSuccess = () => {
	return { type: UPDATE_PASSWORD_SUCCESS };
};
export const updatePasswordError = () => {
	return { type: UPDATE_PASSWORD_ERROR };
};
export const clearUpdateAccountSuccess = () => {
	return { type: CLEAR_UPDATE_ACCOUNT_SUCCESS };
};
export const fetchAllBanksLoading = () => {
	return { type: FETCH_ALL_BANKS_LOADING };
};
export const fetchAllBanksSuccess = (data) => {
	return { type: FETCH_ALL_BANKS_SUCCESS, payload: data };
};
export const fetchAllBanksError = () => {
	return { type: FETCH_ALL_BANKS_ERROR };
};
export const verifyBankAccountLoading = () => {
	return { type: VERIFY_BANK_ACCOUNT_LOADING };
};
export const verifyBankAccountSuccess = (data) => {
	return { type: VERIFY_BANK_ACCOUNT_SUCCESS, payload: data };
};
export const verifyBankAccountError = () => {
	return { type: VERIFY_BANK_ACCOUNT_ERROR };
};
export const clearVerifyAccountSuccess = () => {
	return { type: CLEAR_VERIFY_ACCOUNT_SUCCESS };
};
export const networkErrorTrue = (error) => {
	return { type: NETWORK_ERROR_TRUE, payload: error };
};
export const clearNetworkError = () => {
	return { type: CLEAR_NETWORK_ERROR };
};
export const openResetPinModal = () => {
	return { type: OPEN_RESET_PIN_MODAL };
};
export const closeResetPinModal = () => {
	return { type: CLOSE_RESET_PIN_MODAL };
};
export const openMiniLoginModal = () => {
	return { type: OPEN_MINI_LOGIN_MODAL };
};
export const closeMiniLoginModal = () => {
	return { type: CLOSE_MINI_LOGIN_MODAL };
};
export const onToggleActivateWalletModal = (actionState) => {
	return { type: TOGGLE_ACTIVATE_WALLET_MODAL, payload: actionState };
};
export const saveAddressFromBVN = (address) => {
	return { type: SAVE_HOME_ADDRESS, payload: address };
};
