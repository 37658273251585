import React, { useEffect, useState } from "react";
import { ProfileInput } from "../../../profile/sections/PersonalInfo";
import { useDispatch, useSelector } from "react-redux";
import {
	PlainSelect,
	SelectDropDown,
} from "../../../../layout/CustomComponents";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import Parse from "../../../../api/ApiConfig";
import { saveAccountInformation } from "../../../../redux/travels/travelServices";
import {
	fetchAllBanks,
	updateUserDataInParse,
} from "../../../../redux/authRedux/authServices";

const AccountInformation = () => {
	const dispatch = useDispatch();
	const { currentUser, fetchBanksLoading, fetchBanksSuccess } = useSelector(
		(state) => state.auth
	);
	const { accountInfoLoading } = useSelector((state) => state.travel);
	const [toggleBank, setToggleBank] = useState(false);

	const [accountInfo, setAccountInfo] = useState({
		bvn: currentUser?.bvn || "",
		bank_name: currentUser?.bankName || "",
		account_number: currentUser?.accountNumber || "",
		account_name: currentUser?.accountName || "",
	});

	const handleChange = (e) =>
		setAccountInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const { bvn, bank_name, account_name, account_number } = accountInfo;

	const isBtnDisabled = () => {
		if (
			bvn === "" ||
			bank_name === "" ||
			account_name === "" ||
			account_number === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onAccountInfoSave = () => {
		const data = {
			bvn,
			accountName: account_name,
			accountNumber: account_number,
			bankName: bank_name,
			userId: user,
		};

		const dataForUserProfile = {
			bvn,
			accountName: account_name,
			accountNumber: account_number,
			bankName: bank_name,
			userId: user,
		};
		dispatch(saveAccountInformation(data));
		dispatch(updateUserDataInParse(dataForUserProfile));
	};

	useEffect(() => {
		if (fetchBanksSuccess) {
			return;
		} else {
			dispatch(fetchAllBanks());
		}
	}, []);

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<ProfileInput
				type="number"
				text="BVN"
				value={bvn}
				id="bvn"
				placeholderText="Enter BVN"
				handleChange={handleChange}
				isFieldDisabled={!!currentUser?.bvn}
				color
			/>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 relative z-[1] text-[#393F42]">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					Bank:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					<PlainSelect
						context="choose bank"
						isOpened={toggleBank}
						currentText={bank_name}
						handleToggle={() => setToggleBank(!toggleBank)}
					/>
					{toggleBank && (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								isLoading={fetchBanksLoading}
								arrOptions={fetchBanksSuccess.map((bank) => bank.name)}
								onHandleSelect={(d) => {
									setAccountInfo((prev) => ({
										...prev,
										bank_name: d,
									}));
									setTimeout(() => {
										setToggleBank(false);
									}, 100);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<ProfileInput
				type="number"
				text="Account Number"
				value={account_number}
				id="account_number"
				placeholderText="Enter account number"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="Account Name"
				value={account_name}
				id="account_name"
				placeholderText="Enter account name"
				handleChange={handleChange}
			/>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || accountInfoLoading}
					isLoading={accountInfoLoading}
					onRequestClick={onAccountInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || accountInfoLoading}
					isLoading={accountInfoLoading}
					onRequestClick={onAccountInfoSave}
				/>
			</div>
		</div>
	);
};

export default AccountInformation;
