import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MdOutlineClose } from "react-icons/md";
import moment from "moment";
import { toast } from "react-toastify";
import Container from "../../../layout/Container";
import {
	addBankAccToPage,
	closeAddBankAccountModal,
	closeAddBvnModal,
	openAddBankAccountModal,
	openAddBvnModal,
} from "../../../redux/authRedux/authActions";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "../../../components/ModalContainer";
import AddBvnModal from "../../../components/AddBvnModal";
import PhoneInput from "react-phone-input-2";
import { Preloader } from "../../../components/globalStyled";
import CountDown from "../../../components/Countdown";
import {
	addBankAccount,
	disablePhoneButton,
	getPhoneOTP,
	updateUserAndPersonalData,
	verifyPhoneOTP,
} from "../../../redux/authRedux/authServices";
import { formatPhoneNumber, futureDate } from "../../../common/utils";
import { IsValidIcon } from "../../../layout/assets";
import {
	SelectDropDown,
	SuccessCheckModal,
	PlainSelect,
} from "../../../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import AddBankAccountModal from "../../../components/AddBankAccountModal";
import Parse from "../../../api/ApiConfig";

const FinanceForm = ({ onCloseForm, onMaybeBtnClick }) => {
	const dispatch = useDispatch();

	const {
		currentUser,
		verifyBvnSuccess,
		toggleAddBvnModal,
		toggleAddBankAccountModal,
		bvnSuccessMsg,
		isShowPhoneTimer,
		verifyPhoneOtpLoading,
		verifyPhoneOtpSuccess,
		bankToPage,
		phoneOtpMessageLoading,
		phoneOtpMessageSuccess,
		currentPhone,
		bankAccountSuccess,
		updatingUserAccountLoading,
	} = useSelector((state) => state.auth);

	const { savingPersonalFormSuccess } = useSelector((state) => state.lease);

	const bank_info =
		savingPersonalFormSuccess && savingPersonalFormSuccess?.get("bankAccounts")
			? savingPersonalFormSuccess?.get("bankAccounts")
			: [];

	const generalAccount = currentUser?.accountNumber
		? {
				bankName: currentUser?.bankName,
				accountNo: currentUser?.accountNumber,
				accountName: currentUser?.accountName,
		  }
		: null;

	const [toggleIdType, setToggleIdType] = useState(false);
	const [financeForm, setFinanceForm] = useState({
		phone_number: currentUser?.phone || "",
		phone_code: "",
		home_address: currentUser?.homeAddress || "",
		id_number: currentUser?.idNo || "",
		expiry_date: currentUser?.idExpiryDate
			? moment(currentUser?.idExpiryDate?.iso).format("YYYY-MM-DD")
			: "",
		id_type: currentUser?.idType || "",
		bvn: currentUser?.bvn || verifyBvnSuccess?.bvn || "",
		bank_account: bankToPage || generalAccount || null,
	});

	const {
		phone_number,
		phone_code,
		home_address,
		id_type,
		id_number,
		expiry_date,
		bank_account,
		bvn,
	} = financeForm;

	const handleChange = (e) =>
		setFinanceForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const isBvnAddSuccess = () => {
		dispatch(closeAddBvnModal());
		setFinanceForm((prev) => ({ ...prev, bvn: verifyBvnSuccess?.bvn }));
	};

	// get phoneOTP
	const onGetPhoneOtp = () => {
		if (!phone_number.startsWith("234")) {
			toast.error("Please include country code");
		} else if (phone_number.length < 12) {
			toast.error("Please include country code");
		} else {
			const phone = formatPhoneNumber(phone_number);
			dispatch(getPhoneOTP(phone));
		}
	};

	// disable resend phone otp while counter
	useEffect(() => {
		if (isShowPhoneTimer) {
			dispatch(disablePhoneButton(1000 * 115));
		}
	}, [isShowPhoneTimer]);

	//verify phoneotp code
	useEffect(() => {
		if (verifyPhoneOtpSuccess) {
			setFinanceForm((prev) => ({ ...prev, phone_code: "" }));
			return;
		}
		if (phone_code.length < 6) {
			return;
		} else if (isNaN(phone_code)) {
			return;
		} else {
			dispatch(verifyPhoneOTP(phone_number, phone_code));
		}
	}, [phone_code]);

	// check if phone is still valid
	const checkPhone = () => {
		if (
			formatPhoneNumber(currentUser?.phone) ===
				formatPhoneNumber(phone_number) &&
			currentUser?.phoneVerified
		) {
			return true;
		}
		if (
			verifyPhoneOtpSuccess &&
			currentPhone === formatPhoneNumber(phone_number)
		) {
			return true;
		} else {
			return false;
		}
	};

	const isBankAccountAddSuccess = () => {
		dispatch(closeAddBankAccountModal());
		dispatch(addBankAccToPage(bankAccountSuccess));
		setFinanceForm((prev) => ({ ...prev, bank_account: bankAccountSuccess }));
	};

	const addBankAccountDetails = (data) => {
		dispatch(addBankAccount(data));
	};

	const isProceedBtnValid = () => {
		if (
			!checkPhone() ||
			home_address === "" ||
			id_type === "" ||
			id_number === "" ||
			bvn === "" ||
			bank_account === null
		) {
			return true;
		}
		if (id_type === "International Passport" && expiry_date === "") {
			return true;
		}
		if (id_type === "Driver's Licence" && expiry_date === "") {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const a = bank_info?.find((b) => b?.accountNo === bank_account?.accountNo);
	const date = expiry_date || futureDate;
	const dateObj = new Date(date);

	const data = {
		gender:
			verifyBvnSuccess?.gender || savingPersonalFormSuccess?.get("gender"),
		bankAccounts: a ? bank_info : [...bank_info, bank_account],
		userId: user,
		user: user,
		phone: phone_number,
		homeAddress: home_address,
		DOB:
			moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY") ||
			moment(currentUser?.dateOfBirth).format("DD-MM-YYYY") ||
			moment(currentUser?.DOB).format("DD-MM-YYYY"),
		fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
		BVN: bvn,
		idType: id_type,
		idNumber: id_number,
		idExpiryDate: moment(dateObj).format("YYYY-MM-DD"),
	};

	const dataForUserProfile = {
		gender:
			verifyBvnSuccess?.gender ||
			currentUser?.gender ||
			savingPersonalFormSuccess?.get("gender"),
		phone: phone_number,
		homeAddress: home_address,
		DOB:
			moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY") ||
			moment(currentUser?.dateOfBirth).format("DD-MM-YYYY") ||
			moment(currentUser?.DOB).format("DD-MM-YYYY"),
		dateOfBirth:
			moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY") ||
			moment(currentUser?.dateOfBirth).format("DD-MM-YYYY") ||
			moment(currentUser?.DOB).format("DD-MM-YYYY"),
		bvn,
		idType: id_type,
		idNo: id_number,
		bankName: bank_account?.bankName,
		accountName: bank_account?.accountName,
		accountNumber: bank_account?.accountNo,
		idExpiryDate: {
			__type: "Date",
			iso: dateObj?.toISOString(),
		},
	};

	const onProceedBtnClick = () => {
		dispatch(
			updateUserAndPersonalData(
				data,
				dataForUserProfile,
				"form saved successfully"
			)
		);
	};

	return (
		<>
			{toggleAddBvnModal && (
				<ModalContainer modal={toggleAddBvnModal}>
					{bvnSuccessMsg ? (
						<SuccessCheckModal
							text="BVN Verified successfully"
							onRequestClick={isBvnAddSuccess}
							isShowBtn={true}
						/>
					) : (
						<AddBvnModal />
					)}
				</ModalContainer>
			)}

			{toggleAddBankAccountModal && (
				<ModalContainer modal={toggleAddBankAccountModal}>
					{bankAccountSuccess ? (
						<SuccessCheckModal
							text="Account added successfully"
							onRequestClick={isBankAccountAddSuccess}
							isShowBtn={true}
						/>
					) : (
						<AddBankAccountModal onRequestClick={addBankAccountDetails} />
					)}
				</ModalContainer>
			)}

			<Container>
				<div className="-mt-1 mb-5 sm:my-5 md:my-6 py-4 sm:py-5 lg:py-6 bg-[#F6F7F8] rounded-md space-y-4 sm:space-y-5 lg:space-y-6 w-full h-[82vh] sm:h-[84vh] md:h-[87vh] lg:h-[88vh]">
					<div className="flex items-center justify-between px-4 sm:px-5 lg:px-6">
						<p className="text-[15px] font-medium chillaxFont">
							Apprenticeship Finacing
						</p>
						<button className="font-semibold" onClick={onCloseForm}>
							<MdOutlineClose color="#00525D" size={20} />
						</button>
					</div>
					<div className="space-y-5 h-[89%] overflow-y-auto scroll-wrapper px-4 sm:px-5 lg:px-6">
						<div className="border border-[#D4DEE2] rounded flex p-3 items-center justify-between bg-[#EEF2F3]">
							<div className="text-[#393F42]">
								<p className="text-[13px] font-semibold zabalMedFont">
									One Final Step!
								</p>
								<small className="zabalMedFont opacity-75 text-xs leading-[5px">
									Complete your profile to access apprenticeship financing
									services.
								</small>
							</div>
						</div>
						<div className="space-y-5 md:space-y-6">
							<div className="grid gap-4 md:gap-5 lg:gap-6">
								{/* BVN Verification */}
								<div className="mt-2 sm:mt-0 flex items-center justify-between sm:justify-normal gap-2 sm:gap-5">
									<p className="text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Bank Verification Number (BVN) :
									</p>
									<div className="sm:w-[65%] md:w-3/5 xl:w-4/6 text-fortcore-green font-semibold zabalMedFont text-[13px] lg:text-sm">
										{bvn ? (
											<p>{bvn}</p>
										) : (
											<button onClick={() => dispatch(openAddBvnModal())}>
												Add BVN
											</button>
										)}
									</div>
								</div>

								{/* handle home address input */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Home Address:
									</p>
									<div className="h-[45px] w-full sm:w-[65%] md:w-3/5 xl:w-4/6 zabalRegFont">
										<input
											type="text"
											className="h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] focus:border-2 focus:border-fortcore-green"
											placeholder="home address"
											name="home_address"
											value={home_address}
											onChange={handleChange}
										/>
									</div>
								</div>

								{/* handle phone number input */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Verification Phone Number :
									</p>
									<div
										className={`relative w-full sm:w-[65%] md:w-3/5 xl:w-4/6 ${
											checkPhone() ? "mb-0" : "mb-2"
										}`}
									>
										<PhoneInput
											country={"ng"}
											inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded border !border-[#B8B8B8] text-[#393F42] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green zabalRegFont"
											containerClass="!text-[12.5px] lg:!text-[13px] !w-full !border-[#B8B8B8] !h-[45px] zabalRegFont focus:!border-2 focus:!border-fortcore-green"
											value={phone_number}
											onChange={(phone_number) =>
												setFinanceForm({
													...financeForm,
													phone_number: phone_number,
												})
											}
											inputProps={{ maxLength: 19 }}
										/>
										{!checkPhone() && (
											<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
												Phone number must be verified
											</p>
										)}
										<div className="absolute flex gap-2 items-center right-3 h-[45px] top-0">
											{checkPhone() && (
												<span>
													<IsValidIcon width="15px" height="15px" />
												</span>
											)}
											{!phoneOtpMessageSuccess && !checkPhone() && (
												<button
													className={`h-full flex items-center justify-center md:w-[60px] text-xs md:text-[12.5px] font-semibold zabalMedFont ${
														phoneOtpMessageLoading || phone_number.length < 12
															? "text-[#717E83]"
															: "text-fortcore-green"
													} ${
														phoneOtpMessageLoading ? "w-[42px]" : "w-[56px]"
													}`}
													onClick={onGetPhoneOtp}
													disabled={
														phoneOtpMessageLoading || phone_number.length < 12
													}
												>
													{phoneOtpMessageLoading ? <Preloader /> : "Get Code"}
												</button>
											)}
										</div>
									</div>
								</div>

								{/* handle phone number otp Verification */}
								{phoneOtpMessageSuccess && (
									<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
										<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
											Enter Code :
										</p>
										<div className="space-y-1.5 w-full sm:w-[65%] md:w-3/5 xl:w-4/6">
											<div className="relative h-[45px] w-full zabalMedFont">
												<input
													type="tel"
													className="text-sm h-full w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
													placeholder="phone number otp"
													maxLength={6}
													name="phone_code"
													value={phone_code}
													onChange={handleChange}
												/>
												{verifyPhoneOtpLoading && (
													<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
														<Preloader />
													</div>
												)}
											</div>
											<div className="pl-4 pr-2 text-[11px] flex justify-between text-fortcore-green zabalMedFont">
												{isShowPhoneTimer && <CountDown timer={115} />}
												<div className="space-x-1.5 justify-self-end ">
													<span className="text-[#393F42]">
														Didn&apos;t get code?
													</span>
													<button
														className={`font-semibold ${
															isShowPhoneTimer ||
															phoneOtpMessageLoading ||
															phone_number.length < 12
																? "text-[#717E83]"
																: "text-fortcore-green"
														}`}
														onClick={onGetPhoneOtp}
														disabled={
															isShowPhoneTimer ||
															phoneOtpMessageLoading ||
															phone_number.length < 12
														}
													>
														{phoneOtpMessageLoading
															? "Sending..."
															: "Resend Code"}
													</button>
												</div>
											</div>
										</div>
									</div>
								)}

								{/* handle number of iD selection */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Means of Identification :
									</p>
									<div className="w-full sm:w-[65%] md:w-3/5 xl:w-4/6 relative">
										<PlainSelect
											context="choose Id type"
											isOpened={toggleIdType}
											currentText={id_type}
											handleToggle={() => setToggleIdType((prev) => !prev)}
										/>
										{toggleIdType && (
											<div className="absolute z-[2] w-full top-48px">
												<SelectDropDown
													arrOptions={[
														"International Passport",
														"Driver's Licence",
														"National Identity Number (NIN)",
														"Voter's Card",
													]}
													onHandleSelect={(d) => {
														setFinanceForm((prev) => ({
															...prev,
															id_type: d,
														}));
														setTimeout(() => {
															setToggleIdType(false);
														}, 100);
													}}
												/>
											</div>
										)}
									</div>
								</div>

								{/* handle id number input */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										ID No:
									</p>
									<div className="h-[45px] w-full sm:w-[65%] md:w-3/5 xl:w-4/6 zabalRegFont">
										<input
											type="text"
											className="h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] focus:border-2 focus:border-fortcore-green"
											placeholder="ID Number"
											name="id_number"
											value={id_number}
											onChange={handleChange}
										/>
									</div>
								</div>

								{/* handle id expiry date input */}
								{id_type !== "" &&
									id_type !== "National Identity Number (NIN)" && (
										<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
											<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
												ID Expiry Date:
											</p>
											<div
												className={`h-[45px] relative w-full sm:w-[65%] md:w-3/5 xl:w-4/6 ${
													expiry_date === "" ? "mb-2.5" : "mb-0"
												}`}
											>
												<input
													type="date"
													className={`h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] zabalRegFont bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] focus:border-2 focus:border-fortcore-green ${
														expiry_date === ""
															? "text-[#B8B8B8]"
															: "text-[#393F42]"
													}`}
													min={moment().format("YYYY-MM-DD")}
													name="expiry_date"
													value={expiry_date}
													onChange={handleChange}
												/>
												{expiry_date === "" && (
													<p className="pt-0.5 text-xs zabalMedFont text-red-500">
														ID Card expiry date must be specified
													</p>
												)}
											</div>
										</div>
									)}
								{/* handle bank account listing */}
								<div className="flex flex-col sm:flex-row sm:items-center justify-between sm:justify-normal gap-2 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
									<p className="text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Link Bank Account :
									</p>
									<div className="sm:w-[65%] md:w-3/5 zabalMedFont lg:text-sm flex gap-3 justify-between text-fortcore-green items-start">
										{bank_account && (
											<div className="space-y-1">
												<p>{bank_account?.bankName}</p>
												<p className="text-xs text-[#8b8b8b]">
													{bank_account?.accountNo}
												</p>
											</div>
										)}
										<button
											type="button"
											onClick={() => dispatch(openAddBankAccountModal())}
											className={`${
												bank_account
													? "text-primary-color"
													: "text-fortcore-green"
											}`}
										>
											{bank_account ? "Change " : "Add "}Account
										</button>
									</div>
								</div>
							</div>
							<div className="bg-[#E3E9EC] h-[1px] w-full hidden sm:block"></div>

							{/* form buttons */}
							<div className="hidden sm:flex justify-end gap-4">
								{location.pathname === "/financing/new-transaction" &&
									isProceedBtnValid() && (
										<CustomWhiteBtn
											text="maybe later"
											width="120px"
											height="42px"
											fontSize="13px"
											onRequestClick={onMaybeBtnClick}
										/>
									)}
								<CustomGreenBtn
									text="proceed"
									width="120px"
									height="42px"
									fontSize="13px"
									isDisabled={isProceedBtnValid() || updatingUserAccountLoading}
									onRequestClick={onProceedBtnClick}
									isLoading={updatingUserAccountLoading}
								/>
							</div>
							<div className="flex flex-col gap-4 pt-4 sm:hidden">
								<CustomGreenBtn
									text="proceed"
									isDisabled={isProceedBtnValid() || updatingUserAccountLoading}
									onRequestClick={onProceedBtnClick}
									isLoading={updatingUserAccountLoading}
								/>
								{location.pathname === "/financing/new-transaction" &&
									isProceedBtnValid() && (
										<CustomWhiteBtn
											text="maybe later"
											onRequestClick={onMaybeBtnClick}
										/>
									)}
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

FinanceForm.propTypes = {
	onCloseForm: PropTypes.func,
	onMaybeBtnClick: PropTypes.func,
};

export default FinanceForm;
