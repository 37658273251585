import React, { useContext } from "react";
import { AnyModal } from "../../finance/newTransaction/investor/create/InvestorCreateAF";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import theme from "../../../components/mui/theme";
import userContext from "../../../context/UserContext";
import { onBlurAmount, onInputAmount } from "../../../common/utils";

function PaymentAmount({ open, close, onClick }) {
  const { fundWallet, setFundWallet } = useContext(userContext);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFundWallet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProceed = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <>
      <AnyModal open={open}>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h3"
              sx={{ fontFamily: "ZabalBold", fontSize: "15px" }}
              color="primary"
            >
              Amount
            </Typography>
            <IconButton onClick={close}>
              <IoMdClose style={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>
          <Typography variant="caption" color="textSecondary">
            Enter amount you want to fund your wallet
          </Typography>

          <Box mt={2} component="form" onSubmit={handleProceed}>
            <Stack spacing={1}>
              <Typography
                variant="b"
                ody1sx={{ fontFamily: "ZabalMedium", fontSize: "14px" }}
                color="textSecondary"
              >
                NGN
              </Typography>
              <input
                type="text"
                inputMode="numeric"
                style={inputStyle}
                placeholder="50,000"
                name="amount"
                value={fundWallet.amount}
                onChange={handleInputChange}
                required
                onInput={onInputAmount}
                onBlur={(e) => onBlurAmount(e, fundWallet.amount)}
              />
            </Stack>

            <Box
              mt={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              width="100%"
            >
              <Stack direction="row" spacing={2}>
							<Button
                  variant="outlined"
                  onClick={close}
                  type="button"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Proceed
                </Button>
               
              </Stack>
            </Box>

            {/* Mobile Buttons  */}
            <Box mt={2} width="100%" sx={{ display: { sm: "none" } }}>
              <Stack spacing={2}>
              
                <Button variant="contained" type="submit">
                  Proceed
                </Button>
								<Button variant="outlined" onClick={close} type="button">
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </AnyModal>
    </>
  );
}

PaymentAmount.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func,
};
export default PaymentAmount;

export const inputStyle = {
  border: "1px solid #00525D",
  height: "55px",
  padding: "15px",
  borderRadius: "8px",

  "&:focus": {
    outline: "none",
    border: "1px solid #00525D",
  },
  "&:hover": {
    outline: "none",
    border: "1px solid #00525D",
  },
};
