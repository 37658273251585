import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem,
	Modal,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { industries } from "./Constants";
import theme from "../../../components/mui/theme";
import {
	AiFillCheckCircle,
	AiFillCloseCircle,
	AiFillFileText,
} from "react-icons/ai";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { InfoIcon } from "../../../layout/assets";
import baseURL from "../../../api/UrlConfig";
import axios from "axios";
import Parse from "../../../api/ApiConfig";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../../redux/authRedux/authActions";
import { toast } from "react-toastify";
import { ContentBox } from "../styled";
import { Preloader } from "../../../components/globalStyled";
import Check from "../assets/check.svg";

function BusinessForm({ step, email }) {
	const navigate = useNavigate();
	const [form, setForm] = useState({
		schemeOwnerName: "",
		phoneNumber: "",
		industry: "",
		staffStrength: "",
		approvalLevel: "",
		adminGroupEmail: "",
		password: "",
		confirmPassword: "",
		inviteCode: "",
	});
	const [phoneNumber, setPhoneNumber] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const fileInputRef = useRef();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [success, setSuccess] = useState(false);
	const [loginLoading, setLoginLoading] = useState(false);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setSelectedFile(file);
		}
	};

	const handleFormChange = (e) => {
		const { name, value } = e.target;
		setForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const options = Array.from({ length: 100 }, (_, i) => i + 1);
	const options2 = Array.from({ length: 3 }, (_, i) => i + 1);

	const hasNumber = /\d/.test(form.password);
	const hasSpecialCharacter = /[!@#=$%^&*()_+{}\\[\]:;<>,.?~\\/-]/.test(
		form.password
	);
	const hasLowerCase = /[a-z]/.test(form.password);
	const hasUpperCase = /[A-Z]/.test(form.password);

	const handleForm = (e) => {
		e.preventDefault();
		setLoading(true);

		const data = JSON.stringify({
			email: email,
			schemeOwnerName: form.schemeOwnerName,
			phone: phoneNumber,
			adminEmail: form.adminGroupEmail,
			industry: form.industry,
			staffStrength: form.staffStrength,
			approvalLevel: form.approvalLevel,
			cac: "https://pre.cac.gov.ng/assets/images/CERTIFICATE%20-%20TEST%20FOUNDATION.jpg",
			password: form.password,
			inviteCode: form.inviteCode,
		});

		const config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/functions/createCorporateAccount`,
			headers: {
				// eslint-disable-next-line no-undef
				"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				// eslint-disable-next-line no-undef
				console.log(response.data);
				setSuccess(true);
				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-undef
				console.log(error);
				setLoading(false);
				if (error.message === "Network Error") {
					toast.error("Network Error");
				} else {
					toast.error(error.response.data.error);
				}
			});
	};
	// eslint-disable-next-line no-undef
	console.log(email);
	const handleLogin = async () => {
		setLoginLoading(true);
		try {
			const user = await Parse.User.logIn(email, form.password);

			if (user) {
				const currentUser = Parse.User.current();
				const userData = currentUser?.toJSON();
				// eslint-disable-next-line no-undef
				console.log("CurrentUser: ", userData);
				dispatch(getCurrentUser(userData));
				setLoginLoading(false);
				setSuccess(false);
				navigate("/dashboard", { replace: true });
				// eslint-disable-next-line no-undef
				localStorage.removeItem("accountType");
			}
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
			setLoginLoading(false);
			if (error.message === "Invalid username/password.") {
				toast.error("Invalid email/password.");
			} else {
				toast.error(error.message);
			}
		}
	};
	return (
		<>
			<Box
				component="form"
				onSubmit={handleForm}
				sx={{
					overflowY: "auto",
					height: { xs: "100%", md: "90vh" },
					mt: { xs: 10, md: 7 },
					pb: { xs: 0, md: 10 },
					pt: { xs: 0, md: "32px" },
					pl: { xs: 0, md: "32px" },
					pr: { xs: 0, md: "32px" },
					border: { md: "0.5px solid #EEF2F3" },
					borderRadius: "8px",
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}>
				<Stack spacing={2.5} mt={{ xs: 5, md: 0 }} mb={4} sx={{}}>
					<Typography variant="h2" color="primary">
						Step {step} of 3
					</Typography>
					<hr />
					<Stack spacing={1}>
						<Typography variant="h1" color="info.light">
							Scheme Owner Profile
						</Typography>
						<Typography variant="body1" color="secondary.dark">
							Create your account profile by filling your details below.
						</Typography>
					</Stack>
				</Stack>

				<Stack spacing={3}>
					{/* Scheme Owner’s Name  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Scheme Owner’s Name
						</Typography>
						<TextField
							required
							type="text"
							placeholder="John"
							name="schemeOwnerName"
							value={form.schemeOwnerName}
							onChange={handleFormChange}
							InputProps={{
								style: {
									borderRadius: "8px",
								},
							}}
						/>
					</Stack>

					{/* Phone no.  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Phone no.
						</Typography>
						<div className="phone">
							<PhoneInput
								country={"ng"}
								value={phoneNumber}
								onChange={(value) => setPhoneNumber(value)}
								inputProps={{
									maxLength: 18,
								}}
								required
								containerStyle={{
									display: "flex",
									width: "100%",
								}}
								inputStyle={{
									borderRadius: "8px",
									marginLeft: "10px",
									border: "1px solid #96a0a5",
									width: "100%",
									height: "50px",
								}}
							/>
						</div>
					</Stack>

					{/* Industry  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Industry
						</Typography>

						<FormControl fullWidth>
							<Select
								required
								name="industry"
								value={form.industry}
								onChange={handleFormChange}>
								{industries.map((item, index) => {
									return (
										<MenuItem key={index} value={item.value}>
											{item.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Stack>

					{/* Staff Strength  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Staff Strength
						</Typography>

						<FormControl fullWidth>
							<Select
								required
								name="staffStrength"
								value={form.staffStrength}
								onChange={handleFormChange}>
								{options.map((item) => (
									<MenuItem key={item} value={item}>
										{item}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>

					{/* Approval Level  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Approval Level
						</Typography>

						<FormControl fullWidth>
							<Select
								required
								name="approvalLevel"
								value={form.approvalLevel}
								onChange={handleFormChange}>
								{options2.map((item) => (
									<MenuItem key={item} value={item}>
										{item}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>

					{/* Admin Group Email  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Admin Group Email
						</Typography>
						<TextField
							type="email"
							placeholder="cooperation@gmail.com"
							name="adminGroupEmail"
							value={form.adminGroupEmail}
							onChange={handleFormChange}
							InputProps={{
								style: {
									borderRadius: "8px",
								},
							}}
							required
						/>
					</Stack>

					{/* CAC Document  */}
					<Stack spacing={1.5}>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography variant="body1" color="info.light">
								CAC Document
							</Typography>
							<Tooltip title="Upload CAC Docs">
								<InfoIcon />
							</Tooltip>
						</Stack>
						<input
							type="file"
							accept=".pdf, .doc, .docx"
							onChange={handleFileChange}
							ref={fileInputRef}
							style={{ display: "none" }}
						/>
						<TextField
							type="text"
							required
							value={selectedFile ? selectedFile.name : ""}
							InputProps={{
								readOnly: true,
								style: {
									borderRadius: "8px",
								},
								endAdornment: (
									<InputAdornment position="end" sx={{ padding: 0 }}>
										<button
											type="button"
											onClick={handleButtonClick}
											style={{
												border: `1px solid ${theme.palette.primary.main}`,
												padding: "12px 20px",
												borderRadius: "8px",
												marginRight: "-10px",
												color: theme.palette.primary.main,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<AiFillFileText size={20} /> &nbsp; Add New File
										</button>
									</InputAdornment>
								),
							}}
						/>
					</Stack>

					{/* Password  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Password
						</Typography>
						<Tooltip
							title={
								<Box
									sx={{
										background: "#fff",
										margin: "-4px -8px",
										padding: "4px 8px",
										borderRadius: "2px",
										boxShadow: theme.shadows[1],
									}}>
									<Stack>
										<Typography variant="h5" color="primary">
											Password must contain:
										</Typography>
										<Box
											component="ul"
											sx={{
												li: {
													marginTop: "5px",
													marginBottom: "5px",
												},
											}}>
											<li>
												<Stack direction="row" alignItems="center" spacing={1}>
													{form.password.length > 7 ? (
														<AiFillCheckCircle color="#03f62c" />
													) : (
														<AiFillCloseCircle color="#7e0505" />
													)}{" "}
													<Typography
														variant="caption"
														color={theme.palette.secondary.light}
														sx={{ lineHeight: "100%" }}>
														Between 8 and 250 characters
													</Typography>
												</Stack>
											</li>
											<li>
												<Stack direction="row" alignItems="center" spacing={1}>
													{hasUpperCase ? (
														<AiFillCheckCircle color="#03f62c" />
													) : (
														<AiFillCloseCircle color="#7e0505" />
													)}{" "}
													<Typography
														variant="caption"
														color={theme.palette.secondary.light}
														sx={{ lineHeight: "100%" }}>
														At least 1 uppercase letter
													</Typography>
												</Stack>
											</li>

											<li>
												<Stack direction="row" alignItems="center" spacing={1}>
													{hasLowerCase ? (
														<AiFillCheckCircle color="#03f62c" />
													) : (
														<AiFillCloseCircle color="#7e0505" />
													)}{" "}
													<Typography
														variant="caption"
														color={theme.palette.secondary.light}
														sx={{ lineHeight: "100%" }}>
														At least 1 lowercase letter
													</Typography>
												</Stack>
											</li>

											<li>
												<Stack direction="row" alignItems="center" spacing={1}>
													{hasSpecialCharacter ? (
														<AiFillCheckCircle color="#03f62c" />
													) : (
														<AiFillCloseCircle color="#7e0505" />
													)}{" "}
													<Typography
														variant="caption"
														color={theme.palette.secondary.light}
														sx={{ lineHeight: "100%" }}>
														At least 1 special character
													</Typography>
												</Stack>
											</li>

											<li>
												<Stack direction="row" alignItems="center" spacing={1}>
													{hasNumber ? (
														<AiFillCheckCircle color="#03f62c" />
													) : (
														<AiFillCloseCircle color="#7e0505" />
													)}{" "}
													<Typography
														variant="caption"
														color={theme.palette.secondary.light}
														sx={{ lineHeight: "100%" }}>
														At least 1 number
													</Typography>
												</Stack>
											</li>
										</Box>
									</Stack>
								</Box>
							}
							placement="left">
							<TextField
								type={showPassword ? "text" : "password"}
								name="password"
								value={form.password}
								onChange={handleFormChange}
								required
								placeholder="At least 8 Characters with one character"
								InputProps={{
									style: {
										borderRadius: "8px",
									},
									endAdornment: (
										<InputAdornment position="end" sx={{ padding: 0 }}>
											{showPassword ? (
												<IconButton onClick={() => setShowPassword(false)}>
													<BsFillEyeFill color={theme.palette.primary.main} />
												</IconButton>
											) : (
												<IconButton onClick={() => setShowPassword(true)}>
													<BsFillEyeSlashFill
														color={theme.palette.primary.main}
													/>
												</IconButton>
											)}
										</InputAdornment>
									),
								}}
							/>
						</Tooltip>
					</Stack>

					{/* Confirm Password  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Confirm Password
						</Typography>

						<TextField
							error={form.confirmPassword !== form.password}
							type={showConfirmPassword ? "text" : "password"}
							name="confirmPassword"
							value={form.confirmPassword}
							onChange={handleFormChange}
							placeholder="Re-enter Password"
							required
							helperText={
								form.confirmPassword !== form.password &&
								"Confirm Password must match password"
							}
							InputProps={{
								style: {
									borderRadius: "8px",
								},
								endAdornment: (
									<InputAdornment position="end" sx={{ padding: 0 }}>
										{showConfirmPassword ? (
											<IconButton onClick={() => setShowConfirmPassword(false)}>
												<BsFillEyeFill color={theme.palette.primary.main} />
											</IconButton>
										) : (
											<IconButton onClick={() => setShowConfirmPassword(true)}>
												<BsFillEyeSlashFill
													color={theme.palette.primary.main}
												/>
											</IconButton>
										)}
									</InputAdornment>
								),
							}}
						/>
					</Stack>

					{/* Invite Code (Optional)  */}
					<Stack spacing={1.5}>
						<Typography variant="body1" color="info.light">
							Invite Code (Optional)
						</Typography>
						<TextField
							type="text"
							placeholder="Enter your invite code"
							name="inviteCode"
							value={form.inviteCode}
							onChange={handleFormChange}
							InputProps={{
								style: {
									borderRadius: "8px",
								},
							}}
						/>
					</Stack>

					<FormControlLabel
						required
						control={<Checkbox sx={{ ml: -1.5 }} />}
						label={
							<Typography>
								I Accept Privacy Policies,{" "}
								<Link>
									<Typography
										component="span"
										color="primary"
										sx={{ textDecoration: "underline" }}>
										Terms & Conditions
									</Typography>
								</Link>
							</Typography>
						}
					/>

					{!loading ? (
						<Button
							type="submit"
							variant="contained"
							disabled={
								form.password !== form.confirmPassword ||
								form.password.length < 7 ||
								!hasUpperCase ||
								!hasLowerCase ||
								!hasSpecialCharacter ||
								!hasNumber
							}>
							Proceed
						</Button>
					) : (
						<Button
							type="button"
							disabled
							variant="contained"
							sx={{ background: "#00525D !important", opacity: 0.7 }}>
							<CircularProgress
								size={20}
								sx={{ color: theme.palette.secondary.main }}
							/>
						</Button>
					)}
				</Stack>
			</Box>

			<Modal
				open={success}
				// onClose={handleClose}
			>
				<Box sx={style}>
					<ContentBox>
						<img src={Check} alt="" />
						<p>Account Created Successfully</p>
						{loginLoading ? (
							<Button variant="contained" color="primary">
								<Preloader />
							</Button>
						) : (
							<Button
								variant="contained"
								color="primary"
								type="button"
								onClick={handleLogin}>
								Go to Dashboard
							</Button>
						)}
					</ContentBox>
				</Box>
			</Modal>
		</>
	);
}

BusinessForm.propTypes = {
	step: PropTypes.number,
	email: PropTypes.string,
};

export default BusinessForm;

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	display: "flex",
	justifyContent: "center",
};
