import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openConfirmAutoDebitModal } from "../../redux/lease/leaseActions";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import EmptyData from "../EmptyData";

const SingleSavingsEmpty = ({ query, savingUrl }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onRequestClick = () => {
		if (savingUrl) {
			dispatch(openConfirmAutoDebitModal(savingUrl));
		} else {
			navigate("/leasing/asset-based-savings");
		}
	};

	return (
		<>
			<p className="text-sm zabalMedFont text-gray-500">
				TransactionID: <span className="text-red-500">{query}</span>
			</p>
			<div className="h-[300px] sm:h-[400px] flex flex-col items-center justify-center gap-3">
				<div className="w-full">
					<EmptyData text="No savings exist with this ID. Click the button below to create a new savings." />
				</div>
				<CustomGreenBtn
					width="103px"
					height="40px"
					text="save now"
					onRequestClick={onRequestClick}
				/>
			</div>
		</>
	);
};

export default SingleSavingsEmpty;

SingleSavingsEmpty.propTypes = {
	query: PropTypes.string.isRequired,
	savingUrl: PropTypes.string,
};
