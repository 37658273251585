import React from "react"
import PropTypes from 'prop-types'
import { ImBin } from 'react-icons/im'

const EmptyData = ({ text }) => {
  return (
    <div className={`bg-inherit h-full w-full grid place-items-center`}>
      <div className="w-full flex flex-col gap-1.5 justify-center items-center">
        {/* <EmptyIcon width={170} height={90} /> */}
        <ImBin size={32} color='#C6D3D8' />
        <p className="text-[#8E9DA4] text-[13.5px] zabalRegFont w-9/12 max-w-[250px] sm:max-w-[300px] mx-auto text-center">{text}</p>
      </div>
    </div>
  )
}
export default EmptyData

EmptyData.propTypes = {
  text: PropTypes.string,
}