import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import userContext from "../../../../../../context/UserContext";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	// TextField,
	Typography,
} from "@mui/material";
import Parse from "../../../../../../api/ApiConfig";
import { toast } from "react-toastify";
import { BsDot } from "react-icons/bs";
import { AttachmentUploadComponent } from "../UPLOAD/AttachmentUploadComponent";
import { BusinessPhotoComponent } from "../UPLOAD/ProductPhoto";
import PropTypes from "prop-types";
// import VerifyModal from "../VerifyModal";
import { BusinessProof } from "../UPLOAD/BusinessProof";
import { getProfilePercentage } from "../../../../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import {
	onSaveUserFinanceRequest,
	onSelectFinanceOption,
	onToggleFinanceFormModal,
} from "../../../../../../redux/financing/financeActions";
import { SuccessCheckModal } from "../../../../../../layout/CustomComponents";
import FinanceForm from "../../../FinanceForm";
import { DesktopSideModal } from "../../../../../../components/ModalContainer";
import { createFinaceReqAsOngoing } from "../../../../../../redux/financing/financeServices";
import { useNavigate } from "react-router-dom";
import { clearUpdateAccountSuccess } from "../../../../../../redux/authRedux/authActions";
// import { useNavigate } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
export function FormFileUpload({ step, loading, setLoading }) {
	const { requestFinance, setRequestFinance } = useContext(userContext);
	const navigate = useNavigate();

	// const [open, setOpen] = useState(false);
	const [neededFiles, setNeededFiles] = useState(false);
	const [businessProof, setBusinessProof] = useState(false);

	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const { currentUser, updatingUserAccountSuccess } = useSelector(
		(state) => state.auth
	);
	const { toggleFinanceModal, userFinanceRequest } = useSelector(
		(state) => state.finance
	);

	useEffect(() => {
		if (requestFinance.proofOfPurchase !== "") {
			setBusinessProof(true);
		} else if (requestFinance.businessProofFile) {
			setBusinessProof(true);
		} else {
			setBusinessProof(false);
		}
	}, [
		requestFinance.proofOfPurchase,
		requestFinance.businessProofFile,
		setBusinessProof,
	]);

	useEffect(() => {
		if (businessProof && requestFinance.productPhoto.length > 0) {
			setNeededFiles(true);
		} else {
			setNeededFiles(false);
		}
	}, [businessProof, requestFinance.productPhoto]);

	const handleBusinessProofUpload = (file) => {
		setRequestFinance((prevState) => ({
			...prevState,
			businessProofFile: file,
		}));
	};

	const handleAttachmentUpload = (file) => {
		setRequestFinance((prevState) => ({
			...prevState,
			attachmentFile: file,
		}));
	};

	const handleImagesUpload = (file) => {
		setRequestFinance((prevState) => ({
			...prevState,
			productPhoto: file,
		}));
	};

	const financeProfileValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const financeProfilePercentage = getProfilePercentage(
		financeProfileValidation
	);

	const handleForm = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const currentUser = Parse.User.current();

			if (currentUser) {
				if (financeProfilePercentage > 33) {
					const data = {
						...userFinanceRequest,
						// proofOfPurchase: requestFinance?.proofOfPurchase,
						attachmentDoc: requestFinance?.attachmentFile,
						proofOfPurchaseFile: requestFinance?.businessProofFile,
						productPhotos: requestFinance?.productPhoto,
						requestStatus: "ongoing",
					};

					try {
						const response = await createFinaceReqAsOngoing(data);

						if (response) {
							const updatedData = {
								...data,
								createdAt: response?.createdAt,
								objectId: response?.objectId,
							};
							setRequestFinance((prevState) => ({
								...prevState,
								objectId: response?.objectId,
								// formStep: 2,
							}));
							dispatch(onSaveUserFinanceRequest(updatedData));

							toast.success(
								"Finance request created. Complete the next steps to submit"
							);
							setTimeout(() => {
								// navigate("/financing/ongoing-transactions");
								navigate("/financing/ongoing-transactions/request-finance");
							}, 2000);
						}
					} catch (error) {
						console.log(error);
						throw error;
					}
				} else {
					// setLoading(true);
					// setOpen(true);
					dispatch(onToggleFinanceFormModal(true));
					dispatch(onSelectFinanceOption("request"));
				}
			} else {
				setLoading(true);
				Parse.User.logOut();
				toast.error("You have been logged out of your account");
				setTimeout(() => {
					window.location.href = "/login";
				}, 500);
			}
		} catch (error) {
			if (error.message.includes("Unable to connect to the Parse API")) {
				toast.error("Oops. You are not connected to the internet");
			} else {
				toast.error(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const onFinanceFormSaveSuccess = () => {
		dispatch(onToggleFinanceFormModal(false));
		dispatch(clearUpdateAccountSuccess());
	};

	return (
		<>
			{toggleFinanceModal && (
				<DesktopSideModal modal={toggleFinanceModal}>
					<>
						{updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onFinanceFormSaveSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							<FinanceForm
								onCloseForm={() => dispatch(onToggleFinanceFormModal(false))}
								// onMaybeBtnClick={onMaybeLaterBtnClick}
							/>
						)}
					</>
				</DesktopSideModal>
			)}
			<Box component="form" onSubmit={handleForm}>
				<Stack>
					{/* Upload File  */}
					<Box mt={3} mb={3}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<BsDot style={{ fontSize: "30px" }} />
							<Box width="100%">
								<Stack spacing={2}>
									<Typography variant="caption" color="textSecondary">
										List items you want to purchase and show proof of what you
										are purchasing and from whom you are purchasing
									</Typography>

									<Box width="100%">
										<Stack
											direction={{ xs: "column", sm: "row" }}
											justifyContent="space-between"
											alignItems="center"
											spacing={{ xs: 1, sm: 5 }}
										>
											{/* <TextField
												type="text"
												name="proofOfPurchase"
												value={requestFinance.proofOfPurchase}
												onChange={handleInputChange}
												label="Type Manually"
												multiline
												sx={{ height: "124px", width: "100%" }}
												rows={4.5}
												placeholder="Type Manually"
											/> */}

											{/* <Box>
												<Typography
													variant="caption"
													color="textSecondary"
													textAlign="center"
												>
													or
												</Typography>
											</Box> */}

											{/* First File Upload  */}

											{/* <ImageProofUploadComponent
												onFileUpload={handleFileUpload}
												image={requestFinance.imageURL}
											/> */}

											<BusinessProof
												onFileUpload={handleBusinessProofUpload}
												fileName={requestFinance?.businessProofFile?.fileName}
											/>
										</Stack>
									</Box>
								</Stack>
							</Box>
						</Stack>
					</Box>

					{/* Attachments  */}
					<Box mt={3} mb={3}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<BsDot style={{ fontSize: "30px" }} />
							<Box width="100%">
								<Stack spacing={2}>
									<Box>
										<Typography variant="body1" color="primary">
											Attachments{" "}
											<Typography variant="caption" color="textSecondary">
												(optional)
											</Typography>
										</Typography>
										<Typography variant="caption" color="textSecondary">
											Adding important document such as business plan,
											certificate of incorporation etc. Can increase your chance
											of getting an investor
										</Typography>
									</Box>
									<AttachmentUploadComponent
										onFileUpload={handleAttachmentUpload}
										fileName={requestFinance?.attachmentFile?.fileName}
									/>
								</Stack>
							</Box>
						</Stack>
					</Box>

					{/* Upload Business  */}

					<Box mt={3} mb={3}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<BsDot style={{ fontSize: "30px" }} />
							<Box width="100%">
								<Stack spacing={2}>
									<Box>
										<Typography variant="body1" color="primary">
											Upload Business / Product Photo
										</Typography>
										<Typography variant="caption" color="textSecondary">
											Business / Product photo will be used for the cover
											picture of your request you can add maximum of 4 Jpeg or
											Png Image
										</Typography>
									</Box>

									<BusinessPhotoComponent
										onFileUpload={handleImagesUpload}
										images={requestFinance?.productPhoto}
									/>
								</Stack>
							</Box>
						</Stack>
					</Box>

					<Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							variant="text"
							onClick={() => {
								setRequestFinance((prevState) => ({
									...prevState,
									formStep: 0,
								}));
							}}
						>
							Back
						</Button>

						{!loading ? (
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={!neededFiles}
							>
								Proceed
							</Button>
						) : (
							<Button variant="contained" color="primary" type="button">
								<CircularProgress size="20px" sx={{ color: "#fff" }} />
							</Button>
						)}
					</Box>
				</Stack>
			</Box>
			{/* 
			{open && (
				<VerifyModal
					open={open}
					handleClose={() => setOpen(false)}
					link="/financing/new-transaction/market-place/form/upload/final"
				/>
			)} */}
		</>
	);
}
FormFileUpload.propTypes = {
	step: PropTypes.func,
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
};

// Image
export const ContainerImage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 40px 0;
	background: rgba(140, 48, 245, 0.02);
	border: 1px dashed var(--grey-1100, #96a0a5);
	border-radius: 10px;
	width: 100%;
	height: 122px;
	cursor: pointer;
`;

export const ImageContainer = styled.div`
	.bod {
		width: 100%;
		height: 162px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	p {
		color: rgba(11, 13, 23, 0.5);
		font-size: 14px;
	}
`;

export const Image = styled.img`
	width: 100%;
	max-height: 162px;
	object-fit: cover;
`;

export const Input = styled.input`
	display: none;
`;
