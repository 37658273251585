import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	SavingsButtonNavigation,
	UrlTracker,
} from "../../../layout/CustomComponents";
import SingleSavingsEmpty from "../../../components/regularsavingscomp/SingleSavingsEmpty";
import { Preloader } from "../../../components/globalStyled";
import {
	fetchSingleAssetBasedSavings,
	updateSingleAssetSavings,
} from "../../../redux/lease/leaseServices";
import { AssetSavingsItem } from "./AssetBasedSavingsView";
import ModalContainer, {
	DesktopSideModal,
} from "../../../components/ModalContainer";
import ItemDescription from "../marketplace/ItemDescription";
import AddFundsToAssetSavingsFlow from "../../../components/modifySingleAssetSavings/AddFundsToAssetSavings";
import { walletType } from "../../../common/utils";
import { moveUserFunds } from "../../../redux/payments/paymentServices";
import { clearMoveFundsSuccess } from "../../../redux/payments/paymentActions";
import {
	clearUpdateAssetBasedSavingsSuccess,
	closeAddFundsToAssetSavingsModal,
	openAddFundsToAssetSavingsModal,
	openMoveFundsFromAssetSavingsModal,
} from "../../../redux/lease/leaseActions";
import Parse from "../../../api/ApiConfig";
import { logoutUser } from "../../../redux/authRedux/authServices";
import MoveFundsFromAssetSavings from "../../../components/modifySingleAssetSavings/MoveFundsFromAssetSavings";

const SingleAssetBasedSavings = () => {
	const dispatch = useDispatch();
	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const user = Parse.User.current();

	const {
		fetchSingleAssetSavingsLoading,
		fetchSingleAssetSavingsSuccess,
		toggleMarketItemsModal,
		updateAssetSavingsSuccess,
		toggleAddFundsToAssetSavings,
		toggleMoveFundsFromAssetSavingsModal,
	} = useSelector((state) => state.lease);

	const { customerWallet, moveFundsSuccess } = useSelector(
		(state) => state.payment
	);

	const navigate = useNavigate();
	const params = useParams();

	const isSavingsStarted =
		Number(fetchSingleAssetSavingsSuccess?.amountSaved) > 0;

	const [currentTab, setCurrentTab] = useState(1);
	const [amountToSave, setAmountToSave] = useState(
		isSavingsStarted ? "" : fetchSingleAssetSavingsSuccess?.amountToStartWith
	);
	const [activeMoveFundsModal, setActiveMoveFundsModal] = useState(false);
	const [amountToMove, setAmountToMove] = useState("");

	const onOpenAddFundsModal = () => {
		dispatch(openAddFundsToAssetSavingsModal());
		setCurrentTab(1);
	};

	const onCloseModal = () => {
		dispatch(closeAddFundsToAssetSavingsModal());
	};

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const onProceedToDeductWallet = () => {
		const amountPayable = Number(amountToSave?.replaceAll(",", ""));

		const details = {
			amount: amountPayable,
			source: "main",
			destination: walletType.assetBasedSavings,
			message: "funds-moved-to-asset-savings",
		};

		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		if (user.id) {
			dispatch(moveUserFunds(currentSessionToken, details));
		} else {
			dispatch(logoutUser());
		}
	};

	const clearUpdateSavingsSuccess = () => {
		dispatch(clearMoveFundsSuccess());
		onCloseModal();
		dispatch(clearUpdateAssetBasedSavingsSuccess());
	};

	const onOpenMoveFundsModal = () => {
		if (
			!fetchSingleAssetSavingsSuccess?.amountSaved ||
			Number(fetchSingleAssetSavingsSuccess?.amountSaved?.replaceAll(",", "")) <
				1
		) {
			toast.error("You do not have any funds available.");
			return;
		}
		dispatch(openMoveFundsFromAssetSavingsModal());
		setActiveMoveFundsModal(1);
	};

	useEffect(() => {
		dispatch(fetchSingleAssetBasedSavings(params.id));
	}, [params]);

	useEffect(() => {
		if (moveFundsSuccess === "funds-moved-to-asset-savings") {
			dispatch(clearMoveFundsSuccess());

			const oldAmount = fetchSingleAssetSavingsSuccess?.amountSaved
				? Number(
						fetchSingleAssetSavingsSuccess?.amountSaved?.replaceAll(",", "")
				  )
				: 0;
			const updatedAmount =
				oldAmount + Number(amountToSave?.replaceAll(",", ""));

			dispatch(
				updateSingleAssetSavings(
					params.id,
					{ amountSaved: updatedAmount?.toString() },
					{
						amount: amountToSave,
					}
				)
			);
		} else {
			return;
		}
	}, [moveFundsSuccess]);

	useEffect(() => {
		if (updateAssetSavingsSuccess) {
			setCurrentTab(4);
		} else {
			return;
		}
	}, [updateAssetSavingsSuccess]);

	console.log(fetchSingleAssetSavingsSuccess);

	return (
		<>
			{toggleMarketItemsModal && (
				<DesktopSideModal modal={toggleMarketItemsModal}>
					<ItemDescription />
				</DesktopSideModal>
			)}
			{toggleAddFundsToAssetSavings && (
				<ModalContainer modal={toggleAddFundsToAssetSavings}>
					<AddFundsToAssetSavingsFlow
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
						onCloseModal={onCloseModal}
						amountToSave={amountToSave}
						setAmountToSave={setAmountToSave}
						isSavingsStarted={isSavingsStarted}
						onDeductWallet={onProceedToDeductWallet}
						onDeductWalletSuccess={clearUpdateSavingsSuccess}
					/>
				</ModalContainer>
			)}
			{toggleMoveFundsFromAssetSavingsModal && (
				<ModalContainer modal={toggleMoveFundsFromAssetSavingsModal}>
					<MoveFundsFromAssetSavings
						activeMoveFundsModal={activeMoveFundsModal}
						setActiveMoveFundsModal={setActiveMoveFundsModal}
						onCloseModal={onCloseModal}
						amountToMove={amountToMove}
						setAmountToMove={setAmountToMove}
						amountSaved={fetchSingleAssetSavingsSuccess?.amountSaved}
					/>
				</ModalContainer>
			)}
			<MobileNavDetails text="savings" />
			<Helmet>
				<title>Fortcore - Asset Baed Savings</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="leasing" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<BackTracker
						text="asset based savings"
						onNavigate={() => navigate(-1)}
					/>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="asset based savings"
							onNavigate={() => navigate("/leasing/asset-based-savings/view")}
						/>
						<UrlTracker text={params?.id} isCurrentUrl />
					</div>
					<div className="sm:mt-6"></div>
					{fetchSingleAssetSavingsLoading ? (
						<div className="h-[350px] sm:h-[400px] grid place-items-center w-full">
							<Preloader />
						</div>
					) : fetchSingleAssetSavingsSuccess ? (
						<div className="space-y-5 sm:space-y-6 md:space-y-7 mb-8">
							<div className="-mt-4 flex justify-end">
								<button className="bg-primary-color text-white -mt-6 sm:mt-0 px-4 md:px-5 py-3 text-[13px] md:text-sm zabalMedFont rounded-md">
									Convert to Lease
								</button>
							</div>
							<AssetSavingsItem
								item={fetchSingleAssetSavingsSuccess}
								showAmount
								showMaturityDate
							/>
							<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-5">
								<SavingsButtonNavigation
									text={isSavingsStarted ? "add more funds" : "start saving"}
									onClick={onOpenAddFundsModal}
								/>
								<SavingsButtonNavigation
									text="move funds"
									onClick={onOpenMoveFundsModal}
								/>
								<SavingsButtonNavigation text="history" />
							</div>
						</div>
					) : (
						<SingleSavingsEmpty query={params?.id} />
					)}
				</>
			</Container>
		</>
	);
};

export default SingleAssetBasedSavings;
