import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
	Box,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Typography,
	Button,
	Tooltip,
	Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import BrowseInvestorForm from "../BrowseInvestorForm";
import { toast } from "react-toastify";
import userContext from "../../../../../context/UserContext";
import Parse from "../../../../../api/ApiConfig";
import Navbar from "../../../../../layout/Navbar";
import {
	getProfilePercentage,
	onBlurAmount,
	onInputAmount,
} from "../../../../../common/utils";
import { InfoIcon } from "../../../../../layout/assets";
import { Preloader } from "../../../../../components/globalStyled";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import { BiSolidLock } from "react-icons/bi";
import theme from "../../../../../components/mui/theme";
import { createNotification } from "../../../../../components/notification/CreateNotification";
import { businessTypes, durationFrame } from "../../../../../common/utils";
import {
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import ModalContainer, {
	DesktopSideModal,
} from "../../../../../components/ModalContainer";
import InvestorForm from "../../../../leasing/investor/InvestorForm";
import { useDispatch, useSelector } from "react-redux";
import {
	closeInvestorForm,
	openInvestorForm,
} from "../../../../../redux/lease/leaseActions";
import { clearUpdateAccountSuccess } from "../../../../../redux/authRedux/authActions";

const durationList = [];

for (let i = 1; i <= 24; i++) {
	durationList.push({
		value: i,
		label: i,
	});
}

function InvestorCreateAF() {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { toggleInvestorForm } = useSelector((state) => state.lease);

	const [toggleInvestorCreateFinance, setToggleInvestorCreateFinance] =
		useState(false);

	const { updatingUserAccountSuccess } = useSelector((state) => state.auth);

	const { investorCreateForm, setInvestorCreateForm, currentUserData, afRate } =
		useContext(userContext);

	// const [browseModal, setBrowseModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const duration =
		investorCreateForm.durationTime + " " + investorCreateForm.durationText;

	const waitingPeriod =
		investorCreateForm?.waitingTime + " " + investorCreateForm?.waitingText;

	// Function to handle form input changes
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setInvestorCreateForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	// check if investor profile is complete
	const investorProfileValidation = {
		phoneNumber: currentUserData?.phone,
		phoneNumberVerified: currentUserData?.phoneVerified,
		homeAddress: currentUserData?.homeAddress,
		bvn: currentUserData?.bvn,
		bankName: currentUserData?.bankName,
		accountNumber: currentUserData?.accountNumber,
		netWorth: currentUserData?.netWorth,
		investorSignature: currentUserData?.investorSignature,
		witnessSignature: currentUserData?.witnessSignature,
		idType: currentUserData?.idType,
		idNumber: currentUserData?.idNo,
	};

	const investorProfilePercentage = getProfilePercentage(
		investorProfileValidation
	);

	useEffect(() => {
		const calculateInterestAmount = () => {
			if (
				afRate?.interest?.value != null &&
				investorCreateForm.amountToInvest != null
			) {
				const interestRate = parseFloat(
					afRate.interest.value.replace(/%/g, "")
				);
				const amount = parseFloat(
					investorCreateForm.amountToInvest.replace(/,/g, "")
				);

				if (!isNaN(interestRate) && !isNaN(amount)) {
					const calculatedInterestRate = (amount * interestRate) / 100;

					const formattedInterestRate = calculatedInterestRate.toLocaleString(
						undefined,
						{
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}
					);

					setInvestorCreateForm((prevState) => ({
						...prevState,
						interest: formattedInterestRate,
					}));
				}
			}
		};

		calculateInterestAmount();
	}, [investorCreateForm.amountToInvest, afRate.interest?.value]);

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const saveData = async () => {
		setLoading(true);
		try {
			const currentUser = Parse.User.current();
			if (currentUser) {
				const Business = Parse.Object.extend("AFBusinessCreate");
				const business = new Business();

				const investorData = {
					name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
					email: currentUserData?.email,
					phone: currentUserData?.phone,
					address: currentUserData?.homeAddress,
					userId: user,
					amountPaid: investorCreateForm?.amountToInvest,
				};

				business.set("user", currentUser.toPointer());
				business.set("amountToInvest", investorCreateForm.amountToInvest);
				business.set("duration", duration);
				business.set("waitingPeriod", waitingPeriod);
				business.set("amountToRepay", investorCreateForm.amountToRepay);
				business.set("interest", investorCreateForm.interest);
				business.set("businessType", investorCreateForm.businessType);
				business.set("status", "inactive");
				business.set("type", "sponsored");
				business.set("requestType", "investor-creates");
				business.set("interestRate", afRate?.interest?.value);
				business.set("investorInfo", investorData);
				business.set("apprentice", []);
				// business.set("approvalStatus", "pending");

				business.save().then((result) => {
					const id = result.id;

					const message = "Apprenticeship Finance Created";
					const metaData = {
						category: "AF",
						linkToId: id,
						moreDetails: `You created a new Apprenticeship Finance investment opportunity. Investment amount of NGN ${investorCreateForm.amountToInvest.toLocaleString()}`,
					};

					createNotification(message, metaData);

					setInvestorCreateForm({
						interest: "",
						amountToInvest: "",
						amountToRepay: "",
						durationTime: "",
						durationText: "",
						waitingTime: "",
						waitingText: "",
						businessType: "",
					});
					// eslint-disable-next-line no-undef
					console.log("RESULT", id);

					toast.success("You have created a new finance request.");

					setTimeout(() => {
						navigate("/financing/investment-portfolio");
					}, 1000);

					// setBrowseModal(false);
					setLoading(false);
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
			toast.error(error.message);
			setLoading(false);
		}
	};

	const onOpenCreateFinanceModal = () => {
		dispatch(closeInvestorForm());
		dispatch(clearUpdateAccountSuccess());

		setToggleInvestorCreateFinance(true);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			investorCreateForm.amountToInvest &&
			investorCreateForm.durationText &&
			investorCreateForm.durationTime &&
			investorCreateForm.amountToRepay &&
			investorCreateForm.waitingText &&
			investorCreateForm.waitingTime &&
			investorCreateForm.interest &&
			investorCreateForm.businessType
		) {
			if (investorProfilePercentage > 45) {
				setToggleInvestorCreateFinance(true);
			} else {
				dispatch(openInvestorForm());
			}
		} else {
			toast.error("All Fields are Required");
		}
	};
	// eslint-disable-next-line no-undef
	console.log(investorCreateForm);
	return (
		<>
			{toggleInvestorCreateFinance && (
				<ModalContainer modal={toggleInvestorCreateFinance}>
					<CustomConfirmModal
						text="You are about to create a finanace for request. Do you wish to proceed?"
						greenBtnText="continue"
						isLoading={loading}
						greenBtnClick={saveData}
						onCloseBtnClick={() => setToggleInvestorCreateFinance(false)}
					/>
				</ModalContainer>
			)}
			{toggleInvestorForm && (
				<DesktopSideModal modal={toggleInvestorForm}>
					{updatingUserAccountSuccess ? (
						<div className="w-full grid place-items-center">
							<SuccessCheckModal
								text="Form details saved successfully"
								onRequestClick={onOpenCreateFinanceModal}
								isShowBtn={true}
								btnText="proceed"
							/>
						</div>
					) : (
						<InvestorForm
							onCloseForm={() => dispatch(closeInvestorForm())}
							onNoInvestorFormSubmit={onOpenCreateFinanceModal}
						/>
					)}
				</DesktopSideModal>
			)}

			<MobileNavDetails text="Apprentice Financing" />
			<Container>
				<Helmet>
					<title>Fortcore - New Transaction</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="hidden sm:flex gap-1.5 sm:mt-10">
					<UrlTracker
						text="apprenticeship finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="new transaction"
						onNavigate={() => navigate("/financing/new-transaction")}
					/>
					<UrlTracker text="create finance" isCurrentUrl />
				</div>
				<div className="sm:hidden">
					<BackTracker text="create finance" onNavigate={() => navigate(-1)} />
				</div>
				<div className="sm:mt-8"></div>
				<Box
					sx={{ background: "#F6F7F8", padding: "20px", borderRadius: "8px" }}
				>
					<Typography variant="h4" color="primary.main">
						Create Apprenticeship Finance
					</Typography>

					<Box component="form" onSubmit={handleSubmit} mt={5}>
						<Stack spacing={4}>
							{/* Amount To Invest */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Amount To Invest :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="text"
										name="amountToInvest"
										value={investorCreateForm.amountToInvest}
										onChange={handleInputChange}
										inputMode="numeric"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography sx={{ fontFamily: "ZabalMedium" }}>
														NGN
													</Typography>
												</InputAdornment>
											),
										}}
										sx={{ width: "100%" }}
										required
										onInput={onInputAmount}
										onBlur={(e) =>
											onBlurAmount(e, investorCreateForm.amountToInvest)
										}
									/>
								</div>
							</div>

							{/* Waiting Period */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									<Stack direction="row" alignItems="center">
										Waiting Period : &nbsp;{" "}
										<Tooltip title="Text" placement="top">
											<InfoIcon />
										</Tooltip>
									</Stack>
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<Stack direction="row" alignItems="center" spacing={2}>
										<TextField
											select
											name="waitingText"
											value={investorCreateForm.waitingText}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
											required
										>
											{durationFrame.map((option, index) => (
												<MenuItem
													key={index}
													value={option.value}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>

										<TextField
											select
											name="waitingTime"
											value={investorCreateForm.waitingTime}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
											required
										>
											{durationList.map((option, index) => (
												<MenuItem
													key={index}
													value={option.value}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Stack>
								</div>
							</div>

							{/* Amount To Repay */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									<Stack direction="row" alignItems="center">
										Amount To Repay : &nbsp;{" "}
										<Tooltip title="Text" placement="top">
											<InfoIcon />
										</Tooltip>
									</Stack>
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="text"
										name="amountToRepay"
										value={investorCreateForm.amountToRepay}
										onChange={handleInputChange}
										inputMode="numeric"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography sx={{ fontFamily: "ZabalMedium" }}>
														NGN
													</Typography>
												</InputAdornment>
											),
										}}
										sx={{ width: "100%" }}
										required
										onInput={onInputAmount}
										onBlur={(e) =>
											onBlurAmount(e, investorCreateForm.amountToRepay)
										}
									/>
								</div>
							</div>

							{/* Finance Duartion */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									<Stack direction="row" alignItems="center">
										Finance Duration : &nbsp;{" "}
										<Tooltip title="Text" placement="top">
											<InfoIcon />
										</Tooltip>
									</Stack>
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<Stack direction="row" alignItems="center" spacing={2}>
										<TextField
											select
											name="durationText"
											value={investorCreateForm.durationText}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
											required
										>
											{durationFrame.map((option, index) => (
												<MenuItem
													key={index}
													value={option.value}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>

										<TextField
											select
											name="durationTime"
											value={investorCreateForm.durationTime}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
											required
										>
											{durationList.map((option, index) => (
												<MenuItem
													key={index}
													value={option.value}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Stack>
								</div>
							</div>

							{/* Interest */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Interest :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										type="text"
										name="interest"
										value={investorCreateForm.interest || ""}
										// onChange={handleInputChange}
										// inputMode="numeric"
										sx={{ width: "100%" }}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<Typography sx={{ fontFamily: "ZabalMedium" }}>
														NGN
													</Typography>
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="start">
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
													>
														<Typography
															color="primary"
															sx={{ fontFamily: "ZabalMedium" }}
														>
															({afRate.interest?.value}P/A)
														</Typography>
														<BiSolidLock
															size={15}
															color={theme.palette.primary.main}
														/>
													</Stack>
												</InputAdornment>
											),
										}}
									/>
								</div>
							</div>

							{/* Business Type */}
							<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
								<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
									Business Type :
								</p>
								<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
									<TextField
										select
										name="businessType"
										placeholder="Id type"
										value={investorCreateForm.businessType}
										onChange={handleInputChange}
										sx={{ width: "100%", textTransform: "capitalize" }}
										required
									>
										{businessTypes.map((option, index) => (
											<MenuItem
												key={index}
												value={option.value}
												sx={{ textTransform: "capitalize" }}
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>

							<Box sx={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={loading}
								>
									{loading ? <Preloader /> : "Proceed"}
								</Button>
							</Box>
						</Stack>
					</Box>
				</Box>
			</Container>

			{/* {browseModal && (
				<BrowseInvestorForm
					open={browseModal}
					handleClose={() => setBrowseModal(false)}
					showMaybe="false"
					onClick={() => {
						saveData();
					}}
				/>
			)} */}
		</>
	);
}

// InvestorCreateAF.propTypes = {
//   open: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
// };
export default InvestorCreateAF;

export function AnyModal({ children, open }) {
	return (
		<>
			<Modal open={open}>
				<Box sx={style}>
					<Box>{children}</Box>
				</Box>
			</Modal>
		</>
	);
}
export function AnyModal2({ children, open }) {
	return (
		<>
			<Modal open={open}>
				<Box sx={style2}>
					<Box>{children}</Box>
				</Box>
			</Modal>
		</>
	);
}

AnyModal.propTypes = {
	open: PropTypes.bool,
	children: PropTypes.any,
};
AnyModal2.propTypes = {
	open: PropTypes.bool,
	children: PropTypes.any,
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "8px",
	background: "#fff",
	width: { xs: "90%", sm: "100%" },
	maxWidth: 450,
	maxHeight: { xs: "85vh", sm: "80vh" },
	p: { xs: 4, sm: 2 },
	boxShadow: 24,
	overflowY: "auto",
	scrollbarWidth: "none",
	"&::-webkit-scrollbar": {
		display: "none",
	},
};
const style2 = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "8px",
	background: "#fff",
	width: { xs: "90%", sm: "100%" },
	maxWidth: 580,
	maxHeight: { xs: "85vh", sm: "80vh" },
	p: { xs: 4, sm: 2 },
	boxShadow: 24,
	overflowY: "auto",
	scrollbarWidth: "none",
	"&::-webkit-scrollbar": {
		display: "none",
	},
};
