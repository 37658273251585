import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdFilterAlt, MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import {
	closeBusinessReqLeaseModal,
	closeDeleteLeaseModal,
	closeOngoingTransactionModal,
	closePersonalReqLeaseModal,
	openDeleteLeaseModal,
} from "../../../redux/lease/leaseActions";
import {
	deleteSelectedOngoingLease,
	fetchOngoingLease,
} from "../../../redux/lease/leaseServices";
import { OngoingLeaseSkeleton } from "../requestlease/LeaseItems";
import EmptyData from "../../../components/EmptyData";
import { OngoingLeaseItem } from "../requestlease/LeaseTransaction";
import {
	clearEmailVerrificationOnSuccess,
	clearStatesOnConfirmLease,
} from "../../../redux/authRedux/authActions";
import ModalContainer from "../../../components/ModalContainer";

const OngoingLeaseTransactionsView = () => {
	const { currentUser } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		ongoingLeaseTransactionsLoading,
		ongoingLeaseTransactionsSuccess,
		toggleDeleteOngoingLeaseModal,
		ongoingLeaseToDelete,
		ongoingLeaseDeleteLoading,
		ongoingLeaseDeleteSuccess,
	} = useSelector((state) => state.lease);

	useEffect(() => {
		dispatch(fetchOngoingLease(currentUser?.objectId));
		dispatch(closeOngoingTransactionModal());
		dispatch(clearStatesOnConfirmLease());
		dispatch(clearEmailVerrificationOnSuccess());
		dispatch(closeBusinessReqLeaseModal());
		dispatch(closePersonalReqLeaseModal());
	}, [currentUser?.objectId]);

	const [selectedTransaction, setSelectedTransaction] = useState([]);

	const handleLeaseSelect = (e) => {
		const { id, checked } = e.target;
		setSelectedTransaction([...selectedTransaction, id]);
		if (!checked) {
			setSelectedTransaction(selectedTransaction.filter((item) => item !== id));
		}
	};

	const sortedOngoingTransactions = ongoingLeaseTransactionsSuccess?.sort(
		(leaseOne, leaseTwo) =>
			new Date(leaseTwo.get("createdAt")).getTime() -
			new Date(leaseOne.get("createdAt")).getTime()
	);

	return (
		<>
			{toggleDeleteOngoingLeaseModal && (
				<ModalContainer modal={toggleDeleteOngoingLeaseModal}>
					{!ongoingLeaseDeleteSuccess ? (
						<CustomConfirmModal
							text={`Are you sure you want to delete ${
								ongoingLeaseToDelete?.length > 1 ? "these" : "this"
							}  ${
								ongoingLeaseToDelete?.length > 1
									? "transactions"
									: "transaction"
							} ?`}
							onCloseBtnClick={() => dispatch(closeDeleteLeaseModal())}
							greenBtnText="Yes, Delete"
							greenBtnClick={() =>
								dispatch(deleteSelectedOngoingLease(ongoingLeaseToDelete))
							}
							isLoading={ongoingLeaseDeleteLoading}
							isNotify
						/>
					) : (
						<SuccessCheckModal
							text={`${ongoingLeaseToDelete?.length} ${
								ongoingLeaseToDelete?.length > 1
									? "transactions"
									: "transaction"
							} has been deleted successfully.`}
							onRequestClick={() => dispatch(closeDeleteLeaseModal())}
						/>
					)}
				</ModalContainer>
			)}
			<MobileNavDetails text="ongoing transactions" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Ongoing Transactions</title>
					</Helmet>
					<Navbar text="ongoing transactions" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker text="ongoing transactions" isCurrentUrl />
					</div>
					<BackTracker text="leasing" onNavigate={() => navigate("/leasing")} />
					<div className="bg-[#F6F7F8] rounded-lg my-5 md:my-7 p-4 sm:p-5">
						<div className="flex justify-between items-center">
							<p className="text-[13.5px] sm:text-sm md:text-[14.5px] md:text-fortcore-green zabalBoldFont">
								Transaction Status
							</p>
							{!ongoingLeaseTransactionsLoading &&
								ongoingLeaseTransactionsSuccess?.length > 0 && (
									<div className="hidden sm:block">
										{selectedTransaction.length > 0 ? (
											<button
												className="text-red-600 pr-1"
												onClick={() =>
													dispatch(openDeleteLeaseModal(selectedTransaction))
												}
											>
												<MdDelete size={20} />
											</button>
										) : (
											<div className="flex gap-2 items-center">
												<button>
													<MdSearch color="#8E9DA4" size={20} />
												</button>
												<button>
													<MdFilterAlt color="#8E9DA4" size={20} />
												</button>
											</div>
										)}
									</div>
								)}
						</div>
						<div className="grid gap-4 md:gap-5 mt-5 mb-2">
							{ongoingLeaseTransactionsLoading ? (
								Array(3)
									.fill(0)
									.map((_, i) => <OngoingLeaseSkeleton key={i} />)
							) : ongoingLeaseTransactionsSuccess?.length > 0 ? (
								sortedOngoingTransactions.map((item) => {
									return (
										<OngoingLeaseItem
											key={item?.id}
											leaseItem={item}
											handleLeaseSelect={handleLeaseSelect}
											isChecked={selectedTransaction.includes(item?.id)}
										/>
									);
								})
							) : (
								<div className="w-full h-[370px] md:h-[370px]">
									<EmptyData text="You do not have any ongoing transactions." />
								</div>
							)}
						</div>
					</div>
				</>
			</Container>
		</>
	);
};
export default OngoingLeaseTransactionsView;
