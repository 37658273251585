/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  // List,
  // ListItem,
  // ListItemAvatar,
  // ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import Data from "../../data/AudioData";
import { AiFillPlayCircle } from "react-icons/ai";
// import { useTheme } from "@mui/material/styles";
// import { BsFillClockFill } from "react-icons/bs";
import { EducationContext } from "../../Context/EducationContext";
import theme from "../../../../components/mui/theme";
import { BsFillClockFill } from "react-icons/bs";

function AudioPage() {
  const audioRef = useRef(null);
  const { id } = useParams();
  const [chapter, setChapter] = useState("");
  const [description, setDescription] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [audioCoverPhoto, setAudioCoverPhoto] = useState("");
  const [subText, setSubText] = useState("");
  const { audioTab } = useContext(EducationContext);

  const data = audioTab.find((audio) => audio.objectId === id);

  useEffect(() => {
    if (data && data.chapters.length > 0) {
      if (!chapter) {
        const firstChapter = data.chapters[0];
        setChapter(firstChapter.chapter);
        setAudioUrl(firstChapter.link);
        setDescription(firstChapter.description);
        setAudioCoverPhoto(firstChapter.coverPhoto);
      } else {
        const selectedChapter = data.chapters.find(
          (ch) => ch.chapter === chapter
        );
        if (selectedChapter) {
          setAudioUrl(selectedChapter.link);
          setDescription(selectedChapter.description);
          setChapter(selectedChapter.chapter);
          setAudioCoverPhoto(selectedChapter.coverPhoto);
        }
      }
    } else if (data && data.chapters.length === 0) {
      setAudioUrl(data.link);
      setDescription(data.description);
      setAudioCoverPhoto(data.coverPhoto);
    }
  }, [data, chapter]);

  if (!data) {
    return <CircularProgress />;
  }

  // eslint-disable-next-line no-undef
  console.log("filteredData: ", data);
  return (
    <>
      <Box>
        <Box>
          <Typography
            color="primary"
            variant="h3"
            sx={{ textTransform: "capitalize" }}
          >
            {data.name}
          </Typography>
          <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

          {/* Image  */}
          <Box>
            <Card
              sx={{
                width: "100%",
                height: "100%",
                padding: 0,
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <CardMedia
                sx={{
                  height: "200px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  objectFit: "cover",
                }}
                image={audioCoverPhoto}
              ></CardMedia>
              <CardContent
                sx={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h1"
                  color="primary.light"
                  sx={{ textTransform: "capitalize" }}
                >
                  Chapter {chapter}
                </Typography>
                {/* <Typography variant="h5" color="primary.light">
                  {description.slice(0, 40)}...
                </Typography> */}
              </CardContent>
            </Card>
          </Box>

          {/* Content  */}

          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ marginTop: "30px" }}
          >
            {/* Description  */}
            <Box width={{ xs: "100%", md: "50%" }}>
              <Stack spacing={1}>
                <Typography variant="h3" color="primary">
                  Description
                </Typography>
                <Typography variant="body1" color="secondary.light" paragraph>
                  {description}
                </Typography>
              </Stack>
            </Box>

            {/* Chapter  */}
            <Box
              width={{ xs: "100%", md: "50%" }}
              sx={{
                border: "1px solid #E6E9EB",
                padding: "10px",
                borderRadius: "8px",
                cursor: "pointer",
                textTransform: "capitalize",
              }}
            >
              <Stack spacing={1}>
                <Typography variant="h3" color="primary">
                  Chapter
                </Typography>
                <Divider
                  sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }}
                />
                {data.chapters.length > 0 ? (
                  <>
                    {data.chapters.map((data, index) => (
                      <Box
                        backgroundColor={
                          chapter === data.chapter && theme.palette.primary.main
                        }
                        sx={{
                          borderBottom: "1px solid #EEF0F1",
                          borderRadius: "8px",
                        }}
                        key={index}
                        onClick={() => {
                          setChapter(data.chapter);
                          setDescription(data.description);
                          setAudioUrl(data.link);
                          setSubText(data.subText);
                          setAudioCoverPhoto(data.coverPhoto);
                          audioRef.current.pause();
                          audioRef.current.load();
                          // eslint-disable-next-line no-undef
                          window.scrollTo(80, 80);
                        }}
                      >
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <AiFillPlayCircle
                                color={
                                  chapter === data.chapter
                                    ? theme.palette.primary.light
                                    : theme.palette.primary.main
                                }
                                style={{ fontSize: "30px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primaryTypographyProps={{
                                color:
                                  chapter === data.chapter
                                    ? theme.palette.primary.light
                                    : theme.palette.primary.main,
                              }}
                              secondaryTypographyProps={{
                                color:
                                  chapter === data.chapter
                                    ? theme.palette.info.dark
                                    : theme.palette.secondary.light,
                              }}
                              primary={
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    color:
                                      chapter === data.chapter
                                        ? theme.palette.primary.light
                                        : theme.palette.primary.main,
                                  }}
                                >
                                  {data.name}
                                </Typography>
                              }
                              secondary={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: 1,
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    color={
                                      chapter === data.chapter
                                        ? theme.palette.primary.light
                                        : "#0000007a"
                                    }
                                  >
                                    Chapter {data.chapter}
                                  </Typography>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <BsFillClockFill
                                      color={
                                        chapter === data.chapter
                                          ? theme.palette.primary.light
                                          : "#0000007a"
                                      }
                                      style={{
                                        display: { xs: "none", sm: "flex" },
                                      }}
                                    />
                                    <Typography
                                      variant="caption"
                                      color={
                                        chapter === data.chapter
                                          ? theme.palette.primary.light
                                          : "#0000007a"
                                      }
                                    >
                                      &nbsp; {data.duration} Long
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        </List>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box
                    backgroundColor={theme.palette.primary.main}
                    sx={{
                      borderBottom: "1px solid #EEF0F1",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      setDescription(data.description);
                      setAudioUrl(data.link);
                      audioRef.current.pause();
                      audioRef.current.load();
                      // eslint-disable-next-line no-undef
                      window.scrollTo(80, 80);
                    }}
                  >
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <AiFillPlayCircle
                            color={theme.palette.primary.light}
                            style={{
                              fontSize: "30px",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={{
                            color: theme.palette.primary.light,
                          }}
                          secondaryTypographyProps={{
                            color: theme.palette.info.main,
                          }}
                          primary={
                            <Typography
                              sx={{ textTransform: "capitalize" }}
                              color="primary.light"
                            >
                              {data.name}
                            </Typography>
                          }
                          // secondary={data.description}
                          secondary={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 1,
                                flexWrap: "wrap",
                              }}
                            >
                              <Typography variant="caption">
                                {data.description}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <BsFillClockFill
                                  color={theme.palette.primary.light}
                                  style={{
                                    display: { xs: "none", sm: "flex" },
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  color={theme.palette.primary.light}
                                >
                                  &nbsp; {data.totalDuration} Long
                                </Typography>
                              </Box>
                            </Box>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>

      {/* Audio */}
      <Box
        sx={{
          background: "transparent",
          margin: "-20px",
          borderRadius: "0 0 40px 40px",
          marginTop: "50px",
        }}
      >
        <audio
          ref={audioRef}
          controls
          style={{
            width: "100%",

            background: "transparent",
          }}
        >
          <source src={audioUrl} type="audio/mpeg" />
        </audio>
      </Box>
    </>
  );
}

export default AudioPage;
