import React, { useContext, useEffect, useState } from "react";
import Container from "../../../layout/Container";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import { Helmet } from "react-helmet";
import Navbar from "../../../layout/Navbar";
import {
	Box,
	Button,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import BoxComponent from "./component/BoxComponent";
import Parse from "../../../api/ApiConfig";
import userContext from "../../../context/UserContext";
import { toast } from "react-toastify";
import { NoDataIcon } from "../../../layout/assets";
import theme from "../../../components/mui/theme";
import { BiSolidTrashAlt } from "react-icons/bi";
import { AnyModal } from "../../finance/newTransaction/investor/create/InvestorCreateAF";
import { MdClose } from "react-icons/md";

const breadcrumbItems = [
	{ label: "Application Form", href: "/travels" },
	{ label: "Submitted Applications" },
];

function SubmittedApplication() {
	// eslint-disable-next-line no-unused-vars
	const { currentUserData } = useContext(userContext);
	const [fetching, setFetching] = useState(true);
	const [submitted, setSubmitted] = useState([]);
	const [dataToDelete, setDataToDelete] = useState([]);
	const [popDelete, setPopDelete] = useState(false);

	useEffect(() => {
		async function fetchSubmittedTravel() {
			try {
				const currentUser = Parse.User.current();

				if (currentUser) {
					// const userData = currentUser.toJSON();
					const query = new Parse.Query("TravelAdvance");
					query.equalTo("userId", currentUser);
					query.include("personal");
					query.include("detailsOfRequest");
					query.include("travel");
					query.descending("updatedAt");
					const results = await query.find();
					const applications = results.map((result) => result.toJSON());

					// eslint-disable-next-line no-undef
					console.log("Travel:", applications);
					setSubmitted(applications);
					setFetching(false);
				}
			} catch (error) {
				// eslint-disable-next-line no-undef
				console.log(error);
				toast.error(error.message);
			}
		}

		fetchSubmittedTravel();
	}, []);

	const fetchSubmittedTravel = async () => {
		try {
			const currentUser = Parse.User.current();

			if (currentUser) {
				// const userData = currentUser.toJSON();
				const query = new Parse.Query("TravelAdvance");
				query.equalTo("userId", currentUser);
				query.include("personal");
				query.include("detailsOfRequest");
				query.include("travel");
				query.descending("updatedAt");
				const results = await query.find();
				const applications = results.map((result) => result.toJSON());

				// eslint-disable-next-line no-undef
				console.log("Travel:", applications);
				setSubmitted(applications);
			}
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
			toast.error(error.message);
		}
	};

	const handleDeleteRequest = async () => {
		try {
			if (dataToDelete.length < 1) {
				toast.warning("No items selected for deletion.");
				return;
			}

			const currentUser = Parse.User.current();

			if (currentUser) {
				const TravelAdvance = Parse.Object.extend("TravelAdvance");
				const query = new Parse.Query(TravelAdvance);
				query.containedIn("objectId", dataToDelete);

				const results = await query.find();
				await Parse.Object.destroyAll(results);

				toast.success("Deleted successfully.");
				setDataToDelete([]);
				fetchSubmittedTravel();
				setPopDelete(false);
			}
		} catch (error) {
			toast.error(error.message);
			// eslint-disable-next-line no-undef
			console.error(error);
		}
	};

	// eslint-disable-next-line no-undef
	console.error("dataToDelete: ", dataToDelete);
	return (
		<>
			<MobileNavDetails text="new application" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Transactions</title>
					</Helmet>
					<Navbar text="submitted transactions" />
					<div className="mt-1 sm:mt-[55px]"></div>

					<Box>
						<Breadcrumb items={breadcrumbItems} />
					</Box>
				</>

				<Box
					sx={{
						background: "#F6F7F8",
						marginTop: "50px",
						borderRadius: "8px",
						width: "100%",
						overflowX: "auto",
						padding: "20px",
					}}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Typography
							variant="h3"
							sx={{ fontFamily: "Chillax" }}
							color="primary">
							Transaction Status
						</Typography>

						<Stack direction="row" alignItems="center">
							<IconButton>
								<FiSearch />
							</IconButton>
							<IconButton>
								<FaFilter size={20} />
							</IconButton>
						</Stack>
					</Stack>

					<Box mt={5}>
						<Stack
							mb={3}
							direction="row"
							justifyContent="flex-end"
							alignItems="center">
							{dataToDelete.length > 0 && (
								<IconButton onClick={() => setPopDelete(true)}>
									<BiSolidTrashAlt color="#f00" />
								</IconButton>
							)}
						</Stack>
						<Stack spacing={3}>
							{fetching ? (
								<Stack spacing={2}>
									<Skeleton variant="rounded" width="100%" height={241} />
									<Skeleton variant="rounded" width="100%" height={241} />
									<Skeleton variant="rounded" width="100%" height={241} />
								</Stack>
							) : (
								<>
									{submitted.length > 0 ? (
										<>
											{submitted.map((data, index) => {
												const detailsOfRequest = data.detailsOfRequest;
												const travel = data.travel;

												return (
													<BoxComponent
														key={index}
														amount={detailsOfRequest.amount}
														contribution={detailsOfRequest.equityContribution}
														tenure={travel.lengthOfStay}
														interest={detailsOfRequest.monthlyInterest}
														transactionStatus={data.transactionStatus}
														// status="authorize direct debit"
														status=""
														data={data}
														setSubmitted={setSubmitted}
														setDataToDelete={setDataToDelete}
													/>
												);
											})}
										</>
									) : (
										<Box
											height="50vh"
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<NoDataIcon />
											<Typography mt={2} color={theme.palette.secondary.dark}>
												You have no investments
											</Typography>
										</Box>
									)}
								</>
							)}
						</Stack>
					</Box>
				</Box>
			</Container>

			<AnyModal open={popDelete}>
				<Stack direction="row" justifyContent="flex-end">
					<IconButton onClick={() => setPopDelete(false)}>
						<MdClose color="#000" />
					</IconButton>
				</Stack>

				<Stack mt={1} spacing={2}>
					<Typography align="center">
						You&apos;ve selected{" "}
						<Typography component="span" sx={{ fontFamily: "ZabalMedium" }}>
							{dataToDelete.length}
						</Typography>{" "}
						{dataToDelete.length > 1
							? "Travel Advance submitted applications to delete"
							: "Travel Advance submitted application to delete"}
					</Typography>
					<Button
						variant="contained"
						color="error"
						sx={{ "&:hover": { background: "#D32F2E" } }}
						onClick={handleDeleteRequest}>
						Confirm Delete
					</Button>
				</Stack>
			</AnyModal>
		</>
	);
}

export default SubmittedApplication;
