import React, { useMemo, useState } from "react";
import {
	FatBackTracker,
	PlainSelect,
	SelectDropDown,
} from "../../layout/CustomComponents";
import PropTypes from "prop-types";
import { MdLock } from "react-icons/md";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import { useSelector } from "react-redux";
import {
	formatAmountToIncludeCurrency,
	getSingularOrPlural,
	onInputAmount,
} from "../../common/utils";
import { expectedDuration } from "../../screens/leasing/requestlease/LeaseStructure";

const CardDetails = ({ setActiveTab, amountPayable }) => {
	const { payment, selectedReqLeaseItem } = useSelector((state) => state.lease);

	const getMinExpiryDate = () => {
		const year = new Date().getFullYear();
		const month = new Date().getMonth() + 1;
		const newMonth = month < 10 ? `0${month}` : month;
		return `${year}-${newMonth}`;
	};

	const [toggleWaitingPeriod, setToggleWaitingPeriod] = useState(false);
	const [toggleWaitingLength, setToggleWaitingLength] = useState(false);
	const [cardDetails, setCardDetails] = useState({
		name_on_card: "",
		card_number: "",
		cvv_number: "",
		amount_payable: amountPayable || "",
		expiry_date: "",
		waiting_period: "",
		waiting_length: "",
	});

	const {
		name_on_card,
		card_number,
		expiry_date,
		cvv_number,
		amount_payable,
		waiting_period,
		waiting_length,
	} = cardDetails;

	const handleChange = (e) =>
		setCardDetails((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const handleCreditCardChange = (e) => {
		// Remove any non-numeric characters (including alphabets)
		let numericInput = e.target.value.replace(/[^0-9]/g, "");

		// Split the numericInput into groups of four digits
		let formattedNumber = numericInput.match(/.{1,4}/g);

		// Join the groups with dashes to format the number
		formattedNumber = formattedNumber ? formattedNumber.join("-") : "";

		// Update the card number in your state
		setCardDetails((prev) => ({ ...prev, card_number: formattedNumber }));
	};

	const isAmountGreater = useMemo(() => {
		if (
			Number(amount_payable.replaceAll(",", "")) >
			Number(selectedReqLeaseItem?.productObj.amount)
		) {
			return true;
		} else {
			return false;
		}
	}, [amount_payable]);

	const isProceedBtnValid = () => {
		if (
			name_on_card === "" ||
			card_number.length !== 19 ||
			expiry_date === "" ||
			amount_payable === "" ||
			isNaN(cvv_number) ||
			cvv_number.length !== 3 ||
			isAmountGreater ||
			isNaN(amount_payable.replaceAll(",", ""))
		) {
			return true;
		}
		if (payment?.mode === "partial_investment" && waiting_period === "") {
			return true;
		} else {
			return false;
		}
	};

	const data = {
		nameOnCard: name_on_card,
		cvv: cvv_number,
		cardNumber: card_number.split("-").join(""),
		amount: amount_payable,
		expiry_date: expiry_date,
		waitingPeriod: `${waiting_length} ${getSingularOrPlural(
			waiting_length,
			waiting_period
		)}`,
	};

	const onProceedBtnClick = () => {
		if (payment?.mode == "full_investment") {
			delete data.waitingPeriod;
		}
		console.log(data);
	};

	return (
		<div className="space-y-4 sm:space-y-5 mb-6 sm:my-5 md:my-6">
			<div className="space-y-1.5">
				<FatBackTracker
					text="card details"
					onNavigate={() => setActiveTab(1)}
				/>
				<p className="text-xs md:text-[12.25px] text-[#717E83] zabalRegFont ">
					Please provide your card details in the fields below.
				</p>
			</div>

			<div className="relative text-sm rounded-md bg-[#F6F7F8] p-4 sm:p-5 pb-6 sm:pb-7 text-[#393F42] zabalRegFont text-justify leading-6 space-y-4 sm:space-y-5">
				<div className="flex flex-col gap-2 md:gap-2.5">
					<label
						htmlFor="name_on_card"
						className=" text-xs sm:text-[12.5px] text-[#717E83] zabalMedFont"
					>
						Name On Card
					</label>
					<input
						type="text"
						className="text-sm h-[45px] w-full px-2.5 rounded border border-[#717E83] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
						id="name_on_card"
						placeholder="John Doe"
						value={name_on_card}
						onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col gap-2 md:gap-2.5">
					<label
						htmlFor="card_number"
						className=" text-xs sm:text-[12.5px] text-[#717E83] zabalMedFont"
					>
						Card No
					</label>
					<input
						type="text"
						className="text-sm h-[45px] w-full px-2.5 rounded border border-[#717E83] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
						placeholder="0000-0000-0000-0000"
						id="card_number"
						inputMode="numeric"
						value={card_number}
						onChange={handleCreditCardChange}
						maxLength={19}
					/>
				</div>
				<div className="grid md:grid-cols-2 gap-x-6 gap-y-4 sm:gap-y-5">
					<div className="flex flex-col gap-2 md:gap-2.5">
						<label
							htmlFor="expiry_date"
							className=" text-xs sm:text-[12.5px] text-[#717E83] zabalMedFont"
						>
							Expiry Date
						</label>
						<input
							type="month"
							className="text-sm h-[45px] w-full px-2.5 rounded border border-[#717E83] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
							placeholder="YYYY-MM"
							id="expiry_date"
							value={expiry_date}
							onChange={handleChange}
							min={getMinExpiryDate()}
						/>
					</div>
					<div className="flex flex-col gap-2 md:gap-2.5">
						<label
							htmlFor="cvv_number"
							className=" text-xs sm:text-[12.5px] text-[#717E83] zabalMedFont"
						>
							CVV No
						</label>
						<input
							type="text"
							className="text-sm h-[45px] w-full px-2.5 rounded border border-[#717E83] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
							placeholder="000"
							id="cvv_number"
							inputMode="numeric"
							value={cvv_number}
							onChange={(e) => {
								if (isNaN(e.target.value)) return;
								handleChange(e);
							}}
							maxLength={3}
						/>
					</div>
				</div>
				<div className="flex flex-col gap-2 md:gap-2.5">
					<label
						htmlFor="amount_payable"
						className="text-xs sm:text-[12.5px] text-[#717E83] zabalMedFont"
					>
						Amount
					</label>
					<div className="h-[45px] relative text-[#393F42]">
						<span className="absolute zabalMedFont left-3 h-full w-[30px] grid place-items-center">
							NGN
						</span>
						<input
							type="text"
							className="text-sm h-full w-full px-[50px] rounded border border-[#717E83] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
							placeholder="100,000"
							id="amount_payable"
							inputMode="numeric"
							value={amount_payable}
							onChange={handleChange}
							disabled={payment?.mode === "full_investment"}
							onInput={onInputAmount}
						/>
						{payment?.mode === "full_investment" && (
							<span className="absolute right-2 h-full w-[30px] grid place-items-center top-0">
								<MdLock size={18} color="#717E83" />
							</span>
						)}
						{isAmountGreater && (
							<p className="text-[11px] zabalRedFont text-red-500">
								Amount cannot be greater than{" "}
								{formatAmountToIncludeCurrency(
									"NGN",
									selectedReqLeaseItem?.productObj.amount
								)}
							</p>
						)}
					</div>
				</div>
				{payment?.mode === "partial_investment" && (
					<div className="!mt-7 flex flex-col gap-2 md:gap-2.5">
						<p className="text-xs sm:text-[12.5px] text-[#717E83] zabalMedFont">
							Waiting Period
						</p>
						<div className="grid grid-cols-2 gap-3 md:gap-4 lg:gap-5">
							<div className="relative">
								<PlainSelect
									context="waiting period"
									isOpened={toggleWaitingPeriod}
									currentText={waiting_period}
									handleToggle={() => {
										setToggleWaitingPeriod((prev) => !prev);
										setToggleWaitingLength(false);
									}}
								/>
								{toggleWaitingPeriod && (
									<div className="pb-10 absolute z-[2] w-full top-48px">
										<SelectDropDown
											arrOptions={expectedDuration.map((a) => a.duration)}
											onHandleSelect={(d) => {
												setCardDetails((prev) => ({
													...prev,
													waiting_period: d,
													waiting_length: "",
												}));
												setTimeout(() => {
													setToggleWaitingPeriod(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
							<div className="relative">
								<PlainSelect
									context="waiting length"
									isOpened={toggleWaitingLength}
									currentText={waiting_length}
									handleToggle={() => {
										if (!waiting_period) return;
										setToggleWaitingLength((prev) => !prev);
										setToggleWaitingPeriod(false);
									}}
								/>
								{toggleWaitingLength && (
									<div className="pb-10 absolute z-[2] w-full top-48px">
										<SelectDropDown
											arrOptions={expectedDuration
												?.find((a) => a.duration === waiting_period)
												?.length.map((a) => String(a))}
											onHandleSelect={(d) => {
												setCardDetails((prev) => ({
													...prev,
													waiting_length: d,
												}));
												setTimeout(() => {
													setToggleWaitingLength(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="hidden sm:flex justify-end gap-4">
					<CustomGreenBtn
						text="proceed"
						width="110px"
						height="42px"
						fontSize="13px"
						isDisabled={isProceedBtnValid()}
						onRequestClick={onProceedBtnClick}
					/>
				</div>
				<div className="flex flex-col gap-4 pt-4 sm:hidden">
					<CustomGreenBtn
						text="proceed"
						isDisabled={isProceedBtnValid()}
						onRequestClick={onProceedBtnClick}
					/>
				</div>
			</div>
		</div>
	);
};
export default CardDetails;

CardDetails.propTypes = {
	setActiveTab: PropTypes.func,
	amountPayable: PropTypes.string,
};
