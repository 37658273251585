import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { closeMoveFundsModal } from "../../../redux/payments/paymentActions";
import { formatAmountToIncludeCurrency } from "../../../common/utils";
import NotifyUser from "../../../layout/NotifyUser";
import { Preloader } from "../../../components/globalStyled";
import EmptyData from "../../../components/EmptyData";
// import { networkErrorTrue } from "../../../redux/authRedux/authActions";
import { fetchAllSavingsPlan } from "../../../redux/payments/paymentServices";
import { toast } from "react-toastify";
import moment from "moment";

const PlanToReceiveFunds = ({
	to,
	setActiveMovementTab,
	amountToMove,
	childWallet,
	setMoveFundsInfo,
	selectedData,
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [allSavingsPlan, setAllSavingsPlan] = useState([]);

	const fetchSavingsPlan = async () => {
		setLoading(true);

		try {
			const response = await fetchAllSavingsPlan(childWallet);

			setAllSavingsPlan(response);
		} catch (error) {
			if (error === "Network Error") {
				toast.error("Oops! You are not connected to the internet.");
			} else if (error === "Invalid session token") {
				toast.error("This session has expired. Please login.");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			} else {
				toast.error(error);
			}
		} finally {
			setLoading(false);
		}
	};

	const unExpiredSavingsPlan = allSavingsPlan.filter(
		(plan) => Date.now() < new Date(`${plan?.maturityDate}`)
	);

	const onPlanSelect = (data) => {
		setMoveFundsInfo((prev) => ({ ...prev, selectedData: data }));
	};

	useEffect(() => {
		fetchSavingsPlan();
	}, []);

	return (
		<NotifyUser
			header={`Select ${to} plan to receive funds.`}
			onWhiteBtnClick={() => setActiveMovementTab(1)}
			onCloseBtnClick={() => dispatch(closeMoveFundsModal())}
			onGreenBtnClick={() => setActiveMovementTab(4)}
			isGreenBtnDisabled={!selectedData || loading}
		>
			<>
				<p className="text-sm">
					<span className="text-[#717E83] zabalMedFont">Amount: </span>
					<span className="zabalBoldFont text-primary-color">NGN </span>
					<span className="text-primary-color zabalMedFont">
						{formatAmountToIncludeCurrency(
							undefined,
							amountToMove?.replaceAll(",", "")
						)}
					</span>
				</p>
				<div className="min-h-[120px] max-h-[220px] md:max-h-[240px] lg:max-h-[270px] overflow-y-auto scroll-wrapper">
					{loading ? (
						<div className="h-[120px] md:h-[140px] grid place-items-center">
							<Preloader />
						</div>
					) : unExpiredSavingsPlan.length > 0 ? (
						<div className="grid gap-3 mr-2">
							{unExpiredSavingsPlan.map((plan, i) => (
								<SavingsPlan
									plan={plan}
									key={i}
									onSelect={() => onPlanSelect(plan)}
									isSelected={selectedData?.objectId === plan?.objectId}
								/>
							))}
						</div>
					) : (
						<div className="h-[120px] md:h-[150px]">
							<EmptyData text="No saving plans available" />
						</div>
					)}
				</div>
			</>
		</NotifyUser>
	);
};

PlanToReceiveFunds.propTypes = {
	to: PropTypes.string,
	setActiveMovementTab: PropTypes.func,
	amountToMove: PropTypes.string,
	childWallet: PropTypes.string,
	setMoveFundsInfo: PropTypes.func,
	selectedData: PropTypes.object,
};

export default PlanToReceiveFunds;

const SavingsPlan = ({ plan, onSelect, isSelected }) => {
	return (
		<div
			className={`cursor-pointer p-3 lg:p-4 rounded-lg border flex flex-col md:flex-row gap-y-3 ${
				isSelected ? "border-fortcore-green border-2" : "border-[#B8C8CF]"
			}`}
			onClick={onSelect}
		>
			<h4 className="zabalBoldFont text-sm md:text-[15px] md:w-2/5">
				{formatAmountToIncludeCurrency(
					"NGN",
					plan?.amountSaved?.replaceAll(",", "") || "0"
				)}
			</h4>
			<div className="space-y-1 md:space-y-1.5 md:w-3/5">
				{plan?.savingsType === "regular" && (
					<p className="text-[12.5px] md:text-[13px] zabalMedFont">
						<span className="text-[12.25px] md:text-[12.5px] zabalRegFont">
							Purpose:
						</span>{" "}
						<span className="">{plan?.purpose || "General Plan"}</span>
					</p>
				)}
				<p className="text-[12.5px] md:text-[13px] zabalMedFont">
					<span className="text-[12.25px] md:text-[12.5px] zabalRegFont">
						Amount to save:
					</span>{" "}
					<span className="">
						{formatAmountToIncludeCurrency(
							"NGN",
							plan?.amountToSave?.replaceAll(",", "")
						)}
					</span>
				</p>

				<p className="text-[12.5px] md:text-[13px] zabalMedFont">
					<span className="text-[12.25px] md:text-[12.5px] zabalRegFont">
						Maturity date:
					</span>{" "}
					<span className="">
						{moment(plan?.maturityDate).format("Do MMMM, YYYY")}
					</span>
				</p>
				<p className="text-[12.5px] md:text-[13px] zabalMedFont">
					<span className="text-[12.25px] md:text-[12.5px] zabalRegFont">
						Interest:
					</span>{" "}
					<span className=""></span>
					{plan?.interestRate}%
				</p>
			</div>
		</div>
	);
};
SavingsPlan.propTypes = {
	plan: PropTypes.object,
	onSelect: PropTypes.func,
	isSelected: PropTypes.bool,
};
