import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import PropTypes from "prop-types";

const Pagination = ({
	currentPage,
	totalPages,
	onPrevClick,
	onNextClick,
	isPrevDisabled,
	isNextDisabled,
}) => {
	return (
		<div className="w-full flex items-center justify-center">
			<div className="flex text-[#788084] items-center gap-1.5 md:gap-2 text-[12.5px] md:text-[13px]">
				<button
					className="text-fortcore-green"
					onClick={onPrevClick}
					disabled={isPrevDisabled}
				>
					<MdKeyboardArrowLeft size={25} />
				</button>
				<p>Page</p>
				<p className="border rounded px-3 md:px-3.5 py-1 border-[#B8C8CF] text-fortcore-green zabalBoldFont">
					{currentPage}
				</p>
				<p>of</p>
				<p>{totalPages}</p>
				<button
					className="text-fortcore-green"
					onClick={onNextClick}
					disabled={isNextDisabled}
				>
					<MdKeyboardArrowRight size={25} />
				</button>
			</div>
		</div>
	);
};

export default Pagination;

Pagination.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	onPrevClick: PropTypes.func,
	onNextClick: PropTypes.func,
	isPrevDisabled: PropTypes.bool,
	isNextDisabled: PropTypes.bool,
};
