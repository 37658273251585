/* eslint-disable no-undef */
import {
	fetchMarketPlaceError,
	fetchMarketPlaceLoading,
	fetchMarketPlaceSuccess,
	fetchRequestLeaseDataError,
	fetchRequestLeaseDataLoading,
	fetchRequestLeaseDataSuccess,
	saveBusinessFormFromParse,
	savePersonalFormFromParse,
	saveInvestorFormFromParse,
	fetchOngoingLeaseLoading,
	fetchOngoingLeaseSuccess,
	fetchOngoingLeaseError,
	fetchLeaseWithQueryLoading,
	fetchLeaseWithQuerySuccess,
	fetchLeaseWithQueryError,
	fetchSubmittedLeaseLoading,
	fetchSubmittedLeaseSuccess,
	fetchSubmittedLeaseError,
	submitNegotiationLoading,
	submitNegotiationSuccess,
	submitNegotiationError,
	fetchLeaseBoardDataLoading,
	fetchLeaseBoardDataSuccess,
	fetchLeaseBoardDataError,
	fetchInvestorMarketPlaceLoading,
	fetchInvestorMarketPlaceSuccess,
	fetchInvestorMarketPlaceError,
	fetchRegularSavingsLoading,
	fetchRegularSavingsSuccess,
	fetchRegularSavingsError,
	fetchAssetBasedSavingsLoading,
	fetchAssetBasedSavingsSuccess,
	fetchAssetBasedSavingsError,
	createAssetBasedSavingsLoading,
	createAssetBasedSavingsSuccess,
	createAssetBasedSavingsError,
	fetchRiskInvestmentsLoading,
	fetchRiskInvestmentsSuccess,
	fetchRiskInvestmentsError,
	investorCreateAssetLoading,
	investorCreateAssetSuccess,
	investorCreateAssetError,
	fetchCategoriesLoading,
	fetchCategoriesSuccess,
	fetchCategoriesError,
	fetchBrandsLoading,
	fetchBrandsError,
	fetchBrandsSuccess,
	getRentalFeeLoading,
	getRentalFeeSuccess,
	getRentalFeeError,
	// getDeliveryFeeLoading,
	// getDeliveryFeeSuccess,
	// getDeliveryFeeError,
	getCurrentContextInterest,
	createLeaseLoading,
	createLeaseSuccess,
	createLeaseError,
	updateOngoingLeaseSuccess,
	updateOngoingLeaseError,
	updateOngoingLeaseLoading,
	deleteOngoingLeaseLoading,
	deleteOngoingLeaseError,
	deleteOngoingLeaseSuccess,
	deleteInactiveAssetError,
	deleteInactiveAssetSuccess,
	deleteInactiveAssetLoading,
	submitOngoingLeaseSuccess,
	createRiskInvestmentsLoading,
	createRiskInvestmentsSuccess,
	createRiskInvestmentsError,
	createRegSavingsLoading,
	createRegSavingsSuccess,
	createRegSavingsError,
	activateAssetLoading,
	activateAssetSuccess,
	fetchSingleAssetBasedSavingsLoading,
	fetchSingleAssetBasedSavingsSuccess,
	fetchSingleAssetBasedSavingsError,
	updateAssetBasedSavings,
	queryPartialFundedAssetLoading,
	queryPartialFundedAssetSuccess,
	queryPartialFundedAssetError,
	queryPartialFundedRequestLoading,
	queryPartialFundedRequestSuccess,
	queryPartialFundedRequestError,
	registerAssetLoading,
	registerAssetError,
	registerAssetSuccess,
	activateInsuranceLoading,
	activateInsuranceSuccess,
	activateInsuranceError,
	creditAssessmentLoading,
	creditAssessmentError,
	creditAssessmentSuccess,
} from "./leaseActions";
import { toast } from "react-toastify";
import Parse from "../../api/ApiConfig";
import baseURL from "../../api/UrlConfig";
import axios from "axios";
import { logout, networkErrorTrue, redirect } from "../authRedux/authActions";
import { createNotification } from "../../components/notification/CreateNotification";

export const getMarketPlaceData = () => async (dispatch) => {
	dispatch(fetchMarketPlaceLoading());
	try {
		const assetsClass = Parse.Object.extend("Asset");
		const assetsQuery = new Parse.Query(assetsClass)
			.equalTo("status", "active")
			.equalTo("paidSlots", 4)
			.doesNotExist("leaseAgreement");

		const response = await assetsQuery.find();
		// // console.log(response[0], "market");
		// const getFullData = response.map(async (item) => {
		// 	const product = await item?.get("productId").fetch();
		// 	const productJSON = product.toJSON();
		// 	const dataJSON = item.toJSON();
		// 	return {
		// 		...dataJSON,
		// 		productId: productJSON,
		// 	};
		// });
		// const dataPromise = await Promise.allSettled(getFullData);
		const dataResolved = response.map((items) => items?.toJSON());

		// console.log(dataResolved);
		dispatch(
			fetchMarketPlaceSuccess(
				dataResolved?.sort(
					(assetOne, assetTwo) =>
						new Date(assetTwo?.createdAt).getTime() -
						new Date(assetOne?.createdAt).getTime()
				)
			)
		);
	} catch (error) {
		console.log(error);
		dispatch(fetchMarketPlaceError());
		toast.error("Failed to fetch market data");
	}
};

export const getProductsWithQuery = (filter) => async (dispatch) => {
	dispatch(fetchRequestLeaseDataLoading());
	try {
		const query = new Parse.Query("Products").equalTo("approve", true);

		if (filter?.model) {
			query.equalTo("model", filter.model);
			const response = await query.find();
			const filteredData = response.map((items) => items?.toJSON());
			dispatch(fetchRequestLeaseDataSuccess(filteredData));
		}

		if (filter?.brand) {
			query.equalTo("brand", filter.brand);
			const response = await query.find();
			const filteredData = response.map((items) => items?.toJSON());
			dispatch(fetchRequestLeaseDataSuccess(filteredData));
		} else if (filter?.category) {
			query.equalTo("category", filter.category);
			const response = await query.find();
			const filteredData = response.map((items) => items?.toJSON());

			dispatch(fetchRequestLeaseDataSuccess(filteredData));
		} else {
			const productsClass = Parse.Object.extend("Products");
			const productsQuery = new Parse.Query(productsClass);
			const response = await productsQuery.find();
			const data = response.map((items) => items?.toJSON());
			dispatch(fetchRequestLeaseDataSuccess(data));
		}
	} catch (error) {
		console.log(error);
		dispatch(fetchRequestLeaseDataError());
		toast.error("Failed to fetch market data");
	}
};

export const getPersonalDataFromParse = () => async (dispatch) => {
	try {
		const query = new Parse.Query("Personal");
		query.equalTo("user", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(savePersonalFormFromParse(response[0]));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
		}
	}
};

export const getBusinessDataFromParse = () => async (dispatch) => {
	try {
		const query = new Parse.Query("Business");
		query.equalTo("user", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveBusinessFormFromParse(response[0]));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
		}
	}
};

export const getInvestorDataFromParse = () => async (dispatch) => {
	try {
		const query = new Parse.Query("Investor");
		query.equalTo("user", Parse.User.current()?.id);
		const response = await query.find();

		dispatch(saveInvestorFormFromParse(response[0]));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
		}
	}
};

export const updateOngoingLeaseInParse =
	(objectId, data, msg = "", context = "") =>
	async (dispatch) => {
		dispatch(updateOngoingLeaseLoading());
		try {
			const query = new Parse.Query("Lease");
			const object = await query.get(objectId);

			for (const key in data) {
				object.set(key, data[key]);
			}

			const updatedObject = await object.save();
			if (context === "update-lease-to-submit") {
				dispatch(submitOngoingLeaseSuccess(updatedObject));
			} else if (context === "update-investor-paid-slots") {
				dispatch(
					updateOngoingLeaseSuccess({ data: updatedObject, message: context })
				);
			} else {
				dispatch(
					updateOngoingLeaseSuccess({ data: updatedObject, message: msg })
				);
			}

			const message =
				context === "update-investor-paid-slots"
					? "Lease request funded"
					: context === "update-lease-to-submit"
					? "Lease request submitted"
					: context === "agreement-signed-and-payment-deduction"
					? "Lease agreement signed and first payment"
					: "Lease transaction updated";
			const metaData = {
				category: "Lease",
				linkToId: updatedObject?.id,
				moreDetails:
					context === "update-investor-paid-slots"
						? "You have successfully invested on a lease request. Proceed to your investment portfolio for more details"
						: `You lease request with the transaction ID "TRN-${
								updatedObject?.id
						  }" has been ${
								context === "update-lease-to-submit"
									? "submitted"
									: context === "agreement-signed-and-payment-deduction"
									? "You have signed the lease agreement and has made your first payment."
									: "updated successfully"
						  }.`,
			};

			if (updatedObject) {
				createNotification(message, metaData);
			}
		} catch (error) {
			console.log(error);
			dispatch(updateOngoingLeaseError());
			toast.error(
				context === "update-investor-paid-slots"
					? "Failed to create investment"
					: "Failed to update details"
			);
		}
	};

export const createLease = (data) => async (dispatch) => {
	dispatch(createLeaseLoading());
	try {
		const Lease = Parse.Object.extend("Lease");
		const lease = new Lease();
		const response = await lease.save(data);
		// console.log(response);
		dispatch(createLeaseSuccess(response));

		const message = "New Lease Request";
		const metaData = {
			category: "Lease",
			linkToId: response?.id,
			moreDetails: `You lease request with the transaction ID "TRN-${response?.id}" has been created, to submit, complete neccessary steps.`,
		};

		if (response) {
			createNotification(message, metaData);
		}
	} catch (error) {
		console.log(error);
		dispatch(createLeaseError());
		toast.error("Failed to create lease request");
	}
};

export const investorCreateAsset = (data, moreDetails) => async (dispatch) => {
	dispatch(investorCreateAssetLoading());
	try {
		const Asset = Parse.Object.extend("Asset");
		const asset = new Asset();
		const response = await asset.save(data);
		console.log(response);
		dispatch(investorCreateAssetSuccess(response));

		const message = "New asset created for leasing.";
		const metaData = {
			category: "Asset",
			linkToId: response?.id,
			moreDetails,
		};

		if (response) {
			createNotification(message, metaData);
		}
	} catch (error) {
		console.log(error);
		dispatch(investorCreateAssetError());
		toast.error("Failed to create asset. Try again");
	}
};

export const fetchOngoingLease = (userId) => async (dispatch) => {
	dispatch(fetchOngoingLeaseLoading());
	try {
		const leaseClass = Parse.Object.extend("Lease");
		const query = new Parse.Query(leaseClass);

		query.equalTo("userId", userId).equalTo("state", "ongoing");
		const response = await query.find();

		dispatch(fetchOngoingLeaseSuccess(response));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchOngoingLeaseError());
		} else {
			dispatch(fetchOngoingLeaseError());
		}
	}
};

export const fetchLeaseWithQuery = (objectId, userId) => async (dispatch) => {
	dispatch(fetchLeaseWithQueryLoading());
	try {
		const query = new Parse.Query("Lease");
		query.equalTo("userId", userId).equalTo("objectId", objectId);
		const response = await query.find();

		dispatch(fetchLeaseWithQuerySuccess(response[0]));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchLeaseWithQueryError());
		} else {
			dispatch(fetchLeaseWithQueryError());
		}
	}
};

export const fetchAllLeaseForInvestor = () => async (dispatch) => {
	dispatch(fetchInvestorMarketPlaceLoading());
	try {
		const query = new Parse.Query("Lease");
		query
			.equalTo("leaseSource", "products-catalogue")
			.equalTo("leaseType", "Sponsored Lease")
			.equalTo("state", "requested")
			.equalTo("funded", false);

		const response = await query.find();

		// const getFullData = response.map(async (item) => {
		// 	const product = await item?.get("productId").fetch();
		// 	const productJSON = product.toJSON();
		// 	const dataJSON = item.toJSON();
		// 	return { ...dataJSON, productId: productJSON };
		// });
		// const dataPromise = await Promise.allSettled(getFullData);
		const dataResolved = response.map((items) => items?.toJSON());
		// const dataResolved = dataPromise.map((v) => v.value);
		dispatch(fetchInvestorMarketPlaceSuccess(dataResolved));
	} catch (error) {
		console.log(error);
		dispatch(fetchInvestorMarketPlaceError());
		toast.error("Failed to fetch market data");
	}
};

export const fetchSubmittedLease = () => async (dispatch) => {
	dispatch(fetchSubmittedLeaseLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/submittedLease`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);

		const data = await response.data?.result;

		dispatch(fetchSubmittedLeaseSuccess(data));
	} catch (error) {
		console.log(error);
		if (error?.message === "Network Error") {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchSubmittedLeaseError());
		} else if (error?.response.data?.error === "Invalid session token") {
			dispatch(fetchSubmittedLeaseError());

			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
			setTimeout(() => {
				window.location.reload();
			}, 1200);
			return;
		} else {
			dispatch(fetchSubmittedLeaseError());
		}
	}
};

export const submitNegotiation = (data) => (dispatch) => {
	dispatch(submitNegotiationLoading());
	try {
		setTimeout(() => {
			console.log(data);
			dispatch(submitNegotiationSuccess());
		}, 2000);
	} catch (error) {
		console.log(error);
		dispatch(submitNegotiationError());
	}
};

export const fetchLeaseBoardData = () => async (dispatch) => {
	dispatch(fetchLeaseBoardDataLoading());
	try {
		const Asset = Parse.Object.extend("Asset");
		const Lease = Parse.Object.extend("Lease");

		// Create a Parse query
		const query = new Parse.Query(Asset);
		const queryTwo = new Parse.Query(Lease);

		// Specify the condition for the array field
		query.containedIn("investors", [Parse.User.current()?.id]);
		queryTwo
			.containedIn("investors", [Parse.User.current()?.id])
			.doesNotExist("assetObj");

		const response = await query.find();
		const responseTwo = await queryTwo.find();

		const c = response.map((b) => b.toJSON());
		const d = responseTwo.map((b) => b.toJSON());
		// console.log(c, d);

		// const a = response.map(async (item) => {
		// 	const product = await item?.get("productId").fetch();
		// 	const productJSON = product.toJSON();
		// 	const dataJSON = item.toJSON();
		// 	return { ...dataJSON, productId: productJSON };
		// });
		// const b = responseTwo.map(async (item) => {
		// 	const product = await item?.get("productId").fetch();
		// 	const productJSON = product.toJSON();
		// 	const dataJSON = item.toJSON();
		// 	return { ...dataJSON, productId: productJSON };
		// });
		// const dataPromise = await Promise.allSettled(a);
		// const dataPromiseTwo = await Promise.allSettled(b);
		// const dataResolved = dataPromise.map((v) => v.value);
		// const dataResolvedTwo = dataPromiseTwo.map((v) => v.value);

		dispatch(
			fetchLeaseBoardDataSuccess([
				...c,
				// ...dataResolved,
				...d.filter((a) => a?.usersRequest?.length === 0),
				// ...dataResolvedTwo.filter((a) => a?.usersRequest?.length === 0),
			])
		);
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchLeaseBoardDataError());
		} else {
			toast.error("Error while trying to fetch data");
			dispatch(fetchLeaseBoardDataError());
		}
	}
};

export const fetchLeaseRegularSavings = () => async (dispatch) => {
	dispatch(fetchRegularSavingsLoading());
	try {
		const query = new Parse.Query("Savings");
		query
			.equalTo("userId", Parse.User.current()?.id)
			.equalTo("savingsType", "regular")
			.equalTo("source", "lease");
		const response = await query.find();
		const data = response.map((item) => item.toJSON());
		dispatch(fetchRegularSavingsSuccess(data));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchRegularSavingsError());
		} else {
			dispatch(fetchRegularSavingsError());
		}
	}
};

export const fetchAssetBasedSavings = () => async (dispatch) => {
	dispatch(fetchAssetBasedSavingsLoading());
	try {
		const query = new Parse.Query("Savings");
		query
			.equalTo("userId", Parse.User.current()?.id)
			.equalTo("savingsType", "asset based");
		// .equalTo("source", "lease");

		const response = await query.find();

		// const a = response.map(async (item) => {
		// 	const product = await item?.get("productToSaveFor").fetch();
		// 	const productJSON = product.toJSON();
		// 	const dataJSON = item.toJSON();
		// 	return { ...dataJSON, productId: productJSON };
		// });
		// const dataPromise = await Promise.allSettled(a);
		const dataResolved = response.map((items) => items?.toJSON());
		// const dataResolved = dataPromise.map((v) => v.value);

		dispatch(fetchAssetBasedSavingsSuccess(dataResolved));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchAssetBasedSavingsError());
		} else {
			dispatch(fetchAssetBasedSavingsError());
		}
	}
};

export const createAssetBasedSavings = (data) => async (dispatch) => {
	dispatch(createAssetBasedSavingsLoading());
	try {
		const Savings = Parse.Object.extend("Savings");
		const savings = new Savings();
		const response = await savings.save(data);
		console.log(response);
		dispatch(createAssetBasedSavingsSuccess(response));

		const message = "New Asset savings plan created.";
		const metaData = {
			category: "Asset",
			linkToId: response?.id,
			moreDetails: `A new asset based savings plan with the transaction ID of ${response?.id} has been created successfully.`,
		};

		if (response) {
			createNotification(message, metaData);
		}
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("bad or missing username")) {
			dispatch(redirect("/login"));
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
		} else {
			toast.error("Failed to create savings");
			dispatch(createAssetBasedSavingsError());
		}
	}
};

export const fetchRiskFreeInvestments = () => async (dispatch) => {
	dispatch(fetchRiskInvestmentsLoading());
	try {
		const query = new Parse.Query("Investment");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		const data = response.map((item) => item.toJSON());
		dispatch(fetchRiskInvestmentsSuccess(data));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchRiskInvestmentsError());
		} else {
			toast.error("Failed to fetch investments");
			dispatch(fetchRiskInvestmentsError());
		}
	}
};

export const fetchCategories = () => async (dispatch) => {
	dispatch(fetchCategoriesLoading());
	try {
		const category = Parse.Object.extend("Category");
		const categoryQuery = new Parse.Query(category);
		const response = await categoryQuery.find();
		const data = response.map((items) => items?.toJSON());
		dispatch(fetchCategoriesSuccess(data));
	} catch (error) {
		console.log(error);
		dispatch(fetchCategoriesError());
	}
};

export const fetchBrands = (queriesArr) => async (dispatch) => {
	dispatch(fetchBrandsLoading());
	try {
		const combinedQuery = Parse.Query.or(...queriesArr);
		const response = await combinedQuery.find();
		const data = response.map((items) => items?.toJSON());
		dispatch(fetchBrandsSuccess(data));
	} catch (error) {
		console.log(error);
		dispatch(fetchBrandsError());
	}
};

export const getRentalFee = (assetValue, sessionToken) => async (dispatch) => {
	dispatch(getRentalFeeLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/calculateMonthlyPayment`,
			assetValue,
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": sessionToken,
				},
			}
		);

		const data = response?.data;
		dispatch(getRentalFeeSuccess(data?.result["Monthly Rental Payment"]));
	} catch (error) {
		if (error?.message === "Network Error") {
			toast.error("Oops, Service provider has been disconnected!");
			dispatch(getRentalFeeError());
		} else if (error?.response.data?.error === "Invalid session token") {
			dispatch(getRentalFeeError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else {
			dispatch(getRentalFeeError());
		}
	}
};

// export const getDeliveryFee = (location, sessionToken) => async (dispatch) => {
// 	dispatch(getDeliveryFeeLoading());
// 	try {
// 		const response = await axios.post(
// 			`${baseURL}/functions/calculateDeliveryFee`,
// 			location,
// 			{
// 				headers: {
// 					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
// 					"X-Parse-Session-Token": sessionToken,
// 				},
// 			}
// 		);

// 		const data = response?.data;
// 		dispatch(getDeliveryFeeSuccess(data?.result.deliveryFee));
// 	} catch (error) {
// 		console.log(error);

// 		if (error?.message === "Network Error") {
// 			toast.error("Oops, Service provider has been disconnected!");
// 			dispatch(getDeliveryFeeError());
// 		} else if (error?.response.data?.error === "Invalid session token") {
// 			dispatch(getDeliveryFeeError());
// 			dispatch(logout());
// 			localStorage.clear();
// 			toast.error("This session has expired. Please Login again");
// 			dispatch(redirect("/login"));
// 		} else {
// 			dispatch(getDeliveryFeeError());
// 		}
// 	}
// };

export const fetchCurrentInterest = (context) => async (dispatch) => {
	try {
		const query = new Parse.Query("Parameters");
		query.equalTo("parameter", context);
		const response = await query.find();
		dispatch(getCurrentContextInterest(response[0]?.toJSON()));
	} catch (error) {
		console.log(error, error.message);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
		}
		// dispatch(fetchLeaseWithQueryError());
	}
};

export const deleteSelectedOngoingLease = (leaseIdArr) => async (dispatch) => {
	dispatch(deleteOngoingLeaseLoading());

	try {
		await Promise.all(
			leaseIdArr.map(async (object) => {
				// Create a Parse.Object instance based on the object's class name and ID
				const obj = Parse.Object.extend("Lease");
				const objToDelete = new obj();
				objToDelete.id = object;

				// Use destroy method to delete the object
				await objToDelete.destroy();
			})
		);

		dispatch(deleteOngoingLeaseSuccess());
	} catch (error) {
		console.log(error);
		console.error("Error deleting transaction(s). Try again");
		dispatch(deleteOngoingLeaseError());
	}
};

export const deleteInactiveInvestment = (assetId) => async (dispatch) => {
	dispatch(deleteInactiveAssetLoading());
	try {
		const MyObject = Parse.Object.extend("Asset");
		const objectToDelete = new MyObject();
		objectToDelete.id = assetId;

		const data = await objectToDelete.destroy();

		dispatch(deleteInactiveAssetSuccess());

		const message = "Inactive asset deleted";
		const metaData = {
			category: "Lease",
			linkToId: assetId,
			moreDetails: `An inactivated asset with the id of ${assetId} has been deleted.`,
		};

		if (data) {
			createNotification(message, metaData);
		}
	} catch (error) {
		console.log(error);
		console.error("Error deleting investment. Try again");
		dispatch(deleteInactiveAssetError());
	}
};

export const createRegularSavings = (savingsData) => async (dispatch) => {
	dispatch(createRegSavingsLoading());
	try {
		const Savings = Parse.Object.extend("Savings");
		const savings = new Savings();
		const response = await savings.save(savingsData);
		console.log(response);
		dispatch(createRegSavingsSuccess(response?.toJSON()));
	} catch (error) {
		console.log(error);
		if (error.message.includes("Unable to connect to the Parse API")) {
			toast.error("Oops. You are not connected to the internet");
			dispatch(createRegSavingsError());
			return;
		} else {
			dispatch(createRegSavingsError());
			toast.error("Failed to create savings");
		}
	}
};

export const createRiskFreeInvestments =
	(investmentData) => async (dispatch) => {
		dispatch(createRiskInvestmentsLoading());
		try {
			const Investment = Parse.Object.extend("Investment");
			const investment = new Investment();
			const response = await investment.save(investmentData);
			console.log(response);
			dispatch(createRiskInvestmentsSuccess(response));

			const message = "New free investment created";
			const metaData = {
				category: "Lease",
				linkToId: response?.id,
				moreDetails: `You have created a free investment with the transaction id of TRN-${response?.id} and N${investmentData?.principal} has been deducted from your wallet.`,
			};

			if (response) {
				createNotification(message, metaData);
			}
		} catch (error) {
			console.log(error);
			dispatch(createRiskInvestmentsError());
			toast.error("Failed to create investments");
		}
	};

export const updateInvestorCreatedAsset =
	(assetId, data, message, metaData, user) => async (dispatch) => {
		dispatch(activateAssetLoading());
		try {
			const query = new Parse.Query("Asset");
			const object = await query.get(assetId);
			const updatedObject = await object.save(data);

			dispatch(activateAssetSuccess(updatedObject?.toJSON()));

			if (updatedObject) {
				createNotification(message, metaData, user);
			}
		} catch (error) {
			console.log(error);
			toast.error("Failed to activate asset");
		}
	};

export const fetchSingleAssetBasedSavings = (objectId) => async (dispatch) => {
	dispatch(fetchSingleAssetBasedSavingsLoading());
	try {
		const query = new Parse.Query("Savings");
		query
			.equalTo("userId", Parse.User.current()?.id)
			.equalTo("savingsType", "asset based")
			.equalTo("objectId", objectId);
		const response = await query.find();

		dispatch(fetchSingleAssetBasedSavingsSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(fetchSingleAssetBasedSavingsError());
		} else {
			dispatch(fetchSingleAssetBasedSavingsError());
		}
	}
};

export const updateSingleAssetSavings =
	(objectId, data, otherDetails) => async (dispatch) => {
		try {
			const query = new Parse.Query("Savings");
			const object = await query.get(objectId);
			const updatedObject = await object.save(data);

			dispatch(updateAssetBasedSavings(updatedObject?.toJSON()));

			const message = "Asset based savings top-up";
			const metaData = {
				category: "Savings",
				linkToId: updatedObject?.id,
				moreDetails: `The amount of N${otherDetails.amount} has been added to your savings. Keep up the good work.`,
			};

			if (updatedObject) {
				createNotification(message, metaData);
			}
		} catch (error) {
			console.log(error);
			toast.error("Failed to update details");
		}
	};

export const queryPartialFundedAsset = (objectId) => async (dispatch) => {
	dispatch(queryPartialFundedAssetLoading());
	try {
		const query = new Parse.Query("Asset");
		query
			.equalTo("type", "partial-investment")
			.notEqualTo("paidSlots", 4)
			.equalTo("objectId", objectId);
		const response = await query.find();

		await response[0]?.get("productId").fetch();
		const dataJSON = response[0]?.toJSON();
		// const productJSON = product.toJSON();

		// const newData = { ...dataJSON, productJSON };

		dispatch(queryPartialFundedAssetSuccess(dataJSON));
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(queryPartialFundedAssetError());
		} else {
			dispatch(queryPartialFundedAssetError());
		}
	}
};

export const queryPartialFundedRequest = (objectId) => async (dispatch) => {
	dispatch(queryPartialFundedRequestLoading());
	try {
		const query = new Parse.Query("Lease");
		query
			.equalTo("type", "partial-investment")
			.notEqualTo("paidSlots", 4)
			.equalTo("objectId", objectId);
		const response = await query.find();

		await response[0]?.get("productId").fetch();
		const dataJSON = response[0]?.toJSON();

		dispatch(queryPartialFundedRequestSuccess(dataJSON));
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			dispatch(networkErrorTrue(error.message));
			dispatch(queryPartialFundedRequestError());
		} else {
			dispatch(queryPartialFundedRequestError());
		}
	}
};

export const onInvestorUpdatesRequestStatus = async (
	assetId,
	leaseId,
	data,
	newData
) => {
	try {
		const queryOne = new Parse.Query("Asset");
		const objectOne = await queryOne.get(assetId);
		const updatedObjectOne = await objectOne.save(data);

		// Create a query to fetch objects based on your criteria
		const query = new Parse.Query("Lease");
		query.containedIn(
			"objectId",
			newData.map((obj) => obj.objectId)
		);

		const objects = await query.find();

		// console.log(objects, newData);

		// Update each object with new data
		const updatedObjects = objects.map((parseObject, index) => {
			parseObject.set("investorsArray", newData[index].investorsArray);
			parseObject.set("usersRequest", newData[index].usersRequest);
			return parseObject.save();
		});

		// Wait for all updates to complete
		const dataTwo = await Promise.all(updatedObjects);

		const message = "Request status updated";
		const metaData = {
			category: "Asset",
			linkToId: updatedObjectOne?.id,
			moreDetails: `You have successfully updated the request status on lease ${leaseId}`,
		};

		if (updatedObjectOne) {
			createNotification(message, metaData);
		}

		return {
			asset: updatedObjectOne?.toJSON(),
			leaseArr: dataTwo,
		};
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			toast.error("Oops! You are not conected to the internet");
			throw error;
		} else {
			toast.error("Failed to update request status");
			throw error;
		}
	}
};

export const investorRegisterAsset = (data) => async (dispatch) => {
	dispatch(registerAssetLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/vehicleRegistration`,
			data,
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);
		dispatch(registerAssetSuccess(response.data));

		toast.success(
			"Payment has been deducted successfully for vehicle registration"
		);

		setTimeout(() => {
			window.location.reload();
		}, 1000);
	} catch (error) {
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			toast.error("Oops! You are not conected to the internet");
			throw Error(error);
		} else if (error?.response.data?.error === "Invalid session token") {
			dispatch(registerAssetError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else {
			dispatch(registerAssetError());
			toast.error("Failed to register asset");
		}
	}
};

export const updateAssetOnRegistration =
	(assetId, data) => async (dispatch) => {
		dispatch(activateAssetLoading());
		try {
			const query = new Parse.Query("Asset");
			const object = await query.get(assetId);
			const updatedObject = await object.save(data);
			console.log(updatedObject);
		} catch (error) {
			console.log(error);
			toast.error("Failed to activate asset");
		}
	};

export const getCompanyInsuranceInfo = async () => {
	try {
		const query = new Parse.Query("CommonDetails");
		query.exists("companyInsuranceInfo");
		const response = await query.first();

		return response?.toJSON();
	} catch (error) {
		console.log(error);
		throw Error(error);
	}
};

export const updateLeaseOnRegistration = async (
	leaseId,
	data,
	context,
	message
) => {
	try {
		const query = new Parse.Query("Lease");
		const object = await query.get(leaseId);
		const updatedObject = await object.save(data);

		const metaData = {
			category: "Asset",
			linkToId: updatedObject?.id,
			moreDetails: context,
		};

		if (updatedObject) {
			createNotification(message, metaData);
		}

		return updatedObject;
	} catch (error) {
		toast.error("Failed to activate asset");
		throw Error(error);
	}
};

export const investortApplyForInsurance = (data) => async (dispatch) => {
	dispatch(activateInsuranceLoading());

	try {
		const response = await axios.post(
			`${baseURL}/functions/carInsurance`,
			data,
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);

		dispatch(activateInsuranceSuccess(response.data?.result?.msg));
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			toast.error("Oops! You are not conected to the internet");
			dispatch(activateInsuranceError());
		} else if (error?.response.data?.error === "Invalid session token") {
			dispatch(activateInsuranceError());
			dispatch(logout());
			localStorage.clear();
			toast.error("This session has expired. Please Login again");
			dispatch(redirect("/login"));
		} else {
			dispatch(activateInsuranceError());
			toast.error("Failed to insure asset");
		}
	}
};

export const enableCreditAssessment = (leaseId) => async (dispatch) => {
	dispatch(creditAssessmentLoading());
	try {
		const response = await axios.post(
			`${baseURL}/functions/creditAssessment`,
			{},
			{
				headers: {
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			}
		);
		const data = await response.data;

		dispatch(creditAssessmentSuccess(data));

		const message = "Credit Assessment Successful";
		const metaData = {
			category: "Lease",
			linkToId: leaseId,
			moreDetails: `You have successfully completed your credit assessment. This is valid for only one month. You will be required to do another after the former expires. Thank you`,
		};

		if (data) {
			createNotification(message, metaData);
		}
	} catch (error) {
		console.log(error);
		if (error?.message?.includes("Unable to connect to the Parse API")) {
			toast.error("Oops! You are not conected to the internet");
			dispatch(creditAssessmentError());
		} else if (
			error?.response?.data?.error.includes(
				"An error occurred processing the bank statement. Pls ensure that the account you logged"
			)
		) {
			toast.error(
				"The bank selected does not match the bank saved on your profile"
			);
			dispatch(creditAssessmentError());
		} else {
			dispatch(creditAssessmentError());
		}
	}
};
