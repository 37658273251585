import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import theme from "../../../components/mui/theme";
// import { IoIosArrowForward } from "react-icons/io";
import BoxImage from "../../../assets/boxImage.svg";
import NairaVector from "../../../assets/nairaVector.png";
import GreenBall from "../../../assets/greenBall.png";
import GreenBalls from "../../../assets/greenBalls.png";
import PropTypes from "prop-types";

function AccessSection() {
	return (
		<>
			<Box
				pt={5}
				pb={5}
				mb={5}
				sx={{ background: theme.palette.primary.main, position: "relative" }}
			>
				<Box sx={{ display: { xs: "none", md: "flex" } }}>
					<img
						src={GreenBalls}
						alt=""
						style={{
							position: "absolute",
							right: "20%",
							top: 0,
						}}
					/>
					<img
						src={NairaVector}
						alt=""
						style={{
							position: "absolute",
							right: 0,
							top: 0,
							display: { xs: "none", md: "flex" },
						}}
					/>
					<img
						src={GreenBall}
						alt=""
						style={{
							position: "absolute",
							right: 0,
							top: "40%",
							display: { xs: "none", md: "flex" },
						}}
					/>
				</Box>
				<Container maxWidth="lg">
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: { xs: "1fr", md: "3fr 1fr" },
						}}
					>
						<Box>
							<Stack width={{ xs: "100%", md: "60%" }} spacing={1}>
								<Typography
									variant="h1"
									component="h2"
									color="primary.light"
									sx={{ fontWeight: 600, fontSize: "1.8rem" }}
								>
									Purchase an asset, rent it out and earn income
								</Typography>
								<Typography
									variant="body1"
									sx={{ color: "rgba(255, 255, 255, 0.50)" }}
								>
									Join our community and let Fortcore match your asset with
									eager leasees.
								</Typography>
								{/* <button
									style={{
										color: "rgba(255, 255, 255, 0.50)",
										width: "172px",
										display: "flex",
										alignItems: "center",
										fontSize: "12px",
										paddingTop: "2px",
									}}
								>
									LEARN MORE <IoIosArrowForward size={20} />
								</button> */}
							</Stack>

							<Box
								mt={5}
								sx={{
									display: "grid",
									gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
									gap: 5,
								}}
							>
								{gridData.map((data, index) => {
									return (
										<GridSec key={index} title={data.title} text={data.text} />
									);
								})}
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	);
}

export default AccessSection;

function GridSec({ title, text }) {
	return (
		<>
			<Stack direction="row" spacing={2} alignItems="flex-start">
				<img src={BoxImage} alt="" />
				<Stack>
					<Typography
						variant="h1"
						component="h3"
						color="primary.light"
						sx={{ fontWeight: 600, fontSize: "1.2rem" }}
					>
						{title}
					</Typography>
					<Typography
						sx={{ color: "rgba(255, 255, 255, 0.60)" }}
						variant="body1"
					>
						{text}
					</Typography>
				</Stack>
			</Stack>
		</>
	);
}

GridSec.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
};
const gridData = [
	{
		title: "High-Level Security",
		text: "We simplify encryption technology that adhere to Industry standards to safeguard user data. Two factor authentication is utilized to validate all customer accounts, instilling confidence in the security of funds.",
	},
	{
		title: "Secure Payments",
		text: "various measures are put in place in protecting sensitive financial information and ensuring that transactions are safe and secure. Fortcore uses secure payment gateways that encrypts customer’s data during transmission, this ensures that the information remains confidential and cannot be intercepted by unauthorized parties.",
	},
	{
		title: "Adequate Insurance Coverage",
		text: "Fortcore has partnered with leading insurance companies to fortify your assets with unparalleled coverage. Rest easy knowing that your valuable assets are now backed by the expertise and reliability of industry leaders in insurance.",
	},
	{
		title: "Democratized Financing",
		text: "At Fortcore, we're breaking down barriers to make alternative finance accessible and exciting for you. contribute, Purchase, and rent,  as we redefine the consumer experience. Dive into a community-driven approach to finance that not only empowers but also ensures prosperity for all.",
	},
];
