import React, { useContext, useEffect } from "react";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import {
	Box,
	Stack,
	Typography,
	Button,
	FilledInput,
	InputAdornment,
} from "@mui/material";
import { Helmet } from "react-helmet";
import Container from "../../../../../layout/Container";
import Navbar from "../../../../../layout/Navbar";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import userContext from "../../../../../context/UserContext";
import { IoWarning } from "react-icons/io5";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getGuarantorsDataFromParse } from "../../../../../redux/authRedux/authServices";
import {
	FatBackTracker,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import BrowseFormContext from "../BrowseFormContext";

function GuarantorRequest() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state } = useLocation();

	const { currentUser } = useSelector((state) => state.auth);

	const { requestFinance, setRequestFinance } = useContext(userContext);
	const { marketPlace, setMarketPlace } = useContext(BrowseFormContext);

	const currentDate = new Date().toLocaleDateString();
	const [signature, setSignature] = useState("");

	const objectId =
		state?.from === "old" ? marketPlace?.objectId : requestFinance?.objectId;

	useEffect(() => {
		if (state?.from === "new") {
			setRequestFinance((requestFinance) => ({
				...requestFinance,
				signature,
			}));
		} else if (state === "old") {
			setMarketPlace((marketPlace) => ({
				...marketPlace,
				signature,
			}));
		}
	}, [signature]);

	useEffect(() => {
		setSignature(`${currentUser?.firstName} ${currentUser?.lastName}`);
	}, [currentUser]);

	useEffect(() => {
		dispatch(getGuarantorsDataFromParse());
	}, []);

	if (!objectId) {
		return <Navigate to="/financing/ongoing-transactions" />;
	}

	return (
		<>
			<MobileNavDetails text="New Transaction" />

			<Container>
				<Helmet>
					<title>Fortcore - New Transaction</title>
				</Helmet>
				<Navbar text="New Transaction" />

				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="hidden sm:flex gap-1.5">
					<UrlTracker
						text="apprenticeship Finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="ongoing transactions"
						onNavigate={() => navigate("/financing/ongoing-transactions")}
					/>
					<UrlTracker text="guarantor" isCurrentUrl />
				</div>
				<div className="sm:hidden">
					<FatBackTracker
						text="guarantor's criteria"
						onNavigate={() => navigate(-1)}
					/>
				</div>
				<div className="mt-6 sm:mt-10"></div>
				<Box
					sx={{ background: "#F6F7F8", padding: "20px", borderRadius: "8px" }}
				>
					<Typography variant="h4" color="primary.main">
						Guarantor Criteria
					</Typography>

					<Box mt={3} mb={3}>
						<Typography variant="body1" color="info.light" paragraph>
							NOTE: the person to be used must be above 18 and must be any of
							the following:
						</Typography>
						<ul>
							<li style={listStyle}>
								<Typography variant="body1" color="info.light" paragraph>
									Senior civil servant not below grade 8 in the Public Service.
								</Typography>
							</li>

							<li style={listStyle}>
								<Typography variant="body1" color="info.light" paragraph>
									A banker not less than Senior Banking Officer
								</Typography>
							</li>

							<li style={listStyle}>
								<Typography variant="body1" color="info.light" paragraph>
									A practicing professional such as Doctor, Pilot, Engineer,
									etc.
								</Typography>
							</li>

							<li style={listStyle}>
								<Typography variant="body1" color="info.light" paragraph>
									A well known religious community leader acceptable to us
								</Typography>
							</li>

							<li style={listStyle}>
								<Typography variant="body1" color="info.light" paragraph>
									A trader registered with the appropriate trade union.
								</Typography>
							</li>

							<li style={listStyle}>
								<Typography variant="body1" color="info.light" paragraph>
									Lawyers or any security personnel are not allowed.
								</Typography>
							</li>
						</ul>

						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							mt={4}
							mb={2}
						>
							<IoWarning color="#f00" size={25} />
							<Typography variant="body1" color="info.light" paragraph>
								CAUTION: Do not use someone you do not know as a guarantor.
							</Typography>
						</Stack>

						<Typography variant="body1" color="info.light" paragraph>
							I HAVE READ THE CERTIFICATION ABOVE AND UNDERSTAND AND AGREE TO
							ITS TERMS.
						</Typography>

						<Typography variant="body1" color="info.light" paragraph>
							Please type your name and the date.
						</Typography>

						<Box mt={7}>
							<FilledInput
								type="text"
								name="signature"
								value={signature}
								onChange={(e) => setSignature(e.target.value)}
								placeholder="John Doe"
								disabled
								endAdornment={
									<InputAdornment position="end">{currentDate}</InputAdornment>
								}
								sx={{
									backgroundColor: "transparent",
									"&:hover, &:focus": {
										backgroundColor: "transparent",
									},
								}}
							/>
							<Typography variant="body1" color="info.light">
								[Guarantor&apos;s E-Signature]
							</Typography>
						</Box>

						<Box display="flex" justifyContent="flex-end" mt={5} mb={7}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (signature !== "") {
										navigate(
											"/financing/new-transaction/request/guarantor/form",
											{ state }
										);
									} else {
										toast.error("Signature required");
									}
								}}
								// disabled={!requestFinance.signature}
							>
								Proceed
							</Button>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default GuarantorRequest;

const listStyle = {
	listStyle: "decimal",
	marginLeft: "20px",
};
