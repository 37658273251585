import { styled } from "styled-components";

export const OnboardStyle = styled.div`
  overflow-x: hidden;
  padding-top: 2vh;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  align-items: center;
  height: 100vh;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    height: 100%;

    padding-bottom: 50px;
  }

  .left {
    background: #f9fdff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      padding: 0 20px;
      padding-top: 140px;
      padding-bottom: 40px;

      img {
        width: 80%;
      }
    }
  }

  .right {
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 10%;
    row-gap: 20px;

    form {
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 100%;

      input {
        border: 1px solid #96a0a5;
        border-radius: 8px;
        background: none;
        height: 50px;
        width: 100%;
        max-width: 370px;
        padding: 10px;

        &::placeholder {
          color: #aabdc5;
        }
        &:focus {
          outline: none;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 20px;
    }
  }
  .loginContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .login {
    border: 1px solid #eef2f3;
    border-radius: 12px;
    padding: 20px 10px;
    width: 90%;
    max-width: 530px;
    min-height: 465px;
    
    

    @media (max-width: 480px) {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
`;
