import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft, FaCalendarAlt } from "react-icons/fa";
import { MdLock } from "react-icons/md";
import { PlainSelect, SelectDropDown } from "../../../layout/CustomComponents";
import { CustomGreenBtn, DayButton } from "../../../layout/CustomButtons";
import { expectedDuration } from "../requestlease/LeaseStructure";
import { saveRegularSavingsForm } from "../../../redux/lease/leaseActions";
import {
	getSavingsSource,
	getSingularOrPlural,
	onBlurAmount,
	onInputAmount,
	travelPurpose,
} from "../../../common/utils";
import {
	DualLeaseFormSelect,
	LeaseFormSelect,
} from "../requestlease/LeaseForm";

const SavingsForm = ({ setActiveTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { regularSavingsForm, savingsType, currentGeneratedinterest } =
		useSelector((state) => state.lease);

	const [toggleFrequency, setToggleFrequency] = useState(false);
	const [toggleDuration, setToggleDuration] = useState(false);
	const [toggleLength, setToggleLength] = useState(false);
	const [toggleTravelPurpose, setToggleTravelPurpose] = useState(false);

	const handleTravelPurpose = () => {
		setToggleLength(false);
		setToggleDuration(false);
		setToggleTravelPurpose(!toggleTravelPurpose);
	};

	const [savingsForm, setSavingsForm] = useState({
		amount_to_save: regularSavingsForm?.amountToSave || "",
		frequency: regularSavingsForm?.frequency || "",
		day_of_the_month: regularSavingsForm?.dayOfTheMonth || "",
		interest:
			regularSavingsForm?.interest ||
			currentGeneratedinterest?.value?.replace("%", "") ||
			"",
		duration: regularSavingsForm?.tenure?.split(" ")[1] || "",
		length: regularSavingsForm?.tenure?.split(" ")[0] || "",
		purpose: regularSavingsForm?.purpose || "",
	});

	const {
		amount_to_save,
		frequency,
		day_of_the_month,
		interest,
		duration,
		length,
		purpose,
	} = savingsForm;

	useEffect(() => {
		if (currentGeneratedinterest) {
			setSavingsForm((prev) => ({
				...prev,
				interest: currentGeneratedinterest?.value?.replace("%", ""),
			}));
		}
	}, [currentGeneratedinterest]);

	const [calendar, setCalendar] = useState({
		toggleCalendar: false,
		currentDay: null,
	});
	const { toggleCalendar, currentDay } = calendar;

	const onChooseDay = (day) => {
		setCalendar((prev) => ({ ...prev, currentDay: day }));
	};

	const onSelectDay = () => {
		setSavingsForm((prev) => ({ ...prev, day_of_the_month: currentDay }));
		setCalendar((prev) => ({ ...prev, toggleCalendar: false }));
	};

	const savingsTenure = () => {
		if (frequency === "" || frequency === "Daily") {
			return expectedDuration;
		} else if (frequency === "Weekly" || frequency === "Bi-Weekly") {
			return expectedDuration.filter((d) => d.duration !== "Days");
		} else if (frequency === "Monthly") {
			const a = ["Months", "Years"];
			return expectedDuration.filter((d) => a?.includes(d.duration));
		}
	};

	useEffect(() => {
		if (toggleCalendar) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [toggleCalendar]);

	const savingsSource =
		savingsType || getSavingsSource(pathname.split("/").filter((a) => a)[0]);

	const isProceedBtnValid = () => {
		if (
			amount_to_save === "" ||
			frequency === "" ||
			length === "" ||
			duration === "" ||
			(savingsSource === "travel" && purpose === "")
		) {
			return true;
		}
		if (frequency === "Monthly" && day_of_the_month === "") {
			return true;
		} else {
			return false;
		}
	};

	const onProceedToNextTab = () => {
		const data = {
			amountToSave: amount_to_save,
			interestRate: interest,
			frequency,
			purpose,
			dayOfTheMonth: frequency === "Monthly" ? day_of_the_month : "",
			tenure: `${length} ${getSingularOrPlural(length, duration)}`,
			source: savingsSource,
			automation: regularSavingsForm?.automation || "manual",
			amountSaved: "0",
		};

		dispatch(saveRegularSavingsForm(data));
		setActiveTab(2);
	};

	return (
		<>
			{toggleCalendar && (
				<div
					className="fixed z-[13] inset-0 bg-[#393F4266] grid place-items-center"
					onClick={() => setCalendar(false)}
				>
					<DayButton
						onCancelBtnClick={() =>
							setCalendar((prev) => ({ ...prev, toggleCalendar: false }))
						}
						onChooseDay={onChooseDay}
						currentDay={currentDay}
						onOkBtnClick={onSelectDay}
					/>
				</div>
			)}
			<div className="rounded-md p-4 sm:p-5 bg-[#F6F7F8] space-y-4 sm:space-y-5 mb-8">
				<div className="flex items-center gap-2 text-fortcore-green">
					<button className="hidden sm:block" onClick={() => navigate(-1)}>
						<FaArrowLeft size={15} />
					</button>
					<p className="text-sm md:text-base chillaxFont font-semibold">
						Regular Savings
					</p>
				</div>
				<div className="flex flex-col sm:items-center sm:flex-row gap-3 sm:gap-4 text-xs md:text-[13px]">
					<p className="text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[28%] md:w-[25%] lg:w-1/5">
						Amount to save:
					</p>
					<div className="relative h-[45px] zabalRegFont w-full sm:w-[72%] md:w-[75%] lg:w-4/5">
						<span className=" text-[#393F42] absolute zabalMedFont left-3 h-full w-[30px] grid place-items-center">
							NGN
						</span>
						<input
							type="text"
							className="text-sm h-full w-full pl-[45px] pr-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
							placeholder="enter amount to save"
							name="amount_to_save"
							value={amount_to_save}
							onChange={(e) =>
								setSavingsForm((prev) => ({
									...prev,
									amount_to_save: e.target.value,
								}))
							}
							onInput={onInputAmount}
							onBlur={(e) => onBlurAmount(e, amount_to_save)}
						/>
					</div>
				</div>
				<div className="flex flex-col sm:items-center sm:flex-row gap-2 sm:gap-4 text-xs md:text-[13px]">
					<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[28%] md:w-[25%] lg:w-1/5">
						Frequency :
					</p>
					<div className="w-full sm:w-[72%] md:w-[75%] lg:w-4/5 relative">
						<PlainSelect
							context="choose saving frequency"
							isOpened={toggleFrequency}
							currentText={frequency}
							handleToggle={() => {
								setToggleFrequency((prev) => !prev);
								setToggleDuration(false);
								setToggleLength(false);
							}}
						/>
						{toggleFrequency && (
							<div className="absolute z-[2] w-full top-48px">
								<SelectDropDown
									arrOptions={["Daily", "Weekly", "Bi-Weekly", "Monthly"]}
									onHandleSelect={(d) => {
										setSavingsForm((prev) => ({ ...prev, frequency: d }));
										setTimeout(() => {
											setToggleFrequency(false);
										}, 100);
									}}
								/>
							</div>
						)}
					</div>
				</div>
				{frequency === "Monthly" && (
					<div className="flex flex-col sm:items-center sm:flex-row gap-2 sm:gap-5 md:gap-6 text-xs md:text-[13px]">
						<p className="text-[#393F42] text-xs md:text-[13px] break-normal zabalMedFont md:w-4/12">
							What day of the month works for you? :
						</p>
						<div className="relative w-full md:w-8/12 text-sm h-[45px] flex items-center px-2.5 rounded border border-[#B8B8B8] relative">
							<p
								className={`zabalRegFont text-sm ${
									day_of_the_month === "" ? "text-[#B8B8B8]" : "text-[#393F42]"
								}`}
							>
								{day_of_the_month === ""
									? "Choose a day for payment"
									: day_of_the_month === "last day"
									? "last day"
									: `Day ${day_of_the_month}`}
							</p>
							<button
								className="absolute right-2.5 h-full grid place-items-center"
								onClick={() =>
									setCalendar((prev) => ({
										...prev,
										toggleCalendar: true,
									}))
								}
							>
								<FaCalendarAlt size={16} color="#00525D" />
							</button>
						</div>
					</div>
				)}
				{frequency !== "" && (
					<>
						<DualLeaseFormSelect
							type="savings"
							label="Savings Tenure"
							dropDownOne={{
								context: "duration",
								currentText: duration,
								isOpened: toggleDuration,
								handleToggle: () => setToggleDuration((prev) => !prev),
								arrOptions: savingsTenure()?.map((a) => a.duration),
							}}
							handleSelectOne={(d) => {
								setSavingsForm((prev) => ({ ...prev, duration: d }));
								setTimeout(() => {
									setToggleDuration(false);
								}, 100);
							}}
							dropDownTwo={{
								context: "length",
								currentText: length,
								isOpened: toggleLength,
								handleToggle: () => {
									if (!duration) return;
									setToggleLength((prev) => !prev);
								},
								arrOptions: savingsTenure()
									?.find((a) => a.duration === duration)
									?.length.map((a) => String(a)),
							}}
							handleSelectTwo={(d) => {
								setSavingsForm((prev) => ({
									...prev,
									length: String(d),
								}));
								setTimeout(() => {
									setToggleLength(false);
								}, 100);
							}}
						/>
						<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
							<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[28%] md:w-[25%] lg:w-1/5 capitalize">
								Interest Rate (%) :
							</p>
							<div className="h-[45px] w-full sm:w-[72%] md:w-[75%] lg:w-4/5 relative zabalMedFont rounded border border-[#B8B8B8] text-[#393F42] flex gap-1.5 items-center px-2.5">
								<span className="">%</span>
								<span>{interest}</span>
								<span className="text-[#393F42] text-sm">P/A</span>
								<span className="absolute right-3">
									<MdLock size={18} color="#717E83" />
								</span>
							</div>
						</div>
						{console.log(savingsSource)}
						{savingsSource === "lease" ? (
							<div className="flex flex-col sm:items-center sm:flex-row gap-3 sm:gap-4 text-xs md:text-[13px]">
								<p className="text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[28%] md:w-[25%] lg:w-1/5">
									Purpose <span className="zabalRegFont">(optional)</span> :
								</p>
								<div className="relative h-[45px] zabalRegFont w-full sm:w-[72%] md:w-[75%] lg:w-4/5">
									<input
										type="text"
										className="text-sm h-full w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
										placeholder="e.g schooling"
										name="purpose"
										value={purpose}
										onChange={(e) =>
											setSavingsForm((prev) => ({
												...prev,
												purpose: e.target.value,
											}))
										}
									/>
								</div>
							</div>
						) : (
							<LeaseFormSelect
								type="savings"
								text="travel purpose"
								currentText={purpose}
								arrOptions={travelPurpose}
								isOpened={toggleTravelPurpose}
								handleToggle={handleTravelPurpose}
								onHandleSelect={(d) => {
									setSavingsForm((prev) => ({ ...prev, purpose: d }));
									setTimeout(() => {
										setToggleTravelPurpose(false);
									}, 100);
								}}
							/>
						)}
					</>
				)}
				<div className="flex flex-col gap-2.5 pt-4 sm:hidden">
					<CustomGreenBtn
						text="proceed"
						onRequestClick={onProceedToNextTab}
						isDisabled={isProceedBtnValid()}
					/>
				</div>
				<div className="hidden sm:flex justify-end gap-2.5">
					<CustomGreenBtn
						width="105px"
						height="42px"
						fontSize="13px"
						text="proceed"
						onRequestClick={onProceedToNextTab}
						isDisabled={isProceedBtnValid()}
					/>
				</div>
			</div>
		</>
	);
};
export default SavingsForm;

SavingsForm.propTypes = {
	setActiveTab: PropTypes.func,
};
