import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00525D",
      light: "#FFFFFF",
      dark: "#003A50",
    },
    secondary: {
      main: "#FEFEFE",
      light: "#788084",
      dark: "#717E83",
    },
    info: {
      main: "#F6F7F8",
      light: "#393F42",
      dark: "#96a0a525",
    },
    textPrimary: {
      main: "#003A50",
      dark: "#555F63",
    },
    textSecondary: {
      main: "#393F42",
    },
    notify: {
      main: "#FF4A4A",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontFamily: "Chillax",
    },
    h2: {
      fontSize: "20px",
      fontFamily: "Chillax",
    },
    h3: {
      fontSize: "18px",
      fontFamily: "ZabalMedium",
    },
    h4: {
      fontSize: "16px",
      fontFamily: "Chillax",
    },
    h5: {
      fontSize: "14px",
      fontFamily: "ZabalMedium",
    },
    body1: {
      fontSize: "14px",
      fontFamily: "ZabalRegular",
    },
    caption: {
      fontSize: "12px",
      fontFamily: "ZabalRegular",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          minWidth: "90px",
          minHeight: "50px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            background: "#00525D",
            color: "#fff",
          },
        },
      },
    },
  },
});

export default theme;
