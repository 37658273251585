import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";
import SavingsForm from "../../leasing/regularsavings/SavingsForm";
import SavingsSummary from "../../leasing/regularsavings/SavingsSummary";
import {
	createRegularSavings,
	fetchCurrentInterest,
} from "../../../redux/lease/leaseServices";

const TravelAdvanceSavingsCreate = () => {
	const [activeTab, setActiveTab] = useState(1);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const { deductWalletSuccess } = useSelector((state) => state.payment);
	const { regularSavingsForm, payment } = useSelector((state) => state.lease);

	useEffect(() => {
		dispatch(
			fetchCurrentInterest("Base Interest rate (Savings) Travel Advance")
		);
	}, []);

	const data = {
		...regularSavingsForm,
		paymentMode: payment?.method,
		savingsType: "regular",
	};

	return (
		<>
			<MobileNavDetails text="travels savings" />
			<Helmet>
				<title>Fortcore - Regular Savings</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="travel advance" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<BackTracker text="regular savings" onNavigate={() => navigate(-1)} />
					<div className="flex gap-1.5">
						<UrlTracker
							text="travel advance"
							onNavigate={() => navigate("/travels")}
						/>
						<UrlTracker text="regular savings" isCurrentUrl />
					</div>
					<div className="sm:mt-6"></div>
					{activeTab === 1 && <SavingsForm setActiveTab={setActiveTab} />}
					{activeTab === 2 && (
						<SavingsSummary
							setActiveTab={setActiveTab}
							savingsUrl={"/travels/regular-savings"}
							onSubmitSavings={() => dispatch(createRegularSavings(data))}
						/>
					)}
				</>
			</Container>
		</>
	);
};

export default TravelAdvanceSavingsCreate;
