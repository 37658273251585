import React from "react";
import PropTypes from "prop-types";
import {
	AgricultureImage,
	AutomotiveImage,
	ConstructionImage,
	ConsultingImage,
	ECommerceImage,
	EducationImage,
	FashionImage,
	FinancialImage,
	HealthcareImage,
	HospitalityImage,
	ManufacturingImage,
	MediaImage,
	RestaurantImage,
	RetailImage,
	TechnologyImage,
	TransportationImage,
} from "../../images/ImagesAf";
import { Link } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import Parse from "../../../../api/ApiConfig";

export const ActiveCreatedFinance = ({ financeReq }) => {
	const user = Parse.User.current();

	const investorInfo = financeReq?.financeReq?.investorsInfo?.find(
		(investor) => investor?.investorId === user?.id
	);

	const businessType = financeReq?.businessType?.toLowerCase();

	const getImgUrl = () => {
		if (financeReq?.productPhotos) {
			return financeReq?.productPhotos[0]?.url;
		} else if (businessType?.includes("restaurant")) {
			return RestaurantImage;
		} else if (businessType?.includes("retail")) {
			return RetailImage;
		} else if (businessType?.includes("technology")) {
			return TechnologyImage;
		} else if (businessType?.includes("consulting")) {
			return ConsultingImage;
		} else if (businessType?.includes("construction")) {
			return ConstructionImage;
		} else if (businessType?.includes("healthcare")) {
			return HealthcareImage;
		} else if (businessType?.includes("e-commerce")) {
			return ECommerceImage;
		} else if (businessType?.includes("manufacturing")) {
			return ManufacturingImage;
		} else if (businessType?.includes("transportation")) {
			return TransportationImage;
		} else if (businessType?.includes("hospitality")) {
			return HospitalityImage;
		} else if (businessType?.includes("finance")) {
			return FinancialImage;
		} else if (businessType?.includes("education")) {
			return EducationImage;
		} else if (businessType?.includes("media")) {
			return MediaImage;
		} else if (businessType?.includes("agriculture")) {
			return AgricultureImage;
		} else if (businessType?.includes("fashion")) {
			return FashionImage;
		} else if (businessType?.includes("automotive")) {
			return AutomotiveImage;
		}
	};

	return (
		<div
			className={`rounded-md px-3 py-3.5 sm:px-3.5 sm:py-4 border flex gap-3.5 md:gap-4 min-w-[340px] sm:min-w-[355px] md:min-w-[365px] relative border-[#EEF2F3] bg-[#EFF8F9] indexBalance`}
		>
			<div className={`w-[calc(100%-92px)] grid gap-14`}>
				<div className="relative">
					<p className="relative capitalize text-[#222627] text-[13px] sm:text-[13.5px] font-semibold zabalMedFont">
						{financeReq?.businessType}
					</p>
					<Link
						to={`/financing/investments/${financeReq?.objectId}`}
						className="absolute mt-1 sm:mt-1.5 border-b-2 flex gap-0.5 items-center text-xs text-primary-color zabalMedFont tracking-wider blink"
						state={{ isCreatedReq: financeReq?.investorInfo }}
					>
						<span>View</span> <FaArrowCircleRight size={14} />
					</Link>
				</div>
				<div className="self-end flex gap-2.5 lg:gap-4 zabalMedFont">
					<p className="w-[52%] text-[#393F42] flex flex-col gap-1">
						<span className="text-[11px]">Business Value</span>
						<span className="text-xs sm:text-[12.5px]">
							<span className="font-semibold">NGN </span>
							{financeReq?.amountToInvest || financeReq?.amountToRaise}
						</span>
					</p>
					<p className="w-[48%] text-[#393F42] flex flex-col gap-1">
						<span className="text-[11px]">Principal</span>
						<span className="text-xs sm:text-[12.5px]">
							<span className="font-semibold">NGN </span>
							{investorInfo?.amountPaid ||
								financeReq?.amountToInvest ||
								financeReq?.amount}
						</span>
					</p>
				</div>
			</div>
			<div className="w-[92px] flex flex-col justify-between items-end zabalMedFont">
				<img src={getImgUrl()} alt="asset-logo" className="w-auto h-[55px]" />
				<p className="text-[#393F42] flex flex-col gap-1 w-full">
					<span className="text-[11px]">Interest</span>
					<span className="text-xs sm:text-[12.5px]">
						<span className="font-semibold">NGN </span>
						{financeReq?.interest}
					</span>
				</p>
			</div>
		</div>
	);
};

ActiveCreatedFinance.propTypes = {
	financeReq: PropTypes.object,
};
