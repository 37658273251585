import React, { useEffect, useState } from "react";
import Container from "../../../../layout/Container";
import { MobileNavDetails } from "../../../../layout/MobileNavbar";
import { Helmet } from "react-helmet";
import Navbar from "../../../../layout/Navbar";
import {
	Box,
	Button,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Balance from "../../../../components/Balance";
import theme from "../../../../components/mui/theme";
import Parse from "../../../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiError } from "react-icons/bi";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";

const breadcrumbItems = [
	{ label: "Apprentice Financing", href: "/financing" },
	{ label: "Risk Free Investments" },
];

function AFRiskFreeInvestment() {
	const [table, setTable] = useState([]);
	const [loadingData, setLoadingData] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		const fetchInvestment = async () => {
			try {
				const currentUser = Parse.User.current();

				if (currentUser) {
					const query = new Parse.Query("AFRiskFreeInvestment");
					query.equalTo("user", currentUser.toPointer());

					const results = await query.find();

					const investmentData = results.map((result) => result.toJSON());

					setTable(investmentData);
					setLoadingData(false);
					// eslint-disable-next-line no-undef
					console.log(investmentData);
				} else {
					Parse.User.logOut().then(() => navigate("/login"));
				}
			} catch (error) {
				// eslint-disable-next-line no-undef
				console.log(error);
				toast.error(error.message);
			}
		};
		fetchInvestment();
	}, []);
	return (
		<>
			<MobileNavDetails text="Risk Free Investments" />
			<Container>
				<Helmet>
					<title>Fortcore - Risk Free Investments</title>
				</Helmet>
				<Navbar text="Risk Free Investments" />

				<Box sx={{ paddingTop: { xs: "5px", sm: "50px" } }}>
					<Box mt={-2} mb={2}>
						<Breadcrumb items={breadcrumbItems} />
					</Box>
					<Stack
						direction={{ sm: "row" }}
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={{ xs: 4, sm: 0 }}>
						{/* <WalletCard amount={wallet.balance} /> */}
						<Box width="100%">
							<Balance />
						</Box>

						<Button
							variant="contained"
							color="primary"
							sx={{ minWidth: { xs: "100%", sm: "120px" } }}
							// onClick={() => setAddAmount(true)}
						>
							Fund Wallet
						</Button>
					</Stack>
				</Box>

				{/* Table  */}

				<Box
					mt={10}
					sx={{
						background: theme.palette.info.dark,
						borderRadius: "10px",
						p: 1,
					}}>
					<TableContainer>
						<Table
						// sx={{ minWidth: 650 }}
						>
							{table.length > 0 && (
								<TableHead>
									<TableRow>
										<TableCell>Transaction ID</TableCell>
										<TableCell>Principal</TableCell>
										<TableCell>Interest</TableCell>
										<TableCell>Tenure</TableCell>
										<TableCell>Status</TableCell>
									</TableRow>
								</TableHead>
							)}

							{loadingData ? (
								<TableBody>
									{tableLoad.map((data, index) => {
										return (
											<TableRow key={index}>
												<TableCell sx={rowStyle}>
													<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
												</TableCell>
												<TableCell sx={rowStyle}>
													<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
												</TableCell>
												<TableCell sx={rowStyle}>
													<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
												</TableCell>
												<TableCell sx={rowStyle}>
													<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
												</TableCell>
												<TableCell sx={rowStyle}>
													<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							) : (
								<>
									{table.length > 0 ? (
										<TableBody>
											{table.map((row, index) => {
												const amount =
													parseFloat(row.amount.replace(/,/g, "")) +
													parseFloat(row.interest.replace(/,/g, ""));
												return (
													<TableRow key={index}>
														<TableCell sx={rowStyle2}>{row.objectId}</TableCell>
														<TableCell sx={rowStyle}>
															{/* NGN&nbsp;{Number(row.principal).toLocaleString()} */}
															NGN&nbsp;{amount.toLocaleString()}
														</TableCell>
														<TableCell sx={rowStyle}>
															{/* NGN&nbsp;{Number(row.interest).toLocaleString()} */}
															NGN&nbsp;{row.interest}
														</TableCell>
														<TableCell sx={rowStyle}>{row.duration}</TableCell>
														<TableCell sx={rowStyle}>{row.status}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									) : (
										<Stack
											direction="column"
											justifyContent="center"
											alignItems="center"
											spacing={1}>
											<BiError size={40} color={theme.palette.error.main} />
											<Typography variant="body1" color="primary">
												No Risk Free Investment
											</Typography>
										</Stack>
									)}
								</>
							)}
						</Table>
					</TableContainer>
				</Box>
			</Container>
		</>
	);
}

export default AFRiskFreeInvestment;

const tableLoad = [
	{
		transactionId: "D682HHOGO8F",
		principal: "10000000",
		interest: "1000000",
		tenure: "24 months",
		status: "open",
	},
	{
		transactionId: "D682HHOGO8F",
		principal: "10000000",
		interest: "1000000",
		tenure: "24 months",
		status: "open",
	},
	{
		transactionId: "D682HHOGO8F",
		principal: "10000000",
		interest: "1000000",
		tenure: "24 months",
		status: "open",
	},
	{
		transactionId: "D682HHOGO8F",
		principal: "10000000",
		interest: "1000000",
		tenure: "24 months",
		status: "open",
	},
	{
		transactionId: "D682HHOGO8F",
		principal: "10000000",
		interest: "1000000",
		tenure: "24 months",
		status: "open",
	},
];

const rowStyle = {
	textTransform: "capitalize",
	border: "none",
};
const rowStyle2 = {
	textTransform: "uppercase",
	border: "none",
};
