export const SAVE_PERSONAL_INFO_LOADING = "SAVE_PERSONAL_INFO_LOADING";
export const SAVE_PERSONAL_INFO_SUCCESS = "SAVE_PERSONAL_INFO_SUCCESS";
export const SAVE_PERSONAL_INFO_ERROR = "SAVE_PERSONAL_INFO_ERROR";
export const SAVE_CONTACT_INFO_LOADING = "SAVE_CONTACT_INFO_LOADING";
export const SAVE_CONTACT_INFO_SUCCESS = "SAVE_CONTACT_INFO_SUCCESS";
export const SAVE_CONTACT_INFO_ERROR = "SAVE_CONTACT_INFO_ERROR";
export const SAVE_PROFBUSINESS_INFO_LOADING = "SAVE_PROFBUSINESS_INFO_LOADING";
export const SAVE_PROFBUSINESS_INFO_SUCCESS = "SAVE_PROFBUSINESS_INFO_SUCCESS";
export const SAVE_PROFBUSINESS_INFO_ERROR = "SAVE_PROFBUSINESS_INFO_ERROR";
export const SAVE_EDUCATION_INFO_LOADING = "SAVE_EDUCATION_INFO_LOADING";
export const SAVE_EDUCATION_INFO_SUCCESS = "SAVE_EDUCATION_INFO_SUCCESS";
export const SAVE_EDUCATION_INFO_ERROR = "SAVE_EDUCATION_INFO_ERROR";
export const SAVE_FAMILY_INFO_LOADING = "SAVE_FAMILY_INFO_LOADING";
export const SAVE_FAMILY_INFO_SUCCESS = "SAVE_FAMILY_INFO_SUCCESS";
export const SAVE_FAMILY_INFO_ERROR = "SAVE_FAMILY_INFO_ERROR";
export const SAVE_TRAVEL_INFO_LOADING = "SAVE_TRAVEL_INFO_LOADING";
export const SAVE_TRAVEL_INFO_SUCCESS = "SAVE_TRAVEL_INFO_SUCCESS";
export const SAVE_TRAVEL_INFO_ERROR = "SAVE_TRAVEL_INFO_ERROR";
export const SAVE_ACCOUNT_INFO_LOADING = "SAVE_ACCOUNT_INFO_LOADING";
export const SAVE_ACCOUNT_INFO_SUCCESS = "SAVE_ACCOUNT_INFO_SUCCESS";
export const SAVE_ACCOUNT_INFO_ERROR = "SAVE_ACCOUNT_INFO_ERROR";
export const SAVE_REQUEST_INFO_LOADING = "SAVE_REQUEST_INFO_LOADING";
export const SAVE_REQUEST_INFO_SUCCESS = "SAVE_REQUEST_INFO_SUCCESS";
export const SAVE_REQUEST_INFO_ERROR = "SAVE_REQUEST_INFO_ERROR";
export const SAVE_FINANCIAL_INFO_LOADING = "SAVE_FINANCIAL_INFO_LOADING";
export const SAVE_FINANCIAL_INFO_SUCCESS = "SAVE_FINANCIAL_INFO_SUCCESS";
export const SAVE_FINANCIAL_INFO_ERROR = "SAVE_FINANCIAL_INFO_ERROR";
export const SAVE_DOCUMENTS_INFO_LOADING = "SAVE_DOCUMENTS_INFO_LOADING";
export const SAVE_DOCUMENTS_INFO_SUCCESS = "SAVE_DOCUMENTS_INFO_SUCCESS";
export const SAVE_DOCUMENTS_INFO_ERROR = "SAVE_DOCUMENTS_INFO_ERROR";
export const FETCH_TRAVEL_SAVINGS_LOADING = "FETCH_TRAVEL_SAVINGS_LOADING";
export const FETCH_TRAVEL_SAVINGS_SUCCESS = "FETCH_TRAVEL_SAVINGS_SUCCESS";
export const FETCH_TRAVEL_SAVINGS_ERROR = "FETCH_TRAVEL_SAVINGS_ERROR";
export const SAVE_TRAVEL_INFO_TO_LOCAL = "SAVE_TRAVEL_INFO_TO_LOCAL";
export const CREATE_NEW_APPLICATION_LOADING = "CREATE_NEW_APPLICATION_LOADING";
export const CREATE_NEW_APPLICATION_SUCCESS = "CREATE_NEW_APPLICATION_SUCCESS";
export const CREATE_NEW_APPLICATION_ERROR = "CREATE_NEW_APPLICATION_ERROR";
export const CLEAR_APPLICATION_SUCCESS = "CLEAR_APPLICATION_SUCCESS";
export const FETCH_SINGLE_REGULAR_SAVINGS_LOADING =
	"FETCH_SINGLE_REGULAR_SAVINGS_LOADING";
export const FETCH_SINGLE_REGULAR_SAVINGS_SUCCESS =
	"FETCH_SINGLE_REGULAR_SAVINGS_SUCCESS";
export const FETCH_SINGLE_REGULAR_SAVINGS_ERROR =
	"FETCH_SINGLE_REGULAR_SAVINGS_ERROR";
export const OPEN_REGULAR_SAVINGS_ADD_FUNDS_MODAL =
	"OPEN_REGULAR_SAVINGS_ADD_FUNDS_MODAL";
export const CLOSE_REGULAR_SAVINGS_ADD_FUNDS_MODAL =
	"CLOSE_REGULAR_SAVINGS_ADD_FUNDS_MODAL";
export const UPDATE_REGULAR_SAVINGS = "UPDATE_REGULAR_SAVINGS";
export const CLEAR_UPDATE_REGULAR_SAVINGS = "CLEAR_UPDATE_REGULAR_SAVINGS";
