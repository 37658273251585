import { styled } from "styled-components";

export const Right = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 10%;
  row-gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: ${(props) => (props.padding ? "75vh" : "")};
  padding-bottom: ${(props) => (props.padding ? "100px" : "")};
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
  .back {
    width: 92px;
    height: 38px;
  }
  .steps {
    color: #00525d;
    letter-spacing: 0.25px;
    font-family: "Zabal DEMO", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  .check {
    display: flex;
    align-items: center;

    input {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }
    p {
      color: #5a6063;
    }
    a {
      color: #00525d;
      text-decoration: underline;
    }
  }

  .resend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    margin-bottom: 30px;

    border: 1px solid #d5dee2;
    border-radius: 6px;
    background: #eef2f3;

    .icon {
      cursor: pointer;
    }

    p {
      color: #00525d;
      font-size: 14px;
    }
  }
  hr {
    width: 100%;
    border: 0.8px solid #eef0f1;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    a {
      text-decoration: none;
      color: #00525d;
      font-weight: 700;
    }
  }
  .link {
    color: #8e9ea4;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.25px;
  }
`;

export const FormStyle = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .inactive {
    opacity: 0.3;
  }
  .active {
    opacity: 1;
  }
  .password-input {
    position: relative;
    width: 100%;
  }

  label {
    font-size: 14px;
  }
  input {
    border: 1px solid #96a0a5;
    border-radius: 8px;
    background: none;
    height: 50px;
    width: 100%;
    padding: 10px;
    margin: 10px 0;

    &::placeholder {
      color: #aabdc5;
    }
    &:focus {
      outline: none;
    }
  }

  .phone {
    margin: 10px 0;
  }
  .password-icon {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    font-size: 20px;
  }

  .forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rememberMe {
      display: flex;
      align-items: center;

      input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }

      p {
        color: #717e83;
        font-size: 12px;
      }
    }

    a {
      font-weight: 500;
      font-size: 14px;
      color: #00525d;
    }
  }

  .check {
    display: flex;
    align-items: center;
    p {
      font-size: 13px;
    }
  }
`;

export const Layout = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(57, 63, 66, 0.4);
  backdrop-filter: blur(2px);
  overflow: hidden;
  z-index: 30;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentBox = styled.div`
  background: #ffffff;
  border-radius: 24px;
  width: 90%;
  max-width: 396px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
`;
