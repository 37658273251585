import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { ProfileInput } from "../../../profile/sections/PersonalInfo";
import {
	PlainSelect,
	SelectDropDown,
} from "../../../../layout/CustomComponents";
import { stateAndLga } from "../../../../common/utils";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import Parse from "../../../../api/ApiConfig";
import { useDispatch, useSelector } from "react-redux";
import { savePersonalInformation } from "../../../../redux/travels/travelServices";

const PersonalInformation = () => {
	const dispatch = useDispatch();
	const { currentUser } = useSelector((state) => state.auth);
	const { personalInfoLoading, personalInfoSuccess } = useSelector(
		(state) => state.travel
	);

	const [personalInfo, setPersonalInfo] = useState({
		full_name:
			personalInfoSuccess?.fullName ||
			`${currentUser?.firstName} ${currentUser?.lastName}` ||
			"",
		home_address:
			personalInfoSuccess?.homeAddress || currentUser?.homeAddress || "",
		state_of_origin: personalInfoSuccess?.stateOfOrigin || "",
		local_govt: personalInfoSuccess?.LGA || "",
		gender: personalInfoSuccess?.gender || currentUser?.gender || "",
		marital_status: personalInfoSuccess?.maritalStatus || "",
		date_of_birth: personalInfoSuccess?.dob || currentUser?.dateOfBirth || "",
		religion: personalInfoSuccess?.religion || "",
	});

	const handleChange = (e) =>
		setPersonalInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const {
		full_name,
		home_address,
		state_of_origin,
		local_govt,
		gender,
		marital_status,
		date_of_birth,
		religion,
	} = personalInfo;

	const [formsToggler, setFormsToggler] = useState([
		{ id: 0, toggleForm: false },
		{ id: 1, toggleForm: false },
		{ id: 2, toggleForm: false },
		{ id: 3, toggleForm: false },
		{ id: 4, toggleForm: false },
	]);

	const toggleModal = (id) => {
		setFormsToggler(
			formsToggler.map((a) =>
				a.id === id
					? { ...a, toggleForm: !a.toggleForm }
					: { ...a, toggleForm: false }
			)
		);
	};

	const handleToggleLGA = () => {
		if (!state_of_origin) {
			toast.error("Please select a state");
			return;
		}
		toggleModal(1);
	};

	const isBtnDisabled = () => {
		if (
			full_name === "" ||
			home_address === "" ||
			state_of_origin === "" ||
			local_govt === "" ||
			gender === "" ||
			marital_status === "" ||
			date_of_birth === "" ||
			religion === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onProfileInfoSave = () => {
		const data = {
			gender,
			maritalStatus: marital_status,
			stateOfOrigin: state_of_origin,
			LGA: local_govt,
			userId: user,
			homeAddress: home_address,
			dob: date_of_birth,
			fullName: full_name,
			religion,
		};
		dispatch(savePersonalInformation(data));
	};

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<ProfileInput
				type="text"
				text="Full Name"
				value={full_name}
				id="full_name"
				placeholderText="Enter full name"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="Home Address"
				value={home_address}
				id="home_address"
				placeholderText="Enter home address"
				handleChange={handleChange}
			/>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 relative z-[1]">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					State of Origin:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					<PlainSelect
						context="choose state of origin"
						isOpened={formsToggler[0].toggleForm}
						currentText={state_of_origin}
						handleToggle={() => toggleModal(0)}
					/>
					{formsToggler[0].toggleForm && (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								arrOptions={stateAndLga?.map((d) => d.state).sort()}
								onHandleSelect={(d) => {
									setPersonalInfo((prev) => ({
										...prev,
										state_of_origin: d,
										local_govt: "",
									}));
									setTimeout(() => {
										toggleModal(0);
									}, 100);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					LGA:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					<PlainSelect
						context="select local government"
						isOpened={formsToggler[1].toggleForm}
						currentText={local_govt}
						handleToggle={handleToggleLGA}
					/>
					{formsToggler[1].toggleForm && (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								arrOptions={stateAndLga
									?.find((d) => d.state === state_of_origin)
									?.lgas.sort()}
								onHandleSelect={(d) => {
									setPersonalInfo((prev) => ({
										...prev,
										local_govt: d,
									}));
									setTimeout(() => {
										toggleModal(1);
									}, 100);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					Gender:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					<PlainSelect
						context="choose gender"
						isOpened={formsToggler[2].toggleForm}
						currentText={gender}
						handleToggle={() => toggleModal(2)}
					/>
					{formsToggler[2].toggleForm && (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								arrOptions={["Male", "Female", "Prefer not to say"]}
								onHandleSelect={(d) => {
									setPersonalInfo((prev) => ({ ...prev, gender: d }));
									setTimeout(() => {
										toggleModal(2);
									}, 100);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					Marital Status:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					<PlainSelect
						context="choose marital status"
						isOpened={formsToggler[3].toggleForm}
						currentText={marital_status}
						handleToggle={() => toggleModal(3)}
					/>
					{formsToggler[3].toggleForm && (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								arrOptions={[
									"Single",
									"Engaged",
									"Married",
									"Divorced",
									"Complicated",
								]}
								onHandleSelect={(d) => {
									setPersonalInfo((prev) => ({ ...prev, marital_status: d }));
									setTimeout(() => {
										toggleModal(3);
									}, 100);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<label
					htmlFor="date_of_birth"
					className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont"
				>
					Date of Birth:
				</label>
				<div className="w-full h-[45px] sm:w-[70%] md:w-4/5 text-sm">
					<input
						type="date"
						className={`h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] zabalRegFont bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] focus:border-2 focus:border-fortcore-green ${
							date_of_birth === "" ? "text-[#B8B8B8]" : "text-[#393F42]"
						}`}
						max={moment().format("YYYY-MM-DD")}
						id="date_of_birth"
						value={date_of_birth}
						onChange={handleChange}
					/>
					{/* {date_of_birth === '' && <p className='pt-0.5 text-xs zabalMedFont text-red-500'>ID Card expiry date must be specified</p>} */}
				</div>
			</div>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					Religion:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					<PlainSelect
						context="select religion"
						isOpened={formsToggler[4].toggleForm}
						currentText={religion}
						handleToggle={() => toggleModal(4)}
					/>
					{formsToggler[4].toggleForm && (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								arrOptions={["Islam", "Christianity", "Others"]}
								onHandleSelect={(d) => {
									setPersonalInfo((prev) => ({ ...prev, religion: d }));
									setTimeout(() => {
										toggleModal(4);
									}, 100);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || personalInfoLoading}
					isLoading={personalInfoLoading}
					onRequestClick={onProfileInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || personalInfoLoading}
					isLoading={personalInfoLoading}
					onRequestClick={onProfileInfoSave}
				/>
			</div>
		</div>
	);
};

export default PersonalInformation;
