import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import EmptyData from "../../../components/EmptyData";
import { Link } from "react-router-dom";

const Documents = () => {
	const { currentUser } = useSelector((state) => state.auth);

	const documents = [
		{ label: "id card", value: currentUser?.idCard },
		{ label: "bank statement", value: currentUser?.bankStatement },
		{
			label: "certificate of incorporation",
			value: currentUser?.certificateOfIncorporation,
		},
		{ label: "utility bill", value: currentUser?.utilityBill },
	];

	const validDocs = Object.values(documents).filter((a) => !!a.value);

	return (
		<div className="space-y-3 md:space-y-3.5">
			{validDocs.length > 0 ? (
				validDocs.map((doc) => <DocumentCard document={doc} key={doc.label} />)
			) : (
				<div className="h-[400px] w-full">
					<EmptyData text="You do not have any documents available." />
				</div>
			)}
		</div>
	);
};
export default Documents;

export const DocumentCard = ({ document }) => {
	return (
		<div className="rounded px-2 md:px-3 py-2 border border-[#96A0A5] flex items-center justify-between zabalMedFont">
			<p className="text-[13px] md:text-[13.px] text-[#393F42] capitalize">
				{document?.label}
			</p>
			{/* <button
				className="border border-[#B8C8CF] bg-[#EEF2F3] rounded px-5 py-2 text-[10.5px] text-fortcore-green"
				onClick={onReqView}
			>
				View
			</button> */}
			<Link
				className="border border-[#B8C8CF] bg-[#EEF2F3] rounded px-5 py-2 text-[10.5px] text-fortcore-green"
				// onClick={onReqView}

				to={document?.value}
				target="_blank"
			>
				View
			</Link>
		</div>
	);
};

DocumentCard.propTypes = {
	document: PropTypes.object,
	onReqView: PropTypes.func,
};
