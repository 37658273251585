import React, { useRef, useState } from "react";import { Helmet } from "react-helmet";import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { HiLockClosed } from "react-icons/hi";
import Parse from "../../../../api/ApiConfig";
import { MobileNavDetails } from "../../../../layout/MobileNavbar";
import Navbar from "../../../../layout/Navbar";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import theme from "../../../../components/mui/theme";
import { onBlurAmount, onInputAmount } from "../../../../common/utils";
import { AnyModal } from "../../newTransaction/investor/create/InvestorCreateAF";
import { ExclamationIcon, SuccessCheckIcon } from "../../../../layout/assets";
import { Preloader } from "../../../../components/globalStyled";
import { CreateAccessPin } from "../component/CreateAccessPin";
import Container from "../../../../layout/Container";

const breadcrumbItems = [
  { label: "< Risk Free Investment", href: "/financing" },
];

const durationFrame = [
  {
    value: "weeks",
    label: "Weeks",
  },
  {
    value: "months",
    label: "Months",
  },
  {
    value: "year",
    label: "Year",
  },
];

const durationList = [];

for (let i = 1; i <= 24; i++) {
  durationList.push({
    value: i,
    label: i,
  });
}

function AFRiskFreeApply() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [information, setInformation] = useState(true);
  const [success, setSuccess] = useState(false);
  const [riskForm, setRiskForm] = useState({
    amount: "",
    duration: "",
    durationText: "",
    durationTime: "",
    interest: "",
    amountToDeduct: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "amount") {
      const amount = parseFloat(value.replace(/,/g, ""));
      const interest = isNaN(amount) ? 0 : amount * 0.1;
      const newInterest = interest.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setRiskForm((prevState) => ({
        ...prevState,
        [name]: value,
        interest: newInterest,
      }));
    } else {
      setRiskForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleForm = (e) => {
    e.preventDefault();
    const newAmount =
      parseFloat(riskForm.amount.replace(/,/g, "")) +
      parseFloat(riskForm.interest.replace(/,/g, ""));

    setRiskForm((prevState) => ({
      ...prevState,
      duration: riskForm.durationTime + " " + riskForm.durationText,
      amountToDeduct: newAmount.toLocaleString(),
    }));
    setOpenModal(true);
  };
  const handleForm2 = () => {
    try {
      const currentUser = Parse.User.current();

      if (currentUser) {
        const AFRiskFreeInvestment = Parse.Object.extend(
          "AFRiskFreeInvestment"
        );
        const newAFRiskFreeInvestment = new AFRiskFreeInvestment();

        newAFRiskFreeInvestment.set("user", currentUser.toPointer());
        newAFRiskFreeInvestment.set("amount", riskForm.amount);
        newAFRiskFreeInvestment.set("duration", riskForm.duration);
        newAFRiskFreeInvestment.set("interest", riskForm.interest);

        newAFRiskFreeInvestment.save().then(() => {
          setSuccess(true);
          setRiskForm({
            amount: "",
            duration: "",
            durationText: "",
            durationTime: "",
            interest: "",
            amountToDeduct: "",
          });
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.log(error);
      toast.error(error.message);
      setSuccess(true);
    }
  };

  return (
    <>
      <MobileNavDetails text="Risk Free Investments" />
      <Container>
        <Helmet>
          <title>Fortcore - Risk Free Investments</title>
        </Helmet>
        <Navbar text="Risk Free Investments" />

        <Box
          mt={5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb items={breadcrumbItems} />
        </Box>

        <Box
          mt={5}
          mb={{ xs: 5, sm: 0 }}
          sx={{
            background: theme.palette.info.dark,
            borderRadius: "10px",
            p: 2,
          }}
        >
          <Typography variant="h4" color="primary">
            Risk Free Investment
          </Typography>
          <Box mt={5} component="form" onSubmit={handleForm}>
            <Stack spacing={2}>
              {/* Investment Amount  */}
              <Stack
                spacing={1}
                direction={{ sm: "row" }}
                justifyContent={{ sm: "space-between" }}
                alignItems={{ sm: "center" }}
              >
                <Typography width={{ xs: "100%", sm: "30%" }}>
                  Investment Amount:{" "}
                </Typography>

                <TextField
                  type="tel"
                  name="amount"
                  value={riskForm.amount}
                  onChange={handleInputChange}
                  placeholder=""
                  sx={{ width: "100%" }}
                  inputMode="numeric"
                  required
                  onInput={onInputAmount}
                  onBlur={(e) => onBlurAmount(e, riskForm.amount)}
                />
              </Stack>

              {/* Investment Duration  */}
              <Stack
                spacing={1}
                direction={{ sm: "row" }}
                justifyContent={{ sm: "space-between" }}
                alignItems={{ sm: "center" }}
              >
                <Typography width={{ xs: "100%", sm: "30%" }}>
                  Investment Duration:{" "}
                </Typography>

                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  width="100%"
                >
                  <TextField
                    select
                    name="durationText"
                    value={riskForm.durationText}
                    onChange={handleInputChange}
                    sx={{ width: "100%", textTransform: "capitalize" }}
                    required
                  >
                    {durationFrame.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    name="durationTime"
                    value={riskForm.durationTime}
                    onChange={handleInputChange}
                    sx={{ width: "100%", textTransform: "capitalize" }}
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    required
                  >
                    {durationList.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>

              {/* Interest */}
              <Stack
                spacing={1}
                direction={{ sm: "row" }}
                justifyContent={{ sm: "space-between" }}
                alignItems={{ sm: "center" }}
              >
                <Typography width={{ xs: "100%", sm: "30%" }}>
                  Interest:
                </Typography>

                <TextField
                  type="tel"
                  name="interestRate"
                  value={riskForm.interest}
                  onChange={handleInputChange}
                  // placeholder="Automatically Generated"
                  inputMode="numeric"
                  inputProps={{ min: "0" }}
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{ fontFamily: "ZabalMedium" }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <HiLockClosed />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Stack>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { sm: "flex-end" },
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    order: {
                      xs: 1,
                      sm: 0,
                    },
                  }}
                  onClick={() => navigate("/financing")}
                >
                  Back
                </Button>
                <Button variant="contained" type="submit">
                  Proceed
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Container>

      <AnyModal open={openModal}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              setOpenModal(false);
              setSuccess(false);
              setInformation(true);
            }}
          >
            <IoMdClose color={theme.palette.primary.main} />
          </IconButton>
        </Box>

        {information ? (
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <ExclamationIcon />
            <Typography variant="body1" color="primary" align="center">
              NGN {riskForm.amountToDeduct.toLocaleString()} will be deducted
              from your wallet
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setInformation(false)}
            >
              Proceed
            </Button>
          </Box>
        ) : (
          <Box>
            {!success ? (
              <InputPin action={handleForm2} />
            ) : (
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                }}
              >
                <SuccessCheckIcon />
                <Stack>
                  <Typography variant="h2" color="primary" align="center">
                    Success
                  </Typography>
                  <Typography variant="h2" color="primary" align="center">
                    Investment Completed
                  </Typography>
                </Stack>

                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setOpenModal(false);
                    setSuccess(false);
                    setInformation(true);
                  }}
                >
                  Okay
                </Button>
              </Box>
            )}
          </Box>
        )}
      </AnyModal>
    </>
  );
}

export default AFRiskFreeApply;

export function InputPin({ action }) {
  const navigate = useNavigate();
  const [pin, setPin] = useState(["", "", "", ""]);
  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [loading, setLoading] = useState(false);
  const [createPin, setCreatePin] = useState(false);

  const handleInputChange = (index, value) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < 3) {
      refs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !pin[index]) {
      refs[index - 1].current.focus();
    }
  };

  const filledDigitsCount = pin.filter((digit) => digit !== "").length < 4;

  const handleForm = (e) => {
    e.preventDefault();
    if (!filledDigitsCount) {
      setLoading(true);

      try {
        const currentUser = Parse.User.current();

        if (currentUser) {
          const userData = currentUser.toJSON();
          const accessPin = userData.accessPin;

          if (accessPin) {
            const newPin = pin.join("");

            if (newPin === userData.accessPin) {
              // eslint-disable-next-line no-undef
              console.log("Access Confirmed:");
              action();
              setLoading(false);
            } else {
              setLoading(false);
              toast.error("Invalid Access Pin");
            }
          } else {
            setLoading(false);
            toast.error("Access Pin does not exist, Create one");
          }
        } else {
          Parse.User.logOut().then(() => navigate("/login"));
          setLoading(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-undef
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          pl: { xs: 5, sm: 7 },
          pr: { xs: 5, sm: 7 },
        }}
        component="form"
        onSubmit={handleForm}
      >
        <Typography variant="h5">Enter PIN</Typography>
        <Box
          mt={2}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            columnGap: 1,
          }}
        >
          {pin.map((digit, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <input
                type="tel"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={refs[index]}
                style={{
                  width: "10px",
                  height: "20px",
                  outline: "none",
                }}
                required
              />
            </Box>
          ))}
        </Box>
        <Stack spacing={3} mt={5}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={filledDigitsCount || loading}
          >
            {!loading ? "Okay" : <Preloader />}
          </Button>
          <Button
            variant="text"
            fullWidth
            type="button"
            onClick={() => setCreatePin(true)}
          >
            Create Access Pin
          </Button>
        </Stack>
      </Box>

      <AnyModal open={createPin}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => setCreatePin(false)}>
            <IoMdClose color={theme.palette.primary.main} />
          </IconButton>
        </Box>
        <CreateAccessPin action={() => setCreatePin(false)} />
      </AnyModal>
    </>
  );
}

InputPin.propTypes = {
  action: PropTypes.func,
};
