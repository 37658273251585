// Notifications.js
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {
	Badge,
	Box,
	Menu,
	Stack,
	IconButton,
	Chip,
	Button,
	Divider,
} from "@mui/material";
import { BellIcon } from "../../layout/assets";
import { GrClose } from "react-icons/gr";
// import { GoDotFill } from "react-icons/go";
import { RxDotFilled } from "react-icons/rx";
import theme from "../mui/theme";
import Noti from "../../assets/noti.png";
import Parse from "../../api/ApiConfig";
import baseURL from "../../api/UrlConfig";
import axios from "axios";
import { useSelector } from "react-redux";
import userContext from "../../context/UserContext";
import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
const Notifications = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedNotification, setSelectedNotification] = useState(null);
	const { newNotifications, setNewNotifications } = useContext(userContext);

	const { guarantorsFormSuccess } = useSelector((state) => state.auth);

	const {
		toggleDeleteInactiveInvestmentModal,
		toggleActivateLeaseBoardInvestment,
		toggleAddFundsToAssetSavings,
		updateOngoingTransactionSuccess,
	} = useSelector((state) => state.lease);

	const { toggleRegularSavingsAddFundsModal } = useSelector(
		(state) => state.travel
	);

	const unreadCount = newNotifications.filter(
		(item) => item.read === false
	).length;

	const getNotifications = async () => {
		const userData = Parse.User.current();
		if (userData) {
			// const currentUser = userData.toJSON();
			let config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${baseURL}/functions/getNotifications`,
				headers: {
					// eslint-disable-next-line no-undef
					"X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
					"X-Parse-Session-Token": Parse.User.current().getSessionToken(),
				},
			};

			axios
				.request(config)
				.then((response) => {
					const result = response.data.result.newNotifications;
					result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
					setNewNotifications(result);

					// eslint-disable-next-line no-undef
					// console.log("NOTIFICATION", result);
				})
				.catch((error) => {
					if (error?.response?.data?.error.includes("Invalid session")) {
						toast.error("Invalid session token");
						// window.location.href = "/login";
						// localStorage.clear();
					}
					// eslint-disable-next-line no-undef
					console.log(error);
				});
		}
	};

	useEffect(() => {
		getNotifications();
	}, [
		toggleRegularSavingsAddFundsModal,
		toggleDeleteInactiveInvestmentModal,
		toggleActivateLeaseBoardInvestment,
		toggleAddFundsToAssetSavings,
		updateOngoingTransactionSuccess,
		guarantorsFormSuccess,
	]);

	// Function to handle opening the Popper
	const handleOpenPopper = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// Function to handle closing the Popper
	const handleClose = () => {
		setAnchorEl(null);
	};

	// Function to handle the click on "View" button and show the notification text
	const handleViewClick = async (notificationId) => {
		const selected = newNotifications.find(
			(notification) => notification.objectId === notificationId
		);
		setSelectedNotification(selected);
		try {
			let NotificationUpdate = new Parse.Object("Notifications");
			NotificationUpdate.set("objectId", notificationId);
			NotificationUpdate.set("read", true);

			await NotificationUpdate.save();
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
		}
	};

	const handleCloseSelectedNotification = () => {
		getNotifications();
		setSelectedNotification(null);
	};

	return (
		<>
			<Badge
				color="notify"
				variant={unreadCount > 0 ? "dot" : ""}
				overlap="circular"
				onClick={handleOpenPopper}
				sx={{ cursor: "pointer" }}
			>
				<BellIcon width={20} height={20} />
			</Badge>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					background: "transparent",
				}}
			>
				{/* <NotificationList onClick={handleClose} /> */}
				<NotificationList
					data={newNotifications}
					selectedNotification={selectedNotification}
					onClick={handleViewClick}
					onClose={handleClose}
					closeSelectedNotification={handleCloseSelectedNotification}
				/>
			</Menu>
		</>
	);
};

Notifications.propTypes = {
	showNotifications: PropTypes.bool,
	setShowNotifications: PropTypes.func,
};

export default Notifications;

function NotificationList({
	data,
	selectedNotification,
	closeSelectedNotification,
	onClick,
	onClose,
}) {
	const unreadCount = data.filter((item) => item.read === false).length;

	return (
		<Box
			sx={{
				width: { xs: "100%", md: "380px" },
				maxWidth: "380px",
				borderRadius: "10px",
				height: "434px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				mt={-1}
				sx={{
					background: "#EFF8F9",
					padding: "16px 20px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography variant="h4">Notifications</Typography>
					{data.length > 0 && (
						<Chip
							sx={{ color: theme.palette.primary.main }}
							label={`${unreadCount} unread`}
						/>
					)}
				</Stack>
				<IconButton onClick={onClose}>
					<GrClose size={20} color={theme.palette.primary.main} />
				</IconButton>
			</Box>

			{/* Body  */}

			{/* Notification Opened */}
			{selectedNotification ? (
				<Box sx={{ padding: "16px 20px", flexGrow: 1 }}>
					<Typography variant="body1">
						{selectedNotification.metaData.moreDetails}
					</Typography>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							variant="text"
							color="notify"
							onClick={closeSelectedNotification}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								padding: "0",

								"&:hover": {
									background: "transparent",
								},
							}}
						>
							Close
						</Button>
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						padding: "16px 20px",
					}}
				>
					{data.length > 0 ? (
						<>
							{data.map((message) => (
								<Box
									key={message.objectId}
									mb={2}
									onClick={() => onClick(message.objectId)}
									sx={{ cursor: "pointer" }}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
										sx={{ pb: 2 }}
									>
										<RxDotFilled
											size={18}
											color={
												message.read ? "#8E9DA4" : theme.palette.primary.main
											}
										/>

										<Typography
											sx={{
												color: message.read
													? "#8E9DA4"
													: theme.palette.primary.main,
												fontFamily: message.read
													? "ZabalRegular"
													: "ZabalMedium",
											}}
										>
											{message.message}
										</Typography>
									</Stack>

									{/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
										<Button
											variant="text"
											color="primary"
											onClick={() => onClick(message.objectId)}
											sx={{
												display: "flex",
												justifyContent: "flex-end",
												padding: 0,
											}}>
											View
										</Button>
									</Box> */}

									<Divider />
								</Box>
							))}
						</>
					) : (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: 2,
							}}
						>
							<img src={Noti} alt="" style={{ width: "60%" }} />
							<Typography align="center" sx={{ color: "#999999" }}>
								Notification is empty
							</Typography>
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
}

NotificationList.propTypes = {
	data: PropTypes.array.isRequired,
	selectedNotification: PropTypes.object,
	onClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	closeSelectedNotification: PropTypes.func,
};
