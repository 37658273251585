import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Parse from "../../api/ApiConfig";
import { MdKeyboardArrowDown } from "react-icons/md";
import ModalContainer from "../../components/ModalContainer";
import InvestmentLists from "../../components/InvestmentLists";
import { formatDurationForProgramSavings } from "../../common/utils";

const LeaseRoi = ({ data }) => {
	const [toggleInvestments, setToggleInvestments] = useState(false);

	const toggleModal = () => {
		setToggleInvestments(!toggleInvestments);
	};

	const [filters, setFiters] = useState({
		selectedData: data[0] || null,
		from: "",
		to: "",
	});

	const { selectedData, from, to } = filters;

	const onChange = (e) =>
		setFiters((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const onInvestmentSelect = (data) => {
		setFiters((prev) => ({ ...prev, selectedData: data }));
		setToggleInvestments(false);
		console.log(data);
	};

	const lengthOfTable = formatDurationForProgramSavings(
		selectedData?.leaseDuration
	);

	return (
		<>
			{toggleInvestments && (
				<ModalContainer modal={toggleInvestments}>
					<InvestmentLists
						investments={data}
						onClose={toggleModal}
						onSelect={onInvestmentSelect}
					/>
				</ModalContainer>
			)}
			<div className="rounded-md py-4 md:py-5 bg-[#FBFBFB] border border-#D4DEE2] space-y-4 md:space-y-5 overflow-x-auto scroll-wrapper">
				<div className="flex justify-between items-center min-w-[900px] px-4 md:px-4">
					<button
						className="flex items-center justify-between gap-5 border border-[#879094] rounded p-2.5 w-[300px]"
						onClick={toggleModal}
					>
						<span
							className={`text-sm ${
								selectedData
									? "text-black zabalMedFont"
									: " zabalRegFont text-[#879094]"
							}`}
						>
							{selectedData?.objectId || "Select Investment"}
						</span>
						<MdKeyboardArrowDown size={22} />
					</button>
					<div className="flex items-center gap-4">
						<DateInput name="from" value={from} onChange={onChange} />
						<span>to</span>
						<DateInput name="to" value={to} onChange={onChange} />
					</div>
				</div>
				<div>
					<div className="grid grid-cols-7 gap-3 min-w-[900px] border-b pb-2 px-4 md:px-4">
						<TableHead text="Asset ID" />
						<TableHead text="Asset Value (NGN)" />
						<TableHead text="Amount Invested" />
						<TableHead text="ROI" />
						<TableHead text="Month" />
						<TableHead text="Date" />
						<TableHead text="Status" />
					</div>
					{/* <div className="min-w-[900px] grid gap-5 md:gap-6 lg:gap-7 pt-4 md:pt-5 pb-1">
						{data?.map((investment) => {
							const user = Parse.User.current();
							const investorInfo = investment?.investorsArray?.find(
								(investor) => investor?.user === user?.id
							);
							return (
								<div
									className="w-full grid grid-cols-7 gap-3"
									key={investment?.objectId}
								>
									<TableData text={investment?.objectId} />
									<TableData text={investment?.productObj?.amount} />
									<TableData text={investorInfo?.amountToInvest} />
									<TableData text={investorInfo?.interestToEarn?.toFixed(2)} />
									<TableData text="Month" />
									<TableData
										text={moment(investment?.createdAt).format("Do MMM, YYYY")}
									/>
									<TableData text="Status" />
								</div>
							);
						})}
					</div> */}
					<div className="min-w-[900px] grid gap-5 md:gap-6 lg:gap-7 pt-4 md:pt-5 pb-1 max-h-[230px] overflow-y-auto scroll-wrapper px-4 md:px-4">
						{Array(Number(lengthOfTable))
							.fill(selectedData)
							.map((investment, i) => {
								const user = Parse.User.current();
								const investorInfo = investment?.investorsArray?.find(
									(investor) => investor?.user === user?.id
								);

								const month = new Date(investment?.createdAt).setMonth(
									new Date(investment?.createdAt).getMonth() + i
								);

								// console.log(investment);
								return (
									<div className="w-full grid grid-cols-7 gap-4" key={i}>
										<TableData text={investment?.objectId} />
										<TableData text={investment?.productObj?.amount} isCenter />
										<TableData text={investorInfo?.amountToInvest} isCenter />
										<TableData
											text={investorInfo?.interestToEarn?.toFixed(2)}
										/>
										<TableData text={String(i + 1)} />
										<TableData text={moment(month).format("Do MMM, YYYY")} />
										<TableData text="Pending" />
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};

LeaseRoi.propTypes = {
	data: PropTypes.array,
};

export default LeaseRoi;

export const TableHead = ({ text }) => (
	<p className="text-[13px] md:text-sm zabalMedFont text-[#717E83]">{text}</p>
);

TableHead.propTypes = {
	text: PropTypes.string,
};

export const TableData = ({ text, isCenter }) => (
	<p
		className={`text-[13px] md:text-sm zabalRegFont text-[#717E83] ${
			isCenter ? "text-center" : "text-start"
		}`}
	>
		{text}
	</p>
);

TableData.propTypes = {
	text: PropTypes.string,
	isCenter: PropTypes.bool,
};

export const DateInput = ({ name, value, onChange }) => (
	<input
		type="date"
		placeholder="yyyy-mm-dd"
		className="rounded border border-[#393F42] p-2 placeholder:text-[#879094] text-sm text-fortcore-green"
		name={name}
		value={value}
		onChange={onChange}
	/>
);

DateInput.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};
