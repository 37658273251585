import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Stack, Typography } from "@mui/material";
import theme from "../../../../components/mui/theme";
import {
	AgricultureImage,
	AutomotiveImage,
	ConstructionImage,
	ConsultingImage,
	ECommerceImage,
	EducationImage,
	FashionImage,
	FinancialImage,
	HealthcareImage,
	HospitalityImage,
	ManufacturingImage,
	MediaImage,
	RestaurantImage,
	RetailImage,
	TechnologyImage,
	TransportationImage,
} from "../../images/ImagesAf";
import { MdKeyboardArrowRight } from "react-icons/md";

function OngoingCard({
	title,
	asset,
	interest,
	tenure,
	onClick,
	businessType,
	objectId,
	firstImage,
}) {
	return (
		// <div className="">
		<Button
			sx={{
				border: "1px solid var(--grey-500, #B8C8CF)",
				borderRadius: "8px",
				background: theme.palette.secondary.main,
				width: "100%",
				p: 2,
				display: "flex",
				flexDirection: { xs: "column", sm: "row" },
				// flexWrap: "wrap",
				justifyContent: "flex-start",
				alignItems: "start",
				gap: { xs: 4, md: 5 },
			}}
		>
			<Box
				sx={{
					width: "216px",
					height: "120px",
					border: "1px #E3E9EC solid",
					borderRadius: "5px",
					p: 0.5,
				}}
			>
				{/* <img
						src={image}
						alt=""
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/> */}
				<>
					{businessType?.toLowerCase()?.includes("restaurant") && (
						<img
							src={firstImage?.url || RestaurantImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("retail") && (
						<img
							src={firstImage?.url || RetailImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("technology") && (
						<img
							src={firstImage?.url || TechnologyImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("consulting") && (
						<img
							src={firstImage?.url || ConsultingImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("construction") && (
						<img
							src={firstImage?.url || ConstructionImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("healthcare") && (
						<img
							src={firstImage?.url || HealthcareImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("e-commerce") && (
						<img
							src={firstImage?.url || ECommerceImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("manufacturing") && (
						<img
							src={firstImage?.url || ManufacturingImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("transportation") && (
						<img
							src={firstImage?.url || TransportationImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("hospitality") && (
						<img
							src={firstImage?.url || HospitalityImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}

					{businessType?.toLowerCase()?.includes("finance") && (
						<img
							src={firstImage?.url || FinancialImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("education") && (
						<img
							src={firstImage?.url || EducationImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("media") && (
						<img
							src={firstImage?.url || MediaImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("agriculture") && (
						<img
							src={firstImage?.url || AgricultureImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("fashion") && (
						<img
							src={firstImage?.url || FashionImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
					{businessType?.toLowerCase()?.includes("automotive") && (
						<img
							src={firstImage?.url || AutomotiveImage}
							alt="image"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
				</>
			</Box>

			{/* Information  */}
			<div className="flex justify-between w-full md:w-2/3 lg:10/12 items-center">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						width: "100%",
						gap: 0.5,
					}}
				>
					<Typography
						variant="h5"
						color="initial"
						sx={{ textTransform: "capitalize" }}
					>
						{`TransactionId: TRN-${objectId}`}
					</Typography>

					<Typography
						variant="h4"
						color="initial"
						sx={{ textTransform: "capitalize" }}
					>
						{title}
					</Typography>

					<Stack direction="row" alignItems="center" spacing={3}>
						<Typography variant="body1" color={theme.palette.info.light}>
							Asset Value :{" "}
						</Typography>
						<Typography
							variant="body1"
							sx={{ fontFamily: "ZabalMedium" }}
							color={theme.palette.info.light}
						>
							NGN {asset}{" "}
						</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Typography variant="body1" color={theme.palette.info.light}>
							Interest :
						</Typography>
						<Typography variant="body1" color={theme.palette.info.light}>
							{interest}
						</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={1} width="100%">
						<Typography variant="body1" color={theme.palette.info.light}>
							Tenure :{" "}
						</Typography>
						<Typography variant="body1" color={theme.palette.info.light}>
							{tenure}
						</Typography>
					</Stack>
				</Box>
				<button
					className="text-fortcore-green text-2xl"
					onClick={onClick}
					// onClick={() => {
					// 	navigate(
					// 		`/leasing/ongoing-transactions/confirm-lease?id=${leaseItem?.id}`
					// 	);
					// }}
				>
					<MdKeyboardArrowRight />
				</button>
			</div>
		</Button>
		// </div>
	);
}

OngoingCard.propTypes = {
	title: PropTypes.string,
	asset: PropTypes.string,
	interest: PropTypes.string,
	tenure: PropTypes.string,
	businessType: PropTypes.string,
	objectId: PropTypes.string,
	onClick: PropTypes.func,
	firstImage: PropTypes.object,
};

export default OngoingCard;
