import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./TextFader.css"; // You need to create the CSS for the transition
import { Box, Typography } from "@mui/material";

const texts = [
  "Solve for questions",
  "New questions",
  "Solve for old questions",
];

function TextFader() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    // eslint-disable-next-line no-undef
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-fader">
      <SwitchTransition>
        <CSSTransition
          key={index}
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          timeout={{ enter: 1000, exit: 1000 }}
        >
          <Box component="span">
            <Typography
              variant="h1"
              className="fade-text"
              sx={{
                fontWeight: 600,
                color: "var(--grey-800, #717E83)",
                fontSize: { sm: "64px" },
              }}
            >
              {texts[index]}
            </Typography>
          </Box>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default TextFader;
