import React, { useEffect, useState } from "react";
import { MdLock, MdOutlineClose } from "react-icons/md";
import PropTypes from "prop-types";
import { BsSearch } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
	CustomCheckboxInput,
	PlainSelect,
	SelectDropDown,
} from "../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../layout/CustomButtons";
import {
	clearVerifyAccountSuccess,
	closeAddBankAccountModal,
} from "../redux/authRedux/authActions";
import {
	fetchAllBanks,
	verifyBankAccount,
} from "../redux/authRedux/authServices";

const AddBankAccountModal = ({ onRequestClick, accountInfo }) => {
	const dispatch = useDispatch();
	const {
		bankAccountLoading,
		fetchBanksLoading,
		verfiyBankSuccess,
		verifyBankLoading,
		fetchBanksSuccess,
	} = useSelector((state) => state.auth);

	const [toggleBankAccount, setToggleBankAccount] = useState(false);
	const [accountDetails, setAccountDetails] = useState({
		bank_name: accountInfo?.bankName || "",
		account_name: accountInfo?.accountName || "",
		account_number: accountInfo?.accountNumber || "",
		save_details: false,
	});

	useEffect(() => {
		if (fetchBanksSuccess) {
			return;
		} else {
			dispatch(fetchAllBanks());
		}
	}, []);

	const { bank_name, account_name, account_number, save_details } =
		accountDetails;

	const filteredBanks = fetchBanksSuccess?.filter((bank) =>
		bank?.name.toLowerCase().includes(bank_name.toLowerCase())
	);

	const selectedBankCode = fetchBanksSuccess?.find(
		(bank) => bank?.name === bank_name
	)?.uuid;

	useEffect(() => {
		if (bank_name === "" && account_number === "") {
			return;
		} else if (account_number?.length < 10) {
			return;
		} else if (selectedBankCode) {
			setAccountDetails((prev) => ({ ...prev, account_name: "" }));
			dispatch(
				verifyBankAccount({
					accountNumber: account_number,
					bankUUID: selectedBankCode,
				})
			);
		}
	}, [bank_name, account_number]);

	useEffect(() => {
		if (verifyBankLoading) {
			return;
		} else if (verfiyBankSuccess) {
			setAccountDetails((prev) => ({
				...prev,
				account_name: verfiyBankSuccess?.message?.includes(
					"Invalid Account for account number"
				)
					? "No account matches these details"
					: verfiyBankSuccess?.destinationAccountHolderNameAtBank &&
					  verfiyBankSuccess?.destinationAccountHolderNameAtBank,
			}));
		}
	}, [verfiyBankSuccess]);

	const handleChange = (e) => {
		const target = e.target;
		if (target.type === "checkbox") {
			setAccountDetails((prev) => ({ ...prev, [e.target.id]: target.checked }));
		} else {
			setAccountDetails((prev) => ({ ...prev, [e.target.id]: e.target.value }));
		}
	};

	const isButtonValidated = () => {
		if (
			account_number.length < 10 ||
			account_name === "" ||
			bank_name === "" ||
			isNaN(account_number)
		) {
			return true;
		} else {
			return false;
		}
	};

	const submitBankDetails = () => {
		const data = {
			bankName: bank_name,
			accountName: account_name,
			accountNo: account_number,
			isPrimaryAccount: save_details,
		};
		console.log(data);
		dispatch(clearVerifyAccountSuccess());
		onRequestClick(data);
	};

	return (
		<div className="space-y-5 bg-white rounded-2xl sm:rounded-md py-6 px-5 w-11/12 max-w-[450px]">
			<div className="flex items-center justify-between text-fortcore-green zabalRegFont">
				<h2 className="text-[15px] font-semibold">
					{accountInfo ? "Change" : "Add"} Account
				</h2>
				<button onClick={() => dispatch(closeAddBankAccountModal())}>
					<MdOutlineClose size={20} />
				</button>
			</div>
			<div className="space-y-3">
				<div className="flex flex-col gap-1">
					<p className="text-xs zabalMedFont">Bank Name</p>
					<div className="w-full relative">
						{toggleBankAccount ? (
							<div className="relative h-[43px]">
								<input
									type="text"
									className="h-full text-[text-[#393F42] border rounded border-[#B8B8B8] px-2.5 w-full outline-none text-sm zabalRegFont focus:border-2 focus:border-fortcore-green text-[#393F42] placeholder:text-[#B8B8B8] placeholder:text-[13px]"
									id="bank_name"
									value={bank_name}
									onChange={handleChange}
									placeholder="Search banks"
								/>
								<span className="top-0 absolute right-2 h-full grid place-items-center w-[40px] text-[#B8B8B8]">
									<BsSearch size={15} />
								</span>
							</div>
						) : (
							<PlainSelect
								context="select bank account"
								isOpened={toggleBankAccount}
								currentText={bank_name}
								handleToggle={() => {
									setToggleBankAccount((prev) => !prev);
								}}
								height="43px"
							/>
						)}
						{toggleBankAccount && filteredBanks?.length > 0 ? (
							<div className="absolute z-[2] w-full top-48px">
								<SelectDropDown
									arrOptions={filteredBanks
										?.map((bank) => bank?.name)
										.sort((a, b) => (b > a ? -1 : b < a ? 1 : 0))}
									isLoading={fetchBanksLoading}
									// arrOptions={filteredBanks?.map((bank) => bank.label)}
									onHandleSelect={(d) => {
										setAccountDetails((prev) => ({
											...prev,
											bank_name: d,
											account_number: "",
										}));
										setTimeout(() => {
											setToggleBankAccount(false);
										}, 100);
									}}
								/>
							</div>
						) : (
							toggleBankAccount &&
							filteredBanks?.length < 1 && (
								<div className="w-full absolute z-[2] top-[48px] shadow-md bg-white h-[80px] grid place-items-center zabalRegFont mb-5">
									<div className="flex justify-center flex-col items-center">
										<FaBoxOpen size={35} color="#b5b5b5" />
										<p className="text-[13px] font-medium text-[#b2b2b2]">
											No bank matches the search text
										</p>
									</div>
								</div>
							)
						)}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<label className="text-xs zabalMedFont" htmlFor="account_number">
						Account Number
					</label>
					<input
						type="tel"
						className="text-[text-[#393F42] border rounded border-[#B8B8B8] px-2.5 h-[43px] outline-none text-sm zabalRegFont focus:border-2 focus:border-fortcore-green text-[#393F42] placeholder:text-[#B8B8B8]"
						id="account_number"
						value={account_number}
						onChange={handleChange}
						maxLength={10}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<label className="text-xs zabalMedFont" htmlFor="account_name">
						Account Name
					</label>
					<div className="relative h-[43px]">
						<div
							className={`border rounded border-[#A8A8A8] px-2.5 h-full text-sm zabalRegFont w-full flex items-center ${
								account_name === "No account matches these details"
									? "text-red-500 zabalMedFont"
									: "text-[#393F42] capitalize"
							}`}
						>
							{account_name}
						</div>
						{verifyBankLoading && (
							<p className="text-[13px] zabalMedFont absolute left-3 top-3 text-[#B8B8B8]">
								Searching...
							</p>
						)}
						<span className="absolute right-2 top-3">
							<MdLock size={18} color="#888888" />
						</span>
					</div>
				</div>
				{!accountInfo && (
					<div className="flex gap-2 items-center">
						<CustomCheckboxInput
							id="save_details"
							handleChange={handleChange}
							isChecked={save_details}
						/>
						<p className="text-[11px] text-[#5A6063] zabalRegFont">
							Make this my primary account
						</p>
					</div>
				)}
			</div>
			<div className="hidden sm:flex justify-end gap-2">
				<CustomWhiteBtn
					text="cancel"
					width="105px"
					fontSize="12px"
					height="40px"
					onRequestClick={() => dispatch(closeAddBankAccountModal())}
				/>
				<CustomGreenBtn
					text={`${accountInfo ? "change" : "add"} account`}
					width={accountInfo ? "125px" : "105px"}
					fontSize="12px"
					height="40px"
					isDisabled={isButtonValidated() || bankAccountLoading}
					isLoading={bankAccountLoading}
					onRequestClick={submitBankDetails}
				/>
			</div>
			<div className="pt-4 flex flex-col justify-end gap-2.5 sm:hidden">
				<CustomGreenBtn
					text={`${accountInfo ? "change" : "add"} account`}
					isDisabled={isButtonValidated() || bankAccountLoading}
					isLoading={bankAccountLoading}
					onRequestClick={submitBankDetails}
				/>
				<CustomWhiteBtn
					text="cancel"
					onRequestClick={() => dispatch(closeAddBankAccountModal())}
				/>
			</div>
		</div>
	);
};
export default AddBankAccountModal;

AddBankAccountModal.propTypes = {
	onRequestClick: PropTypes.func,
	accountInfo: PropTypes.object,
};
