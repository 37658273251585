import {
	FETCH_MARKET_PLACE_ERROR,
	FETCH_MARKET_PLACE_LOADING,
	FETCH_MARKET_PLACE_SUCCESS,
	OPEN_MARKET_ITEMS_DETAILS,
	OPEN_PERSONAL_FORM,
	CLOSE_PERSONAL_FORM,
	CLOSE_MARKET_ITEMS_DETAILS,
	FETCH_REQUEST_LEASE_DATA_LOADING,
	FETCH_REQUEST_LEASE_DATA_SUCCESS,
	FETCH_REQUEST_LEASE_DATA_ERROR,
	SELECTED_REQUEST_LEASE_ITEM,
	DATA_FROM_LEASE_FORM,
	DATA_FROM_LEASE_STRUCTURE,
	OPEN_BUSINESS_FORM,
	CLOSE_BUSINESS_FORM,
	OPEN_INVESTOR_FORM,
	CLOSE_INVESTOR_FORM,
	OPEN_PERSONAL_REQUEST_LEASE_MODAL,
	CLOSE_PERSONAL_REQUEST_LEASE_MODAL,
	OPEN_BUSINESS_REQUEST_LEASE_MODAL,
	CLOSE_BUSINESS_REQUEST_LEASE_MODAL,
	CLOSE_INVESTOR_REQUEST_LEASE_MODAL,
	OPEN_INVESTOR_REQUEST_LEASE_MODAL,
	OPEN_CONFIRM_AUTODEBIT_MODAL,
	CLOSE_CONFIRM_AUTODEBIT_MODAL,
	UPDATE_CURRENT_ONGOING_TRANSACTION,
	CLOSE_ONGOING_TRANSACTION_MODAL,
	SAVE_PERSONAL_FORM_SUCCESS,
	BUSINESS_FORM_DATA_FROM_PARSE,
	PERSONAL_FORM_DATA_FROM_PARSE,
	SAVE_BUSINESS_FORM_LOADING,
	SAVE_BUSINESS_FORM_ERROR,
	SAVE_BUSINESS_FORM_SUCCESS,
	SAVE_INVESTOR_FORM_LOADING,
	SAVE_INVESTOR_FORM_SUCCESS,
	SAVE_INVESTOR_FORM_ERROR,
	INVESTOR_FORM_DATA_FROM_PARSE,
	FETCH_ONGOING_LEASE_LOADING,
	FETCH_ONGOING_LEASE_SUCCESS,
	FETCH_ONGOING_LEASE_ERROR,
	FETCH_LEASE_WITH_QUERY_LOADING,
	FETCH_LEASE_WITH_QUERY_SUCCESS,
	FETCH_LEASE_WITH_QUERY_ERROR,
	FETCH_SUBMITTED_LEASE_LOADING,
	FETCH_SUBMITTED_LEASE_SUCCESS,
	FETCH_SUBMITTED_LEASE_ERROR,
	SUBMIT_NEGOTIATION_LOADING,
	SUBMIT_NEGOTIATION_SUCCESS,
	SUBMIT_NEGOTIATION_ERROR,
	CLEAR_SUBMITTED_NEGOTIATION,
	SELECTED_PROFILE,
	FETCH_LEASE_BOARD_DATA_LOADING,
	FETCH_LEASE_BOARD_DATA_SUCCESS,
	FETCH_LEASE_BOARD_DATA_ERROR,
	FETCH_INVESTOR_MARKET_PLACE_LOADING,
	FETCH_INVESTOR_MARKET_PLACE_SUCCESS,
	FETCH_INVESTOR_MARKET_PLACE_ERROR,
	SELECTED_PAYMENT_METHOD,
	OPEN_PAY_WITH_WALLET_MODAL,
	CLOSE_PAY_WITH_WALLET_MODAL,
	OPEN_ACTIVATE_LEASE_BOARD_MODAL,
	CLOSE_ACTIVATE_LEASE_BOARD_MODAL,
	FETCH_REGULAR_SAVINGS_LOADING,
	FETCH_REGULAR_SAVINGS_SUCCESS,
	FETCH_REGULAR_SAVINGS_ERROR,
	REGULAR_SAVINGS_FORM,
	FETCH_ASSET_BASED_SAVINGS_LOADING,
	FETCH_ASSET_BASED_SAVINGS_SUCCESS,
	FETCH_ASSET_BASED_SAVINGS_ERROR,
	CREATE_ASSET_BASED_SAVINGS_LOADING,
	CREATE_ASSET_BASED_SAVINGS_SUCCESS,
	CREATE_ASSET_BASED_SAVINGS_ERROR,
	IS_CREATE_ASSET_SAVINGS_SUCCESS,
	CLEAR_SAVE_FORM_SUCCESS,
	FETCH_RISK_INVESTMENTS_LOADING,
	FETCH_RISK_INVESTMENTS_SUCCESS,
	FETCH_RISK_INVESTMENTS_ERROR,
	INVESTOR_CREATE_ASSET_LOADING,
	INVESTOR_CREATE_ASSET_ERROR,
	INVESTOR_CREATE_ASSET_SUCCESS,
	FETCH_CATEGORIES_SUCCESS,
	FETCH_CATEGORIES_LOADING,
	FETCH_CATEGORIES_ERROR,
	FETCH_BRANDS_LOADING,
	FETCH_BRANDS_SUCCESS,
	FETCH_BRANDS_ERROR,
	GET_RENTAL_FEE_LOADING,
	GET_RENTAL_FEE_SUCCESS,
	GET_RENTAL_FEE_ERROR,
	GET_DELIVERY_FEE_LOADING,
	GET_DELIVERY_FEE_SUCCESS,
	GET_DELIVERY_FEE_ERROR,
	GET_CURRENT_CONTEXT_INTEREST,
	CLEAR_REGULAR_SAVINGS,
	CREATE_LEASE_LOADING,
	CREATE_LEASE_SUCCESS,
	CREATE_LEASE_ERROR,
	UPDATE_ONGOING_LEASE_LOADING,
	UPDATE_ONGOING_LEASE_ERROR,
	UPDATE_ONGOING_LEASE_SUCCESS,
	CLEAR_UPDATE_LEASE_SUCCESS,
	OPEN_FILTER_MODAL,
	CLOSE_FILTER_MODAL,
	OPEN_DELETE_ONGOING_LEASE_MODAL,
	CLOSE_DELETE_ONGOING_LEASE_MODAL,
	DELETE_ONGOING_LEASE_SUCCESS,
	DELETE_ONGOING_LEASE_ERROR,
	DELETE_ONGOING_LEASE_LOADING,
	OPEN_DELETE_INACTIVE_INVESTMENT_MODAL,
	CLOSE_DELETE_INACTIVE_INVESTMENT_MODAL,
	DELETE_INACTIVE_ASSET_ERROR,
	DELETE_INACTIVE_ASSET_SUCCESS,
	DELETE_INACTIVE_ASSET_LOADING,
	CREATE_REGULAR_SAVINGS_LOADING,
	CREATE_REGULAR_SAVINGS_SUCCESS,
	CREATE_REGULAR_SAVINGS_ERROR,
	CLEAR_REGULAR_SAVINGS_CREATED_SUCCESS,
	SUBMIT_ONGOING_LEASE_SUCCESS,
	CREATE_RISK_INVESTMENTS_LOADING,
	CREATE_RISK_INVESTMENTS_SUCCESS,
	CREATE_RISK_INVESTMENTS_ERROR,
	ACTIVATE_ASSET_LOADING,
	ACTIVATE_ASSET_SUCCESS,
	ACTIVATE_ASSET_ERROR,
	FETCH_SINGLE_ASSET_BASED_SAVINGS_LOADING,
	FETCH_SINGLE_ASSET_BASED_SAVINGS_SUCCESS,
	FETCH_SINGLE_ASSET_BASED_SAVINGS_ERROR,
	UPDATE_SINGLE_ASSET_BASED_SAVINGS,
	CLEAR_UPDATE_ASSET_SAVINGS_SUCCESS,
	OPEN_ADD_FUNDS_TO_ASSET_SAVINGS,
	CLOSE_ADD_FUNDS_TO_ASSET_SAVINGS,
	CLEAR_LEASE_STRUCTURE_DATA,
	QUERY_PARTIAL_FUNDED_ASSET_LOADING,
	QUERY_PARTIAL_FUNDED_ASSET_SUCCESS,
	QUERY_PARTIAL_FUNDED_ASSET_ERROR,
	CLEAR_ACTIVATE_ASSET_SUCCESS,
	OPEN_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL,
	CLOSE_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL,
	OPEN_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL,
	CLOSE_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL,
	QUERY_PARTIAL_FUNDED_REQUEST_LOADING,
	QUERY_PARTIAL_FUNDED_REQUEST_SUCCESS,
	QUERY_PARTIAL_FUNDED_REQUEST_ERROR,
	INVESTOR_UPDATE_REQUEST_STATUS,
	TOGGLE_PAY_DELIVERY_FEE_MODAL,
	TOGGLE_APPLY_INSURANCE_MODAL,
	TOGGLE_REGISTER_ASSET_MODAL,
	REGISTER_ASSET_LOADING,
	REGISTER_ASSET_SUCCESS,
	REGISTER_ASSET_ERROR,
	ACTIVATE_INSURANCE_LOADING,
	ACTIVATE_INSURANCE_SUCCESS,
	ACTIVATE_INSURANCE_ERROR,
	CREDIT_ASSESSMENT_LOADING,
	CREDIT_ASSESSMENT_SUCCESS,
	CREDIT_ASSESSMENT_ERROR,
	CLEAR_CREDIT_ASSESSMENT_SUCCESS,
} from "./leaseTypes";

export const fetchMarketPlaceLoading = () => {
	return { type: FETCH_MARKET_PLACE_LOADING };
};
export const fetchMarketPlaceSuccess = (data) => {
	return { type: FETCH_MARKET_PLACE_SUCCESS, payload: data };
};
export const fetchMarketPlaceError = () => {
	return { type: FETCH_MARKET_PLACE_ERROR };
};
export const fetchInvestorMarketPlaceLoading = () => {
	return { type: FETCH_INVESTOR_MARKET_PLACE_LOADING };
};
export const fetchInvestorMarketPlaceSuccess = (data) => {
	return { type: FETCH_INVESTOR_MARKET_PLACE_SUCCESS, payload: data };
};
export const fetchInvestorMarketPlaceError = () => {
	return { type: FETCH_INVESTOR_MARKET_PLACE_ERROR };
};
export const fetchRequestLeaseDataLoading = () => {
	return { type: FETCH_REQUEST_LEASE_DATA_LOADING };
};
export const fetchRequestLeaseDataSuccess = (data) => {
	return { type: FETCH_REQUEST_LEASE_DATA_SUCCESS, payload: data };
};
export const fetchRequestLeaseDataError = () => {
	return { type: FETCH_REQUEST_LEASE_DATA_ERROR };
};
export const openMarketItemDetails = (item) => {
	return { type: OPEN_MARKET_ITEMS_DETAILS, payload: item };
};
export const closeMarketItemDetails = () => {
	return { type: CLOSE_MARKET_ITEMS_DETAILS };
};
export const openPersonalForm = () => {
	return { type: OPEN_PERSONAL_FORM };
};
export const closePersonalForm = () => {
	return { type: CLOSE_PERSONAL_FORM };
};
export const openBusinessForm = () => {
	return { type: OPEN_BUSINESS_FORM };
};
export const closeBusinessForm = () => {
	return { type: CLOSE_BUSINESS_FORM };
};
export const openInvestorForm = () => {
	return { type: OPEN_INVESTOR_FORM };
};
export const closeInvestorForm = () => {
	return { type: CLOSE_INVESTOR_FORM };
};
export const selectedRequestLeaseItem = (item) => {
	return { type: SELECTED_REQUEST_LEASE_ITEM, payload: item };
};
export const saveDataFromLeaseForm = (data) => {
	return { type: DATA_FROM_LEASE_FORM, payload: data };
};
export const saveDataFromLeaseStructure = (data) => {
	return { type: DATA_FROM_LEASE_STRUCTURE, payload: data };
};
export const openPersonalReqLeaseModal = () => {
	return { type: OPEN_PERSONAL_REQUEST_LEASE_MODAL };
};
export const closePersonalReqLeaseModal = () => {
	return { type: CLOSE_PERSONAL_REQUEST_LEASE_MODAL };
};
export const openBusinessReqLeaseModal = () => {
	return { type: OPEN_BUSINESS_REQUEST_LEASE_MODAL };
};
export const closeBusinessReqLeaseModal = () => {
	return { type: CLOSE_BUSINESS_REQUEST_LEASE_MODAL };
};
export const openInvestorReqLeaseModal = () => {
	return { type: OPEN_INVESTOR_REQUEST_LEASE_MODAL };
};
export const closeInvestorReqLeaseModal = () => {
	return { type: CLOSE_INVESTOR_REQUEST_LEASE_MODAL };
};
export const openConfirmAutoDebitModal = (type) => {
	return { type: OPEN_CONFIRM_AUTODEBIT_MODAL, payload: type };
};
export const closeConfirmAutoDebitModal = () => {
	return { type: CLOSE_CONFIRM_AUTODEBIT_MODAL };
};
export const updateCurrentOngoingTransaction = (data) => {
	return { type: UPDATE_CURRENT_ONGOING_TRANSACTION, payload: data };
};
export const closeOngoingTransactionModal = () => {
	return { type: CLOSE_ONGOING_TRANSACTION_MODAL };
};
export const savePersonalFormSuccess = (data) => {
	return { type: SAVE_PERSONAL_FORM_SUCCESS, payload: data };
};
export const saveBusinessFormLoading = () => {
	return { type: SAVE_BUSINESS_FORM_LOADING };
};
export const saveBusinessFormSuccess = (data) => {
	return { type: SAVE_BUSINESS_FORM_SUCCESS, payload: data };
};
export const saveBusinessFormError = () => {
	return { type: SAVE_BUSINESS_FORM_ERROR };
};
export const savePersonalFormFromParse = (data) => {
	return { type: PERSONAL_FORM_DATA_FROM_PARSE, payload: data };
};
export const saveBusinessFormFromParse = (data) => {
	return { type: BUSINESS_FORM_DATA_FROM_PARSE, payload: data };
};
export const saveInvestorFormLoading = () => {
	return { type: SAVE_INVESTOR_FORM_LOADING };
};
export const saveInvestorFormSuccess = (data) => {
	return { type: SAVE_INVESTOR_FORM_SUCCESS, payload: data };
};
export const saveInvestorFormError = () => {
	return { type: SAVE_INVESTOR_FORM_ERROR };
};
export const saveInvestorFormFromParse = (data) => {
	return { type: INVESTOR_FORM_DATA_FROM_PARSE, payload: data };
};
export const fetchOngoingLeaseLoading = () => {
	return { type: FETCH_ONGOING_LEASE_LOADING };
};
export const fetchOngoingLeaseSuccess = (data) => {
	return { type: FETCH_ONGOING_LEASE_SUCCESS, payload: data };
};
export const fetchOngoingLeaseError = () => {
	return { type: FETCH_ONGOING_LEASE_ERROR };
};
export const fetchLeaseWithQueryLoading = () => {
	return { type: FETCH_LEASE_WITH_QUERY_LOADING };
};
export const fetchLeaseWithQuerySuccess = (data) => {
	return { type: FETCH_LEASE_WITH_QUERY_SUCCESS, payload: data };
};
export const fetchLeaseWithQueryError = () => {
	return { type: FETCH_LEASE_WITH_QUERY_ERROR };
};
export const fetchSubmittedLeaseLoading = () => {
	return { type: FETCH_SUBMITTED_LEASE_LOADING };
};
export const fetchSubmittedLeaseSuccess = (data) => {
	return { type: FETCH_SUBMITTED_LEASE_SUCCESS, payload: data };
};
export const fetchSubmittedLeaseError = () => {
	return { type: FETCH_SUBMITTED_LEASE_ERROR };
};
export const submitNegotiationLoading = () => {
	return { type: SUBMIT_NEGOTIATION_LOADING };
};
export const submitNegotiationSuccess = () => {
	return { type: SUBMIT_NEGOTIATION_SUCCESS };
};
export const submitNegotiationError = () => {
	return { type: SUBMIT_NEGOTIATION_ERROR };
};
export const fetchLeaseBoardDataLoading = () => {
	return { type: FETCH_LEASE_BOARD_DATA_LOADING };
};
export const fetchLeaseBoardDataSuccess = (data) => {
	return { type: FETCH_LEASE_BOARD_DATA_SUCCESS, payload: data };
};
export const fetchLeaseBoardDataError = () => {
	return { type: FETCH_LEASE_BOARD_DATA_ERROR };
};
export const clearSubmittedNegotiation = () => {
	return { type: CLEAR_SUBMITTED_NEGOTIATION };
};
export const selectedProfile = (text) => {
	return { type: SELECTED_PROFILE, payload: text };
};
export const selectedPaymentMethod = (data) => {
	return { type: SELECTED_PAYMENT_METHOD, payload: data };
};
export const openPayWithWalletModal = () => {
	return { type: OPEN_PAY_WITH_WALLET_MODAL };
};
export const closePayWithWalletModal = () => {
	return { type: CLOSE_PAY_WITH_WALLET_MODAL };
};
export const openActivateLeaseBoardModal = (data) => {
	return { type: OPEN_ACTIVATE_LEASE_BOARD_MODAL, payload: data };
};
export const closeActivateLeaseBoardModal = () => {
	return { type: CLOSE_ACTIVATE_LEASE_BOARD_MODAL };
};
export const fetchRegularSavingsLoading = () => {
	return { type: FETCH_REGULAR_SAVINGS_LOADING };
};
export const fetchRegularSavingsSuccess = (data) => {
	return { type: FETCH_REGULAR_SAVINGS_SUCCESS, payload: data };
};
export const fetchRegularSavingsError = () => {
	return { type: FETCH_REGULAR_SAVINGS_ERROR };
};
export const fetchAssetBasedSavingsLoading = () => {
	return { type: FETCH_ASSET_BASED_SAVINGS_LOADING };
};
export const fetchAssetBasedSavingsSuccess = (data) => {
	return { type: FETCH_ASSET_BASED_SAVINGS_SUCCESS, payload: data };
};
export const fetchAssetBasedSavingsError = () => {
	return { type: FETCH_ASSET_BASED_SAVINGS_ERROR };
};
export const createAssetBasedSavingsLoading = () => {
	return { type: CREATE_ASSET_BASED_SAVINGS_LOADING };
};
export const createAssetBasedSavingsSuccess = (data) => {
	return { type: CREATE_ASSET_BASED_SAVINGS_SUCCESS, payload: data };
};
export const createAssetBasedSavingsError = () => {
	return { type: CREATE_ASSET_BASED_SAVINGS_ERROR };
};
export const isCreateAssetSavingsSuccess = () => {
	return { type: IS_CREATE_ASSET_SAVINGS_SUCCESS };
};
export const clearSaveFormSuccess = () => {
	return { type: CLEAR_SAVE_FORM_SUCCESS };
};
export const saveRegularSavingsForm = (data) => {
	return { type: REGULAR_SAVINGS_FORM, payload: data };
};
export const fetchRiskInvestmentsLoading = () => {
	return { type: FETCH_RISK_INVESTMENTS_LOADING };
};
export const fetchRiskInvestmentsSuccess = (data) => {
	return { type: FETCH_RISK_INVESTMENTS_SUCCESS, payload: data };
};
export const fetchRiskInvestmentsError = () => {
	return { type: FETCH_RISK_INVESTMENTS_ERROR };
};
export const investorCreateAssetLoading = () => {
	return { type: INVESTOR_CREATE_ASSET_LOADING };
};
export const investorCreateAssetSuccess = (data) => {
	return { type: INVESTOR_CREATE_ASSET_SUCCESS, payload: data };
};
export const investorCreateAssetError = () => {
	return { type: INVESTOR_CREATE_ASSET_ERROR };
};
export const fetchCategoriesLoading = () => {
	return { type: FETCH_CATEGORIES_LOADING };
};
export const fetchCategoriesSuccess = (data) => {
	return { type: FETCH_CATEGORIES_SUCCESS, payload: data };
};
export const fetchCategoriesError = () => {
	return { type: FETCH_CATEGORIES_ERROR };
};
export const fetchBrandsLoading = () => {
	return { type: FETCH_BRANDS_LOADING };
};
export const fetchBrandsSuccess = (data) => {
	return { type: FETCH_BRANDS_SUCCESS, payload: data };
};
export const fetchBrandsError = () => {
	return { type: FETCH_BRANDS_ERROR };
};
export const getRentalFeeLoading = () => {
	return { type: GET_RENTAL_FEE_LOADING };
};
export const getRentalFeeSuccess = (data) => {
	return { type: GET_RENTAL_FEE_SUCCESS, payload: data };
};
export const getRentalFeeError = () => {
	return { type: GET_RENTAL_FEE_ERROR };
};
export const getDeliveryFeeLoading = () => {
	return { type: GET_DELIVERY_FEE_LOADING };
};
export const getDeliveryFeeSuccess = (data) => {
	return { type: GET_DELIVERY_FEE_SUCCESS, payload: data };
};
export const getDeliveryFeeError = () => {
	return { type: GET_DELIVERY_FEE_ERROR };
};
export const getCurrentContextInterest = (interest) => {
	return { type: GET_CURRENT_CONTEXT_INTEREST, payload: interest };
};
export const clearRegularSavings = () => {
	return { type: CLEAR_REGULAR_SAVINGS };
};
export const createLeaseLoading = () => {
	return { type: CREATE_LEASE_LOADING };
};
export const createLeaseSuccess = (data) => {
	return { type: CREATE_LEASE_SUCCESS, payload: data };
};
export const createLeaseError = () => {
	return { type: CREATE_LEASE_ERROR };
};
export const updateOngoingLeaseLoading = () => {
	return { type: UPDATE_ONGOING_LEASE_LOADING };
};
export const updateOngoingLeaseSuccess = (data) => {
	return { type: UPDATE_ONGOING_LEASE_SUCCESS, payload: data };
};
export const updateOngoingLeaseError = () => {
	return { type: UPDATE_ONGOING_LEASE_ERROR };
};
export const clearUpdateLeaseSuccess = () => {
	return { type: CLEAR_UPDATE_LEASE_SUCCESS };
};
export const openFilterModal = () => {
	return { type: OPEN_FILTER_MODAL };
};
export const closeFilterModal = () => {
	return { type: CLOSE_FILTER_MODAL };
};
export const openDeleteLeaseModal = (objectIds) => {
	return { type: OPEN_DELETE_ONGOING_LEASE_MODAL, payload: objectIds };
};
export const closeDeleteLeaseModal = () => {
	return { type: CLOSE_DELETE_ONGOING_LEASE_MODAL };
};
export const deleteOngoingLeaseLoading = () => {
	return { type: DELETE_ONGOING_LEASE_LOADING };
};
export const deleteOngoingLeaseSuccess = () => {
	return { type: DELETE_ONGOING_LEASE_SUCCESS };
};
export const deleteOngoingLeaseError = () => {
	return { type: DELETE_ONGOING_LEASE_ERROR };
};
export const openDeleteInactiveInvestmentModal = (data) => {
	return { type: OPEN_DELETE_INACTIVE_INVESTMENT_MODAL, payload: data };
};
export const closeDeleteInactiveInvestmentModal = () => {
	return { type: CLOSE_DELETE_INACTIVE_INVESTMENT_MODAL };
};
export const deleteInactiveAssetLoading = () => {
	return { type: DELETE_INACTIVE_ASSET_LOADING };
};
export const deleteInactiveAssetSuccess = () => {
	return { type: DELETE_INACTIVE_ASSET_SUCCESS };
};
export const deleteInactiveAssetError = () => {
	return { type: DELETE_INACTIVE_ASSET_ERROR };
};
export const createRegSavingsLoading = () => {
	return { type: CREATE_REGULAR_SAVINGS_LOADING };
};
export const createRegSavingsSuccess = (data) => {
	return { type: CREATE_REGULAR_SAVINGS_SUCCESS, payload: data };
};
export const createRegSavingsError = () => {
	return { type: CREATE_REGULAR_SAVINGS_ERROR };
};
export const clearRegularSavingsCreatedSuccess = () => {
	return { type: CLEAR_REGULAR_SAVINGS_CREATED_SUCCESS };
};
export const submitOngoingLeaseSuccess = (data) => {
	return { type: SUBMIT_ONGOING_LEASE_SUCCESS, payload: data };
};
export const createRiskInvestmentsLoading = () => {
	return { type: CREATE_RISK_INVESTMENTS_LOADING };
};
export const createRiskInvestmentsSuccess = (data) => {
	return { type: CREATE_RISK_INVESTMENTS_SUCCESS, payload: data };
};
export const createRiskInvestmentsError = () => {
	return { type: CREATE_RISK_INVESTMENTS_ERROR };
};
export const activateAssetLoading = () => {
	return { type: ACTIVATE_ASSET_LOADING };
};
export const activateAssetSuccess = (data) => {
	return { type: ACTIVATE_ASSET_SUCCESS, payload: data };
};
export const activateAssetError = () => {
	return { type: ACTIVATE_ASSET_ERROR };
};
export const fetchSingleAssetBasedSavingsLoading = () => {
	return { type: FETCH_SINGLE_ASSET_BASED_SAVINGS_LOADING };
};
export const fetchSingleAssetBasedSavingsSuccess = (data) => {
	return { type: FETCH_SINGLE_ASSET_BASED_SAVINGS_SUCCESS, payload: data };
};
export const fetchSingleAssetBasedSavingsError = () => {
	return { type: FETCH_SINGLE_ASSET_BASED_SAVINGS_ERROR };
};
export const updateAssetBasedSavings = (data) => {
	return { type: UPDATE_SINGLE_ASSET_BASED_SAVINGS, payload: data };
};
export const clearUpdateAssetBasedSavingsSuccess = () => {
	return { type: CLEAR_UPDATE_ASSET_SAVINGS_SUCCESS };
};
export const openAddFundsToAssetSavingsModal = () => {
	return { type: OPEN_ADD_FUNDS_TO_ASSET_SAVINGS };
};
export const closeAddFundsToAssetSavingsModal = () => {
	return { type: CLOSE_ADD_FUNDS_TO_ASSET_SAVINGS };
};
export const clearLeaseStructureData = () => {
	return { type: CLEAR_LEASE_STRUCTURE_DATA };
};
export const queryPartialFundedAssetLoading = () => {
	return { type: QUERY_PARTIAL_FUNDED_ASSET_LOADING };
};
export const queryPartialFundedAssetSuccess = (data) => {
	return { type: QUERY_PARTIAL_FUNDED_ASSET_SUCCESS, payload: data };
};
export const queryPartialFundedAssetError = () => {
	return { type: QUERY_PARTIAL_FUNDED_ASSET_ERROR };
};
export const clearActivateAssetSuccess = () => {
	return { type: CLEAR_ACTIVATE_ASSET_SUCCESS };
};
export const openMoveFundsFromRegSavingsModal = () => {
	return { type: OPEN_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL };
};
export const closeMoveFundsFromRegSavingsModal = () => {
	return { type: CLOSE_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL };
};
export const openMoveFundsFromAssetSavingsModal = () => {
	return { type: OPEN_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL };
};
export const closeMoveFundsFromAssetSavingsModal = () => {
	return { type: CLOSE_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL };
};
export const queryPartialFundedRequestLoading = () => {
	return { type: QUERY_PARTIAL_FUNDED_REQUEST_LOADING };
};
export const queryPartialFundedRequestSuccess = (data) => {
	return { type: QUERY_PARTIAL_FUNDED_REQUEST_SUCCESS, payload: data };
};
export const queryPartialFundedRequestError = () => {
	return { type: QUERY_PARTIAL_FUNDED_REQUEST_ERROR };
};
export const investorUpdateRequestStatus = (data) => {
	return { type: INVESTOR_UPDATE_REQUEST_STATUS, payload: data };
};
export const onTogglePayDeliveryFeeModal = (state, id) => {
	return { type: TOGGLE_PAY_DELIVERY_FEE_MODAL, payload: { state, id: id } };
};
export const onToggleApplyInsuranceModal = (state) => {
	return { type: TOGGLE_APPLY_INSURANCE_MODAL, payload: state };
};
export const onToggleRegisterAssetModal = (state) => {
	return { type: TOGGLE_REGISTER_ASSET_MODAL, payload: state };
};
export const registerAssetLoading = () => {
	return { type: REGISTER_ASSET_LOADING };
};
export const registerAssetSuccess = (data) => {
	return { type: REGISTER_ASSET_SUCCESS, payload: data };
};
export const registerAssetError = () => {
	return { type: REGISTER_ASSET_ERROR };
};
export const activateInsuranceLoading = () => {
	return { type: ACTIVATE_INSURANCE_LOADING };
};
export const activateInsuranceSuccess = (data) => {
	return { type: ACTIVATE_INSURANCE_SUCCESS, payload: data };
};
export const activateInsuranceError = () => {
	return { type: ACTIVATE_INSURANCE_ERROR };
};
export const creditAssessmentLoading = () => {
	return { type: CREDIT_ASSESSMENT_LOADING };
};
export const creditAssessmentSuccess = (data) => {
	return { type: CREDIT_ASSESSMENT_SUCCESS, payload: data };
};
export const creditAssessmentError = () => {
	return { type: CREDIT_ASSESSMENT_ERROR };
};
export const clearCreditAssessmentSuccess = () => {
	return { type: CLEAR_CREDIT_ASSESSMENT_SUCCESS };
};
