import React, { useEffect } from "react";
import Navbar from "../../layout/Navbar";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import Balance from "../../components/Balance";
import Container from "../../layout/Container";
import { Helmet } from "react-helmet";
import { CustomTabSelector } from "../../layout/CustomComponents";
import { useState } from "react";
import DashOverview from "./DashOverview";
import TabAccounts from "../../components/TabAccounts";
import { useDispatch, useSelector } from "react-redux";
import {
	getCreditScore,
	getUpcomingPayments,
} from "../../redux/authRedux/authServices";
import Parse from "../../api/ApiConfig";
import { walletType } from "../../common/utils";
// import { moveUserFunds } from "../../redux/payments/paymentServices";

const Dashboard = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(1);
	const currentSessionToken = Parse.User.current()?.getSessionToken();
	const { customerWallet } = useSelector((state) => state.payment);

	// const details = {
	// 	amount: "5",
	// 	source: "main",
	// 	// source: walletType.financeInvestment,
	// 	destination: walletType.financeInvestment,
	// 	// destination: "main",
	// 	message: "test",
	// };

	// get credit score and upcoming payments
	useEffect(() => {
		dispatch(getCreditScore(currentSessionToken));
		dispatch(getUpcomingPayments(currentSessionToken));
		// dispatch(moveUserFunds(currentSessionToken, details));
	}, []);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	return (
		<>
			<MobileNavDetails text="dashboard" />
			<Helmet>
				<title>Fortcore - Dashboard</title>
			</Helmet>
			<Container>
				<>
					<Navbar text="dashboard" />
					<div className="mt-1 sm:mt-[55px]"></div>
					<Balance
						wallet={mainWallet}
						showAddFunds
						showWithdrawFunds
						showMoveFunds
					/>
					<div className="my-6 sm:my-8 lg:my-10">
						<CustomTabSelector
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
					</div>
					<div className="mb-8">
						{activeTab === 1 && <DashOverview />}
						{activeTab === 2 && <TabAccounts />}
					</div>
				</>
			</Container>
		</>
	);
};

export default Dashboard;
