import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeMarketItemDetails } from "../../../redux/lease/leaseActions";
import PropTypes from "prop-types";
import { MdOutlineClose } from "react-icons/md";
import Container from "../../../layout/Container";

const ItemDescription = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(1);
	const { currentMarketPlaceItem } = useSelector((state) => state.lease);

	return (
		<Container>
			<div className="h-[78vh] mx-auto sm:h-[85vh] max-h-[550px] sm:max-h-[600px] py-5 sm:py-5 lg:py-6 bg-white rounded-md space-y-4 sm:my-5 md:my-6">
				<div className="flex justify-end px-4 sm:px-5 lg:px-6">
					<button
						className="font-semibold"
						onClick={() => dispatch(closeMarketItemDetails())}
					>
						<MdOutlineClose color="#00525D" size={17} />
					</button>
				</div>
				<div className="flex justify-between gap-1 px-4 sm:px-5 lg:px-6 sm:justify-start sm:gap-5 border-b border-[#EEF2F3]">
					<Tab
						text="description"
						onTabSelect={() => setActiveTab(1)}
						isCurrentTab={activeTab === 1}
					/>
					<Tab
						text="key fetaures"
						onTabSelect={() => setActiveTab(2)}
						isCurrentTab={activeTab === 2}
					/>
					<Tab
						text="specification"
						onTabSelect={() => setActiveTab(3)}
						isCurrentTab={activeTab === 3}
					/>
				</div>
				<div className="h-[82%] px-4 sm:px-5 lg:px-6 overflow-y-auto scroll-wrapper">
					{activeTab === 1 && (
						<p className="text-[13px] text-[#393F42] text-justify leading-6 zabalRegFont">
							{Object.hasOwn(currentMarketPlaceItem, "description") &&
							currentMarketPlaceItem?.description?.length > 0
								? currentMarketPlaceItem?.description
								: "No information available for this product."}
						</p>
					)}
					{activeTab === 2 && (
						<ul className="text-[13px] text-[#393F42] text-justify leading-6 zabalRegFont">
							{Object.hasOwn(currentMarketPlaceItem, "keyFeatures") &&
							currentMarketPlaceItem?.keyFeatures?.length > 0
								? currentMarketPlaceItem?.keyFeatures
										.split(";")
										.filter((a) => a.length > 0)
										.map((a, i) => (
											<li key={i} className="list-disc ml-2.5 md:ml-3">
												{a.trim()}.
											</li>
										))
								: "No information available for this product."}
						</ul>
					)}
					{activeTab === 3 && (
						<ul className="text-[13px] text-[#393F42] text-justify leading-6 zabalRegFont">
							{Object.hasOwn(currentMarketPlaceItem, "specifications") &&
							currentMarketPlaceItem?.specifications?.length > 0
								? currentMarketPlaceItem?.specifications
										.split(";")
										.filter((a) => a.length > 0)
										.map((a, i) => (
											<li key={i} className="list-disc ml-2.5 md:ml-3">
												{a.trim()}.
											</li>
										))
								: "No information available for this product."}
						</ul>
					)}
				</div>
			</div>
		</Container>
	);
};
export default ItemDescription;

const Tab = ({ text, onTabSelect, isCurrentTab }) => {
	return (
		<button
			className={`capitalize px-2 sm:px-3 text-[13px] sm:text-sm zabalMedFont ${
				isCurrentTab
					? "text-primary-color border-b-2 border-primary-color"
					: "text-[#8E9DA4]"
			}`}
			onClick={onTabSelect}
		>
			{text}
		</button>
	);
};

Tab.propTypes = {
	text: PropTypes.string,
	onTabSelect: PropTypes.func,
	isCurrentTab: PropTypes.bool,
};
