import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	CustomBigSelect,
	FinanceType,
	InvestmentType,
} from "../layout/CustomComponents";
import {
	openConfirmAutoDebitModal,
	selectedProfile,
} from "../redux/lease/leaseActions";
import { useDispatch, useSelector } from "react-redux";
import { formatAmountToIncludeCurrency, walletType } from "../common/utils";
import { onSelectFinanceOption } from "../redux/financing/financeActions";

const TabAccounts = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [toggleInvestment, setToggleInvestment] = useState(false);
	const [toggleFinancing, setToggleFinancing] = useState(false);
	const [toggleSavings, setToggleSavings] = useState(false);

	const { customerWallet } = useSelector((state) => state.payment);

	const leaseInvW =
		customerWallet?.find(
			(wallet) => wallet?.product === walletType.leaseInvestment
		)?.availableBalance || 0;

	const financeInvW =
		customerWallet?.find((wallet) => wallet?.product === "apprentice-fixed")
			?.availableBalance || 0;

	const regularSavingsWallet = formatAmountToIncludeCurrency(
		undefined,
		customerWallet?.find(
			(wallet) => wallet?.product === walletType.regularSavings
		)?.availableBalance || 0
	)?.split(".");

	const assetBasedSavingsWallet = formatAmountToIncludeCurrency(
		undefined,
		customerWallet?.find(
			(wallet) => wallet?.product === walletType.assetBasedSavings
		)?.availableBalance || 0
	)?.split(".");

	// const riskFreeWallet = formatAmountToIncludeCurrency(
	// 	undefined,
	// 	customerWallet?.find(
	// 		(wallet) => wallet?.product === walletType.riskFreeInvestment
	// 	)?.availableBalance || 0
	// )?.split(".");

	const leaseInvestmentWallet = formatAmountToIncludeCurrency(
		undefined,
		leaseInvW
	)?.split(".");

	const totalSavings = formatAmountToIncludeCurrency(
		undefined,
		Number(regularSavingsWallet?.at(0)) + Number(assetBasedSavingsWallet?.at(0))
	).split(".");

	const financeInvestmentWallet = formatAmountToIncludeCurrency(
		undefined,
		financeInvW
	)?.split(".");

	const totalInvestments = formatAmountToIncludeCurrency(
		undefined,
		leaseInvW + financeInvW
	).split(".");

	return (
		<div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4 items-start sm:mt-0">
			<CustomBigSelect
				text="Total Investment"
				toggleModal={toggleInvestment}
				amount={
					<>
						<span className="text-xs font-semibold">NGN </span>
						<span className="font-medium text-xs">
							{totalInvestments?.at(0)}.
						</span>
						<span className="font-medium text-[11.25px]">
							{totalInvestments?.at(1)}
						</span>
					</>
				}
				onRequestToggle={() => {
					setToggleInvestment((prev) => !prev);
					setToggleFinancing(false);
					setToggleSavings(false);
				}}
			>
				<>
					{toggleInvestment && (
						<div className="divide-y divide-[#EEF2F3] pb-4">
							{location.pathname.includes("/dashboard") && (
								<>
									{/* <InvestmentType
										text="Risk Free Investment"
										amount={{
											whole: riskFreeWallet?.at(0),
											decimal: riskFreeWallet?.at(1),
										}}
										url="/leasing/risk-free-investment/view"
										onReqClick={() => navigate("/leasing/risk-free-investment")}
									/> */}
									<InvestmentType
										text="Lease Investment"
										amount={{
											whole: leaseInvestmentWallet?.at(0),
											decimal: leaseInvestmentWallet?.at(1),
										}}
										url="/leasing/investment-board"
										onReqClick={() => {
											navigate(
												"/leasing/new-transaction/investor-market-place"
											);
											dispatch(selectedProfile("investor"));
										}}
									/>
									<InvestmentType
										text="Apprenticeship Finance Investment"
										amount={{
											whole: financeInvestmentWallet?.at(0),
											decimal: financeInvestmentWallet?.at(1),
										}}
										url="/financing"
										onReqClick={() => navigate("/financing")}
									/>
								</>
							)}
							{location.pathname.includes("/leasing") && (
								<>
									{/* <InvestmentType
										text="Risk Free Investment"
										amount={{
											whole: riskFreeWallet?.at(0),
											decimal: riskFreeWallet?.at(1),
										}}
										url="/leasing/risk-free-investment/view"
										onReqClick={() => navigate("/leasing/risk-free-investment")}
									/> */}
									<InvestmentType
										text="Lease Investment"
										amount={{
											whole: leaseInvestmentWallet?.at(0),
											decimal: leaseInvestmentWallet?.at(1),
										}}
										url="/leasing/investment-board"
										onReqClick={() => {
											navigate(
												"/leasing/new-transaction/investor-market-place"
											);
											dispatch(selectedProfile("investor"));
										}}
									/>
								</>
							)}
							{location.pathname.includes("/financing") && (
								<>
									{/* <InvestmentType
										text="Risk Free Investment"
										amount={{ whole: "00", decimal: "00" }}
										url="/financing/risk-free-investment/view"
										onReqClick={() =>
											navigate("/financing/risk-free-investment")
										}
									/> */}
									<InvestmentType
										text="Apprenticeship Finance"
										amount={{
											whole: financeInvestmentWallet?.at(0),
											decimal: financeInvestmentWallet?.at(1),
										}}
										url="/financing/investment-portfolio"
										onReqClick={() => {
											navigate(
												"/financing/new-transaction/investor-market-place"
											);
											dispatch(onSelectFinanceOption("investor-browse"));
										}}
									/>
								</>
							)}
						</div>
					)}
				</>
			</CustomBigSelect>
			<CustomBigSelect
				text="Total Financing"
				toggleModal={toggleFinancing}
				amount={
					<>
						<span className="text-xs font-semibold">NGN </span>
						<span className="font-medium text-xs">00.</span>
						<span className="font-medium text-[11.25px]">00</span>
					</>
				}
				onRequestToggle={() => {
					setToggleFinancing((prev) => !prev);
					setToggleInvestment(false);
					setToggleSavings(false);
				}}
			>
				<>
					{toggleFinancing && (
						<div className="divide-y divide-[#EEF2F3] pb-4">
							{location.pathname !== "/financing" && (
								<FinanceType
									text="Leasing"
									amount={{ whole: "00", decimal: "00" }}
								/>
							)}
							{location.pathname !== "/leasing" && (
								<FinanceType
									text="Apprenticeship Finance"
									amount={{ whole: "00", decimal: "00" }}
								/>
							)}
						</div>
					)}
				</>
			</CustomBigSelect>
			<CustomBigSelect
				text="Total Savings"
				toggleModal={toggleSavings}
				amount={
					<>
						<span className="text-xs font-semibold">NGN </span>
						<span className="font-medium text-xs">{totalSavings?.at(0)}.</span>
						<span className="font-medium text-[11.25px]">
							{totalSavings?.at(1)}
						</span>
					</>
				}
				onRequestToggle={() => {
					setToggleSavings((prev) => !prev);
					setToggleInvestment(false);
					setToggleFinancing(false);
				}}
			>
				<>
					{toggleSavings && (
						<div className="divide-y divide-[#EEF2F3] pb-4">
							{location.pathname.includes("/dashboard") && (
								<>
									<InvestmentType
										text="Lease Savings"
										amount={{ whole: "00", decimal: "00" }}
										onReqClick={() => navigate("/leasing/savings-overview")}
										isSave={true}
										url="/leasing/savings-overview"
									/>
									<InvestmentType
										text="Apprentice Finance Savings"
										amount={{ whole: "00", decimal: "00" }}
										onReqClick={() => navigate("/financing")}
										isSave={true}
										url="/financing"
									/>
									<InvestmentType
										text="Travel Advance Savings"
										amount={{
											whole: "00",
											decimal: "00",
										}}
										onReqClick={() =>
											dispatch(openConfirmAutoDebitModal("travel"))
										}
										isSave={true}
										url="/travels/regular-savings/view"
									/>
								</>
							)}
							{location.pathname.includes("/leasing") && (
								<>
									<InvestmentType
										text="Regular Savings"
										amount={{
											whole: regularSavingsWallet?.at(0),
											decimal: regularSavingsWallet?.at(1),
										}}
										onReqClick={() =>
											dispatch(openConfirmAutoDebitModal("lease"))
										}
										isSave={true}
										url="/leasing/regular-savings/view"
									/>
									<InvestmentType
										text="Asset Based Savings"
										amount={{
											whole: assetBasedSavingsWallet?.at(0),
											decimal: assetBasedSavingsWallet?.at(1),
										}}
										onReqClick={() => navigate("/leasing/asset-based-savings")}
										url="/leasing/asset-based-savings/view"
										isSave={true}
									/>
								</>
							)}
							{location.pathname.includes("/financing") && (
								<>
									<InvestmentType
										text="Regular Savings"
										amount={{
											whole: regularSavingsWallet?.at(0),
											decimal: regularSavingsWallet?.at(1),
										}}
										onReqClick={() => dispatch(openConfirmAutoDebitModal("AF"))}
										isSave={true}
										url="/fiancing/regular-savings-view"
									/>
								</>
							)}
						</div>
					)}
				</>
			</CustomBigSelect>
		</div>
	);
};
export default TabAccounts;
