import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	CustomCheckboxInput,
	CustomRadioInput,
	PlainSelect,
	SelectDropDown,
} from "../../../../layout/CustomComponents";
import {
	InputForAmount,
	ProfileInput,
} from "../../../profile/sections/PersonalInfo";
import { BUSINESSTYPE } from "../../../../common/utils";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import Parse from "../../../../api/ApiConfig";
import { saveProfBusinessInformation } from "../../../../redux/travels/travelServices";

const ProfAndBusinessInfo = () => {
	const dispatch = useDispatch();
	const { currentUser } = useSelector((state) => state.auth);
	const { profBusinessInfoLoading, profBusinessInfoSuccess } = useSelector(
		(state) => state.travel
	);
	const [toggleBusinessType, setToggleBusinessType] = useState(false);
	const [toggleBusinessTypeTwo, setToggleBusinessTypeTwo] = useState(false);
	const [toggleRegStatus, setToggleRegStatus] = useState(false);
	const [toggleNumOfStaffs, setToggleNumOfStaffs] = useState(false);

	const [selectedProfile, setSelectedProfile] = useState(
		profBusinessInfoSuccess?.profile || ""
	);
	const [isCurrentRole, setIsCurrentRole] = useState(
		profBusinessInfoSuccess?.isCurrentRole ? true : false
	);

	const handleProfileSelect = (e) => setSelectedProfile(e.target.value);

	const handleToggleBusinessTwo = () => {
		if (selectedProfile !== "business") return;
		setToggleBusinessTypeTwo((prev) => !prev);
		setToggleRegStatus(false);
		setToggleNumOfStaffs(false);
	};

	const handleToggleRegStatus = () => {
		if (selectedProfile !== "business") return;
		setToggleRegStatus((prev) => !prev);
		setToggleBusinessTypeTwo(false);
		setToggleNumOfStaffs(false);
	};

	const handleToggleNumOfStaffs = () => {
		if (selectedProfile !== "business") return;
		setToggleNumOfStaffs((prev) => !prev);
		setToggleBusinessTypeTwo(false);
		setToggleRegStatus(false);
	};

	const [professionalInfo, setProfessionalInfo] = useState({
		employer: profBusinessInfoSuccess?.recentEmployer || "",
		work_address: profBusinessInfoSuccess?.workAddress || "",
		role: profBusinessInfoSuccess?.role || "",
		industry: profBusinessInfoSuccess?.industry || "",
		start_date: profBusinessInfoSuccess?.startDate || "",
		end_date: profBusinessInfoSuccess?.endDate || "",
	});

	const { employer, work_address, role, industry, start_date, end_date } =
		professionalInfo;

	const [businessInfo, setBusinessInfo] = useState({
		business_name:
			currentUser?.businessName || profBusinessInfoSuccess?.businessName || "",
		business_type:
			currentUser?.businessType || profBusinessInfoSuccess?.businessType || "",
		registration_status:
			currentUser?.incorporationStatus ||
			profBusinessInfoSuccess?.registrationStatus ||
			"",
		number_of_staff: profBusinessInfoSuccess?.numberOfStaffs || "",
		annual_turnover: profBusinessInfoSuccess?.annualTurnover || "",
	});

	const {
		business_name,
		business_type,
		registration_status,
		number_of_staff,
		annual_turnover,
	} = businessInfo;

	const handleChange = (e) =>
		setProfessionalInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const handleChangeTwo = (e) =>
		setBusinessInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const isDisabled = () => {
		if (isCurrentRole && selectedProfile !== "professional") {
			return true;
		}
		if (isCurrentRole) {
			return true;
		}
		if (selectedProfile !== "professional") {
			return true;
		} else {
			return false;
		}
	};

	const getDate = () => {
		const earlyDate = new Date(start_date);
		const lateDate = new Date(end_date);
		if (!isCurrentRole && earlyDate > lateDate) {
			return true;
		} else {
			return false;
		}
	};

	const isBtnDisabled = () => {
		if (selectedProfile === "") {
			return true;
		}
		if (selectedProfile === "professional") {
			if (
				employer === "" ||
				start_date === "" ||
				work_address === "" ||
				role === "" ||
				industry === ""
			) {
				return true;
			}
			if (end_date == "" && !isCurrentRole) {
				return true;
			}
		}
		if (getDate()) {
			return true;
		}
		if (selectedProfile === "business") {
			if (
				business_name === "" ||
				business_type === "" ||
				registration_status === "" ||
				number_of_staff === "" ||
				annual_turnover === ""
			) {
				return true;
			} else {
				return false;
			}
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onSelectedProfileSave = () => {
		const data = {
			role,
			industry,
			profile: selectedProfile,
			workAddress: work_address,
			startDate: start_date,
			endDate: end_date,
			isCurrentRole,
			recentEmployer: employer,
			userId: user,
			businessName: business_name,
			businessType: business_type,
			registrationStatus: registration_status,
			numberOfStaffs: number_of_staff,
			annualTurnover: annual_turnover,
		};
		dispatch(saveProfBusinessInformation(data, selectedProfile));
	};

	return (
		<div className="flex flex-col gap-3 md:gap-3.5 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF] space-y-1.5">
			<p className="text-[#393F42] text-xs zabalRegFont">
				Choose which profile applies to you
			</p>
			<div className="space-y-3 md:space-y-4">
				<SelectProfile
					text="professional information"
					value="professional"
					selectedProfile={selectedProfile}
					isSelected={selectedProfile === "professional"}
					handleChange={handleProfileSelect}
				/>
				<div
					className={`flex flex-col gap-3 md:gap-3.5 relative ${
						selectedProfile === "professional"
							? "!text-[#393F42]"
							: "!text-[#8E9DA4]"
					}`}
				>
					{selectedProfile !== "professional" && (
						<div className="absolute h-full w-full top-0 bg-transparent z-[6]"></div>
					)}
					<ProfileInput
						type="text"
						text="Recent Employer"
						value={employer}
						id="employer"
						placeholderText="Enter recent employer"
						handleChange={handleChange}
						isFieldDisabled={selectedProfile !== "professional"}
					/>
					<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 md:gap-7 relative z-[1]">
						<label
							htmlFor="start_date"
							className={`w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont ${
								getDate() && "md:-mt-5"
							}`}
						>
							Start Date:
						</label>
						<div className="w-full sm:w-[70%] md:w-4/5 text-sm flex md:gap-4 lg:gap-8">
							<div className="relative w-full md:w-2/5">
								<input
									type="month"
									className="bg-transparent rounded h-[45px] w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5 zabalRegFont"
									value={start_date}
									onChange={handleChange}
									id="start_date"
									max={moment().format("YYYY-MM")}
									disabled={selectedProfile !== "professional"}
								/>
								{selectedProfile !== "professional" && (
									<span className="absolute right-2 h-[45px] w-[30px] grid place-items-center top-0">
										<MdLock size={18} color="#717E83" />
									</span>
								)}
							</div>
							<div className="hidden md:block md:w-3/5 space-y-1.5">
								<div className="md:flex items-center gap-4">
									<label
										htmlFor="end_date"
										className="text-xs md:text-[13px] zabalMedFont whitespace-pre"
									>
										End Date:
									</label>
									<div className="relative w-full">
										<input
											type="month"
											className="bg-transparent rounded h-[45px] w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5 zabalRegFont"
											value={end_date}
											min={moment(start_date).format("YYYY-MM")}
											onChange={handleChange}
											id="end_date"
											disabled={
												isCurrentRole || selectedProfile !== "professional"
											}
										/>
										{isDisabled() && (
											<span className="absolute right-2 h-[45px] w-[30px] grid place-items-center -top-1.5 md:top-0">
												<MdLock size={18} color="#717E83" />
											</span>
										)}
									</div>
								</div>
								{!end_date && (
									<div className="top-[70px] flex gap-2 justify-end">
										<CustomCheckboxInput
											handleChange={(e) => setIsCurrentRole(e.target.checked)}
											isChecked={isCurrentRole}
											value="Present"
										/>
										<p className="text-xs zabalRegFont text-[#393F42]">
											I am currently working in this role
										</p>
									</div>
								)}
								{getDate() && (
									<p className="top-[70px] text-xs zabalRegFont text-red-500 text-end">
										End date cannot be earlier than start date
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 md:hidden">
						<label
							htmlFor="start_date"
							className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont"
						>
							End Date:
						</label>
						<div className="relative w-full sm:w-[70%] space-y-1.5">
							<input
								type="month"
								className="bg-transparent rounded h-[45px] w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5 zabalRegFont"
								value={end_date}
								onChange={handleChange}
								min={moment(start_date).format("YYYY-MM")}
								id="end_date"
								disabled={isCurrentRole || selectedProfile !== "professional"}
							/>
							{isDisabled() && (
								<span className="absolute right-2 h-[45px] w-[30px] grid place-items-center -top-1.5">
									<MdLock size={18} color="#717E83" />
								</span>
							)}
							{!end_date && (
								<div className="flex gap-2">
									<CustomCheckboxInput
										handleChange={(e) => setIsCurrentRole(e.target.checked)}
										isChecked={isCurrentRole}
										value="Present"
									/>
									<p className="text-xs zabalRegFont text-[#393F42]">
										I am currently working in this role
									</p>
								</div>
							)}
							{getDate() && (
								<p className="top-[70px] text-xs zabalRegFont text-red-500">
									End date cannot be earlier than start date
								</p>
							)}
						</div>
					</div>
					<ProfileInput
						type="text"
						text="Work Address"
						value={work_address}
						id="work_address"
						placeholderText="Enter work address"
						handleChange={handleChange}
						isFieldDisabled={selectedProfile !== "professional"}
					/>
					<ProfileInput
						type="text"
						text="Role"
						value={role}
						id="role"
						placeholderText="Enter your role"
						isFieldDisabled={selectedProfile !== "professional"}
						handleChange={handleChange}
					/>
					<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 md:gap-7 relative z-[5]">
						<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
							Industry:
						</p>
						<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
							<PlainSelect
								context="choose industry"
								isOpened={toggleBusinessType}
								currentText={industry}
								handleToggle={() => {
									if (selectedProfile !== "professional") return;
									setToggleBusinessType((prev) => !prev);
								}}
							/>
							{toggleBusinessType && (
								<div className="absolute z-[2] w-full top-[48px] pb-8">
									<SelectDropDown
										arrOptions={BUSINESSTYPE}
										onHandleSelect={(d) => {
											setProfessionalInfo((prev) => ({
												...prev,
												industry: d,
											}));
											setTimeout(() => {
												setToggleBusinessType(false);
											}, 100);
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="space-y-3 md:space-y-4">
				<SelectProfile
					text="business information"
					value="business"
					selectedProfile={selectedProfile}
					isSelected={selectedProfile === "business"}
					handleChange={handleProfileSelect}
				/>
				<div
					className={`flex flex-col gap-3 md:gap-3.5 relative ${
						selectedProfile === "business"
							? "!text-[#393F42]"
							: "!text-[#8E9DA4]"
					}`}
				>
					{selectedProfile !== "business" && (
						<div className="absolute h-full w-full top-0 bg-transparent z-[4]"></div>
					)}
					<ProfileInput
						type="text"
						text="Business Name"
						value={business_name}
						id="business_name"
						placeholderText="Enter business name"
						handleChange={handleChangeTwo}
						isFieldDisabled={selectedProfile !== "business"}
					/>
					<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 md:gap-7 relative z-[3]">
						<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
							Business Type:
						</p>
						<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
							<PlainSelect
								context="choose business type"
								isOpened={toggleBusinessTypeTwo}
								currentText={business_type}
								handleToggle={handleToggleBusinessTwo}
							/>
							{toggleBusinessTypeTwo && (
								<div className="absolute z-[2] w-full top-[48px] pb-8">
									<SelectDropDown
										arrOptions={BUSINESSTYPE}
										onHandleSelect={(d) => {
											setBusinessInfo((prev) => ({
												...prev,
												business_type: d,
											}));
											setTimeout(() => {
												setToggleBusinessTypeTwo(false);
											}, 100);
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 md:gap-7 relative z-[2]">
						<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
							Registration Status:
						</p>
						<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
							<PlainSelect
								context="select registration status"
								isOpened={toggleRegStatus}
								currentText={registration_status}
								handleToggle={handleToggleRegStatus}
							/>
							{toggleRegStatus && (
								<div className="absolute z-[3] w-full top-[48px] pb-8">
									<SelectDropDown
										arrOptions={["Pending", "Completed", "Not Started"]}
										onHandleSelect={(d) => {
											setBusinessInfo((prev) => ({
												...prev,
												registration_status: d,
											}));
											setTimeout(() => {
												setToggleRegStatus(false);
											}, 100);
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 md:gap-7 relative z-[1]">
						<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
							Number of staffs:
						</p>
						<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
							<PlainSelect
								context="select number of staffs"
								isOpened={toggleNumOfStaffs}
								currentText={number_of_staff}
								handleToggle={handleToggleNumOfStaffs}
							/>
							{toggleNumOfStaffs && (
								<div className="absolute z-[2] w-full top-[48px] pb-8">
									<SelectDropDown
										arrOptions={[
											"1 - 5",
											"5 - 10",
											"10 - 20",
											"20 - 50",
											"Above 50",
										]}
										onHandleSelect={(d) => {
											setBusinessInfo((prev) => ({
												...prev,
												number_of_staff: d,
											}));
											setTimeout(() => {
												setToggleNumOfStaffs(false);
											}, 100);
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<InputForAmount
						labelText="Annal Turnover"
						id="annual_turnover"
						value={annual_turnover}
						handleChange={handleChangeTwo}
						placeholderText="Enter annual turnover"
						isDisabled={selectedProfile !== "business"}
					/>
				</div>
				<div className="hidden sm:flex justify-end py-2">
					<CustomGreenBtn
						text="save"
						width="105px"
						height="42px"
						fontSize="13px"
						isDisabled={isBtnDisabled() || profBusinessInfoLoading}
						isLoading={profBusinessInfoLoading}
						onRequestClick={onSelectedProfileSave}
					/>
				</div>
				<div className="sm:hidden pt-4 pb-2">
					<CustomGreenBtn
						text="save"
						isDisabled={isBtnDisabled() || profBusinessInfoLoading}
						isLoading={profBusinessInfoLoading}
						onRequestClick={onSelectedProfileSave}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProfAndBusinessInfo;

const SelectProfile = ({ text, value, isSelected, handleChange }) => {
	return (
		<div className="flex gap-2 items-center">
			<CustomRadioInput
				name="choose_profile"
				value={value}
				isChecked={isSelected}
				handleChange={handleChange}
			/>
			<p
				className={`zabalMedFont text-xs md:text-[12.5px] capitalize ${
					isSelected ? "text-fortcore-green" : "text-[#717E83]"
				}`}
			>
				{text}
			</p>
		</div>
	);
};

SelectProfile.propTypes = {
	text: PropTypes.string,
	isSelected: PropTypes.bool,
	value: PropTypes.string,
	handleChange: PropTypes.func,
};
