import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
	BackTracker,
	FatBackTracker,
	PlainSelect,
	SelectDropDown,
} from "../../../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import { saveDataFromLeaseStructure } from "../../../redux/lease/leaseActions";
import { DesktopSideModal } from "../../../components/ModalContainer";
import ItemDescription from "../marketplace/ItemDescription";
import {
	LeaseStructureItem,
	deliveryTypeArr,
	expectedDuration,
} from "../requestlease/LeaseStructure";
import {
	DualLeaseFormSelect,
	LeaseFormSelect,
} from "../requestlease/LeaseForm";
import { useLocation, useNavigate } from "react-router-dom";
import { getSingularOrPlural, onInputAmount } from "../../../common/utils";
import { MdLock } from "react-icons/md";
import { fetchCurrentInterest } from "../../../redux/lease/leaseServices";

const SelectedSavingsDetails = ({ setActiveTab }) => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		selectedReqLeaseItem,
		toggleMarketItemsModal,
		leaseStructureData,
		currentGeneratedinterest,
	} = useSelector((state) => state.lease);

	const [leaseStructureForm, setLeaseStructureForm] = useState({
		lease_period: leaseStructureData?.lease_duration?.split(" ")[1] || "",
		lease_length: leaseStructureData?.lease_duration?.split(" ")[0] || "",
		lease_structure: leaseStructureData?.lease_structure || "",
		delivery_type: leaseStructureData?.delivery_type || "",
		amount_to_invest: leaseStructureData?.amount_to_invest || "",
		saving_period: leaseStructureData?.saving_tenor?.split(" ")[1] || "",
		saving_length: leaseStructureData?.saving_tenor?.split(" ")[0] || "",
		interest: leaseStructureData?.interest || "",
	});

	const {
		lease_structure,
		delivery_type,
		amount_to_invest,
		interest,
		lease_length,
		lease_period,
		saving_length,
		saving_period,
	} = leaseStructureForm;

	useEffect(() => {
		dispatch(fetchCurrentInterest("Base Interest rate (Savings) leasing"));
	}, []);

	useEffect(() => {
		if (currentGeneratedinterest) {
			setLeaseStructureForm((prev) => ({
				...prev,
				interest: currentGeneratedinterest?.value?.replace("%", ""),
			}));
		}
	}, [currentGeneratedinterest]);

	// keep one drop down open a time
	const [formsToggler, setFormsToggler] = useState([
		{ id: 0, toggleForm: false },
		{ id: 1, toggleForm: false },
		{ id: 2, toggleForm: false },
		{ id: 3, toggleForm: false },
		{ id: 4, toggleForm: false },
		{ id: 5, toggleForm: false },
	]);

	const toggleModal = (id) => {
		setFormsToggler(
			formsToggler.map((a) =>
				a.id === id
					? { ...a, toggleForm: !a.toggleForm }
					: { ...a, toggleForm: false }
			)
		);
	};

	const handleDurationOne = () => {
		if (!lease_period) return;
		toggleModal(1);
	};

	const handleDurationTwo = () => {
		if (!saving_period) return;
		toggleModal(5);
	};

	const isProceedBtnValid = () => {
		if (
			lease_structure === "" ||
			lease_length === "" ||
			lease_period === "" ||
			delivery_type === "" ||
			amount_to_invest === "" ||
			saving_length === "" ||
			saving_period === "" ||
			Number(amount_to_invest?.replaceAll(",", "")) >
				Number(selectedReqLeaseItem?.amount) ||
			Number(amount_to_invest?.replaceAll(",", "")) < 100
		) {
			return true;
		} else {
			return false;
		}
	};

	const [error, setError] = useState(false);

	useEffect(() => {
		if (
			Number(amount_to_invest?.replaceAll(",", "")) >
				Number(selectedReqLeaseItem?.amount) ||
			Number(amount_to_invest?.replaceAll(",", "")) < 100
		) {
			setError(true);
			setTimeout(() => {
				setError(false);
			}, 3000);
		}
	}, [amount_to_invest]);

	const onProceedToNextTab = () => {
		const data = {
			lease_duration: `${lease_length} ${getSingularOrPlural(
				lease_length,
				lease_period
			)}`,
			saving_tenor: `${saving_length} ${getSingularOrPlural(
				saving_length,
				saving_period
			)}`,
			lease_structure,
			delivery_type,
			amount_to_invest,
			interest,
		};
		dispatch(saveDataFromLeaseStructure(data));
		setActiveTab(4);
	};

	return (
		<>
			{toggleMarketItemsModal && (
				<DesktopSideModal modal={toggleMarketItemsModal}>
					<ItemDescription />
				</DesktopSideModal>
			)}
			<BackTracker
				text="Create Lease"
				onNavigate={
					state?.currentTab == 3 ? () => navigate(-1) : () => setActiveTab(2)
				}
			/>
			<div className="mb-4 hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold">
				<div className="space-y-1.5">
					<FatBackTracker
						text="asset based savings"
						onNavigate={
							state?.currentTab == 3
								? () => navigate(-1)
								: () => setActiveTab(2)
						}
					/>
					<p className="text-xs text-[#717E83] zabalRegFont ">
						Select as you wish to save.
					</p>
				</div>
			</div>
			<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseStructureItem item={selectedReqLeaseItem} />
					<DualLeaseFormSelect
						label="Lease Duration"
						dropDownOne={{
							context: "duration",
							currentText: lease_period,
							isOpened: formsToggler[0].toggleForm,
							handleToggle: () => toggleModal(0),
							arrOptions: expectedDuration.map((a) => a.duration),
						}}
						handleSelectOne={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								lease_period: d,
								lease_length: "",
							}));
							setTimeout(() => {
								toggleModal(0);
							}, 100);
						}}
						dropDownTwo={{
							context: "length",
							currentText: lease_length,
							isOpened: formsToggler[1].toggleForm,
							handleToggle: handleDurationOne,
							arrOptions: expectedDuration
								?.find((a) => a.duration === lease_period)
								?.length.map((a) => String(a)),
						}}
						handleSelectTwo={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								lease_length: String(d),
							}));
							setTimeout(() => {
								toggleModal(1);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="lease structure"
						context="choose lease structure"
						isOpened={formsToggler[2].toggleForm}
						currentText={lease_structure}
						handleToggle={() => toggleModal(2)}
						arrOptions={["Lease To Own", "Operating Lease"]}
						onHandleSelect={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								lease_structure: d,
							}));
							setTimeout(() => {
								toggleModal(2);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="delivery type"
						context="choose delivery type"
						isOpened={formsToggler[3].toggleForm}
						currentText={delivery_type}
						handleToggle={() => toggleModal(3)}
						arrOptions={deliveryTypeArr.map((a) => a.label)}
						onHandleSelect={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								delivery_type: deliveryTypeArr.find((a) => a.label === d).label,
							}));
							setTimeout(() => {
								toggleModal(3);
							}, 100);
						}}
					/>
					<div
						className={`flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px] ${
							error ? "mb-4 sm:mb-6 md:mb-4" : "mb-0"
						}`}
					>
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-2/6 md:w-5/12">
							How much would you like to start with? :
						</p>
						<div className="h-[45px] w-full sm:w-4/6 md:w-7/12 relative zabalRegFont">
							<span className="pt-0.5 text-[#393F42] absolute zabalMedFont left-3 h-full w-[30px] grid place-items-center">
								NGN
							</span>
							<input
								type="text"
								className="text-sm h-full w-full pl-[45px] pr-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
								placeholder="amount to invest"
								name="amount_to_invest"
								value={amount_to_invest}
								onChange={(e) =>
									setLeaseStructureForm((prev) => ({
										...prev,
										amount_to_invest: e.target.value,
									}))
								}
								onInput={onInputAmount}
							/>
							{error && (
								<p className="absolute text-xs md:text-[13px] text-red-500 zabalRegFont pt-1">
									Amount to invest cannot be greater than N
									{Number(selectedReqLeaseItem?.amount)?.toLocaleString()} or
									less than N100
								</p>
							)}
						</div>
					</div>
					<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-2/6 md:w-5/12">
							How long would you like to save? :
						</p>
						<div className="w-full sm:w-4/6 md:w-7/12 grid grid-cols-2 gap-3 md:gap-4 lg:gap-5">
							<div className="relative">
								<PlainSelect
									context="duration"
									isOpened={formsToggler[4].toggleForm}
									currentText={saving_period}
									handleToggle={() => toggleModal(4)}
								/>
								{formsToggler[4].toggleForm && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={expectedDuration.map((a) => a.duration)}
											onHandleSelect={(d) => {
												setLeaseStructureForm((prev) => ({
													...prev,
													saving_period: d,
													saving_length: "",
												}));
												setTimeout(() => {
													toggleModal(4);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
							<div className="relative">
								<PlainSelect
									context="length"
									isOpened={formsToggler[5].toggleForm}
									currentText={saving_length}
									handleToggle={handleDurationTwo}
								/>
								{formsToggler[5].toggleForm && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={expectedDuration
												?.find((a) => a.duration === saving_period)
												?.length.map((a) => String(a))}
											onHandleSelect={(d) => {
												setLeaseStructureForm((prev) => ({
													...prev,
													saving_length: String(d),
												}));
												setTimeout(() => {
													toggleModal(5);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-2/6 md:w-5/12">
							Interest :
						</p>
						<div className="h-[45px] w-full sm:w-4/6 md:w-7/12 relative zabalRegFont rounded border border-[#B8B8B8] text-[#393F42] flex gap-1.5 items-center px-2.5">
							<span className="">%</span>
							<span>{interest}</span>
							<span className="text-[#393F42] text-sm">P/A</span>
							<span className="absolute right-3">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>

					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						<CustomWhiteBtn
							text="back"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={() => setActiveTab(2)}
						/>
						<CustomGreenBtn
							text="proceed"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={onProceedToNextTab}
							isDisabled={isProceedBtnValid()}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						<CustomGreenBtn
							text="proceed"
							onRequestClick={onProceedToNextTab}
							isDisabled={isProceedBtnValid()}
						/>
						<CustomWhiteBtn
							text="back"
							onRequestClick={() => setActiveTab(2)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default SelectedSavingsDetails;

SelectedSavingsDetails.propTypes = {
	setActiveTab: PropTypes.func,
};
