import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import { PersonalFormCardToggler } from "../../profile/sections/CompletePersonalForm";
import PersonalInformation from "./forms/PersonalInformation";
import ContactInformation from "./forms/ContactInformation";
import ProfAndBusinessInfo from "./forms/ProfAndBusinessInfo";
import EduAndStudyInfo from "./forms/EduAndStudyInfo";
import FamilyInformation from "./forms/FamilyInformation";
import TravelInformation from "./forms/TravelInformation";
import AccountInformation from "./forms/AccountInformation";
import RequestInformation from "./forms/RequestInformation";
import DocumentsInformation from "./forms/DocumentsInformation";
import FinancialInformation from "./forms/FinancialInformation";
import {
	createNewApplication,
	fetchContactInformation,
	fetchDocumentsInformation,
	fetchEducationInformation,
	fetchFamilyInformation,
	fetchFinancialInformation,
	fetchPersonalInformation,
	fetchProfBusinessInformation,
	fetchRequestInformation,
} from "../../../redux/travels/travelServices";
import { toast } from "react-toastify";
import ModalContainer from "../../../components/ModalContainer";
import { clearApplicationSuccess } from "../../../redux/travels/travelActions";
import Parse from "../../../api/ApiConfig";

const NewApplication = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		personalInfoSuccess,
		contactInfoSuccess,
		profBusinessInfoSuccess,
		educationInfoSuccess,
		familyInfoSuccess,
		travelInfoSuccess,
		accountInfoSuccess,
		requestInfoSuccess,
		financialInfoSuccess,
		documentsInfoSuccess,
		createApplicationLoading,
		createApplicationSuccess,
	} = useSelector((state) => state.travel);

	useEffect(() => {
		dispatch(fetchPersonalInformation());
		dispatch(fetchContactInformation());
		dispatch(fetchProfBusinessInformation());
		dispatch(fetchEducationInformation());
		dispatch(fetchFamilyInformation());
		dispatch(fetchFinancialInformation());
		dispatch(fetchDocumentsInformation());
		dispatch(fetchRequestInformation());
	}, []);

	const [formsToggler, setFormsToggler] = useState([
		{ id: 0, toggleForm: false },
		{ id: 1, toggleForm: false },
		{ id: 2, toggleForm: false },
		{ id: 3, toggleForm: false },
		{ id: 4, toggleForm: false },
		{ id: 5, toggleForm: false },
		{ id: 6, toggleForm: false },
		{ id: 7, toggleForm: false },
		{ id: 8, toggleForm: false },
		{ id: 9, toggleForm: false },
	]);

	const toggleModal = (id) => {
		setFormsToggler(
			formsToggler.map((a) =>
				a.id === id
					? { ...a, toggleForm: !a.toggleForm }
					: { ...a, toggleForm: false }
			)
		);
	};

	const checkData = {
		personalInfoSuccess,
		contactInfoSuccess,
		profBusinessInfoSuccess,
		educationInfoSuccess,
		familyInfoSuccess,
		travelInfoSuccess,
		requestInfoSuccess,
		financialInfoSuccess,
		documentsInfoSuccess,
	};

	const truthyValues = Object.values(checkData).filter((a) => a).length;

	const isBtnDisabled = () => {
		if (truthyValues < 9) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const Personal = Parse.Object.extend("TA_Personal");
	const personal = new Personal();
	personal.id = personalInfoSuccess?.objectId;

	const Contact = Parse.Object.extend("TA_Contact");
	const contact = new Contact();
	contact.id = contactInfoSuccess?.objectId;

	const Documentation = Parse.Object.extend("TA_Documentation");
	const documentation = new Documentation();
	documentation.id = documentsInfoSuccess?.objectId;

	const Family = Parse.Object.extend("TA_Family");
	const family = new Family();
	family.id = familyInfoSuccess?.objectId;

	const FinancialInformationP = Parse.Object.extend("TA_FinancialInformation");
	const financialInformation = new FinancialInformationP();
	financialInformation.id = financialInfoSuccess?.objectId;

	const ProfBus = Parse.Object.extend("TA_ProfBus");
	const profBus = new ProfBus();
	profBus.id = profBusinessInfoSuccess?.objectId;

	const EduStudy = Parse.Object.extend("TA_EduStudy");
	const eduStudy = new EduStudy();
	eduStudy.id = educationInfoSuccess?.objectId;

	const DetailsOfRequest = Parse.Object.extend("TA_DetailsOfRequest");
	const detailsOfRequest = new DetailsOfRequest();
	detailsOfRequest.id = requestInfoSuccess?.objectId;

	const Bank = Parse.Object.extend("TA_Bank");
	const bank = new Bank();
	bank.id = accountInfoSuccess?.objectId;

	const onSubmitApplication = () => {
		if (!accountInfoSuccess) {
			toast.warning("Please save your account information");
			return;
		}
		const data = {
			userId: user,
			personal,
			contact,
			documentation,
			family,
			financialInformation,
			profBus,
			eduStudy,
			detailsOfRequest,
			bank,
			state: "submitted",
		};

		dispatch(createNewApplication(data, travelInfoSuccess));
	};

	const onSuccessModalClick = () => {
		navigate("/travels/submitted-application");
		dispatch(clearApplicationSuccess());
	};
	return (
		<>
			{createApplicationSuccess && (
				<ModalContainer modal={!!createApplicationSuccess}>
					<SuccessCheckModal
						text="Application Submitted Successfully"
						isShowBtn
						btnText="okay"
						onRequestClick={onSuccessModalClick}
					/>
				</ModalContainer>
			)}
			<MobileNavDetails text="new application" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Transactions</title>
					</Helmet>
					<Navbar text="travel advance" />
					<div className="sm:pt-9 md:pt-10"></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="travels"
							onNavigate={() => navigate("/travels")}
						/>
						<UrlTracker text="new application" isCurrentUrl />
					</div>
					<BackTracker text="travel advance" onNavigate={() => navigate(-1)} />
					<div className="pb-6 sm:py-5 md:py-6 space-y-1.5">
						<p className="text-xs md:text-[12.5px] text-[#393F42] zabalMedFont">
							Traveling begins with your account balance. Get travel advance to
							facilitate your travel.
						</p>
					</div>
					<div className="rounded-md bg-[#F6F7F8] p-4 md:p-5 space-y-3.5 md:space-y-4 mb-6 md:mb-10">
						<p className="chillaxFont font-black text-fortcore-green">
							Application Form
						</p>
						<div className="space-y-3 sm:space-y-3.5">
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="personal information"
									isOpen={formsToggler[0].toggleForm}
									onClick={() => toggleModal(0)}
								/>
								{formsToggler[0].toggleForm && <PersonalInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="contact information"
									isOpen={formsToggler[1].toggleForm}
									onClick={() => toggleModal(1)}
								/>
								{formsToggler[1].toggleForm && <ContactInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="professional / business information"
									isOpen={formsToggler[2].toggleForm}
									onClick={() => toggleModal(2)}
								/>
								{formsToggler[2].toggleForm && <ProfAndBusinessInfo />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="education / study information"
									isOpen={formsToggler[3].toggleForm}
									onClick={() => toggleModal(3)}
								/>
								{formsToggler[3].toggleForm && <EduAndStudyInfo />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="family information"
									isOpen={formsToggler[4].toggleForm}
									onClick={() => toggleModal(4)}
								/>
								{formsToggler[4].toggleForm && <FamilyInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="travel information"
									isOpen={formsToggler[5].toggleForm}
									onClick={() => toggleModal(5)}
								/>
								{formsToggler[5].toggleForm && <TravelInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="bank account information"
									isOpen={formsToggler[6].toggleForm}
									onClick={() => toggleModal(6)}
								/>
								{formsToggler[6].toggleForm && <AccountInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="details of request"
									isOpen={formsToggler[7].toggleForm}
									onClick={() => toggleModal(7)}
								/>
								{formsToggler[7].toggleForm && <RequestInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="financial information"
									isOpen={formsToggler[8].toggleForm}
									onClick={() => toggleModal(8)}
								/>
								{formsToggler[8].toggleForm && <FinancialInformation />}
							</div>
							<div className="space-y-3 md:space-y-3.5">
								<PersonalFormCardToggler
									text="documentation"
									isOpen={formsToggler[9].toggleForm}
									onClick={() => toggleModal(9)}
								/>
								{formsToggler[9].toggleForm && <DocumentsInformation />}
							</div>
							<div className="hidden sm:flex justify-end py-2">
								<CustomGreenBtn
									text="submit"
									width="120px"
									height="43px"
									fontSize="14px"
									isDisabled={isBtnDisabled() || createApplicationLoading}
									isLoading={createApplicationLoading}
									onRequestClick={onSubmitApplication}
								/>
							</div>
							<div className="sm:hidden pt-4 pb-2">
								<CustomGreenBtn
									text="submit"
									height="43px"
									isDisabled={isBtnDisabled() || createApplicationLoading}
									isLoading={createApplicationLoading}
									onRequestClick={onSubmitApplication}
								/>
							</div>
						</div>
					</div>
				</>
			</Container>
		</>
	);
};
export default NewApplication;
