import React from "react";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import { formatLeaseeStatus, getUserRequestStatus } from "../../common/utils";
import MiniLoader from "../../layout/miniLoader";
import Parse from "../../api/ApiConfig";

const ViewLeaseeRequests = ({
	onClose,
	selectedRequest,
	onApproveSingleRequest,
	loading,
}) => {
	const usersRequest = selectedRequest?.usersRequest;
	const user = Parse.User.current();

	const currentInvestor = selectedRequest?.investorsArray?.find(
		(investor) => investor?.user === user?.id
	);

	const formatName = (name) => {
		const splitName = name?.split(" ").filter((a) => a.length > 2);
		return splitName?.map((a) => a.charAt()).join("");
	};

	function getInvestorStatus(array) {
		const data = array?.find((a) => a?.user === user?.id);

		if (!data) {
			return "pending";
		}
		return data?.status;
	}

	return (
		<div className="bg-white rounded-md py-4 md:py-5 w-[86%] max-w-[400px]">
			<div className="flex items-center justify-between text-primary-color px-4 md:px-5">
				<h4 className="text-[15px] md:text-lg zabalBoldFont">Users Requests</h4>
				<button onClick={onClose}>
					<MdClose size={20} />
				</button>
			</div>
			{currentInvestor?.approvalStatus ? (
				<p className="text-green-600 text-xs md:text-[13px] px-5 md:px-5 mt-1 mb-4">
					You have approved one request.
				</p>
			) : (
				<p className="text-red-500 text-xs md:text-[13px] px-5 md:px-5 mt-1 mb-4">
					NB: You can only approve one request
				</p>
			)}
			<div className="px-4 md:px-5 max-h-[300px] overflow-y-auto scroll-wrapper grid gap-3 md:gap-4">
				{usersRequest?.map((request, index) => (
					<div
						className="flex justify-between border rounded-md p-2.5 md:p-3 min-h-[115px]"
						key={index}
					>
						<div className="text-[13px] flex flex-col gap-1">
							<div className="text-[13px] flex gap-1.5 md:gap-2 items-center">
								<div className="text-[11.5px] uppercase zabalBoldFont bg-[#BDBDBD66] text-fortcore-green rounded-full h-[27px] w-[27px] grid place-items-center p-1">
									<p>{formatName(request.name)}</p>
								</div>
								<p>{request.name}</p>
							</div>
							<div className="flex text-[12.5px]">
								<p>
									<span className="text-xs text-gray-500">Transaction ID:</span>{" "}
									{request.transactionId}
								</p>
							</div>
							{console.log(
								getUserRequestStatus(
									selectedRequest?.investorsArray,
									request?.investor
								)
							)}
							{request?.date && (
								<div className="flex text-[12.5px]">
									<p>
										<span className="text-xs text-gray-500">
											Date Requested:
										</span>{" "}
										{moment(request?.date).format("MMM Do, YYYY")}
									</p>
								</div>
							)}
							<div
								className="flex text-[12.5px]"
								style={{
									color: formatLeaseeStatus(
										getInvestorStatus(request?.investor)
									).color,
								}}
							>
								<p>
									<span className="text-xs text-gray-500 capitalize">
										Approval Status:
									</span>{" "}
									{
										formatLeaseeStatus(getInvestorStatus(request?.investor))
											?.status
									}
								</p>
							</div>
						</div>
						{getUserRequestStatus(
							selectedRequest?.investorsArray,
							request?.investor
						) === "pending" && (
							<div className="flex flex-col justify-center">
								<button
									className={`h-[28px] w-[28px] flex items-center justify-center rounded-full bg-fortcore-green text-white ${
										loading[request?.transactionId]
											? "bg-stone-300"
											: "bg-fortcore-green"
									}`}
									onClick={() => onApproveSingleRequest(request.transactionId)}
									disabled={loading[request?.transactionId]}
								>
									{loading[request?.transactionId] ? (
										<MiniLoader />
									) : (
										<FaCheck size={15} />
									)}
								</button>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default ViewLeaseeRequests;

ViewLeaseeRequests.propTypes = {
	onClose: PropTypes.func,
	selectedRequest: PropTypes.object,
	loading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	onApproveSingleRequest: PropTypes.func,
};
