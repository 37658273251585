import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { IsValidIcon } from "../../../layout/assets";
import GeneralInformation from "./GeneralInformation";
import { validateEmail } from "../../../common/utils";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import { PlainSelect, SelectDropDown } from "../../../layout/CustomComponents";
import {
	disableEmailButton,
	getWorkEmailOtp,
	updateUserAndPersonalData,
	verifyEmailOtp,
} from "../../../redux/authRedux/authServices";
import { toast } from "react-toastify";
import { Preloader } from "../../../components/globalStyled";
import CountDown from "../../../components/Countdown";
import Parse from "../../../api/ApiConfig";
import { ProfileInput } from "./PersonalInfo";

const CompletePersonalForm = () => {
	const dispatch = useDispatch();
	const currentSessionToken = Parse.User.current()?.getSessionToken();
	const {
		currentUser,
		updatingUserAccountLoading,
		emailOtpMessageSuccess,
		emailOtpMessageLoading,
		verifyEmailOtpLoading,
		verifyEmailOtpSuccess,
		isShowEmailTimer,
	} = useSelector((state) => state.auth);

	const [toggleAdditionalInfo, setToggleAdditionalInfo] = useState(false);

	const [toggleWorkStatus, setToggleWorkStatus] = useState(false);
	const [toggleSalaryRange, setToggleSalaryRange] = useState(false);
	const [toggleNumberOfDependants, setToggleNumberOfDependants] =
		useState(false);

	const [additionalProfile, setAdditionalProfile] = useState({
		work_status: currentUser?.workStatus || "",
		work_email: currentUser?.workEmail || "",
		employer: currentUser?.employerName || "",
		salary_range: currentUser?.salaryRange || "",
		number_of_dependants: currentUser?.numberOfDependants,
		email_code: "",
	});

	const {
		work_status,
		work_email,
		employer,
		salary_range,
		number_of_dependants,
		email_code,
	} = additionalProfile;

	const checkEmail = () => {
		if (!currentUser?.workEmailVerified) {
			return true;
		}
		if (currentUser?.workEmail !== work_email) {
			return true;
		} else {
			return false;
		}
	};

	const isEmailValid = validateEmail(work_email);

	// get email otp
	const onGetEmailOtp = () => {
		if (isEmailValid) {
			dispatch(getWorkEmailOtp({ workEmail: work_email }, currentSessionToken));
		} else {
			toast.error("WorkEmail is not valid");
		}
	};

	//verify email otp
	useEffect(() => {
		if (verifyEmailOtpSuccess) {
			setAdditionalProfile((prev) => ({ ...prev, email_code: "" }));
			return;
		}
		if (email_code.length < 6) {
			return;
		} else if (isNaN(email_code)) {
			return;
		} else {
			dispatch(verifyEmailOtp({ workEmail: work_email, otp: email_code }));
		}
	}, [email_code]);

	// disable resend email otp while counter
	useEffect(() => {
		if (isShowEmailTimer) {
			dispatch(disableEmailButton(1000 * 115));
		}
	}, [isShowEmailTimer]);

	const isAddProfileBtnInvalid = () => {
		if (
			work_email === "" ||
			salary_range === "" ||
			work_status === "" ||
			employer === "" ||
			number_of_dependants === "" ||
			checkEmail()
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleToggleWorkStatus = () => {
		setToggleWorkStatus((prev) => !prev);
		setToggleSalaryRange(false);
	};

	const handleToggleSalaryRange = () => {
		setToggleSalaryRange((prev) => !prev);
		// setToggleNumberOfDependent(false)
	};

	const handleToggleNumOfDependants = () => {
		setToggleNumberOfDependants((prev) => !prev);
		setToggleSalaryRange(false);
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const dataForUserProfile = {
		employerName: employer,
		numberOfDependants: number_of_dependants,
		workEmail: work_email,
		salaryRange: salary_range,
		workStatus: work_status,
	};
	const dataForPersonalProfile = {
		employers: employer,
		numberOfDependants: number_of_dependants,
		workEmail: work_email,
		salaryRange: salary_range,
		workStatus: work_status,
		userId: user,
		user: user,
		email: currentUser?.email || work_email,
	};

	const onSubmitAdditionalInfo = () => {
		dispatch(
			updateUserAndPersonalData(dataForPersonalProfile, dataForUserProfile)
		);
	};

	return (
		<div className="space-y-3.5 md:space-y-4 lg:space-y-5">
			<GeneralInformation />
			<div className="space-y-2 space-y-2.5">
				<PersonalFormCardToggler
					text="additional information for asset leasing"
					isOpen={toggleAdditionalInfo}
					onClick={() => setToggleAdditionalInfo((prev) => !prev)}
				/>
				{toggleAdditionalInfo && (
					<div className="flex flex-col gap-3 md:gap-4 bg-white rounded p-3.5 md:p-4">
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7 relative z-[1]">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Work Status:
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="select work status"
									isOpened={toggleWorkStatus}
									currentText={work_status}
									handleToggle={handleToggleWorkStatus}
								/>
								{toggleWorkStatus && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={["Employed", "Self employed", "Unemployed"]}
											onHandleSelect={(d) => {
												setAdditionalProfile((prev) => ({
													...prev,
													work_status: d,
												}));
												setTimeout(() => {
													setToggleWorkStatus(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7">
							<label
								htmlFor="work_email"
								className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont"
							>
								Work Email Address:
							</label>
							<div
								className={`h-[45px] relative w-full sm:w-[70%] md:w-4/5 ${
									checkEmail() ? "mb-2" : "mb-0"
								}`}
							>
								<input
									type="email"
									className="zabalRegFont bg-transparent rounded h-full w-full border border-[#B8B8B8] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
									placeholder="work email"
									id="work_email"
									value={work_email}
									onChange={(e) =>
										setAdditionalProfile((prev) => ({
											...prev,
											work_email: e.target.value,
										}))
									}
								/>
								{checkEmail() && (
									<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
										Work Email must be verified
									</p>
								)}
								<div className="absolute flex gap-2 items-center right-3 h-full top-0">
									{!checkEmail() && (
										<span>
											<IsValidIcon width="15px" height="15px" />
										</span>
									)}
									{!emailOtpMessageSuccess && checkEmail() && (
										<button
											className={`h-full flex items-center justify-center md:w-[60px] text-xs md:text-[12.5px] font-semibold zabalMedFont ${
												emailOtpMessageLoading || !isEmailValid
													? "text-[#717E83]"
													: "text-fortcore-green"
											} ${emailOtpMessageLoading ? "w-[42px]" : "w-[56px]"}`}
											onClick={onGetEmailOtp}
											disabled={emailOtpMessageLoading || !isEmailValid}
										>
											{emailOtpMessageLoading ? <Preloader /> : "Get Code"}
										</button>
									)}
								</div>
							</div>
						</div>
						{/* handle work email otp Verification */}
						{emailOtpMessageSuccess && (
							<div className="flex flex-col sm:flex-row gap-1.5 sm:items-center zabalRegFont text-[#393F42] md:gap-6">
								<label
									htmlFor="email_code"
									className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
								>
									Enter Code :
								</label>
								<div className="space-y-1.5 w-full sm:w-[70%] md:w-4/5">
									<div className="relative h-[45px] w-full zabalMedFont relative">
										<input
											type="tel"
											className="text-sm h-full w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
											placeholder="email otp"
											id="email_code"
											maxLength={6}
											value={email_code}
											onChange={(e) =>
												setAdditionalProfile((prev) => ({
													...prev,
													email_code: e.target.value,
												}))
											}
										/>
										{verifyEmailOtpLoading && (
											<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
												<Preloader />
											</div>
										)}
									</div>
									<div className="pl-4 pr-2 text-[11px] flex justify-between text-fortcore-green zabalMedFont">
										{isShowEmailTimer && <CountDown timer={115} />}
										<div className="space-x-1.5 justify-self-end ">
											<span className="text-[#393F42]">
												Didn&apos;t get code?
											</span>
											<button
												className={`font-semibold ${
													isShowEmailTimer || emailOtpMessageLoading
														? "text-[#717E83]"
														: "text-fortcore-green"
												}`}
												onClick={onGetEmailOtp}
												disabled={isShowEmailTimer || emailOtpMessageLoading}
											>
												{emailOtpMessageLoading ? "Sending..." : "Resend Code"}
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
						<ProfileInput
							text="employer"
							id="employer"
							type="text"
							value={employer}
							handleChange={(e) =>
								setAdditionalProfile((prev) => ({
									...prev,
									employer: e.target.value,
								}))
							}
							color
							placeholderText="Enter employer name"
						/>
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7 relative z-[1]">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Salary Range:
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="choose salary range"
									isOpened={toggleSalaryRange}
									currentText={salary_range}
									handleToggle={handleToggleSalaryRange}
								/>
								{toggleSalaryRange && (
									<div className="absolute w-full top-48px z-[2] pb-8">
										<SelectDropDown
											arrOptions={[
												"Below N50,000",
												"N50,000 - N100,000",
												"N100,000 - N500,000",
												"Above N1,000,000",
											]}
											onHandleSelect={(d) => {
												setAdditionalProfile((prev) => ({
													...prev,
													salary_range: d,
												}));
												setTimeout(() => {
													setToggleSalaryRange(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Number of Dependents:
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="select number of dependents"
									isOpened={toggleNumberOfDependants}
									currentText={number_of_dependants}
									handleToggle={handleToggleNumOfDependants}
								/>
								{toggleNumberOfDependants && (
									<div className="absolute z-[2] w-full top-48px pb-8">
										<SelectDropDown
											arrOptions={["None", "1", "2", "3", "4", "Above 4"]}
											onHandleSelect={(d) => {
												setAdditionalProfile((prev) => ({
													...prev,
													number_of_dependants: d,
												}));
												setTimeout(() => {
													setToggleNumberOfDependants(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="hidden sm:flex justify-end py-2">
							<CustomGreenBtn
								text="save"
								width="105px"
								height="42px"
								fontSize="13px"
								isDisabled={
									isAddProfileBtnInvalid() || updatingUserAccountLoading
								}
								isLoading={updatingUserAccountLoading}
								onRequestClick={onSubmitAdditionalInfo}
							/>
						</div>
						<div className="sm:hidden pt-4 pb-2">
							<CustomGreenBtn
								text="save"
								isDisabled={
									isAddProfileBtnInvalid() || updatingUserAccountLoading
								}
								isLoading={updatingUserAccountLoading}
								onRequestClick={onSubmitAdditionalInfo}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default CompletePersonalForm;

export const PersonalFormCardToggler = ({ text, isOpen, onClick }) => {
	return (
		<div
			className={`cursor-pointer capitalize rounded flex items-center justify-between border p-2.5 py-3 sm:p-3 ${
				isOpen
					? "border-[#557C8A] text-fortcore-green zabalMedFont"
					: "border-[#96A0A5] text-[#393F42] zabalRegFont"
			}`}
			onClick={onClick}
		>
			<span className="text-[12.5px] md:text-[13.5px]">{text}</span>
			<span>
				{isOpen ? (
					<MdKeyboardArrowUp size={18} />
				) : (
					<MdKeyboardArrowDown size={18} />
				)}
			</span>
		</div>
	);
};

PersonalFormCardToggler.propTypes = {
	text: PropTypes.string,
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
};
