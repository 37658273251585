import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import AmountConfirmation from "./AmountConfirmation";
import { EnterPin } from "../../../components/payments/PinComponent";
import SelectWallet from "./SelectWallet";
import { moveUserFunds } from "../../../redux/payments/paymentServices";
import { SuccessCheckModal } from "../../../layout/CustomComponents";
import {
	clearMoveFundsSuccess,
	closeMoveFundsModal,
} from "../../../redux/payments/paymentActions";
import PlanToReceiveFunds from "./PlanToReceiveFunds";
import { walletType } from "../../../common/utils";
import Parse from "../../../api/ApiConfig";
import { createNotification } from "../../../components/notification/CreateNotification";

const MoveFundsFlow = ({
	fromWallet = "main",
	activeMovementTab,
	setActiveMovementTab,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { currentUser } = useSelector((state) => state.auth);
	const { moveFundsLoading, customerWallet, moveFundsSuccess } = useSelector(
		(state) => state.payment
	);

	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");
	const [updateLoading, setUpdateLoading] = useState(false);
	const [updateSuccess, setUpdateSuccess] = useState(false);
	const [moveFundsInfo, setMoveFundsInfo] = useState({
		amount: "",
		toWallet: "",
		childWallet: "",
		selectedData: null,
	});

	const { amount, toWallet, childWallet, selectedData } = moveFundsInfo;
	console.log(selectedData);

	const accessPin = currentUser?.accessPin || "";

	const handleChange = (e) =>
		setMoveFundsInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const getDestinationWallet = () => {
		switch (childWallet) {
			case "regular":
				return walletType.regularSavings;
			case "asset-based":
				return walletType.assetBasedSavings;
		}
	};

	const amountPayable =
		process.env.NODE_ENV !== "production"
			? 1
			: Number(amount?.replaceAll(",", ""));

	const onActivateMoveFunds = () => {
		if (toWallet !== "savings") {
			toast.error("Moving funds to other wallet is not available");
			return;
		}

		if (customerWallet[0]?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		const data = {
			amount: amountPayable,
			source: fromWallet === "main" ? "main" : fromWallet,
			destination: getDestinationWallet(),
			message: "move-funds-to-plans",
		};
		console.log(data);

		dispatch(moveUserFunds(undefined, data));
	};

	const onUpdateSelectedData = async () => {
		if (toWallet === "savings") {
			const oldAmount = selectedData?.amountSaved
				? Number(selectedData?.amountSaved?.replaceAll(",", ""))
				: 0;
			const updatedAmount = oldAmount + amountPayable;
			const message = "Regular savings plan top-up";
			const metaData = {
				category: "Savings",
				linkToId: selectedData?.objectId,
				moreDetails: `The amount of N${amountPayable} has been added to your ${
					selectedData?.purpose || "General"
				} Regular Savings Plan. Keep up the good work.`,
			};

			setUpdateLoading(true);

			try {
				const query = new Parse.Query("Savings");
				const object = await query.get(selectedData?.objectId);
				const updatedObject = await object.save({
					amountSaved: updatedAmount?.toString(),
				});

				if (updatedObject) {
					createNotification(message, metaData);
					setUpdateSuccess("savings plan updated");
				}
				console.log(updatedObject);
			} catch (error) {
				console.log(error);
				toast.error("Failed to update details");
			} finally {
				setUpdateLoading(false);
			}
		} else {
			console.log(toWallet);
		}
	};

	const onClearMoveFundsSuccess = () => {
		setUpdateSuccess("");
		dispatch(closeMoveFundsModal());
	};

	useEffect(() => {
		if (moveFundsSuccess === "move-funds-to-plans") {
			dispatch(clearMoveFundsSuccess());

			onUpdateSelectedData();
		} else {
			return;
		}
	}, [moveFundsSuccess]);

	useEffect(() => {
		if (updateSuccess) {
			setActiveMovementTab(5);
		} else {
			return;
		}
	}, [updateSuccess]);

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	return (
		<>
			{activeMovementTab === 1 && (
				<SelectWallet
					from={fromWallet}
					to={toWallet}
					childWallet={childWallet}
					setActiveMovementTab={setActiveMovementTab}
					amountToMove={amount}
					handleChange={handleChange}
				/>
			)}
			{activeMovementTab === 2 && (
				<AmountConfirmation
					setActiveMovementTab={setActiveMovementTab}
					from={fromWallet}
					to={toWallet}
					amountToMove={amount}
				/>
			)}
			{activeMovementTab === 3 && (
				<PlanToReceiveFunds
					setActiveMovementTab={setActiveMovementTab}
					from={fromWallet}
					to={toWallet}
					childWallet={childWallet}
					amountToMove={amount}
					setMoveFundsInfo={setMoveFundsInfo}
					selectedData={selectedData}
				/>
			)}
			{activeMovementTab === 4 && (
				<EnterPin
					btnText="Move Funds"
					onCloseBtnClick={() => setActiveMovementTab(3)}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={currentUser?.accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onActivateMoveFunds}
					isPaymentLoading={moveFundsLoading || updateLoading}
				/>
			)}
			{activeMovementTab === 5 && (
				<div className="flex items-center justify-center w-full">
					<SuccessCheckModal
						text="Your funds have been moved successfully"
						isShowBtn
						btnText="Ok"
						onRequestClick={onClearMoveFundsSuccess}
					/>
				</div>
			)}
		</>
	);
};

export default MoveFundsFlow;

MoveFundsFlow.propTypes = {
	fromWallet: PropTypes.string,
	toWallet: PropTypes.string,
	activeMovementTab: PropTypes.number,
	setActiveMovementTab: PropTypes.func,
};
