import React from "react";
import PropTypes from "prop-types";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBank, BsBank2 } from "react-icons/bs";
import NotifyUser from "../../../layout/NotifyUser";

const Channel = ({
	onCloseModal,
	setActivePaymentTab,
	paymentChannel,
	onSelect,
}) => {
	return (
		<NotifyUser
			header="Payment Method"
			title="Select payment method to proceed"
			onCloseBtnClick={onCloseModal}
			onWhiteBtnClick={() => setActivePaymentTab(1)}
			onGreenBtnClick={() => setActivePaymentTab(3)}
			isGreenBtnDisabled={paymentChannel === ""}
			btnText="proceed"
		>
			<div className="border border-[#557C8A] rounded-md py-3 grid divide-y divide-[#EEF2F3] ">
				<PaymentMethods
					text="Paga Bank Transfer"
					icon={<BsBank />}
					onSelect={() => onSelect("paga-bank-transfer")}
					isSelected={paymentChannel === "paga-bank-transfer"}
				/>
				<PaymentMethods
					text="Providus Bank Transfer"
					icon={<BsBank2 />}
					onSelect={() => onSelect("providus-bank-transfer")}
					isSelected={paymentChannel === "providus-bank-transfer"}
				/>
			</div>
		</NotifyUser>
	);
};

Channel.propTypes = {
	onCloseModal: PropTypes.func,
	setActivePaymentTab: PropTypes.func,
	paymentChannel: PropTypes.string,
	onSelect: PropTypes.func,
};

export default Channel;

const PaymentMethods = ({ icon, text, onSelect, isSelected }) => {
	return (
		<div
			className={`flex justify-between items-center text-fortcore-green p-3 ${
				isSelected ? " bg-[#EEF2F3] cursor-default" : "bg-white cursor-pointer"
			}`}
			onClick={onSelect}
		>
			<div className="flex items-center gap-2.5">
				{icon}
				<p className="zabalMedFont text-[13px] md:text-[13.5px]">{text}</p>
			</div>
			<MdKeyboardArrowRight size={24} />
		</div>
	);
};

PaymentMethods.propTypes = {
	icon: PropTypes.object,
	text: PropTypes.string,
	onSelect: PropTypes.func,
	isSelected: PropTypes.bool,
};
