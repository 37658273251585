import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { FiUpload } from "react-icons/fi";
import theme from "../../../../../../components/mui/theme";
import { toast } from "react-toastify";
import { Preloader } from "../../../../../../components/globalStyled";
import { useUploadFile } from "../../../../../../components/customhooks/useUploadFile";

const supportedFileTypes = ["application/pdf", "application/msword"];

export const BusinessProof = ({ onFileUpload, fileName }) => {
	const attachmentInputRef = useRef(null);

	const [businessProof, setBusinessProof] = useState(null);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file && supportedFileTypes.includes(file.type)) {
			setBusinessProof(file);
			// onFileUpload(file);
			// eslint-disable-next-line no-undef
		} else {
			toast.error(
				"Unsupported file type. Please select a PDF, DOC, DOCX or TXT file."
			);
		}
	};

	const { loading, fileSuccess } = useUploadFile(businessProof);

	useEffect(() => {
		if (fileSuccess) {
			onFileUpload(fileSuccess);
		}
	}, [fileSuccess]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height={122}
			width="100%"
			border="2px dashed #ccc"
			position="relative"
			borderRadius={2}
			onClick={() => attachmentInputRef.current.click()}
			sx={{ cursor: "pointer", background: "#EEF2F3" }}
			p={1}
		>
			<input
				type="file"
				ref={attachmentInputRef}
				style={{ display: "none" }}
				onChange={handleFileChange}
				accept=".pdf, .doc"
			/>
			{loading && (
				<div className="absolute top-0 w-full h-full grid place-items-center bg-black/30">
					<Preloader />
				</div>
			)}
			{fileName ? (
				<Stack spacing={1} alignItems="center">
					<Typography
						variant="body1"
						color="textSecondary"
						sx={{
							fontStyle: "italic",
							textTransform: "capitalize",
						}}
					>
						{fileName}
					</Typography>
				</Stack>
			) : (
				<Stack spacing={1} alignItems="center">
					<FiUpload style={{ color: "#717E83" }} size={20} />
					<Typography variant="body1" color={theme.palette.primary.main}>
						Upload File
					</Typography>
					<Typography variant="caption" sx={{ color: "#717E83" }}>
						Supports PDF or Doc
					</Typography>
				</Stack>
			)}
		</Box>
	);
};

BusinessProof.propTypes = {
	onFileUpload: PropTypes.any,
	fileName: PropTypes.string,
};
