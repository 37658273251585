import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const BrowseFormContext = createContext();

export function BrowseFormProvider({ children }) {
	// Market Form
	const [afBusinessClass, setAfBusinessClass] = useState([]);
	const [financeType, setFinanceType] = useState("");
	const [businessType, setBusinessType] = useState("");
	const [duration, setDuration] = useState("");
	const [marketPlace, setMarketPlace] = useState({
		amount: "",
		businessType: "",
		interest: "",
		tenure: "",
		commitmentFee: "",
		expectedProfit: "",
		durationText: "",
		durationTime: "",
		programSavings: "",
		programSavingsRate: "",
		type: "",
		businessDescription: "",
		purpose: "",
		marketDetails: "",
		interestRate: "",

		proofOfPurchase: "",
		proofOfPurchaseDoc: null,
		businessProofFile: {
			name: "",
		},
		attachmentFile: {
			name: "",
		},
		productPhoto: [],

		idCard: {
			name: "",
		},
		utilityBill: {
			name: "",
		},
		autoDebit: false,
		insurance: false,
		guarantor: false,
		paymentType: "",

		signature: "",
	});

	// Auto Setting Form
	const [autoDebit, setAutoDebit] = useState(false);
	const [insurance, setInsurance] = useState(false);
	const [guarantorValid, setGuarantorValid] = useState(false);
	const [commitmentValid, setCommitmentValid] = useState(false);

	// Guarantors Form
	const [guarantorsForm, setGuarantorsForm] = useState({
		name1: "",
		emailAddress1: "",
		name2: "",
		emailAddress2: "",
	});
	const [phoneNumber1, setPhoneNumber1] = useState("");
	const [phoneNumber2, setPhoneNumber2] = useState("");

	const clearData = () => {
		setFinanceType("");
		setBusinessType("");
		setDuration("");

		setMarketPlace({
			amount: 0,
			fee: "",
			expectedProfit: "",
			interestRate: "",
			programSavings: "15%",
			businessDescription: "",
			purpose: "",
			tenor: "",
			businessType: "",
		});
		setAutoDebit(false);
		setInsurance(false);
	};

	const values = {
		// MarketForm Values
		marketPlace,
		setMarketPlace,
		financeType,
		setFinanceType,
		businessType,
		setBusinessType,
		duration,
		setDuration,

		//Auto Setting Form
		autoDebit,
		setAutoDebit,
		insurance,
		setInsurance,
		guarantorValid,
		setGuarantorValid,
		commitmentValid,
		setCommitmentValid,

		//Guarantors form
		guarantorsForm,
		setGuarantorsForm,
		phoneNumber1,
		setPhoneNumber1,
		phoneNumber2,
		setPhoneNumber2,

		afBusinessClass,
		setAfBusinessClass,

		clearData,
	};
	return (
		<BrowseFormContext.Provider value={values}>
			{children}
		</BrowseFormContext.Provider>
	);
}

BrowseFormProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default BrowseFormContext;
