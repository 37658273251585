import React, { useState } from "react";
import {
	Box,
	Button,
	Container,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import HeroImage from "../../../assets/heroImage.png";
import HeroImage2 from "../../../assets/heroImage2.png";
// import HeroImage3 from "../../../assets/heroImage3.png";
import HeroImage4 from "../../../assets/heroImage4.png";
import HeroImage5 from "../../../assets/heroImage5.png";
// import Parse from "../../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

function HeroSection() {
	const navigate = useNavigate();
	// eslint-disable-next-line no-undef
	const defaultEmail = localStorage.getItem("email");
	const [email, setEmail] = useState(defaultEmail || "");

	// useEffect(() => {
	//   const currentUser = Parse.User.current();
	//   if (currentUser) {
	//     navigate("/dashboard");
	//   }
	// }, []);

	const handleExplore = (e) => {
		e.preventDefault();
		// eslint-disable-next-line no-undef
		localStorage.setItem("email", email);
		navigate("/login");
		// eslint-disable-next-line no-undef
		window.scrollTo(0, 0);
	};
	return (
		<>
			<Container maxWidth="lg">
				<Box
					mt="10vh"
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
						gap: { xs: 4, md: 5 },
						alignItems: "center",
					}}
				>
					<Box sx={{ position: "relative" }}>
						<Box
							sx={{
								background:
									"linear-gradient(89deg, #FFE0B2 -224.35%, rgba(224, 246, 234, 0.00) 208.85%)",
								height: "100%",
								width: "60%",
								position: "absolute",
								zIndex: -1,
							}}
						/>
						<Carousel
							autoPlay
							interval={3000}
							infiniteLoop
							direction="rtl"
							showArrows={false}
							showThumbs={false}
							showIndicators={false}
							showStatus={false}
						>
							<img src={HeroImage} alt="hero image" style={{ zIndex: 1 }} />
							<img src={HeroImage2} alt="hero image" style={{ zIndex: 1 }} />
							{/* <img src={HeroImage3} alt="hero image" style={{ zIndex: 1 }} /> */}
							<img src={HeroImage4} alt="hero image" style={{ zIndex: 1 }} />
							<img src={HeroImage5} alt="hero image" style={{ zIndex: 1 }} />
						</Carousel>
						<Box
							sx={{
								border: "1.243px solid var(--grey-100, #EEF2F3)",
								maxWidth: { xs: "80%", md: "55%" },
								background: "#fff",
								boxShadow: "0px 2.48636px 24.86364px 0px rgba(0, 54, 62, 0.12)",
								position: "absolute",
								zIndex: 2,
								left: "4%",
								bottom: { xs: "12%", md: "15%" },
								p: 2,
							}}
						>
							<Typography variant="body1" color="textPrimary.main">
								Easily access digitalized leasing options and invest in leasing
								assets with Fortcore.
							</Typography>
						</Box>
					</Box>

					{/* Right Side  */}

					<Stack spacing={2}>
						<Typography
							variant="h1"
							color="primary"
							sx={{
								fontWeight: 600,
								letterSpacing: "-0.25px",
								fontSize: { xs: "40px", sm: "72px" },
							}}
						>
							Affordable financing at your finger tips
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							sx={{ fontWeight: 500, fontSize: { xs: "20px", sm: "22px" } }}
						>
							Let&apos;s navigate your financial journey together. Get started
							with Fortcore and unlock new possibilities today.
						</Typography>

						<Box component="form" onSubmit={handleExplore}>
							<Stack direction={{ xs: "column", md: "row" }} spacing={1.2}>
								<TextField
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter your E-mail"
									fullWidth
									sx={{ height: "50px", borderRadius: "8px" }}
									required
								/>
								<Button
									variant="contained"
									type="submit"
									sx={{ height: "50px", width: "158px" }}
								>
									Explore
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Container>
		</>
	);
}

export default HeroSection;
