import React, { useContext, useState } from "react";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import { Helmet } from "react-helmet";
import Navbar from "../../../../../layout/Navbar";
import {
	Box,
	InputLabel,
	TextField,
	Typography,
	Button,
	CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Parse from "../../../../../api/ApiConfig";
import userContext from "../../../../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import {
	saveGuarantorsFormToParse,
	updateGuarantorsFormInParse,
} from "../../../../../redux/authRedux/authServices";
import { updateOngoingReq } from "../../../../../redux/financing/financeServices";
import {
	FatBackTracker,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import { onSaveUserFinanceRequest } from "../../../../../redux/financing/financeActions";
import BrowseFormContext from "../BrowseFormContext";

function GuarantorRequestForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state } = useLocation();

	const { requestFinance, setRequestFinance } = useContext(userContext);
	const { marketPlace, setMarketPlace } = useContext(BrowseFormContext);

	const objectId =
		state?.from === "old" ? marketPlace?.objectId : requestFinance?.objectId;

	const { savingGuarantorsFormLoading, guarantorsFormSuccess } = useSelector(
		(state) => state.auth
	);

	const [information, setInformation] = useState({
		signature:
			guarantorsFormSuccess?.get("signature") || requestFinance?.signature,
		name1: "",
		name2: "",
		emailAddress1: "",
		emailAddress2: "",
		phoneNumber1: "",
		phoneNumber2: "",
	});

	const {
		name1,
		name2,
		emailAddress1,
		emailAddress2,
		phoneNumber1,
		phoneNumber2,
	} = information;

	const onUseOldDetails = () => {
		setInformation({
			signature: requestFinance?.signature,
			name1: guarantorsFormSuccess?.get("name1"),
			name2: guarantorsFormSuccess?.get("name2"),
			emailAddress1: guarantorsFormSuccess?.get("email1"),
			emailAddress2: guarantorsFormSuccess?.get("email2"),
			phoneNumber1: guarantorsFormSuccess?.get("phone1"),
			phoneNumber2: guarantorsFormSuccess?.get("phone2"),
		});
	};

	const [loading, setLoading] = useState(false);

	const currentUser = Parse.User.current();

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInformation((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = {
			phone1: phoneNumber1,
			phone2: phoneNumber2,
			name1: name1,
			name2: name2,
			email1: emailAddress1,
			email2: emailAddress2,
			signature: requestFinance?.signature,
			dateSigned: new Date().toISOString(),
			user: currentUser?.toPointer(),
		};

		const isGuarantorFormExist = guarantorsFormSuccess?.id || "";

		try {
			setLoading(true);
			if (isGuarantorFormExist) {
				dispatch(updateGuarantorsFormInParse(isGuarantorFormExist, data));
			} else {
				dispatch(saveGuarantorsFormToParse(data));
			}

			const response = await updateOngoingReq(
				objectId,
				{ guarantorsInfo: data, guarantor: true },
				"ApprenticeshipFinance"
			);

			if (response) {
				toast.success("Guarantors information saved successfully");

				if (state?.from === "new") {
					setRequestFinance((prevState) => ({
						...prevState,
						guarantorsInfo: data,
						guarantor: true,
						formStep: 1,
					}));
				} else {
					setMarketPlace((prevState) => ({
						...prevState,
						guarantorsInfo: data,
						guarantor: true,
						formStep: 1,
					}));
				}

				dispatch(
					onSaveUserFinanceRequest({ guarantorsInfo: data, guarantor: true })
				);

				const url =
					state?.from === "new" ? "request-finance" : "market-finance";

				setTimeout(() => {
					navigate(`/financing/ongoing-transactions/${url}`);
				}, 1000);
			}
		} catch (error) {
			toast.error("Failed to upload guarantors details");
		} finally {
			setLoading(false);
		}
	};

	if (!objectId) {
		return <Navigate to="/financing/ongoing-transactions" />;
	}

	return (
		<>
			<MobileNavDetails text="Ongoing Transactions" />

			<Container>
				<Helmet>
					<title>Fortcore - Ongoing Transactions</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="flex gap-1.5">
					<UrlTracker
						text="apprenticeship Finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="ongoing transactions"
						onNavigate={() => navigate("/financing/ongoing-transactions")}
					/>
					<UrlTracker
						text="guarantor"
						onNavigate={() =>
							navigate("/financing/new-transaction/request/guarantor", {
								state,
							})
						}
					/>
					<UrlTracker text="form" isCurrentUrl />
				</div>
				<div className="sm:hidden">
					<FatBackTracker
						text="guarantor's form"
						onNavigate={() => navigate(-1, { state })}
					/>
				</div>
				<div className="mt-6 sm:mt-10"></div>

				<Box
					sx={{ background: "#F6F7F8", padding: "20px", borderRadius: "8px" }}
				>
					<Typography variant="h4" color="primary.main">
						Guarantors
					</Typography>

					<Box mt={2} mb={3}>
						<Typography variant="caption" color="info.light">
							Please provide two(2) guarantors to complete your request
						</Typography>
						{guarantorsFormSuccess && (
							<p className="text-[12.5px] mt-4">
								Click{" "}
								<button
									className="zabalMedFont text-fortcore-green"
									onClick={onUseOldDetails}
								>
									Update
								</button>{" "}
								to use previous guarantors&apos; information
							</p>
						)}

						<Box mt={5} width="100%">
							<Typography variant="h4" color="#717E83">
								Guarantor 1
							</Typography>

							{/* Form  */}
							<Box component="form" onSubmit={handleSubmit}>
								<InputLabel sx={inputLabelStyle}>Name</InputLabel>
								<TextField
									type="text"
									name="name1"
									value={name1}
									onChange={handleInputChange}
									placeholder="Full Name"
									required
									sx={inputStyle}
								/>

								{/* Phone Number 1 */}
								<InputLabel sx={inputLabelStyle}>Phone no.</InputLabel>
								<PhoneInput
									country={"ng"}
									value={phoneNumber1}
									// onChange={(value) => setPhoneNumber1(value)}
									onChange={(phone_number) =>
										setInformation((prev) => ({
											...prev,
											phoneNumber1: phone_number,
										}))
									}
									inputProps={{
										maxLength: 18,
									}}
									required
									inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded border !border-[#B8B8B8] text-[#393F42] !bg-transparent outline-none zabalRegFont"
									containerClass="!text-[12.5px] lg:!text-[13px] !w-full !border-[#B8B8B8] !h-[45px] mt-2 zabalRegFont"
								/>
								{/* Email Address 1 */}

								<InputLabel sx={inputLabelStyle}>E-mail Address</InputLabel>
								<TextField
									type="email"
									name="emailAddress1"
									value={emailAddress1}
									onChange={handleInputChange}
									placeholder="johndoe@example.com"
									required
									sx={inputStyle}
								/>

								{/* Guarantor 2 */}

								<Typography variant="h4" color="#717E83" mt={5}>
									Guarantor 2
								</Typography>

								<InputLabel sx={inputLabelStyle}>Name</InputLabel>
								<TextField
									type="text"
									name="name2"
									value={name2}
									onChange={handleInputChange}
									placeholder="Full Name"
									required
									sx={inputStyle}
								/>

								{/* Phone Number 1 */}
								<InputLabel sx={inputLabelStyle}>Phone no.</InputLabel>
								<PhoneInput
									country={"ng"}
									value={phoneNumber2}
									// onChange={(value) => setPhoneNumber2(value)}
									onChange={(phone_number) =>
										setInformation((prev) => ({
											...prev,
											phoneNumber2: phone_number,
										}))
									}
									inputProps={{
										maxLength: 18,
									}}
									required
									inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded border !border-[#B8B8B8] text-[#393F42] !bg-transparent outline-none zabalRegFont"
									containerClass="!text-[12.5px] lg:!text-[13px] !w-full !border-[#B8B8B8] !h-[45px] mt-2 zabalRegFont"
								/>

								{/* Email Address 1 */}

								<InputLabel sx={inputLabelStyle}>E-mail Address</InputLabel>
								<TextField
									type="email"
									name="emailAddress2"
									value={emailAddress2}
									onChange={handleInputChange}
									placeholder="johndoe@example.com"
									required
									sx={inputStyle}
								/>

								<Box display="flex" justifyContent="flex-end" mt={5} mb={5}>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={loading || savingGuarantorsFormLoading}
									>
										{loading || savingGuarantorsFormLoading ? (
											<CircularProgress size="20px" sx={{ color: "#fff" }} />
										) : (
											"Proceed"
										)}
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default GuarantorRequestForm;

const inputStyle = {
	width: "100%",
	mt: 1,
};
const inputLabelStyle = {
	width: "100%",
	mt: 2,
};
