import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
	closeMiniLoginModal,
	currentUserData,
} from "../../redux/authRedux/authActions";
import { PasswordInput } from "../profile/sections/UpdatePassword";
import { Preloader } from "../../components/globalStyled";
import Parse from "../../api/ApiConfig";
import { toast } from "react-toastify";

const MiniLogin = () => {
	const dispatch = useDispatch();

	const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
	const [togglePassword, setTogglePassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const { email, password } = loginDetails;

	const isBtnDisabled = () => {
		if (email === "" || password === "") {
			return true;
		} else {
			return false;
		}
	};

	const handleChange = (e) =>
		setLoginDetails((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const data = await Parse.User.logIn(email, password);

			dispatch(currentUserData(data));
		} catch (error) {
			console.log(error);
			setLoading(false);
			if (error.message === "Invalid username/password.") {
				toast.error("Invalid email/password.");
			} else if (
				error?.message.includes("Unable to connect to the Parse API")
			) {
				toast.error("Opps. You are not connected to the internet.");
			} else {
				toast.error(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="space-y-5 bg-white rounded-lg py-6 px-5 w-[88%] max-w-[420px]">
			<div className="flex items-center justify-between text-fortcore-green zabalRegFont">
				<h2 className="text-[15px] md:text-base font-semibold">Login</h2>
				<button onClick={() => dispatch(closeMiniLoginModal())}>
					<MdOutlineClose size={20} />
				</button>
			</div>
			<form className="flex flex-col gap-3" onSubmit={handleLogin}>
				<div className="flex flex-col gap-1.5 text-[#393F42] ">
					<label className="text-xs zabalMedFont inline" htmlFor="email">
						Email Address
					</label>
					<input
						type="email"
						className="text-[text-[#393F42] border rounded border-[#B8B8B8] px-2.5 h-[43px] outline-none text-sm zabalRegFont focus:border-2 focus:border-fortcore-green text-[#393F42] placeholder:text-[#B8B8B8]"
						id="email"
						placeholder="Enter your email"
						value={email}
						onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col gap-1.5">
					<PasswordInput
						text="password"
						id="password"
						value={password}
						handleChange={handleChange}
						togglePassword={togglePassword}
						setTogglePassword={() => setTogglePassword((prev) => !prev)}
					/>
				</div>
				<div className="flex mt-3 justify-center">
					<button
						className={`text-white text-[15px] zabalMedFont tracking-wider grid place-items-center ${
							loading ? " loginBtnLoading" : "loginBtn"
						}`}
						disabled={loading || isBtnDisabled()}
						type="submit"
					>
						{loading ? <Preloader /> : "Login"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default MiniLogin;
