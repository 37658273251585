import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
	PlainSelect,
	SelectDropDown,
} from "../../../../layout/CustomComponents";
import { educationLevel, getFamilyCount } from "../../../../common/utils";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import { ProfileInput } from "../../../profile/sections/PersonalInfo";
import Parse from "../../../../api/ApiConfig";
import { saveFamilyInformation } from "../../../../redux/travels/travelServices";

const FamilyInformation = () => {
	const dispatch = useDispatch();
	const { familyInfoLoading, familyInfoSuccess } = useSelector(
		(state) => state.travel
	);
	const [toggleEducationLevel, setToggleEducationLevel] = useState(null);
	const handleToggleEducationLevel = (id) => {
		setToggleEducationLevel((exp) => (exp === id ? null : id));
	};

	const [toggleNumOfDependents, setToggleNumOfDependents] = useState(false);

	const [numOfDependants, setNumOfDependants] = useState(
		familyInfoSuccess?.noOfDependents || ""
	);

	const [familyInfo, setFamilyInfo] = useState(
		familyInfoSuccess
			? familyInfoSuccess.dependantsDetails
			: [
					{
						full_name: "",
						date_of_birth: "",
						relationship: "",
						phone_number: "",
						email_address: "",
						address: "",
						education_level: "",
					},
			  ]
	);

	const addDependent = () => {
		setFamilyInfo([
			...familyInfo,
			{
				full_name: "",
				date_of_birth: "",
				relationship: "",
				phone_number: "",
				email_address: "",
				address: "",
				education_level: "",
			},
		]);
	};

	const removeDepndent = (i) => {
		const formValues = [...familyInfo];
		formValues.splice(i, 1);
		setFamilyInfo(formValues);
	};

	const handleChange = (e, i) => {
		let formValues = [...familyInfo];

		formValues[i][e.target.id] = e.target.value;
		setFamilyInfo(formValues);
	};

	const choosenCount = getFamilyCount(numOfDependants);

	useEffect(() => {
		if (choosenCount === 1) {
			setFamilyInfo([
				{
					full_name: "",
					date_of_birth: "",
					relationship: "",
					phone_number: "",
					email_address: "",
					address: "",
					education_level: "",
				},
			]);
		} else {
			let formValues = [...familyInfo];

			setFamilyInfo(formValues.slice(0, choosenCount));
		}
	}, [numOfDependants]);

	const full_names = familyInfo.map((d) => d.full_name);
	const phone_numbers = familyInfo.map((d) => d.phone_number);
	const email_addresses = familyInfo.map((d) => d.email_address);
	const addresses = familyInfo.map((d) => d.address);
	const relationships = familyInfo.map((d) => d.relationship);
	const dates_of_birth = familyInfo.map((d) => d.date_of_birth);
	const education_levels = familyInfo.map((d) => d.education_level);

	const isBtnDisabled = () => {
		if (
			full_names.includes("") ||
			phone_numbers.includes("") ||
			email_addresses.includes("") ||
			addresses.includes("") ||
			relationships.includes("") ||
			dates_of_birth.includes("") ||
			education_levels.includes("")
		) {
			return true;
		}
		if (choosenCount <= 3 && familyInfo.length !== choosenCount) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onSubmitFamilyInfo = () => {
		const data = {
			userId: user,
			noOfDependents: numOfDependants,
			dependantsDetails: familyInfo,
		};
		console.log(data);
		dispatch(saveFamilyInformation(data));
	};

	return (
		<div className="bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF] space-y-3">
			<div className="space-y-4 md:space-y-5">
				<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7">
					<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont text-[#393F42]">
						Number Of Dependents:
					</p>
					<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
						<PlainSelect
							context="choose number of dependents"
							isOpened={toggleNumOfDependents}
							currentText={numOfDependants}
							handleToggle={() =>
								setToggleNumOfDependents(!toggleNumOfDependents)
							}
						/>
						{toggleNumOfDependents && (
							<div className="absolute z-[2] w-full top-48px pb-8">
								<SelectDropDown
									arrOptions={["None", "1", "2", "3", "4", "Above 4"]}
									onHandleSelect={(d) => {
										setNumOfDependants(d);
										setTimeout(() => {
											setToggleNumOfDependents(false);
										}, 100);
									}}
								/>
							</div>
						)}
					</div>
				</div>

				{choosenCount > 0 &&
					familyInfo.map((el, i) => (
						<div className="flex flex-col gap-2.5 md:gap-3" key={i}>
							<div className="flex items-center justify-between">
								<p className="text-[13px] md:text-sm text-fortcore-green chillaxFont font-semibold">
									Dependent {i + 1}
								</p>
								{i ? (
									<button
										className="text-red-600 pr-0.5"
										onClick={() => removeDepndent(i)}
									>
										<MdDelete size={18} />
									</button>
								) : null}
							</div>
							<ProfileInput
								type="text"
								id="full_name"
								text="Full Name"
								value={el.full_name}
								placeholderText="Full Name (as on passport)"
								handleChange={(e) => handleChange(e, i)}
							/>
							<ProfileInput
								type="date"
								id="date_of_birth"
								text="Date of Birth"
								value={el.date_of_birth}
								max={moment().format("YYYY-MM-DD")}
								placeholderText="Date of Birth"
								handleChange={(e) => handleChange(e, i)}
							/>
							<ProfileInput
								type="text"
								id="relationship"
								text="Relationship"
								value={el.relationship}
								placeholderText="Enter relationship"
								handleChange={(e) => handleChange(e, i)}
							/>
							<ProfileInput
								type="tel"
								id="phone_number"
								text="Phone Number"
								value={el.phone_number}
								placeholderText="Enter Phone Number"
								maxLength={13}
								handleChange={(e) => {
									if (isNaN(e.target.value)) {
										return;
									}
									handleChange(e, i);
								}}
							/>
							<ProfileInput
								type="email"
								id="email_address"
								text="Email Address"
								value={el.email_address}
								placeholderText="Enter Email Address"
								handleChange={(e) => handleChange(e, i)}
							/>
							<ProfileInput
								type="address"
								id="address"
								text="Address"
								value={el.address}
								placeholderText="Enter home address"
								handleChange={(e) => handleChange(e, i)}
							/>
							<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7">
								<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont text-[#393F42]">
									Education Level:
								</p>
								<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
									<PlainSelect
										context="select education level"
										isOpened={toggleEducationLevel === i}
										currentText={el.education_level}
										handleToggle={() => handleToggleEducationLevel(i)}
									/>
									{toggleEducationLevel === i && (
										<div className="absolute z-[2] w-full top-48px pb-8">
											<SelectDropDown
												arrOptions={educationLevel}
												onHandleSelect={(d) => {
													let formValues = [...familyInfo];
													formValues[i].education_level = d;
													setFamilyInfo(formValues);
													handleToggleEducationLevel(i);
												}}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					))}
			</div>
			{familyInfo.length < choosenCount && (
				<div>
					<button
						className="text-[13px] md:text-[13.5px] zabalMedFont font-semibold text-fortcore-green"
						onClick={addDependent}
					>
						Add Dependent
					</button>
				</div>
			)}
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || familyInfoLoading}
					isLoading={familyInfoLoading}
					onRequestClick={onSubmitFamilyInfo}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || familyInfoLoading}
					isLoading={familyInfoLoading}
					onRequestClick={onSubmitFamilyInfo}
				/>
			</div>
		</div>
	);
};

export default FamilyInformation;
