/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Data } from "../../data/VideoData";
import { AiFillPlayCircle } from "react-icons/ai";
import { useTheme } from "@mui/material/styles";
import { BsFillClockFill } from "react-icons/bs";
import { EducationContext } from "../../Context/EducationContext";
import Parse from "../../../../api/ApiConfig";
import VideoUrl from "../../assets/video.mp4";

function VideoPage() {
  const videoRef = useRef(null);
  const { id } = useParams();
  const theme = useTheme();
  const [chapter, setChapter] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoCoverPhoto, setVideoCoverPhoto] = useState("");
  const { educationData } = useContext(EducationContext);

  const data = educationData.find((video) => video.objectId === id);

  useEffect(() => {
    const updateCourseState = async () => {
      try {
        const currentUser = Parse.User.current();
        if (currentUser) {
          const Education = Parse.Object.extend("Education");
          const educationQuery = new Parse.Query(Education);

          educationQuery.equalTo("objectId", id);

          const videoData = await educationQuery.first();

          if (videoData) {
            videoData.set("isOpened", true);

            await videoData.save();
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-undef
        console.log(error);
      }
    };

    updateCourseState();
  }, []);

  // eslint-disable-next-line no-undef
  console.log("DataPage: ", data);

  useEffect(() => {
    if (data && data.chapters.length > 0) {
      if (!chapter) {
        const firstChapter = data.chapters[0];
        setChapter(firstChapter.chapter);
        setVideoUrl(firstChapter.link);
        setDescription(firstChapter.description);
        setVideoCoverPhoto(firstChapter.coverPhoto);
        if (videoRef.current) {
          videoRef.current.load();
        }
      } else {
        const selectedChapter = data.chapters.find(
          (ch) => ch.chapter === chapter
        );
        if (selectedChapter) {
          setVideoUrl(selectedChapter.link);
          setDescription(selectedChapter.description);
          setChapter(selectedChapter.chapter);
          setVideoCoverPhoto(selectedChapter.coverPhoto);
        }
      }
    } else if (data && data.chapters.length === 0) {
      setVideoUrl(data.link);
      setDescription(data.description);
      setVideoCoverPhoto(data.coverPhoto);
      if (videoRef.current) {
        videoRef.current.load();
      }
    }
  }, [data, chapter]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, []);

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box>
        <Box>
          <Typography
            color="primary"
            variant="h3"
            sx={{ textTransform: "capitalize" }}
          >
            {data.name}
          </Typography>
          <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

          {/* Video  */}

          <Box>
            <Card
              sx={{
                width: "100%",
                height: "100%",
                padding: 0,
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <CardMedia>
                <video
                  ref={videoRef}
                  controls
                  poster={videoCoverPhoto}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  step="10"
                >
                  <source src={videoUrl} type="video/mp4" />
                  {/* <source src={VideoUrl} type="video/mp4" /> */}
                </video>
              </CardMedia>
              <CardContent
                sx={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  padding: "16px",
                }}
              >
                {/* <Typography variant="h3" color="primary.light">
                  Chapter {chapter}
                </Typography> */}
              </CardContent>
            </Card>
          </Box>

          {/* Content  */}
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ marginTop: "30px" }}
          >
            {/* Description  */}
            <Box width={{ xs: "100%", md: "50%" }}>
              <Stack spacing={1}>
                <Typography variant="h3" color="primary">
                  Description
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary.light"
                  paragraph
                  sx={{ textTransform: "capitalize" }}
                >
                  {description}
                </Typography>
              </Stack>
            </Box>

            {/* Course  */}
            <Box
              width={{ xs: "100%", md: "50%" }}
              sx={{
                border: "1px solid #E6E9EB",
                padding: "10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <Stack spacing={1}>
                <Typography variant="h3" color="primary">
                  Course
                </Typography>
                <Divider
                  sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }}
                />
                {data.chapters.length > 0 ? (
                  <>
                    {data.chapters.map((data, index) => (
                      <>
                        <Box
                          backgroundColor={
                            chapter === data.chapter &&
                            theme.palette.primary.main
                          }
                          sx={{
                            borderBottom: "1px solid #EEF0F1",
                            borderRadius: "8px",
                          }}
                          key={index}
                          onClick={() => {
                            setChapter(data.chapter);
                            setDescription(data.description);
                            setVideoUrl(data.link);
                            setVideoCoverPhoto(data.coverPhoto);
                            videoRef.current.pause();
                            videoRef.current.load();
                            // eslint-disable-next-line no-undef
                            window.scrollTo(80, 80);
                          }}
                        >
                          <List>
                            <ListItem>
                              <ListItemAvatar>
                                <AiFillPlayCircle
                                  color={
                                    chapter === data.chapter
                                      ? theme.palette.primary.light
                                      : theme.palette.primary.main
                                  }
                                  style={{ fontSize: "30px" }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primaryTypographyProps={{
                                  color:
                                    chapter === data.chapter
                                      ? theme.palette.primary.light
                                      : theme.palette.primary.main,
                                }}
                                secondaryTypographyProps={{
                                  color:
                                    chapter === data.chapter
                                      ? theme.palette.info.dark
                                      : theme.palette.secondary.light,
                                }}
                                primary={
                                  <Typography
                                    sx={{
                                      textTransform: "capitalize",
                                      color:
                                        chapter === data.chapter
                                          ? theme.palette.primary.light
                                          : theme.palette.primary.main,
                                    }}
                                  >
                                    {data.name}
                                  </Typography>
                                }
                                secondary={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: 1,
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      color={
                                        chapter === data.chapter
                                          ? theme.palette.primary.light
                                          : "#0000007a"
                                      }
                                    >
                                      Chapter {data.chapter}
                                    </Typography>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <BsFillClockFill
                                        color={
                                          chapter === data.chapter
                                            ? theme.palette.primary.light
                                            : "#0000007a"
                                        }
                                        style={{
                                          display: { xs: "none", sm: "flex" },
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        color={
                                          chapter === data.chapter
                                            ? theme.palette.primary.light
                                            : "#0000007a"
                                        }
                                      >
                                        &nbsp; {data.duration} Long
                                      </Typography>
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItem>
                          </List>
                        </Box>
                      </>
                    ))}
                  </>
                ) : (
                  <Box
                    backgroundColor={theme.palette.primary.main}
                    sx={{
                      borderBottom: "1px solid #EEF0F1",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      setDescription(data.description);
                      setVideoUrl(data.link);
                      videoRef.current.pause();
                      videoRef.current.load();
                      // eslint-disable-next-line no-undef
                      window.scrollTo(80, 80);
                    }}
                  >
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <AiFillPlayCircle
                            color={theme.palette.primary.light}
                            style={{
                              fontSize: "30px",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={{
                            color: theme.palette.primary.light,
                          }}
                          secondaryTypographyProps={{
                            color: theme.palette.info.main,
                          }}
                          primary={
                            <Typography
                              sx={{ textTransform: "capitalize" }}
                              color="primary.light"
                            >
                              {data.name}
                            </Typography>
                          }
                          // secondary={data.description}
                          secondary={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 1,
                                flexWrap: "wrap",
                              }}
                            >
                              <Typography variant="caption">
                                {data.description}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <BsFillClockFill
                                  color={theme.palette.primary.light}
                                  style={{
                                    display: { xs: "none", sm: "flex" },
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  color={theme.palette.primary.light}
                                >
                                  &nbsp; {data.totalDuration} Long
                                </Typography>
                              </Box>
                            </Box>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default VideoPage;
