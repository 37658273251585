import React from "react";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
import {
	formatAmountToIncludeCurrency,
	formatLeaseeStatus,
} from "../../common/utils";

const ViewInvestorsDetails = ({ investorsArray, onClose }) => {
	function createData(
		investorId,
		amount,
		slots,
		paymentStatus,
		approvalStatus
	) {
		return { investorId, amount, slots, paymentStatus, approvalStatus };
	}

	const rows = investorsArray?.map((investor) =>
		createData(
			investor?.user,
			investor?.amountToInvest,
			investor?.slots,
			investor?.paid,
			investor?.approvalStatus
		)
	);

	return (
		<div className="bg-white rounded-md py-4 md:py-5 w-[90%] max-w-[600px] overflow-x-auto scroll-wrapper zabalRegFont">
			<div className="flex items-center justify-between text-primary-color px-4 md:px-5">
				<h4 className="text-sm md:text-[14.5px] zabalMedFont">
					Investor(s) Details
				</h4>
				<button onClick={onClose}>
					<MdClose size={18} />
				</button>
			</div>
			<div className="mx-4 md:mx-5 my-4 rounded-md border overflow-x-auto px-3 scroll-wrapper">
				<div className="min-w-[700px]">
					<div className="grid grid-cols-5 gap-4 text-[13.5px] md:text-sm zabalMedFont w-full py-3 border-b">
						<p>Investors&apos; Info</p>
						<p>Number of Slots</p>
						<p>Amount To Invest</p>
						<p>Payment Status</p>
						<p>Approval Status</p>
					</div>
					<div className="grid">
						{rows.map((row) => (
							<div
								key={row?.investorId}
								className="grid grid-cols-5 gap-3 text-[13px] md:text-[13.5px] zabalRegFont w-full py-3 border-b last:border-none"
							>
								<p>{row?.name || row?.investorId}</p>
								<p className="text-center">{row?.slots}</p>
								<p className="text-center">
									{formatAmountToIncludeCurrency("NGN", row?.amount)}
								</p>
								<p
									className={`text-center zabalMedFont ${
										row?.paymentStatus ? "text-green-700" : "text-[#FFC214]"
									}`}
								>
									{row?.paymentStatus ? "Paid" : "Pending"}
								</p>
								<p
									className="text-center"
									style={{
										color: formatLeaseeStatus(row?.approvalStatus || "pending")
											?.color,
									}}
								>
									{formatLeaseeStatus(row?.approvalStatus || "pending")?.status}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

ViewInvestorsDetails.propTypes = {
	investorsArray: PropTypes.array,
	onClose: PropTypes.func,
};

export default ViewInvestorsDetails;
