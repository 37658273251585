export const TOGGLE_FINANCE_FORM_MODAL = "TOGGLE_FINANCE_FORM_MODAL";
export const SELECTED_FINANCE_OPTION = "SELECTED_FINANCE_OPTION";
export const SAVE_FINANCE_USER_REQUEST = "SAVE_FINANCE_USER_REQUEST";
export const CLEAR_USER_REQ_FINANCE_DATA = "CLEAR_USER_REQ_FINANCE_DATA";
export const SAVE_INVESTOR_FINANCE_REQUEST = "SAVE_INVESTOR_FINANCE_REQUEST";
export const TOGGLE_DELETE_FINANCE_MODAL = "TOGGLE_DELETE_FINANCE_MODAL";
export const SELECT_FINANCE_TO_ACTIVATE = "SELECT_FINANCE_TO_ACTIVATE";
export const SAVE_APPRENTICE_SELECTED_REQUEST =
	"SAVE_SELECTED_APPRENTICE_REQUEST";
export const USER_CHOOSES_CREATED_FINANCE = "USER_CHOOSES_CREATED_FINANCE";
export const TOGGLE_VIEW_FINANCE_REQUESTS_MODAL =
	"TOGGLE_VIEW_FINANCE_REQUESTS_MODAL";
export const TOGGLE_PAY_COMMITMENT_FEE = "TOGGLE_PAY_COMMITMENT_FEE";
