import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { Option } from "../../../components/payments/PaymentOption";
import { useDispatch, useSelector } from "react-redux";
import {
	closeInvestorReqLeaseModal,
	closePayWithWalletModal,
	openPayWithWalletModal,
	selectedPaymentMethod,
} from "../../../redux/lease/leaseActions";
import { Navigate, useNavigate } from "react-router";
import CardDetails from "../../../components/payments/CardDetails";
import ModalContainer from "../../../components/ModalContainer";
import PartialWalletMode from "../../../components/payments/PartialWalletMode";
import {
	ConfirmPin,
	CreatePin,
	EnterPin,
} from "../../../components/payments/PinComponent";
import { clearPinCreationSuccess } from "../../../redux/authRedux/authActions";
import {
	createAccessPin,
	logoutUser,
} from "../../../redux/authRedux/authServices";
import {
	getAmountWithDurationAndInterest,
	getSingularOrPlural,
	walletType,
} from "../../../common/utils";
import { toast } from "react-toastify";
import FullInvestmentMode from "../../../components/payments/FullInvestmentMode";
import Parse from "../../../api/ApiConfig";
import { updateOngoingLeaseInParse } from "../../../redux/lease/leaseServices";
import { clearMoveFundsSuccess } from "../../../redux/payments/paymentActions";
import { moveUserFunds } from "../../../redux/payments/paymentServices";

const InvestmentOptions = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(1);
	const [walletMethodTab, setWalletMethodTab] = useState(0);
	const [investorPinStatus, setInvestorPinStatus] = useState("");
	const [inputPin, setInputPin] = useState("");
	const [createPin, setCreatePin] = useState("");
	const [confirmPin, setConfirmPin] = useState("");
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [invalidConfirmPinError, setInvalidConfirmPinError] = useState(false);

	const {
		selectedReqLeaseItem,
		payment,
		togglePayWithWalletModal,
		updateOngoingTransactionLoading,
		updateOngoingTransactionSuccess,
	} = useSelector((state) => state.lease);

	const { currentUser, accessPinSuccess } = useSelector((state) => state.auth);

	const { customerWallet, moveFundsLoading, moveFundsSuccess } = useSelector(
		(state) => state.payment
	);

	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const [walletForm, setWalletForm] = useState({
		amount_payable: "",
		period: "",
		length: "",
		selectedSlots: null,
	});

	const { amount_payable, period, length, selectedSlots } = walletForm;

	useEffect(() => {
		dispatch(closeInvestorReqLeaseModal());
		dispatch(closePayWithWalletModal());
		setInputPin("");
	}, []);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (accessPinSuccess) {
			setWalletMethodTab(2);
			setInvestorPinStatus("pin creation success");
		}
	}, [accessPinSuccess]);

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	useEffect(() => {
		if (confirmPin.length < 4) {
			return;
		} else if (confirmPin !== createPin) {
			setInvalidConfirmPinError(true);
			setTimeout(() => {
				setInvalidConfirmPinError(false);
			}, 1500);
		}
	}, [confirmPin]);

	const interestAmount = getAmountWithDurationAndInterest(
		selectedReqLeaseItem?.interest,
		selectedReqLeaseItem?.leaseDuration,
		selectedReqLeaseItem?.price
	);

	const onFullInvestmentClick = () => {
		dispatch(selectedPaymentMethod({ mode: "full_investment" }));
		if (payment?.method === "wallet") {
			setActiveTab(4);
		} else {
			setActiveTab(2);
		}
	};

	const onPartialInvestmentClick = () => {
		dispatch(selectedPaymentMethod({ mode: "partial_investment" }));
		if (payment?.method === "wallet") {
			setActiveTab(3);
		} else {
			setActiveTab(2);
		}
	};

	const amountPayable =
		payment?.mode === "full_investment"
			? Number(selectedReqLeaseItem?.price)
			: selectedSlots?.price;

	const onOpenPaymentModal = () => {
		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}

		dispatch(openPayWithWalletModal());
		setWalletMethodTab(1);
	};

	const onCloseWalletModal = () => {
		dispatch(closePayWithWalletModal());
		setWalletMethodTab(0);
	};

	const onProceedToInputPin = () => {
		setWalletMethodTab(2);
		setInvestorPinStatus("");
	};

	const customModalText =
		payment?.mode === "full_investment"
			? `The amount of N${selectedReqLeaseItem?.price} will be deducted from your wallet for a full investment on this asset.`
			: `The amount of N${selectedSlots?.price} will be deducted from your wallet for partial investment on this asset.`;

	const onProceedToCreatePin = () => {
		setWalletMethodTab(2);
		setInvestorPinStatus("create-pin");
	};

	const onCloseBtnClickFromCreatePin = () => {
		setWalletMethodTab(2);
		setInvestorPinStatus("");
	};

	const onProceedToConfirmPin = () => {
		setWalletMethodTab(2);
		setInvestorPinStatus("confirm-pin");
	};

	const onCreatePin = () => {
		dispatch(createAccessPin({ accessPin: confirmPin }));
	};

	const onPinCreationSuccess = () => {
		setWalletMethodTab(2);
		setInvestorPinStatus("");
		dispatch(clearPinCreationSuccess());
	};

	const onCloseBtnClickFromConfirmPin = () => {
		setWalletMethodTab(2);
		setInvestorPinStatus("create-pin");
	};

	const onProceedToFundAsset = () => {
		if (!Parse.User.current()?.id) {
			dispatch(logoutUser());
			return;
		}

		const details = {
			amount: String(amountPayable),
			source: "main",
			destination: walletType.leaseInvestment,
			message: "investor-slot-payment-for-created-lease-request",
		};

		dispatch(moveUserFunds(currentSessionToken, details));
	};

	const slotsToPurchase =
		payment?.mode === "full_investment" ? 4 : selectedSlots?.slotNum;

	const interestToEarn = (interestAmount / 4) * slotsToPurchase || 0;

	useEffect(() => {
		if (
			moveFundsSuccess === "investor-slot-payment-for-created-lease-request"
		) {
			dispatch(clearMoveFundsSuccess());
			const updatedData = {
				funded: true,
				investors: [Parse.User.current()?.id],
				investorsArray: [
					{
						user: Parse.User.current()?.id,
						name: `${currentUser?.firstName} ${currentUser?.lastName}`,
						phone: currentUser?.phone,
						address: currentUser?.homeAddress,
						slots:
							payment?.mode === "full_investment" ? 4 : selectedSlots?.slotNum,
						amountToInvest:
							payment?.mode === "full_investment"
								? selectedReqLeaseItem?.price
								: selectedSlots?.price,
						interestToEarn,
						paid: true,
					},
				],
				paidSlots:
					payment?.mode === "full_investment" ? 4 : selectedSlots?.slotNum,
				approvalStatus:
					payment?.mode === "full_investment" ? "approved" : "pending",
				investorWaitingPeriod:
					payment?.mode === "full_investment"
						? ""
						: `${length} ${getSingularOrPlural(length, period)}`,
				type:
					payment?.mode === "full_investment"
						? "full-investment"
						: "partial-investment",
			};
			console.log(updatedData);
			dispatch(
				updateOngoingLeaseInParse(
					selectedReqLeaseItem?.objectId,
					updatedData,
					undefined,
					"update-investor-paid-slots"
				)
			);
		} else {
			return;
		}
	}, [moveFundsSuccess]);

	useEffect(() => {
		if (updateOngoingTransactionSuccess === "update-investor-paid-slots") {
			setWalletMethodTab(3);
		} else {
			return;
		}
	}, [updateOngoingTransactionSuccess]);

	const successMsg =
		payment?.mode === "full_investment"
			? `You have successfully invested the full investment amount of N${selectedReqLeaseItem?.price} on this asset.`
			: `The amount of N${selectedSlots?.price} has been deducted for partial investment on this asset. `;

	const onUpdateLeaseSuccess = () => {
		navigate("/leasing/investment-board");
		dispatch(closeInvestorReqLeaseModal());
	};

	if (!selectedReqLeaseItem) {
		return <Navigate to="/leasing/new-transaction/investor-market-place" />;
	}

	return (
		<>
			{togglePayWithWalletModal && (
				<ModalContainer modal={togglePayWithWalletModal}>
					<>
						{walletMethodTab === 1 && (
							<CustomConfirmModal
								isNotify={true}
								text={customModalText}
								greenBtnText="proceed"
								onCloseBtnClick={onCloseWalletModal}
								greenBtnClick={onProceedToInputPin}
							/>
						)}
						{walletMethodTab === 2 && investorPinStatus === "" && (
							<EnterPin
								btnText="proceed"
								onCloseBtnClick={onCloseWalletModal}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								isPinExist={currentUser?.accessPin !== undefined}
								proceedToCreatePin={onProceedToCreatePin}
								onRequestClick={onProceedToFundAsset}
								isPaymentLoading={
									moveFundsLoading || updateOngoingTransactionLoading
								}
							/>
						)}
						{walletMethodTab === 2 && investorPinStatus === "create-pin" && (
							<CreatePin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromCreatePin}
								onInputChange={setCreatePin}
								onRequestClick={onProceedToConfirmPin}
								isAllowedToProceed={createPin.length > 3}
							/>
						)}
						{walletMethodTab === 2 && investorPinStatus === "confirm-pin" && (
							<ConfirmPin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromConfirmPin}
								onInputChange={setConfirmPin}
								onRequestClick={onCreatePin}
								isAllowedToProceed={
									confirmPin.length > 3 && createPin === confirmPin
								}
								isPinInvalid={invalidConfirmPinError}
							/>
						)}
						{walletMethodTab === 2 &&
							investorPinStatus === "pin creation success" && (
								<SuccessCheckModal
									onRequestClick={onPinCreationSuccess}
									text={`Access pin has been ${
										accessPin ? "updated" : "created"
									} successfully!`}
									isShowBtn={true}
									btnText="okay"
								/>
							)}
						{walletMethodTab === 3 && (
							<SuccessCheckModal
								text={successMsg}
								isShowBtn
								btnText="view portfolio"
								onRequestClick={onUpdateLeaseSuccess}
							/>
						)}
					</>
				</ModalContainer>
			)}
			<Helmet>
				<title>Fortcore - Investment Options</title>
			</Helmet>
			<MobileNavDetails text="fund request" />
			<Container>
				<>
					<Navbar text="new transaction" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="new transaction"
							onNavigate={() => navigate("/leasing/new-transaction")}
						/>
						<UrlTracker
							text="fund request"
							onNavigate={() =>
								navigate("/leasing/new-transaction/fund-request")
							}
						/>
						<UrlTracker text="investment options" isCurrentUrl />
					</div>
					{activeTab === 1 && (
						<>
							<BackTracker text="leasing" onNavigate={() => navigate(-1)} />
							<div className="mb-6 sm:my-5 md:my-6">
								<div className="space-y-6">
									<div className="space-y-1.5">
										<p className="text-[14.5px] text-fortcore-green zabalBoldFont">
											Choose Investment Options
										</p>
										<p className="text-xs text-[#717E83] zabalMedFont">
											Complete the required action below to submit this
											transaction
										</p>
									</div>
									<div className="grid sm:grid-cols-2 gap-3.5">
										<Option
											text="Full Investment Amount"
											height={true}
											onRequestClick={onFullInvestmentClick}
										/>
										<Option
											text="Partial Investment Amount"
											height={true}
											onRequestClick={onPartialInvestmentClick}
										/>
									</div>
								</div>
							</div>
						</>
					)}
					{activeTab === 2 && (
						<CardDetails
							setActiveTab={setActiveTab}
							amountPayable={
								payment?.mode === "full_investment"
									? selectedReqLeaseItem?.productObj.amount
									: ""
							}
						/>
					)}
					{activeTab === 3 && (
						<PartialWalletMode
							setActiveTab={setActiveTab}
							onBtnClick={onOpenPaymentModal}
							setWalletForm={setWalletForm}
							amount_payable={amount_payable}
							period={period}
							length={length}
							interestAmount={interestAmount}
							selectedSlots={selectedSlots}
						/>
					)}
					{activeTab === 4 && (
						<FullInvestmentMode
							setActiveTab={setActiveTab}
							amount_to_invest={selectedReqLeaseItem?.productObj.amount}
							interest={selectedReqLeaseItem?.interest}
							interestAmount={interestAmount}
							onBtnClick={onOpenPaymentModal}
							// onBtnClick={onBtnClickOnFullMode}
							// setWalletForm={setWalletForm}
							// amount_payable={amount_payable}
							// period={period}
							// length={length}
						/>
					)}
				</>
			</Container>
		</>
	);
};
export default InvestmentOptions;
