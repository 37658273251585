import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../../../components/mui/theme";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import {
	TrackerRadio,
	TrackerText,
} from "../../../leasing/submittedtransactions";
import {
	addAmounts,
	formatAmountToIncludeCurrency,
} from "../../../../common/utils";
import { onToggleUpcomingPayments } from "../../../../redux/payments/paymentActions";
import { useDispatch } from "react-redux";
import { onTogglePayDeliveryFeeModal } from "../../../../redux/lease/leaseActions";
import { toast } from "react-toastify";

function TransactBox({ date, time, request }) {
	const navigate = useNavigate();
	// console.log(request);
	const status = {
		autoDebit: request?.autoDebit,
		insurance: request?.insurance,
		guarantor: request?.guarantor,
		requestType: request?.requestType,
		approvalStatus: request?.approvalStatus,
		commitmentFee: request?.commitmentFeeStatus === "paid",
		investors: request?.investorsInfo,
		totalAmountToPay: formatAmountToIncludeCurrency(undefined, request?.amount),
		financeAgreement: request?.financeAgreement,
		funded: request?.funded,
		apprenticeId: request?.objectId,
		assetDeployed: request?.logisticStatus === "deployed",
		deliveryInfo: request?.deliveryInfo,
	};

	const [showStatus, setShowStatus] = useState(false);
	return (
		<Box>
			<Box
				mt={2}
				sx={{
					border: "1px solid var(--grey-500, #B8C8CF)",
					borderRadius: "8px",
					background: theme.palette.secondary.main,
					p: { xs: 2, sm: 4 },
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					cursor: "pointer",
				}}
				onClick={() => setShowStatus(!showStatus)}
			>
				<Stack spacing={1}>
					<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							Transaction ID:{" "}
						</Typography>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
							sx={{ textTransform: "capitalize" }}
						>
							{request?.objectId}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							Amount:{" "}
						</Typography>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							<span style={{ fontWeight: 600 }}>NGN</span> {request?.amount}{" "}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							Business Interest:{" "}
						</Typography>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
							sx={{ textTransform: "capitalize" }}
						>
							{request?.businessType}{" "}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							Interest:{" "}
						</Typography>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
							sx={{ textTransform: "capitalize" }}
						>
							{request?.interestRate}{" "}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							Tenure:{" "}
						</Typography>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
							sx={{ textTransform: "capitalize" }}
						>
							{request?.tenure}{" "}
						</Typography>
					</Box>

					{/* <Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
						>
							Category:{" "}
						</Typography>
						<Typography
							variant="body1"
							color={theme.palette.textSecondary.main}
							sx={{ textTransform: "capitalize" }}
						>
							{category}{" "}
						</Typography>
					</Box> */}

					<Box sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}>
						<Box
							sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}
							alignItems="center"
						>
							<Typography
								variant="body1"
								color={theme.palette.textSecondary.main}
							>
								Date:{" "}
							</Typography>
							<Typography
								variant="body1"
								color={theme.palette.textSecondary.main}
								sx={{ textTransform: "capitalize" }}
							>
								{date}{" "}
							</Typography>
						</Box>
						{/* Time  */}
						<Box
							sx={{ display: "flex", columnGap: 1, flexWrap: "wrap" }}
							alignItems="center"
						>
							<Typography
								variant="body1"
								color={theme.palette.textSecondary.main}
							>
								Time:{" "}
							</Typography>
							<Typography
								variant="body1"
								color={theme.palette.textSecondary.main}
								sx={{ textTransform: "capitalize" }}
							>
								{time}{" "}
							</Typography>
						</Box>
					</Box>
				</Stack>

				{!showStatus ? (
					<IconButton onClick={() => setShowStatus(false)}>
						<MdOutlineKeyboardArrowDown />
					</IconButton>
				) : (
					<IconButton onClick={() => setShowStatus(true)}>
						<MdOutlineKeyboardArrowUp />
					</IconButton>
				)}
			</Box>

			{/* Status Box  */}
			{showStatus && (
				<div className="mt-6 rounded-lg pl-3 py-5 pr-4 md:p-6 bg-[#fefefe] border border-[#B8C8CF] space-y-4">
					<FirstStatusTracker
						status={status}
						onPayFee={() =>
							navigate(`/financing/${request?.objectId}/finance-agreement`)
						}
					/>
				</div>
			)}
		</Box>
	);
}

TransactBox.propTypes = {
	date: PropTypes.node,
	time: PropTypes.node,
	request: PropTypes.object,
};

export default TransactBox;

const FirstStatusTracker = ({ status, onPayFee }) => {
	const dispatch = useDispatch();

	const arrOfAmount = status?.investors
		? [...status.investors.map((a) => a.amountPaid)]
		: [0];

	const totalAmountReceived = status?.investors ? addAmounts(arrOfAmount) : 0;

	const isAmountComplete = totalAmountReceived === status?.totalAmountToPay;

	const canPayCommitmentFee = () => {
		if (status?.approvalStatus === "approved" && status?.commitmentFee) {
			return false;
		}
		if (status?.approvalStatus === "approved" && !status?.commitmentFee) {
			return true;
		}
		if (status?.approvalStatus === "rejected") {
			return false;
		}
		if (status?.approvalStatus === "pending") {
			return false;
		}
	};

	const isCommitmentFeePending = () => {
		if (status?.approvalStatus === "approved" && !status?.commitmentFee) {
			return true;
		} else {
			return false;
		}
	};

	const cannotSignFinanceAgreement = () => {
		if (status?.requestType === "new") {
			if (status?.commitmentFee && status?.financeAgreement) {
				return true;
			}
			if (!status?.funded) {
				return true;
			} else {
				return false;
			}
		}
	};

	// console.log(status);

	const showPendingOnDelivery = () => {
		if (status?.approvalStatus === "rejected") {
			return false;
		} else if (status?.deliveryInfo?.deliveryFeeStatus === "paid") {
			return false;
		} else if (!status?.deliveryInfo?.deliveryFee) {
			return true;
		} else {
			return true;
		}
	};

	const onViewDeliveryInfo = () => {
		if (status?.financeAgreement) {
			dispatch(onTogglePayDeliveryFeeModal(true, status.apprenticeId));
		} else {
			toast.error(
				"You need to sign the finance agreement to perform this action"
			);
		}
	};

	const onCanviewPayment = () => {
		if (status?.assetDeployed) {
			return true;
		} else {
			return false;
		}
	};

	const onViewUpcomingPayments = () => {
		dispatch(onToggleUpcomingPayments(true, status?.apprenticeId));
	};

	return (
		<div className="relative">
			<div className="mt-2.5 md:mt-3 absolute">
				<TrackerRadio currentStatus={status?.autoDebit || false} />
				<TrackerRadio currentStatus={status?.insurance || false} />
				<TrackerRadio currentStatus={status?.guarantor || false} />
				{status?.requestType === "old" ? (
					<>
						<TrackerRadio currentStatus={true} />
						<TrackerRadio
							currentStatus={status.approvalStatus === "approved"}
							statusRejected={status?.approvalStatus === "rejected"}
						/>
						<TrackerRadio currentStatus={status?.commitmentFee} />
						<TrackerRadio currentStatus={!!status?.financeAgreement} />
					</>
				) : (
					<>
						<TrackerRadio currentStatus={status?.commitmentFee} />
						<TrackerRadio currentStatus={isAmountComplete} />
						<TrackerRadio currentStatus={!!status?.financeAgreement} />
					</>
				)}
				<TrackerRadio
					currentStatus={status?.deliveryInfo?.deliveryFeeStatus === "paid"}
				/>
				<TrackerRadio currentStatus={status.assetDeployed} />
				<TrackerRadio currentStatus={onCanviewPayment()} isEnd />
			</div>
			<div className="flex justify-end">
				<div className="grid gap-3 md:gap-4 w-[calc(100%-32px)] md:w-[calc(100%-37px)] lg:w-[calc(100%-42px)]">
					<TrackerText
						text="Auto Debit Authorization"
						currentStatus={status?.autoDebit || false}
					/>
					<TrackerText
						text="Insurance Authorization"
						currentStatus={status?.insurance || false}
					/>
					<TrackerText
						text="Guarantors' Information"
						currentStatus={status?.guarantor || false}
					/>
					{status?.requestType === "old" ? (
						<>
							<TrackerText text="Request Funded" currentStatus={true} />
							<TrackerText
								text={`Approval Status ( ${status?.approvalStatus} )`}
								currentStatus={status.approvalStatus === "approved"}
								statusRejected={status?.approvalStatus === "rejected"}
							/>
							<TrackerText
								text="Commitment Fee Payment"
								currentStatus={status?.commitmentFee}
								showBtns
								btnText="Pay Now"
								isBtnDisabled={!canPayCommitmentFee()}
								showPending={isCommitmentFeePending()}
								onNavigate={onPayFee}
							/>
							<TrackerText
								text="Finance Agreement"
								currentStatus={!!status?.financeAgreement}
							/>
						</>
					) : (
						<>
							<TrackerText
								text="Commitment Fee Payment"
								currentStatus={status?.commitmentFee}
							/>
							<TrackerText
								text={`Request ${
									isAmountComplete ? "Funded" : "Funding ( pending )"
								}`}
								currentStatus={isAmountComplete}
							/>
							<TrackerText
								text="Finance Agreement"
								currentStatus={!!status?.financeAgreement}
								btnText="Finance Agreement"
								isBtnDisabled={cannotSignFinanceAgreement()}
								showPending={!cannotSignFinanceAgreement()}
								onNavigate={onPayFee}
								showBtns
							/>
						</>
					)}
					<TrackerText
						text="delivery information"
						showBtns={!!status?.financeAgreement}
						btnText="View Details"
						currentStatus={status?.deliveryInfo?.deliveryFeeStatus === "paid"}
						onNavigate={onViewDeliveryInfo}
						showPending={showPendingOnDelivery()}
					/>
					<TrackerText
						text="asset deployed"
						currentStatus={status.assetDeployed}
					/>
					<TrackerText
						text="Upcoming Repayments"
						currentStatus={onCanviewPayment()}
						showBtns
						btnText="View Details"
						onNavigate={onViewUpcomingPayments}
						isBtnDisabled={!onCanviewPayment()}
					/>
				</div>
			</div>
		</div>
	);
};

FirstStatusTracker.propTypes = {
	status: PropTypes.object,
	onPayFee: PropTypes.func,
};
