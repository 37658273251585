import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Background from "../../../components/mui/Background";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import { Typography, Box, Stack, IconButton, Skeleton } from "@mui/material";
import { FilterIcon, SearchIcon, NoDataIcon } from "../../../layout/assets";
import theme from "../../../components/mui/theme";
import TransactBox from "./components/TransactBox";
import { BackTracker, UrlTracker } from "../../../layout/CustomComponents";
import { fetchSubmittedRequests } from "../../../redux/financing/financeServices";
import ModalContainer from "../../../components/ModalContainer";
import UpcomingPayments from "../../../components/payments/UpcomingPayments";
import { onToggleUpcomingPayments } from "../../../redux/payments/paymentActions";
import DeliveryInfo from "../../../components/payments/DeliveryInfo";

function SubmittedTransactions() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [fetching, setFetching] = useState(false);
	const [submitted, setSubmitted] = useState([]);

	const { toggleUpcomingPayments, currentItemToRepay } = useSelector(
		(state) => state.payment
	);

	const { toggleDeliveryFeeModal, currentLeaseId } = useSelector(
		(state) => state.lease
	);

	useEffect(() => {
		const fetchRequests = async () => {
			setFetching(true);
			try {
				const response = await fetchSubmittedRequests();

				setSubmitted(response);
			} catch (error) {
				console.log(error);
				toast.error(error);
			} finally {
				setFetching(false);
			}
		};

		fetchRequests();
	}, []);

	const selectedItem = submitted?.find(
		(a) => a?.objectId === currentItemToRepay || currentLeaseId
	);

	const onClosePaymentsModal = () => dispatch(onToggleUpcomingPayments(false));

	return (
		<>
			{toggleDeliveryFeeModal && (
				<ModalContainer modal={toggleDeliveryFeeModal}>
					<DeliveryInfo leaseItem={selectedItem} context="AF" />
				</ModalContainer>
			)}
			{toggleUpcomingPayments && (
				<ModalContainer modal={toggleUpcomingPayments}>
					<UpcomingPayments
						requestData={selectedItem}
						onCloseModal={onClosePaymentsModal}
						context="AF"
					/>
				</ModalContainer>
			)}

			<MobileNavDetails text="Submitted Transactions" />
			<Container>
				<Helmet>
					<title>Fortcore - Submitted Transactions</title>
				</Helmet>
				<Navbar text="Submitted Transactions" />
				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="hidden sm:flex gap-1.5">
					<UrlTracker
						text="apprenticeship Finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker text="submitted transactions" isCurrentUrl />
				</div>
				<BackTracker
					text="apprenticeship finance"
					onNavigate={() => navigate("/financing")}
				/>
				<div className="sm:mt-10"></div>
				<Background>
					<Box p={{ xs: 2, sm: 4 }} sx={{ borderRadius: "8px" }}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h2" color={theme.palette.primary.main}>
								Transaction Status
							</Typography>

							<Stack direction="row">
								<IconButton onClick={() => {}}>
									<SearchIcon />
								</IconButton>
								<IconButton onClick={() => {}}>
									<FilterIcon />
								</IconButton>
							</Stack>
						</Stack>

						{fetching ? (
							<>
								{data.map((data) => (
									<Box key={data.id}>
										<SkeletonBox />
									</Box>
								))}
							</>
						) : (
							<Box>
								{submitted.length > 0 ? (
									<>
										{submitted.map((data, index) => {
											const timestamp = data?.updatedAt;
											const dateObj = new Date(timestamp);
											const dateString = moment(timestamp).format("DD/MM/YYYY");
											const timeString = dateObj.toLocaleTimeString();
											return (
												<Box key={index}>
													<TransactBox
														request={data}
														date={dateString}
														time={timeString}
													/>
												</Box>
											);
										})}
									</>
								) : (
									<Box
										height="50vh"
										sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<NoDataIcon />
										<Typography mt={2} color={theme.palette.secondary.dark}>
											You have no investments
										</Typography>
									</Box>
								)}
							</Box>
						)}
					</Box>
				</Background>
			</Container>
		</>
	);
}

export default SubmittedTransactions;

const data = [
	{
		id: 1,
		amount: 1000000,
		businessInterest: "eletrical part",
		interest: 36,
		tenor: "24 Months",
		date: "24th Mar 2023",
		time: "12:12PM",
		status: "awaiting guarantor",
	},
	{
		id: 2,
		amount: 1000000,
		businessInterest: "eletrical part",
		interest: 36,
		tenor: "24 Months",
		date: "24th Mar 2023",
		time: "12:12PM",
		status: "request submitted",
	},
	{
		id: 3,
		amount: 1000000,
		businessInterest: "eletrical part",
		interest: 36,
		tenor: "24 Months",
		date: "24th Mar 2023",
		time: "12:12PM",
		status: "request done",
	},
];

function SkeletonBox() {
	return (
		<Box
			mt={2}
			sx={{
				border: "1px solid var(--grey-500, #B8C8CF)",
				borderRadius: "8px",
				background: theme.palette.secondary.main,
				p: { xs: 2, sm: 4 },
			}}
		>
			<Stack spacing={1}>
				<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

				<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

				<Skeleton variant="text" sx={{ fontSize: "1rem" }} />

				<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
			</Stack>
		</Box>
	);
}
