import { Box } from "@mui/material";
import React from "react";
import BarChart from "./BarChart";
import PropTypes from "prop-types";

function BalanceSummary({ data }) {
	const totalAmountToInvestPerMonth = new Array(12).fill(0);
	const totalInterestPerMonth = new Array(12).fill(0);

	data.forEach((investment) => {
		const updatedAt = new Date(investment.updatedAt);
		const month = updatedAt.getMonth();

		const amount = investment?.amountToInvest || investment?.amountToRaise;

		const amountToInvest = parseFloat(amount?.replace(",", ""));
		const interest = parseFloat(investment.interest.replace(",", ""));

		totalAmountToInvestPerMonth[month] += amountToInvest;
		totalInterestPerMonth[month] += interest;
	});

	const totalAmountToInvestInt = totalAmountToInvestPerMonth.map(Math.round);
	const totalInterestInt = totalInterestPerMonth.map(Math.round);

	const chartData = {
		totalValue: totalAmountToInvestInt,
		totalInterest: totalInterestInt,
	};

	const chartLabels = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	return (
		<Box sx={style}>
			<BarChart
				data={chartData}
				labels={chartLabels}
				primaryColor="#557C8A"
				secondaryColor="#FFC670"
			/>
		</Box>
	);
}
BalanceSummary.propTypes = {
	data: PropTypes.any,
};
export default BalanceSummary;

const style = {
	background: "#F6F7F8",
	borderRadius: "12px",
	p: "32px",
	mt: 3,
};
