import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthStatus } from "./useAuthStatus";
import ModalContainer from "../components/ModalContainer";
import { Preloader } from "../components/globalStyled";

const PrivateRoute = () => {
	const { loggedIn, checkingStatus } = useAuthStatus();

	if (checkingStatus) {
		return (
			<ModalContainer>
				<Preloader />
			</ModalContainer>
		);
	}
	return loggedIn ? <Outlet /> : <Navigate to="/login" replace={true} />;
};

export default PrivateRoute;
