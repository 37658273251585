import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../layout/Navbar";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import Container from "../../layout/Container";
import Balance from "../../components/Balance";
import { useDispatch, useSelector } from "react-redux";
import { getCreditScore } from "../../redux/authRedux/authServices";
import {
	InviteFriendCard,
	RiskAppetiteCard,
	TransactionCards,
} from "../../layout/FinanceLeaseCards";
import { CreditScore } from "../dashboard/DashOverview";
import { SavingsIconTwo } from "../../layout/assets";
import { clearStatesOnConfirmLease } from "../../redux/authRedux/authActions";
import Parse from "../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { walletType } from "../../common/utils";

const Travels = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const { customerWallet } = useSelector((state) => state.payment);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	useEffect(() => {
		dispatch(getCreditScore(currentSessionToken));
		dispatch(clearStatesOnConfirmLease());
	}, []);

	return (
		<>
			<Helmet>
				<title>Forcore - Travel Advance</title>
			</Helmet>
			<MobileNavDetails text="travel advance" />
			<Container>
				<>
					<Navbar text="travel advance" />
					<div className="mt-1 sm:mt-[55px]"></div>
					<Balance
						wallet={mainWallet}
						showAddFunds
						showWithdrawFunds
						showMoveFunds
					/>
					<CreditScore />
					<div className="-mt-5"></div>
					<TransactionCards
						urlArr={[
							"/travels/new-application",
							"/travels",
							"/travels/submitted-application",
						]}
						isApplication
					/>
					<hr className="hidden sm:block" />
					<div className="py-5 pb-10 sm:py-8 grid gap-5 sm:gap-8 sm:grid-cols-2 xl:grid-cols-3">
						<RiskAppetiteCard
							text=""
							icon={<SavingsIconTwo width={60} height={60} />}
							textOne="Save for your travels"
							onReqClick={() => navigate("/travels/regular-savings/view")}
						/>
						<InviteFriendCard />
					</div>
				</>
			</Container>
		</>
	);
};
export default Travels;
