export const FETCH_MARKET_PLACE_LOADING = "FETCH_MARKET_PLACE_LOADING";
export const FETCH_MARKET_PLACE_SUCCESS = "FETCH_MARKET_PLACE_SUCCESS";
export const FETCH_MARKET_PLACE_ERROR = "FETCH_MARKET_PLACE_ERROR";
export const FETCH_INVESTOR_MARKET_PLACE_LOADING =
	"FETCH_INVESTOR_MARKET_PLACE_LOADING";
export const FETCH_INVESTOR_MARKET_PLACE_SUCCESS =
	"FETCH_INVESTOR_MARKET_PLACE_SUCCESS";
export const FETCH_INVESTOR_MARKET_PLACE_ERROR =
	"FETCH_INVESTOR_MARKET_PLACE_ERROR";
export const FETCH_REQUEST_LEASE_DATA_LOADING =
	"FETCH_REQUEST_LEASE_DATA_LOADING";
export const FETCH_REQUEST_LEASE_DATA_SUCCESS =
	"FETCH_REQUEST_LEASE_DATA_SUCCESS";
export const FETCH_REQUEST_LEASE_DATA_ERROR = "FETCH_REQUEST_LEASE_DATA_ERROR";
export const OPEN_MARKET_ITEMS_DETAILS = "OPEN_MARKET_ITEMS_DETAILS";
export const CLOSE_MARKET_ITEMS_DETAILS = "CLOSE_MARKET_ITEMS_DETAILS";
export const OPEN_PERSONAL_FORM = "OPEN_PERSONAL_FORM";
export const CLOSE_PERSONAL_FORM = "CLOSE_PERSONAL_FORM";
export const OPEN_BUSINESS_FORM = "OPEN_BUSINESS_FORM";
export const CLOSE_BUSINESS_FORM = "CLOSE_BUSINESS_FORM";
export const OPEN_INVESTOR_FORM = "OPEN_INVESTOR_FORM";
export const CLOSE_INVESTOR_FORM = "CLOSE_INVESTOR_FORM";
export const SELECTED_REQUEST_LEASE_ITEM = "SELECTED_REQUEST_LEASE_ITEM";
export const DATA_FROM_LEASE_FORM = "DATA_FROM_LEASE_FORM";
export const DATA_FROM_LEASE_STRUCTURE = "DATA_FROM_LEASE_STRUCTURE";
export const OPEN_PERSONAL_REQUEST_LEASE_MODAL =
	"OPEN_PERSONAL_REQUEST_LEASE_MODAL";
export const CLOSE_PERSONAL_REQUEST_LEASE_MODAL =
	"CLOSE_PERSONAL_REQUEST_LEASE_MODAL";
export const OPEN_BUSINESS_REQUEST_LEASE_MODAL =
	"OPEN_BUSINESS_REQUEST_LEASE_MODAL";
export const CLOSE_BUSINESS_REQUEST_LEASE_MODAL =
	"CLOSE_BUSINESS_REQUEST_LEASE_MODAL";
export const OPEN_INVESTOR_REQUEST_LEASE_MODAL =
	"OPEN_INVESTOR_REQUEST_LEASE_MODAL";
export const CLOSE_INVESTOR_REQUEST_LEASE_MODAL =
	"CLOSE_INVESTOR_REQUEST_LEASE_MODAL";
export const OPEN_CONFIRM_AUTODEBIT_MODAL = "OPEN_CONFIRM_AUTO_DEBIT_MODAL";
export const CLOSE_CONFIRM_AUTODEBIT_MODAL = "CLOSE_CONFIRM_AUTO_DEBIT_MODAL";
export const OPEN_CURRENT_ONGOING_TRANSACTION =
	"OPEN_CURRENT_ONGOING_TRANSACTION";
export const CLOSE_CURRENT_ONGOING_TRANSACTION =
	"CLOSE_CURRENT_ONGOING_TRANSACTION";
export const UPDATE_CURRENT_ONGOING_TRANSACTION =
	"UPDATE_CURRENT_ONGOING_TRANSACTION";
export const CLOSE_ONGOING_TRANSACTION_MODAL =
	"CLOSE_ONGOING_TRANSACTION_MODAL";
export const SAVE_PERSONAL_FORM_SUCCESS = "SAVE_PERSONAL_FORM_SUCCESS";
export const SAVE_BUSINESS_FORM_LOADING = "SAVE_BUSINESS_FORM_LOADING";
export const SAVE_BUSINESS_FORM_SUCCESS = "SAVE_BUSINESS_FORM_SUCCESS";
export const SAVE_BUSINESS_FORM_ERROR = "SAVE_BUSINESS_FORM_ERROR";
export const SAVE_INVESTOR_FORM_LOADING = "SAVE_INVESTOR_FORM_LOADING";
export const SAVE_INVESTOR_FORM_SUCCESS = "SAVE_INVESTOR_FORM_SUCCESS";
export const SAVE_INVESTOR_FORM_ERROR = "SAVE_INVESTOR_FORM_ERROR";
export const PERSONAL_FORM_DATA_FROM_PARSE = "PERSONAL_FORM_DATA_FROM_PARSE";
export const BUSINESS_FORM_DATA_FROM_PARSE = "BUSINESS_FORM_DATA_FROM_PARSE";
export const INVESTOR_FORM_DATA_FROM_PARSE = "INVESTOR_FORM_DATA_FROM_PARSE";
export const FETCH_ONGOING_LEASE_LOADING = "FETCH_ONGOING_LEASE_LOADING";
export const FETCH_ONGOING_LEASE_SUCCESS = "FETCH_ONGOING_LEASE_SUCCESS";
export const FETCH_ONGOING_LEASE_ERROR = "FETCH_ONGOING_LEASE_ERROR";
export const SAVE_ONGOING_LEASE_LOADING = "SAVE_ONGOING_LEASE_LOADING";
export const SAVE_ONGOING_LEASE_SUCCESS = "SAVE_ONGOING_LEASE_SUCCESS";
export const SAVE_ONGOING_LEASE_ERROR = "SAVE_ONGOING_LEASE_ERROR";
export const FETCH_LEASE_WITH_QUERY_LOADING = "FETCH_LEASE_WITH_QUERY_LOADING";
export const FETCH_LEASE_WITH_QUERY_SUCCESS = "FETCH_LEASE_WITH_QUERY_SUCCESS";
export const FETCH_LEASE_WITH_QUERY_ERROR = "FETCH_LEASE_WITH_QUERY_ERROR";
export const FETCH_SUBMITTED_LEASE_LOADING = "FETCH_SUBMITTED_LEASE_LOADING";
export const FETCH_SUBMITTED_LEASE_SUCCESS = "FETCH_SUBMITTED_LEASE_SUCCESS";
export const FETCH_SUBMITTED_LEASE_ERROR = "FETCH_SUBMITTED_LEASE_ERROR";
export const SUBMIT_NEGOTIATION_LOADING = "SUBMIT_NEGOTIATION_LOADING";
export const SUBMIT_NEGOTIATION_SUCCESS = "SUBMIT_NEGOTIATION_SUCCESS";
export const SUBMIT_NEGOTIATION_ERROR = "SUBMIT_NEGOTIATION_ERROR";
export const CLEAR_SUBMITTED_NEGOTIATION = "CLEAR_SUBMITTED_NEGOTIATION";
export const SELECTED_PROFILE = "SELECTED_PROFILE";
export const FETCH_LEASE_BOARD_DATA_LOADING = "FETCH_LEASE_BOARD_DATA_LOADING";
export const FETCH_LEASE_BOARD_DATA_SUCCESS = "FETCH_LEASE_BOARD_DATA_SUCCESS";
export const FETCH_LEASE_BOARD_DATA_ERROR = "FETCH_LEASE_BOARD_DATA_ERROR";
export const SELECTED_PAYMENT_METHOD = "SELECTED_PAYMENT_METHOD";
export const OPEN_PAY_WITH_WALLET_MODAL = "OPEN_PAY_WITH_WALLET_MODAL";
export const CLOSE_PAY_WITH_WALLET_MODAL = "CLOSE_PAY_WITH_WALLET_MODAL";
export const OPEN_ACTIVATE_LEASE_BOARD_MODAL =
	"OPEN_ACTIVATE_LEASE_BOARD_MODAL";
export const CLOSE_ACTIVATE_LEASE_BOARD_MODAL =
	"CLOSE_ACTIVATE_LEASE_BOARD_MODAL";
export const FETCH_REGULAR_SAVINGS_LOADING = "FETCH_REGULAR_SAVINGS_LOADING";
export const FETCH_REGULAR_SAVINGS_SUCCESS = "FETCH_REGULAR_SAVINGS_SUCCESS";
export const FETCH_REGULAR_SAVINGS_ERROR = "FETCH_REGULAR_SAVINGS_ERROR";
export const FETCH_ASSET_BASED_SAVINGS_LOADING =
	"FETCH_ASSET_BASED_SAVINGS_LOADING";
export const FETCH_ASSET_BASED_SAVINGS_SUCCESS =
	"FETCH_ASSET_BASED_SAVINGS_SUCCESS";
export const FETCH_ASSET_BASED_SAVINGS_ERROR =
	"FETCH_ASSET_BASED_SAVINGS_ERROR";
export const CREATE_ASSET_BASED_SAVINGS_LOADING =
	"CREATE_ASSET_BASED_SAVINGS_LOADING";
export const CREATE_ASSET_BASED_SAVINGS_SUCCESS =
	"CREATE_ASSET_BASED_SAVINGS_SUCCESS";
export const CREATE_ASSET_BASED_SAVINGS_ERROR =
	"CREATE_ASSET_BASED_SAVINGS_ERROR";
export const IS_CREATE_ASSET_SAVINGS_SUCCESS =
	"IS_CREATE_ASSET_SAVINGS_SUCCESS";
export const REGULAR_SAVINGS_FORM = "REGULAR_SAVINGS_FORM";
export const CLEAR_SAVE_FORM_SUCCESS = "CLEAR_SAVE_FORM_SUCCESS";
export const FETCH_RISK_INVESTMENTS_LOADING = "FETCH_RISK_INVESTMENTS_LOADING";
export const FETCH_RISK_INVESTMENTS_SUCCESS = "FETCH_RISK_INVESTMENTS_SUCCESS";
export const FETCH_RISK_INVESTMENTS_ERROR = "FETCH_RISK_INVESTMENTS_ERROR";
export const INVESTOR_CREATE_ASSET_LOADING = "INVESTOR_CREATE_ASSET_LOADING";
export const INVESTOR_CREATE_ASSET_SUCCESS = "INVESTOR_CREATE_ASSET_SUCCESS";
export const INVESTOR_CREATE_ASSET_ERROR = "INVESTOR_CREATE_ASSET_ERROR";
export const FETCH_CATEGORIES_LOADING = "FETCH_CATEGORIES_LOADING";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";
export const FETCH_BRANDS_LOADING = "FETCH_BRANDS_LOADING";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_ERROR = "FETCH_BRANDS_ERROR";
export const GET_RENTAL_FEE_LOADING = "GET_RENTAL_FEE_LOADING";
export const GET_RENTAL_FEE_SUCCESS = "GET_RENTAL_FEE_SUCCESS";
export const GET_RENTAL_FEE_ERROR = "GET_RENTAL_FEE_ERROR";
export const GET_DELIVERY_FEE_LOADING = "GET_DELIVERY_FEE_LOADING";
export const GET_DELIVERY_FEE_SUCCESS = "GET_DELIVERY_FEE_SUCCESS";
export const GET_DELIVERY_FEE_ERROR = "GET_DELIVERY_FEE_ERROR";
export const GET_CURRENT_CONTEXT_INTEREST = "GET_CURRENT_CONTEXT_INTEREST";
export const CLEAR_REGULAR_SAVINGS = "CLEAR_REGULAR_SAVINGS";
export const CREATE_LEASE_LOADING = "CREATE_LEASE_LOADING";
export const CREATE_LEASE_SUCCESS = "CREATE_LEASE_SUCCESS";
export const CREATE_LEASE_ERROR = "CREATE_LEASE_ERROR";
export const UPDATE_ONGOING_LEASE_LOADING = "UPDATE_ONGOING_LEASE_LOADING";
export const UPDATE_ONGOING_LEASE_SUCCESS = "UPDATE_ONGOING_LEASE_SUCCESS";
export const UPDATE_ONGOING_LEASE_ERROR = "UPDATE_ONGOING_LEASE_ERROR";
export const CLEAR_UPDATE_LEASE_SUCCESS = "CLEAR_UPDATE_LEASE_SUCCESS";
export const OPEN_FILTER_MODAL = "OPEN_FILTER_MODAL";
export const CLOSE_FILTER_MODAL = "CLOSE_FILTER_MODAL";
export const OPEN_DELETE_ONGOING_LEASE_MODAL =
	"OPEN_DELETE_ONGOING_LEASE_MODAL";
export const CLOSE_DELETE_ONGOING_LEASE_MODAL =
	"CLOSE_DELETE_ONGOING_LEASE_MODAL";
export const DELETE_ONGOING_LEASE_LOADING = "DELETE_ONGOING_LEASE_LOADING";
export const DELETE_ONGOING_LEASE_SUCCESS = "DELETE_ONGOING_LEASE_SUCCESS";
export const DELETE_ONGOING_LEASE_ERROR = "DELETE_ONGOING_LEASE_ERROR";
export const OPEN_DELETE_INACTIVE_INVESTMENT_MODAL =
	"OPEN_DELETE_INACTIVE_INVESTMENT_MODAL";
export const CLOSE_DELETE_INACTIVE_INVESTMENT_MODAL =
	"CLOSE_DELETE_INACTIVE_INVESTMENT_MODAL";
export const DELETE_INACTIVE_ASSET_LOADING = "DELETE_INACTIVE_ASSET_LOADING";
export const DELETE_INACTIVE_ASSET_SUCCESS = "DELETE_INACTIVE_ASSET_SUCCESS";
export const DELETE_INACTIVE_ASSET_ERROR = "DELETE_INACTIVE_ASSET_ERROR";
export const CREATE_REGULAR_SAVINGS_LOADING = "CREATE_REGULAR_SAVINGS_LOADING";
export const CREATE_REGULAR_SAVINGS_SUCCESS = "CREATE_REGULAR_SAVINGS_SUCCESS";
export const CREATE_REGULAR_SAVINGS_ERROR = "CREATE_REGULAR_SAVINGS_ERROR";
export const CLEAR_REGULAR_SAVINGS_CREATED_SUCCESS =
	"CLEAR_REGULAR_SAVINGS_CREATED_SUCCESS";
export const SUBMIT_ONGOING_LEASE_SUCCESS = "SUBMIT_ONGOING_LEASE_SUCCESS";
export const CREATE_RISK_INVESTMENTS_LOADING =
	"CREATE_RISK_INVESTMENTS_LOADING";
export const CREATE_RISK_INVESTMENTS_SUCCESS =
	"CREATE_RISK_INVESTMENTS_SUCCESS";
export const CREATE_RISK_INVESTMENTS_ERROR = "CREATE_RISK_INVESTMENTS_ERROR";
export const ACTIVATE_ASSET_LOADING = "ACTIVATE_ASSET_LOADING";
export const ACTIVATE_ASSET_SUCCESS = "ACTIVATE_ASSET_SUCCESS";
export const ACTIVATE_ASSET_ERROR = "ACTIVATE_ASSET_ERROR";
export const FETCH_SINGLE_ASSET_BASED_SAVINGS_LOADING =
	"FETCH_SINGLE_ASSET_BASED_SAVINGS_LOADING";
export const FETCH_SINGLE_ASSET_BASED_SAVINGS_SUCCESS =
	"FETCH_SINGLE_ASSET_BASED_SAVINGS_SUCCESS";
export const FETCH_SINGLE_ASSET_BASED_SAVINGS_ERROR =
	"FETCH_SINGLE_ASSET_BASED_SAVINGS_ERROR";
export const UPDATE_SINGLE_ASSET_BASED_SAVINGS =
	"UPDATE_SINGLE_ASSET_BASED_SAVINGS";
export const CLEAR_UPDATE_ASSET_SAVINGS_SUCCESS =
	"CLEAR_UPDATE_ASSET_SAVINGS_SUCCESS";
export const OPEN_ADD_FUNDS_TO_ASSET_SAVINGS =
	"OPEN_ADD_FUNDS_TO_ASSET_SAVINGS";
export const CLOSE_ADD_FUNDS_TO_ASSET_SAVINGS =
	"CLOSE_ADD_FUNDS_TO_ASSET_SAVINGS";
export const CLEAR_LEASE_STRUCTURE_DATA = "CLEAR_LEASE_STRUCTURE_DATA";
export const CLEAR_ACTIVATE_ASSET_SUCCESS = "CLEAR_ACTIVATE_ASSET_SUCCESS";
export const QUERY_PARTIAL_FUNDED_ASSET_LOADING =
	"QUERY_PARTIAL_FUNDED_ASSET_LOADING";
export const QUERY_PARTIAL_FUNDED_ASSET_SUCCESS =
	"QUERY_PARTIAL_FUNDED_ASSET_SUCCESS";
export const QUERY_PARTIAL_FUNDED_ASSET_ERROR =
	"QUERY_PARTIAL_FUNDED_ASSET_ERROR";
export const OPEN_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL =
	"OPEN_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL";
export const CLOSE_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL =
	"CLOSE_MOVE_FUNDS_FROM_REGULAR_SAVINGS_MODAL";
export const OPEN_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL =
	"OPEN_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL";
export const CLOSE_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL =
	"CLOSE_MOVE_FUNDS_FROM_ASSET_SAVINGS_MODAL";
export const QUERY_PARTIAL_FUNDED_REQUEST_LOADING =
	"QUERY_PARTIAL_FUNDED_REQUEST_LOADING";
export const QUERY_PARTIAL_FUNDED_REQUEST_SUCCESS =
	"QUERY_PARTIAL_FUNDED_REQUEST_SUCCESS";
export const QUERY_PARTIAL_FUNDED_REQUEST_ERROR =
	"QUERY_PARTIAL_FUNDED_REQUEST_ERROR";
export const INVESTOR_UPDATE_REQUEST_STATUS = "INVESTOR_UPDATE_REQUEST_STATUS";
export const TOGGLE_PAY_DELIVERY_FEE_MODAL = "TOGGLE_PAY_DELIVERY_FEE_MODAL";
export const TOGGLE_APPLY_INSURANCE_MODAL = "TOGGLE_APPLY_INSURANCE_MODAL";
export const TOGGLE_REGISTER_ASSET_MODAL = "TOGGLE_REGISTER_ASSET_MODAL";
export const REGISTER_ASSET_LOADING = "REGISTER_ASSET_LOADING";
export const REGISTER_ASSET_SUCCESS = "REGISTER_ASSET_SUCCESS";
export const REGISTER_ASSET_ERROR = "REGISTER_ASSET_ERROR";
export const ACTIVATE_INSURANCE_LOADING = "ACTIVATE_INSURANCE_LOADING";
export const ACTIVATE_INSURANCE_SUCCESS = "ACTIVATE_INSURANCE_SUCCESS";
export const ACTIVATE_INSURANCE_ERROR = "ACTIVATE_INSURANCE_ERROR";
export const CREDIT_ASSESSMENT_LOADING = "CREDIT_ASSESSMENT_LOADING";
export const CREDIT_ASSESSMENT_SUCCESS = "CREDIT_ASSESSMENT_SUCCESS";
export const CREDIT_ASSESSMENT_ERROR = "CREDIT_ASSESSMENT_ERROR";
export const CLEAR_CREDIT_ASSESSMENT_SUCCESS =
	"CLEAR_CREDIT_ASSESSMENT_SUCCESS";
