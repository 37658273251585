import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { onToggleRegisterAssetModal } from "../../redux/lease/leaseActions";
import { InputField } from "./InsuranceApplication";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import FileUpload from "../../components/FileUpload";
import {
	formatAmountToIncludeCurrency,
	getLeaseId,
	walletType,
} from "../../common/utils";
import PaymentOption from "../../components/payments/PaymentOption";
import {
	CustomConfirmModal,
	CustomHeaderWithCloseBtn,
} from "../../layout/CustomComponents";
import Container from "../../layout/Container";
import { EnterPin } from "../../components/payments/PinComponent";
import useParameters from "../../components/customhooks/useParameters";
import { toast } from "react-toastify";
import {
	getCompanyInsuranceInfo,
	investorRegisterAsset,
	updateAssetOnRegistration,
} from "../../redux/lease/leaseServices";
import { Preloader } from "../../components/globalStyled";

const RegisterAsset = ({ requestData }) => {
	const data = useParameters();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { state } = useLocation();

	const { currentUser } = useSelector((state) => state.auth);
	const { customerWallet } = useSelector((state) => state.payment);
	const { assetRegLoading } = useSelector((state) => state.lease);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const [activeTab, setActiveTab] = useState(1);
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");
	const [info, setInfo] = useState({
		companyInfo: null,
		loading: false,
	});

	const isSingleInvestor = requestData?.investors?.length === 1;

	const accessPin = currentUser?.accessPin;

	const assetRegInfo = requestData?.assetRegInfo?.vehicleInfo;

	const vehicleFee = data?.find((p) =>
		p?.parameter?.includes("Private vehicle registration")
	);

	const formattedRegFee = Number(vehicleFee?.value?.replaceAll(",", ""));

	const investorEmail =
		requestData?.investors?.length > 1
			? info?.companyInfo?.email
			: currentUser?.email;

	const investorName =
		requestData?.investors?.length > 1
			? `${info?.companyInfo?.firstName}
			${info?.companyInfo?.lastName}`
			: `${currentUser?.firstName} ${currentUser?.lastName}`;

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	const getTotalAmount = () => {
		if (requestData?.investors?.length > 1) {
			return 1 * requestData?.investors?.length;
		} else {
			return 1;
		}
	};

	const leaseId = getLeaseId(requestData);

	const totalAmount =
		process.env.NODE_ENV !== "development" ? formattedRegFee : getTotalAmount();

	// console.log(formattedRegFee, getTotalAmount());

	const amountToPay = formattedRegFee / requestData?.investors?.length;

	const registrationFee =
		process.env.NODE_ENV !== "development" ? amountToPay : 1;

	const dataToUpdate = {
		totalAmount,
		amountToPay: registrationFee,
		leaseId,
		vehicleRegDetails: {
			...assetRegInfo,
			investorName,
			investorEmail,
		},
	};

	const assetDataToUpdate = {
		assetRegistration: [
			...(requestData?.assetRegistration || []),
			{
				name: `${currentUser?.firstName} ${currentUser?.lastName}`,
				userId: currentUser?.objectId,
				amountPaid: registrationFee,
				totalAmount,
				status: "paid",
				dateOfPayment: new Date().toISOString(),
			},
		],
		vehicleRegDetails: { ...dataToUpdate?.vehicleRegDetails },
	};

	const onProceedToPayment = () => {
		if (!vehicleFee) {
			toast.error(
				"Registration fee is not available yet. Please check back later."
			);
			return;
		} else if (mainWallet?.availableBalance < registrationFee) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		} else {
			setActiveTab(3);
		}
	};

	const onRegisterAsset = () => {
		dispatch(investorRegisterAsset(dataToUpdate));
		if (state?.isLeaseReq === undefined) {
			dispatch(
				updateAssetOnRegistration(requestData?.objectId, assetDataToUpdate)
			);
		}
	};

	// FETCH COMPANY DETAILS ON MULTIPLE INVESTORS
	const fetchCompanyInfo = async () => {
		setInfo((prev) => ({ ...prev, loading: true }));
		try {
			const response = await getCompanyInsuranceInfo();

			if (response) {
				setInfo((prev) => ({
					...prev,
					companyInfo: response?.companyInsuranceInfo,
				}));
			} else {
				toast.error("Insurance information is not available.");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setInfo((prev) => ({ ...prev, loading: false }));
		}
	};

	useEffect(() => {
		if (isSingleInvestor) {
			return;
		} else {
			fetchCompanyInfo();
		}
	}, []);

	return (
		<>
			{info?.loading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}
			<Container>
				{activeTab === 1 && (
					<div className="rounded-lg py-4 md:py-5 bg-[#F6F7F8] w-full sm:w-11/12 md:10/12 max-w-[450px] sm:max-[w-600px] md:max-w-[700px] mx-auto">
						<CustomHeaderWithCloseBtn
							text="vehicle registration form"
							onClick={() => dispatch(onToggleRegisterAssetModal(false))}
							isPadding
						/>
						<p className="text-[12.75px] text-[#393F42] px-4 md:px-5">
							Submit the form below to register this asset.
						</p>
						<div className="grid sm:grid-cols-2 gap-3 sm:gap-x-4 md:gap-x-5 lg:gap-x-6 mt-5 sm:gap-y-4 lg:gap-y-5 max-h-[400px] sm:max-h-[450px] overflow-auto scroll-wrapper px-4 md:px-5">
							<InputField
								label="vehicleBrand"
								labelText="Vehicle Brand"
								value={assetRegInfo?.vehicleBrand}
								isDisabled
							/>
							<InputField
								label="vehicleName"
								labelText="Vehicle Name"
								value={assetRegInfo?.vehicleName}
								isDisabled
							/>
							<InputField
								label="engineNum"
								labelText="Engine No."
								value={assetRegInfo?.engineNum}
								isDisabled
							/>
							<InputField
								label="chassisNum"
								labelText="Chassis No."
								value={assetRegInfo?.chassisNum}
								isDisabled
							/>
							<InputField
								label="investorEmail"
								labelText="Investor's Email"
								value={investorEmail}
								isDisabled
							/>
							<div className="flex flex-col gap-2 text-[#393F42] zabalMedFont">
								<label
									htmlFor="assetInvoice"
									className="text-xs md:text-[12.5px]"
								>
									Vehicle Invoice
								</label>
								<FileUpload
									fileInfo={assetRegInfo?.assetInvoice}
									name="assetInvoice"
									isDisabled
								/>
							</div>
						</div>
						<div className="px-4 md:px-5 my-3 sm:hidden">
							<CustomGreenBtn text="proceed" />
						</div>
						<div className="px-4 md:px-5 mb-3 mt-5 hidden sm:flex justify-end">
							<CustomGreenBtn
								text="proceed"
								width="115px"
								height="40px"
								onRequestClick={() => setActiveTab(2)}
							/>
						</div>
					</div>
				)}
				{activeTab === 2 && (
					<div className="rounded-lg p-4 md:p-5 bg-[#F6F7F8] w-11/12 max-w-[450px] mx-auto">
						<div className="flex items-center justify-between text-fortcore-green mb-1">
							<h3 className="text-md md:text-[18px] font-semibold chillaxFont">
								One Final Step
							</h3>
							<button onClick={() => setActiveTab(1)}>
								<MdClose size={20} />
							</button>
						</div>
						<p className="text-[12.75px] text-[#393F42]">
							You are required to pay for your vehicle registration.
						</p>
						<div className="mt-4 space-y-2">
							<div className="flex justify-between text-[13px]">
								<p className="zabalMedFont">Asset Value:</p>
								<p className="zabalRegFont">
									{formatAmountToIncludeCurrency(
										"NGN",
										requestData?.productObj?.amount
									)}
								</p>
							</div>
							<div className="flex justify-between text-[13px]">
								<p className="zabalMedFont">Registration Fee:</p>
								<p className="zabalRegFont">
									{" "}
									{formatAmountToIncludeCurrency("NGN", registrationFee)}
								</p>
							</div>
						</div>
						<div className="mt-10 flex justify-between items-center">
							<p className="text-[12.5px] text-[#717E83]">
								Proceed to make payment
							</p>
							<CustomGreenBtn
								text="pay"
								width="80px"
								height="39px"
								onRequestClick={onProceedToPayment}
							/>
						</div>
					</div>
				)}
				{activeTab === 3 && (
					<PaymentOption
						onClose={() => setActiveTab(2)}
						onWalletSelect={() => setActiveTab(4)}
						onCardSelect={() => {}}
					/>
				)}
				{activeTab === 4 && (
					<CustomConfirmModal
						text={`The amount of N${registrationFee} will be deducted from your wallet for insurance application.`}
						greenBtnText="proceed"
						onCloseBtnClick={() => dispatch(onToggleRegisterAssetModal(false))}
						greenBtnClick={() => setActiveTab(5)}
					/>
				)}
				{activeTab === 5 && (
					<EnterPin
						btnText="pay now"
						onCloseBtnClick={() => dispatch(onToggleRegisterAssetModal(false))}
						onInputChange={setInputPin}
						isPinInvalid={invalidPinError}
						isPinExist={accessPin !== undefined}
						isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
						proceedToCreatePin={() =>
							navigate("/dashboard/profile", { state: { currentTab: 2 } })
						}
						onRequestClick={onRegisterAsset}
						isPaymentLoading={assetRegLoading}
					/>
				)}
			</Container>
		</>
	);
};

RegisterAsset.propTypes = {
	requestData: PropTypes.object,
};

export default RegisterAsset;
