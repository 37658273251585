import PropTypes from "prop-types";
import React, { useState } from "react";
import Parse from "../../../../api/ApiConfig";
import { toast } from "react-toastify";
import { Box, Button, Stack, Typography } from "@mui/material";
import theme from "../../../../components/mui/theme";
import { Preloader } from "../../../../components/globalStyled";
import { SuccessCheckIcon } from "../../../../layout/assets";
import PinInput from "react-pin-input";

export function CreateAccessPin({ action }) {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [pin, setPin] = useState("");

	const handleChange = (value) => {
		setPin(value);
	};

	const handleComplete = async () => {
		const currentUser = Parse.User.current();

		if (currentUser) {
			setLoading(true);
			try {
				currentUser.set("accessPin", pin);
				await currentUser.save();
				toast.success("Pin created successfully");
				setSuccess(true);
				setLoading(false);
			} catch (error) {
				toast.error(error.message);
				setLoading(false);
			}
		} else {
			toast.error("Session expired");
		}
	};

	return (
		<>
			{!success ? (
				<Box
					sx={{
						pl: { xs: 5, sm: 5 },
						pr: { xs: 5, sm: 5 },
					}}>
					<Typography variant="h5">Enter New PIN</Typography>
					<PinInput
						length={4}
						initialValue=""
						secret
						secretDelay={100}
						onChange={handleChange}
						// onComplete={handleComplete}
						type="numeric"
						inputMode="number"
						style={{ padding: "10px" }}
						inputStyle={{
							borderColor: theme.palette.primary.main,
							borderRadius: "8px",
						}}
						inputFocusStyle={{
							borderColor: theme.palette.primary.main,
						}}
						autoSelect={true}
						regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
						value={pin}
					/>

					<Stack spacing={3} mt={5}>
						{!loading ? (
							<Button
								fullWidth
								variant="contained"
								color="primary"
								disabled={pin.length < 4}
								onClick={handleComplete}>
								Proceed
							</Button>
						) : (
							<Button fullWidth variant="contained" color="primary">
								<Preloader />
							</Button>
						)}
					</Stack>
				</Box>
			) : (
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={3}>
					<SuccessCheckIcon />
					<Stack spacing={1}>
						<Typography variant="h3" color="primary" align="center">
							New Access Pin
						</Typography>
						<Typography variant="body1" color="primary" align="center">
							Successfully Set
						</Typography>
					</Stack>
					<Button variant="contained" fullWidth onClick={action}>
						Done
					</Button>
				</Stack>
			)}
		</>
	);
}

CreateAccessPin.propTypes = {
	action: PropTypes.func,
};
