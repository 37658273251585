import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../layout/Container";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import Navbar from "../../layout/Navbar";
import { UrlTracker } from "../../layout/CustomComponents";
import { LeaseSummaryItemDetails } from "./requestlease/LeaseSummary";
import { TrackerRadio, TrackerText } from "./submittedtransactions";
import ViewLeaseeRequests from "./ViewLeaseeRequests";
import ModalContainer, {
	DesktopSideModal,
} from "../../components/ModalContainer";
import ViewInvestorsDetails from "./ViewInvestorsDetails";
import { onInvestorUpdatesRequestStatus } from "../../redux/lease/leaseServices";
import Parse from "../../api/ApiConfig";
import {
	investorUpdateRequestStatus,
	onToggleApplyInsuranceModal,
	onToggleRegisterAssetModal,
} from "../../redux/lease/leaseActions";
import InsuranceApplication from "./InsuranceApplication";
import RegisterAsset from "./RegisterAsset";
import { networkErrorTrue } from "../../redux/authRedux/authActions";
import NetworkError from "../../components/networkerror/NetworkError";
import { Preloader } from "../../components/globalStyled";
import { NoRequestFound } from "./submittedtransactions/LeaseAgreement";
import { getInsurancePaymentStatus } from "../../common/utils";

const ActiveInvestment = () => {
	const navigate = useNavigate();
	const params = useParams();
	const user = Parse.User.current();
	const dispatch = useDispatch();
	const { state } = useLocation();

	const [isLoading, setIsLoading] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [error, setError] = useState(null);

	const [activeInsurancTab, setActiveInsuranceTab] = useState(1);

	const { networkError } = useSelector((state) => state.auth);

	const { toggleApplyForInsuranceModal, toggleRegisterAssetModal } =
		useSelector((state) => state.lease);

	const fetchRequestWithQuery = async () => {
		try {
			setIsLoading(true);
			const classToQuery = state?.isLeaseReq ? "Lease" : "Asset";

			const query = new Parse.Query(classToQuery);
			query.equalTo("objectId", params.id);
			const response = await query.first();
			const data = response?.toJSON();

			setSelectedData(data);
		} catch (error) {
			if (error?.message === "Network Error") {
				dispatch(networkErrorTrue("Network Error"));
			}
			console.log(error);
			if (error.message.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
			} else if (error?.message?.includes("Invalid session token")) {
				toast.error("This session has expired. Please login.");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			} else {
				setError(error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const [toggleViewRequests, setToggleViewRequests] = useState(false);
	const [toggleViewInvestors, setToggleViewInvestors] = useState(false);
	const [loading, setLoading] = useState(
		selectedData?.usersRequest
			? Object.assign(
					{},
					...selectedData.usersRequest.map((request) => ({
						[request?.transactionId]: false,
					}))
			  )
			: false
	);

	const currentInvestor = selectedData?.investorsArray?.find(
		(investor) => investor?.user === user?.id
	);

	const onApproveSingleRequest = async (id) => {
		const updatedUsersRequest = selectedData?.usersRequest.map((a) => {
			if (a.transactionId === id) {
				return {
					...a,
					status: "approved",
					investor: [
						...(a?.investor || []),
						{ user: currentInvestor?.user, status: "approved" },
					],
				};
			} else {
				return {
					...a,
					status: a?.status === "approved" ? "approved" : "rejected",
					investor: [
						...(a?.investor || []),
						{ user: currentInvestor?.user, status: "rejected" },
					],
				};
			}
		});
		const updatedInvestorArr = selectedData?.investorsArray.map((a) => {
			if (a?.user === currentInvestor?.user) {
				return { ...a, approvalStatus: "approved" };
			} else {
				return a;
			}
		});

		const data = {
			investorsArray: updatedInvestorArr,
			usersRequest: updatedUsersRequest,
		};

		const dataOnLease = selectedData?.usersRequest?.map((a) => ({
			objectId: a?.transactionId,
			...data,
		}));

		try {
			setLoading((prev) => ({ ...prev, [id]: true }));

			const response = await onInvestorUpdatesRequestStatus(
				selectedData?.objectId,
				id,
				data,
				dataOnLease
			);

			dispatch(investorUpdateRequestStatus(response?.asset));
			toast.success("You have successfully updated this request");

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			toast.error(error);
		} finally {
			setTimeout(() => {
				setLoading((prev) => ({ ...prev, [id]: false }));
			}, 2000);
		}
	};

	const investorPaidAssetRegfee = selectedData?.assetRegistration?.find(
		(a) => a?.userId === user?.id
	);

	const investorPaidInsurancefee =
		selectedData?.insurancePaymentInfo?.investors?.find(
			(a) => a?.userId === user?.id
		);

	const insuranceStatus = getInsurancePaymentStatus(selectedData);

	const onBeginPaymentForInsurance = () => {
		dispatch(onToggleApplyInsuranceModal(true));
		// setActiveInsuranceTab(2);
		setActiveInsuranceTab(1);
	};

	const onActivateInsurance = () => {
		dispatch(onToggleApplyInsuranceModal(true));
		setActiveInsuranceTab(1);
	};

	// console.log(selectedData?.paidSlots);

	const status = {
		funded: selectedData?.paidSlots === 4,
		usersRequest: selectedData?.usersRequest?.length > 0,
		investorsArray: selectedData?.investorsArray,
		isCreatedAsset: selectedData?.usersRequest?.length > 0,
		approvalStatus: currentInvestor?.approvalStatus,
		leaseAgreement: selectedData?.leaseAgreement,
		firstPayment: selectedData?.firstPayment?.paid,
		vehicleCategory:
			selectedData?.productObj?.category || selectedData?.productObj?.category,
		logisticStatus: selectedData?.logisticStatus,
		isRegAssetReady: selectedData?.assetRegInfo?.vehicleInfo,
		hasInvestorPaidRegFee: investorPaidAssetRegfee,
		registrationStatus: selectedData?.vehicleRegDetails?.registrationStatus,
		registrationNum: selectedData?.vehicleRegDetails?.registrationNum,
		hasInvestorPaidInsuranceFee: investorPaidInsurancefee,
		hasInvestorCompleteInsurance:
			selectedData?.insuranceDetails?.status === "insured",
	};

	if (!state) {
		return <Navigate to="/leasing/investment-board" />;
	}

	useEffect(() => {
		fetchRequestWithQuery();
	}, [params.id]);

	return (
		<>
			{networkError && <NetworkError />}
			{isLoading && (
				<div className="fixed inset-0 z-[10] h-full grid place-items-center w-full overflow-hidden">
					<Preloader />
				</div>
			)}
			{toggleViewRequests && (
				<ModalContainer modal={toggleViewRequests}>
					<ViewLeaseeRequests
						onClose={() => setToggleViewRequests(false)}
						selectedRequest={selectedData}
						loading={loading}
						onApproveSingleRequest={onApproveSingleRequest}
					/>
				</ModalContainer>
			)}
			{toggleViewInvestors && (
				<ModalContainer modal={toggleViewInvestors}>
					<ViewInvestorsDetails
						onClose={() => setToggleViewInvestors(false)}
						investorsArray={selectedData?.investorsArray}
					/>
				</ModalContainer>
			)}
			{toggleApplyForInsuranceModal && (
				<DesktopSideModal modal={toggleApplyForInsuranceModal}>
					<InsuranceApplication
						requestData={selectedData}
						activeTab={activeInsurancTab}
						setActiveTab={setActiveInsuranceTab}
					/>
				</DesktopSideModal>
			)}
			{toggleRegisterAssetModal && (
				<DesktopSideModal modal={toggleRegisterAssetModal}>
					<RegisterAsset requestData={selectedData} />
				</DesktopSideModal>
			)}
			<MobileNavDetails text="active investment" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Investments</title>
					</Helmet>
					<Navbar text="active investment" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() =>
								navigate("/leasing", { state: { currentTab: 2 } })
							}
						/>
						<UrlTracker
							text="lease investment board"
							onNavigate={() => navigate("/leasing/investment-board")}
						/>
						<UrlTracker
							text={`active investment - ${params.id}`}
							isCurrentUrl
						/>
					</div>
					<div className="mt-1 sm:mt-[30px]"></div>
					{error ? (
						<NoRequestFound />
					) : (
						<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8 mb-10 sm:mb-14">
							<div className="flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold pb-1.5 border-b border-[#E3E9EC]">
								<button className="zabalMedFont" onClick={() => navigate(-1)}>
									<FaArrowLeft />
								</button>
								<p>Transaction Details</p>
							</div>
							<LeaseSummaryItemDetails
								item={selectedData?.productObj}
								interestAmount={currentInvestor?.interestToEarn}
								availableSlots={4 - selectedData?.paidSlots || 0}
								amountInvested={currentInvestor?.amountToInvest}
							/>
							<div className="mt-6 rounded-md pl-3 py-5 pr-4 md:p-6 bg-[#fefefe] border space-y-4">
								<FirstStatusTracker
									status={status}
									onOpenViewRequest={() => setToggleViewRequests(true)}
									onToggleInvestorInfo={() => setToggleViewInvestors(true)}
									onOpenInsuranceApplication={onBeginPaymentForInsurance}
									onActivateInsurance={onActivateInsurance}
									onRegisterAsset={() =>
										dispatch(onToggleRegisterAssetModal(true))
									}
									insuranceStatus={insuranceStatus}
								/>
							</div>
						</div>
					)}
				</>
			</Container>
		</>
	);
};

export default ActiveInvestment;

const FirstStatusTracker = ({
	status,
	onOpenViewRequest,
	onToggleInvestorInfo,
	onOpenInsuranceApplication,
	onRegisterAsset,
	insuranceStatus,
	onActivateInsurance,
}) => {
	const showAssetRegPending = () => {
		if (status?.isRegAssetReady && !status?.hasInvestorPaidRegFee) {
			return true;
		} else {
			return false;
		}
	};

	const isRegAssetBtnDisabled = () => {
		if (!status?.isRegAssetReady) {
			return true;
		} else if (status?.isRegAssetReady && status?.hasInvestorPaidRegFee) {
			return true;
		} else {
			return false;
		}
	};

	const isInsurancePayNotAvailable = () => {
		if (!status?.registrationNum && !status?.hasInvestorPaidInsuranceFee) {
			return true;
		} else if (status?.registrationNum && status?.hasInvestorPaidInsuranceFee) {
			return true;
		} else if (
			status?.registrationNum &&
			!status?.hasInvestorPaidInsuranceFee
		) {
			return false;
		} else {
			return true;
		}
	};

	const showPayInsurancePending = () => {
		if (status?.registrationNum && !status?.hasInvestorPaidInsuranceFee) {
			return;
		} else {
			return false;
		}
	};

	const canInvestorApplyInsurance = () => {
		if (
			status?.registrationNum &&
			insuranceStatus?.isPaymentComplete &&
			!status?.hasInvestorCompleteInsurance
		) {
			return false;
		}
		if (status?.hasInvestorCompleteInsurance) {
			return true;
		} else {
			return true;
		}
	};

	const showApplyInsPending = () => {
		if (
			status?.registrationNum &&
			insuranceStatus?.isPaymentComplete &&
			!status?.hasInvestorCompleteInsurance
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className="relative">
			<div className="mt-2.5 md:mt-3 absolute">
				<TrackerRadio currentStatus={status.investorsArray?.length > 0} />
				<TrackerRadio currentStatus={status.funded} />
				{status?.isCreatedAsset && (
					<>
						<TrackerRadio currentStatus={status.usersRequest} />
						<TrackerRadio
							currentStatus={
								status?.approvalStatus && status?.approvalStatus !== "pending"
							}
							statusRejected={
								status?.approvalStatus && status?.approvalStatus === "rejected"
							}
						/>
					</>
				)}
				<TrackerRadio currentStatus={!!status?.leaseAgreement} />
				<TrackerRadio
					currentStatus={!!status?.firstPayment}
					isEnd={status?.vehicleCategory !== "Vehicles"}
				/>
				{status?.vehicleCategory === "Vehicles" && (
					<>
						<TrackerRadio currentStatus={!!status?.hasInvestorPaidRegFee} />
						<TrackerRadio
							currentStatus={status?.registrationStatus === "Registered"}
						/>
						<TrackerRadio
							currentStatus={!!status?.hasInvestorPaidInsuranceFee}
						/>
						<TrackerRadio
							isEnd
							currentStatus={status?.hasInvestorCompleteInsurance}
						/>
					</>
				)}
			</div>
			<div className="flex justify-end">
				<div className="grid gap-3 md:gap-4 w-[calc(100%-32px)] md:w-[calc(100%-37px)] lg:w-[calc(100%-42px)]">
					<TrackerText
						text="Investors' Details"
						currentStatus={status?.investorsArray?.length > 0}
						showBtns
						btnText="View Info"
						onNavigate={onToggleInvestorInfo}
					/>
					<TrackerText text="Fully Funded" currentStatus={status?.funded} />
					{status?.isCreatedAsset && (
						<>
							<TrackerText
								currentStatus={status?.usersRequest}
								text="Requests Submitted"
								showPending={!status?.approvalStatus}
								showBtns
								btnText={status?.usersRequest ? "View Requests" : "No requests"}
								isBtnDisabled={status.usersRequest < 1}
								onNavigate={onOpenViewRequest}
							/>
							<TrackerText
								text="Request Approval Status"
								currentStatus={
									status?.approvalStatus && status?.approvalStatus !== "pending"
								}
								statusRejected={
									status?.approvalStatus &&
									status?.approvalStatus === "rejected"
								}
							/>
						</>
					)}
					<TrackerText
						text="Lease Agreement Signed"
						currentStatus={!!status?.leaseAgreement}
					/>
					<TrackerText
						text="Made First Payment"
						currentStatus={!!status?.firstPayment}
					/>
					{status?.vehicleCategory === "Vehicles" && (
						<>
							<TrackerText
								text={`Vehicle Registration ${
									status?.hasInvestorPaidRegFee ? "( paid )" : ""
								}`}
								currentStatus={!!status?.hasInvestorPaidRegFee}
								showBtns={true}
								showPending={showAssetRegPending()}
								btnText="Register Asset"
								onNavigate={onRegisterAsset}
								isBtnDisabled={isRegAssetBtnDisabled()}
							/>
							<TrackerText
								text={`Registration Status ( ${
									status?.registrationStatus
										? status?.registrationStatus
										: "Pending"
								} )`}
								currentStatus={status?.registrationStatus === "Registered"}
							/>
							<TrackerText
								text="Payment for insurance"
								currentStatus={!!status?.hasInvestorPaidInsuranceFee}
								showPending={showPayInsurancePending()}
								showBtns={true}
								isBtnDisabled={isInsurancePayNotAvailable()}
								btnText="Pay Now"
								onNavigate={onOpenInsuranceApplication}
							/>
							<TrackerText
								text={`Insurance Status ${
									status?.hasInvestorCompleteInsurance
										? "( Insured )"
										: "( Pending )"
								}`}
								currentStatus={status?.hasInvestorCompleteInsurance}
								showPending={showApplyInsPending()}
								// showBtns={true}
								// btnText="Apply for Insurance"
								isBtnDisabled={canInvestorApplyInsurance()}
								onNavigate={onActivateInsurance}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

FirstStatusTracker.propTypes = {
	status: PropTypes.object,
	onOpenViewRequest: PropTypes.func,
	onToggleInvestorInfo: PropTypes.func,
	onOpenInsuranceApplication: PropTypes.func,
	onRegisterAsset: PropTypes.func,
	onActivateInsurance: PropTypes.func,
	insuranceStatus: PropTypes.object,
};
