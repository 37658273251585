import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { toast } from "react-toastify";
import {
	formatPhoneNumber,
	futureDate,
	shortenLongText,
} from "../../../common/utils";
import {
	addBankAccount,
	disablePhoneButton,
	getPhoneOTP,
	updateUserAndPersonalData,
	verifyPhoneOTP,
} from "../../../redux/authRedux/authServices";
import {
	addBankAccToPage,
	closeAddBankAccountModal,
	closeAddBvnModal,
	openAddBankAccountModal,
	openAddBvnModal,
} from "../../../redux/authRedux/authActions";
import ModalContainer from "../../../components/ModalContainer";
import {
	PlainSelect,
	SelectDropDown,
	SuccessCheckModal,
} from "../../../layout/CustomComponents";
import AddBvnModal from "../../../components/AddBvnModal";
import AddBankAccountModal from "../../../components/AddBankAccountModal";
import { PersonalFormCardToggler } from "./CompletePersonalForm";
import { IsValidIcon } from "../../../layout/assets";
import { Preloader } from "../../../components/globalStyled";
import CountDown from "../../../components/Countdown";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import { useUploadFile } from "../../../components/customhooks/useUploadFile";
import Parse from "../../../api/ApiConfig";
import { ProfileInput } from "./PersonalInfo";
import { Link } from "react-router-dom";

const GeneralInformation = () => {
	const dispatch = useDispatch();

	const {
		currentUser,
		toggleAddBankAccountModal,
		toggleAddBvnModal,
		bankToPage,
		phoneOtpMessageSuccess,
		phoneOtpMessageLoading,
		verifyPhoneOtpLoading,
		verifyPhoneOtpSuccess,
		isShowPhoneTimer,
		updatingUserAccountLoading,
		verifyBvnSuccess,
		bankAccountSuccess,
		bvnSuccessMsg,
	} = useSelector((state) => state.auth);

	const { savingPersonalFormSuccess } = useSelector((state) => state.lease);

	const [toggleGeneralInfo, setToggleGeneralInfo] = useState(false);
	const [toggleIdType, setToggleIdType] = useState(false);

	const [generalProfile, setGeneralProfile] = useState({
		bvn: verifyBvnSuccess?.bvn || currentUser?.bvn || "",
		bankName: bankToPage?.bankName || currentUser?.bankName || "",
		accountNumber: bankToPage?.accountNo || currentUser?.accountNumber || "",
		accountName: bankToPage?.accountName || currentUser?.accountName || "",
		phone_number: currentUser?.phone || "",
		phone_code: "",
		id_number: currentUser?.idNo || "",
		id_type: currentUser?.idType || "",
		expiry_date: currentUser?.idExpiryDate
			? moment(currentUser?.idExpiryDate?.iso).format("YYYY-MM-DD")
			: "",
		home_address: currentUser?.homeAddress || "",
		bank_account: bankToPage,
		idCardInput: null,
		utilityBillInput: null,
		idCard: currentUser?.idCard || "",
		utilityBill: currentUser?.utilityBill || "",
	});

	const {
		bvn,
		bankName,
		accountNumber,
		accountName,
		phone_number,
		phone_code,
		id_number,
		id_type,
		expiry_date,
		home_address,
		bank_account,
		idCard,
		utilityBill,
		idCardInput,
		utilityBillInput,
	} = generalProfile;

	const {
		loading: idCardUploadLoading,
		fileUploadSuccess: idCardUploadSuccess,
	} = useUploadFile(idCardInput);

	const {
		loading: utilityBillUploadLoading,
		fileUploadSuccess: utilityBillUploadSuccess,
	} = useUploadFile(utilityBillInput);

	// upload id card
	useEffect(() => {
		if (idCardUploadSuccess) {
			setGeneralProfile((prev) => ({
				...prev,
				idCardInput: null,
				idCard: idCardUploadSuccess,
			}));
		}
	}, [idCardUploadSuccess]);

	// upload utility bill
	useEffect(() => {
		if (utilityBillUploadSuccess) {
			setGeneralProfile((prev) => ({
				...prev,
				utilityBillInput: null,
				utilityBill: utilityBillUploadSuccess,
			}));
		}
	}, [utilityBillUploadSuccess]);

	const checkPhone = () => {
		if (!currentUser?.phoneVerified) {
			return true;
		}
		if (
			formatPhoneNumber(currentUser?.phone) !== formatPhoneNumber(phone_number)
		) {
			return true;
		} else {
			return false;
		}
	};

	const onGetPhoneOtp = () => {
		if (!phone_number.startsWith("234")) {
			toast.error("Please include country code");
		} else if (phone_number.length < 12) {
			toast.error("Please include country code");
		} else {
			const phone = formatPhoneNumber(phone_number);
			dispatch(getPhoneOTP(phone));
		}
	};

	// disable resend phone otp while counter
	useEffect(() => {
		if (isShowPhoneTimer) {
			dispatch(disablePhoneButton(1000 * 115));
		}
	}, [isShowPhoneTimer]);

	//verify phoneotp code
	useEffect(() => {
		if (verifyPhoneOtpSuccess) {
			setGeneralProfile((prev) => ({ ...prev, phone_code: "" }));
			return;
		}
		if (phone_code.length < 6) {
			return;
		} else if (isNaN(phone_code)) {
			return;
		} else {
			dispatch(verifyPhoneOTP(phone_number, phone_code));
		}
	}, [phone_code]);

	const isGenProfileBtnInvalid = () => {
		if (
			bvn === "" ||
			bankName === "" ||
			accountNumber === "" ||
			phone_number === "" ||
			id_type === "" ||
			id_number === "" ||
			idCard === "" ||
			utilityBill === "" ||
			checkPhone()
		) {
			return true;
		}
		if (id_type === "International Passport" && expiry_date === "") {
			return true;
		}
		if (id_type === "Driver's Licence" && expiry_date === "") {
			return true;
		} else {
			return false;
		}
	};

	const isBvnAddSuccess = () => {
		dispatch(closeAddBvnModal());
		setGeneralProfile((prev) => ({ ...prev, bvn: verifyBvnSuccess?.bvn }));
	};
	const isBankAccountAddSuccess = () => {
		dispatch(closeAddBankAccountModal());
		dispatch(addBankAccToPage(bankAccountSuccess));
		setGeneralProfile((prev) => ({
			...prev,
			bank_account: bankAccountSuccess,
			bankName: bankAccountSuccess?.bankName,
			accountNumber: bankAccountSuccess?.accountNo,
			accountName: bankAccountSuccess?.accountName,
		}));
	};

	const bank_info =
		savingPersonalFormSuccess && savingPersonalFormSuccess?.get("bankAccounts")
			? savingPersonalFormSuccess?.get("bankAccounts")
			: [];

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const a = bank_info?.find((b) => b?.accountNo === bank_account?.accountNo);
	const date = expiry_date || futureDate;
	const dateObj = new Date(date);

	const dataForUserProfile = {
		gender:
			verifyBvnSuccess?.gender || savingPersonalFormSuccess?.get("gender"),
		DOB:
			moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY") ||
			moment(currentUser?.dateOfBirth).format("DD-MM-YYYY") ||
			moment(currentUser?.DOB).format("DD-MM-YYYY"),
		dateOfBirth:
			moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY") ||
			moment(currentUser?.dateOfBirth).format("DD-MM-YYYY") ||
			moment(currentUser?.DOB).format("DD-MM-YYYY"),
		phone: phone_number,
		bankName: bankName,
		accountName: accountName,
		accountNumber: accountNumber,
		homeAddress: home_address,
		idType: id_type,
		idNo: id_number,
		idExpiryDate: {
			__type: "Date",
			iso: dateObj?.toISOString(),
		},
		bvn,
		idCard,
		utilityBill,
	};

	const dataForPersonalProfile = {
		gender:
			verifyBvnSuccess?.gender || savingPersonalFormSuccess?.get("gender"),
		bankAccounts: a
			? bank_info
			: bank_account
			? [...bank_info, bank_account]
			: bank_info,
		userId: user,
		user: user,
		phone: phone_number,
		homeAddress: home_address,
		DOB:
			moment(verifyBvnSuccess?.dob).format("DD-MM-YYYY") ||
			moment(currentUser?.dateOfBirth).format("DD-MM-YYYY") ||
			moment(currentUser?.DOB).format("DD-MM-YYYY"),
		fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
		BVN: bvn,
		idType: id_type,
		idNumber: id_number,
		idExpiryDate: moment(dateObj).format("YYYY-MM-DD"),
	};

	const onSubmitGeneralInfo = () => {
		dispatch(
			updateUserAndPersonalData(dataForPersonalProfile, dataForUserProfile)
		);
	};

	const addBankAccountDetails = (data) => {
		dispatch(addBankAccount(data));
	};

	return (
		<>
			{toggleAddBvnModal && (
				<ModalContainer modal={toggleAddBvnModal}>
					{bvnSuccessMsg ? (
						<SuccessCheckModal
							text="BVN Verified successfully"
							onRequestClick={isBvnAddSuccess}
							isShowBtn={true}
						/>
					) : (
						<AddBvnModal />
					)}
				</ModalContainer>
			)}
			{toggleAddBankAccountModal && (
				<ModalContainer modal={toggleAddBankAccountModal}>
					{bankAccountSuccess ? (
						<SuccessCheckModal
							text="Account added successfully"
							onRequestClick={isBankAccountAddSuccess}
							isShowBtn={true}
						/>
					) : (
						<AddBankAccountModal onRequestClick={addBankAccountDetails} />
					)}
				</ModalContainer>
			)}
			<div className="space-y-2 space-y-2.5">
				<PersonalFormCardToggler
					text="general profile information"
					isOpen={toggleGeneralInfo}
					onClick={() => setToggleGeneralInfo((prev) => !prev)}
				/>
				{toggleGeneralInfo && (
					<div className="flex flex-col gap-3 md:gap-4 bg-white rounded p-3.5 md:p-4">
						<div className="flex flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								BVN Verification:
							</p>
							<div className="zabalRegFont w-full sm:w-[70%] md:w-4/5 text-sm flex justify-end sm:justify-start">
								{bvn ? (
									<p className="text-sm">{bvn}</p>
								) : (
									<button
										className="zabalRegFont font-semibold text-fortcore-green"
										onClick={() => dispatch(openAddBvnModal())}
									>
										Add BVN
									</button>
								)}
							</div>
						</div>
						<div className="flex flex-col sm:flex-row wrap sm:items-start gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Link Bank Account:
							</p>
							<div className="zabalRegFont w-full sm:w-[70%] md:w-4/5 text-xs md:text-[13px] font-semibold flex gap-3 justify-between text-fortcore-green items-start">
								{bankName && (
									<div className="space-y-1">
										<p>{bankName}</p>
										<p className="text-xs text-[#8b8b8b]">{accountNumber}</p>
									</div>
								)}
								<button
									type="button"
									onClick={() => dispatch(openAddBankAccountModal())}
									className={`${
										bankName ? "text-primary-color" : "text-fortcore-green"
									}`}
								>
									{bankName ? "Change " : "Add "}Account
								</button>
							</div>
						</div>
						<ProfileInput
							text="home address"
							id="home_address"
							type="text"
							value={home_address}
							handleChange={(e) =>
								setGeneralProfile((prev) => ({
									...prev,
									home_address: e.target.value,
								}))
							}
							color
							placeholderText="Enter home address"
						/>
						<div className="flex flex-col sm:flex-row wrap sm:items-center gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Means of Identification :
							</p>
							<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
								<PlainSelect
									context="choose Id type"
									isOpened={toggleIdType}
									currentText={id_type}
									handleToggle={() => setToggleIdType((prev) => !prev)}
								/>
								{toggleIdType && (
									<div className="absolute z-[2] w-full top-48px">
										<SelectDropDown
											arrOptions={[
												"International Passport",
												"Driver's Licence",
												"National Identity Number (NIN)",
												"Voter's Card",
											]}
											onHandleSelect={(d) => {
												setGeneralProfile((prev) => ({ ...prev, id_type: d }));
												setTimeout(() => {
													setToggleIdType(false);
												}, 100);
											}}
										/>
									</div>
								)}
							</div>
						</div>

						{/* handle id number input */}
						<div className="flex flex-col sm:flex-row wrap sm:items-center gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								ID No:
							</p>
							<div className="h-[45px] w-full sm:w-[70%] md:w-4/5 text-sm relative zabalRegFont">
								<input
									type="text"
									className="h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] focus:border-2 focus:border-fortcore-green"
									placeholder="ID Number"
									name="id_number"
									value={id_number}
									onChange={(e) =>
										setGeneralProfile((prev) => ({
											...prev,
											id_number: e.target.value,
										}))
									}
								/>
							</div>
						</div>

						{/* handle id expiry date input */}
						{id_type !== "" && id_type !== "National Identity Number (NIN)" && (
							<div className="flex flex-col sm:flex-row wrap sm:items-center gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
								<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
									ID Expiry Date:
								</p>
								<div
									className={`h-[45px] w-full sm:w-[70%] md:w-4/5 text-sm relative ${
										expiry_date === "" ? "mb-2.5" : "mb-0"
									}`}
								>
									<input
										type="date"
										className={`h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] zabalRegFont bg-transparent outline-none text-[13px] lg:text-sm placeholder:text-[#B8B8B8] focus:border-2 focus:border-fortcore-green ${
											expiry_date === "" ? "text-[#B8B8B8]" : "text-[#393F42]"
										}`}
										min={moment().format("YYYY-MM-DD")}
										name="expiry_date"
										value={expiry_date}
										onChange={(e) =>
											setGeneralProfile((prev) => ({
												...prev,
												expiry_date: e.target.value,
											}))
										}
									/>
									{expiry_date === "" && (
										<p className="pt-0.5 text-xs zabalMedFont text-red-500">
											ID Card expiry date must be specified
										</p>
									)}
								</div>
							</div>
						)}

						{/* handle identiy card upload */}
						<div className="flex flex-col sm:flex-row sm:items-center sm:justify-start gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Identity Card:{" "}
								<span className="text-red-500 zabalMedFont sm:hidden">*</span>
							</p>
							<div className="sm:h-[45px] w-full sm:w-[70%] md:w-4/5 flex gap-4 items-start sm:items-center zabalMedFont">
								{idCard && (
									<a
										href={idCard}
										target="_blank"
										rel="noreferrer"
										className="zabalMedFont text-[12.5px] md:text-[13px] text-blue-700"
									>
										{shortenLongText(idCard)}
									</a>
								)}
								{idCardUploadLoading ? (
									<Preloader />
								) : (
									<button
										onClick={() =>
											document.getElementById("uploadIdCard").click()
										}
										className="text-fortcore-green text-[13px] md:text-sm"
									>
										{idCard ? "Change File" : "Upload ID Card"}
									</button>
								)}
								<input
									type="file"
									id="uploadIdCard"
									className="hidden"
									onChange={(e) =>
										setGeneralProfile((prev) => ({
											...prev,
											idCardInput: e.target.files[0],
										}))
									}
									accept="image/*,.pdf"
								/>
								{!idCard && !idCardUploadLoading && (
									<p className="hidden sm:block pt-0.5 text-[12.5px] zabalMedFont text-red-500">
										Please upload an identiy card *
									</p>
								)}
							</div>
						</div>

						{/* handle utility bill upload */}
						<div className="flex flex-col sm:flex-row sm:items-center sm:justify-start gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
								Utility Bill:{" "}
								<span className="text-red-500 zabalMedFont sm:hidden">*</span>
							</p>
							<div className="sm:h-[45px] w-full sm:w-[70%] md:w-4/5 flex gap-4 items-start sm:items-center zabalMedFont">
								{utilityBill && (
									<a
										href={utilityBill}
										target="_blank"
										rel="noreferrer"
										className="zabalMedFont text-[12.5px] md:text-[13px] text-blue-700"
									>
										{shortenLongText(utilityBill)}
									</a>
								)}
								{utilityBillUploadLoading ? (
									<Preloader />
								) : (
									<button
										onClick={() =>
											document.getElementById("uploadBill").click()
										}
										className="text-fortcore-green text-[13px] md:text-sm"
									>
										{utilityBill ? "Change File" : "Upload Utiltiy Bill"}
									</button>
								)}
								<input
									type="file"
									id="uploadBill"
									className="hidden"
									onChange={(e) =>
										setGeneralProfile((prev) => ({
											...prev,
											utilityBillInput: e.target.files[0],
										}))
									}
									accept="image/*,.pdf"
								/>
								{!utilityBill && !utilityBillUploadLoading && (
									<p className="hidden sm:block pt-0.5 text-[12.5px] zabalMedFont text-red-500">
										Please upload a utility bill *
									</p>
								)}
							</div>
						</div>

						{/* handle phone number */}
						<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
							<label
								htmlFor="phone"
								className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
							>
								Phone Number:
							</label>
							<div
								className={`relative w-full sm:w-[70%] md:w-4/5 ${
									checkPhone() ? "mb-2" : "mb-0"
								}`}
							>
								<PhoneInput
									country={"ng"}
									inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded text-[#393F42] !border-[#B8B8B8] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green border-[#B8B8B8] zabalRegFont"
									containerClass="!text-[12.5px] lg:!text-[13px] !w-full rounded !h-[44px] md:!h-[45px] !border-[#B8B8B8] zabalRegFont focus:!border-2 focus:!border-fortcore-green"
									value={phone_number}
									onChange={(phone_number) =>
										setGeneralProfile({
											...generalProfile,
											phone_number: phone_number,
										})
									}
									inputProps={{ maxLength: 19, id: "phone" }}
								/>
								{checkPhone() && (
									<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
										Phone number must be verified
									</p>
								)}
								<div className="absolute flex gap-2 items-center right-3 h-[45px] top-0">
									{!checkPhone() && (
										<span>
											<IsValidIcon width="15px" height="15px" />
										</span>
									)}
									{!phoneOtpMessageSuccess && checkPhone() && (
										<button
											className={`h-full flex items-center justify-center md:w-[60px] text-xs md:text-[12.5px] font-semibold zabalMedFont ${
												phoneOtpMessageLoading || phone_number?.length < 12
													? "text-[#717E83]"
													: "text-fortcore-green"
											} ${phoneOtpMessageLoading ? "w-[42px]" : "w-[56px]"}`}
											onClick={onGetPhoneOtp}
											disabled={
												phoneOtpMessageLoading || phone_number?.length < 12
											}
										>
											{phoneOtpMessageLoading ? <Preloader /> : "Get Code"}
										</button>
									)}
								</div>
							</div>
						</div>
						{/* handle phone otp */}
						{phoneOtpMessageSuccess && (
							<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont text-[#393F42] gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
								<label
									htmlFor="phone_code"
									className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
								>
									Phone Code:
								</label>
								<div className="space-y-1.5 w-full sm:w-[70%] md:w-4/5">
									<div className="relative h-[45px] w-full zabalMedFont">
										<input
											type="tel"
											className="text-sm h-full w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
											placeholder="phone number otp"
											maxLength={6}
											id="phone_code"
											value={phone_code}
											onChange={(e) =>
												setGeneralProfile((prev) => ({
													...prev,
													phone_code: e.target.value,
												}))
											}
										/>
										{verifyPhoneOtpLoading && (
											<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
												<Preloader />
											</div>
										)}
									</div>
									<div className="pl-4 pr-2 text-[11px] flex justify-between text-fortcore-green zabalMedFont">
										{isShowPhoneTimer && <CountDown timer={115} />}
										<div className="space-x-1.5 justify-self-end ">
											<span className="text-[#393F42]">
												Didn&apos;t get code?
											</span>
											<button
												className={`font-semibold ${
													isShowPhoneTimer ||
													phoneOtpMessageLoading ||
													phone_number?.length < 12
														? "text-[#717E83]"
														: "text-fortcore-green"
												}`}
												onClick={onGetPhoneOtp}
												disabled={
													isShowPhoneTimer ||
													phoneOtpMessageLoading ||
													phone_number?.length < 12
												}
											>
												{phoneOtpMessageLoading ? "Sending..." : "Resend Code"}
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
						{!currentUser?.accessPin && (
							<p className="zabalRegFont text-[13px] mt-3">
								<span className="zabalBoldFont">NB:</span> For{" "}
								<span className="text-red-500 zabalMedFont">100%</span> profile
								completion, go to{" "}
								<Link
									to="/dashboard/profile"
									state={{ currentTab: 2 }}
									className="font-semibold text-blue-600 text-sm border-b-2 border-blue-600"
								>
									Security
								</Link>{" "}
								to create your transaction pin.
							</p>
						)}
						<div className="hidden sm:flex justify-end py-2">
							<CustomGreenBtn
								text="save"
								width="105px"
								height="42px"
								fontSize="13px"
								isDisabled={
									isGenProfileBtnInvalid() || updatingUserAccountLoading
								}
								isLoading={updatingUserAccountLoading}
								onRequestClick={onSubmitGeneralInfo}
							/>
						</div>
						<div className="sm:hidden pt-4 pb-2">
							<CustomGreenBtn
								text="save"
								isDisabled={
									isGenProfileBtnInvalid() || updatingUserAccountLoading
								}
								isLoading={updatingUserAccountLoading}
								onRequestClick={onSubmitGeneralInfo}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default GeneralInformation;
