import React, { useState } from "react";
import { FatBackTracker } from "../../layout/CustomComponents";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import { useSelector, useDispatch } from "react-redux";
import Parse from "../../api/ApiConfig";
import {
	saveGuarantorsFormToParse,
	updateGuarantorsFormInParse,
} from "../../redux/authRedux/authServices";
import { updateOngoingLeaseInParse } from "../../redux/lease/leaseServices";

const Form = ({
	setActiveTab,
	information,
	handleChange,
	setInformation,
	query,
}) => {
	const dispatch = useDispatch();
	const { savingGuarantorsFormLoading, guarantorsFormSuccess } = useSelector(
		(state) => state.auth
	);

	const [oldDetails, setOldDetails] = useState(false);

	const { name_one, name_two, email_one, email_two, phone_one, phone_two } =
		information;

	const isBtnValid = () => {
		if (
			name_one === "" ||
			name_two === "" ||
			email_one === "" ||
			email_two === "" ||
			phone_one === "" ||
			phone_two === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const isShowUpdateBtn = () => {
		if (guarantorsFormSuccess && !oldDetails) {
			return true;
		} else {
			return false;
		}
	};

	console.log(guarantorsFormSuccess);

	const onUseOldDetails = () => {
		setInformation({
			signature: guarantorsFormSuccess?.get("signature"),
			name_one: guarantorsFormSuccess?.get("name1"),
			name_two: guarantorsFormSuccess?.get("name2"),
			email_one: guarantorsFormSuccess?.get("email1"),
			email_two: guarantorsFormSuccess?.get("email2"),
			phone_one: guarantorsFormSuccess?.get("phone1"),
			phone_two: guarantorsFormSuccess?.get("phone2"),
		});
		setOldDetails(true);
	};

	const data = {
		phone1: phone_one,
		phone2: phone_two,
		name1: name_one,
		name2: name_two,
		email1: email_one,
		email2: email_two,
		signature: information?.signature,
		dateSigned: new Date().toISOString(),
		user,
	};

	const onSubmitGuarantorInfo = () => {
		const isGuarantorFormExist = guarantorsFormSuccess?.id || "";
		console.log(data, isGuarantorFormExist);
		if (isGuarantorFormExist) {
			dispatch(updateGuarantorsFormInParse(isGuarantorFormExist, data));
			dispatch(
				updateOngoingLeaseInParse(
					query,
					{ guarantor: data },
					"update-guarantor"
				)
			);
		} else {
			dispatch(saveGuarantorsFormToParse(data));
			dispatch(
				updateOngoingLeaseInParse(
					query,
					{ guarantor: data },
					"update-guarantor"
				)
			);
		}
	};

	return (
		<>
			<div className="space-y-4 sm:space-y-5">
				<div className="space-y-1.5">
					<FatBackTracker
						text="guarantors' form"
						onNavigate={() => setActiveTab(1)}
					/>
					<p className="text-xs md:text-[12.25px] text-[#717E83] zabalRegFont ">
						Please provide two(2) guarantors to complete your request.
					</p>
				</div>
				<div className="text-sm rounded-md bg-[#F6F7F8] p-4 sm:p-5 pb-6 sm:pb-7 text-[#393F42] zabalRegFont text-justify leading-6 space-y-6">
					<div className="flex flex-col gap-3.5 sm:gap-4 lg:gap-5">
						{isShowUpdateBtn() && (
							<p className="text-[12.5px]">
								Click{" "}
								<button
									className="zabalMedFont text-fortcore-green"
									onClick={onUseOldDetails}
								>
									Update
								</button>{" "}
								to use previous guarantors&apos; information
							</p>
						)}
						<h4 className="text-[#717E83] text-sm zabalRegFont font-semibold">
							Guarantor 1
						</h4>
						<div className="flex flex-col space-y-1.5">
							<label htmlFor="name_one" className="text-xs text-[#393F42]">
								Name
							</label>
							<input
								type="text"
								id="name_one"
								placeholder="Guarantor's Name"
								className="px-2.5 bg-transparent placeholder:text-[12.5px] placeholder:text-[#717e83] outline-none rounded border border-[#96A0A5] h-[45px] focus:border-2 focus:border-fortcore-green"
								value={name_one}
								onChange={handleChange}
							/>
						</div>
						<div className="flex flex-col space-y-1.5">
							<label htmlFor="phone_one" className="text-xs text-[#393F42]">
								Phone No.
							</label>
							<PhoneInput
								country={"ng"}
								inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-[45px] rounded border !border-[#96A0A5] !text-[#393F42] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green zabalRegFont"
								containerClass="!text-[12.5px] lg:!text-[13px] !w-full !border-[#96A0A5] !h-[45px] !text-[#393F42] focus:!border-2 focus:!border-fortcore-green zabalRegFont"
								value={phone_one}
								inputProps={{
									placeholder: "Guarantor's Number (include country code)",
									id: "phone_one",
									maxLength: 19,
									inputMode: "numeric",
								}}
								onChange={(phone_number) =>
									setInformation((prev) => ({
										...prev,
										phone_one: phone_number,
									}))
								}
							/>
						</div>
						<div className="flex flex-col space-y-1.5">
							<label htmlFor="email_one" className="text-xs text-[#393F42]">
								Email Address
							</label>
							<input
								type="text"
								id="email_one"
								placeholder="Guarantor's Email"
								className="bg-transparent placeholder:text-[12.5px] placeholder:text-[#717e83] outline-none rounded border border-[#96A0A5] px-2.5 h-[45px] focus:border-2 focus:border-fortcore-green"
								value={email_one}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-3.5 sm:gap-4 lg:gap-5">
						<h4 className="text-[#717E83] text-sm zabalRegFont font-semibold">
							Guarantor 2
						</h4>
						<div className="flex flex-col space-y-1.5">
							<label htmlFor="name_two" className="text-xs text-[#393F42]">
								Name
							</label>
							<input
								type="text"
								id="name_two"
								placeholder="Guarantor's Name"
								className="px-2.5 bg-transparent placeholder:text-[12.5px] placeholder:text-[#717e83] outline-none rounded border border-[#96A0A5] h-[45px] focus:border-2 focus:border-fortcore-green"
								value={name_two}
								onChange={handleChange}
							/>
						</div>
						<div className="flex flex-col space-y-1.5">
							<label htmlFor="phone_two" className="text-xs text-[#393F42]">
								Phone No.
							</label>
							<PhoneInput
								country={"ng"}
								inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-[45px] rounded border !border-[#96A0A5] !text-[#393F42] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green zabalRegFont"
								containerClass="!text-[12.5px] lg:!text-[13px] !w-full !border-[#96A0A5] !h-[45px] !text-[#393F42] focus:!border-2 focus:!border-fortcore-green zabalRegFont"
								value={phone_two}
								inputProps={{
									placeholder: "Guarantor's Number (include country code)",
									id: "phone_two",
									maxLength: 19,
									inputMode: "numeric",
								}}
								onChange={(phone_number) =>
									setInformation((prev) => ({
										...prev,
										phone_two: phone_number,
									}))
								}
							/>
						</div>
						<div className="flex flex-col space-y-1.5">
							<label htmlFor="email_two" className="text-xs text-[#393F42]">
								Email Address
							</label>
							<input
								type="text"
								id="email_two"
								placeholder="Guarantor's Email"
								className="bg-transparent placeholder:text-[12.5px] placeholder:text-[#717e83] outline-none rounded border border-[#96A0A5] px-2.5 h-[45px] focus:border-2 focus:border-fortcore-green"
								value={email_two}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="sm:hidden pt-2">
						<CustomGreenBtn
							text="submit"
							onRequestClick={onSubmitGuarantorInfo}
							isDisabled={isBtnValid() || savingGuarantorsFormLoading}
							isLoading={savingGuarantorsFormLoading}
						/>
					</div>
					<div className="hidden sm:flex justify-end pt-4">
						<CustomGreenBtn
							text="submit"
							width="110px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={onSubmitGuarantorInfo}
							isDisabled={isBtnValid() || savingGuarantorsFormLoading}
							isLoading={savingGuarantorsFormLoading}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default Form;

Form.propTypes = {
	setActiveTab: PropTypes.func,
	handleChange: PropTypes.func,
	setInformation: PropTypes.func,
	information: PropTypes.object,
	query: PropTypes.string,
};
