import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
	CustomConfirmModal,
	CustomHeaderWithCloseBtn,
	SuccessCheckModal,
} from "../../layout/CustomComponents";
import { CustomGreenBtn } from "../../layout/CustomButtons";
import {
	addAllNumbersAmount,
	formatAmountToIncludeCurrency,
	formatDurationForProgramSavings,
} from "../../common/utils";
import NotifyUser from "../../layout/NotifyUser";
import PaymentOption from "./PaymentOption";
import { EnterPin } from "./PinComponent";
import MonthlyRepayments from "./MonthlyRepayments";
import { payMonthlyPayment } from "../../redux/payments/paymentServices";

const UpcomingPayments = ({ requestData, onCloseModal, context }) => {
	const { currentUser } = useSelector((state) => state.auth);

	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(1);
	const [numOfMonths, setNumberOfMonths] = useState("");
	const [inputPin, setInputPin] = useState("");
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [paymentLoading, setPaymentLoading] = useState(false);

	const accessPin = currentUser?.accessPin || "";

	const monthsPaid = addAllNumbersAmount(
		requestData?.userPayment?.map((a) => Number(a?.months))
	);
	const paymentStatus = Array(monthsPaid).fill(true);

	const leaseDuration = formatDurationForProgramSavings(
		requestData?.leaseDuration
	);

	const apprenticeDuration = formatDurationForProgramSavings(
		requestData?.tenure
	);

	const leasePaymentOne = {
		date: new Date(requestData?.leaseAgreement?.date).getTime(),
		amount: requestData?.monthlyPayment,
		status: requestData?.firstPayment?.paid,
	};

	let leasePayments = [{ ...leasePaymentOne }];
	let apprenticePayments = [];

	for (let i = 1; i < leaseDuration; i++) {
		const a = {
			amount: requestData?.monthlyPayment,
			date: new Date(requestData?.dateOfDeployment).setMonth(
				new Date(requestData?.dateOfDeployment).getMonth() + i
			),
			status: paymentStatus[i - 1] || false,
		};

		leasePayments.push(a);
	}

	for (let i = 1; i <= apprenticeDuration; i++) {
		const a = {
			amount: requestData?.programSavings,
			date: new Date(requestData?.dateOfDeployment).setMonth(
				new Date(requestData?.dateOfDeployment).getMonth() + i
			),
			status: paymentStatus[i - 1] || false,
		};

		apprenticePayments.push(a);
	}

	const onProceedToTabTwo = () => {
		setActiveTab(2);
	};

	const currentData = context === "lease" ? leasePayments : apprenticePayments;

	const monthlyPayment =
		context === "lease"
			? requestData?.monthlyPayment
			: requestData?.programSavings;

	const totalAmount = Number(numOfMonths) * monthlyPayment;

	// console.log(currentData);

	const unpaidMonths = currentData?.filter((p) => !p.status);

	const numberError = () => {
		if (numOfMonths.length) {
			if (Number(numOfMonths) < 1) {
				return "Months must be greater than zero";
			} else if (Number(numOfMonths) > unpaidMonths.length) {
				return `Number of months cannot be greater than ${unpaidMonths.length}`;
			} else {
				return "";
			}
		} else {
			return "";
		}
	};

	const isTabTwoBtnDisabled = () => {
		if (!numOfMonths) {
			return true;
		} else if (Number(numOfMonths) > unpaidMonths.length) {
			return true;
		} else {
			return false;
		}
	};

	const onUserClickMakePayment = async () => {
		const amountToPay =
			process.env.NODE_ENV !== "production" ? 0.5 : monthlyPayment;
		try {
			setPaymentLoading(true);
			const data = {
				id: requestData?.objectId,
				queryType: context === "lease" ? "lease" : "AFinance",
				months: numOfMonths,
				amount: String(Number(numOfMonths) * amountToPay),
			};

			const response = await payMonthlyPayment(data);

			if (response) {
				setActiveTab(7);
			}

			console.log(response);
		} catch (error) {
			if (error?.message === "Network Error") {
				toast.error("Oops! You are not connected to the internet.");
			}
			if (error?.message.includes("Unable to connect to the Parse API")) {
				toast.error("Oops! You are not connected to the internet.");
			} else if (error?.message?.includes("Invalid session token")) {
				toast.error("This session has expired. Please login.");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			}
		} finally {
			setPaymentLoading(false);
		}
	};
	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	return (
		<>
			{activeTab === 1 && (
				<div className="bg-white rounded-lg py-4 md:py-5 w-11/12 max-w-[520px] mx-auto">
					<CustomHeaderWithCloseBtn
						text="upcoming repayment schedule"
						onClick={onCloseModal}
						isPadding
					/>
					<div className="px-4 md:px-5 my-4 md:px-3 max-h-[320px] md:max-h-[350px] overflow-y-auto scroll-wrapper">
						<div className="grid grid-cols-3 gap-4 text-[13.5px] md:text-sm zabalMedFont w-full py-2.5 md:py-3 border-y">
							<p className="zabalMedFont text-sm md:text-[15px]">Date</p>
							<p className="zabalMedFont text-sm md:text-[15px] text-center">
								Amount
							</p>
							<p className="zabalMedFont text-sm md:text-[15px] text-end">
								Status
							</p>
						</div>
						<MonthlyRepayments payments={currentData} />
					</div>
					<div className="md:py-2 px-4 md:px-5">
						<CustomGreenBtn
							text="make payment"
							onRequestClick={onProceedToTabTwo}
						/>
					</div>
				</div>
			)}
			{activeTab === 2 && (
				<NotifyUser
					header="Month Selection"
					title="Enter the number of months you want to pay for"
					onCloseBtnClick={onCloseModal}
					onWhiteBtnClick={() => setActiveTab(1)}
					onGreenBtnClick={() => setActiveTab(3)}
					isGreenBtnDisabled={isTabTwoBtnDisabled()}
					btnText="proceed"
				>
					<div className="space-y-1 zabalMedFont">
						<p className="text-xs md:text[12.5px]">Months</p>
						<input
							type="number"
							min="1"
							max={unpaidMonths.length}
							className="h-[45px] bg-transparent rounded-md w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
							placeholder="enter number of months"
							value={numOfMonths}
							onChange={(e) => setNumberOfMonths(e.target.value)}
							// onInput={onInputAmount}
						/>
						<p className="text-xs md:text-[13px] text-red-500">
							{numberError()}
						</p>
					</div>
				</NotifyUser>
			)}
			{activeTab === 3 && (
				<div className="rounded-lg p-4 md:p-5 bg-[#F6F7F8] w-11/12 max-w-[450px] mx-auto">
					<CustomHeaderWithCloseBtn
						text="one final step"
						onClick={() => setActiveTab(2)}
						// isPadding
					/>
					<p className="text-[12.75px] text-[#393F42]">
						You are about to pay for your monthly recurring payment.
					</p>
					<div className="mt-4 space-y-2">
						<div className="flex justify-between text-[13px]">
							<p className="zabalMedFont">Number Of Months:</p>
							<p className="zabalRegFont">{numOfMonths}</p>
						</div>
						<div className="flex justify-between text-[13px]">
							<p className="zabalMedFont">Amount:</p>
							<p className="zabalRegFont">
								{" "}
								{formatAmountToIncludeCurrency("NGN", totalAmount)}
							</p>
						</div>
					</div>
					<div className="mt-10 flex justify-between items-center">
						<p className="text-[12.5px] text-[#717E83]">
							Proceed to make payment
						</p>
						<CustomGreenBtn
							text="pay"
							width="80px"
							height="39px"
							onRequestClick={() => setActiveTab(4)}
						/>
					</div>
				</div>
			)}
			{activeTab === 4 && (
				<PaymentOption
					onClose={onCloseModal}
					onWalletSelect={() => setActiveTab(5)}
					onCardSelect={() => {}}
				/>
			)}
			{activeTab === 5 && (
				<CustomConfirmModal
					text={`The amount of N${totalAmount.toFixed(
						2
					)} will be deducted from your wallet for ${numOfMonths} ${
						numOfMonths > 1 ? "months" : "month"
					} repayment.`}
					greenBtnText="proceed"
					onCloseBtnClick={onCloseModal}
					greenBtnClick={() => setActiveTab(6)}
				/>
			)}
			{activeTab === 6 && (
				<EnterPin
					btnText="pay now"
					onCloseBtnClick={onCloseModal}
					onInputChange={setInputPin}
					isPinInvalid={invalidPinError}
					isPinExist={accessPin !== undefined}
					isAllowedToProceed={inputPin.length > 3 && inputPin === accessPin}
					proceedToCreatePin={() =>
						navigate("/dashboard/profile", { state: { currentTab: 2 } })
					}
					onRequestClick={onUserClickMakePayment}
					isPaymentLoading={paymentLoading}
				/>
			)}
			{activeTab === 7 && (
				<SuccessCheckModal
					text={`The amount of NGN ${totalAmount} has been deducted from your wallet for ${numOfMonths} months(s) payment`}
					onRequestClick={() => location.reload()}
				/>
			)}
		</>
	);
};

export default UpcomingPayments;

UpcomingPayments.propTypes = {
	requestData: PropTypes.object,
	onCloseModal: PropTypes.func,
	context: PropTypes.string,
};
