import { toast } from "react-toastify";
import Parse from "../../api/ApiConfig";
import {
	createNewApplicationError,
	createNewApplicationLoading,
	createNewApplicationSuccess,
	fetchSingleRegularSavingsError,
	fetchSingleRegularSavingsLoading,
	fetchSingleRegularSavingsSuccess,
	fetchTravelSavingsError,
	fetchTravelSavingsLoading,
	fetchTravelSavingsSuccess,
	saveAccountInfoError,
	saveAccountInfoLoading,
	saveAccountInfoSuccess,
	saveContactInfoError,
	saveContactInfoLoading,
	saveContactInfoSuccess,
	saveDocumentsInfoError,
	saveDocumentsInfoLoading,
	saveDocumentsInfoSuccess,
	saveEducationInfoError,
	saveEducationInfoLoading,
	saveEducationInfoSuccess,
	saveFamilyInfoError,
	saveFamilyInfoLoading,
	saveFamilyInfoSuccess,
	saveFinancialInfoError,
	saveFinancialInfoLoading,
	saveFinancialInfoSuccess,
	savePersonalInfoError,
	savePersonalInfoLoading,
	savePersonalInfoSuccess,
	saveProfBusinessInfoError,
	saveProfBusinessInfoLoading,
	saveProfBusinessInfoSuccess,
	saveRequestInfoError,
	saveRequestInfoLoading,
	saveRequestInfoSuccess,
	// saveTravelInfoError,
	saveTravelInfoLoading,
	saveTravelInfoSuccess,
	updateRegularSavings,
} from "./travelActions";
import { networkErrorTrue } from "../authRedux/authActions";
import { createNotification } from "../../components/notification/CreateNotification";

export const fetchPersonalInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_Personal");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(savePersonalInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const savePersonalInformation = (userInfo) => async (dispatch) => {
	dispatch(savePersonalInfoLoading());
	try {
		const query = new Parse.Query("TA_Personal");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in userInfo
				for (const key in userInfo) {
					myObject.set(key, userInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_Personal = Parse.Object.extend("TA_Personal");
				myObject = new TA_Personal();
				console.log(myObject);
				myObject.set("userId", Parse.User.current()?.id);
				for (const key in userInfo) {
					myObject.set(key, userInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(savePersonalInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Personal Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(savePersonalInfoError());
			} else {
				dispatch(savePersonalInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(savePersonalInfoError());
	}
};

export const fetchContactInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_Contact");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveContactInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveContactInformation = (contactInfo) => async (dispatch) => {
	dispatch(saveContactInfoLoading());
	try {
		const query = new Parse.Query("TA_Contact");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in contactInfo
				for (const key in contactInfo) {
					myObject.set(key, contactInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_Contact = Parse.Object.extend("TA_Contact");
				myObject = new TA_Contact();
				console.log(myObject);
				myObject.set("userId", Parse.User.current()?.id);
				for (const key in contactInfo) {
					myObject.set(key, contactInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveContactInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Contact Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveContactInfoError());
			} else {
				dispatch(saveContactInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveContactInfoError());
	}
};

export const fetchProfBusinessInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_ProfBus");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveProfBusinessInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveProfBusinessInformation =
	(profBusInfo, context) => async (dispatch) => {
		dispatch(saveProfBusinessInfoLoading());
		try {
			const query = new Parse.Query("TA_ProfBus");
			query.equalTo("userId", Parse.User.current()?.id);
			let myObject = null;
			try {
				myObject = await query.first();

				if (myObject) {
					// If the object exists, update its attributes with the values in profBusInfo
					for (const key in profBusInfo) {
						myObject.set(key, profBusInfo[key]);
					}
				} else {
					// If the object doesn't exist, create a new one
					const TA_ProfBus = Parse.Object.extend("TA_ProfBus");
					myObject = new TA_ProfBus();

					myObject.set("userId", Parse.User.current()?.id);
					for (const key in profBusInfo) {
						myObject.set(key, profBusInfo[key]);
					}
				}

				const createdOrUpdatedData = await myObject.save();
				dispatch(saveProfBusinessInfoSuccess(createdOrUpdatedData?.toJSON()));
				toast.success(`${context} Information saved successfully!`);
			} catch (error) {
				console.log(error);
				if (error?.message === "Network Error") {
					toast.error("Oops, Service provider has been disconnected!");
					dispatch(saveProfBusinessInfoError());
				} else {
					dispatch(saveProfBusinessInfoError());
					toast.error("Failed to update details");
				}
			}
		} catch (error) {
			console.log(error);
			toast.error("Failed to update details");
			dispatch(saveProfBusinessInfoError());
		}
	};

export const fetchEducationInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_EduStudy");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveEducationInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveEducationInformation = (educationInfo) => async (dispatch) => {
	dispatch(saveEducationInfoLoading());
	try {
		const query = new Parse.Query("TA_EduStudy");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in educationInfo
				for (const key in educationInfo) {
					myObject.set(key, educationInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_EduStudy = Parse.Object.extend("TA_EduStudy");
				myObject = new TA_EduStudy();

				myObject.set("userId", Parse.User.current()?.id);
				for (const key in educationInfo) {
					myObject.set(key, educationInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveEducationInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Education Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveEducationInfoError());
			} else {
				dispatch(saveEducationInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveEducationInfoError());
	}
};

export const fetchFamilyInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_Family");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveFamilyInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveFamilyInformation = (familyInfo) => async (dispatch) => {
	dispatch(saveFamilyInfoLoading());
	try {
		const query = new Parse.Query("TA_Family");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in familyInfo
				for (const key in familyInfo) {
					myObject.set(key, familyInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_Family = Parse.Object.extend("TA_Family");
				myObject = new TA_Family();

				myObject.set("userId", Parse.User.current()?.id);
				for (const key in familyInfo) {
					myObject.set(key, familyInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveFamilyInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Family Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveFamilyInfoError());
			} else {
				dispatch(saveFamilyInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveFamilyInfoError());
	}
};

export const fetchTravelInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_Travel");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveTravelInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveTravelInformation = (travelInfo) => async (dispatch) => {
	dispatch(saveTravelInfoLoading());
	setTimeout(() => {
		dispatch(saveTravelInfoSuccess(travelInfo));
		toast.success("Travel Information saved successfully!");
	}, 1000);
	// try {
	// 	const query = new Parse.Query("TA_Travel");
	// 	query.equalTo("userId", Parse.User.current()?.id);
	// 	let myObject = null;
	// 	try {
	// 		myObject = await query.first();

	// 		if (myObject) {
	// 			// If the object exists, update its attributes with the values in travelInfo
	// 			for (const key in travelInfo) {
	// 				myObject.set(key, travelInfo[key]);
	// 			}
	// 		} else {
	// 			// If the object doesn't exist, create a new one
	// 			const TA_Travel = Parse.Object.extend("TA_Travel");
	// 			myObject = new TA_Travel();

	// 			myObject.set("userId", Parse.User.current()?.id);
	// 			for (const key in travelInfo) {
	// 				myObject.set(key, travelInfo[key]);
	// 			}
	// 		}

	// 		const createdOrUpdatedData = await myObject.save();
	// 		dispatch(saveTravelInfoSuccess(createdOrUpdatedData?.toJSON()));
	// 		toast.success("Travel Information saved successfully!");
	// 	} catch (error) {
	// 		console.log(error);
	// 		if (error?.message === "Network Error") {
	// 			toast.error("Oops, Service provider has been disconnected!");
	// 			dispatch(saveTravelInfoError());
	// 		} else {
	// 			dispatch(saveTravelInfoError());
	// 			toast.error("Failed to update details");
	// 		}
	// 	}
	// } catch (error) {
	// 	console.log(error);
	// 	toast.error("Failed to update details");
	// 	dispatch(saveTravelInfoError());
	// }
};

export const saveAccountInformation = (accountInfo) => async (dispatch) => {
	dispatch(saveAccountInfoLoading());
	try {
		const query = new Parse.Query("TA_Bank");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in accountInfo
				for (const key in accountInfo) {
					myObject.set(key, accountInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_Bank = Parse.Object.extend("TA_Bank");
				myObject = new TA_Bank();

				myObject.set("userId", Parse.User.current()?.id);
				for (const key in accountInfo) {
					myObject.set(key, accountInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveAccountInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Account Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveAccountInfoError());
			} else {
				dispatch(saveAccountInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveAccountInfoError());
	}
};

export const fetchFinancialInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_FinancialInformation");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveFinancialInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveFinancialInformation = (financialInfo) => async (dispatch) => {
	dispatch(saveFinancialInfoLoading());
	try {
		const query = new Parse.Query("TA_FinancialInformation");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in financialInfo
				for (const key in financialInfo) {
					myObject.set(key, financialInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_FinancialInformation = Parse.Object.extend(
					"TA_FinancialInformation"
				);
				myObject = new TA_FinancialInformation();

				myObject.set("userId", Parse.User.current()?.id);
				for (const key in financialInfo) {
					myObject.set(key, financialInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveFinancialInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Financial Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveFinancialInfoError());
			} else {
				dispatch(saveFinancialInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveFinancialInfoError());
	}
};

export const fetchDocumentsInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_Documentation");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveDocumentsInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveDocumentsInformation = (documentsInfo) => async (dispatch) => {
	dispatch(saveDocumentsInfoLoading());
	try {
		const query = new Parse.Query("TA_Documentation");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in documentsInfo
				for (const key in documentsInfo) {
					myObject.set(key, documentsInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_Documentation = Parse.Object.extend("TA_Documentation");
				myObject = new TA_Documentation();

				myObject.set("userId", Parse.User.current()?.id);
				for (const key in documentsInfo) {
					myObject.set(key, documentsInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveDocumentsInfoSuccess(createdOrUpdatedData.toJSON()));
			toast.success("Documents Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveDocumentsInfoError());
			} else {
				dispatch(saveDocumentsInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveDocumentsInfoError());
	}
};

export const fetchTravelsRegularSavings = () => async (dispatch) => {
	dispatch(fetchTravelSavingsLoading());
	try {
		const query = new Parse.Query("Savings");
		query
			.equalTo("userId", Parse.User.current()?.id)
			.equalTo("savingsType", "regular")
			.equalTo("source", "travel");
		const response = await query.find();
		const data = response.map((item) => item?.toJSON());
		dispatch(fetchTravelSavingsSuccess(data));
	} catch (error) {
		console.log(error);
		dispatch(fetchTravelSavingsError());
	}
};

export const fetchRequestInformation = () => async (dispatch) => {
	try {
		const query = new Parse.Query("TA_DetailsOfRequest");
		query.equalTo("userId", Parse.User.current()?.id);
		const response = await query.find();
		dispatch(saveRequestInfoSuccess(response[0]?.toJSON()));
	} catch (error) {
		console.log(error);
	}
};

export const saveRequestInformation = (requestInfo) => async (dispatch) => {
	dispatch(saveRequestInfoLoading());
	try {
		const query = new Parse.Query("TA_DetailsOfRequest");
		query.equalTo("userId", Parse.User.current()?.id);
		let myObject = null;
		try {
			myObject = await query.first();

			if (myObject) {
				// If the object exists, update its attributes with the values in requestInfo
				for (const key in requestInfo) {
					myObject.set(key, requestInfo[key]);
				}
			} else {
				// If the object doesn't exist, create a new one
				const TA_DetailsOfRequest = Parse.Object.extend("TA_DetailsOfRequest");
				myObject = new TA_DetailsOfRequest();

				myObject.set("userId", Parse.User.current()?.id);
				for (const key in requestInfo) {
					myObject.set(key, requestInfo[key]);
				}
			}

			const createdOrUpdatedData = await myObject.save();
			dispatch(saveRequestInfoSuccess(createdOrUpdatedData?.toJSON()));
			toast.success("Request Information saved successfully!");
		} catch (error) {
			console.log(error);
			if (error?.message === "Network Error") {
				toast.error("Oops, Service provider has been disconnected!");
				dispatch(saveRequestInfoError());
			} else {
				dispatch(saveRequestInfoError());
				toast.error("Failed to update details");
			}
		}
	} catch (error) {
		console.log(error);
		toast.error("Failed to update details");
		dispatch(saveRequestInfoError());
	}
};

export const createNewApplication =
	(allData, travelData) => async (dispatch) => {
		dispatch(createNewApplicationLoading());
		try {
			const Travel = Parse.Object.extend("TA_Travel");
			const travels = new Travel();
			const response = await travels.save(travelData);

			const TravelAdvance = Parse.Object.extend("TravelAdvance");
			const travelAdvance = new TravelAdvance();

			travelAdvance.set("travel", response.toPointer());
			const newApplication = await travelAdvance.save(allData);
			console.log(newApplication);
			dispatch(createNewApplicationSuccess(newApplication));
		} catch (error) {
			console.log(error);
			toast.error("Failed to create new application");
			dispatch(createNewApplicationError());
		}
	};

export const fetchSingleRegularSavings =
	(objectId, source) => async (dispatch) => {
		dispatch(fetchSingleRegularSavingsLoading());
		try {
			const query = new Parse.Query("Savings");
			query
				.equalTo("userId", Parse.User.current()?.id)
				.equalTo("savingsType", "regular")
				.equalTo("source", source)
				.equalTo("objectId", objectId);
			const response = await query.find();

			dispatch(fetchSingleRegularSavingsSuccess(response[0]?.toJSON()));
		} catch (error) {
			console.log(error);
			if (error?.message?.includes("Unable to connect to the Parse API")) {
				dispatch(networkErrorTrue(error.message));
				dispatch(fetchSingleRegularSavingsError());
			} else {
				dispatch(fetchSingleRegularSavingsError());
			}
		}
	};

export const updateSingleRegularSavings =
	(objectId, data, otherDetails) => async (dispatch) => {
		try {
			const query = new Parse.Query("Savings");
			const object = await query.get(objectId);
			const updatedObject = await object.save(data);

			dispatch(updateRegularSavings(updatedObject?.toJSON()));

			if (updatedObject) {
				createNotification(otherDetails?.message, otherDetails?.metaData);
			}
		} catch (error) {
			console.log(error);
			toast.error("Failed to update details");
		}
	};
