import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { BiError } from "react-icons/bi";
import { shortenLongText } from "../../../../../../common/utils";
import MiniLoader from "../../../../../../layout/miniLoader";
import { IoCloudUpload } from "react-icons/io5";
import { useUploadFile } from "../../../../../../components/customhooks/useUploadFile";

export const OtherUploadComponent = ({ onFileUpload, fileName, title }) => {
	const attachmentInputRef = useRef(null);

	const [fileToUpload, setFileToUpload] = useState(null);

	const { loading, fileSuccess } = useUploadFile(fileToUpload);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileToUpload(file);
		} else {
			toast.error("Unsupported file type. Please select a JPEG or PNG file.");
		}
	};

	useEffect(() => {
		if (fileSuccess) {
			onFileUpload(fileSuccess);
			setFileToUpload(null);
		}
	}, [fileSuccess]);

	return (
		<Box
			sx={{
				border: "1px solid var(--grey-200, #E3E9EC)",
				borderRadius: "8px",
				width: "100%",
				height: "66px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "0 8px",
			}}
		>
			<input
				type="file"
				ref={attachmentInputRef}
				style={{ display: "none" }}
				onChange={handleFileChange}
				accept=".jpg, .jpeg, .png, .pdf"
			/>
			<Typography variant="h4" sx={{ color: "#393F42" }}>
				{title}
			</Typography>

			<Stack
				direction="row"
				spacing={1}
				sx={{ display: "flex", alignItems: "center", gap: "5px" }}
			>
				{!fileName ? (
					<Typography
						variant="body1"
						color="primary"
						sx={{
							display: "flex",
							alignItems: "center",
							color: "#F00",
						}}
					>
						<BiError style={{ fontSize: "20px", marginRight: "5px" }} /> Action
						Required
					</Typography>
				) : (
					<a
						href={fileName}
						target="_blank"
						rel="noreferrer"
						className="zabalMedFont text-[13px] md:text-sm italic text-black/80"
					>
						{shortenLongText(fileName, 8)}
					</a>
				)}
				<button
					className="rounded-lg flex flex-col items-center justify-center gap-0.5 px-5 text-fortcore-green border border-fortcore-green/50 w-auto h-[50px] md:w-[100px]"
					onClick={() => attachmentInputRef.current.click()}
				>
					{loading ? (
						<MiniLoader />
					) : (
						<>
							<IoCloudUpload size={20} />
							<span className="text-xs zabalMedFont">
								{fileName ? "Change" : "Add"}
							</span>
						</>
					)}
				</button>
			</Stack>
		</Box>
	);
};

OtherUploadComponent.propTypes = {
	onFileUpload: PropTypes.any,
	fileName: PropTypes.string,
	title: PropTypes.string,
};
