import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { LeaseSummaryInput } from "../requestlease/LeaseSummary";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import ModalContainer from "../../../components/ModalContainer";
import { FaArrowLeft } from "react-icons/fa";
import PaymentOption from "../../../components/payments/PaymentOption";
import CardDetails from "../../../components/payments/CardDetails";
import {
	saveRegularSavingsForm,
	selectedPaymentMethod,
} from "../../../redux/lease/leaseActions";
import {
	ConfirmPin,
	CreatePin,
	EnterPin,
} from "../../../components/payments/PinComponent";
import PropTypes from "prop-types";
import { SuccessCheckModal } from "../../../layout/CustomComponents";
import {
	createAccessPin,
	logoutUser,
} from "../../../redux/authRedux/authServices";
import { clearPinCreationSuccess } from "../../../redux/authRedux/authActions";
import {
	formatAmountToIncludeCurrency,
	getAmountWithDurationAndInterest,
	getMaturityDate,
} from "../../../common/utils";
import Parse from "../../../api/ApiConfig";
import moment from "moment";
import { createNotification } from "../../../components/notification/CreateNotification";

const SavingsSummary = ({ setActiveTab, onSubmitSavings, savingsUrl }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { currentUser } = useSelector((state) => state.auth);
	const [toggleWalletOptions, setToggleWalletOptions] = useState(false);
	const [activeSummaryTab, setActiveSummaryTab] = useState(1);
	const [activeModal, setActiveModal] = useState(0);

	const [personalPinStatus, setPersonalPinStatus] = useState("");
	const [inputPin, setInputPin] = useState("");
	const [createPin, setCreatePin] = useState("");
	const [confirmPin, setConfirmPin] = useState("");
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [invalidConfirmPinError, setInvalidConfirmPinError] = useState(false);

	const {
		regularSavingsForm,
		createRegularSavingsLoading,
		createRegularSavingsSuccess,
		currentGeneratedinterest,
	} = useSelector((state) => state.lease);

	const interestAmount = getAmountWithDurationAndInterest(
		regularSavingsForm?.interest ||
			currentGeneratedinterest?.value?.replace("%", ""),
		regularSavingsForm?.tenure,
		regularSavingsForm?.amountToSave
	);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 3000);
		}
	}, [inputPin]);

	useEffect(() => {
		if (confirmPin.length < 4) {
			return;
		} else if (confirmPin !== createPin) {
			setInvalidConfirmPinError(true);
			setTimeout(() => {
				setInvalidConfirmPinError(false);
			}, 3000);
		}
	}, [confirmPin]);

	const onProceedToInputPin = () => {
		setActiveModal(2);
		setPersonalPinStatus("");
	};

	const onProceedToCreatePin = () => {
		setActiveModal(2);
		setPersonalPinStatus("create-pin");
	};

	const onCloseBtnClickFromCreatePin = () => {
		setActiveModal(2);
		setPersonalPinStatus("");
	};

	const onProceedToConfirmPin = () => {
		setActiveModal(2);
		setPersonalPinStatus("confirm-pin");
	};

	const onCloseBtnClickFromConfirmPin = () => {
		setActiveModal(2);
		setPersonalPinStatus("create-pin");
	};

	const onCloseModalOnInputPin = () => {
		setActiveModal(0);
		setToggleWalletOptions(false);
	};

	const onCreatePin = () => {
		dispatch(createAccessPin({ accessPin: confirmPin }));
	};

	const onPinCreationSuccess = () => {
		setActiveModal(2);
		setPersonalPinStatus("");
		dispatch(clearPinCreationSuccess());
	};

	const onCardSelect = () => {
		setActiveSummaryTab(2);
		setToggleWalletOptions(false);
		dispatch(selectedPaymentMethod({ mode: "full_investment" }));
	};

	const maturityDate = getMaturityDate(regularSavingsForm?.tenure);

	const user = Parse.User.current();

	const summaryData = {
		...regularSavingsForm,
		userId: user,
		maturityDate,
	};

	const onWalletSelect = () => {
		onProceedToInputPin();
		dispatch(saveRegularSavingsForm(summaryData));
	};

	const onUserSubmitSavings = () => {
		if (user.id) {
			onSubmitSavings();
		} else {
			dispatch(logoutUser());
		}
	};

	useEffect(() => {
		const message = "New Savings Plan Created";
		const metaData = {
			category: regularSavingsForm?.source,
			linkToId: createRegularSavingsSuccess?.objectId,
			moreDetails: `You have created a new regular savings plan for a ${regularSavingsForm?.purpose} purpose.`,
		};

		if (createRegularSavingsSuccess) {
			setActiveModal(3);
			createNotification(message, metaData);
		} else {
			return;
		}
	}, [createRegularSavingsSuccess]);

	const onSavingsCreatedSuccess = () => {
		setToggleWalletOptions(false);
		navigate(`${savingsUrl}/${createRegularSavingsSuccess?.objectId}`);
	};

	return (
		<>
			{toggleWalletOptions && (
				<ModalContainer modal={toggleWalletOptions}>
					<>
						{activeModal === 1 && (
							<PaymentOption
								onClose={() => setToggleWalletOptions(false)}
								onWalletSelect={onWalletSelect}
								onCardSelect={onCardSelect}
							/>
						)}
						{activeModal === 2 && personalPinStatus === "" && (
							<EnterPin
								btnText="proceed"
								onCloseBtnClick={onCloseModalOnInputPin}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								isPinExist={currentUser?.accessPin !== undefined}
								proceedToCreatePin={onProceedToCreatePin}
								onRequestClick={onUserSubmitSavings}
								isPaymentLoading={createRegularSavingsLoading}
							/>
						)}
						{activeModal === 2 && personalPinStatus === "create-pin" && (
							<CreatePin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromCreatePin}
								onInputChange={setCreatePin}
								onRequestClick={onProceedToConfirmPin}
								isAllowedToProceed={createPin.length > 3}
							/>
						)}
						{activeModal === 2 && personalPinStatus === "confirm-pin" && (
							<ConfirmPin
								btnText="proceed"
								onCloseBtnClick={onCloseBtnClickFromConfirmPin}
								onInputChange={setConfirmPin}
								onRequestClick={onCreatePin}
								isAllowedToProceed={
									confirmPin.length > 3 && createPin === confirmPin
								}
								isPinInvalid={invalidConfirmPinError}
							/>
						)}
						{activeModal === 2 &&
							personalPinStatus === "pin creation success" && (
								<SuccessCheckModal
									onRequestClick={onPinCreationSuccess}
									text="Access pin has been created successfully!"
									isShowBtn={true}
									btnText="okay"
								/>
							)}
						{activeModal === 3 && (
							<SuccessCheckModal
								text="Your savings has been created successfully. Proceed to start saving."
								isShowBtn
								onRequestClick={onSavingsCreatedSuccess}
							/>
						)}
					</>
				</ModalContainer>
			)}
			{activeSummaryTab === 1 && (
				<div className="rounded-md p-4 sm:p-5 bg-[#F6F7F8] space-y-4 sm:space-y-5 mb-8">
					<div className="flex items-center gap-2 text-fortcore-green">
						<button onClick={() => setActiveTab(1)}>
							<FaArrowLeft size={15} />
						</button>
						<p className="text-sm md:text-base chillaxFont font-semibold ">
							Summary
						</p>
					</div>
					<LeaseSummaryInput
						input_value={`NGN ${regularSavingsForm?.amountToSave}`}
						text="amount to save"
					/>
					<LeaseSummaryInput
						input_value={regularSavingsForm?.frequency}
						text="frequency"
					/>
					<LeaseSummaryInput
						input_value={regularSavingsForm?.tenure}
						text="tenor"
					/>
					<LeaseSummaryInput
						input_value={regularSavingsForm?.purpose}
						text="purpose"
					/>
					<LeaseSummaryInput
						input_value={moment(maturityDate).format("MMMM Do YYYY")}
						text="maturity date"
					/>
					<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
							Interest Rate (NGN) :
						</p>
						<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative  rounded border border-[#B8B8B8] flex gap-2 zabalRegFont px-2.5">
							<div className="flex items-center justify-between w-full">
								<p>
									<span className="text-[#393F42] zabalMedFont">NGN </span>
									<span>
										{formatAmountToIncludeCurrency(undefined, interestAmount)}
									</span>
								</p>
								<p className="text-fortcore-green zabalMedFont">{`( ${
									regularSavingsForm?.interest ||
									currentGeneratedinterest?.value?.replace("%", "")
								}% P/A)`}</p>
							</div>
							<span className="h-full grid place-items-center">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-2.5 pt-4 sm:hidden">
						<CustomGreenBtn
							text="create savings plan"
							onRequestClick={() => {
								setToggleWalletOptions(true);
								setActiveModal(1);
							}}
						/>
					</div>
					<div className="hidden sm:flex justify-end gap-2.5 pt-4">
						<CustomGreenBtn
							width="175px"
							height="42px"
							fontSize="13px"
							text="create savings plan"
							onRequestClick={() => {
								setToggleWalletOptions(true);
								setActiveModal(1);
							}}
						/>
					</div>
				</div>
			)}
			{activeSummaryTab === 2 && (
				<CardDetails
					setActiveTab={setActiveSummaryTab}
					amountPayable={regularSavingsForm?.amountToSave}
				/>
			)}
		</>
	);
};

export default SavingsSummary;

SavingsSummary.propTypes = {
	setActiveTab: PropTypes.func,
	savingsUrl: PropTypes.string,
	onSubmitSavings: PropTypes.func,
};
