import React from "react";
import PropTypes from "prop-types";
import { IoCloudUpload } from "react-icons/io5";
import MiniLoader from "../layout/miniLoader";
import { FileUploadWrapper } from "../layout/CustomComponents";
import { shortenLongText } from "../common/utils";

const FileUpload = ({
	name,
	isDisabled,
	fileInfo,
	accept,
	isLoading,
	handleChange,
}) => {
	return (
		<FileUploadWrapper>
			{fileInfo ? (
				<a
					href={fileInfo.url}
					target="_blank"
					rel="noreferrer"
					className="truncate"
				>
					{shortenLongText(fileInfo?.fileName, 3)}
				</a>
			) : (
				<p>---</p>
			)}
			<button
				onClick={() => document.getElementById(`${name}`).click()}
				disabled={isLoading || isDisabled}
				style={{ color: isDisabled ? "#AABDC5" : "#003a50" }}
			>
				{isLoading ? <MiniLoader /> : <IoCloudUpload size={20} />}
			</button>
			<input
				type="file"
				id={name}
				name={name}
				style={{ visibility: "hidden", position: "absolute" }}
				disabled={isDisabled || isLoading}
				accept={accept}
				onChange={handleChange}
			/>
		</FileUploadWrapper>
	);
};

FileUpload.propTypes = {
	name: PropTypes.string,
	fileInfo: PropTypes.object,
	accept: PropTypes.string,
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	handleChange: PropTypes.func,
};

export default FileUpload;
