import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Parse from "../../../api/ApiConfig";
import { MdOutlineClose } from "react-icons/md";
import { updateUserPassword } from "../../../redux/authRedux/authServices";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CustomGreenBtn } from "../../../layout/CustomButtons";

const UpdatePassword = ({ onReqClose }) => {
	const dispatch = useDispatch();
	const currentSessionToken = Parse.User.current()?.getSessionToken();
	const { updatingPasswordLoading } = useSelector((state) => state.auth);
	const [passwords, setPasswords] = useState({
		old_password: "",
		new_password: "",
		confirm_password: "",
	});

	const { old_password, new_password, confirm_password } = passwords;

	const handleChange = (e) =>
		setPasswords((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const [toggleOldPassword, setToggleOldPassword] = useState(false);
	const [toggleNewPassword, setToggleNewPassword] = useState(false);
	const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

	const isBtnDisabled = () => {
		if (old_password === "" || new_password === "" || confirm_password === "") {
			return true;
		}
		if (new_password?.length < 8) {
			return true;
		}
		if (new_password !== confirm_password) {
			return true;
		} else {
			return false;
		}
	};

	const onSubmitPasswordInfo = () => {
		const data = {
			password: old_password,
			newPassword: new_password,
		};
		dispatch(updateUserPassword(data, currentSessionToken));
	};

	return (
		<form className="rounded-md bg-white p-4 md:p-5 w-11/12 max-w-[400px] space-y-4">
			<div className="flex justify-between items-center text-fortcore-green">
				<p className="chillaxFont text-[15px] md:text-base font-semibold">
					Update Password
				</p>
				<button onClick={onReqClose}>
					<MdOutlineClose size={20} />
				</button>
			</div>
			<div className="space-y-3">
				<PasswordInput
					text="old password"
					id="old_password"
					value={old_password}
					handleChange={handleChange}
					togglePassword={toggleOldPassword}
					setTogglePassword={() => setToggleOldPassword((prev) => !prev)}
				/>
				<PasswordInput
					text="new password"
					id="new_password"
					value={new_password}
					handleChange={handleChange}
					togglePassword={toggleNewPassword}
					setTogglePassword={() => setToggleNewPassword((prev) => !prev)}
				/>
				<PasswordInput
					text="confirm new password"
					id="confirm_password"
					value={confirm_password}
					handleChange={handleChange}
					togglePassword={toggleConfirmPassword}
					setTogglePassword={() => setToggleConfirmPassword((prev) => !prev)}
				/>
			</div>
			<div className="py-2.5 md:py-2 space-y-3">
				<CustomGreenBtn
					text="update password"
					isDisabled={isBtnDisabled() || updatingPasswordLoading}
					isLoading={updatingPasswordLoading}
					onRequestClick={onSubmitPasswordInfo}
				/>
				<p className="text-xs zabalMedFont text-[#393F42]">
					<span className="font-semibold">Hint: </span> Password must contain at
					least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special
					character.
				</p>
			</div>
		</form>
	);
};

export default UpdatePassword;

UpdatePassword.propTypes = {
	onReqClose: PropTypes.func,
};

export const PasswordInput = ({
	id,
	text,
	value,
	handleChange,
	togglePassword,
	setTogglePassword,
}) => {
	return (
		<div className="flex flex-col gap-1.5">
			<label
				htmlFor={id}
				className="w-full text-[#393F42] text-xs zabalMedFont capitalize"
			>
				{text}
			</label>
			<div className="h-[44px] relative">
				<input
					type={togglePassword ? "text" : "password"}
					className="h-full tracking-wide w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none text-sm lg:text-sm placeholder:text-[#B8B8B8] placeholder:text-[13px] focus:border-2 focus:border-fortcore-green zabalRegFont"
					id={id}
					value={value}
					onChange={handleChange}
					placeholder="Enter password ( min 8 characters)"
					autoComplete="on"
				/>
				<button
					className="absolute right-1.5 h-full w-[30px] grid place-items-center top-0"
					onClick={setTogglePassword}
				>
					{togglePassword ? (
						<FaEye size={16} color="#717E83" />
					) : (
						<FaEyeSlash size={16} color="#717E83" />
					)}
				</button>
			</div>
		</div>
	);
};

PasswordInput.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	togglePassword: PropTypes.bool,
	setTogglePassword: PropTypes.func,
};
