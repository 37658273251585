import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { MdOutlineArrowForward } from "react-icons/md";
import { AiFillFileText } from "react-icons/ai";
import Okra from "okra-js";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import Navbar from "../../../layout/Navbar";
import {
	BackTracker,
	CustomConfirmModal,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import {
	enableCreditAssessment,
	fetchLeaseWithQuery,
	updateInvestorCreatedAsset,
	updateOngoingLeaseInParse,
} from "../../../redux/lease/leaseServices";
import {
	clearStatesOnConfirmLease,
	clearUpdateAccountSuccess,
	closeAuthorizeAutoDebitModal,
	closeAuthorizeCreditAssessmentModal,
	closeAuthorizeInsuranceModal,
	currentUserData,
	openAuthorizeAutoDebitModal,
	openAuthorizeCreditAssessmentModal,
	openAuthorizeInsuranceModal,
} from "../../../redux/authRedux/authActions";
import {
	logoutUser,
	updateUserData,
} from "../../../redux/authRedux/authServices";
import ModalContainer from "../../../components/ModalContainer";
import { shortenLongText, walletType } from "../../../common/utils";
import { useUploadFile } from "../../../components/customhooks/useUploadFile";
import { Preloader } from "../../../components/globalStyled";
import {
	clearCreditAssessmentSuccess,
	clearUpdateLeaseSuccess,
} from "../../../redux/lease/leaseActions";
import { EnterPin } from "../../../components/payments/PinComponent";
import Parse from "../../../api/ApiConfig";
import { deductPaymentFromUserWallet } from "../../../redux/payments/paymentServices";
import { clearPaymentDeductionSuccess } from "../../../redux/payments/paymentActions";
import { createNotification } from "../../../components/notification/CreateNotification";

const ConfirmTransactions = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [resultFromCA, setResultFromCA] = useState(null);

	useEffect(() => {
		dispatch(closeAuthorizeCreditAssessmentModal());
		dispatch(closeAuthorizeAutoDebitModal());
		dispatch(closeAuthorizeInsuranceModal());
	}, []);

	const [pinStatusForCA, setPinStatusForCA] = useState(1);
	const [invalidPinError, setInvalidPinError] = useState(false);
	const [inputPin, setInputPin] = useState("");

	const {
		fetchLeaseWithQueryLoading,
		fetchLeaseWithQuerySuccess,
		updateOngoingTransactionLoading,
		updateOngoingTransactionSuccess,
		savingPersonalFormSuccess,
		ongoingLeaseSubmitSuccess,
		credAssessLoading,
		creditAssessMsg,
	} = useSelector((state) => state.lease);

	const {
		toggleAuthorizeAutoDebitModal,
		toggleAuthorizeInsuranceModal,
		toggleAuthorizeCreditAssessmentModal,
		currentUser,
		updatingUserAccountSuccess,
	} = useSelector((state) => state.auth);

	const { customerWallet, deductWalletLoading, deductWalletSuccess } =
		useSelector((state) => state.payment);

	const accessPin = currentUser?.accessPin || "";

	useEffect(() => {
		if (inputPin.length < 4) {
			return;
		} else if (inputPin !== accessPin) {
			setInvalidPinError(true);
			setTimeout(() => {
				setInvalidPinError(false);
			}, 1500);
		}
	}, [inputPin]);

	const [searchParams] = useSearchParams();
	const query = searchParams?.get("id");

	const [documents, setDocuments] = useState({
		idCard: null,
		utilityBill: null,
		uploadCardLoading: false,
		uploadBillLoading: false,
		idCardString: "",
		utilityBillString: "",
	});

	const {
		idCard,
		utilityBill,
		uploadCardLoading,
		uploadBillLoading,
		idCardString,
		utilityBillString,
	} = documents;

	useEffect(() => {
		if (Parse.User.current()?.id) {
			dispatch(fetchLeaseWithQuery(query, currentUser?.objectId));
		} else {
			dispatch(logoutUser());
		}
	}, [currentUser]);

	// function to update idcard and utility bill on Parse
	const saveDocumentsInParseObject = async (data) => {
		try {
			const query = new Parse.Query("User");
			const object = await query.get(Parse.User.current()?.id);
			const updatedObject = await object.save(data);
			// eslint-disable-next-line no-undef
			console.log(updatedObject.toJSON());
			dispatch(currentUserData(updatedObject));

			const message = "Document upload successful";
			const metaData = {
				category: "User",
				linkToId: updatedObject?.id,
				moreDetails: `You have successfully uploaded a new document.`,
			};

			if (updatedObject) {
				createNotification(message, metaData);
			}
			toast.success("File upload is successful.");
			setDocuments((prev) => ({
				...prev,
				uploadCardLoading: false,
				uploadBillLoading: false,
				idCardString: "",
				utilityBillString: "",
			}));
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error);
			setDocuments((prev) => ({
				...prev,
				uploadCardLoading: false,
				uploadBillLoading: false,
				idCard: null,
				utilityBill: null,
			}));
			toast.error("Failed to upload document.");
		}
	};

	// destructure document string from hook
	const { loading: idCardLoadingOne, fileUploadSuccess: idCardUploadSuccess } =
		useUploadFile(idCard);

	const {
		loading: utilityBillLoadingOne,
		fileUploadSuccess: utilityBillUploadSuccess,
	} = useUploadFile(utilityBill);

	// set id card string to state
	useEffect(() => {
		if (idCardUploadSuccess) {
			setDocuments((prev) => ({
				...prev,
				idCard: null,
				idCardString: idCardUploadSuccess,
			}));
		}
	}, [idCardUploadSuccess]);

	// set utility string to state
	useEffect(() => {
		if (utilityBillUploadSuccess) {
			setDocuments((prev) => ({
				...prev,
				utilityBill: null,
				utilityBillString: utilityBillUploadSuccess,
			}));
		}
	}, [utilityBillUploadSuccess]);

	// upload id card to parse
	useEffect(() => {
		if (!idCardString) {
			return;
		} else {
			setDocuments((prev) => ({ ...prev, uploadCardLoading: true }));
			saveDocumentsInParseObject({ idCard: idCardString });
		}
	}, [idCardString]);

	// upload utility bill to Parse
	useEffect(() => {
		if (!utilityBillString) {
			return;
		} else {
			setDocuments((prev) => ({ ...prev, uploadBillLoading: true }));
			saveDocumentsInParseObject({ utilityBill: utilityBillString });
		}
	}, [utilityBillString]);

	const enableAutoDebit = () => {
		dispatch(
			updateOngoingLeaseInParse(query, { autoDebit: true }, "update-autoDebit")
		);
	};

	const enableInsurance = () => {
		dispatch(
			updateOngoingLeaseInParse(
				query,
				{ insurance: "enabled" },
				"update-insurance"
			)
		);
	};

	const closeAutoDebitSuccessModal = () => {
		dispatch(closeAuthorizeAutoDebitModal());
		dispatch(clearUpdateLeaseSuccess());
	};

	const closeInsuranceSuccessModal = () => {
		dispatch(closeAuthorizeInsuranceModal());
		dispatch(clearUpdateLeaseSuccess());
	};

	const closeCreditAssessmentSuccess = () => {
		dispatch(closeAuthorizeCreditAssessmentModal());
		dispatch(clearUpdateAccountSuccess());
		dispatch(clearPaymentDeductionSuccess());
		dispatch(clearCreditAssessmentSuccess());
		setResultFromCA(null);
	};

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const amountPayable = process.env.NODE_ENV !== "production" ? 1 : 100;

	// const onSuccess = (data) => {
	// 	setResultFromCA(data);
	// 	setPinStatusForCA(2);
	// 	console.log(data);
	// 	toast.success("Account connected successfully for credit assessment.");
	// };

	const onClose = () => {
		console.log(resultFromCA);
		// console.log(resultFromCA);
		// if (resultFromCA) {
		// 	console.log("done", resultFromCA);
		// 	// dispatch(closeAuthorizeCreditAssessmentModal());
		// 	setPinStatusForCA(2);
		// } else {
		// 	console.log("not-done");
		// 	// dispatch(closeAuthorizeCreditAssessmentModal());
		// 	// toast.error("You have closed the credit assessment gateway.");
		// }
	};

	console.log(
		pinStatusForCA,
		resultFromCA,
		toggleAuthorizeCreditAssessmentModal
	);

	// validate credit assessment with okra
	const onStartCreditAssessmentWithOkra = () => {
		if (mainWallet?.availableBalance < amountPayable) {
			toast.error("Insuffient balance. Please fund your wallet!");
			return;
		}
		Okra.buildWithOptions({
			name: "Okra React Widget",
			env: "production",
			app_id: "", // app_id from your app builder
			key: process.env.REACT_APP_OKRA_KEY,
			token: process.env.REACT_APP_OKRA_TOKEN,
			products: ["auth", "identity", "balance", "transactions", "income"],
			// preselect_bank: {
			// 	bank: "guaranty-trust-bank",
			// 	type: "personal",
			// 	platform: "bank",
			// 	// app: 'gtbank',
			// },
			onSuccess: (data) => {
				console.log(data);
				setPinStatusForCA(2);
				setResultFromCA(data);
			},
			onClose: onClose,
			onError: (error) => {
				console.log(error);
				toast.error("Failed to authorize credit assessment.");
				dispatch(closeAuthorizeCreditAssessmentModal());
			},
		});
	};

	// do actual credit assessment
	const activateCreditAssessment = () => {
		dispatch(enableCreditAssessment(query));
	};

	// deduct funds for credit assessment
	const onDeductFundsForCA = () => {
		const details = {
			walletId: savingPersonalFormSuccess?.get("walletId"),
			amount: amountPayable,
			narration: "credit-assessment",
		};

		dispatch(
			deductPaymentFromUserWallet(
				details,
				"credit-assessment-payment",
				"credit assessment"
			)
		);
	};

	console.log(resultFromCA);

	// check if all condition valid before lease submission
	const isAllConditionValidToSubmit = () => {
		const conditions = {
			idCard: currentUser?.idCard,
			utilityBill: currentUser?.utilityBill,
			autodebit: fetchLeaseWithQuerySuccess?.get("autoDebit"),
			insurance: fetchLeaseWithQuerySuccess?.get("insurance"),
			guarantor:
				fetchLeaseWithQuerySuccess?.get("leaseFor") === "business"
					? true
					: !!fetchLeaseWithQuerySuccess?.get("guarantor"),
			creditAssessment: currentUser?.creditAssessment,
		};

		if (Object.values(conditions).every((a) => !!a)) {
			return true;
		} else {
			return false;
		}
	};

	// const assetId = fetchLeaseWithQuerySuccess?.get("assetId");
	const assetId = fetchLeaseWithQuerySuccess?.get("assetObj");

	const assetDataToUpdate = {
		usersRequest: [
			...(assetId?.usersRequest || []),
			{
				name: `${currentUser?.firstName} ${currentUser?.lastName}`,
				monthlyPayment: fetchLeaseWithQuerySuccess?.get("monthlyPayment"),
				transactionId: fetchLeaseWithQuerySuccess?.id,
				status: "pending",
				date: new Date().toISOString(),
			},
		],
	};

	// on submit ongoing lease
	const onSubmitOngoingLease = () => {
		dispatch(
			updateOngoingLeaseInParse(
				query,
				{
					state: "requested",
					// guarantor: guarantorsFormSuccess?.toJSON(),
					usersRequest: assetId ? assetDataToUpdate?.usersRequest : [],
				},
				undefined,
				"update-lease-to-submit"
			)
		);
		if (assetId) {
			// apppend lease to asset for investor.
			const message = "New Lease Request.";
			const metaData = {
				category: "Asset",
				linkToId: assetId.id,
				moreDetails: `A leasee with the userID of ${currentUser?.objectId} has submitted a request on this asset with the transactionID of ${assetId}. Kindly look through, before approving this request.`,
			};
			const user = assetId?.userId;

			const data = {
				usersRequest: assetDataToUpdate?.usersRequest,
			};

			dispatch(
				updateInvestorCreatedAsset(assetId?.id, data, message, metaData, user)
			);
		}
	};

	// deduct funds on successful credit assessment
	useEffect(() => {
		if (creditAssessMsg === "credit assessment success") {
			onDeductFundsForCA();
			dispatch(clearCreditAssessmentSuccess());
			setResultFromCA(null);
		}
	}, [creditAssessMsg]);

	// update CA on ongoing lease transaction on deduction success
	useEffect(() => {
		if (deductWalletSuccess === "credit-assessment-payment") {
			const data = { creditAssessment: resultFromCA };

			dispatch(updateUserData(data, "credit-assessment-update"));
			dispatch(clearPaymentDeductionSuccess());
		} else {
			return;
		}
	}, [deductWalletSuccess]);

	useEffect(() => {
		if (resultFromCA) {
			dispatch(openAuthorizeCreditAssessmentModal());
			setPinStatusForCA(2);
		}
	}, [resultFromCA]);

	// show success modal for credit assessment
	useEffect(() => {
		if (updatingUserAccountSuccess === "credit-assessment-update") {
			dispatch(clearPaymentDeductionSuccess());
			setPinStatusForCA(3);
		} else {
			return;
		}
	}, [updatingUserAccountSuccess]);

	if (!query) {
		return <Navigate to="/leasing/ongoing-transactions" />;
	}

	const [submitData, setSubmitData] = useState(false);

	useEffect(() => {
		if (ongoingLeaseSubmitSuccess) {
			setSubmitData(true);
			dispatch(clearStatesOnConfirmLease());
		} else {
			return;
		}
	}, [ongoingLeaseSubmitSuccess]);

	const onSubmitSuccess = () => {
		navigate("/leasing/submitted-transactions");
		setSubmitData(false);
	};

	return (
		<>
			{submitData && (
				<ModalContainer modal={submitData}>
					<SuccessCheckModal
						text="Your lease request has been submitted successfully"
						btnText="Proceed to View"
						onRequestClick={onSubmitSuccess}
						isShowBtn
					/>
				</ModalContainer>
			)}
			{toggleAuthorizeAutoDebitModal && (
				<ModalContainer modal={toggleAuthorizeAutoDebitModal}>
					<>
						{updateOngoingTransactionSuccess === "update-autoDebit" ? (
							<SuccessCheckModal
								text="Auto Debit Authorized Successfully"
								onRequestClick={closeAutoDebitSuccessModal}
							/>
						) : (
							<CustomConfirmModal
								text="Authorize Auto Debit on your bank account?"
								greenBtnText="authorize"
								onCloseBtnClick={() => dispatch(closeAuthorizeAutoDebitModal())}
								greenBtnClick={enableAutoDebit}
								isLoading={updateOngoingTransactionLoading}
							/>
						)}
					</>
				</ModalContainer>
			)}
			{toggleAuthorizeInsuranceModal && (
				<ModalContainer modal={toggleAuthorizeInsuranceModal}>
					<>
						{updateOngoingTransactionSuccess === "update-insurance" ? (
							<SuccessCheckModal
								text="Insurance Enabled Successfully"
								onRequestClick={closeInsuranceSuccessModal}
							/>
						) : (
							<CustomConfirmModal
								text="Proceed to to apply for insurance?"
								greenBtnText="Proceed"
								onCloseBtnClick={() => dispatch(closeAuthorizeInsuranceModal())}
								greenBtnClick={enableInsurance}
								isLoading={updateOngoingTransactionLoading}
							/>
						)}
					</>
				</ModalContainer>
			)}
			{toggleAuthorizeCreditAssessmentModal && (
				<ModalContainer modal={toggleAuthorizeCreditAssessmentModal}>
					<>
						{pinStatusForCA == 1 && (
							<CustomConfirmModal
								text={`N${amountPayable} will be deducted from your wallet for Credit Assessment. Bank selected must correspond with that on your profile.`}
								greenBtnText="Proceed"
								onCloseBtnClick={() =>
									dispatch(closeAuthorizeCreditAssessmentModal())
								}
								greenBtnClick={onStartCreditAssessmentWithOkra}
							/>
						)}
						{pinStatusForCA === 2 && (
							<EnterPin
								btnText="Proceed"
								onCloseBtnClick={() => {
									dispatch(closeAuthorizeCreditAssessmentModal());
									setPinStatusForCA(1);
								}}
								onInputChange={setInputPin}
								isPinInvalid={invalidPinError}
								isAllowedToProceed={
									inputPin.length > 3 && inputPin === accessPin
								}
								isPinExist={currentUser?.accessPin !== undefined}
								proceedToCreatePin={() =>
									navigate("/dashboard/profile", { state: { currentTab: 2 } })
								}
								isPaymentLoading={deductWalletLoading || credAssessLoading}
								onRequestClick={activateCreditAssessment}
							/>
						)}
						{pinStatusForCA === 3 && (
							<SuccessCheckModal
								text="Credit Assessment is successful."
								onRequestClick={closeCreditAssessmentSuccess}
							/>
						)}
					</>
				</ModalContainer>
			)}
			<Helmet>
				<title>Fortcore - Ongoing Transactions</title>
			</Helmet>
			<MobileNavDetails text="ongoing transactions" />
			<Container>
				<>
					<Navbar text="ongoing transactions" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker
							text="ongoing transactions"
							onNavigate={() => navigate("/leasing/ongoing-transactions")}
						/>
						<UrlTracker text="confirm lease" isCurrentUrl />
					</div>
					<BackTracker
						text="leasing"
						onNavigate={() => navigate("/leasing/ongoing-transactions")}
					/>
					<div className="zabalRegFont my-6 mb-10 sm:my-10 md:my-12 lg:my-14 space-y-2.5 md:space-y-3 w-full max-w-[450px] sm:w-10/12 sm:max-w-[500px] lg:max-w-[550px] xl:max-w-[600px] mx-auto">
						<p className="text-sm text-fortcore-green font-semibold">
							Final Step!
						</p>
						{fetchLeaseWithQueryLoading ? (
							<Skeleton className="mt-2 w-full h-[19px] max-w-[400px]" />
						) : fetchLeaseWithQuerySuccess ? (
							<p className="text-xs md:text-[13px] text-[#717E83]">
								{fetchLeaseWithQuerySuccess?.get("state") === "requested" ? (
									<span>
										This transaction has already been submitted.{" "}
										<span
											className="text-fortcore-green zabalMedFont cursor-pointer"
											onClick={() =>
												navigate("/leasing/submitted-transactions")
											}
										>
											View Now
										</span>
									</span>
								) : (
									"Complete the action required below to submit this transaction"
								)}
							</p>
						) : (
							<p className="text-[13px] text-[#717E83]">
								No transaction with{" "}
								<span className="zabalMedFont text-black">{query}</span> is
								found.
							</p>
						)}
						{fetchLeaseWithQueryLoading ? (
							<Skeleton className="mt-2 h-[18px] w-3/5 max-w-[300px]" />
						) : fetchLeaseWithQuerySuccess ? (
							<p className="text-[#393F42]">
								<span className="text-xs">Transaction ID: </span>
								<span className="text-[12.5px] zabalMedFont">TRN-{query}</span>
							</p>
						) : (
							<Link
								to="/leasing/new-transaction"
								className="text-primary-color zabalMedFont text-xs"
							>
								<span>Create a new transaction?</span>
							</Link>
						)}
						{fetchLeaseWithQueryLoading
							? null
							: fetchLeaseWithQuerySuccess && (
									<div className="grid gap-2.5 md:gap-3">
										<UploadCard
											text="ID Card"
											name="idCard"
											handleChange={(e) =>
												setDocuments((prev) => ({
													...prev,
													idCard: e.target.files[0],
												}))
											}
											isGetStringLoading={idCardLoadingOne}
											uploadIdLoading={uploadCardLoading}
											docUrl={currentUser?.idCard}
										/>
										<UploadCard
											text="Utility Bill"
											name="utilityBill"
											handleChange={(e) =>
												setDocuments((prev) => ({
													...prev,
													utilityBill: e.target.files[0],
												}))
											}
											isGetStringLoading={utilityBillLoadingOne}
											uploadIdLoading={uploadBillLoading}
											docUrl={currentUser?.utilityBill}
										/>
										<ConfirmLeaseStep
											text="auto debit"
											onRequestClick={() =>
												dispatch(openAuthorizeAutoDebitModal())
											}
											isValidated={fetchLeaseWithQuerySuccess?.get("autoDebit")}
										/>
										<ConfirmLeaseStep
											text="insurance"
											onRequestClick={() =>
												dispatch(openAuthorizeInsuranceModal())
											}
											isValidated={
												!!fetchLeaseWithQuerySuccess?.get("insurance")
											}
										/>
										{fetchLeaseWithQuerySuccess?.get("leaseFor") ===
											"personal" && (
											<ConfirmLeaseStep
												text="guarantor"
												onRequestClick={() =>
													navigate(`/leasing/${query}/guarantors`)
												}
												isValidated={
													!!fetchLeaseWithQuerySuccess?.get("guarantor")
												}
												// isValidated={!!guarantorsFormSuccess}
											/>
										)}
										<ConfirmLeaseStep
											text="credit assessment"
											onRequestClick={() => {
												setPinStatusForCA(1);
												dispatch(openAuthorizeCreditAssessmentModal());
												setResultFromCA(null);
											}}
											// isValidated={!!currentUser?.creditAssessment}
										/>
										{isAllConditionValidToSubmit() &&
											fetchLeaseWithQuerySuccess?.get("state") !==
												"requested" && (
												<div className="flex justify-end pt-4">
													<button
														className="flex items-center gap-1 text-fortcore-green zabalMedFont text-sm capitalize tracking-wide capitalize"
														onClick={onSubmitOngoingLease}
														disabled={updateOngoingTransactionLoading}
													>
														{updateOngoingTransactionLoading ? (
															"submitting..."
														) : (
															<>
																<span>submit</span>
																<MdOutlineArrowForward size={18} />
															</>
														)}
													</button>
												</div>
											)}
									</div>
							  )}
					</div>
				</>
			</Container>
		</>
	);
};
export default ConfirmTransactions;

const ConfirmLeaseStep = ({ text, isValidated, onRequestClick }) => {
	return (
		<button
			className="flex items-center justify-between border border-[#E3E9EC] rounded-md py-4 px-2.5 sm:px-3 md:px-4 md:py-6 lg:py-7"
			onClick={() => {
				if (isValidated) return false;
				onRequestClick();
			}}
			disabled={isValidated}
		>
			<span className="text-[13.5px] md:text-sm capitalize text-[#393F42] zabalRegFont font-semibold">
				{text}
			</span>
			{!isValidated && (
				<span className="flex items-center gap-1 text-[#FF7676] text-[12.5px] md:text-[13px] zabalMedfont">
					<TbAlertTriangleFilled size={20} />
					<span className="leading-4 w-[60px]">Action Required</span>
				</span>
			)}
		</button>
	);
};

const UploadCard = ({
	text,
	name,
	handleChange,
	docUrl,
	isGetStringLoading,
	uploadIdLoading,
}) => {
	const isLoading = () => {
		if (isGetStringLoading && uploadIdLoading) {
			return true;
		}
		if (isGetStringLoading) {
			return true;
		}
		if (uploadIdLoading) {
			return true;
		}
		if (isGetStringLoading && !uploadIdLoading) {
			return true;
		}
		if (uploadIdLoading && !isGetStringLoading) {
			return true;
		} else if (!uploadIdLoading && !isGetStringLoading) {
			return false;
		}
	};

	return (
		<div className="flex items-center justify-between border border-[#E3E9EC] rounded-md p-2.5 py-3 sm:px-3 sm:py-4 md:px-4 md:py-5 relative">
			<p className="text-[13.5px] md:text-[14.5px] capitalize text-[#393F42] zabalRegFont font-semibold">
				{text} <span className="text-red-500">*</span>
			</p>
			<div className="flex gap-2">
				{isLoading() ? (
					<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
						<Preloader />
					</div>
				) : (
					<>
						{docUrl && (
							<a
								href={docUrl}
								target="_blank"
								rel="noreferrer"
								className="zabalMedFont text-xs md:text-[12.5px] text-blue-800 font-semibold sm:font-medium"
							>
								{shortenLongText(docUrl)}
							</a>
						)}
						<button
							className="flex items-center gap-1 text-xs text-fortcore-green"
							onClick={() => document.getElementById(`${name}`).click()}
						>
							<AiFillFileText size={15} />
							<span>{docUrl ? "Change" : "Add"} File</span>
						</button>
						<input
							type="file"
							name={name}
							className="hidden"
							id={name}
							onChange={handleChange}
							accept="image/*,.pdf"
						/>
					</>
				)}
			</div>
		</div>
	);
};

ConfirmLeaseStep.propTypes = {
	text: PropTypes.string.isRequired,
	isValidated: PropTypes.bool,
	onRequestClick: PropTypes.func,
};
UploadCard.propTypes = {
	text: PropTypes.string.isRequired,
	name: PropTypes.string,
	handleChange: PropTypes.func,
	docUrl: PropTypes.string,
	isGetStringLoading: PropTypes.bool,
	uploadIdLoading: PropTypes.bool,
};
