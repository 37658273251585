import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../../layout/Navbar";
import { Helmet } from "react-helmet";
import Balance from "../../components/Balance";
import Container from "../../layout/Container";
import { CustomTabSelector } from "../../layout/CustomComponents";
import TabAccounts from "../../components/TabAccounts";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import { walletType } from "../../common/utils";
import LeaseOverview from "./LeaseOverview";

const Leasing = () => {
	const { state } = useLocation();
	const [activeTab, setActiveTab] = useState(state?.currentTab || 1);
	const { customerWallet } = useSelector((state) => state.payment);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	return (
		<>
			<MobileNavDetails text="leasing" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Leasing</title>
					</Helmet>
					<Navbar text="leasing" />
					<div className="mt-1 sm:mt-[55px]"></div>
					<Balance
						wallet={mainWallet}
						showAddFunds
						showWithdrawFunds
						showMoveFunds
					/>
					<div className="my-8 lg:my-10">
						<CustomTabSelector
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
					</div>
					<div className="mb-6">
						{activeTab === 1 && <LeaseOverview />}
						{activeTab === 2 && <TabAccounts />}
					</div>
				</>
			</Container>
		</>
	);
};
export default Leasing;
