import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Modal, Typography } from "@mui/material";
import { InfoPop } from "../../../../../../layout/assets";

export function ModalPop({ title, btnText, modal, setModal, onClick }) {
	const handleClose = () => setModal(false);

	return (
		<Modal open={modal} onClose={handleClose}>
			<Box sx={style}>
				<Box display="flex" justifyContent="center" mb={2}>
					<InfoPop />
				</Box>
				<Typography variant="body1" color="primary" align="center">
					{title}
				</Typography>
				<Box display="flex" justifyContent="center" mt={3}>
					<Button variant="contained" color="primary" onClick={onClick}>
						{btnText}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}

ModalPop.propTypes = {
	title: PropTypes.string.isRequired,
	btnText: PropTypes.string.isRequired,
	modal: PropTypes.bool.isRequired,
	setModal: PropTypes.func,
	onClick: PropTypes.func,
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "#fff",
	boxShadow: 24,
	borderRadius: "24px",
	p: 4,
};
