import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { onToggleViewFinanceReqModal } from "../../redux/financing/financeActions";
import { MdClose } from "react-icons/md";
import moment from "moment";
import { formatLeaseeStatus } from "../../common/utils";
import MiniLoader from "../../layout/miniLoader";
import { FaCheck, FaTimes } from "react-icons/fa";

const ViewFinanceRequests = ({
	requests,
	// onActionSelect,
	approveLoaders,
	rejectLoaders,
	onApproveRequest,
	onRejectRequest,
}) => {
	const dispatch = useDispatch();

	const onClose = () => dispatch(onToggleViewFinanceReqModal(false));

	const formatName = (name) => {
		const splitName = name?.split(" ").filter((a) => a.length > 2);
		return splitName?.map((a) => a.charAt()).join("");
	};

	const isApprovedReq = requests?.find((req) => req?.status === "approved");

	return (
		<div className="py-4 md:py-5 rounded-lg bg-white w-11/12 max-w-[500px]">
			<div className="flex items-center justify-between text-primary-color px-4 md:px-5">
				<h4 className="text-[15px] md:text-lg zabalBoldFont">Users Requests</h4>
				<button onClick={onClose}>
					<MdClose size={20} />
				</button>
			</div>
			{isApprovedReq ? (
				<p className="text-green-600 text-xs md:text-[13px] px-5 md:px-5 mt-1 mb-4">
					You have approved one request.
				</p>
			) : (
				<p className="text-red-500 text-xs md:text-[13px] px-5 md:px-5 mt-1 mb-4">
					NB: You can only approve one request
				</p>
			)}
			<div className="mt-4 md:mt-5 px-4 md:px-5 max-h-[300px] overflow-y-auto scroll-wrapper grid gap-3 md:gap-4">
				{requests?.map((req, index) => (
					<div
						className="flex justify-between gap-8 md:gap-5 border rounded-md p-2.5 md:p-3 min-h-[115px]"
						key={index}
					>
						<div className="text-[13px] flex flex-col gap-1">
							<div className="text-[13px] flex gap-1.5 md:gap-2 items-center">
								<div className="text-[11.5px] uppercase zabalBoldFont bg-[#BDBDBD66] text-fortcore-green rounded-full h-[27px] w-[27px] grid place-items-center p-1">
									<p>{formatName(req?.name)}</p>
								</div>
								<p className="zabalMedFont">{req?.name}</p>
							</div>
							<div className="flex text-[12.5px]">
								<p>
									<span className="text-xs text-gray-500">Transaction ID:</span>{" "}
									{req?.transactionId}
								</p>
							</div>
							<div className="flex text-[12.5px]">
								<p>
									<span className="text-xs text-gray-500">Date Requested:</span>{" "}
									{moment(req?.date).format("MMM Do, YYYY")}
								</p>
							</div>
							<div
								className="flex text-[12.5px]"
								style={{
									color: formatLeaseeStatus(req?.status)?.color,
								}}
							>
								<p>
									<span className="text-xs text-gray-500 capitalize">
										Approval Status:
									</span>{" "}
									{formatLeaseeStatus(req?.status)?.status}
								</p>
							</div>
							<div className="flex text-[12.5px]">
								<p>
									<span className="text-xs text-gray-500">
										Business Description:
									</span>{" "}
									<span className="zabalMedFont">
										{req?.businessInfo?.description}
									</span>
								</p>
							</div>
							<div className="flex text-[12.5px]">
								<p>
									<span className="text-xs text-gray-500">
										Business Purpose:
									</span>{" "}
									<span className="zabalMedFont">
										{req?.businessInfo?.purpose}
									</span>
								</p>
							</div>
						</div>
						{req?.status === "pending" && (
							<div className="flex flex-col justify-center gap-5">
								<button
									className={`h-[28px] w-[28px] flex items-center justify-center rounded-full text-white ${
										approveLoaders[req?.transactionId]
											? "bg-stone-300"
											: "bg-fortcore-green"
									}`}
									onClick={() =>
										onApproveRequest("approved", req?.transactionId)
									}
									disabled={
										approveLoaders[req?.transactionId] ||
										rejectLoaders[req?.transactionId]
									}
								>
									{approveLoaders[req?.transactionId] ? (
										<MiniLoader />
									) : (
										<FaCheck size={15} />
									)}
								</button>
								<button
									className={`h-[28px] w-[28px] flex items-center justify-center rounded-full text-white ${
										rejectLoaders[req?.transactionId]
											? "bg-stone-300"
											: "bg-red-500"
									}`}
									onClick={() =>
										onRejectRequest("rejected", req?.transactionId)
									}
									disabled={
										rejectLoaders[req?.transactionId] ||
										approveLoaders[req?.transactionId]
									}
								>
									{rejectLoaders[req?.transactionId] ? (
										<MiniLoader />
									) : (
										<FaTimes size={15} />
									)}
								</button>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

ViewFinanceRequests.propTypes = {
	requests: PropTypes.array,
	// onActionSelect: PropTypes.func,
	loaders: PropTypes.object,
	approveLoaders: PropTypes.object,
	rejectLoaders: PropTypes.object,
	onApproveRequest: PropTypes.func,
	onRejectRequest: PropTypes.func,
};

export default ViewFinanceRequests;
