import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Container from "../../../layout/Container"
import { Helmet } from "react-helmet"
import Navbar from "../../../layout/Navbar"
import { MobileNavDetails } from "../../../layout/MobileNavbar"
import CreateSaving from "./CreateSaving"
import { UrlTracker } from "../../../layout/CustomComponents"
import ChooseSaving from "./ChooseSaving"
import SelectedSavingsDetails from "./SelectedSavingsDetails"
import AssetSavingsSummary from "./AssetSavingsSummary"

const AssetBasedContainer = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [activeTab, setActiveTab] = useState(state?.currentTab || 1)

  return (
    <>
      <Helmet>
        <title>Fortcore - Asset Based Savings</title>
      </Helmet>
      <MobileNavDetails text='savings' />
      <Container>
        <>
          <Navbar text='leasing' />
          <div className="sm:pt-9 md:pt-10 "></div>
          <div className="flex gap-1.5">
            <UrlTracker
              text="leasing"
              onNavigate={() => navigate("/leasing")}
            />
            <UrlTracker text="asset based savings" isCurrentUrl />
          </div>
          <div className='mb-10 sm:my-6'>
            {activeTab === 1 && <CreateSaving setActiveTab={setActiveTab} />}
            {activeTab === 2 && <ChooseSaving setActiveTab={setActiveTab} />}
            {activeTab === 3 && <SelectedSavingsDetails setActiveTab={setActiveTab} />}
            {activeTab === 4 && <AssetSavingsSummary setActiveTab={setActiveTab} />}
          </div>
        </>
      </Container>
    </>
  )
}
export default AssetBasedContainer