import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { PlainSelect, SelectDropDown } from "../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../layout/CustomButtons";
import { useDispatch, useSelector } from "react-redux";
import {
	closeAddBvnModal,
	onToggleActivateWalletModal,
	saveAddressFromBVN,
} from "../redux/authRedux/authActions";
import { toast } from "react-toastify";
import { verifyBVN } from "../redux/authRedux/authServices";
import moment from "moment";
import PropTypes from "prop-types";

const AddBvnModal = ({ activateWallet }) => {
	const dispatch = useDispatch();
	const [toggleGender, setToggleGender] = useState(false);
	const { verifyBvnLoading } = useSelector((state) => state.auth);
	const [bvnData, setBvnData] = useState({
		bvn: "",
		dob: "",
		gender: "",
		homeAddress: "",
	});
	const { bvn, dob, gender, homeAddress } = bvnData;

	const verifyBtn = () => {
		if (bvn.length < 11) {
			return true;
		} else if (isNaN(bvn)) {
			return true;
		} else if (dob === "") {
			return true;
		}
		if (activateWallet && homeAddress === "") {
			return true;
		}
		if (gender === "") {
			return true;
		} else {
			return false;
		}
	};

	const handleAddBvn = () => {
		if (bvn === "" || dob === "") {
			toast.error("Fields cannot be empty!");
			return;
		} else if (bvn.length < 11) {
			console.log(bvn);
			toast.error("BVN must equal 11 characters");
			return;
		} else {
			dispatch(verifyBVN({ bvn }));
			dispatch(saveAddressFromBVN(homeAddress));
		}
	};

	const onClose = () => {
		if (activateWallet) {
			dispatch(onToggleActivateWalletModal(false));
		}
		dispatch(closeAddBvnModal());
	};

	const handleChange = (e) =>
		setBvnData((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	return (
		<div className="space-y-5 bg-white rounded-2xl sm:rounded-md py-6 px-5 w-10/12 max-w-[450px]">
			<div className="flex items-center justify-between text-fortcore-green zabalRegFont">
				<h2 className="text-[15px] font-semibold">Add BVN</h2>
				<button onClick={onClose}>
					<MdOutlineClose size={20} />
				</button>
			</div>
			<div className="space-y-3">
				<div className="flex flex-col gap-1">
					<label className="text-xs zabalMedFont" htmlFor="bvn">
						BVN
					</label>
					<input
						type="tel"
						maxLength={11}
						className="text-[text-[#393F42] border rounded border-[#B8B8B8] px-2.5 h-[43px] outline-none text-[15px] zabalRegFont focus:border-2 focus:border-fortcore-green text-[#393F42] placeholder:text-[#B8B8B8]"
						id="bvn"
						placeholder="Enter Your BVN"
						value={bvn}
						onChange={(e) => {
							if (isNaN(e.target.value)) return;
							setBvnData((prev) => ({ ...prev, bvn: e.target.value }));
						}}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<label className="text-xs zabalMedFont" htmlFor="dob">
						Date of Birth
					</label>
					<input
						type="date"
						className={`border rounded border-[#B8B8B8] px-2.5 h-[43px] outline-none text-sm zabalRegFont w-full bg-transparent focus:border-2 focus:border-fortcore-green ${
							dob === "" ? "text-[#B8B8B8]" : "text-[#393F42]"
						}`}
						onFocus={(e) => (e.target.type = "date")}
						id="dob"
						value={dob}
						onChange={handleChange}
						max={moment().format("YYYY-MM-DD")}
					/>
				</div>

				<div className="flex flex-col gap-1">
					<p className="text-xs zabalMedFont">Gender</p>
					<div className="w-full relative">
						<PlainSelect
							context="choose gender"
							isOpened={toggleGender}
							currentText={gender}
							handleToggle={() => setToggleGender((prev) => !prev)}
							height="43px"
						/>
						{toggleGender && (
							<div className="absolute z-[2] w-full top-48px">
								<SelectDropDown
									arrOptions={["Male", "Female", "Prefer not to say"]}
									onHandleSelect={(d) => {
										setBvnData((prev) => ({ ...prev, gender: d }));
										// eslint-disable-next-line no-undef
										setTimeout(() => {
											setToggleGender(false);
										}, 100);
									}}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<label className="text-xs zabalMedFont" htmlFor="homeAddress">
						Home Address
					</label>
					<input
						type="text"
						className="border rounded border-[#B8B8B8] px-2.5 h-[43px] outline-none text-sm zabalRegFont w-full bg-transparent focus:border-2 focus:border-fortcore-green"
						id="homeAddress"
						value={homeAddress}
						onChange={handleChange}
					/>
				</div>
			</div>
			<div className="hidden sm:flex justify-end gap-2">
				<CustomWhiteBtn
					text="cancel"
					width="105px"
					fontSize="12.25px"
					height="40px"
					onRequestClick={() => dispatch(closeAddBvnModal())}
				/>
				<CustomGreenBtn
					text="verify"
					width="105px"
					fontSize="12.25px"
					height="40px"
					isDisabled={verifyBtn() || verifyBvnLoading}
					isLoading={verifyBvnLoading}
					onRequestClick={handleAddBvn}
				/>
			</div>
			<div className="pt-4 flex flex-col justify-end gap-2.5 sm:hidden">
				<CustomGreenBtn
					text="verify"
					isDisabled={verifyBtn() || verifyBvnLoading}
					isLoading={verifyBvnLoading}
					onRequestClick={handleAddBvn}
				/>
				<CustomWhiteBtn
					text="cancel"
					onRequestClick={() => dispatch(closeAddBvnModal())}
				/>
			</div>
		</div>
	);
};
export default AddBvnModal;

AddBvnModal.propTypes = {
	activateWallet: PropTypes.bool,
};
