import moment from "moment";

export const formatPhoneNumber = (phone) => {
	const countryCode = phone?.slice(0, 3);
	return phone?.slice(3).startsWith(0)
		? `${countryCode}${phone?.slice(4)}`
		: phone;
};

export const validateEmail = (email) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(email);
};

export const formatAmountToIncludeCurrency = (currency, amount) => {
	return currency
		? `${currency} ${Number(amount).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })}`
		: `${Number(amount).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })}`;
};

export const getSubmittedLeaseStatus = (status) => {
	switch (status) {
		case "awaiting delivery":
			return 1;
		case "payment deducted":
			return 2;
		case "lease agreed":
			return 3;
		default:
			return 10;
	}
};

export const shortenLongText = (text, length = 7) => {
	const firstPart = text?.slice(0, 6);
	const lastPart = text?.slice(text?.length - length, text?.length);
	return `${firstPart}...${lastPart}`;
};

export const getProfilePercentage = (userData) => {
	if (!userData) {
		return 0;
	} else {
		const truthyValues = Object.values(userData).filter((a) => a).length;
		return Math.round(truthyValues * 4.35);
	}
};

export function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if (
			(areObjects && !deepEqual(val1, val2)) ||
			(!areObjects && val1 !== val2)
		) {
			return false;
		}
	}

	return true;
}

function isObject(object) {
	return object != null && typeof object === "object";
}

export const BUSINESSTYPE = [
	"Restaurant",
	"Retail Store",
	"Technology",
	"Consulting Firm",
	"Construction",
	"Healthcare",
	"Transportation Service",
	"Financial Institutions",
	"Media and Entertainment",
	"Agriculture",
	"Automotive Industry",
	"Fashion Retailer",
	"Education Institution",
	"E-commerce Business",
	"Manufacturing Company",
	"Others",
];

export const educationQualification = [
	{ schoolLevels: "High School", degrees: ["Senior School Cerificate (SSCE)"] },
	{ schoolLevels: "Vocational Centers", degrees: ["Vocational Degrees"] },
	{
		schoolLevels: "UnderGraduate",
		degrees: [
			"Nigerian Certificate of Education (NCE)",
			"National Diploma (OND)",
			"Higher National Diploma (HND)",
			"Bachelor's Degree (BSc, BA, BEd, LLB etc)",
			"MBBS/MBChB",
		],
	},
	{
		schoolLevels: "PostGraduate",
		degrees: [
			"Master's Degree (MSc, MA, MBA, MPhil etc)",
			"PhDs (DBA, M.D., Eng.D. etc)",
		],
	},
];

export const educationLevel = [
	"Primary",
	"Junior Secondary",
	"Senior Secondary",
	"Vocation",
	"Tertiary",
];

export const getFamilyCount = (text) => {
	switch (text) {
		case "None":
			return 0;
		case "1":
			return 1;
		case "2":
			return 2;
		case "3":
			return 3;
		case "4":
			return 4;
		case "Above 4":
			return 5;
	}
};

export const onInputAmount = (e) => {
	e.target.value = e.target.value
		.replace(/[^\dA-Z]/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const onBlurAmount = (e, amount) => {
	e.target.value = formatAmountToIncludeCurrency(
		undefined,
		amount.replace(/,/g, "") || 0
	);
};

export const stateAndLga = [
	{
		state: "Adamawa",
		alias: "adamawa",
		lgas: [
			"Demsa",
			"Fufure",
			"Ganye",
			"Gayuk",
			"Gombi",
			"Grie",
			"Hong",
			"Jada",
			"Larmurde",
			"Madagali",
			"Maiha",
			"Mayo Belwa",
			"Michika",
			"Mubi North",
			"Mubi South",
			"Numan",
			"Shelleng",
			"Song",
			"Toungo",
			"Yola North",
			"Yola South",
		],
		postalCode: "640001",
	},
	{
		state: "Akwa Ibom",
		alias: "akwa_ibom",
		lgas: [
			"Abak",
			"Eastern Obolo",
			"Eket",
			"Esit Eket",
			"Essien Udim",
			"Etim Ekpo",
			"Etinan",
			"Ibeno",
			"Ibesikpo Asutan",
			"Ibiono-Ibom",
			"Ikot Abasi",
			"Ika",
			"Ikono",
			"Ikot Ekpene",
			"Ini",
			"Mkpat-Enin",
			"Itu",
			"Mbo",
			"Nsit-Atai",
			"Nsit-Ibom",
			"Nsit-Ubium",
			"Obot Akara",
			"Okobo",
			"Onna",
			"Oron",
			"Udung-Uko",
			"Ukanafun",
			"Oruk Anam",
			"Uruan",
			"Urue-Offong/Oruko",
			"Uyo",
		],
		postalCode: "520001",
	},
	{
		state: "Anambra",
		alias: "anambra",
		lgas: [
			"Aguata",
			"Anambra East",
			"Anaocha",
			"Awka North",
			"Anambra West",
			"Awka South",
			"Ayamelum",
			"Dunukofia",
			"Ekwusigo",
			"Idemili North",
			"Idemili South",
			"Ihiala",
			"Njikoka",
			"Nnewi North",
			"Nnewi South",
			"Ogbaru",
			"Onitsha North",
			"Onitsha South",
			"Orumba North",
			"Orumba South",
			"Oyi",
		],
		postalCode: "420001",
	},
	{
		state: "Ogun",
		alias: "ogun",
		lgas: [
			"Abeokuta North",
			"Abeokuta South",
			"Ado-Odo/Ota",
			"Egbado North",
			"Ewekoro",
			"Egbado South",
			"Ijebu North",
			"Ijebu East",
			"Ifo",
			"Ijebu Ode",
			"Ijebu North East",
			"Imeko Afon",
			"Ikenne",
			"Ipokia",
			"Odeda",
			"Obafemi Owode",
			"Odogbolu",
			"Remo North",
			"Ogun Waterside",
			"Shagamu",
		],
		postalCode: "110001",
	},
	{
		state: "Ondo",
		alias: "ondo",
		lgas: [
			"Akoko North-East",
			"Akoko North-West",
			"Akoko South-West",
			"Akoko South-East",
			"Akure North",
			"Akure South",
			"Ese Odo",
			"Idanre",
			"Ifedore",
			"Ilaje",
			"Irele",
			"Ile Oluji/Okeigbo",
			"Odigbo",
			"Okitipupa",
			"Ondo West",
			"Ose",
			"Ondo East",
			"Owo",
		],
		postalCode: "340001",
	},
	{
		state: "Rivers",
		alias: "rivers",
		lgas: [
			"Abua/Odual",
			"Ahoada East",
			"Ahoada West",
			"Andoni",
			"Akuku-Toru",
			"Asari-Toru",
			"Bonny",
			"Degema",
			"Emuoha",
			"Eleme",
			"Ikwerre",
			"Etche",
			"Gokana",
			"Khana",
			"Obio/Akpor",
			"Ogba/Egbema/Ndoni",
			"Ogu/Bolo",
			"Okrika",
			"Omuma",
			"Opobo/Nkoro",
			"Oyigbo",
			"Port Harcourt",
			"Tai",
		],
		postalCode: "500001",
	},
	{
		state: "Bauchi",
		alias: "bauchi",
		lgas: [
			"Alkaleri",
			"Bauchi",
			"Bogoro",
			"Damban",
			"Darazo",
			"Dass",
			"Gamawa",
			"Ganjuwa",
			"Giade",
			"Itas/Gadau",
			"Jama'are",
			"Katagum",
			"Kirfi",
			"Misau",
			"Ningi",
			"Shira",
			"Tafawa Balewa",
			"Toro",
			"Warji",
			"Zaki",
		],
		postalCode: "740001",
	},
	{
		state: "Benue",
		alias: "benue",
		lgas: [
			"Agatu",
			"Apa",
			"Ado",
			"Buruku",
			"Gboko",
			"Guma",
			"Gwer East",
			"Gwer West",
			"Katsina-Ala",
			"Konshisha",
			"Kwande",
			"Logo",
			"Makurdi",
			"Obi",
			"Ogbadibo",
			"Ohimini",
			"Oju",
			"Okpokwu",
			"Oturkpo",
			"Tarka",
			"Ukum",
			"Ushongo",
			"Vandeikya",
		],
		postalCode: "970001",
	},
	{
		state: "Borno",
		alias: "borno",
		lgas: [
			"Abadam",
			"Askira/Uba",
			"Bama",
			"Bayo",
			"Biu",
			"Chibok",
			"Damboa",
			"Dikwa",
			"Guzamala",
			"Gubio",
			"Hawul",
			"Gwoza",
			"Jere",
			"Kaga",
			"Kala/Balge",
			"Konduga",
			"Kukawa",
			"Kwaya Kusar",
			"Mafa",
			"Magumeri",
			"Maiduguri",
			"Mobbar",
			"Marte",
			"Monguno",
			"Ngala",
			"Nganzai",
			"Shani",
		],
		postalCode: "600001",
	},
	{
		state: "Bayelsa",
		alias: "bayelsa",
		lgas: [
			"Brass",
			"Ekeremor",
			"Kolokuma/Opokuma",
			"Nembe",
			"Ogbia",
			"Sagbama",
			"Southern Ijaw",
			"Yenagoa",
		],
		postalCode: "561001",
	},
	{
		state: "Cross River",
		alias: "cross_river",
		lgas: [
			"Abi",
			"Akamkpa",
			"Akpabuyo",
			"Bakassi",
			"Bekwarra",
			"Biase",
			"Boki",
			"Calabar Municipal",
			"Calabar South",
			"Etung",
			"Ikom",
			"Obanliku",
			"Obubra",
			"Obudu",
			"Odukpani",
			"Ogoja",
			"Yakuur",
			"Yala",
		],
		postalCode: "540001",
	},
	{
		state: "Delta",
		alias: "delta",
		lgas: [
			"Aniocha North",
			"Aniocha South",
			"Bomadi",
			"Burutu",
			"Ethiope West",
			"Ethiope East",
			"Ika North East",
			"Ika South",
			"Isoko North",
			"Isoko South",
			"Ndokwa East",
			"Ndokwa West",
			"Okpe",
			"Oshimili North",
			"Oshimili South",
			"Patani",
			"Sapele",
			"Udu",
			"Ughelli North",
			"Ukwuani",
			"Ughelli South",
			"Uvwie",
			"Warri North",
			"Warri South",
			"Warri South West",
		],
		postalCode: "320001",
	},
	{
		state: "Ebonyi",
		alias: "ebonyi",
		lgas: [
			"Abakaliki",
			"Afikpo North",
			"Ebonyi",
			"Afikpo South",
			"Ezza North",
			"Ikwo",
			"Ezza South",
			"Ivo",
			"Ishielu",
			"Izzi",
			"Ohaozara",
			"Ohaukwu",
			"Onicha",
		],
		postalCode: "840001",
	},
	{
		state: "Edo",
		alias: "edo",
		lgas: [
			"Akoko-Edo",
			"Egor",
			"Esan Central",
			"Esan North-East",
			"Esan South-East",
			"Esan West",
			"Etsako Central",
			"Etsako East",
			"Etsako West",
			"Igueben",
			"Ikpoba Okha",
			"Orhionmwon",
			"Oredo",
			"Ovia North-East",
			"Ovia South-West",
			"Owan East",
			"Owan West",
			"Uhunmwonde",
		],
		postalCode: "300001",
	},
	{
		state: "Ekiti",
		alias: "ekiti",
		lgas: [
			"Ado Ekiti",
			"Efon",
			"Ekiti East",
			"Ekiti South-West",
			"Ekiti West",
			"Emure",
			"Gbonyin",
			"Ido Osi",
			"Ijero",
			"Ikere",
			"Ilejemeje",
			"Irepodun/Ifelodun",
			"Ikole",
			"Ise/Orun",
			"Moba",
			"Oye",
		],
		postalCode: "360001",
	},
	{
		state: "Enugu",
		alias: "enugu",
		lgas: [
			"Awgu",
			"Aninri",
			"Enugu East",
			"Enugu North",
			"Ezeagu",
			"Enugu South",
			"Igbo Etiti",
			"Igbo Eze North",
			"Igbo Eze South",
			"Isi Uzo",
			"Nkanu East",
			"Nkanu West",
			"Nsukka",
			"Udenu",
			"Oji River",
			"Uzo Uwani",
			"Udi",
		],
		postalCode: "400001",
	},
	{
		state: "Federal Capital Territory",
		alias: "abuja",
		lgas: [
			"Abaji",
			"Bwari",
			"Gwagwalada",
			"Kuje",
			"Kwali",
			"Municipal Area Council",
		],
		postalCode: "900001",
	},
	{
		state: "Gombe",
		alias: "gombe",
		lgas: [
			"Akko",
			"Balanga",
			"Billiri",
			"Dukku",
			"Funakaye",
			"Gombe",
			"Kaltungo",
			"Kwami",
			"Nafada",
			"Shongom",
			"Yamaltu/Deba",
		],
		postalCode: "760001",
	},
	{
		state: "Jigawa",
		alias: "jigawa",
		lgas: [
			"Auyo",
			"Babura",
			"Buji",
			"Biriniwa",
			"Birnin Kudu",
			"Dutse",
			"Gagarawa",
			"Garki",
			"Gumel",
			"Guri",
			"Gwaram",
			"Gwiwa",
			"Hadejia",
			"Jahun",
			"Kafin Hausa",
			"Kazaure",
			"Kiri Kasama",
			"Kiyawa",
			"Kaugama",
			"Maigatari",
			"Malam Madori",
			"Miga",
			"Sule Tankarkar",
			"Roni",
			"Ringim",
			"Yankwashi",
			"Taura",
		],
		postalCode: "720001",
	},
	{
		state: "Oyo",
		alias: "oyo",
		lgas: [
			"Afijio",
			"Akinyele",
			"Atiba",
			"Atisbo",
			"Egbeda",
			"Ibadan North",
			"Ibadan North-East",
			"Ibadan North-West",
			"Ibadan South-East",
			"Ibarapa Central",
			"Ibadan South-West",
			"Ibarapa East",
			"Ido",
			"Ibarapa North",
			"Irepo",
			"Iseyin",
			"Itesiwaju",
			"Iwajowa",
			"Kajola",
			"Lagelu",
			"Ogbomosho North",
			"Ogbomosho South",
			"Ogo Oluwa",
			"Olorunsogo",
			"Oluyole",
			"Ona Ara",
			"Orelope",
			"Ori Ire",
			"Oyo",
			"Oyo East",
			"Saki East",
			"Saki West",
			"Surulere Oyo State",
		],
		postalCode: "200001",
	},
	{
		state: "Imo",
		alias: "imo",
		lgas: [
			"Aboh Mbaise",
			"Ahiazu Mbaise",
			"Ehime Mbano",
			"Ezinihitte",
			"Ideato North",
			"Ideato South",
			"Ihitte/Uboma",
			"Ikeduru",
			"Isiala Mbano",
			"Mbaitoli",
			"Isu",
			"Ngor Okpala",
			"Njaba",
			"Nkwerre",
			"Nwangele",
			"Obowo",
			"Oguta",
			"Ohaji/Egbema",
			"Okigwe",
			"Orlu",
			"Orsu",
			"Oru East",
			"Oru West",
			"Owerri Municipal",
			"Owerri North",
			"Unuimo",
			"Owerri West",
		],
		postalCode: "460001",
	},
	{
		state: "Kaduna",
		alias: "kaduna",
		postalCode: "700001",
		lgas: [
			"Birnin Gwari",
			"Chikun",
			"Giwa",
			"Ikara",
			"Igabi",
			"Jaba",
			"Jema'a",
			"Kachia",
			"Kaduna North",
			"Kaduna South",
			"Kagarko",
			"Kajuru",
			"Kaura",
			"Kauru",
			"Kubau",
			"Kudan",
			"Lere",
			"Makarfi",
			"Sabon Gari",
			"Sanga",
			"Soba",
			"Zangon Kataf",
			"Zaria",
		],
	},
	{
		state: "Kebbi",
		alias: "kebbi",
		postalCode: "860001",
		lgas: [
			"Aleiro",
			"Argungu",
			"Arewa Dandi",
			"Augie",
			"Bagudo",
			"Birnin Kebbi",
			"Bunza",
			"Dandi",
			"Fakai",
			"Gwandu",
			"Jega",
			"Kalgo",
			"Koko/Besse",
			"Maiyama",
			"Ngaski",
			"Shanga",
			"Suru",
			"Sakaba",
			"Wasagu/Danko",
			"Yauri",
			"Zuru",
		],
	},
	{
		state: "Kano",
		alias: "kano",
		postalCode: "800001",
		lgas: [
			"Ajingi",
			"Albasu",
			"Bagwai",
			"Bebeji",
			"Bichi",
			"Bunkure",
			"Dala",
			"Dambatta",
			"Dawakin Kudu",
			"Dawakin Tofa",
			"Doguwa",
			"Fagge",
			"Gabasawa",
			"Garko",
			"Garun Mallam",
			"Gezawa",
			"Gaya",
			"Gwale",
			"Gwarzo",
			"Kabo",
			"Kano Municipal",
			"Karaye",
			"Kibiya",
			"Kiru",
			"Kumbotso",
			"Kunchi",
			"Kura",
			"Madobi",
			"Makoda",
			"Minjibir",
			"Nasarawa",
			"Rano",
			"Rimin Gado",
			"Rogo",
			"Shanono",
			"Takai",
			"Sumaila",
			"Tarauni",
			"Tofa",
			"Tsanyawa",
			"Tudun Wada",
			"Ungogo",
			"Warawa",
			"Wudil",
		],
	},
	{
		state: "Kogi",
		alias: "kogi",
		postalCode: "260001",
		lgas: [
			"Ajaokuta",
			"Adavi",
			"Ankpa",
			"Bassa",
			"Dekina",
			"Ibaji",
			"Idah",
			"Igalamela Odolu",
			"Ijumu",
			"Kogi",
			"Kabba/Bunu",
			"Lokoja",
			"Ofu",
			"Mopa Muro",
			"Ogori/Magongo",
			"Okehi",
			"Okene",
			"Olamaboro",
			"Omala",
			"Yagba East",
			"Yagba West",
		],
	},
	{
		state: "Osun",
		alias: "osun",
		postalCode: "230001",
		lgas: [
			"Aiyedire",
			"Atakunmosa West",
			"Atakunmosa East",
			"Aiyedaade",
			"Boluwaduro",
			"Boripe",
			"Ife East",
			"Ede South",
			"Ife North",
			"Ede North",
			"Ife South",
			"Ejigbo",
			"Ife Central",
			"Ifedayo",
			"Egbedore",
			"Ila",
			"Ifelodun",
			"Ilesa East",
			"Ilesa West",
			"Irepodun",
			"Irewole",
			"Isokan",
			"Iwo",
			"Obokun",
			"Odo Otin",
			"Ola Oluwa",
			"Olorunda",
			"Oriade",
			"Orolu",
			"Osogbo",
		],
	},
	{
		state: "Sokoto",
		alias: "sokoto",
		postalCode: "840001",
		lgas: [
			"Gudu",
			"Gwadabawa",
			"Illela",
			"Isa",
			"Kebbe",
			"Kware",
			"Rabah",
			"Sabon Birni",
			"Shagari",
			"Silame",
			"Sokoto North",
			"Sokoto South",
			"Tambuwal",
			"Tangaza",
			"Tureta",
			"Wamako",
			"Wurno",
			"Yabo",
			"Binji",
			"Bodinga",
			"Dange Shuni",
			"Goronyo",
			"Gada",
		],
	},
	{
		state: "Plateau",
		alias: "plateau",
		postalCode: "930001",
		lgas: [
			"Bokkos",
			"Barkin Ladi",
			"Bassa",
			"Jos East",
			"Jos North",
			"Jos South",
			"Kanam",
			"Kanke",
			"Langtang South",
			"Langtang North",
			"Mangu",
			"Mikang",
			"Pankshin",
			"Qua'an Pan",
			"Riyom",
			"Shendam",
			"Wase",
		],
	},
	{
		state: "Taraba",
		alias: "taraba",
		postalCode: "660001",
		lgas: [
			"Ardo Kola",
			"Bali",
			"Donga",
			"Gashaka",
			"Gassol",
			"Ibi",
			"Jalingo",
			"Karim Lamido",
			"Kumi",
			"Lau",
			"Sardauna",
			"Takum",
			"Ussa",
			"Wukari",
			"Yorro",
			"Zing",
		],
	},
	{
		state: "Yobe",
		alias: "yobe",
		postalCode: "320001",
		lgas: [
			"Bade",
			"Bursari",
			"Damaturu",
			"Fika",
			"Fune",
			"Geidam",
			"Gujba",
			"Gulani",
			"Jakusko",
			"Karasuwa",
			"Machina",
			"Nangere",
			"Nguru",
			"Potiskum",
			"Tarmuwa",
			"Yunusari",
			"Yusufari",
		],
	},
	{
		state: "Zamfara",
		alias: "zamfara",
		postalCode: "860001",
		lgas: [
			"Anka",
			"Birnin Magaji/Kiyaw",
			"Bakura",
			"Bukkuyum",
			"Bungudu",
			"Gummi",
			"Gusau",
			"Kaura Namoda",
			"Maradun",
			"Shinkafi",
			"Maru",
			"Talata Mafara",
			"Tsafe",
			"Zurmi",
		],
	},
	{
		state: "Lagos",
		alias: "lagos",
		postalCode: "100001",
		lgas: [
			"Agege",
			"Ajeromi-Ifelodun",
			"Alimosho",
			"Amuwo-Odofin",
			"Badagry",
			"Apapa",
			"Epe",
			"Eti Osa",
			"Ibeju-Lekki",
			"Ifako-Ijaiye",
			"Ikeja",
			"Ikorodu",
			"Kosofe",
			"Lagos Island",
			"Mushin",
			"Lagos Mainland",
			"Ojo",
			"Oshodi-Isolo",
			"Shomolu",
			"Surulere",
		],
	},
	{
		state: "Katsina",
		alias: "katsina",
		postalCode: "820001",
		lgas: [
			"Bakori",
			"Batagarawa",
			"Batsari",
			"Baure",
			"Bindawa",
			"Charanchi",
			"Danja",
			"Dandume",
			"Dan Musa",
			"Daura",
			"Dutsi",
			"Dutsin Ma",
			"Faskari",
			"Funtua",
			"Ingawa",
			"Jibia",
			"Kafur",
			"Kaita",
			"Kankara",
			"Kankia",
			"Katsina",
			"Kurfi",
			"Kusada",
			"Mai'Adua",
			"Malumfashi",
			"Mani",
			"Mashi",
			"Matazu",
			"Musawa",
			"Rimi",
			"Sabuwa",
			"Safana",
			"Sandamu",
			"Zango",
		],
	},
	{
		state: "Kwara",
		alias: "kwara",
		postalCode: "240001",
		lgas: [
			"Asa",
			"Baruten",
			"Edu",
			"Ilorin East",
			"Ifelodun",
			"Ilorin South",
			"Ekiti Kwara State",
			"Ilorin West",
			"Irepodun",
			"Isin",
			"Kaiama",
			"Moro",
			"Offa",
			"Oke Ero",
			"Oyun",
			"Pategi",
		],
	},
	{
		state: "Nasarawa",
		alias: "nasarawa",
		postalCode: "962001",
		lgas: [
			"Akwanga",
			"Awe",
			"Doma",
			"Karu",
			"Keana",
			"Keffi",
			"Lafia",
			"Kokona",
			"Nasarawa Egon",
			"Nasarawa",
			"Obi",
			"Toto",
			"Wamba",
		],
	},
	{
		state: "Niger",
		alias: "niger",
		postalCode: "920001",
		lgas: [
			"Agaie",
			"Agwara",
			"Bida",
			"Borgu",
			"Bosso",
			"Chanchaga",
			"Edati",
			"Gbako",
			"Gurara",
			"Katcha",
			"Kontagora",
			"Lapai",
			"Lavun",
			"Mariga",
			"Magama",
			"Mokwa",
			"Mashegu",
			"Moya",
			"Paikoro",
			"Rafi",
			"Rijau",
			"Shiroro",
			"Suleja",
			"Tafa",
			"Wushishi",
		],
	},
	{
		state: "Abia",
		alias: "abia",
		postalCode: "440001",
		lgas: [
			"Aba North",
			"Arochukwu",
			"Aba South",
			"Bende",
			"Isiala Ngwa North",
			"Ikwuano",
			"Isiala Ngwa South",
			"Isuikwuato",
			"Obi Ngwa",
			"Ohafia",
			"Osisioma",
			"Ugwunagbo",
			"Ukwa East",
			"Ukwa West",
			"Umuahia North",
			"Umuahia South",
			"Umu Nneochi",
		],
	},
];

export const countries = [
	{ name: "Afghanistan", code: "AF" },
	{ name: "Åland Islands", code: "AX" },
	{ name: "Albania", code: "AL" },
	{ name: "Algeria", code: "DZ" },
	{ name: "American Samoa", code: "AS" },
	{ name: "AndorrA", code: "AD" },
	{ name: "Angola", code: "AO" },
	{ name: "Anguilla", code: "AI" },
	{ name: "Antarctica", code: "AQ" },
	{ name: "Antigua and Barbuda", code: "AG" },
	{ name: "Argentina", code: "AR" },
	{ name: "Armenia", code: "AM" },
	{ name: "Aruba", code: "AW" },
	{ name: "Australia", code: "AU" },
	{ name: "Austria", code: "AT" },
	{ name: "Azerbaijan", code: "AZ" },
	{ name: "Bahamas", code: "BS" },
	{ name: "Bahrain", code: "BH" },
	{ name: "Bangladesh", code: "BD" },
	{ name: "Barbados", code: "BB" },
	{ name: "Belarus", code: "BY" },
	{ name: "Belgium", code: "BE" },
	{ name: "Belize", code: "BZ" },
	{ name: "Benin", code: "BJ" },
	{ name: "Bermuda", code: "BM" },
	{ name: "Bhutan", code: "BT" },
	{ name: "Bolivia", code: "BO" },
	{ name: "Bosnia and Herzegovina", code: "BA" },
	{ name: "Botswana", code: "BW" },
	{ name: "Bouvet Island", code: "BV" },
	{ name: "Brazil", code: "BR" },
	{ name: "British Indian Ocean Territory", code: "IO" },
	{ name: "Brunei Darussalam", code: "BN" },
	{ name: "Bulgaria", code: "BG" },
	{ name: "Burkina Faso", code: "BF" },
	{ name: "Burundi", code: "BI" },
	{ name: "Cambodia", code: "KH" },
	{ name: "Cameroon", code: "CM" },
	{ name: "Canada", code: "CA" },
	{ name: "Cape Verde", code: "CV" },
	{ name: "Cayman Islands", code: "KY" },
	{ name: "Central African Republic", code: "CF" },
	{ name: "Chad", code: "TD" },
	{ name: "Chile", code: "CL" },
	{ name: "China", code: "CN" },
	{ name: "Christmas Island", code: "CX" },
	{ name: "Cocos (Keeling) Islands", code: "CC" },
	{ name: "Colombia", code: "CO" },
	{ name: "Comoros", code: "KM" },
	{ name: "Congo", code: "CG" },
	{ name: "Congo, The Democratic Republic of the", code: "CD" },
	{ name: "Cook Islands", code: "CK" },
	{ name: "Costa Rica", code: "CR" },
	{ name: "Croatia", code: "HR" },
	{ name: "Cuba", code: "CU" },
	{ name: "Cyprus", code: "CY" },
	{ name: "Czech Republic", code: "CZ" },
	{ name: "Denmark", code: "DK" },
	{ name: "Djibouti", code: "DJ" },
	{ name: "Dominica", code: "DM" },
	{ name: "Dominican Republic", code: "DO" },
	{ name: "Ecuador", code: "EC" },
	{ name: "Egypt", code: "EG" },
	{ name: "El Salvador", code: "SV" },
	{ name: "Equatorial Guinea", code: "GQ" },
	{ name: "Eritrea", code: "ER" },
	{ name: "Estonia", code: "EE" },
	{ name: "Ethiopia", code: "ET" },
	{ name: "Falkland Islands (Malvinas)", code: "FK" },
	{ name: "Faroe Islands", code: "FO" },
	{ name: "Fiji", code: "FJ" },
	{ name: "Finland", code: "FI" },
	{ name: "France", code: "FR" },
	{ name: "French Guiana", code: "GF" },
	{ name: "French Polynesia", code: "PF" },
	{ name: "French Southern Territories", code: "TF" },
	{ name: "Gabon", code: "GA" },
	{ name: "Gambia", code: "GM" },
	{ name: "Georgia", code: "GE" },
	{ name: "Germany", code: "DE" },
	{ name: "Ghana", code: "GH" },
	{ name: "Gibraltar", code: "GI" },
	{ name: "Greece", code: "GR" },
	{ name: "Greenland", code: "GL" },
	{ name: "Grenada", code: "GD" },
	{ name: "Guadeloupe", code: "GP" },
	{ name: "Guam", code: "GU" },
	{ name: "Guatemala", code: "GT" },
	{ name: "Guernsey", code: "GG" },
	{ name: "Guinea", code: "GN" },
	{ name: "Guinea-Bissau", code: "GW" },
	{ name: "Guyana", code: "GY" },
	{ name: "Haiti", code: "HT" },
	{ name: "Heard Island and Mcdonald Islands", code: "HM" },
	{ name: "Holy See (Vatican City State)", code: "VA" },
	{ name: "Honduras", code: "HN" },
	{ name: "Hong Kong", code: "HK" },
	{ name: "Hungary", code: "HU" },
	{ name: "Iceland", code: "IS" },
	{ name: "India", code: "IN" },
	{ name: "Indonesia", code: "ID" },
	{ name: "Iran, Islamic Republic Of", code: "IR" },
	{ name: "Iraq", code: "IQ" },
	{ name: "Ireland", code: "IE" },
	{ name: "Isle of Man", code: "IM" },
	{ name: "Israel", code: "IL" },
	{ name: "Italy", code: "IT" },
	{ name: "Jamaica", code: "JM" },
	{ name: "Japan", code: "JP" },
	{ name: "Jersey", code: "JE" },
	{ name: "Jordan", code: "JO" },
	{ name: "Kazakhstan", code: "KZ" },
	{ name: "Kenya", code: "KE" },
	{ name: "Kiribati", code: "KI" },
	{ name: "Korea, Republic of", code: "KR" },
	{ name: "Kuwait", code: "KW" },
	{ name: "Kyrgyzstan", code: "KG" },
	{ name: "Latvia", code: "LV" },
	{ name: "Lebanon", code: "LB" },
	{ name: "Lesotho", code: "LS" },
	{ name: "Liberia", code: "LR" },
	{ name: "Libyan Arab Jamahiriya", code: "LY" },
	{ name: "Liechtenstein", code: "LI" },
	{ name: "Lithuania", code: "LT" },
	{ name: "Luxembourg", code: "LU" },
	{ name: "Macao", code: "MO" },
	{ name: "North Macedonia", code: "MK" },
	{ name: "Madagascar", code: "MG" },
	{ name: "Malawi", code: "MW" },
	{ name: "Malaysia", code: "MY" },
	{ name: "Maldives", code: "MV" },
	{ name: "Mali", code: "ML" },
	{ name: "Malta", code: "MT" },
	{ name: "Marshall Islands", code: "MH" },
	{ name: "Martinique", code: "MQ" },
	{ name: "Mauritania", code: "MR" },
	{ name: "Mauritius", code: "MU" },
	{ name: "Mayotte", code: "YT" },
	{ name: "Mexico", code: "MX" },
	{ name: "Micronesia, Federated States of", code: "FM" },
	{ name: "Moldova, Republic of", code: "MD" },
	{ name: "Monaco", code: "MC" },
	{ name: "Mongolia", code: "MN" },
	{ name: "Montserrat", code: "MS" },
	{ name: "Morocco", code: "MA" },
	{ name: "Mozambique", code: "MZ" },
	{ name: "Myanmar", code: "MM" },
	{ name: "Namibia", code: "NA" },
	{ name: "Nauru", code: "NR" },
	{ name: "Nepal", code: "NP" },
	{ name: "Netherlands", code: "NL" },
	{ name: "Netherlands Antilles", code: "AN" },
	{ name: "New Caledonia", code: "NC" },
	{ name: "New Zealand", code: "NZ" },
	{ name: "Nicaragua", code: "NI" },
	{ name: "Niger", code: "NE" },
	{ name: "Nigeria", code: "NG" },
	{ name: "Niue", code: "NU" },
	{ name: "Norfolk Island", code: "NF" },
	{ name: "Northern Mariana Islands", code: "MP" },
	{ name: "Norway", code: "NO" },
	{ name: "Oman", code: "OM" },
	{ name: "Pakistan", code: "PK" },
	{ name: "Palau", code: "PW" },
	{ name: "Palestinian Territory, Occupied", code: "PS" },
	{ name: "Panama", code: "PA" },
	{ name: "Papua New Guinea", code: "PG" },
	{ name: "Paraguay", code: "PY" },
	{ name: "Peru", code: "PE" },
	{ name: "Philippines", code: "PH" },
	{ name: "Pitcairn Islands", code: "PN" },
	{ name: "Poland", code: "PL" },
	{ name: "Portugal", code: "PT" },
	{ name: "Puerto Rico", code: "PR" },
	{ name: "Qatar", code: "QA" },
	{ name: "Reunion", code: "RE" },
	{ name: "Romania", code: "RO" },
	{ name: "Russian Federation", code: "RU" },
	{ name: "Rwanda", code: "RW" },
	{ name: "Saint Helena", code: "SH" },
	{ name: "Saint Kitts and Nevis", code: "KN" },
	{ name: "Saint Lucia", code: "LC" },
	{ name: "Saint Pierre and Miquelon", code: "PM" },
	{ name: "Saint Vincent and the Grenadines", code: "VC" },
	{ name: "Samoa", code: "WS" },
	{ name: "San Marino", code: "SM" },
	{ name: "Sao Tome and Principe", code: "ST" },
	{ name: "Saudi Arabia", code: "SA" },
	{ name: "Senegal", code: "SN" },
	{ name: "Serbia and Montenegro", code: "CS" },
	{ name: "Seychelles", code: "SC" },
	{ name: "Sierra Leone", code: "SL" },
	{ name: "Singapore", code: "SG" },
	{ name: "Slovakia", code: "SK" },
	{ name: "Slovenia", code: "SI" },
	{ name: "Solomon Islands", code: "SB" },
	{ name: "Somalia", code: "SO" },
	{ name: "South Africa", code: "ZA" },
	{ name: "South Georgia and the South Sandwich Islands", code: "GS" },
	{ name: "Spain", code: "ES" },
	{ name: "Sri Lanka", code: "LK" },
	{ name: "Sudan", code: "SD" },
	{ name: "Suriname", code: "SR" },
	{ name: "Svalbard and Jan Mayen", code: "SJ" },
	{ name: "Swaziland", code: "SZ" },
	{ name: "Sweden", code: "SE" },
	{ name: "Switzerland", code: "CH" },
	{ name: "Syrian Arab Republic", code: "SY" },
	{ name: "Taiwan", code: "TW" },
	{ name: "Tajikistan", code: "TJ" },
	{ name: "Tanzania, United Republic of", code: "TZ" },
	{ name: "Thailand", code: "TH" },
	{ name: "Timor-Leste", code: "TL" },
	{ name: "Togo", code: "TG" },
	{ name: "Tokelau", code: "TK" },
	{ name: "Tonga", code: "TO" },
	{ name: "Trinidad and Tobago", code: "TT" },
	{ name: "Tunisia", code: "TN" },
	{ name: "Turkey", code: "TR" },
	{ name: "Turkmenistan", code: "TM" },
	{ name: "Turks and Caicos Islands", code: "TC" },
	{ name: "Tuvalu", code: "TV" },
	{ name: "Uganda", code: "UG" },
	{ name: "Ukraine", code: "UA" },
	{ name: "United Arab Emirates", code: "AE" },
	{ name: "United Kingdom", code: "GB" },
	{ name: "United States", code: "US" },
	{ name: "United States Minor Outlying Islands", code: "UM" },
	{ name: "Uruguay", code: "UY" },
	{ name: "Uzbekistan", code: "UZ" },
	{ name: "Vanuatu", code: "VU" },
	{ name: "Venezuela", code: "VE" },
	{ name: "Vietnam", code: "VN" },
	{ name: "Virgin Islands, British", code: "VG" },
	{ name: "Virgin Islands, U.S.", code: "VI" },
	{ name: "Wallis and Futuna", code: "WF" },
	{ name: "Western Sahara", code: "EH" },
	{ name: "Yemen", code: "YE" },
	{ name: "Zambia", code: "ZM" },
	{ name: "Zimbabwe", code: "ZN" },
];

export const netWorthRange = [
	"N1,000,000 - N5,000,000",
	"N6,000,000 - N10,000,000",
	"N10,000,000 - N20,000,000",
	"Above N20,000,000",
];

export const getMaintenanceFee = (assetValue, shelfLife, productCategory) => {
	if (productCategory?.toLowerCase() !== "vehicle") {
		return 0;
	}
	const percentage = 10 / 100;
	const VAT = 7.5 / 100;
	const newShelfLife =
		shelfLife?.length > 2 ? shelfLife.slice(0, 2) : shelfLife;
	const shelfLifeInYears = Number(newShelfLife) / 12;
	const totalAssetValue = Number(assetValue) * (1 + VAT);

	const annualMaintenaceFee =
		percentage * totalAssetValue * (shelfLifeInYears / 12);
	const monthlyMaintenanceFee = annualMaintenaceFee / 12;

	return monthlyMaintenanceFee;
};

export const removeDuplicatesInData = (array) => {
	let newArray = array?.reduce(function (accumulator, curValue) {
		if (accumulator.indexOf(curValue) === -1) {
			accumulator.push(curValue);
		}
		return accumulator;
	}, []);
	return newArray;
};

export const getSavingsPath = (savingsType) => {
	switch (savingsType) {
		case "lease":
			return "leasing";
		case "AF":
			return "financing";
		case "travel":
			return "travels";
		default:
			return "leasing";
	}
};

export const getSavingsSource = (savingsType) => {
	switch (savingsType) {
		case "leasing":
			return "lease";
		case "financing":
			return "AF";
		case "travels":
			return "travel";
		default:
			return "lease";
	}
};

export const getSingularOrPlural = (length, text) => {
	if (Number(length) > 1) {
		return text;
	} else if (text?.slice(-1) === "s") {
		return text.slice(0, text.length - 1);
	} else {
		return text;
	}
};

export const formatProductWeight = (productSpecifications) => {
	const weight = productSpecifications
		?.split(";")
		?.find((a) => a?.toLowerCase().includes("weight"));
	const value = weight?.split(" ").find((a) => isFinite(a));
	const unit = weight
		?.split(" ")
		.find((a) => a.startsWith("("))
		?.replace("(", "")
		?.replace(")", "")
		?.replace(":", "");
	return value === undefined || unit === undefined ? "" : `${value} ${unit}`;
};

export const addAmounts = (arrayOfAmounts) => {
	const total = arrayOfAmounts.reduce(
		(acc, cur) => acc + Number(cur?.replaceAll(",", "")),
		0
	);
	return formatAmountToIncludeCurrency(undefined, total);
};

// export const removeCountryCode = (phone) => {
// 	return phone?.startsWith(2) ? `0${phone?.slice(3)}` : phone;
// };

export const futureDate = moment().add(5, "years");

export const getAmountWithDurationAndInterest = (
	interest,
	duration,
	amount
) => {
	const length = duration?.split(" ")?.at(0);
	const time = duration?.split(" ")?.at(1);

	const divisor = () => {
		if (time?.toLowerCase().includes("day")) {
			return length / 365;
		}
		if (time?.toLowerCase().includes("week")) {
			return length / 52.143;
		}
		if (time?.toLowerCase().includes("month")) {
			return length / 12;
		}
		if (time?.toLowerCase().includes("year")) {
			return 1;
		}
	};

	const interestAmount =
		(Number(amount?.replaceAll(",", "")) * Number(interest) * divisor()) / 100;
	return interestAmount;
};

export const getInterestAmount = (interest, amount) => {
	return Number(amount?.replaceAll(",", "")) * (Number(interest) / 100);
};

export const getRoughMonthlyPayment = (interestAmount, amount) => {
	return (Number(amount?.replaceAll(",", "")) + interestAmount) / 12;
};

export const getMaturityDate = (tenure) => {
	const length = tenure?.split(" ")[0];
	const period = tenure?.split(" ")[1];
	const lengthNum = Number(length);

	let futureDate;

	if (period === "Days") {
		const futureDays = moment().add(lengthNum, "d");
		futureDate = futureDays?.toISOString();
	} else if (period === "Weeks") {
		const futureWeeks = moment().add(lengthNum, "w");
		futureDate = futureWeeks?.toISOString();
	} else if (period === "Months") {
		const futureMonths = moment().add(lengthNum, "M");
		futureDate = futureMonths?.toISOString();
	} else if (period === "Years") {
		const futureYears = moment().add(lengthNum, "y");
		futureDate = futureYears?.toISOString();
	}
	return futureDate;
};

export const availableWallets = ["main", "savings", "investment"];
export const walletType = {
	main: "wallet",
	regularSavings: "regular-savings",
	assetBasedSavings: "asset-based-savings",
	leaseInvestment: "lease-investment",
	financeInvestment: "finance-investment",
	riskFreeInvestment: "risk-free-investment",
};

export const travelPurpose = [
	"A Vacation",
	"An Upcoming Conference",
	"General Travels",
	"Others",
];

export const getMonthlyPayment = (rentalFee) =>
	Number(rentalFee + getMaintenanceFee());

export const getOneOffPayment = (data) => {
	const total_amount = Number(data?.price);

	const fixedOriginalFee = (2.5 / 100) * total_amount;
	const refundableFee = (10 / 100) * total_amount;
	const assetInsuranceFee = (2.5 / 100) * total_amount;
	// const deliveryFee = data?.deliveryType === "pickup" ? 0 : deliveryFeeSuccess;

	const one_off_payment = fixedOriginalFee + refundableFee + assetInsuranceFee;

	return one_off_payment;
};

export const dateRegex =
	/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/;

export const formatLeaseeStatus = (status) => {
	// console.log(status);
	if (status === "pending") {
		return { status: "Pending", color: "#FFC214" };
	}
	if (status === "approved") {
		return { status: "Approved", color: "green" };
	} else if (status === "rejected") {
		return { status: "Rejected", color: "red" };
	}
};

export const getProductsObjects = (product) => {
	return {
		id: product?.objectId,
		amount: product?.amount,
		brand: product?.brand,
		model: product?.model,
		category: product?.category,
		color: product?.color,
		condition: product?.condition || "brand new",
		description: product?.description,
		keyFeatures: product?.keyFeatures,
		pickupAddress: product?.pickupAddress,
		productName: product?.productName,
		shelfLife: product?.shelfLife,
		specifications: product?.specifications,
		subCategory: product?.subCategory,
		tags: product?.tags,
		year: product?.year,
		imageArr: [
			product?.image,
			product?.secondImage,
			product?.thirdImage,
		].filter((image) => image !== undefined),
		objectId: product?.objectId,
	};
};

export const getAssetDetails = (asset) => {
	return {
		id: asset?.objectId,
		usersRequest: asset?.usersRequest ? asset?.usersRequest : [],
		investors: asset?.investors,
		investorsArray: asset?.investorsArray,
		paidSlots: asset?.paidSlots,
		userId: asset?.userId,
	};
};

export const getLeaseId = (requestData) => {
	if (requestData?.state) {
		return requestData?.objectId;
	} else {
		const usersRequest = requestData?.usersRequest;
		const approvedReq = usersRequest?.find((a) => a?.status === "approved");
		return approvedReq?.transactionId;
	}
};

export const getInsurancePaymentStatus = (request) => {
	const regInfo = request?.insurancePaymentInfo;

	const totalAmount = regInfo ? regInfo?.totalAmount : 0;

	const totalAmountPaid = regInfo?.investors?.reduce(
		(acc, cur) => acc + cur?.amountToPay || cur?.amountPaid,
		0
	);

	const paymentStatus =
		regInfo && totalAmountPaid === totalAmount
			? ""
			: regInfo?.investor?.length && totalAmountPaid !== totalAmount
			? "awaiting complete payment"
			: "";

	const isPaymentComplete = totalAmountPaid === totalAmount;

	return { paymentStatus, isPaymentComplete };
};

export const removeCountryCode = (phone) => {
	const firstChar = phone?.charAt(0);
	return firstChar === "0" ? phone : `0${phone?.slice(3)}`;
};

export const getIdType = (idType) => {
	if (idType?.includes("National Identity")) {
		return "nimc";
	} else if (idType?.includes("International")) {
		return "internationalPassport";
	} else if (idType?.includes("Voter")) {
		return "votersCard";
	} else if (idType?.includes("Driver")) {
		return "driversLicense";
	} else {
		return "others";
	}
};

export const getVehicleType = (type) => {
	switch (type) {
		case "Suv":
			return "SUV";
		default:
			"Saloon";
	}
};
export const formatDuration = (duration) => {
	const length = duration?.split(" ")?.at(0);
	const time = duration?.split(" ")?.at(1);

	if (time?.toLowerCase().includes("day")) {
		return length / 365;
	}
	if (time?.toLowerCase().includes("week")) {
		return length / 52.143;
	}
	if (time?.toLowerCase().includes("month")) {
		return length / 12;
	}
	if (time?.toLowerCase().includes("year")) {
		return 1 * length;
	}
};
export const formatDurationForProgramSavings = (duration) => {
	const length = duration?.split(" ")?.at(0);
	const time = duration?.split(" ")?.at(1);

	if (time?.toLowerCase().includes("week")) {
		if (length < 4) {
			return 1;
		} else {
			return Math.ceil(length / 4);
		}
	}
	if (time?.toLowerCase().includes("month")) {
		return length;
	}
	if (time?.toLowerCase().includes("year")) {
		return 12 * length;
	}
};

export const businessTypes = [
	{
		value: "",
		label: "",
	},
	{
		value: "restaurant",
		label: "Restaurant",
	},
	{
		value: "retail",
		label: "Retail Store",
	},
	{
		value: "technology",
		label: "Technology Company",
	},
	{
		value: "consulting",
		label: "Consulting Firm",
	},
	{
		value: "construction",
		label: "Construction Company",
	},
	{
		value: "healthcare",
		label: "Healthcare Provider",
	},
	{
		value: "e-commerce",
		label: "E-commerce Business",
	},
	{
		value: "manufacturing",
		label: "Manufacturing Company",
	},
	{
		value: "transportation",
		label: "Transportation Service",
	},
	{
		value: "hospitality",
		label: "Hospitality Industry",
	},
	{
		value: "finance",
		label: "Financial Institution",
	},
	{
		value: "education",
		label: "Education Institution",
	},
	{
		value: "media",
		label: "Media and Entertainment",
	},
	{
		value: "agriculture",
		label: "Agriculture Business",
	},
	{
		value: "fashion",
		label: "Fashion Retailer",
	},
	{
		value: "automotive",
		label: "Automotive Industry",
	},
];

export const durationFrame = [
	{
		value: "",
		label: "",
	},
	{
		value: "weeks",
		label: "Weeks",
	},
	{
		value: "months",
		label: "Months",
	},
	{
		value: "years",
		label: "Year",
	},
];

export const getUserRequestStatus = (investorsArray, userRequest) => {
	const approvedReq = userRequest?.investor?.filter(
		(a) => a?.status === "approved"
	);
	const rejectedReq = userRequest?.investor?.filter(
		(a) => a?.status === "rejected"
	);

	if (investorsArray?.length < 2) {
		return userRequest?.status;
	} else if (investorsArray?.length === 2) {
		if (approvedReq?.length + rejectedReq?.length === 2) {
			if (approvedReq?.length > rejectedReq?.length) {
				return "approved";
			} else if (approvedReq?.length === rejectedReq?.length) {
				return "undecided";
			} else {
				return "rejected";
			}
		} else {
			return "pending";
		}
	} else if (investorsArray?.length === 3) {
		if (approvedReq?.length + rejectedReq?.length > 1) {
			if (approvedReq?.length > rejectedReq?.length) {
				return "approved";
			} else if (approvedReq?.length < rejectedReq?.length) {
				return "rejected";
			} else {
				return "pending";
			}
		} else {
			return "pending";
		}
	} else if (investorsArray?.length === 4) {
		if (approvedReq?.length + rejectedReq?.length > 2) {
			if (approvedReq?.length > rejectedReq?.length) {
				return "approved";
			} else if (approvedReq?.length < rejectedReq?.length) {
				return "rejected";
			} else if (approvedReq?.length === rejectedReq?.length) {
				return "undecided";
			} else {
				return "pending";
			}
		} else {
			return "pending";
		}
	}
};
export const addAllNumbersAmount = (numArray) => {
	const add = numArray?.reduce((acc, cur) => acc + cur, 0);

	return add || 0;
};
