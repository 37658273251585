import React, { useEffect, useState } from "react";
import Container from "../../layout/Container";
import Navbar from "../../layout/Navbar";
import { Helmet } from "react-helmet";
import {
	BackTracker,
	SuccessCheckModal,
	UrlTracker,
} from "../../layout/CustomComponents";
import { CustomBigSelect } from "../../layout/CustomComponents";
import { BsGiftFill, BsPersonFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { MobileNavDetails } from "../../layout/MobileNavbar";
import {
	openPersonalForm,
	openBusinessForm,
	openInvestorForm,
	closeInvestorForm,
	closePersonalForm,
	closeBusinessForm,
	selectedProfile,
} from "../../redux/lease/leaseActions";
import { useDispatch, useSelector } from "react-redux";
import { DesktopSideModal } from "../../components/ModalContainer";
import BusinessForm from "./business/BusinessForm";
import PersonalForm from "./personal/PersonalForm";
import InvestorForm from "./investor/InvestorForm";
import {
	clearStatesOnConfirmLease,
	clearUpdateAccountSuccess,
} from "../../redux/authRedux/authActions";
import { getProfilePercentage } from "../../common/utils";

const NewTransaction = () => {
	const { state, pathname } = useLocation();
	const dispatch = useDispatch();
	const [togglePersonalAccModal, setTogglePersonalAccModal] = useState(false);
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(state?.currentTab || 1);

	const { currentUser, updatingUserAccountSuccess } = useSelector(
		(state) => state.auth
	);

	const { togglePersonalForm, toggleBusinessForm, toggleInvestorForm } =
		useSelector((state) => state.lease);

	useEffect(() => {
		dispatch(clearStatesOnConfirmLease());
	}, []);

	const onNoInvestorFormSubmit = () => {
		setActiveTab(2);
		setTimeout(() => {
			dispatch(closeInvestorForm());
		}, 300);
	};

	const onInvestorFormSaveSuccess = () => {
		setActiveTab(2);
		setTimeout(() => {
			dispatch(clearUpdateAccountSuccess());
			dispatch(closeInvestorForm());
		}, 300);
	};

	const onPersonalFormSaveSuccess = () => {
		navigate("/leasing/new-transaction/market-place");
		setTimeout(() => {
			dispatch(clearUpdateAccountSuccess());
			dispatch(closePersonalForm());
		}, 300);
	};

	const onBusinessFormSaveSuccess = () => {
		navigate("/leasing/new-transaction/market-place");
		setTimeout(() => {
			dispatch(clearUpdateAccountSuccess());
			dispatch(closeBusinessForm());
		}, 300);
	};

	const personalProfileValidation = {
		workEmail: currentUser?.workEmail,
		workEmailVerified: currentUser?.workEmailVerified,
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		workStatus: currentUser?.workStatus,
		salaryRange: currentUser?.salaryRange,
		numberOfDependants: currentUser?.numberOfDependants,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const businessProfileValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		businessAddress: currentUser?.businessAddress,
		businessType: currentUser?.businessType,
		businessName: currentUser?.businessName,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const investorProfileValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		netWorth: currentUser?.netWorth,
		investorSignature: currentUser?.investorSignature,
		witnessSignature: currentUser?.witnessSignature,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const personalProfilePercentage = getProfilePercentage(
		personalProfileValidation
	);
	const businessProfilePercentage = getProfilePercentage(
		businessProfileValidation
	);
	const investorProfilePercentage = getProfilePercentage(
		investorProfileValidation
	);

	const onPersonalProfileClick = () => {
		if (personalProfilePercentage < 56) {
			dispatch(openPersonalForm());
		} else {
			navigate("/leasing/new-transaction/market-place");
		}
	};

	const onBusinessProfileClick = () => {
		if (businessProfilePercentage < 48) {
			dispatch(openBusinessForm());
		} else {
			navigate("/leasing/new-transaction/market-place");
		}
	};

	const onInvestorProfileClick = () => {
		if (investorProfilePercentage < 48) {
			dispatch(openInvestorForm());
		} else if (pathname.includes("leasing")) {
			setActiveTab(2);
		}
	};

	return (
		<>
			{togglePersonalForm && (
				<DesktopSideModal modal={togglePersonalForm}>
					<>
						{updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onPersonalFormSaveSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							<PersonalForm onCloseForm={() => dispatch(closePersonalForm())} />
						)}
					</>
				</DesktopSideModal>
			)}
			{toggleBusinessForm && (
				<DesktopSideModal modal={toggleBusinessForm}>
					{updatingUserAccountSuccess ? (
						<div className="w-full grid place-items-center">
							<SuccessCheckModal
								text="Form details saved successfully"
								onRequestClick={onBusinessFormSaveSuccess}
								isShowBtn={true}
								btnText="proceed"
							/>
						</div>
					) : (
						<BusinessForm onCloseForm={() => dispatch(closeBusinessForm())} />
					)}
				</DesktopSideModal>
			)}
			{toggleInvestorForm && (
				<DesktopSideModal modal={toggleInvestorForm}>
					{updatingUserAccountSuccess ? (
						<div className="w-full grid place-items-center">
							<SuccessCheckModal
								text="Form details saved successfully"
								onRequestClick={onInvestorFormSaveSuccess}
								isShowBtn={true}
								btnText="proceed"
							/>
						</div>
					) : (
						<InvestorForm
							onCloseForm={() => dispatch(closeInvestorForm())}
							onNoInvestorFormSubmit={onNoInvestorFormSubmit}
						/>
					)}
				</DesktopSideModal>
			)}

			<MobileNavDetails text="new transaction" />
			<Container>
				<>
					<Helmet>
						<title>Fortcore - Transactions</title>
					</Helmet>
					<Navbar text="new transaction" />
					<div className="sm:pt-9 md:pt-10 "></div>
					<div className="flex gap-1.5">
						<UrlTracker
							text="leasing"
							onNavigate={() => navigate("/leasing")}
						/>
						<UrlTracker text="new transaction" isCurrentUrl />
					</div>
					{activeTab === 1 && (
						<>
							<BackTracker text="leasing" onNavigate={() => navigate(-1)} />
							<div className="pb-6 sm:py-5 md:py-6 space-y-1.5">
								<p className="text-[14.5px] text-fortcore-green zabalBoldFont">
									New Transaction
								</p>
								<p className="text-xs text-[#717E83] zabalMedFont">
									How would you like to explore the service?
								</p>
							</div>
							<div className="grid sm:grid-cols-2 gap-4 md:gap-5 lg:gap-6 items-start mb-8">
								<CustomBigSelect
									text="Personal / Business"
									subText="Your accounts"
									toggleModal={togglePersonalAccModal}
									onRequestToggle={() =>
										setTogglePersonalAccModal((prev) => !prev)
									}
								>
									<>
										{togglePersonalAccModal && (
											<div className="mt-3.5 grid gap-3 pb-7 ">
												<LeaseSelectType
													title="personal"
													icon={<BsPersonFill color="#00525D" size={12} />}
													subTitle="Transaction for self"
													onRequestClick={() => {
														dispatch(selectedProfile("personal"));
														onPersonalProfileClick();
													}}
												/>
												<LeaseSelectType
													title="business"
													icon={<BsGiftFill color="#00525D" size={12} />}
													subTitle="Transaction for your Business"
													onRequestClick={() => {
														dispatch(selectedProfile("business"));
														onBusinessProfileClick();
													}}
												/>
											</div>
										)}
									</>
								</CustomBigSelect>
								<div className="rounded-md border border-[#EEF2F3] px-4 md:px-5 blueGradient">
									<div
										className="flex items-center justify-between h-[80px] sm:h-[85px] md:h-[90px] lg:h-[95px] xl:h-[110px] text-[#393F42] cursor-pointer"
										onClick={() => {
											setTogglePersonalAccModal(false);
											dispatch(selectedProfile("investor"));
											onInvestorProfileClick();
										}}
									>
										<div className="flex flex-col gap-1.5">
											<p className="zabalBoldFont text-sm">Investor</p>
										</div>
										<MdKeyboardArrowRight size={22} />
									</div>
								</div>
							</div>
						</>
					)}
					{activeTab === 2 && (
						<>
							<BackTracker text="leasing" onNavigate={() => setActiveTab(1)} />
							<div className="pb-6 sm:py-5 md:py-6 space-y-1">
								<>
									<button
										className="hidden sm:flex items-center text-[12.5px] text-fortcore-green zabalMedFont mb-4"
										onClick={() => setActiveTab(1)}
									>
										<MdKeyboardArrowLeft size={20} />
										<span>Back</span>
									</button>
									<p className="text-[14.5px] text-fortcore-green zabalMedFont">
										What do you want to do today?
									</p>
									<p className="text-xs text-[#717E83] zabalRegFont">
										Complete the action required below to submit this
										transaction
									</p>
								</>
							</div>
							<div className="grid sm:grid-cols-2 gap-4 md:gap-5 lg:gap-6 items-start mb-8">
								<div
									className="rounded-md border border-[#EEF2F3] px-4 md:px-5 hover:border-[#557C8A] bg-[#FEFEFE] hover:bg-[#EEF2F3]"
									onClick={() =>
										navigate("/leasing/new-transaction/create-lease", {
											state: { currentTab: 1 },
										})
									}
								>
									<div className="flex items-center justify-between h-[80px] md:h-[85px] lg:h-[90px] text-[#393F42] hover:text-fortcore-green cursor-pointer">
										<div className="flex flex-col gap-1.5">
											<p className="zabalMedFont text-sm">Create an Asset</p>
										</div>
										<MdKeyboardArrowRight size={22} color="#00525d" />
									</div>
								</div>
								<div
									className="rounded-md border border-[#EEF2F3] px-4 md:px-5 hover:border-[#557C8A] bg-[#FEFEFE] hover:bg-[#EEF2F3]"
									onClick={() =>
										navigate("/leasing/new-transaction/investor-market-place")
									}
								>
									<div className="flex items-center justify-between h-[80px] md:h-[85px] lg:h-[90px] text-[#393F42] hover:text-fortcore-green cursor-pointer">
										<div className="flex flex-col gap-1.5">
											<p className="zabalMedFont text-sm">
												View Available Asset
											</p>
										</div>
										<MdKeyboardArrowRight size={22} color="#00525d" />
									</div>
								</div>
							</div>
						</>
					)}
				</>
			</Container>
		</>
	);
};
export default NewTransaction;

const LeaseSelectType = ({ title, subTitle, icon, onRequestClick }) => {
	return (
		<div
			className="flex items-center justify-between border border-[#E3E9EC] rounded p-3.5 cursor-pointer innerCard hover:border-[#AABDC5] transition-colors"
			onClick={onRequestClick}
		>
			<div className="flex gap-3 items-center">
				<div className="rounded-full h-[25px] w-[25px] flex items-center justify-center bg-[#E3E9EC]">
					{icon}
				</div>
				<div className="space-y-1">
					<p className="capitalize text-[13.5px] text-[#393F42] hover:text-fortcore-green zabalMedFont">
						{title}
					</p>
					<p className="text-[11px] text-[#788084] zabalRegFont">{subTitle}</p>
				</div>
			</div>
			<div className="text-[#8E9DA4] hover:text-fortcore-green">
				<MdKeyboardArrowRight size={22} />
			</div>
		</div>
	);
};

LeaseSelectType.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	icon: PropTypes.object,
	onRequestClick: PropTypes.func,
};
