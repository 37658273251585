import Parse from "../../api/ApiConfig";

export const createNotification = async (
	message,
	metaData,
	user = Parse.User.current()
) => {
	try {
		const Notification = new Parse.Object("Notifications");

		Notification.set("user", user);

		Notification.set("message", message);

		Notification.set("metaData", metaData);

		const result = await Notification.save();

		// eslint-disable-next-line no-undef
		// console.log("Notification created successfully:", result);

		return result;
	} catch (error) {
		// eslint-disable-next-line no-undef
		console.error("Error creating notification:", error);
		throw error;
	}
};
