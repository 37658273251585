import React from "react";
import { TransactionCards, RiskAppetiteCard, InviteFriendCard } from "../../layout/FinanceLeaseCards";
import { PigIcon, RiskAppetiteIcon } from "../../layout/assets";
import { useNavigate } from "react-router-dom";

const LeaseOverview = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="space-y-1.5 mt-6 sm:mt-0">
        <p className="text-fortcore-green text-[15px] zabalBoldFont">Explore Leasing</p>
        <p className="text-[12.5px] text-[#393F42] text-justify zabalRegFont">It is always more financially viable to lease than to own. Eliminate the burden of ownership of assets and save significant fund when you lease.</p>
      </div>
      <TransactionCards urlArr={['/leasing/new-transaction', '/leasing/ongoing-transactions', '/leasing/submitted-transactions']} />
      <hr className="hidden sm:block" />
      <div className="py-5 pb-10 sm:py-8 lg:py-10 grid gap-5 sm:gap-8 sm:grid-cols-2 xl:grid-cols-3">
        <div className="border border-[#E3E9EC] rounded-md p-4 space-y-3">
          <p className="uppercase text-[11.5px] text-[#393F42] zabalBoldFont">lease to income ratio</p>
          <div className="flex gap-3 items-center">
            <h3 className="text-2xl font-bold text-fortcore-green zabalBoldFont">0.3</h3>
            <p className="max-w-[220px] text-[#555F63] text-xs leading-5 zabalRegFont">You lease monthly repayment must not exceed <span className="zabalMedFont font-semibold">30% </span> of  your income </p>
          </div>
        </div>
        <RiskAppetiteCard text='risk appetite' icon={<RiskAppetiteIcon width={60} height={60} />} textOne='Know your Risk Appetite' textTwo="Let's Go" />
        <RiskAppetiteCard text='savings' icon={<PigIcon width={60} height={60} />} textOne='Explore Savings' onReqClick={() => navigate('/leasing/savings-overview')} />
        <InviteFriendCard />
      </div>
    </>
  )
}
export default LeaseOverview
