import React from "react";
import {
	BsFillPlusCircleFill,
	BsFillCartFill,
	BsFillExclamationCircleFill,
	BsFilter,
} from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
	openFilterModal,
	openMarketItemDetails,
} from "../../../redux/lease/leaseActions";
import {
	formatAmountToIncludeCurrency,
	getInterestAmount,
	getRoughMonthlyPayment,
} from "../../../common/utils";
import { useLocation } from "react-router-dom";

export const MarketTab = ({ text, isCurrentTab, onTabChange }) => {
	return (
		<button
			className={`py-1 px-2.5 sm:px-5 text-[13px] md:text-sm capitalize zabalMedFont ${
				isCurrentTab
					? "border-b-2 border-fortcore-green text-fortcore-green"
					: "text-[#8E9DA4]"
			}`}
			onClick={onTabChange}
		>
			{text}
		</button>
	);
};

export const MarketOrderSkeleton = () => (
	<div className="flex flex-col gap-1.5 bg-white rounded p-3">
		<Skeleton className="h-[80px] w-full" baseColor="#f1f1f1" />
		<div>
			<Skeleton className="h-[19px] w-full" />
			<Skeleton className="h-[19px] !w-5/6" />
		</div>
		<div className="mt-2 flex items-center justify-between">
			<Skeleton className="h-[20px] !w-[45px]" />
			<Skeleton className="h-[35px] !w-[50px]" />
		</div>
	</div>
);

MarketTab.propTypes = {
	text: PropTypes.string,
	isCurrentTab: PropTypes.bool,
	onTabChange: PropTypes.func,
};

export const MobileLeaseCartBtn = ({ text, onLeaseNavigate }) => {
	return (
		<div className="flex gap-3 items-center">
			<button
				className="flex flex-col gap-0.5 text-[10px] tracking-wide"
				onClick={onLeaseNavigate}
			>
				<span className="self-center">
					<BsFillPlusCircleFill size={14} color="#00525D" />
				</span>
				<span className="capitalize">{text}</span>
			</button>
			<button className="flex flex-col gap-0.5 text-[10px] tracking-wide ">
				<span className="self-center">
					<BsFillCartFill size={15} color="#00525D" />
				</span>
				<span>Cart</span>
			</button>
		</div>
	);
};

MobileLeaseCartBtn.propTypes = {
	text: PropTypes.string,
	onLeaseNavigate: PropTypes.func,
};

export const MarketPlaceInputBtns = ({
	text,
	handleTextChange,
	leaseBtnText,
	leaseBtnRequestClick,
}) => {
	const dispatch = useDispatch();

	return (
		<div className="flex justify-between gap-0.5 items-center">
			<div
				className={`w-full sm:w-9/12 ${
					leaseBtnText === "create"
						? "sm:w-[82%] md:w-[87%] lg:w-[88%]"
						: "md:w-10/12 lg:w-[85%]"
				} h-[45px] relative`}
			>
				<input
					type="text"
					className="w-full h-full border border-[#96A0A5] px-2.5 bg-transparent rounded outline-none text-sm zabalRegFont focus:border-fortcore-green focus:border-2"
					placeholder="search"
					value={text}
					onChange={handleTextChange}
				/>
				<button
					className="absolute right-[15px] top-[15px] flex items-center gap-1"
					onClick={() => dispatch(openFilterModal())}
				>
					<BsFilter size={18} color="#00525D" />
					<span className="text-xs zabalMedFont">Filter</span>
				</button>
			</div>
			<button
				className="hidden sm:flex flex-col gap-1 text-[11.5px] tracking-wide zabalMedFont"
				onClick={leaseBtnRequestClick}
			>
				<span className="self-center">
					<BsFillPlusCircleFill size={15} color="#00525D" />
				</span>
				<span className="capitalize">{leaseBtnText}</span>
			</button>
			<button className="hidden sm:flex flex-col gap-1 text-[11.5px] tracking-wide zabalMedFont">
				<span className="self-center">
					<BsFillCartFill size={16} color="#00525D" />
				</span>
				<span>Cart</span>
			</button>
		</div>
	);
};

MarketPlaceInputBtns.propTypes = {
	text: PropTypes.string,
	handleTextChange: PropTypes.func,
	leaseBtnText: PropTypes.string,
	leaseBtnRequestClick: PropTypes.func,
};

export const MarketDataCard = ({ data, asset, onReqClick }) => {
	const dispatch = useDispatch();
	const { currentProfile } = useSelector((state) => state.lease);
	const location = useLocation();

	// const amountInterest = getAmountWithDurationAndInterest(
	// 	asset?.interest,
	// 	asset?.leaseDuration,
	// 	asset?.price || asset?.productObj?.amount
	// );

	const amountInteres = getInterestAmount(
		asset?.interest,
		asset?.price || asset?.productObj?.amount
	);

	const roughMonthlyPayment = getRoughMonthlyPayment(
		amountInteres,
		asset?.price || asset?.productObj?.amount
	);

	return (
		<div className="border border-[#EEF2F3] rounded-md overflow-hidden w-full bg-white">
			<div className="bg-[#8888881F] p-2.5 sm:px-3 sm:py-4 h-[140px] sm:h-[150px] grid place-items-center">
				<img
					src={data?.imageArr[0]}
					alt={data.productName}
					className="h-auto w-full max-w-[120px] max-w-[125px] mx-auto max-h-[120px] sm:max-h-[120px] mix-blend-multiply"
					crossOrigin="anonymous"
					loading="lazy"
				/>
			</div>
			<div
				className={`p-2.5 sm:px-3 bg-[#FEFEFE] sm:py-4 flex flex-col gap-1 sm:gap-1.5 ${
					asset?.interest
						? "min-h-[155px] sm:min-h-[180px] lg:min-h-[170px]"
						: "min-h-[130px] sm:min-h-[150px] lg:min-h-[140px]"
				}`}
			>
				<p className="pb-1.5 text-fortcore-green font-semibold zabalRegFont text-xs border-b border-[#EEF2F3]">
					<span className="zabalMedFont">NGN</span>{" "}
					{formatAmountToIncludeCurrency(undefined, data?.amount)}
				</p>
				<p className="text-black zabalMedFont tracking-wide text-[12.5px] capitalize min-h-[30px]">
					{data.productName}
				</p>
				<p className="text-[#393F42] zabalMedFont text-[11px] capitalize">
					{data.color}
				</p>
				{/* {asset?.interest && (
					<p className="text-[#393F42] zabalMedFont text-[11px] h-[30px]">
						<span className="zabalRegFont">Interest: </span>
						<span>
							NGN{" "}
							{amountInterest?.toLocaleString(undefined, {
								maximumFractionDigits: 1,
							})}{" "}
							at {asset?.interest}%
						</span>
					</p>
				)} */}
				<p className="text-[#393F42] zabalMedFont text-xs">
					As low as{" "}
					<span className="zabalBoldFont">
						{formatAmountToIncludeCurrency("NGN", roughMonthlyPayment)}
					</span>{" "}
					for one year tenor.
				</p>
				<div className="flex justify-between gap-3.5 items-center flex-wrap">
					<button
						className="flex items-center gap-1 text-xs zabalMedFont"
						onClick={() => dispatch(openMarketItemDetails(data))}
					>
						<BsFillExclamationCircleFill color="#AABDC5" size={15} />
						<span className="underline text-fortcore-green">Details</span>
					</button>
					<button
						className="flex flex-col gap-0.5 text-[10.5px] tracking-wide zabalMedFont"
						onClick={() => onReqClick(asset)}
					>
						<span className="self-center">
							<BsFillPlusCircleFill size={15} color="#00525D" />
						</span>
						<span>
							{currentProfile === "investor" ||
							location.pathname.includes("investor-market-place")
								? "Fund"
								: "Request"}
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

MarketDataCard.propTypes = {
	data: PropTypes.object,
	asset: PropTypes.object,
	onReqClick: PropTypes.func,
};
