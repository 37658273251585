import {
	Box,
	Button,
	IconButton,
	Modal,
	Stack,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowForward, IoMdClose } from "react-icons/io";
import theme from "../../../components/mui/theme";
import PinInput from "react-pin-input";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Parse from "../../../api/ApiConfig";
import { CreateAccessPin } from "../accountTab/component/CreateAccessPin";
import axios from "axios";
import baseURL from "../../../api/UrlConfig";
import userContext from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { walletType } from "../../../common/utils";

function PaymentModal({
	payModal,
	setPayModal,
	setState,
	wallet,
	card,
	narration,
	amountToDeduct,
}) {
	const navigate = useNavigate();
	const handleClose = () => setPayModal(false);
	const [pin, setPin] = useState("");
	const [selected, setSelected] = useState(false);
	const [pinExist, setPinExist] = useState(false);
	const [createPin, setCreatePin] = useState(false);

	const { currentUserData } = useContext(userContext);

	const { customerWallet, walletDetails } = useSelector(
		(state) => state.payment
	);

	const accessPin = currentUserData?.accessPin || "";

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const checkUserPin = () => {
		const currentUser = Parse.User.current();
		if (currentUser) {
			if (accessPin.length === 4) {
				setPinExist(true);
			} else {
				setPinExist(false);
			}
		} else {
			Parse.User.logOut();
			toast.error("Session Expired");
			navigate("/login");
		}
	};
	useEffect(() => {
		checkUserPin();
	}, []);

	const handleChange = (value) => {
		setPin(value);
	};

	// eslint-disable-next-line no-unused-vars
	const handleWithdrawWallet = () => {
		if (walletDetails) {
			let data = JSON.stringify({
				walletId: walletDetails.id,
				amount: amountToDeduct,
				narration: narration,
			});

			let config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${baseURL}/functions/makeWalletWithdrawal`,
				headers: {
					"X-Parse-Application-Id": "FortCore",
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios
				.request(config)
				.then((response) => {
					// eslint-disable-next-line no-undef
					console.log(response);
					// toast.success("Success");
					toast.success(narration);
					setState();
					handleClose();
				})
				.catch((error) => {
					// eslint-disable-next-line no-undef
					console.log(error);
				});
		}
	};

	const handleComplete = () => {
		const currentUser = Parse.User.current();

		if (currentUser) {
			if (pin === accessPin) {
				if (
					mainWallet?.availableBalance !== 0 &&
					mainWallet?.availableBalance >= parseFloat(amountToDeduct)
				) {
					handleWithdrawWallet();
				} else {
					toast.error("Insufficient Funds");
				}
			} else {
				toast.error("Incorrect Pin");
			}
		} else {
			Parse.User.logOut();
			toast.error("Session Expired");
		}
	};
	return (
		<Modal open={payModal} onClose={handleClose}>
			<Box sx={style}>
				{!selected ? (
					<>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography variant="h3" color="primary">
								Select Option
							</Typography>
							<IconButton onClick={handleClose}>
								<IoMdClose color={theme.palette.primary.main} />
							</IconButton>
						</Stack>
						<Typography variant="caption" color="info.light">
							Pick your most preferred payment mode
						</Typography>

						<Button
							variant="outlined"
							color="primary"
							sx={{
								border: "1px solid var(--grey-200, #E3E9EC)",
								background: theme.palette.secondary.main,
								mt: 3,
								width: "100%",
								height: "96px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
							onClick={() => {
								setSelected(true);
								wallet();
							}}
						>
							<Typography variant="body1" color="initial">
								Pay from Wallet
							</Typography>
							<IoIosArrowForward />
						</Button>

						<Button
							variant="outlined"
							color="primary"
							sx={{
								border: "1px solid var(--grey-200, #E3E9EC)",
								background: theme.palette.secondary.main,
								mt: 3,
								width: "100%",
								height: "96px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
							onClick={() => {
								// setSelected(true);
								card();
							}}
						>
							<Typography variant="body1" color="initial">
								Pay from Bank Card
							</Typography>
							<IoIosArrowForward />
						</Button>
					</>
				) : (
					<>
						{pinExist ? (
							<Box>
								<Box display="flex" justifyContent="flex-end">
									<IconButton onClick={handleClose}>
										<IoMdClose
											fontSize="24px"
											color={theme.palette.primary.main}
										/>
									</IconButton>
								</Box>
								<Typography variant="h3" color="primary">
									Enter Your Pin
								</Typography>
								<Box display="flex" justifyContent="center" mt={2}>
									<PinInput
										length={4}
										initialValue=""
										secret
										secretDelay={100}
										onChange={handleChange}
										// onComplete={handleComplete}
										type="numeric"
										inputMode="number"
										style={{ padding: "10px" }}
										inputStyle={{
											borderColor: theme.palette.primary.main,
											borderRadius: "8px",
										}}
										inputFocusStyle={{
											borderColor: theme.palette.primary.main,
										}}
										autoSelect={true}
										regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
										value={pin}
									/>
								</Box>

								<Box display="flex" justifyContent="center" mt={3}>
									<Button
										variant="contained"
										color="primary"
										onClick={handleComplete}
									>
										Proceed
									</Button>
								</Box>
							</Box>
						) : (
							<>
								{createPin ? (
									<CreateAccessPin
										action={() => {
											setCreatePin(false);
											checkUserPin();
										}}
									/>
								) : (
									<Box sx={style}>
										<Box display="flex" justifyContent="flex-end">
											<IconButton onClick={handleClose}>
												<IoMdClose
													fontSize="24px"
													color={theme.palette.primary.main}
												/>
											</IconButton>
										</Box>

										<Typography variant="h3" color="primary" align="center">
											You do not have an access pin set for your wallet
										</Typography>

										<Box display="flex" justifyContent="center" mt={3}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => setCreatePin(true)}
											>
												Create Access Pin
											</Button>
										</Box>
									</Box>
								)}
							</>
						)}
					</>
				)}
			</Box>
		</Modal>
	);
}

PaymentModal.propTypes = {
	payModal: PropTypes.bool.isRequired,
	setPayModal: PropTypes.func,
	setState: PropTypes.func,
	wallet: PropTypes.func,
	card: PropTypes.func,
	narration: PropTypes.string,
	amountToDeduct: PropTypes.string,
};

export default PaymentModal;

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "#fff",
	boxShadow: 24,
	borderRadius: "24px",
	p: 4,
};
