import React, { useEffect, useState } from "react";
import Navbar from "../../layout/Navbar";
import Container from "../../layout/Container";
import { Helmet } from "react-helmet";
import {
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { AiFillFilter } from "react-icons/ai";
import Video from "./tabs/video/Video";
import Audio from "./tabs/audio/Audio";
import Books from "./tabs/books/Books";
import theme from "../../components/mui/theme";
import VideoPage from "./tabs/video/VideoPage";
import { useNavigate, useParams } from "react-router-dom";
import AudioPage from "./tabs/audio/AudioPage";
import BooksPage from "./tabs/books/BooksPage";
import { EducationContext } from "./Context/EducationContext";
import Parse from "../../api/ApiConfig";
import PropTypes from "prop-types";
import Slider from "./components/slider/Slider";

const Education = () => {
	const { id } = useParams();

	const [category, setCategory] = useState("");
	const [tab, setTab] = useState(0);
	// eslint-disable-next-line no-undef
	const localPath = location.pathname;
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(true);

	const [educationData, setEducationData] = useState([]);
	const [videoTab, setVideoTab] = useState([]);
	const [audioTab, setAudioTab] = useState([]);
	const [booksTab, setBooksTab] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [showSearchInput, setShowSearchInput] = useState(false);
	const [searchInput, setSearchInput] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			const EducationClass = Parse.Object.extend("Education");
			const query = new Parse.Query(EducationClass);

			try {
				const results = await query.find();
				const data = results.map((result) => result.toJSON());
				setEducationData(data);
				// eslint-disable-next-line no-undef
				console.log("data:", data);
				//  videoTab  after fetching the data
				const videoData = data.filter((data) => data.courseType === "video");
				const videoApprove = videoData.filter((book) => book.approve === true);
				setVideoTab(videoApprove);

				//  audioTab  after fetching the data
				const audioData = data.filter((data) => data.courseType === "audio");
				const audioApprove = audioData.filter((book) => book.approve === true);
				setAudioTab(audioApprove);

				//  booksTab  after fetching the data
				const booksData = data.filter((data) => data.courseType === "book");
				const bookApprove = booksData.filter((book) => book.approve === true);

				setBooksTab(bookApprove);

				setLoading(false);
			} catch (error) {
				// eslint-disable-next-line no-undef
				console.error("Error retrieving Education Class: ", error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		// When the tab is 0 and a category is selected
		if (tab === 0 && category !== "") {
			const filteredData = videoTab.filter(
				(data) => data.category === category
			);
			setFilteredData(filteredData);
		}
		// When the tab is 0 and no category is selected
		else if (tab === 0 && category === "") {
			setFilteredData(videoTab);
		}

		// When the tab is 1 and a category is selected
		if (tab === 1 && category !== "") {
			const filteredData = audioTab.filter(
				(data) => data.category === category
			);
			setFilteredData(filteredData);
		}
		// When the tab is 1 and no category is selected
		else if (tab === 1 && category === "") {
			setFilteredData(audioTab);
		}

		// When the tab is 2 and a category is selected
		if (tab === 2 && category !== "") {
			const filteredData = booksTab.filter(
				(data) => data.category === category
			);
			setFilteredData(filteredData);
		}
		// When the tab is 1 and no category is selected
		else if (tab === 2 && category === "") {
			setFilteredData(booksTab);
		}
	}, [tab, category, videoTab, audioTab, booksTab]);

	const handleSearchInput = (e) => {
		setSearchInput(e.target.value);
	};

	useEffect(() => {
		// When the tab is 0 and a category is selected
		if (tab === 0 && searchInput !== "") {
			const filteredData = videoTab.filter((data) =>
				data.name
					.toLocaleString()
					.toLowerCase()
					.includes(searchInput.toLowerCase())
			);
			setFilteredData(filteredData);
		}
		// When the tab is 0 and no category is selected
		else if (tab === 0 && searchInput === "") {
			setFilteredData(videoTab);
		}

		// When the tab is 1 and a category is selected
		if (tab === 1 && searchInput !== "") {
			const filteredData = audioTab.filter((data) =>
				data.name
					.toLocaleString()
					.toLowerCase()
					.includes(searchInput.toLowerCase())
			);
			setFilteredData(filteredData);
		}
		// When the tab is 1 and no category is selected
		else if (tab === 1 && searchInput === "") {
			setFilteredData(audioTab);
		}

		// When the tab is 2 and a category is selected
		if (tab === 2 && searchInput !== "") {
			const filteredData = booksTab.filter((data) =>
				data.name
					.toLocaleString()
					.toLowerCase()
					.includes(searchInput.toLowerCase())
			);
			setFilteredData(filteredData);
		}
		// When the tab is 1 and no category is selected
		else if (tab === 2 && searchInput === "") {
			setFilteredData(booksTab);
		}
	}, [tab, searchInput, videoTab, audioTab, booksTab]);

	return (
		<Container>
			<Helmet>
				<title>Fortcore - Education</title>
			</Helmet>
			<Navbar text="education" />

			<Box sx={{ paddingTop: "50px" }}>
				{/* <Typography variant="h4" color="primary">
					Education
				</Typography>
				<Typography variant="caption" color="secondary.dark">
					Access free value-added services
				</Typography> */}

				<Slider />
			</Box>

			<Box
				sx={{
					background: "#F6F7F8",
					borderRadius: "20px",
					padding: "20px",
					margin: "40px 0",
				}}>
				<Typography variant="caption" color="primary">
					Categories
				</Typography>

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						flexWrap: "wrap",
						marginBottom: "40px",
					}}>
					{/* Right  */}
					<Box>
						<FormControl
							sx={{
								minWidth: 200,
								border: "1px solid primary",
								marginTop: "20px",
							}}>
							<InputLabel id="cat">Select category</InputLabel>
							<Select
								labelId="cat"
								id="cate"
								value={category}
								label="Select category"
								onChange={(e) => setCategory(e.target.value)}
								sx={{ textTransform: "capitalize" }}>
								<MenuItem value="">All Category</MenuItem>
								{tab === 0 &&
									videoTab.map((categoryItem) => (
										<MenuItem
											key={categoryItem.id}
											value={categoryItem.category} // Update this to the appropriate category property
											sx={{ textTransform: "capitalize" }}>
											{categoryItem.category}
										</MenuItem>
									))}
								{tab === 1 &&
									audioTab.map((categoryItem) => (
										<MenuItem
											key={categoryItem.id}
											value={categoryItem.category} // Update this to the appropriate category property
											sx={{ textTransform: "capitalize" }}>
											{categoryItem.category}
										</MenuItem>
									))}
								{tab === 2 &&
									booksTab.map((categoryItem) => (
										<MenuItem
											key={categoryItem.id}
											value={categoryItem.category} // Update this to the appropriate category property
											sx={{ textTransform: "capitalize" }}>
											{categoryItem.category}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Box>

					{/* Left  */}
					<Stack
						direction="row"
						alignItems="center"
						spacing={1}
						sx={{ marginTop: "15px" }}>
						<Box sx={{ display: { xs: "none", md: "flex" } }}>
							{showSearchInput && (
								<input
									type="text"
									value={searchInput}
									onChange={handleSearchInput}
									placeholder="Enter search text"
									style={{
										padding: "5px 10px",
										borderRadius: "5px",
										width: "100%",
										caretColor: theme.palette.primary.main,
									}}
								/>
							)}
						</Box>

						<IconButton
							color="info.dark"
							onClick={() => setShowSearchInput(!showSearchInput)}
							sx={{
								borderRadius: "50%",
								border: `0.5px solid ${theme.palette.info.dark}`,
							}}>
							<CiSearch color="info.dark" />
						</IconButton>

						<Typography
							color="info.dark"
							variant="body1"
							sx={{ display: { xs: "none", sm: "block" } }}>
							Filter
						</Typography>

						<IconButton
							color="primary"
							aria-label="add an alarm"
							sx={{ fontSize: "20px" }}>
							<AiFillFilter />
						</IconButton>
					</Stack>
				</Box>

				<Box sx={{ display: { xs: "flex", md: "none" }, marginBottom: "40px" }}>
					{showSearchInput && (
						<input
							type="text"
							value={searchInput}
							onChange={handleSearchInput}
							placeholder="Enter search text"
							style={{
								padding: "10px 10px",
								borderRadius: "5px",
								width: "100%",
								caretColor: theme.palette.primary.main,
							}}
						/>
					)}
				</Box>

				<Box mb={4}>
					<CustomTabSelector
						activeTab={tab}
						setActiveTab={setTab}
						onClick={() => setCategory("")}
					/>
				</Box>

				<EducationContext.Provider
					value={{
						educationData,
						setEducationData,
						videoTab,
						setVideoTab,

						audioTab,
						setAudioTab,

						booksTab,
						setBooksTab,

						filteredData,

						loading,
					}}>
					{/* Tab 1  */}
					{localPath === "/education" && tab === 0 && <Video />}
					{localPath === `/education/video/${id}` && tab === 0 && <VideoPage />}

					{/* Tab 2  */}
					{localPath === "/education" && tab === 1 && <Audio />}
					{localPath === `/education/audio/${id}` && tab === 1 && <AudioPage />}

					{/* Tab 3  */}
					{localPath === "/education" && tab === 2 && <Books />}
					{localPath === `/education/books/${id}` && tab === 2 && <BooksPage />}
				</EducationContext.Provider>
			</Box>
		</Container>
	);
};

export default Education;

const CustomTabSelector = ({ activeTab, setActiveTab, onClick }) => {
	const navigate = useNavigate();
	return (
		<div className="flex items-center p-2 sm:p-1.5 gap-1 border border-[#E3E9EC] bg-[#F5F5F5] rounded-[30px] w-full max-w-[600px] sm:w-[440px] sm:max-w-none">
			<button
				className={`flex-grow py-2.5 sm:py-2 text-center rounded-3xl border transition-all ${
					activeTab === 0
						? "text-fortcore-green border-[#557C8A] bg-white zabalBoldFont"
						: "text-[#8E9DA4] border-transparent zabalMedFont"
				}`}
				onClick={() => {
					onClick();
					setActiveTab(0);
					navigate("/education");
				}}>
				Video
			</button>
			<button
				className={`flex-grow py-2.5 sm:py-2 rounded-3xl border transition-all ${
					activeTab === 1
						? "text-fortcore-green border-[#557C8A] bg-white zabalBoldFont"
						: "text-[#8E9DA4] border-transparent zabalMedFont"
				}`}
				onClick={() => {
					onClick();
					setActiveTab(1);
					navigate("/education");
				}}>
				Audio
			</button>
			<button
				className={`flex-grow py-2.5 sm:py-2 rounded-3xl border transition-all ${
					activeTab === 2
						? "text-fortcore-green border-[#557C8A] bg-white zabalBoldFont"
						: "text-[#8E9DA4] border-transparent zabalMedFont"
				}`}
				onClick={() => {
					onClick();
					setActiveTab(2);
					navigate("/education");
				}}>
				Books
			</button>
		</div>
	);
};

CustomTabSelector.propTypes = {
	activeTab: PropTypes.any,
	setActiveTab: PropTypes.func,
	onClick: PropTypes.func,
};
