import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	Box,
	IconButton,
	Modal,
	Stack,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import theme from "../../../../../../components/mui/theme";
import Container from "../../../../../../layout/Container";

function MarketPlaceDescription({ selectedItem, open, close }) {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	return (
		<>
			<Modal open={open}>
				<Box sx={style}>
					<Container>
						<Box sx={style2}>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="flex-end"
							>
								<IconButton onClick={close}>
									<IoMdClose color={theme.palette.primary.main} />
								</IconButton>
							</Stack>

							<Box
								mb={3}
								sx={{
									borderBottom: 0.5,
									borderColor: "#808080",
									overflow: "auto",
								}}
							>
								<Tabs value={selectedTab} onChange={handleChange}>
									<Tab label="Description" />
									<Tab label="Purpose" />
								</Tabs>
							</Box>

							{/* Description  */}
							<CustomTabPanel value={selectedTab} index={0}>
								<Typography variant="body1" color="textSecondary">
									{selectedItem?.businessDescription}
								</Typography>
							</CustomTabPanel>

							{/* Purpose  */}
							<CustomTabPanel value={selectedTab} index={1}>
								<Typography variant="body1" color="textSecondary">
									{selectedItem?.purpose}
								</Typography>
							</CustomTabPanel>
						</Box>
					</Container>
				</Box>
			</Modal>
		</>
	);
}

MarketPlaceDescription.propTypes = {
	selectedItem: PropTypes.any,
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
};

export default MarketPlaceDescription;

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	maxWidth: 1000,
};
const style2 = {
	width: "100%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 2,
	borderRadius: "16px",
};

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
