export function formatDateToString(dateObject) {
  const dateOnlyString = dateObject?.iso.slice(0, 10);

  return dateOnlyString;
}


export function formatParseDate(dateString) {
  // Create a new Date object from the date string
  const dateObject = new Date(dateString);

  // Format the date to the required format for Parse Server
  const formattedDate = {
    __type: "Date",
    iso: dateObject.toISOString(),
  };

  return formattedDate;
}