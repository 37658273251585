import * as financeTypes from "./financeTypes";

export const onToggleFinanceFormModal = (status) => {
	return { type: financeTypes.TOGGLE_FINANCE_FORM_MODAL, payload: status };
};
export const onSelectFinanceOption = (option) => {
	return { type: financeTypes.SELECTED_FINANCE_OPTION, payload: option };
};
export const onSaveUserFinanceRequest = (data) => {
	return { type: financeTypes.SAVE_FINANCE_USER_REQUEST, payload: data };
};
export const onClearUserFinanceRequest = () => {
	return { type: financeTypes.CLEAR_USER_REQ_FINANCE_DATA };
};
export const onSaveInvestorFinanceRequest = (data) => {
	return { type: financeTypes.SAVE_INVESTOR_FINANCE_REQUEST, payload: data };
};
export const onToggleDeleteFinanceModal = (state, data) => {
	return {
		type: financeTypes.TOGGLE_DELETE_FINANCE_MODAL,
		payload: { state, data },
	};
};
export const selectFinanceToActivate = (request) => {
	return { type: financeTypes.SELECT_FINANCE_TO_ACTIVATE, payload: request };
};
export const onSaveDataFromMarketPlace = (data) => {
	return { type: financeTypes.SAVE_APPRENTICE_SELECTED_REQUEST, payload: data };
};
export const onSaveUserChooseCreatedFinance = (data) => {
	return { type: financeTypes.USER_CHOOSES_CREATED_FINANCE, payload: data };
};
export const onToggleViewFinanceReqModal = (state) => {
	return {
		type: financeTypes.TOGGLE_VIEW_FINANCE_REQUESTS_MODAL,
		payload: state,
	};
};
export const onTogglePayCommitmentFeeModal = (state, data = null) => {
	return {
		type: financeTypes.TOGGLE_PAY_COMMITMENT_FEE,
		payload: { state, data },
	};
};
