import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import EduImage from "../../../../../assets/eduImage.png";
import EduBG from "../../../../../assets/edubg.svg";
import Logo from "../../../../../assets/logosvg.svg";

function SliderOne() {
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "328px",
					borderRadius: "12px",
					background: "#FCF7E7",
					border: "1px solid #EEF2F3",
					position: "relative",
				}}>
				<img src={EduBG} alt="" style={{ position: "absolute" }} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						pl: 6,
						pr: 4,
					}}>
					<Stack width="45%" spacing={2} sx={{ zIndex: 2 }}>
						<img src={Logo} alt="" style={{ width: "20%" }} />
						<Typography
							variant="h1"
							sx={{ fontFamily: "inter", fontWeight: 600 }}>
							Enrich Your Mind, Ignite Your Future
						</Typography>
					</Stack>
					<img src={EduImage} alt="" />
				</Box>
			</Box>
		</>
	);
}

export default SliderOne;
