import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { MdKeyboardArrowDown } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { formatAmountToIncludeCurrency } from "../../common/utils";
import moment from "moment";
import { TransactionCard } from "../../layout/FinanceLeaseCards";
import { CircledFinanceIcon, CircledSellIcon } from "../../layout/assets";

const DashOverview = () => {
	const { upcomingPaymentsLoading, upcomingPaymentsSuccess } = useSelector(
		(state) => state.auth
	);

	return (
		<>
			<CreditScore />
			<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 pt-4 pb-12 md:pb-16">
				<TransactionCard
					icon={<CircledFinanceIcon width={40} height={40} />}
					cardTitle="Asset Financing"
					cardSubtitle="Access digital leasing options"
					url="/leasing"
				/>
				<TransactionCard
					icon={<CircledSellIcon width={40} height={40} />}
					cardTitle="Apprentice Finance"
					cardSubtitle="Finance your entrepreneurial dream"
					url="/financing"
				/>
				<TransactionCard
					icon={<CircledFinanceIcon width={40} height={40} />}
					cardTitle="Travel Advance"
					cardSubtitle="Power your journey abroad"
					url="/travels"
				/>
			</div>
			<div className="rounded-md bg-[#FBFBFB] border border-[#F6F7F8] py-4 px-3 sm:p-4 mb-8 overflow-x-auto scroll-wrapper">
				<div className="w-full sm:min-w-[450px]">
					<div className="flex items-center justify-between pb-2 sm:pb-4 border-b border-[#F6F7F8] sm:border-transparent">
						<p className="text-[15px] text-[#393F42] zabalBoldFont">
							Upcoming Payments
						</p>
						<div className="flex gap-4 items-center">
							{!upcomingPaymentsLoading &&
								upcomingPaymentsSuccess?.length > 0 && (
									<button className="border rounded-[5px] text-[#879094] text-xs flex gap-1.5 items-center p-2 zabalRegFont">
										<span>Today</span>
										<MdKeyboardArrowDown size={16} />
									</button>
								)}
							{!upcomingPaymentsLoading &&
								upcomingPaymentsSuccess?.length > 4 && (
									<button className="hidden text-xs text-fortcore-green sm:block zabalMedFont">
										View All
									</button>
								)}
						</div>
					</div>
					<div className="hidden sm:grid grid-cols-3 sm:grid-cols-7 lg:grid-cols-10 gap-3 sm:gap-3.5 lg:gap-10 py-1 border-b border-[#F6F7F8] text-sm text-[#96A0A5] zabalMedFont">
						{upcomingPaymentsSuccess?.length > 0 && (
							<>
								<p className="hidden sm:block col-span-1 sm:col-span-3 lg:col-span-4">
									Date
								</p>
								<p className="col-span-1 sm:col-span-2 lg:col-span-4">
									Purpose
								</p>
								<p className="col-span-1 sm:col-span-2 justify-self-end">
									Amount <span className="font-normal text-[11px]">(NGN)</span>
								</p>
							</>
						)}
					</div>
					<div className="space-y-3 pt-2">
						{upcomingPaymentsLoading ? (
							Array(4)
								.fill(0)
								.map((_, i) => <UpcomingPaymentSkeleton key={i} />)
						) : upcomingPaymentsSuccess?.length > 0 ? (
							upcomingPaymentsSuccess.map((d, i) => (
								<div
									className="zabalRegFont flex justify-between sm:grid gap-3 sm:gap-3.5 md:gap-7 lg:gap-10 sm:grid-cols-7 lg:grid-cols-10 text-[#393F42] text-[13px] hover:bg-[#EFF8F9] cursor-pointer py-2 px-1.5 rounded-sm payment"
									key={i}
								>
									<p className="hidden col-span-1 sm:col-span-3 lg:col-span-4 sm:block">
										{moment(d?.paymentDate?.iso).format("MMMM Do YYYY")}
									</p>
									<p className="hidden col-span-1 sm:col-span-2 lg:col-span-4 capitalize sm:block">
										{d?.purpose}
									</p>
									<div className="flex flex-col gap-0.5 capitalize sm:hidden">
										<p className="zabalMedFont">{d?.purpose}</p>
										<p className="text-[11px]">
											<span>Date: </span>
											<span className="text-[#879094]">
												{moment(d?.paymentDate?.iso).format("MMMM Do")}
											</span>
										</p>
									</div>
									<p className="sm:col-span-2 lg:col-span-2 sm:justify-self-end">
										<span className="font-semibold">NGN </span>
										{formatAmountToIncludeCurrency(null, d?.amount)}
									</p>
								</div>
							))
						) : (
							<div className="w-full h-[200px] md:h-[210px]">
								<EmptyData text="You have no upcoming payments." />
							</div>
						)}
					</div>
					{!upcomingPaymentsLoading && upcomingPaymentsSuccess?.length > 4 && (
						<div className="flex justify-center mt-3">
							<button className="flex gap-1 items-center text-fortcore-green text-[12.5px] sm:hidden zabalMedFont">
								<span>View All</span>
								<MdKeyboardArrowDown size={20} />
							</button>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
export default DashOverview;

const UpcomingPaymentSkeleton = () => {
	return (
		<div className="grid grid-cols-2 sm:flex sm:justify-between gap-1.5 sm:gap-6 md:gap-8 lg:gap-10  xl:gap-12">
			<div className="w-full hidden sm:block">
				<div className="w-11/12">
					<Skeleton className="h-[21px] w-full" />
				</div>
			</div>
			<div className="w-full hidden sm:block">
				<div className="w-11/12">
					<Skeleton className="h-[21px] w-full" />
				</div>
			</div>
			<div className="w-full hidden sm:block">
				<div className="w-11/12">
					<Skeleton className="h-[21px] w-full" />
				</div>
			</div>
			<div className="flex flex-col gap-0.5 w-5/6 sm:hidden">
				<div className="w-full max-w-[300px]">
					<Skeleton className="h-[19px] w-full" />
				</div>
				<div className="w-5/6 max-w-[200px]">
					<Skeleton className="h-[15.5px] w-full" />
				</div>
			</div>
			<div className="flex flex-col gap-2 justify-self-end w-5/6 sm:hidden">
				<Skeleton className="h-[19px] w-full max-w-[300px]" />
			</div>
		</div>
	);
};

export const CreditScore = () => {
	const { creditScoreLoading, creditScoreSuccess } = useSelector(
		(state) => state.auth
	);
	return (
		<div className="h-[250px] grid place-items-center">
			<div className="text-center w-11/12 sm:w-8/12 max-w-[400px] flex flex-col gap-3 items-center">
				<div className="h-[80px] w-[80px] md:h-[85px] md:w-[85px] zabalBoldFont">
					{creditScoreLoading ? (
						<Skeleton className="!w-full !h-full !rounded-full" />
					) : creditScoreSuccess?.result ? (
						<CircularProgressbar
							value={creditScoreSuccess.result}
							text={`${creditScoreSuccess.result}%`}
							strokeWidth={5}
							background
							styles={buildStyles({
								textSize: "20px",
								textColor: "#00525D",
								backgroundColor: "#EEF0F1",
								pathColor: "#00525D",
								trailColor: "white",
							})}
						/>
					) : (
						<div className="h-full w-full rounded-full border flex items-center justify-center zabalBoldFont text-lg">
							<p>N/A</p>
						</div>
					)}
				</div>
				<p className="text-sm font-semibold text-[#393F42] zabalBoldFont">
					Credit Score
				</p>
				<p className="text-xs leading-5 text-[#8E9DA4] zabalRegFont">
					Build your credit score and have access to reduced rates as you meet
					your obligations on time.
				</p>
			</div>
		</div>
	);
};
