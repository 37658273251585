import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Parse from "../../../../../api/ApiConfig";
import { HiLockClosed } from "react-icons/hi";
import BrowseFormContext from "../BrowseFormContext";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import Navbar from "../../../../../layout/Navbar";
import {
	formatAmountToIncludeCurrency,
	formatDuration,
	formatDurationForProgramSavings,
	onBlurAmount,
	onInputAmount,
} from "../../../../../common/utils";
import { InfoIcon } from "../../../../../layout/assets";
import { BiSolidLock } from "react-icons/bi";
import theme from "../../../../../components/mui/theme";
import userContext from "../../../../../context/UserContext";
import {
	FatBackTracker,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import { businessTypes, durationFrame } from "../../../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { onSaveUserChooseCreatedFinance } from "../../../../../redux/financing/financeActions";
// import userContext from "../../../../../context/UserContext";

const durationList = [];

for (let i = 1; i <= 24; i++) {
	durationList.push({
		value: i,
		label: i,
	});
}

function MarketForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { userFinanceRequest } = useSelector((state) => state.finance);
	const [loading] = useState(false);
	const {
		marketPlace,
		setMarketPlace,
		// financeType,
		// businessType,
		// setBusinessType,
	} = useContext(BrowseFormContext);
	const { afRate } = useContext(userContext);
	useEffect(() => {
		if (userFinanceRequest?.duration) {
			const [time, text] = userFinanceRequest.duration.split(" ");

			setMarketPlace((prevState) => ({
				...prevState,
				durationText: text,
				durationTime: time,
			}));
		}
	}, [userFinanceRequest?.duration]);

	useEffect(() => {
		const calculateAmountRepayableAT = () => {
			const getTime = formatDuration(userFinanceRequest?.duration);

			const amount =
				Number(userFinanceRequest?.interest?.replaceAll(",", "")) * getTime;

			const totalAmountRepayable =
				amount +
				Number(userFinanceRequest?.amountToInvest?.replaceAll(",", ""));

			const formattedAmount = totalAmountRepayable?.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			const commitmentFee =
				(Number(userFinanceRequest?.amountToInvest?.replaceAll(",", "")) * 10) /
				100;

			const formattedCommitFee = commitmentFee?.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			setMarketPlace((prevState) => ({
				...prevState,
				commitmentFee: formattedCommitFee,
				amountRepayableAfterTenure: totalAmountRepayable
					? formattedAmount
					: "0.00",
			}));
		};

		calculateAmountRepayableAT();
	}, [
		userFinanceRequest?.amountToInvest,
		userFinanceRequest?.duration,
		userFinanceRequest?.interest,
	]);

	const amount =
		Number(marketPlace?.amountRepayableAfterTenure?.replaceAll(",", "")) || 0;

	useEffect(() => {
		const calculateProgramSavings = () => {
			const getTime = formatDurationForProgramSavings(
				userFinanceRequest?.duration
			);

			const addedFee = amount < 100 ? 0.02 : 0.05;

			const savings = amount / getTime + addedFee || 0;

			const formattedSavings = savings?.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			setMarketPlace((prevState) => ({
				...prevState,
				programSavings: formattedSavings,
			}));
		};

		calculateProgramSavings();
	}, [
		userFinanceRequest?.amountRepayableAfterTenure,
		userFinanceRequest?.duration,
		amount,
	]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setMarketPlace((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleForm = (e) => {
		e.preventDefault();

		const currentUser = Parse.User.current();

		const data = {
			user: currentUser.toPointer(),
			type: userFinanceRequest?.type,
			amount: userFinanceRequest?.amountToInvest,
			commitmentFee: marketPlace?.commitmentFee,
			expectedProfit: userFinanceRequest?.amountToRepay,
			tenure: userFinanceRequest?.duration,
			interest: marketPlace?.interest,
			programSavings: marketPlace?.programSavings,
			businessType: marketPlace?.businessType,
			businessDescription: marketPlace?.businessDescription,
			purpose: marketPlace?.purpose,
			marketDetails: marketPlace?.marketDetails,
			totalAmountRepayable: marketPlace?.amountRepayableAfterTenure,
			transaction: "market",
			interestRate: userFinanceRequest?.interestRate || afRate.interest?.value,
			requestType: "old",
		};

		if (!data?.businessDescription || !data?.purpose || !data?.marketDetails) {
			toast.error("Please fill all fields to proceed");
			return;
		}

		dispatch(onSaveUserChooseCreatedFinance(data));

		setMarketPlace((prev) => ({
			...prev,
			formStep: 1,
			businessProofFile: {
				name: "",
			},
		}));

		navigate("/financing/new-transaction/market-place/form/upload");
	};

	if (!userFinanceRequest?.objectId) {
		return <Navigate to="/financing/new-transaction/market-place" />;
	}

	return (
		<>
			<MobileNavDetails text="New Transaction" />
			<Container>
				<Helmet>
					<title>Fortcore - New Transaction</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="hidden sm:flex gap-1.5 sm:mt-10">
					<UrlTracker
						text="apprenticeship finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="market place"
						onNavigate={() =>
							navigate("/financing/new-transaction/market-place")
						}
					/>
					<UrlTracker text="request finance" isCurrentUrl />
				</div>

				<div className="mb-3 sm:hidden">
					<FatBackTracker
						text="request finance"
						onNavigate={() =>
							navigate("/financing/new-transaction/market-place")
						}
					/>
				</div>
				<div className="sm:mt-10"></div>

				<Box sx={{ background: "#F6F7F8", padding: "20px" }}>
					<Typography variant="h4" color="primary.main">
						Request Apprenticeship Finance
					</Typography>

					<Box
						mt={3}
						mb={3}
						sx={{
							background: "var(--grey-100, #EEF2F3)",
							border: "1px solid var(--grey-300, #D4DEE2)",
							borderRadius: "10px",
							padding: "20px",
						}}
					>
						<Stack spacing={1.5}>
							<Typography
								variant="h4"
								color="initial"
								sx={{ textTransform: "capitalize" }}
							>
								Business Interest: {marketPlace?.businessType}
							</Typography>

							<Typography variant="body1" color="initial">
								Amount: &nbsp;
								<Typography variant="h4" color="initial" component="span">
									NGN {marketPlace.amount}
								</Typography>
							</Typography>

							<Typography variant="body1" color="initial">
								Interest Rate:{" "}
								{marketPlace?.interestRate || afRate?.interest?.value}
							</Typography>
							<Typography variant="body1" color="initial">
								Tenure: {userFinanceRequest?.duration}
							</Typography>

							<Box></Box>
						</Stack>
					</Box>

					<Box width="100%">
						<form onSubmit={handleForm}>
							<Stack spacing={4}>
								{/* Commitment Fee */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Commitment Fee :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="tel"
											name="fee"
											value={marketPlace.commitmentFee}
											onChange={handleInputChange}
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),

												endAdornment: (
													<InputAdornment position="start">
														<Stack
															direction="row"
															alignItems="center"
															spacing={0.5}
														>
															<Typography
																color="primary"
																sx={{ fontFamily: "ZabalMedium" }}
															>
																(10P/A)
															</Typography>
															<BiSolidLock
																size={15}
																color={theme.palette.primary.main}
															/>
														</Stack>
													</InputAdornment>
												),
											}}
											sx={{ width: "100%" }}
											required
										/>
									</div>
								</div>

								{/* Expected Profit */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Expected Profit :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="expectedProfit"
											value={formatAmountToIncludeCurrency(
												undefined,
												userFinanceRequest?.amountToRepay
											)}
											placeholder="Amount"
											sx={{ width: "100%" }}
											inputMode="numeric"
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
											}}
											onInput={onInputAmount}
											required
										/>
									</div>
								</div>

								{/* Duration Of Apprentice Financing */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Apprentice Finance Duration :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<Stack direction="row" alignItems="center" spacing={2}>
											<TextField
												select
												name="durationText"
												value={marketPlace.durationText}
												onChange={handleInputChange}
												sx={{ width: "100%", textTransform: "capitalize" }}
												InputProps={{
													readOnly: true,
												}}
												required
											>
												{durationFrame.map((option, index) => (
													<MenuItem
														key={index}
														value={option.value}
														sx={{ textTransform: "capitalize" }}
													>
														{option.label}
													</MenuItem>
												))}
											</TextField>

											<TextField
												select
												name="durationTime"
												value={marketPlace.durationTime}
												onChange={handleInputChange}
												sx={{ width: "100%", textTransform: "capitalize" }}
												InputProps={{
													readOnly: true,
												}}
												required
											>
												{durationList.map((option, index) => (
													<MenuItem
														key={index}
														value={option.value}
														sx={{ textTransform: "capitalize" }}
													>
														{option.label}
													</MenuItem>
												))}
											</TextField>
										</Stack>
									</div>
								</div>

								{/* Interest Rate */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Interest :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="interestAmount"
											value={marketPlace.interest}
											onChange={handleInputChange}
											placeholder="Automatically Generated"
											sx={{ width: "100%" }}
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="start">
														<Stack
															direction="row"
															alignItems="center"
															spacing={0.5}
														>
															<Typography
																color="primary"
																sx={{ fontFamily: "ZabalMedium" }}
															>
																{/* ({marketPlace.interestRate}P/A) */}(
																{afRate.interest?.value}P/A)
															</Typography>
															<BiSolidLock
																size={15}
																color={theme.palette.primary.main}
															/>
														</Stack>
													</InputAdornment>
												),
											}}
											required
										/>
									</div>
								</div>

								{/* Total Amount Repayable After Tenure */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Amount Repayable After Tenure :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="tel"
											name="amountRepayableAfterTenure"
											value={marketPlace?.amountRepayableAfterTenure}
											onChange={handleInputChange}
											placeholder="Amount Repayable"
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="start">
														<HiLockClosed />
													</InputAdornment>
												),
											}}
											sx={{ width: "100%" }}
											required
											onInput={onInputAmount}
										/>
									</div>
								</div>

								{/* Program Savings  */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										<Stack direction="row" alignItems="center">
											Program Savings : &nbsp; <InfoIcon />
										</Stack>
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="tel"
											name="programSavings"
											value={marketPlace.programSavings}
											onChange={handleInputChange}
											placeholder=""
											sx={{ width: "100%" }}
											inputMode="numeric"
											InputProps={{
												readOnly: true,
												startAdornment: (
													<InputAdornment position="start">
														<Typography sx={{ fontFamily: "ZabalMedium" }}>
															NGN
														</Typography>
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="start">
														<Stack
															direction="row"
															alignItems="center"
															spacing={0.5}
														>
															<BiSolidLock
																size={15}
																color={theme.palette.primary.main}
															/>
														</Stack>
													</InputAdornment>
												),
											}}
											required
											onInput={onInputAmount}
											onBlur={(e) =>
												onBlurAmount(e, marketPlace.programSavings)
											}
										/>
									</div>
								</div>

								{/* Business Type */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Business Type :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											select
											name="businessType"
											placeholder="Id type"
											value={marketPlace.businessType}
											onChange={handleInputChange}
											sx={{ width: "100%", textTransform: "capitalize" }}
											required
										>
											{businessTypes.map((option, index) => (
												<MenuItem
													key={index}
													value={option.label}
													sx={{ textTransform: "capitalize" }}
												>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</div>
								</div>

								{/* Description Of Your Business */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										Description Of Your Business :
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="businessDescription"
											value={marketPlace.businessDescription}
											onChange={handleInputChange}
											placeholder="Description"
											sx={{ width: "100%" }}
											required
										/>
									</div>
								</div>

								{/* Purpose */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										<Stack direction="row" alignItems="center">
											Purpose : &nbsp; <InfoIcon />
										</Stack>
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="purpose"
											value={marketPlace.purpose}
											onChange={handleInputChange}
											placeholder="Purpose"
											sx={{ width: "100%" }}
											required
										/>
									</div>
								</div>

								{/* Market Details */}
								<div className="flex flex-col items-center sm:flex-row gap-2 sm:gap-4 md:gap-5">
									<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[35%] md:w-2/5 xl:w-2/6">
										<Stack direction="row" alignItems="center">
											Market Details : &nbsp; <InfoIcon />
										</Stack>
									</p>
									<div className="w-full h-[45px] sm:w-[65%] md:w-3/5 xl:w-4/6">
										<TextField
											type="text"
											name="marketDetails"
											value={marketPlace.marketDetails}
											onChange={handleInputChange}
											placeholder="Market Details"
											sx={{ width: "100%" }}
											required
										/>
									</div>
								</div>
							</Stack>

							<Box pt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
								<Stack direction="row" alignItems="center" spacing={1}>
									<Button type="submit" variant="contained" disabled={loading}>
										{loading ? (
											<CircularProgress size="20px" sx={{ color: "#fff" }} />
										) : (
											"Proceed"
										)}
									</Button>
								</Stack>
							</Box>
						</form>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default MarketForm;
