import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../layout/Navbar";
import { MobileNavDetails } from "../../../layout/MobileNavbar";
import Container from "../../../layout/Container";
import {
	BackTracker,
	CustomBigSelect,
	SuccessCheckModal,
	UrlTracker,
} from "../../../layout/CustomComponents";
import { useDispatch, useSelector } from "react-redux";
import { DesktopSideModal } from "../../../components/ModalContainer";
import FinanceForm from "./FinanceForm";
import {
	onSelectFinanceOption,
	onToggleFinanceFormModal,
} from "../../../redux/financing/financeActions";
import { getProfilePercentage } from "../../../common/utils";
import { clearUpdateAccountSuccess } from "../../../redux/authRedux/authActions";
import InvestorForm from "../../leasing/investor/InvestorForm";
import {
	closeInvestorForm,
	openInvestorForm,
} from "../../../redux/lease/leaseActions";
import userContext from "../../../context/UserContext";
import BrowseFormContext from "./browse/BrowseFormContext";

function NewFinanceTransaction() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { setRequestFinance } = useContext(userContext);
	const { setMarketPlace } = useContext(BrowseFormContext);

	const { currentUser, updatingUserAccountSuccess } = useSelector(
		(state) => state.auth
	);
	const { toggleInvestorForm } = useSelector((state) => state.lease);
	const { toggleFinanceModal, selectedFinanceOption } = useSelector(
		(state) => state.finance
	);

	const [toggleApprentice, setToggleApprentice] = useState(false);
	const [toggleInvestor, setToggleInvestor] = useState(false);

	useEffect(() => {
		setMarketPlace({
			amount: "",
			businessType: "",
			interest: "",
			tenure: "",
			commitmentFee: "",
			expectedProfit: "",
			durationText: "",
			durationTime: "",
			programSavings: "",
			programSavingsRate: "",
			type: "",
			businessDescription: "",
			purpose: "",
			marketDetails: "",
			interestRate: "",

			proofOfPurchase: "",
			proofOfPurchaseDoc: null,
			businessProofFile: {
				name: "",
			},
			attachmentFile: {
				name: "",
			},
			productPhoto: [],

			idCard: {
				name: "",
			},
			utilityBill: {
				name: "",
			},
			autoDebit: false,
			insurance: false,
			guarantor: false,
			paymentType: "",

			signature: "",
		});
		setRequestFinance({
			formStep: 0,
			amount: "",
			businessInterest: "",
			interest: "",
			tenure: "",
			commitmentFee: "",
			expectedProfit: "",
			durationText: "",
			durationTime: "",
			programSavings: "",
			programSavingsRate: "",
			type: "",
			businessType: "",
			businessDescription: "",
			purpose: "",
			marketDetails: "",
			interestRate: "",

			proofOfPurchase: "",
			proofOfPurchaseDoc: null,
			attachmentFile: {
				name: "",
			},
			productPhoto: [],

			idCard: {
				name: "",
			},
			utilityBill: {
				name: "",
			},
			autoDebit: false,
			insurance: false,
			guarantor: false,
			paymentType: "",

			signature: "",
		});
		dispatch(onToggleFinanceFormModal(false));
	}, []);

	// check if profile is complete
	const financeProfileValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const financeProfilePercentage = getProfilePercentage(
		financeProfileValidation
	);

	// check if investor profile is complete
	const investorProfileValidation = {
		phoneNumber: currentUser?.phone,
		phoneNumberVerified: currentUser?.phoneVerified,
		homeAddress: currentUser?.homeAddress,
		bvn: currentUser?.bvn,
		bankName: currentUser?.bankName,
		accountNumber: currentUser?.accountNumber,
		netWorth: currentUser?.netWorth,
		investorSignature: currentUser?.investorSignature,
		witnessSignature: currentUser?.witnessSignature,
		idType: currentUser?.idType,
		idNumber: currentUser?.idNo,
	};

	const investorProfilePercentage = getProfilePercentage(
		investorProfileValidation
	);

	// on maybe later btn click
	const onMaybeLaterBtnClick = () => {
		if (selectedFinanceOption === "browse") {
			navigate("/financing/new-transaction/market-place");
		} else if (selectedFinanceOption === "request") {
			navigate("/financing/new-transaction/request");
		} else if (selectedFinanceOption === "investor-browse") {
			navigate("/financing/new-transaction/investor-market-place");
		} else if (selectedFinanceOption === "investor-create") {
			navigate("/financing/new-transaction/investor-create");
		}

		setTimeout(() => {
			dispatch(onToggleFinanceFormModal(false));
		}, 300);
	};

	const onBrowseFinanceClick = () => {
		if (financeProfilePercentage < 33) {
			dispatch(onToggleFinanceFormModal(true));
			dispatch(onSelectFinanceOption("browse"));
		} else {
			navigate("/financing/new-transaction/market-place");
		}
	};

	const onRequestFinanceClick = () => {
		if (financeProfilePercentage < 33) {
			dispatch(onToggleFinanceFormModal(true));
			dispatch(onSelectFinanceOption("request"));
		} else {
			setRequestFinance((prev) => ({ ...prev, formStep: 0 }));
			navigate("/financing/new-transaction/request");
		}
	};

	const onInvestorBrowseClick = () => {
		if (investorProfilePercentage < 48) {
			dispatch(openInvestorForm());
			dispatch(onSelectFinanceOption("investor-browse"));
		} else {
			navigate("/financing/new-transaction/investor-market-place");
		}
	};

	const onInvestorCreateClick = () => {
		if (investorProfilePercentage < 48) {
			dispatch(openInvestorForm());
			dispatch(onSelectFinanceOption("investor-create"));
		} else {
			navigate("/financing/new-transaction/investor-create");
		}
	};

	const onNoInvestorFormSubmit = () => {
		if (selectedFinanceOption === "investor-browse") {
			navigate("/financing/new-transaction/investor-market-place");
		} else {
			navigate("/financing/new-transaction/investor-create");
		}
		setTimeout(() => {
			dispatch(closeInvestorForm());
		}, 300);
	};

	const onFinanceFormSaveSuccess = () => {
		onMaybeLaterBtnClick();

		setTimeout(() => {
			dispatch(clearUpdateAccountSuccess());
			dispatch(onToggleFinanceFormModal(false));
		}, 300);
	};

	const onInvestorFormSaveSuccess = () => {
		if (selectedFinanceOption === "investor-browse") {
			navigate("/financing/new-transaction/investor-browse");
		} else {
			navigate("/financing/new-transaction/investor-create");
		}

		setTimeout(() => {
			dispatch(clearUpdateAccountSuccess());
			dispatch(closeInvestorForm());
		}, 300);
	};

	return (
		<>
			{toggleFinanceModal && (
				<DesktopSideModal modal={toggleFinanceModal}>
					<>
						{updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onFinanceFormSaveSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							<FinanceForm
								onCloseForm={() => dispatch(onToggleFinanceFormModal(false))}
								onMaybeBtnClick={onMaybeLaterBtnClick}
							/>
						)}
					</>
				</DesktopSideModal>
			)}
			{toggleInvestorForm && (
				<DesktopSideModal modal={toggleInvestorForm}>
					{updatingUserAccountSuccess ? (
						<div className="w-full grid place-items-center">
							<SuccessCheckModal
								text="Form details saved successfully"
								onRequestClick={onInvestorFormSaveSuccess}
								isShowBtn={true}
								btnText="proceed"
							/>
						</div>
					) : (
						<InvestorForm
							onCloseForm={() => dispatch(closeInvestorForm())}
							onNoInvestorFormSubmit={onNoInvestorFormSubmit}
						/>
					)}
				</DesktopSideModal>
			)}
			<MobileNavDetails text="Finance" />
			<Container>
				<Helmet>
					<title>Fortcore - Finance</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="flex gap-1.5">
					<UrlTracker
						text="apprentice finance"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker text="new transaction" isCurrentUrl />
				</div>

				<BackTracker
					text="apprentice finance"
					onNavigate={() => navigate(-1)}
				/>
				<div className="pb-6 sm:py-5 md:py-6 space-y-1.5">
					<p className="text-[14.5px] text-fortcore-green zabalBoldFont">
						New Transaction
					</p>
					<p className="text-xs text-[#717E83] zabalMedFont">
						How would you like to explore the service?
					</p>
				</div>
				<div className="grid sm:grid-cols-2 gap-4 md:gap-5 lg:gap-6 items-start mb-8">
					<CustomBigSelect
						text="Apprentice"
						toggleModal={toggleApprentice}
						onRequestToggle={() => {
							setToggleApprentice((prev) => !prev);
							setToggleInvestor(false);
						}}
					>
						<>
							{toggleApprentice && (
								<div className="mt-3.5 grid gap-3 pb-7 ">
									<FinanceSelectType
										title="Browse"
										onRequestClick={onBrowseFinanceClick}
									/>
									<FinanceSelectType
										title="Request"
										onRequestClick={onRequestFinanceClick}
									/>
								</div>
							)}
						</>
					</CustomBigSelect>
					<CustomBigSelect
						text="Investor"
						toggleModal={toggleInvestor}
						onRequestToggle={() => {
							setToggleInvestor((prev) => !prev);
							setToggleApprentice(false);
						}}
					>
						<>
							{toggleInvestor && (
								<div className="mt-3.5 grid gap-3 pb-7 ">
									<FinanceSelectType
										title="Browse through available assets"
										onRequestClick={onInvestorBrowseClick}
									/>
									<FinanceSelectType
										title="Create Apprenticeship Finance"
										onRequestClick={onInvestorCreateClick}
									/>
								</div>
							)}
						</>
					</CustomBigSelect>
				</div>
			</Container>

			{/* {browse ? (
				<BrowseForm open={browse} handleClose={() => setBrowse(false)} />
			) : null}
			{browse2 ? (
				<BrowseForm2 open={browse2} handleClose={() => setBrowse2(false)} />
			) : null}
			{investorBrowse ? (
				<BrowseInvestorForm
					open={investorBrowse}
					handleClose={() => setInvestorBrowse(false)}
					handleMaybeLater={handleMaybeLater}
					onClick={handleProceed}
				/>
			) : null} */}
		</>
	);
}

export default NewFinanceTransaction;

const FinanceSelectType = ({ onRequestClick, title }) => {
	return (
		<div
			className="flex items-center justify-between border border-[#E3E9EC] rounded-md p-3.5 cursor-pointer innerCard hover:border-[#AABDC5] transition-colors"
			onClick={onRequestClick}
		>
			<p className="text-[13.5px] text-[#393F42] hover:text-fortcore-green zabalMedFont">
				{title}
			</p>
		</div>
	);
};

FinanceSelectType.propTypes = {
	onRequestClick: PropTypes.func,
	title: PropTypes.string,
};

{
	/* <Box pt={5}>
					<CustomTwoGridBox>
						<CustomCardDropDown
							text="Apprentice"
							dropDown={apprenticeDropDown}
							setDropDown={() => {
								setApprenticeDropDown(true);
								setInvestorDropDown(false);
							}}
							dropClose={() => setApprenticeDropDown(false)}
							buttonText1="Browse"
							buttonText2="Request"
							onClick1={() => {
								if (
									currentUserData.bvnVerified &&
									currentUserData.accountVerified &&
									currentUserData.homeAddress &&
									currentUserData.phone &&
									currentUserData.idType &&
									currentUserData.idNo
								) {
									navigate("/financing/new-transaction/market-place");
								} else {
									setBrowse(true);
								}
							}}
							onClick2={() => {
								if (
									currentUserData.bvnVerified &&
									currentUserData.accountVerified &&
									currentUserData.homeAddress &&
									currentUserData.phone &&
									currentUserData.idType &&
									currentUserData.idNo
								) {
									navigate("/financing/new-transaction/request");
								} else {
									setBrowse2(true);
								}
							}}
						/>

						{/* Investor  */
}

// 		<CustomCardDropDown
// 			text="Investor"
// 			dropDown={investorDropDown}
// 			setDropDown={() => {
// 				setInvestorDropDown(true);
// 				setApprenticeDropDown(false);
// 			}}
// 			dropClose={() => setInvestorDropDown(false)}
// 			buttonText1="Browse through available assets"
// 			onClick1={() => {
// 				if (
// 					// currentUserData.investorSignature &&
// 					// currentUserData.witnessSignature &&
// 					currentUserData.bvn &&
// 					currentUserData.accountNumber &&
// 					currentUserData.accountName &&
// 					currentUserData.bankName &&
// 					currentUserData.bvnVerified &&
// 					currentUserData.accountVerified &&
// 					currentUserData.homeAddress &&
// 					currentUserData.phone &&
// 					currentUserData.idType &&
// 					currentUserData.idNo &&
// 					currentUserData.netWorth
// 				) {
// 					navigate("/financing/new-transaction/investor-market-place");
// 				} else {
// 					setInvestorMarketPlace(true);
// 					setInvestorCreate(false);
// 					setInvestorBrowse(true);
// 				}
// 			}}
// 			buttonText2="Create Apprenticeship Finance"
// 			onClick2={() => {
// 				if (
// 					currentUserData.bvn &&
// 					currentUserData.accountNumber &&
// 					currentUserData.accountName &&
// 					currentUserData.bankName &&
// 					currentUserData.bvnVerified &&
// 					currentUserData.accountVerified &&
// 					currentUserData.homeAddress &&
// 					currentUserData.phone &&
// 					currentUserData.idType &&
// 					currentUserData.idNo &&
// 					currentUserData.netWorth
// 				) {
// 					navigate("/financing/new-transaction/investor-create");
// 				} else {
// 					setInvestorCreate(true);
// 					setInvestorMarketPlace(false);
// 					setInvestorBrowse(true);
// 				}
// 			}}
// 		/>
// 	</CustomTwoGridBox>
// </Box> */}
