import {
	AppBar,
	Box,
	Button,
	Container,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Toolbar,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import theme from "../mui/theme";
import Logo from "../../assets/logosvg.svg";
import { GrClose, GrMenu } from "react-icons/gr";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Parse from "../../api/ApiConfig";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { NavHashLink } from "react-router-hash-link";
import userContext from "../../context/UserContext";

function HeaderNav() {
	const navigate = useNavigate();
	const currentUser = Parse.User.current();
	const [dropMobile, setDropMobile] = useState(false);
	const { setCreateAccountModal } = useContext(userContext);

	useEffect(() => {
		if (currentUser) {
			Parse.User.logOut();
		}
	}, []);
	const [menuToggle, setMenuToggle] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar
					position="fixed"
					sx={{
						background: theme.palette.primary.light,
						borderBottom: "1px solid var(--grey-100, #EEF2F3)",
					}}
					elevation={0}
				>
					<Toolbar>
						<Container maxWidth="lg">
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Stack direction="row" alignItems="center" spacing={15}>
									<Link
										to="/"
										onClick={() => {
											// eslint-disable-next-line no-undef
											window.scrollTo({
												behavior: "smooth",
												top: 0,
											});
										}}
									>
										<img src={Logo} alt="fortcore logo" />
									</Link>
									<Box component="ul" sx={style}>
										<li>
											<Button
												variant="text"
												direction="row"
												// alignItems="center"
												sx={{
													cursor: "pointer",
													"&:hover": { background: "transparent" },
												}}
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup="true"
												aria-expanded={open ? "true" : undefined}
												onMouseEnter={handleClick}
											>
												Alternative Finance &nbsp;
												<IoIosArrowDown size={20} />
											</Button>

											<Menu
												id="basic-menu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												MenuListProps={{
													"aria-labelledby": "basic-button",
												}}
												sx={{
													li: {
														color: "#393F42",
														"&:hover": {
															color: theme.palette.primary.main,
															background: "none",
															fontFamily: "ZabalMedium",
														},
													},
												}}
											>
												<NavHashLink smooth to="/#leasing">
													<MenuItem
														sx={{ width: "300px" }}
														onClick={handleClose}
													>
														Leasing
													</MenuItem>
												</NavHashLink>
												<Divider
													sx={{ borderColor: "#EEF2F3", mr: 2, ml: 2 }}
												/>
												<NavHashLink smooth to="/#apprentice">
													<MenuItem
														sx={{ width: "300px" }}
														onClick={handleClose}
													>
														Apprentice Finance
													</MenuItem>
												</NavHashLink>
												<Divider
													sx={{ borderColor: "#EEF2F3", mr: 2, ml: 2 }}
												/>
												<NavHashLink smooth to="/#travel">
													<MenuItem
														sx={{ width: "300px" }}
														onClick={handleClose}
													>
														Travel Advance
													</MenuItem>
												</NavHashLink>
											</Menu>
										</li>
										<li>
											<NavLink to="/">Learn</NavLink>
										</li>
									</Box>
								</Stack>

								{/* Auth Button  */}
								<Stack
									spacing={2}
									direction="row"
									sx={{ display: { xs: "none", md: "flex" } }}
								>
									<Button
										variant="outlined"
										sx={{ width: "158px" }}
										onClick={() => navigate("/login")}
									>
										Login
									</Button>
									<Button
										variant="contained"
										sx={{
											width: "158px",
										}}
										// onClick={() => navigate("/create-account")}
										onClick={() => setCreateAccountModal(true)}
									>
										Create Account
									</Button>
								</Stack>

								{!menuToggle ? (
									<IconButton
										color="primary.main"
										sx={{ display: { xs: "flex", md: "none" } }}
										onClick={() => setMenuToggle(true)}
									>
										<GrMenu size={28} />
									</IconButton>
								) : (
									<IconButton
										color="primary.main"
										sx={{ display: { xs: "flex", md: "none" } }}
										onClick={() => setMenuToggle(false)}
									>
										<GrClose size={28} />
									</IconButton>
								)}
							</Stack>
						</Container>
					</Toolbar>
				</AppBar>
			</Box>

			{menuToggle && (
				<Box sx={styleMobile}>
					<Container maxWidth="lg">
						<Stack direction="row" spacing={2}>
							<Button
								variant="outlined"
								onClick={() => {
									setMenuToggle(false);
									navigate("/login");
								}}
								fullWidth
							>
								Login
							</Button>

							<Button
								variant="contained"
								onClick={() => {
									setCreateAccountModal(true);
									setMenuToggle(false);
								}}
								fullWidth
							>
								Create Account
							</Button>
						</Stack>
						<Stack mt={5} spacing={3}>
							{!dropMobile ? (
								<Stack
									direction="row"
									alignItems="center"
									sx={{ cursor: "pointer", fontFamily: "ZabalRegular" }}
									onClick={() => setDropMobile(true)}
								>
									Alternative Finance &nbsp;
									<IoIosArrowDown size={20} />
								</Stack>
							) : (
								<Stack
									direction="row"
									alignItems="center"
									sx={{
										cursor: "pointer",
										color: "#00525D",
										fontFamily: "ZabalMedium",
									}}
									onClick={() => setDropMobile(false)}
								>
									Alternative Finance &nbsp;
									<IoIosArrowUp size={20} />
								</Stack>
							)}
							{dropMobile ? (
								<Box
									component="ul"
									sx={{
										width: "100%",
										li: {
											color: "#393F42",
											width: "100%",
											"&:hover": {
												color: theme.palette.primary.main,
												background: "none",
												fontFamily: "ZabalMedium",
											},
										},
									}}
								>
									<Stack spacing={3}>
										<li onClick={() => setMenuToggle(false)}>
											<NavHashLink smooth to="/#leasing">
												Leasing
											</NavHashLink>
										</li>
										<li onClick={() => setMenuToggle(false)}>
											<NavHashLink smooth to="/#apprentice">
												Apprentice Finance
											</NavHashLink>
										</li>
										<li onClick={() => setMenuToggle(false)}>
											<NavHashLink smooth to="/#travel">
												Travel Advance
											</NavHashLink>
										</li>
									</Stack>
								</Box>
							) : null}
							<Divider />
							<NavLink to="/">Learn</NavLink>
						</Stack>
					</Container>
				</Box>
			)}
		</>
	);
}

export default HeaderNav;

const style = {
	display: { xs: "none", md: "flex" },
	alignItems: { md: "center" },
	li: {
		color: "#393F42",
		marginRight: "25px",
	},
};

const styleMobile = {
	width: "100%",
	height: "100vh",
	background: "#fff",
	position: "fixed",
	top: 0,
	zIndex: "10",
	paddingTop: "10vh",
};
