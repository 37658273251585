import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { BackTracker } from "../../../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../../../layout/CustomButtons";
import { saveDataFromLeaseStructure } from "../../../redux/lease/leaseActions";
import { FaArrowLeft } from "react-icons/fa";
import { DesktopSideModal } from "../../../components/ModalContainer";
import ItemDescription from "../marketplace/ItemDescription";
import {
	LeaseStructureItem,
	deliveryTypeArr,
	expectedDuration,
} from "../requestlease/LeaseStructure";
import {
	DualLeaseFormSelect,
	LeaseFormSelect,
} from "../requestlease/LeaseForm";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { LeaseSummaryInput } from "../requestlease/LeaseSummary";
import { getSingularOrPlural } from "../../../common/utils";
import { MdLock } from "react-icons/md";
import { fetchCurrentInterest } from "../../../redux/lease/leaseServices";

const RequestLeaseStructure = ({ setActiveTab }) => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		selectedReqLeaseItem,
		toggleMarketItemsModal,
		leaseStructureData,
		currentGeneratedinterest,
		leaseFormData,
	} = useSelector((state) => state.lease);

	const [leaseStructureForm, setLeaseStructureForm] = useState({
		lease_period:
			leaseStructureData?.leaseDuration?.split(" ")[1] ||
			selectedReqLeaseItem?.leaseDuration?.split(" ")[1] ||
			"",
		lease_length:
			leaseStructureData?.leaseDuration?.split(" ")[0] ||
			selectedReqLeaseItem?.leaseDuration?.split(" ")[0] ||
			"",
		lease_structure:
			selectedReqLeaseItem?.leaseStructure ||
			leaseStructureData?.leaseStructure ||
			"",
		delivery_type: leaseStructureData?.deliveryType || "",
		interest:
			selectedReqLeaseItem?.interest || leaseStructureData?.interest || "",
	});

	const {
		lease_structure,
		delivery_type,
		lease_length,
		lease_period,
		interest,
	} = leaseStructureForm;

	// keep one drop down open a time
	const [formsToggler, setFormsToggler] = useState([
		{ id: 0, toggleForm: false },
		{ id: 1, toggleForm: false },
		{ id: 2, toggleForm: false },
		{ id: 3, toggleForm: false },
	]);

	const toggleModal = (id) => {
		setFormsToggler(
			formsToggler.map((a) =>
				a.id === id
					? { ...a, toggleForm: !a.toggleForm }
					: { ...a, toggleForm: false }
			)
		);
	};

	const handleDurationTwo = () => {
		if (!lease_period) return;
		toggleModal(2);
	};

	useEffect(() => {
		if (!selectedReqLeaseItem) {
			navigate("/leasing/new-transaction/request-lease", {
				state: { currentTab: 1 },
			});
		} else {
			return;
		}
	}, [selectedReqLeaseItem]);

	useEffect(() => {
		if (leaseStructureData?.requestType === "old") {
			return;
		} else {
			dispatch(fetchCurrentInterest("Base Interest rate leasing"));
		}
	}, []);

	useEffect(() => {
		if (currentGeneratedinterest) {
			setLeaseStructureForm((prev) => ({
				...prev,
				interest: currentGeneratedinterest?.value?.replace("%", ""),
			}));
		}
	}, [currentGeneratedinterest]);

	const isProceedBtnValid = () => {
		if (
			lease_length === "" ||
			lease_period === "" ||
			lease_structure === "" ||
			delivery_type === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const onProceedToNextTab = () => {
		const data = {
			interest,
			leaseStructure: lease_structure,
			deliveryType: delivery_type,
			leaseDuration: `${lease_length} ${getSingularOrPlural(
				lease_length,
				lease_period
			)}`,
		};
		dispatch(saveDataFromLeaseStructure(data));
		setActiveTab(4);
	};

	if (selectedReqLeaseItem === null) {
		setActiveTab(2);
	}

	if (!selectedReqLeaseItem) {
		return <Navigate to="/leasing/new-transaction/investor-market-place" />;
	}

	if (leaseStructureData?.requestType === "new" && !leaseFormData) {
		return <Navigate to="/leasing/new-transaction/investor-market-place" />;
	}

	return (
		<>
			{toggleMarketItemsModal && (
				<DesktopSideModal modal={toggleMarketItemsModal}>
					<ItemDescription />
				</DesktopSideModal>
			)}
			<BackTracker
				text="Request Lease"
				onNavigate={
					state?.currentTab == 3 ? () => navigate(-1) : () => setActiveTab(2)
				}
			/>
			<div className="mb-4 hidden sm:flex gap-2.5 items-center text-[13px] sm:text-sm text-fortcore-green zabalMedFont font-semibold">
				<button
					className="zabalMedFont"
					onClick={
						state?.currentTab == 3 ? () => navigate(-1) : () => setActiveTab(2)
					}
				>
					<FaArrowLeft />
				</button>
				<p>Request Lease</p>
			</div>
			<div className="rounded-md bg-[#F6F7F8] p-4 sm:px-5 md:px-6 space-y-4 md:space-y-5 pb-8">
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseStructureItem
						item={
							leaseStructureData?.requestType === "old"
								? selectedReqLeaseItem?.productObj
								: selectedReqLeaseItem
						}
					/>
					{leaseStructureData?.requestType === "new" ? (
						<>
							<LeaseFormSelect
								text="lease structure"
								context="choose lease structure"
								isOpened={formsToggler[0].toggleForm}
								currentText={lease_structure}
								handleToggle={() => toggleModal(0)}
								arrOptions={["Lease To Own", "Operating Lease"]}
								onHandleSelect={(d) => {
									setLeaseStructureForm((prev) => ({
										...prev,
										lease_structure: d,
									}));
									setTimeout(() => {
										toggleModal(0);
									}, 100);
								}}
							/>
							<DualLeaseFormSelect
								label="Lease Duration"
								dropDownOne={{
									context: "duration",
									currentText: lease_period,
									isOpened: formsToggler[1].toggleForm,
									handleToggle: () => toggleModal(1),
									arrOptions: expectedDuration.map((a) => a.duration),
								}}
								handleSelectOne={(d) => {
									setLeaseStructureForm((prev) => ({
										...prev,
										lease_period: String(d),
										lease_length: "",
									}));
									setTimeout(() => toggleModal(1), 100);
								}}
								dropDownTwo={{
									context: "length",
									currentText: lease_length,
									isOpened: formsToggler[2].toggleForm,
									handleToggle: handleDurationTwo,
									arrOptions: expectedDuration
										?.find((a) => a.duration === lease_period)
										?.length.map((a) => String(a)),
								}}
								handleSelectTwo={(d) => {
									setLeaseStructureForm((prev) => ({
										...prev,
										lease_length: String(d),
									}));
									setTimeout(() => toggleModal(2), 100);
								}}
							/>
						</>
					) : (
						<>
							<LeaseSummaryInput
								text="lease duration"
								input_value={`${lease_length} ${getSingularOrPlural(
									lease_length,
									lease_period
								)}`}
							/>
							<LeaseSummaryInput
								text="lease structure"
								input_value={lease_structure}
							/>
						</>
					)}
					<div className="flex flex-col items-center sm:flex-row gap-3 sm:gap-4 md:gap-5 text-xs md:text-[13px]">
						<p className="w-full text-[#393F42] text-xs md:text-[13px] zabalMedFont sm:w-[33%] md:w-2/6 lg:w-3/12 capitalize">
							Interest Rate (%):
						</p>
						<div className="h-[45px] w-full sm:w-[67%] md:w-4/6 lg:w-9/12 relative zabalRegFont rounded border border-[#B8B8B8] text-[#393F42] flex gap-1.5 items-center px-2.5">
							<span className="">%</span>
							<span>{interest}</span>
							<span className="text-[#393F42] text-sm">P/A</span>
							<span className="absolute right-3">
								<MdLock size={18} color="#717E83" />
							</span>
						</div>
					</div>
					<LeaseFormSelect
						text="delivery type"
						context="choose delivery type"
						isOpened={formsToggler[3].toggleForm}
						currentText={delivery_type}
						handleToggle={() => toggleModal(3)}
						arrOptions={deliveryTypeArr.map((a) => a.label)}
						onHandleSelect={(d) => {
							setLeaseStructureForm((prev) => ({
								...prev,
								delivery_type: deliveryTypeArr.find((a) => a.label === d).label,
							}));
							setTimeout(() => toggleModal(3), 100);
						}}
					/>

					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						<CustomWhiteBtn
							text="back"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={
								state?.currentTab == 3
									? () => navigate(-1)
									: () => setActiveTab(2)
							}
						/>
						<CustomGreenBtn
							text="proceed"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={onProceedToNextTab}
							isDisabled={isProceedBtnValid()}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						<CustomGreenBtn
							text="proceed"
							onRequestClick={onProceedToNextTab}
							isDisabled={isProceedBtnValid()}
						/>
						<CustomWhiteBtn
							text="back"
							onRequestClick={
								state?.currentTab == 3
									? () => navigate(-1)
									: () => setActiveTab(2)
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default RequestLeaseStructure;

RequestLeaseStructure.propTypes = {
	setActiveTab: PropTypes.func,
};
