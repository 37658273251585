import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { CustomRadioInput } from "../../layout/CustomComponents";
import { CustomGreenBtn, CustomWhiteBtn } from "../../layout/CustomButtons";
import PropTypes from "prop-types";

const AutoDebitModal = ({
	onCloseBtnClick,
	onCanelBtnClick,
	onProceedBtnClick,
	automateSavings,
	handleChange,
}) => {
	return (
		<div className="rounded-md p-5 sm:p-6 flex flex-col gap-5 w-11/12 max-w-[450px] bg-white">
			<div className="flex justify-between items-center">
				<p className="text-sm text-[#393F42] zabalMedFont">
					Do you want to automate your savings?
				</p>
				<button onClick={onCloseBtnClick}>
					<MdOutlineClose size={19} color="#00525D" />
				</button>
			</div>
			<div className="flex flex-col gap-3.5 text-[13px] text-[#393F42] zabalRegFont">
				<div className="flex gap-2 items-center">
					<CustomRadioInput
						name="choose_automate"
						value="auto-debit"
						handleChange={handleChange}
						isChecked={automateSavings === "auto-debit"}
					/>
					<span>Yes, I want to be debited automatically</span>
				</div>
				<div className="flex gap-2 items-center">
					<CustomRadioInput
						name="choose_automate"
						value="my_choice"
						handleChange={handleChange}
						isChecked={automateSavings === "my_choice"}
					/>
					<span>No, I want to save when I want to</span>
				</div>
			</div>
			<p className="text-xs text-[#8E9DA4] zabalRegFont">
				You can modify your automation anytime
			</p>
			<div className="flex flex-col gap-2.5 pt-4 sm:hidden">
				<CustomGreenBtn
					text="proceed"
					onRequestClick={onProceedBtnClick}
					isDisabled={automateSavings === ""}
				/>
				<CustomWhiteBtn text="cancel" onRequestClick={onCanelBtnClick} />
			</div>
			<div className="hidden sm:flex justify-end gap-2.5">
				<CustomWhiteBtn
					width="105px"
					height="41px"
					fontSize="12.25px"
					text="cancel"
					onRequestClick={onCanelBtnClick}
				/>
				<CustomGreenBtn
					width="105px"
					height="41px"
					fontSize="12.25px"
					text="proceed"
					onRequestClick={onProceedBtnClick}
					isDisabled={automateSavings === ""}
				/>
			</div>
		</div>
	);
};
export default AutoDebitModal;

AutoDebitModal.propTypes = {
	onCloseBtnClick: PropTypes.func,
	onCanelBtnClick: PropTypes.func,
	onProceedBtnClick: PropTypes.func,
	handleChange: PropTypes.func,
	automateSavings: PropTypes.string,
};
