import React, { useState } from "react";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import { useSelector, useDispatch } from "react-redux";
import { DesktopSideModal } from "../../../components/ModalContainer";
import PowerOfAttorney from "../../leasing/investor/PowerOfAttorney";
import GeneralInformation from "./GeneralInformation";
import { PersonalFormCardToggler } from "./CompletePersonalForm";
import { updateUserAndInvestorData } from "../../../redux/authRedux/authServices";
import Parse from "../../../api/ApiConfig";
import { netWorthRange } from "../../../common/utils";
import { PlainSelect, SelectDropDown } from "../../../layout/CustomComponents";

const CompleteInvestorForm = () => {
	const dispatch = useDispatch();
	const { currentUser, updatingUserAccountLoading } = useSelector(
		(state) => state.auth
	);

	const [investorForm, setInvestorForm] = useState({
		net_worth: currentUser?.netWorth || "",
		witness_signature: currentUser?.witnessSignature || "",
		investor_signature: currentUser?.investorSignature || "",
	});

	const [toggleNetWorth, setToggleNetWorth] = useState(false);
	const [toggleAdditionalInfo, setToggleAdditionalInfo] = useState(false);
	const [toggleAttorney, setToggleAttorney] = useState(false);

	const { net_worth, witness_signature, investor_signature } = investorForm;

	const handleChange = (e) =>
		setInvestorForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const isAttorneyBtnDisabled = () => {
		if (
			investor_signature?.trim().length < 2 ||
			witness_signature?.trim().length < 2
		) {
			return true;
		} else {
			return false;
		}
	};

	const isBtnDisabled = () => {
		if (
			net_worth === "" ||
			investor_signature === "" ||
			witness_signature === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const onHandleAttorneySubmit = () => {
		setToggleAttorney(false);
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const dataForInvestorProfile = {
		user,
		netWorth: net_worth,
		witnessSignature: witness_signature,
		investorSignature: investor_signature,
		email: currentUser?.email,
	};

	const dataForUserProfile = {
		netWorth: net_worth,
		witnessSignature: witness_signature,
		investorSignature: investor_signature,
	};

	const onSubmitInvestorDetails = () => {
		dispatch(
			updateUserAndInvestorData(dataForInvestorProfile, dataForUserProfile)
		);
	};

	return (
		<>
			{toggleAttorney && (
				<DesktopSideModal modal={toggleAttorney}>
					<PowerOfAttorney
						onClose={() => setToggleAttorney(false)}
						handleChange={handleChange}
						investor_signature={investor_signature}
						witness_signature={witness_signature}
						isAttorneyBtnDisabled={isAttorneyBtnDisabled}
						onHandleSubmit={onHandleAttorneySubmit}
					/>
				</DesktopSideModal>
			)}
			<div className="space-y-3.5 md:space-y-4 lg:space-y-5">
				<GeneralInformation />
				<div className="space-y-2 space-y-2.5">
					<PersonalFormCardToggler
						text="additional information for asset creation"
						isOpen={toggleAdditionalInfo}
						onClick={() => setToggleAdditionalInfo((prev) => !prev)}
					/>
					{toggleAdditionalInfo && (
						<div className="flex flex-col gap-3 md:gap-4 rounded">
							<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7 relative z-[1]">
								<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
									Net worth:
								</p>
								<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
									<PlainSelect
										context="select net worth"
										isOpened={toggleNetWorth}
										currentText={net_worth}
										handleToggle={() => setToggleNetWorth((prev) => !prev)}
									/>
									{toggleNetWorth && (
										<div className="absolute z-[2] w-full top-48px pb-8">
											<SelectDropDown
												arrOptions={netWorthRange}
												onHandleSelect={(d) => {
													setInvestorForm((prev) => ({
														...prev,
														net_worth: d,
													}));
													setTimeout(() => {
														setToggleNetWorth(false);
													}, 100);
												}}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-5 md:gap-7">
								<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
									Power Of Attorney:
								</p>
								<div className="zabalRegFont w-full sm:w-[70%] md:w-4/5 text-sm">
									<button
										className="underline zabalMedFont"
										onClick={() => setToggleAttorney(true)}
									>
										View Details
									</button>
									{isAttorneyBtnDisabled() && (
										<p className="pt-0.5 text-xs zabalRegFont  text-red-500">
											Please view and append signatures*
										</p>
									)}
								</div>
							</div>
							<div className="hidden sm:flex justify-end py-2">
								<CustomGreenBtn
									text="save"
									width="105px"
									height="42px"
									fontSize="13px"
									isDisabled={updatingUserAccountLoading || isBtnDisabled()}
									isLoading={updatingUserAccountLoading}
									onRequestClick={onSubmitInvestorDetails}
								/>
							</div>
							<div className="sm:hidden pt-4 pb-2">
								<CustomGreenBtn
									text="save"
									isDisabled={updatingUserAccountLoading || isBtnDisabled()}
									isLoading={updatingUserAccountLoading}
									onRequestClick={onSubmitInvestorDetails}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
export default CompleteInvestorForm;
