import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { MdLock } from "react-icons/md";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import {
	formatPhoneNumber,
	onBlurAmount,
	onInputAmount,
	validateEmail,
} from "../../../common/utils";
import { IsValidIcon } from "../../../layout/assets";
import { Preloader } from "../../../components/globalStyled";
import {
	disableEmailButton,
	disablePhoneButton,
	getPhoneOTP,
	getWorkEmailOtp,
	updateUserData,
	verifyEmailOtp,
	verifyPhoneOTP,
} from "../../../redux/authRedux/authServices";
import CountDown from "../../../components/Countdown";
import { toast } from "react-toastify";
import Toolip from "../../../components/Toolip";
import Parse from "../../../api/ApiConfig";

const PersonalInfo = () => {
	const dispatch = useDispatch();
	const currentSessionToken = Parse.User.current()?.getSessionToken();

	const {
		currentUser,
		updatingUserAccountLoading,
		emailOtpMessageSuccess,
		emailOtpMessageLoading,
		phoneOtpMessageSuccess,
		phoneOtpMessageLoading,
		verifyEmailOtpLoading,
		verifyEmailOtpSuccess,
		isShowEmailTimer,
		isShowPhoneTimer,
		verifyPhoneOtpLoading,
		verifyPhoneOtpSuccess,
	} = useSelector((state) => state.auth);

	const [personalInfo, setPersonalInfo] = useState({
		first_name: currentUser?.firstName || "",
		last_name: currentUser?.lastName || "",
		email: currentUser?.email || "",
		work_email: currentUser?.workEmail || "",
		address: currentUser?.homeAddress || "",
		phone_number: currentUser?.phone || "",
		phone_code: "",
		email_code: "",
	});

	const {
		first_name,
		last_name,
		email,
		work_email,
		address,
		phone_number,
		phone_code,
		email_code,
	} = personalInfo;

	const handleChange = (e) =>
		setPersonalInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const checkPhone = () => {
		if (!currentUser?.phoneVerified) {
			return true;
		}
		if (
			formatPhoneNumber(currentUser?.phone) !== formatPhoneNumber(phone_number)
		) {
			return true;
		} else {
			return false;
		}
	};

	const checkEmail = () => {
		if (!currentUser?.workEmailVerified) {
			return true;
		}
		if (currentUser?.workEmail !== work_email) {
			return true;
		} else {
			return false;
		}
	};

	// get phoneOTP
	const onGetPhoneOtp = () => {
		if (!phone_number.startsWith("234")) {
			toast.error("Please include country code");
		} else if (phone_number.length < 12) {
			toast.error("Please include country code");
		} else {
			const phone = formatPhoneNumber(phone_number);
			dispatch(getPhoneOTP(phone));
		}
	};

	// disable resend phone otp while counter
	useEffect(() => {
		if (isShowPhoneTimer) {
			dispatch(disablePhoneButton(1000 * 115));
		}
	}, [isShowPhoneTimer]);

	//verify phoneotp code
	useEffect(() => {
		if (verifyPhoneOtpSuccess) {
			setPersonalInfo((prev) => ({ ...prev, phone_code: "" }));
			return;
		}
		if (phone_code.length < 6) {
			return;
		} else if (isNaN(phone_code)) {
			return;
		} else {
			dispatch(verifyPhoneOTP(phone_number, phone_code));
		}
	}, [phone_code]);

	// check email validity
	const isEmailValid = validateEmail(work_email);

	// get email otp
	const onGetEmailOtp = () => {
		if (isEmailValid) {
			dispatch(getWorkEmailOtp({ workEmail: work_email }, currentSessionToken));
		} else {
			toast.error("WorkEmail is not valid");
		}
	};

	//verify email otp
	useEffect(() => {
		if (verifyEmailOtpSuccess) {
			setPersonalInfo((prev) => ({ ...prev, email_code: "" }));
			return;
		}
		if (email_code.length < 6) {
			return;
		} else if (isNaN(email_code)) {
			return;
		} else {
			dispatch(verifyEmailOtp({ workEmail: work_email, otp: email_code }));
		}
	}, [email_code]);

	// disable resend email otp while counter
	useEffect(() => {
		if (isShowEmailTimer) {
			dispatch(disableEmailButton(1000 * 115));
		}
	}, [isShowEmailTimer]);

	const isBtnInvalid = () => {
		if (
			first_name === "" ||
			last_name === "" ||
			work_email === "" ||
			address === "" ||
			phone_number === "" ||
			checkPhone() ||
			checkEmail()
		) {
			return true;
		} else {
			return false;
		}
	};

	const onSubmitFormData = () => {
		const data = {
			firstName: first_name,
			lastName: last_name,
			workEmail: work_email,
			homeAddress: address,
			phone: formatPhoneNumber(phone_number),
			email: currentUser?.email || work_email,
		};

		dispatch(updateUserData(data));
	};

	return (
		<div className="space-y-4 md:space-y-5">
			<ProfileInput
				text="first name"
				id="first_name"
				value={first_name}
				handleChange={handleChange}
				placeholderText="Enter first name"
			/>
			<ProfileInput
				text="last name"
				id="last_name"
				value={last_name}
				handleChange={handleChange}
				placeholderText="Enter last name"
			/>
			<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont text-[#393F42] gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 ">
				<label
					htmlFor="phone"
					className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
				>
					Phone Number:
				</label>
				<div
					className={`relative w-full sm:w-[70%] md:w-4/5 ${
						checkPhone() ? "mb-2" : "mb-0"
					}`}
				>
					<PhoneInput
						country={"ng"}
						inputClass="!text-[12.5px] lg:!text-[13px] !w-full !h-full rounded text-[#393F42] !border-[#B8B8B8] !bg-transparent outline-none focus:!border-2 focus:!border-fortcore-green border-[#B8B8B8] zabalRegFont"
						containerClass="!text-[12.5px] lg:!text-[13px] !w-full rounded !h-[44px] md:!h-[45px] !border-[#B8B8B8] zabalRegFont focus:!border-2 focus:!border-fortcore-green"
						value={phone_number}
						onChange={(phone_number) =>
							setPersonalInfo({
								...personalInfo,
								phone_number: phone_number,
							})
						}
						inputProps={{ maxLength: 19, id: "phone" }}
					/>
					{checkPhone() && (
						<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
							Phone number must be verified
						</p>
					)}
					<div className="absolute flex gap-2 items-center right-3 h-[45px] top-0">
						{!checkPhone() && (
							<span>
								<IsValidIcon width="15px" height="15px" />
							</span>
						)}
						{!phoneOtpMessageSuccess && checkPhone() && (
							<button
								className={`h-full flex items-center justify-center md:w-[60px] text-xs md:text-[12.5px] font-semibold zabalMedFont ${
									phoneOtpMessageLoading || phone_number.length < 12
										? "text-[#717E83]"
										: "text-fortcore-green"
								} ${phoneOtpMessageLoading ? "w-[42px]" : "w-[56px]"}`}
								onClick={onGetPhoneOtp}
								disabled={phoneOtpMessageLoading || phone_number.length < 12}
							>
								{phoneOtpMessageLoading ? <Preloader /> : "Get Code"}
							</button>
						)}
					</div>
				</div>
			</div>

			{/* handle phone otp */}
			{phoneOtpMessageSuccess && (
				<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont text-[#393F42] gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 ">
					<label
						htmlFor="phone_code"
						className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
					>
						Phone Code:
					</label>
					<div className="space-y-1.5 w-full sm:w-[70%] md:w-4/5">
						<div className="relative h-[45px] w-full zabalMedFont">
							<input
								type="tel"
								className="text-sm h-full w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
								placeholder="phone number otp"
								maxLength={6}
								id="phone_code"
								value={phone_code}
								onChange={handleChange}
							/>
							{verifyPhoneOtpLoading && (
								<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
									<Preloader />
								</div>
							)}
						</div>
						<div className="pl-4 pr-2 text-[11px] flex justify-between text-fortcore-green zabalMedFont">
							{isShowPhoneTimer && <CountDown timer={115} />}
							<div className="space-x-1.5 justify-self-end ">
								<span className="text-[#393F42]">Didn&apos;t get code?</span>
								<button
									className={`font-semibold ${
										isShowPhoneTimer ||
										phoneOtpMessageLoading ||
										phone_number.length < 12
											? "text-[#717E83]"
											: "text-fortcore-green"
									}`}
									onClick={onGetPhoneOtp}
									disabled={
										isShowPhoneTimer ||
										phoneOtpMessageLoading ||
										phone_number.length < 12
									}
								>
									{phoneOtpMessageLoading ? "Sending..." : "Resend Code"}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			<ProfileInput
				text="email address"
				id="email"
				type="email"
				value={email}
				handleChange={handleChange}
				isFieldDisabled={true}
				color={true}
				placeholderText="enter email address"
			/>

			{/* work email address */}
			<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont text-[#393F42]  gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 ">
				<label
					htmlFor="work_email"
					className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
				>
					Work Email Address:
				</label>
				<div
					className={`h-[45px] relative w-full sm:w-[70%] md:w-4/5 ${
						checkEmail() ? "mb-2" : "mb-0"
					}`}
				>
					<input
						type="email"
						className="bg-transparent rounded h-full w-full border border-[#B8B8B8] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5 placeholder:text-[13px] placeholder:text-[#B8B8B8]"
						placeholder="enter work email"
						id="work_email"
						value={work_email}
						onChange={handleChange}
					/>
					{checkEmail() && (
						<p className="absolute pt-0.5 text-xs zabalMedFont text-red-500">
							Work Email must be verified
						</p>
					)}
					<div className="absolute flex gap-2 items-center right-3 h-full top-0">
						{!checkEmail() && (
							<span>
								<IsValidIcon width="15px" height="15px" />
							</span>
						)}
						{!emailOtpMessageSuccess && checkEmail() && (
							<button
								className={`h-full flex items-center justify-center md:w-[60px] text-xs md:text-[12.5px] font-semibold zabalMedFont ${
									emailOtpMessageLoading || !isEmailValid
										? "text-[#717E83]"
										: "text-fortcore-green"
								} ${emailOtpMessageLoading ? "w-[42px]" : "w-[56px]"}`}
								onClick={onGetEmailOtp}
								disabled={emailOtpMessageLoading || !isEmailValid}
							>
								{emailOtpMessageLoading ? <Preloader /> : "Get Code"}
							</button>
						)}
					</div>
				</div>
			</div>

			{/* handle work email otp Verification */}
			{emailOtpMessageSuccess && (
				<div className="flex flex-col sm:flex-row sm:items-center zabalRegFont text-[#393F42]  gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 ">
					<label
						htmlFor="email_code"
						className="text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5"
					>
						Enter Code :
					</label>
					<div className="space-y-1.5 w-full sm:w-[70%] md:w-4/5">
						<div className="relative h-[45px] w-full zabalMedFont relative">
							<input
								type="tel"
								className="text-sm h-full w-full px-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
								placeholder="email otp"
								id="email_code"
								maxLength={6}
								value={email_code}
								onChange={handleChange}
							/>
							{verifyEmailOtpLoading && (
								<div className="absolute right-2.5 top-0 h-full w-[55px] grid place-items-center">
									<Preloader />
								</div>
							)}
						</div>
						<div className="pl-4 pr-2 text-[11px] flex justify-between text-fortcore-green zabalMedFont">
							{isShowEmailTimer && <CountDown timer={115} />}
							<div className="space-x-1.5 justify-self-end ">
								<span className="text-[#393F42]">Didn&apos;t get code?</span>
								<button
									className={`font-semibold ${
										isShowEmailTimer || emailOtpMessageLoading
											? "text-[#717E83]"
											: "text-fortcore-green"
									}`}
									onClick={onGetEmailOtp}
									disabled={isShowEmailTimer || emailOtpMessageLoading}
								>
									{emailOtpMessageLoading ? "Sending..." : "Resend Code"}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			<ProfileInput
				text="home address"
				id="address"
				value={address}
				handleChange={handleChange}
				placeholderText="enter home address"
			/>
			<div className="hidden sm:flex justify-end pb-2">
				<CustomGreenBtn
					text="update profile"
					width="140px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnInvalid() || updatingUserAccountLoading}
					isLoading={updatingUserAccountLoading}
					onRequestClick={onSubmitFormData}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="update profile"
					isDisabled={isBtnInvalid() || updatingUserAccountLoading}
					isLoading={updatingUserAccountLoading}
					onRequestClick={onSubmitFormData}
				/>
			</div>
		</div>
	);
};
export default PersonalInfo;

export const ProfileInput = ({
	type,
	text,
	id,
	value,
	handleChange,
	isFieldDisabled,
	color,
	placeholderText,
	isToolip,
	toolipText,
	max,
	maxLength,
}) => (
	<div
		className={`flex flex-col sm:flex-row sm:items-center zabalRegFont gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 ${
			color ? "text-black" : "text-[#393F42]"
		}`}
	>
		{isToolip ? (
			<div className="space-x-1 text-xs sm:text-[13px] md:text-[13.5px] w-full zabalMedFont sm:w-[30%] md:w-1/5">
				<label htmlFor={id} className="capitalize">
					{text}:
				</label>
				<Toolip text={toolipText} />
			</div>
		) : (
			<label
				htmlFor={id}
				className={`text-xs sm-text-[13px] md:text-[13.5px] capitalize w-full zabalMedFont sm:w-[30%] md:w-1/5 ${
					color
						? "text-black"
						: isFieldDisabled
						? "text-[#8E9DA4]"
						: "text-[#393F42]"
				}`}
			>
				{text}:
			</label>
		)}
		<div className={`relative h-[44px] sm:h-[45px] w-full sm:w-[70%] md:w-4/5`}>
			<input
				type={type ? type : "text"}
				className="bg-transparent rounded h-full w-full border border-[#B8B8B8] placeholder:text-[#B8B8B8] placeholder:text-[13px] outline-none text-sm focus:border-2 focus:border-fortcore-green px-2.5"
				value={value}
				placeholder={placeholderText}
				onChange={handleChange}
				id={id}
				max={max}
				maxLength={maxLength}
				disabled={isFieldDisabled ? isFieldDisabled : false}
			/>
			{isFieldDisabled && (
				<span className="absolute right-2 h-full w-[30px] grid place-items-center top-0">
					<MdLock size={18} color="#717E83" />
				</span>
			)}
		</div>
	</div>
);

ProfileInput.propTypes = {
	type: PropTypes.string,
	text: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	isFieldDisabled: PropTypes.bool,
	color: PropTypes.bool,
	placeholderText: PropTypes.string,
	isToolip: PropTypes.bool,
	toolipText: PropTypes.string,
	max: PropTypes.string,
	maxLength: PropTypes.number,
};

export const InputForAmount = ({
	labelText,
	id,
	value,
	handleChange,
	isDisabled,
	placeholderText,
	color,
	type,
}) => {
	return (
		<div className="flex flex-col sm:flex-row sm:items-center gap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2">
			<label
				htmlFor={id}
				className={`w-full sm:w-[30%] md:w-1/5 text-xs sm-text-[13px] md:text-[13.5px]  zabalMedFont ${
					color
						? "text-[#393F42]"
						: isDisabled
						? "text-[#8E9DA4]"
						: "text-[#393F42]"
				}`}
			>
				{labelText}:
			</label>
			<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative h-[45px]">
				<span className="text-[#393F42] absolute zabalMedFont left-3 h-full w-[30px] grid place-items-center">
					NGN
				</span>
				<input
					type={type || "text"}
					className="text-sm h-full w-full pl-[48px] pr-2.5 rounded border border-[#B8B8B8] text-[#393F42] bg-transparent outline-none placeholder:text-[#B8B8B8] zabalRegFont focus:border-2 focus:border-fortcore-green"
					placeholder={placeholderText}
					id={id}
					value={value}
					onChange={handleChange}
					onInput={onInputAmount}
					onBlur={(e) => onBlurAmount(e, value)}
					disabled={isDisabled}
				/>
				{isDisabled && (
					<span className="absolute right-2 h-full w-[30px] grid place-items-center top-0">
						<MdLock size={18} color="#717E83" />
					</span>
				)}
			</div>
		</div>
	);
};

InputForAmount.propTypes = {
	labelText: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	color: PropTypes.bool,
	type: PropTypes.string,
	placeholderText: PropTypes.string,
};
