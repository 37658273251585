/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from "react";
import { GridContainer, OnboardStyle } from "../../screens/onboarding/styled";
import { Helmet } from "react-helmet";
import Image from "./assets/image.png";
import { HOne, Preloader, SubText } from "../../components/globalStyled";
import { Right } from "../createAccount/styled";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Parse from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
	clearRedirect,
	currentUserData,
} from "../../redux/authRedux/authActions";
import {
	Box,
	Button,
	// Checkbox,
	FormControl,
	// FormControlLabel,
	// FormGroup,
	FormLabel,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Stack,
	TextField,
	Typography,
	// Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import userContext from "../../context/UserContext";
import { formatDateToString } from "../../screens/finance/DateFormat";
import HeaderNav from "../../components/header/HeaderNav";

function Login() {
	// eslint-disable-next-line no-undef
	const { redirectUrl } = useSelector((state) => state.auth);
	const savedEmail = localStorage.getItem("email");
	const [email, setEmail] = useState(savedEmail || "");
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [wrong, setWrong] = useState(false);
	const dispatch = useDispatch();
	const theme = useTheme();
	// const [rememberMe, setRememberMe] = useState(
	//   localStorage.getItem("rememberMe") === "true"
	// );
	const { setCurrentUserData, setCreateAccountModal } = useContext(userContext);

	useEffect(() => {
		if (redirectUrl === "/login") {
			dispatch(clearRedirect());
		}
	}, [redirectUrl]);

	// const handleCheckboxChange = (event) => {
	//   const checked = event.target.checked;
	//   setRememberMe(checked);

	//   if (checked) {
	//     localStorage.setItem("rememberMe", true);
	//   } else {
	//     localStorage.removeItem("rememberMe");
	//   }
	// };
	console.log(process.env.REACT_APP_APP_ID);
	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		setWrong(false);

		try {
			await Parse.User.logIn(email, password);

			Parse.enableEncryptedUser();
			Parse.secret = process.env.REACT_APP_APP_ID;

			const currentUser = Parse.User.current();
			const userData = currentUser;

			dispatch(currentUserData(userData));
			// localStorage.setItem(
			// 	"Parse/FortCore/currentUser",
			// 	JSON.stringify(userData)
			// );

			const dateString = userData?.idExpiryDate;
			const formattedDateString = formatDateToString(dateString);

			setCurrentUserData(currentUser.toJSON());
			setCurrentUserData((prevState) => ({
				...prevState,
				idExpiryDate: formattedDateString,
			}));
			// setCurrentUserData({
			// 	adminStatus: userData?.adminStatus,
			// 	createdAt: userData?.createdAt,
			// 	creditScore: userData?.creditScore,
			// 	email: userData?.email,
			// 	emailVerified: userData?.emailVerified,
			// 	objectId: userData?.objectId,
			// 	sessionToken: userData?.sessionToken,
			// 	updatedAt: userData?.updatedAt,
			// 	username: userData?.username,
			// 	workEmail: userData?.workEmail,
			// 	workEmailVerified: userData?.workEmailVerified,
			// 	firstName: userData?.firstName,
			// 	lastName: userData?.lastName,
			// 	bvnVerified: userData?.bvnVerified,
			// 	homeAddress: userData?.homeAddress,
			// 	phone: userData?.phone,
			// 	phoneVerified: userData?.phoneVerified,
			// 	idType: userData?.idType,
			// 	idExpiryDate: formattedDateString,
			// 	idNo: userData?.idNo,
			// });

			// eslint-disable-next-line no-undef

			const dataObject = {
				adminStatus: userData?.adminStatus,
				createdAt: userData?.createdAt,
				creditScore: userData?.creditScore,
				email: userData?.email,
				emailVerified: userData?.emailVerified,
				objectId: userData?.objectId,
				sessionToken: userData?.sessionToken,
				updatedAt: userData?.updatedAt,
				username: userData?.username,
				workEmail: userData?.workEmail,
				workEmailVerified: userData?.workEmailVerified,
				firstName: userData?.firstName,
				lastName: userData?.lastName,
				bvnVerified: userData?.bvnVerified,
				homeAddress: userData?.homeAddress,
				phone: userData?.phone,
				phoneVerified: userData?.phoneVerified,
				idType: userData?.idType,
				idExpiryDate: formattedDateString,
				idNo: userData?.idNo,
			};

			// Convert the object to a JSON string
			const dataString = JSON.stringify(dataObject);

			// Save the JSON string to the localStorage under a specified key
			localStorage.setItem("userData", dataString);

			console.log("Encrypted currentUser:", userData.idExpiryDate);
			setLoading(false);
			navigate("/dashboard", { replace: true });
			// eslint-disable-next-line no-undef
			window.scrollTo(0, 0);
		} catch (error) {
			// eslint-disable-next-line no-undef
			console.log(error.message);
			setLoading(false);
			if (error.message === "Invalid username/password.") {
				toast.error("Invalid email/password.");
				setWrong(true);
			} else if (
				error?.message.includes("Unable to connect to the Parse API")
			) {
				toast.error("Opps. You are not connected to the internet.");
			} else {
				toast.error(error.message);
			}
		}
	};

	return (
		<>
			<HeaderNav />
			<OnboardStyle>
				<Helmet>
					<title>Fortcore - Login</title>
				</Helmet>

				<GridContainer>
					<div className="left">
						<img src={Image} alt="" />
					</div>

					<div className="loginContainer">
						<div className="login zabalRegFont">
							<Right>
								<HOne>Get Right Back In</HOne>
								<SubText>
									Input your password to continue enjoying our services.{" "}
								</SubText>

								{/* Form  */}

								<Box
									component="form"
									sx={{ width: "100%" }}
									onSubmit={handleLogin}>
									<FormLabel
										sx={{
											fontFamily: "ZabalMedium",
											color: theme.palette.primary.dark,
										}}>
										Email Address
									</FormLabel>
									<TextField
										sx={textFieldStyle}
										error={wrong}
										id="outlined-error"
										label={wrong ? "Invalid email or password" : ""}
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										placeholder="Enter your E-mail"
										required
									/>
									<Box>
										<FormLabel
											sx={{
												fontFamily: "ZabalMedium",
												color: theme.palette.primary.dark,
											}}>
											Password
										</FormLabel>
									</Box>

									<FormControl
										sx={{
											width: "100%",
											height: "50px",
											marginTop: "10px",
											marginBottom: "10px",
										}}
										variant="outlined">
										<OutlinedInput
											sx={{
												border: "1px",
												width: "100%",
												borderRadius: "8px",
											}}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											placeholder="*******"
											error={wrong}
											id="outlined-adornment-password"
											type={showPassword ? "text" : "password"}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((show) => !show)}
														edge="end"
														sx={{ color: theme.palette.primary.dark }}>
														{showPassword ? <HiEye /> : <HiEyeOff />}
													</IconButton>
												</InputAdornment>
											}
											required
										/>
									</FormControl>

									<Stack
										direction="row"
										justifyContent="flex-end"
										alignItems="center">
										{/* <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={rememberMe}
                        onChange={handleCheckboxChange}
                        label={
                          <Typography
                            variant="caption"
                            sx={{ color: "#717e83" }}
                          >
                            Keep me logged in
                          </Typography>
                        }
                      />
                    </FormGroup> */}
										<Link to="/forgot-password">
											<Button
												sx={{ fontFamily: "ZabalMedium" }}
												disableElevation>
												Forgot Password
											</Button>
										</Link>
									</Stack>
									<Button
										variant="contained"
										sx={{
											background: theme.palette.primary.dark,
											borderRadius: "8px",
											width: "100%",
											height: "50px",
											marginTop: "20px",
											textTransform: "capitalize",
										}}
										type="submit"
										disabled={loading}>
										{!loading ? "Proceed" : <Preloader />}
									</Button>
								</Box>

								<div className="center">
									<p className="link">
										Don’t have an account yet?{" "}
										<Typography
											component="span"
											color="primary"
											sx={{ fontFamily: "ZabalMedium", cursor: "pointer" }}
											onClick={() => setCreateAccountModal(true)}>
											Create Account
										</Typography>
									</p>
								</div>
							</Right>
						</div>
					</div>
				</GridContainer>
			</OnboardStyle>
		</>
	);
}

export default Login;

const textFieldStyle = {
	width: "100%",
	height: "50px",
	marginTop: "10px",
	marginBottom: "10px",
	"& .MuiInputBase-root": {
		borderRadius: "8px",
	},
};
