import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { openMarketItemDetails } from "../../../redux/lease/leaseActions";
import { formatAmountToIncludeCurrency } from "../../../common/utils";
import ProductImagesCarousel from "../../../layout/ProductImagesCarousel";

export const LeaseStructureItem = ({ item, interest, interestAmount }) => {
	const dispatch = useDispatch();

	return (
		<div className="!mt-0 sm:!mt-1 bg-[#FEFEFE] border border-[#B8C8CF] rounded p-3 sm:py-4 flex flex-col md:px-4 lg:px-5 sm:flex-row gap-4 sm:gap-6 md:gap-8 lg:gap-10 lg:items-center">
			<div className="flex max-w-[271px] sm:max-w-[150px] md:max-w-[250px] lg:max-w-[300px] items-center">
				<ProductImagesCarousel
					imageArr={item?.imageArr}
					productName={item?.productName}
				/>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex flex-col gap-1 sm:gap-1.5">
					<p className="text-[#222627] zabalMedFont text-[12.5px] md:text-[13px]">
						{item?.productName}
					</p>
					<p className="text-fortcore-green text-[11.5px]">
						<span className="zabalMedFont">
							<span className="text-[#393F42] zabalMedFont">Asset Value: </span>
							<span className="zabalRegFont font-semibold">NGN </span>
						</span>{" "}
						<span className="zabalMedFont">
							{formatAmountToIncludeCurrency(undefined, item?.amount)}
						</span>
					</p>
					<p className="text-[#393F42] text-[10.5px]">
						<span className="zabalMedFont">Service Life: </span>
						<span className="zabalRegFont capitalize">
							{" "}
							{item?.shelfLife?.length > 3
								? item.shelfLife
								: `${item.shelfLife} Months`}
						</span>
					</p>
					{interest && (
						<p className="text-[#393F42] zabalMedFont text-[11px]">
							<span className="zabalRegFont">Interest: </span>
							<span>
								NGN {interestAmount?.toLocaleString()} at %{interest}
							</span>
						</p>
					)}
					<button
						className="flex items-center gap-1 text-xs zabalMedFont"
						onClick={() => dispatch(openMarketItemDetails(item))}
					>
						<BsFillExclamationCircleFill color="#AABDC5" size={15} />
						<span className="underline text-fortcore-green">Details</span>
					</button>
				</div>
				<div className="flex gap-2">
					{item?.tags?.slice(0, 2).map((info, i) => (
						<p
							className="capitalize bg-[#E3E9EC] text-[#393f42] text-[10px] rounded px-2 py-1 zabalRegFont"
							key={i}
						>
							{info}
						</p>
					))}
				</div>
			</div>
		</div>
	);
};

LeaseStructureItem.propTypes = {
	item: PropTypes.object,
	interest: PropTypes.string,
	interestAmount: PropTypes.number,
};

export const deliveryTypeArr = [
	{ label: "Door Delivery", value: "door-delivery" },
	{ label: "Pick Up", value: "pick-up" },
];

export const expectedWaitingperiod = [
	{ duration: "Days", length: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13] },
	{ duration: "Weeks", length: [1, 2, 3, 5, 6, 7, 9, 10, 11, 13, 14, 15] },
	{
		duration: "Months",
		length: [
			1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
			23,
		],
	},
	{ duration: "Years", length: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
];

export const expectedDuration = [
	// { duration: "Days", length: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13] },
	// { duration: "Weeks", length: [1, 2, 3, 5, 6, 7, 9, 10, 11, 13, 14, 15] },
	{
		duration: "Months",
		length: [
			1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
			23,
		],
	},
	{ duration: "Years", length: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
];

export const leaseStructureArr = ["Lease To Own", "Operating Lease"];
