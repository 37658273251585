import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Stack, Typography } from "@mui/material";
import { BsDot } from "react-icons/bs";
import Parse from "../../../../../api/ApiConfig";
import { toast } from "react-toastify";
import BrowseFormContext from "../BrowseFormContext";
import { MobileNavDetails } from "../../../../../layout/MobileNavbar";
import Container from "../../../../../layout/Container";
import Navbar from "../../../../../layout/Navbar";
import { AttachmentUploadComponent } from "../RequestButton/UPLOAD/AttachmentUploadComponent";
import { BusinessPhotoComponent } from "../RequestButton/UPLOAD/ProductPhoto";
import { Preloader } from "../../../../../components/globalStyled";
import userContext from "../../../../../context/UserContext";
import { Navigate, useNavigate } from "react-router-dom";
import { BusinessProof } from "../RequestButton/UPLOAD/BusinessProof";
import {
	BackTracker,
	SuccessCheckModal,
	UrlTracker,
} from "../../../../../layout/CustomComponents";
import { FaArrowLeft } from "react-icons/fa";
import { DesktopSideModal } from "../../../../../components/ModalContainer";
import {
	onSaveUserFinanceRequest,
	onSelectFinanceOption,
	onToggleFinanceFormModal,
} from "../../../../../redux/financing/financeActions";
import FinanceForm from "../../FinanceForm";
import { getProfilePercentage } from "../../../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdateAccountSuccess } from "../../../../../redux/authRedux/authActions";
import { createFinaceReqAsOngoing } from "../../../../../redux/financing/financeServices";

function FormUpload() {
	const dispatch = useDispatch();

	const { updatingUserAccountSuccess } = useSelector((state) => state.auth);
	const { toggleFinanceModal, userFinanceRequest, userSelectedCreatedFinance } =
		useSelector((state) => state.finance);

	const navigate = useNavigate();

	const { currentUserData } = useContext(userContext);
	const { marketPlace, setMarketPlace } = useContext(BrowseFormContext);
	const [loading, setLoading] = useState(false);

	const [neededFiles, setNeededFiles] = useState(false);
	const [businessProofFile, setBusinessProofFile] = useState(false);

	useEffect(() => {
		if (marketPlace.proofOfPurchase !== "") {
			setBusinessProofFile(true);
		} else if (marketPlace.businessProofFile) {
			setBusinessProofFile(true);
		} else {
			setBusinessProofFile(false);
		}
	}, [
		marketPlace.proofOfPurchase,
		marketPlace.businessProofFile,
		setBusinessProofFile,
	]);

	useEffect(() => {
		if (businessProofFile && marketPlace?.productPhoto.length > 0) {
			setNeededFiles(true);
		} else {
			setNeededFiles(false);
		}
	}, [businessProofFile, marketPlace?.productPhoto]);

	const handleBusinessProofUpload = (file) => {
		setMarketPlace((prevState) => ({
			...prevState,
			businessProofFile: file,
		}));
	};

	const handleAttachmentUpload = (file) => {
		setMarketPlace((prevState) => ({
			...prevState,
			attachmentFile: file,
		}));
	};

	const handleImagesUpload = (file) => {
		setMarketPlace((prevState) => ({
			...prevState,
			productPhoto: file,
		}));
	};

	const financeProfileValidation = {
		phoneNumber: currentUserData?.phone,
		phoneNumberVerified: currentUserData?.phoneVerified,
		homeAddress: currentUserData?.homeAddress,
		bvn: currentUserData?.bvn,
		bankName: currentUserData?.bankName,
		accountNumber: currentUserData?.accountNumber,
		idType: currentUserData?.idType,
		idNumber: currentUserData?.idNo,
	};

	const financeProfilePercentage = getProfilePercentage(
		financeProfileValidation
	);

	useEffect(() => {
		if (marketPlace.proofOfPurchase !== "") {
			setBusinessProofFile(true);
		} else if (marketPlace.businessProofFile) {
			setBusinessProofFile(true);
		} else {
			setBusinessProofFile(false);
		}
	}, [
		marketPlace.proofOfPurchase,
		marketPlace.businessProofFile,
		setBusinessProofFile,
	]);

	useEffect(() => {
		if (marketPlace.productPhoto) {
			if (businessProofFile && marketPlace.productPhoto.length > 0) {
				setNeededFiles(true);
			} else {
				setNeededFiles(false);
			}
		}
	}, [businessProofFile, marketPlace.productPhoto]);

	const handleFormUpload = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const currentUser = Parse.User.current();

			if (currentUser) {
				if (financeProfilePercentage > 33) {
					try {
						const investorFinanceInfo = {
							...userFinanceRequest?.investorInfo,
							financeId: userFinanceRequest?.objectId,
							apprentice: userFinanceRequest?.apprentice || [],
						};

						const data = {
							...userSelectedCreatedFinance,

							attachmentDoc: marketPlace?.attachmentFile,
							proofOfPurchaseFile: marketPlace?.businessProofFile,
							productPhotos: marketPlace?.productPhoto,
							requestStatus: "ongoing",
							investorFinanceInfo,
						};

						const response = await createFinaceReqAsOngoing(data);

						if (response) {
							const updatedData = {
								...data,
								createdAt: response?.createdAt,
								objectId: response?.objectId,
							};
							setMarketPlace((prevState) => ({
								...prevState,
								objectId: response?.objectId,
								// formStep: 2,
							}));
							dispatch(onSaveUserFinanceRequest(updatedData));

							toast.success(
								"Finance request created. Complete the next steps to submit"
							);
							setTimeout(() => {
								// navigate("/financing/ongoing-transactions");
								navigate("/financing/ongoing-transactions/market-finance");
							}, 2000);
						}
					} catch (error) {
						console.log(error);
						throw error;
					}
				} else {
					dispatch(onToggleFinanceFormModal(true));
					dispatch(onSelectFinanceOption("browse"));
				}
			} else {
				setLoading(true);
				Parse.User.logOut();
				toast.error("You have been logged out of your account");
				setTimeout(() => {
					window.location.href = "/login";
				}, 500);
			}
		} catch (error) {
			if (error.message.includes("Unable to connect to the Parse API")) {
				toast.error("Oops. You are not connected to the internet");
			} else {
				toast.error(error.message);
			}
		} finally {
			setLoading;
		}
	};

	const onMobileBackBtnClick = () => {
		navigate("/financing/new-transaction/market-place/form");
	};

	const onFinanceFormSaveSuccess = () => {
		dispatch(onToggleFinanceFormModal(false));
		dispatch(clearUpdateAccountSuccess());
	};

	console.log("MarketPlace: ", marketPlace, userFinanceRequest);

	if (!userFinanceRequest?.objectId) {
		return <Navigate to="/financing/new-transaction/market-place" />;
	}

	return (
		<>
			{toggleFinanceModal && (
				<DesktopSideModal modal={toggleFinanceModal}>
					<>
						{updatingUserAccountSuccess ? (
							<div className="w-full grid place-items-center">
								<SuccessCheckModal
									text="Form details saved successfully"
									onRequestClick={onFinanceFormSaveSuccess}
									isShowBtn={true}
									btnText="proceed"
								/>
							</div>
						) : (
							<FinanceForm
								onCloseForm={() => dispatch(onToggleFinanceFormModal(false))}
								// onMaybeBtnClick={onMaybeLaterBtnClick}
							/>
						)}
					</>
				</DesktopSideModal>
			)}

			<MobileNavDetails text="New Transaction" />

			<Container>
				<Helmet>
					<title>Fortcore - New Transaction</title>
				</Helmet>
				<Navbar text="New Transaction" />
				<div className="sm:pt-9 md:pt-10 "></div>
				<div className="flex gap-1.5">
					<UrlTracker
						text="financing"
						onNavigate={() => navigate("/financing")}
					/>
					<UrlTracker
						text="market place"
						onNavigate={() =>
							navigate("/financing/new-transaction/market-place")
						}
					/>
					<UrlTracker text="upload documents" isCurrentUrl />
				</div>

				<BackTracker text="request finance" onNavigate={onMobileBackBtnClick} />

				<Box sx={{ background: "#F6F7F8", padding: "20px", marginTop: "30px" }}>
					<div className="flex items-center gap-3 text-primary-color">
						<button onClick={onMobileBackBtnClick} className="hidden sm:inline">
							<FaArrowLeft size={15} />
						</button>
						<p className="chillaxFont text-lg font-medium">
							Request Apprentice Finance
						</p>
					</div>

					<form onSubmit={handleFormUpload}>
						<Stack>
							{/* Upload File  */}
							<Box mt={3} mb={3}>
								<Stack direction="row" justifyContent="flex-start" spacing={1}>
									<BsDot style={{ fontSize: "30px" }} />
									<Box width="100%">
										<Stack spacing={2}>
											<Typography variant="caption" color="textSecondary">
												List items you want to purchase and show proof of what
												you are purchasing and from whom you are purchasing
											</Typography>

											<Box width="100%">
												<Stack
													direction={{ xs: "column", sm: "row" }}
													justifyContent="space-between"
													alignItems="center"
													spacing={{ xs: 1, sm: 5 }}
												>
													{/* <TextField
														type="text"
														name="proofOfPurchase"
														value={marketPlace.proofOfPurchase}
														onChange={handleInputChange}
														label="Type Manually"
														multiline
														sx={{ height: "124px", width: "100%" }}
														rows={4.5}
														placeholder="Type Manually"
													/>

													<Box>
														<Typography
															variant="caption"
															color="textSecondary"
															textAlign="center"
														>
															or
														</Typography>
													</Box> */}
													{/* <ImageProofUploadComponent
														onFileUpload={handleFileUpload}
														image={marketPlace.imageURL}
													/> */}
													<BusinessProof
														onFileUpload={handleBusinessProofUpload}
														fileName={marketPlace?.businessProofFile?.fileName}
													/>
												</Stack>
											</Box>
										</Stack>
									</Box>
								</Stack>
							</Box>

							{/* Attachments  */}
							<Box mt={3} mb={3}>
								<Stack direction="row" justifyContent="flex-start" spacing={1}>
									<BsDot style={{ fontSize: "30px" }} />
									<Box width="100%">
										<Stack spacing={2}>
											<Box>
												<Typography variant="body1" color="primary">
													Attachments{" "}
													<Typography variant="caption" color="textSecondary">
														(optional)
													</Typography>
												</Typography>
												<Typography variant="caption" color="textSecondary">
													Adding important document such as business plan,
													certificate of incorporation etc. Can increase your
													chance of getting an investor
												</Typography>
											</Box>

											<AttachmentUploadComponent
												onFileUpload={handleAttachmentUpload}
												fileName={marketPlace?.attachmentFile?.fileName}
											/>
										</Stack>
									</Box>
								</Stack>
							</Box>

							{/* Upload Business  */}

							<Box mt={3} mb={3}>
								<Stack direction="row" justifyContent="flex-start" spacing={1}>
									<BsDot style={{ fontSize: "30px" }} />
									<Box width="100%">
										<Stack spacing={2}>
											<Box>
												<Typography variant="body1" color="primary">
													Upload Business / Product Photo
												</Typography>
												<Typography variant="caption" color="textSecondary">
													Business / Product photo will be used for the cover
													picture of your request you can add maximum of 4 Jpeg
													or Png Image
												</Typography>
											</Box>

											<BusinessPhotoComponent
												onFileUpload={handleImagesUpload}
												images={marketPlace.productPhoto}
											/>
										</Stack>
									</Box>
								</Stack>
							</Box>

							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={!neededFiles}
								>
									{loading ? <Preloader /> : "Proceed"}
								</Button>
							</Box>
						</Stack>
					</form>
				</Box>
			</Container>
		</>
	);
}

export default FormUpload;
