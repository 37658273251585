/* eslint-disable no-undef */
import React, { useState } from "react";
// import CompanyLogo from "../assets/logo1.png";
import CompanyLogo from "../assets/logo.png";
// import CompanyLogo from "../assets/logo-b.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
import {
	NonActiveDashIcon,
	ActiveDashIcon,
	NonActiveRealIcon,
	// ActiveRealIcon,
	// NonActiveCardIcon,
	// ActiveCardIcon,
} from "./assets";
import PropTypes from "prop-types";
import { BsDot, BsFillCreditCardFill } from "react-icons/bs";
// import { IoLogOut } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { closeMobileMenuModal } from "../redux/authRedux/authActions";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { openLogoutModal } from "../redux/authRedux/authActions";

const Sidebar = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isCurrentChild = () => {
		if (
			location.pathname.includes("leasing") ||
			location.pathname.includes("financing") ||
			location.pathname.includes("travels")
		) {
			return true;
		} else {
			return false;
		}
	};
	const [showChildren, setShowChildren] = useState(isCurrentChild() || true);

	return (
		<div className="h-[85vh] sm:h-[90vh] md:h-[92vh] flex flex-col justify-between">
			<div className="w-full space-y-5 md:space-y-6">
				<div className="h-[80px] sm:h-[57px] md:h-[63px] w-full relative logoBg">
					<Link
						to="/dashboard"
						className="h-full w-[83%] mx-auto flex items-center justify-center"
					>
						<img
							src={CompanyLogo}
							alt="company-logo"
							className="h-[40px] w-full max-w-[175px] sm:max-w-none sm:h-[25px] md:h-[30px] sm:w-[120px] md:w-[140px]"
						/>
					</Link>
				</div>
				<div className="h-full flex flex-col gap-3 w-full">
					<div className="border-b border-[#2B5B6D] pb-3 flex flex-col gap-4">
						<SideBarLink
							text="Dashboard"
							icon={
								location.pathname.includes("dashboard") ? (
									<ActiveDashIcon width="12px" height="12px" />
								) : (
									<NonActiveDashIcon width="12px" height="12px" />
								)
							}
							url="/dashboard"
						/>
						<div className="flex flex-col gap-3">
							<div
								className={`flex gap-2.5 items-center cursor-pointer ${
									showChildren
										? "text-white zabalMedFont"
										: "text-[#E3E9EC] zabalRegFont"
								}`}
								onClick={() => setShowChildren((prev) => !prev)}
							>
								<span className="w-4/6 mx-auto flex gap-2.5 items-center">
									<span className="text-sm">
										<NonActiveRealIcon width="13px" height="13px" />
									</span>
									<span className="text-sm sm:text-[13px] mt-[0.1px] text-white">
										Alternative Financing
									</span>
									<span>
										{showChildren ? (
											<MdKeyboardArrowUp size={20} />
										) : (
											<MdKeyboardArrowDown size={20} />
										)}
									</span>
								</span>
							</div>
							{showChildren && (
								<div className="grid gap-1.5 justify-items-end">
									<MinSidebarLink text="Leasing" url="/leasing" />
									<MinSidebarLink
										text="Apprentice Financing"
										url="/financing"
									/>
									<MinSidebarLink text="Travel Advance" url="/travels" />
								</div>
							)}
						</div>
						<SideBarLink
							text="Education"
							free="(Free)"
							icon={<FaBookOpen size={14} />}
							url="/education"
						/>
					</div>
					<SideBarLink
						text="Payments"
						icon={<BsFillCreditCardFill />}
						url="/payments"
					/>
					<div className="flex justify-center mt-10">
						<button
							className="justify-self-end self-end flex gap-2.5 items-center text-red-500 zabalMedFont bg-white rounded-3xl px-6 py-2.5 w-10/12 max-w-[160px] hover:text-white hover:bg-red-500"
							onClick={() => dispatch(openLogoutModal())}
						>
							<IoLogOut size={22} />
							<span>Logout</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Sidebar;

const SideBarLink = ({ icon, text, url, free }) => {
	const dispatch = useDispatch();
	const closeMobileModal = () => {
		setTimeout(() => {
			dispatch(closeMobileMenuModal());
		}, 300);
	};

	return (
		<NavLink
			className={({ isActive }) =>
				isActive
					? "py-4 sm:py-3 text-fortcore-green zabalBoldFont activeLink"
					: "py-4 sm:py-3 text-[#E3E9EC] zabalRegFont"
			}
			to={url}
			onClick={closeMobileModal}
		>
			<span className="w-4/6 mx-auto flex gap-2 items-center">
				<span className="text-sm">{icon}</span>
				<span className="text-sm sm:text-[13px] mt-[0.1px]">
					{text} <span className="!text-[#FF8787] zabalRegFont">{free}</span>
				</span>
			</span>
		</NavLink>
	);
};

const MinSidebarLink = ({ text, url }) => {
	const dispatch = useDispatch();
	const closeMobileModal = () => {
		setTimeout(() => {
			dispatch(closeMobileMenuModal());
		}, 300);
	};
	return (
		<NavLink
			className={({ isActive }) =>
				isActive
					? "w-5/6 py-3 text-fortcore-green zabalBoldFont activeLink rounded-l pl-2 pr-5"
					: "w-5/6 py-3 text-[#E3E9EC] zabalRegFont rounded-l pl-2 pr-5 "
			}
			to={url}
			onClick={closeMobileModal}
		>
			<p className="text-[13px] sm:text-xs leading-[18px] flex items-center">
				<BsDot size={24} />
				<span>{text}</span>
			</p>
		</NavLink>
	);
};

SideBarLink.propTypes = {
	text: PropTypes.string,
	free: PropTypes.string,
	icon: PropTypes.object,
	url: PropTypes.string,
};
MinSidebarLink.propTypes = {
	text: PropTypes.string,
	url: PropTypes.string,
};
