import React, { useEffect } from "react";
import propTypes from "prop-types";
import { CustomCheckboxInput } from "./CustomComponents";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "../components/globalStyled";
import { onInputAmount, removeDuplicatesInData } from "../common/utils";
import { fetchCategories } from "../redux/lease/leaseServices";
import { productColors } from "../screens/leasing/requestlease/LeaseForm";
import { CustomGreenBtn, CustomWhiteBtn } from "./CustomButtons";
import { closeFilterModal } from "../redux/lease/leaseActions";

const CustomFilter = ({
	startPrice,
	endPrice,
	categoryChecked,
	setCategoryChecked,
	colorChecked,
	setColorChecked,
	setPriceQuery,
	onBtnClick,
}) => {
	const dispatch = useDispatch();

	const { categoriesLoading, categoriesSuccess } = useSelector(
		(state) => state.lease
	);

	useEffect(() => {
		dispatch(fetchCategories());
	}, []);

	const newCategoryArray = removeDuplicatesInData(
		categoriesSuccess?.map((d) => d.subCategory)
	);

	const handlePriceChange = (e) => {
		setPriceQuery((prev) => ({
			...prev,
			[e.target.id]: e.target.value,
		}));
	};

	const handleCategoryChange = (e) => {
		const target = e.target;
		if (target.type === "checkbox") {
			const { id, checked } = target;
			setCategoryChecked([...categoryChecked, id]);
			if (!checked) {
				setCategoryChecked(categoryChecked.filter((item) => item !== id));
			}
		}
	};

	const handleColorChange = (e) => {
		const target = e.target;
		if (target.type === "checkbox") {
			const { id, checked } = target;
			setColorChecked([...colorChecked, id]);
			if (!checked) {
				setColorChecked(colorChecked.filter((item) => item !== id));
			}
		}
	};

	const isBtnValid = () => {
		if (endPrice && !startPrice) {
			return "Start price cannot be empty";
		}
		if (
			endPrice !== "" &&
			Number(startPrice.replaceAll(",", "")) >
				Number(endPrice.replaceAll(",", ""))
		) {
			return "Start price cannot be greater than End price";
		} else {
			return false;
		}
	};

	const clearAllQuery = () => {
		setCategoryChecked([]);
		setColorChecked([]);
		setPriceQuery({ startPrice: "", endPrice: "" });
	};

	const onSaveBtnClick = () => {
		onBtnClick();
		dispatch(closeFilterModal());
	};

	return (
		<div className="bg-white w-11/12 max-w-[450px] mx-auto rounded-md py-4 md:py-5">
			<header className="flex items-center justify-between mb-2 px-4 md:px-5">
				<h2 className="zabalBoldFont text-fortcore-green">Filter</h2>
				<button
					className="text-sm text-[#717E83] zabalMedFont"
					onClick={clearAllQuery}
				>
					Clear All
				</button>
			</header>
			<div className="max-h-[500px] overflow-auto px-4 md:px-5 scroll-wrapper">
				<div className="space-y-2 py-3.5 border-b border-[#EEF2F3]">
					<p className="text-[13px] font-semibold text-[#393F42]">
						Price (NGN):
					</p>
					<div className="grid grid-cols-2 gap-3 md:gap-4">
						<input
							type="tel"
							id="startPrice"
							className="border border-[#96A0A5] focus:border-2 rounded px-2.5 h-[43px] text-sm border-fortcore-green"
							placeholder="From"
							value={startPrice}
							onChange={handlePriceChange}
							onInput={onInputAmount}
						/>
						<input
							type="tel"
							id="endPrice"
							className="border border-[#96A0A5] focus:border-2 rounded px-2.5 h-[43px] text-sm border-fortcore-green "
							placeholder="To"
							value={endPrice}
							onChange={handlePriceChange}
							onInput={onInputAmount}
						/>
					</div>
					{isBtnValid() && (
						<p className="text-xs zabalMedFont text-red-500">{isBtnValid()}</p>
					)}
				</div>
				<div className="space-y-2 py-3.5 border-b border-[#EEF2F3]">
					<p className="text-[13px] font-semibold text-[#393F42]">
						SubCategories:
					</p>
					<div className="flex gap-4 flex-wrap">
						{categoriesLoading ? (
							<div className="w-full h-[40px] grid place-items-center">
								<Preloader />
							</div>
						) : (
							newCategoryArray?.map((category, i) => (
								<div
									key={i}
									className="flex gap-1.5 items-center text-xs zabalMedFont"
								>
									<CustomCheckboxInput
										handleChange={handleCategoryChange}
										isChecked={categoryChecked.includes(category)}
										id={category}
									/>
									<span>{category}</span>
								</div>
							))
						)}
					</div>
				</div>
				<div className="space-y-2 py-3.5">
					<p className="text-[13px] font-semibold text-[#393F42]">Colours:</p>
					<div className="flex gap-4 flex-wrap">
						{productColors.map((color, i) => (
							<div
								key={i}
								className="flex gap-1.5 items-center text-xs zabalMedFont"
							>
								<CustomCheckboxInput
									handleChange={handleColorChange}
									isChecked={colorChecked.includes(color)}
									id={color}
								/>
								<span>{color}</span>
							</div>
						))}
					</div>
				</div>
				<div className="hidden sm:flex justify-end gap-2 pt-4">
					<CustomWhiteBtn
						text="cancel"
						width="105px"
						fontSize="12px"
						height="40px"
						onRequestClick={() => dispatch(closeFilterModal())}
					/>
					<CustomGreenBtn
						text="save"
						width="105px"
						fontSize="12px"
						height="40px"
						isDisabled={!!isBtnValid()}
						onRequestClick={onSaveBtnClick}
					/>
				</div>
				<div className="pt-4 pb-2 flex flex-col justify-end gap-2.5 sm:hidden">
					<CustomGreenBtn
						text="save"
						isDisabled={!!isBtnValid()}
						onRequestClick={onSaveBtnClick}
					/>
					<CustomWhiteBtn
						text="cancel"
						onRequestClick={() => dispatch(closeFilterModal())}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomFilter;

CustomFilter.propTypes = {
	startPrice: propTypes.string,
	endPrice: propTypes.string,
	categoryChecked: propTypes.array,
	setCategoryChecked: propTypes.func,
	colorChecked: propTypes.array,
	setColorChecked: propTypes.func,
	setPriceQuery: propTypes.func,
	onBtnClick: propTypes.func,
};
