import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalContainer from "../../../components/ModalContainer";
import InvestmentLists from "../../../components/InvestmentLists";
import { MdKeyboardArrowDown } from "react-icons/md";
import { DateInput, TableData, TableHead } from "../../leasing/LeaseRoi";
import { formatDurationForProgramSavings } from "../../../common/utils";
import moment from "moment";

const AppFinancaROI = ({ data }) => {
	const [toggleInvestments, setToggleInvestments] = useState(false);

	const toggleModal = () => {
		setToggleInvestments(!toggleInvestments);
	};

	const [filters, setFiters] = useState({
		selectedData: data[0] || null,
		from: "",
		to: "",
	});

	const { selectedData, from, to } = filters;

	const onChange = (e) =>
		setFiters((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const onInvestmentSelect = (data) => {
		setFiters((prev) => ({ ...prev, selectedData: data }));
		setToggleInvestments(false);
	};

	const lengthOfTable = formatDurationForProgramSavings(selectedData?.duration);

	const dataArray = Array(Number(lengthOfTable)).fill(selectedData);

	console.log(selectedData, lengthOfTable, dataArray);

	return (
		<>
			{toggleInvestments && (
				<ModalContainer modal={toggleInvestments}>
					<InvestmentLists
						investments={data}
						onClose={toggleModal}
						onSelect={onInvestmentSelect}
					/>
				</ModalContainer>
			)}
			<div className="mt-4 rounded-md py-4 md:py-5 bg-[#FBFBFB] border border-#D4DEE2] space-y-4 md:space-y-5 overflow-x-auto scroll-wrapper">
				<div className="flex justify-between items-center min-w-[900px] px-4 md:px-4">
					<button
						className="flex items-center justify-between gap-5 border border-[#879094] rounded p-2.5 w-[300px]"
						onClick={toggleModal}
					>
						<span
							className={`text-sm ${
								selectedData
									? "text-black zabalMedFont"
									: " zabalRegFont text-[#879094]"
							}`}
						>
							{selectedData?.objectId || "Select Investment"}
						</span>
						<MdKeyboardArrowDown size={22} />
					</button>
					<div className="flex items-center gap-4">
						<DateInput name="from" value={from} onChange={onChange} />
						<span>to</span>
						<DateInput name="to" value={to} onChange={onChange} />
					</div>
				</div>
				<div>
					<div className="grid grid-cols-7 gap-3 min-w-[900px] border-b pb-2 px-4 md:px-4">
						<TableHead text="Request ID" />
						<TableHead text="Req. Value (NGN)" />
						<TableHead text="Amount Invested" />
						<TableHead text="ROI" />
						<TableHead text="Month" />
						<TableHead text="Date" />
						<TableHead text="Status" />
					</div>
					<div className="min-w-[900px] grid gap-5 md:gap-6 lg:gap-7 pt-4 md:pt-5 pb-1 max-h-[230px] overflow-y-auto scroll-wrapper px-4 md:px-4">
						{dataArray.map((investment, i) => {
							const calculateProgramSavings = () => {
								const amount =
									Number(investment?.amountToInvest?.replaceAll(",", "")) || 0;

								const addedFee = amount < 100 ? 0.02 : 0.05;

								const savings = amount / lengthOfTable + addedFee || 0;

								const formattedSavings = savings?.toLocaleString(undefined, {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});

								return formattedSavings;
							};

							const month = new Date(investment?.createdAt).setMonth(
								new Date(investment?.createdAt).getMonth() + i
							);

							// console.log(investment);
							return (
								<div className="w-full grid grid-cols-7 gap-4" key={i}>
									<TableData text={investment?.objectId} />
									<TableData text={investment?.amountToInvest} isCenter />
									<TableData text={investment?.amountToInvest} isCenter />
									<TableData text={calculateProgramSavings()} />
									<TableData text={String(i + 1)} />
									<TableData text={moment(month).format("Do MMM, YYYY")} />
									<TableData text="Pending" />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

AppFinancaROI.propTypes = {
	data: PropTypes.array,
};

export default AppFinancaROI;
