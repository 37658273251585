import React, { useEffect, useState } from "react";
import { BackTracker } from "../../../layout/CustomComponents";
import {
	LeaseFormHeader,
	LeaseFormSelect,
	leaseTypeArr,
	productColors,
	productCondition,
	productYears,
} from "../requestlease/LeaseForm";
import { useNavigate } from "react-router-dom";
import { CustomGreenBtn } from "../../../layout/CustomButtons";
import { saveDataFromLeaseForm } from "../../../redux/lease/leaseActions";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearStatesOnConfirmLease } from "../../../redux/authRedux/authActions";
import { removeDuplicatesInData } from "../../../common/utils";
import {
	fetchBrands,
	fetchCategories,
} from "../../../redux/lease/leaseServices";
import Parse from "../../../api/ApiConfig";

const CreateSaving = ({ setActiveTab }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		leaseFormData,
		categoriesLoading,
		categoriesSuccess,
		brandsLoading,
		brandsSuccess,
	} = useSelector((state) => state.lease);

	useEffect(() => {
		dispatch(clearStatesOnConfirmLease());
	}, []);

	const [leaseForm, setLeaseForm] = useState({
		lease_type: leaseFormData?.lease_type || "",
		product_category: leaseFormData?.product_category || "",
		brand: leaseFormData?.brand || "",
		model: leaseFormData?.model || "",
		year: leaseFormData?.year || "",
		condition: leaseFormData?.condition || "",
		color: leaseFormData?.color || "",
	});
	const { lease_type, product_category, brand, model, year, condition, color } =
		leaseForm;

	const data = {
		lease_type,
		product_category,
		brand,
		model,
		year,
		condition,
		color,
	};

	const onProceedToNextTab = () => {
		if (lease_type === "") {
			toast.error("Please choose a lease type");
			return;
		}
		dispatch(saveDataFromLeaseForm(data));
		setActiveTab(2);
	};

	const [toggleLeaseType, setToggleLeaseType] = useState(false);
	const [toggleProductCategory, setToggleProductCategory] = useState(false);
	const [toggleProductBrand, setToggleProductBrand] = useState(false);
	const [toggleProductModel, setToggleProductModel] = useState(false);
	const [toggleYear, setToggleYear] = useState(false);
	const [toggleCondtions, setToggleConditions] = useState(false);
	const [toggleColor, setToggleColor] = useState(false);

	const getChoosenBrand = () => {
		const selectedCategory = categoriesSuccess?.filter(
			(a) => a.categoryName === product_category
		);
		return selectedCategory;
	};

	const selectedBrand = getChoosenBrand();

	const getQuery = () => {
		let arr = [];
		for (let i = 0; i < selectedBrand?.length; i++) {
			const query = new Parse.Query("Brand");
			query.equalTo("categoryId", selectedBrand[i].objectId);
			arr.push(query);
		}
		return arr;
	};

	const handleLeaseTypeSelect = () => {
		setToggleLeaseType((prev) => !prev);
		setToggleProductCategory(false);
		setToggleProductBrand(false);
	};

	const handleProductCategoryChange = () => {
		setToggleProductCategory((prev) => !prev);
		setToggleProductBrand(false);
		setToggleProductModel(false);

		if (!toggleProductCategory) {
			dispatch(fetchCategories());
		}
	};

	const handleSelectProductBrand = () => {
		if (product_category === "") {
			toast.error("Please select a product category");
		} else {
			setToggleProductBrand((prev) => !prev);
			setToggleProductModel(false);
			setToggleProductCategory(false);

			if (!toggleProductBrand) {
				dispatch(fetchBrands(getQuery()));
			}
		}
	};

	const handleSelectProductModel = () => {
		if (brand === "") {
			toast.error("Please select a product brand");
		} else {
			setToggleProductModel((prev) => !prev);
			setToggleYear(false);
			setToggleConditions(false);
		}
	};

	const handleSelectProductYear = () => {
		setToggleYear((prev) => !prev);
		setToggleConditions(false);
		setToggleColor(false);
	};

	const handleSelectProductCondtion = () => {
		setToggleConditions((prev) => !prev);
		setToggleColor(false);
	};

	const newCategoryArray = removeDuplicatesInData(
		categoriesSuccess?.map((d) => d.categoryName)
	);

	const newBrandsArray = removeDuplicatesInData(
		brandsSuccess?.map((d) => d.brand)
	);

	const brandModels = removeDuplicatesInData(
		brandsSuccess
			?.filter((a) => a.brand === brand)
			.map((b) => b.model)
			.filter((c) => c !== "")
	);

	return (
		<>
			<BackTracker text="asset based savings" onNavigate={() => navigate(-1)} />
			<p className="text-xs pb-4 text-[#393F42] zabalMedFont">
				Create a saving plan to lease on asset
			</p>
			<div className="rounded-md p-4 sm:p-5 lg:p-6 bg-[#F6F7F8] space-y-4 sm:space-y-5">
				<LeaseFormHeader
					text="create saving"
					onRequestClick={() => navigate(-1)}
				/>
				<div className="grid gap-4 md:gap-5 lg:gap-6">
					<LeaseFormSelect
						text="lease type"
						context="choose lease type"
						isOpened={toggleLeaseType}
						currentText={lease_type}
						isShowError={true}
						errorText="Please choose a lease type"
						handleToggle={handleLeaseTypeSelect}
						arrOptions={leaseTypeArr}
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({ ...prev, lease_type: d }));
							setTimeout(() => {
								setToggleLeaseType(false);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="product category"
						context="select product category"
						isOpened={toggleProductCategory}
						currentText={product_category}
						handleToggle={handleProductCategoryChange}
						isLoading={categoriesLoading}
						arrOptions={newCategoryArray}
						emptyMsg="No products category available"
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({
								...prev,
								product_category: d,
								brand: "",
								model: "",
							}));
							setTimeout(() => {
								setToggleProductCategory(false);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="brand"
						context="choose product brand"
						isOpened={toggleProductBrand}
						currentText={brand}
						handleToggle={handleSelectProductBrand}
						isLoading={brandsLoading}
						arrOptions={newBrandsArray}
						emptyMsg="No brands exist for the selected category"
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({ ...prev, brand: d, model: "" }));
							setTimeout(() => {
								setToggleProductBrand(false);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="model"
						context="choose product model"
						isOpened={toggleProductModel}
						currentText={model}
						handleToggle={handleSelectProductModel}
						arrOptions={brandModels}
						emptyMsg="No models available for the selected brand"
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({ ...prev, model: d }));
							setTimeout(() => {
								setToggleProductModel(false);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="year"
						context="choose year"
						isOpened={toggleYear}
						currentText={year}
						handleToggle={handleSelectProductYear}
						arrOptions={productYears.map((a) => a.label)}
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({
								...prev,
								year: productYears?.find((a) => a.label === d).value,
							}));
							setTimeout(() => {
								setToggleYear(false);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="condition"
						context="select product condition"
						isOpened={toggleCondtions}
						currentText={condition}
						handleToggle={handleSelectProductCondtion}
						arrOptions={productCondition}
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({ ...prev, condition: d }));
							setTimeout(() => {
								setToggleConditions(false);
							}, 100);
						}}
					/>
					<LeaseFormSelect
						text="color"
						context="choose product color"
						currentText={color}
						isOpened={toggleColor}
						handleToggle={() => setToggleColor((prev) => !prev)}
						arrOptions={productColors}
						onHandleSelect={(d) => {
							setLeaseForm((prev) => ({ ...prev, color: d }));
							setTimeout(() => {
								setToggleColor(false);
							}, 100);
						}}
					/>
					<div className="bg-[#E3E9EC] h-[1px] w-full hidden sm:block"></div>

					{/* form buttons */}
					<div className="hidden sm:flex justify-end gap-4">
						<CustomGreenBtn
							text="proceed"
							width="115px"
							height="42px"
							fontSize="13.5px"
							onRequestClick={onProceedToNextTab}
							isDisabled={lease_type === "" || product_category === ""}
						/>
					</div>
					<div className="flex flex-col gap-4 pt-4 sm:hidden">
						<CustomGreenBtn
							text="proceed"
							onRequestClick={onProceedToNextTab}
							isDisabled={lease_type === "" || product_category === ""}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default CreateSaving;

CreateSaving.propTypes = {
	setActiveTab: PropTypes.func,
};
