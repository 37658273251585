import React, { useRef, useEffect } from "react";
import { Chart as ChartJs } from "chart.js/auto";
import PropTypes from "prop-types";

const BarChart = ({ data, labels, primaryColor, secondaryColor }) => {
	const chartRef = useRef(null);
	const chartInstance = useRef(null);

	useEffect(() => {
		if (chartInstance.current) {
			chartInstance.current.destroy();
		}

		if (chartRef.current) {
			const ctx = chartRef.current.getContext("2d");

			// Data for the chart
			const chartData = {
				labels: labels,
				datasets: [
					{
						label: "Total Value of Active Investment",
						data: data.totalValue,
						backgroundColor: primaryColor,
						borderWidth: 0,
						pointStyle: "rectRot",
						pointRadius: 6,
					},
					{
						label: "Total Interest of Active Investment",
						data: data.totalInterest,
						backgroundColor: secondaryColor,
						borderWidth: 0,
						pointStyle: "rectRot",
						pointRadius: 6,
					},
				],
			};

			// Chart configuration
			const chartConfig = {
				type: "bar",
				data: chartData,
				options: {
					scales: {
						y: {
							beginAtZero: true,
						},
					},
				},
			};

			chartInstance.current = new ChartJs(ctx, chartConfig);
		}
	}, [data, labels, primaryColor, secondaryColor]);

	return (
		<div>
			<canvas ref={chartRef}></canvas>
		</div>
	);
};

BarChart.propTypes = {
	data: PropTypes.any,
	labels: PropTypes.arrayOf(PropTypes.string),
	primaryColor: PropTypes.string,
	secondaryColor: PropTypes.string,
};
export default BarChart;
