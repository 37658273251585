import React, { useContext, useState } from "react";
import { HeadStyle, Right } from "./styled";
import { PageLayout } from "../globalStyled";
import Logo from "./assets/logo.png";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Parse from "../../api/ApiConfig";
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	SwipeableDrawer,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import userContext from "../../context/UserContext";

function Header() {
	const theme = useTheme();
	const [menu, setMenu] = useState(false);
	const navigate = useNavigate();
	const currentUser = Parse.User.current();
	const { setCreateAccountModal } = useContext(userContext);

	//Mobile Drawer
	const list = () => (
		<Box
			sx={{
				width: 250,
				background: "#EEF2F3",
				height: "100%",
				padding: "10px",
			}}
			role="presentation"
			onClick={() => setMenu(false)}
			onKeyDown={() => setMenu(false)}>
			<img src={Logo} alt="" />
			<Typography color="secondary.light" sx={{ margin: "18px 0" }}>
				Continue with us:
			</Typography>
			<List>
				<ListItem disablePadding>
					<ListItemButton
						sx={{
							border: `1px solid ${theme.palette.primary.main}`,
							borderRadius: "8px",
							color: theme.palette.primary.main,
							fontFamily: "ZabalMedium",
						}}
						onClick={() => navigate("/login")}>
						<ListItemText primary="Login" sx={{ textAlign: "center" }} />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding sx={{ marginTop: "8px" }}>
					<ListItemButton
						sx={{
							background: theme.palette.primary.main,
							borderRadius: "8px",
							color: theme.palette.primary.light,
							fontFamily: "ZabalMedium",
						}}
						// onClick={() => navigate("/create-account")}
						onClick={() => setCreateAccountModal(true)}>
						<ListItemText
							primary="Create Account"
							sx={{ textAlign: "center" }}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	return (
		<HeadStyle>
			<PageLayout>
				<div className="head">
					<div className="left">
						<img src={Logo} alt="" />
					</div>

					<Right>
						<ul className="zabalRegFont">
							<li className="desktop">
								{!currentUser ? (
									<Stack direction="row" spacing={1}>
										<Button
											variant="outlined"
											sx={{
												width: "158px",
												height: "50px",
												borderRadius: "8px",
												border: `1px solid ${theme.palette.primary.dark}`,
												textTransform: "capitalize",
											}}
											onClick={() => navigate("/login")}>
											Login
										</Button>

										<Button
											variant="contained"
											sx={{
												background: theme.palette.primary.dark,
												borderRadius: "8px",
												width: "158px",
												height: "50px",
												textTransform: "capitalize",
											}}
											// onClick={() => navigate("/create-account")}
											onClick={() => setCreateAccountModal(true)}>
											Create Account
										</Button>
									</Stack>
								) : (
									<Button
										variant="contained"
										sx={{
											background: theme.palette.primary.dark,
											borderRadius: "8px",
											width: "158px",
											height: "50px",
											textTransform: "capitalize",
										}}
										onClick={() => navigate("/dashboard")}>
										Dashboard
									</Button>
								)}
							</li>

							<li className="mobile">
								{!menu ? (
									<FiMenu className="icon" onClick={() => setMenu(true)} />
								) : (
									<AiOutlineClose
										className="icon"
										onClick={() => setMenu(false)}
									/>
								)}

								{["right"].map((anchor) => (
									<React.Fragment key={anchor}>
										<SwipeableDrawer
											anchor={anchor}
											open={menu}
											onClose={() => setMenu(false)}
											onOpen={() => setMenu(true)}>
											{list(anchor)}
										</SwipeableDrawer>
									</React.Fragment>
								))}
							</li>
						</ul>
					</Right>
				</div>
			</PageLayout>
		</HeadStyle>
	);
}

export default Header;
