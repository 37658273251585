import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../../../components/mui/theme";
import { useNavigate } from "react-router-dom";
import TextFader from "./TextFader";

function Cta() {
	const navigate = useNavigate();
	// eslint-disable-next-line no-undef
	const defaultEmail = localStorage.getItem("email");
	const [email, setEmail] = useState(defaultEmail || "");

	const handleExplore = (e) => {
		e.preventDefault();
		// eslint-disable-next-line no-undef
		localStorage.setItem("email", email);
		navigate("/login");
		// eslint-disable-next-line no-undef
		window.scrollTo(0, 0);
	};
	return (
		<>
			<Container maxWidth="lg">
				<Box mt={{ xs: 10, md: 5 }}>
					<Stack>
						<Typography
							variant="caption"
							color="primary.main"
							sx={{ fontSize: { sm: "16px" } }}
						>
							WHY FORTCORE ?
						</Typography>
						{/* <Typography
              variant="h1"
              sx={{
                fontWeight: 600,
                color: "var(--grey-800, #717E83)",
                fontSize: { sm: "64px" },
              }}
            >
              Solve for questions
            </Typography> */}
						<TextFader />
					</Stack>
				</Box>

				<Box
					mt={{ xs: 10, md: 5 }}
					mb={10}
					sx={{
						background: theme.palette.primary.main,
						borderRadius: "10px",
						height: "290px",
						p: 4,
						display: "flex",
						alignItems: "center",
						boxShadow: "0px 30px 40px 0px rgba(0, 102, 244, 0.25)",
					}}
				>
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
							gap: 2,
							alignItems: "center",
						}}
					>
						<Typography
							variant="h1"
							color="primary.light"
							sx={{ fontWeight: 600, fontSize: { xs: "28px", sm: "45px" } }}
						>
							Create an account, access alternative financing today.
						</Typography>
						<Box component="form" onSubmit={handleExplore}>
							<Stack
								direction={{ xs: "column", md: "row" }}
								spacing={1.2}
								sx={{
									background: {
										xs: "transparent",
										sm: "rgba(113, 126, 131, 0.70)",
									},
									borderRadius: "8px",
									padding: "5px",
								}}
							>
								<input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter your E-mail"
									style={{
										height: "50px",
										width: "100%",
										padding: "10px",
										borderRadius: "8px",
										color: "#fff",
									}}
									className="lInput"
									required
								/>
								<Button
									variant="contained"
									type="submit"
									sx={{
										height: "50px",
										background: theme.palette.primary.light,
										color: theme.palette.primary.main,
										width: "158px",
										"&:hover": {
											height: "50px",
											background: theme.palette.primary.light,
											color: theme.palette.primary.main,
											width: "158px",
										},
									}}
								>
									Explore
								</Button>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default Cta;
