import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NotifyUser from "../../../layout/NotifyUser";
import {
	formatAmountToIncludeCurrency,
	onInputAmount,
	walletType,
} from "../../../common/utils";

const WithdrawalAmount = ({
	setActiveWithdrawalTab,
	onCloseModal,
	amount,
	description,
	handleChange,
}) => {
	const { customerWallet } = useSelector((state) => state.payment);

	const mainWallet = customerWallet?.find(
		(wallet) => wallet?.product === walletType.main
	);

	const isBtnDisabled = () => {
		if (amount === "") {
			return true;
		}
		if (Number(amount?.replaceAll(",", "")) < 100) {
			return true;
		}
		if (mainWallet?.availableBalance < Number(amount?.replaceAll(",", ""))) {
			return true;
		} else {
			return false;
		}
	};

	const onProceedBtnClick = () => {
		setActiveWithdrawalTab(3);
	};

	const userWalletCurrency = customerWallet ? mainWallet?.currency : "NGN";
	const userWalletBalance = customerWallet ? mainWallet?.availableBalance : 0;

	const isAmountValid = useMemo(() => {
		if (amount.length > 0 && Number(amount?.replaceAll(",", "")) < 100) {
			return "Amount cannot be less than NGN 100";
		}
		if (
			amount.length > 0 &&
			mainWallet?.availableBalance < Number(amount?.replaceAll(",", ""))
		) {
			return `Amount cannot be greater than ${formatAmountToIncludeCurrency(
				userWalletCurrency,
				userWalletBalance
			)}`;
		} else {
			return "";
		}
	}, [amount]);

	return (
		<NotifyUser
			header="Amount"
			title="Account Balance:"
			boldTitle={formatAmountToIncludeCurrency(
				userWalletCurrency,
				userWalletBalance
			)}
			onCloseBtnClick={onCloseModal}
			onWhiteBtnClick={() => setActiveWithdrawalTab(1)}
			onGreenBtnClick={onProceedBtnClick}
			isGreenBtnDisabled={isBtnDisabled()}
			btnText="proceed"
			color={Number(userWalletBalance) < 100 ? "red" : "#00525D"}
		>
			<div className="space-y-4">
				<div className="space-y-1">
					<FieldInput
						labelText="Amount to send (NGN)"
						type="tel"
						handleChange={handleChange}
						value={amount}
						placeholderText="100"
						name="amount"
					/>
					{isAmountValid && (
						<p className="text-xs text-red-500 zabalMedFont">{isAmountValid}</p>
					)}
				</div>
				<FieldInput
					labelText="Description"
					type="text"
					handleChange={handleChange}
					value={description}
					placeholderText="Description (Optional) max. of 25 characters"
					name="description"
				/>
			</div>
		</NotifyUser>
	);
};

export default WithdrawalAmount;

WithdrawalAmount.propTypes = {
	setActiveWithdrawalTab: PropTypes.func,
	onCloseModal: PropTypes.func,
	handleChange: PropTypes.func,
	amount: PropTypes.string,
	description: PropTypes.string,
};

const FieldInput = ({
	labelText,
	type,
	value,
	handleChange,
	placeholderText,
	name,
}) => {
	return (
		<div className="flex flex-col gap-1">
			<label htmlFor={name} className="text-xs zabalMedFont">
				{labelText}
			</label>
			<input
				name={name}
				type={type}
				value={value}
				onChange={handleChange}
				placeholder={placeholderText}
				className="zabalRegFont rounded-md outline-none h-[43px] px-2.5 border border-[#96A0A5] focus:border-2 focus:border-fortcore-green placeholder:text-[#B8B8B8] text-sm"
				onInput={type === "tel" ? onInputAmount : null}
				maxLength={25}
			/>
		</div>
	);
};

FieldInput.propTypes = {
	handleChange: PropTypes.func,
	labelText: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	placeholderText: PropTypes.string,
	name: PropTypes.string,
};
