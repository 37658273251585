import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { BsFillClockFill } from "react-icons/bs";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { EducationContext } from "../../Context/EducationContext";

function Video() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { loading, filteredData } = useContext(EducationContext);

  const courseType = filteredData.filter(
    (video) => video.courseType === "video"
  );
  const videoData = courseType.filter((video) => video.approve === true);
  const openedArray = videoData.filter((video) => video.isOpened === true);

  // eslint-disable-next-line no-undef
  console.log("videoData: ", videoData);
  return (
    <>
      <Box>
        {/* Suggestions For you  */}
        <Box>
          {loading ? (
            <Box>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Box>
          ) : (
            <>
              {videoData.length > 0 ? (
                <Typography color="primary" variant="h3">
                  Suggestions For You
                </Typography>
              ) : (
                <Typography color="primary" variant="h3">
                  No Videos at the moment
                </Typography>
              )}
            </>
          )}
          <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

          <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
            {loading ? (
              <>
                {loadingData.map((index) => (
                  <>
                    <Skeleton
                      key={index}
                      variant="rounded"
                      sx={{
                        minWidth: { xs: "100%", sm: 345 },
                        maxWidth: 345,
                        height: "222px",
                        borderRadius: "8px",
                      }}
                    />
                  </>
                ))}
              </>
            ) : (
              <>
                {videoData.length > 0 ? (
                  <>
                    {videoData.map((data, index) => (
                      <>
                        <Card
                          key={index}
                          sx={{
                            minWidth: { xs: "100%", sm: 345 },
                            maxWidth: 345,
                            height: "222px",
                            backgroundImage: `url(${data.coverPhoto})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/education/video/${data.objectId}`);
                          }}
                        >
                          <CardActionArea>
                            <CardContent
                              sx={{ background: "rgba(51, 51, 51, 0.4)" }}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                color="secondary"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {data.name}
                              </Typography>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="caption"
                                  color="secondary"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {data.category}
                                </Typography>

                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <BsFillClockFill
                                    color={theme.palette.primary.main}
                                  />
                                  <Typography variant="body2" color="secondary">
                                    + {data.totalDuration} Long
                                  </Typography>
                                </Stack>
                              </Stack>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Stack>
        </Box>

        {/* Resume your course  */}
        <Box sx={{ marginTop: "40px" }}>
          {loading ? (
            <Box>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Box>
          ) : (
            <>
              {videoData.length > 0 ? (
                <>
                  {openedArray.length > 0 ? (
                    <Typography color="primary" variant="h3">
                      Resume Your Course
                    </Typography>
                  ) : (
                    <Typography color="primary" variant="h3">
                      You have no course started
                    </Typography>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <Divider sx={{ margin: "20px 0", border: "1px solid #EEF0F1" }} />

          <Stack direction="row" spacing={2} sx={scrollableContainerStyles}>
            {loading ? (
              <>
                {loadingData.map((index) => (
                  <>
                    <Skeleton
                      key={index}
                      variant="rounded"
                      sx={{
                        minWidth: { xs: "100%", sm: 345 },
                        maxWidth: 345,
                        height: "222px",
                        borderRadius: "8px",
                      }}
                    />
                  </>
                ))}
              </>
            ) : (
              <>
                {openedArray.length > 0 ? (
                  <>
                    {openedArray.map((data, index) => (
                      <>
                        <Card
                          key={index}
                          sx={{
                            minWidth: { xs: "100%", sm: 345 },
                            maxWidth: 345,
                            height: "222px",
                            backgroundImage: `url(${data.coverPhoto})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/education/video/${data.objectId}`)
                          }
                        >
                          <CardActionArea>
                            <CardContent
                              sx={{ background: "rgba(51, 51, 51, 0.4)" }}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                color="secondary"
                              >
                                {data.name}
                              </Typography>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography variant="body2" color="secondary">
                                  {data.category}
                                </Typography>

                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <BsFillClockFill
                                    color={theme.palette.primary.main}
                                  />
                                  <Typography variant="body2" color="secondary">
                                    + {data.totalDuration} Long
                                  </Typography>
                                </Stack>
                              </Stack>

                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                marginTop="5px"
                              >
                                <Box width="90%">
                                  <LinearProgress
                                    variant="determinate"
                                    value={0}
                                    sx={{
                                      borderRadius: "2px",
                                      "& .MuiLinearProgress-bar": {
                                        borderRadius: "2px",
                                      },
                                    }}
                                  />
                                </Box>
                                <Typography
                                  color="primary.light"
                                  variant="caption"
                                >
                                  %{0}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default Video;

const scrollableContainerStyles = {
  overflow: "auto",
  pb: 1.5,
};

export const loadingData = [{}, {}, {}, {}, {}, {}];
