import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
	CustomRadioInput,
	PlainSelect,
	SelectDropDown,
} from "../../../../layout/CustomComponents";
import { countries } from "../../../../common/utils";
import { ProfileInput } from "../../../profile/sections/PersonalInfo";
import { CustomGreenBtn } from "../../../../layout/CustomButtons";
import Parse from "../../../../api/ApiConfig";
import { saveTravelInformation } from "../../../../redux/travels/travelServices";
import { BsSearch } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";

const TravelInformation = () => {
	const dispatch = useDispatch();
	const { travelInfoLoading, travelInfoSuccess } = useSelector(
		(state) => state.travel
	);

	const [toggleCountry, setToggleCountry] = useState(false);
	const [travelInfo, setTravelInfo] = useState({
		destination_country: travelInfoSuccess?.destinationCountry || "",
		departure_date: travelInfoSuccess?.expectedDepartureDate
			? moment(travelInfoSuccess?.expectedDepartureDate, "Do MMMM YYYY").format(
					"YYYY-MM-DD"
			  )
			: "",
		travel_purpose: travelInfoSuccess?.travelPurpose || "",
		travel_duration: travelInfoSuccess?.lengthOfStay || "",
		travel_experience: travelInfoSuccess?.firstTime ? "yes" : "no",
	});

	const {
		destination_country,
		departure_date,
		travel_duration,
		travel_purpose,
		travel_experience,
	} = travelInfo;

	const filteredCountries = countries.filter((country) =>
		country.name.toLowerCase().includes(destination_country?.toLowerCase())
	);

	const handleChange = (e) =>
		setTravelInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

	const isBtnDisabled = () => {
		if (
			destination_country === "" ||
			departure_date === "" ||
			travel_duration === "" ||
			travel_purpose === "" ||
			travel_experience === ""
		) {
			return true;
		} else {
			return false;
		}
	};

	const User = Parse.Object.extend("_User");
	const user = new User();
	user.id = Parse.User.current()?.id;

	const onTravelInfoSave = () => {
		const data = {
			userId: user,
			travelPurpose: travel_purpose,
			destinationCountry: destination_country,
			expectedDepartureDate: moment(departure_date).format("Do MMMM YYYY"),
			lengthOfStay: travel_duration,
			firstTime: travel_experience === "yes" ? true : false,
		};
		dispatch(saveTravelInformation(data));
	};

	return (
		<div className="flex flex-col gap-2.5 md:gap-3 bg-white rounded p-3 md:p-3.5 border border-[#B8C8CF]">
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 relative z-[1] text-[#393F42]">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					Destination Country:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative">
					{toggleCountry ? (
						<div className="relative h-[43px]">
							<input
								type="text"
								className="h-full text-[text-[#393F42] border rounded border-[#B8B8B8] px-2.5 w-full outline-none text-sm zabalRegFont focus:border-2 focus:border-fortcore-green text-[#393F42] placeholder:text-[#B8B8B8] placeholder:text-[13px]"
								id="destination_country"
								value={destination_country}
								onChange={handleChange}
								placeholder="Search countries"
								onBlur={() => setToggleCountry(false)}
							/>
							<span className="top-0 absolute right-2 h-full grid place-items-center w-[40px] text-[#B8B8B8]">
								<BsSearch size={15} />
							</span>
						</div>
					) : (
						<PlainSelect
							context="select destination country"
							isOpened={toggleCountry}
							currentText={destination_country}
							handleToggle={() => setToggleCountry(!toggleCountry)}
						/>
					)}

					{toggleCountry && filteredCountries?.length > 0 ? (
						<div className="absolute z-[2] w-full top-48px pb-8">
							<SelectDropDown
								arrOptions={filteredCountries?.map((country) => country.name)}
								onHandleSelect={(d) => {
									setTravelInfo((prev) => ({
										...prev,
										destination_country: d,
									}));
									setTimeout(() => {
										setToggleCountry(false);
									}, 100);
								}}
							/>
						</div>
					) : (
						toggleCountry &&
						filteredCountries?.length < 1 && (
							<div className="w-full absolute z-[2] top-[48px] shadow-md bg-white h-[80px] grid place-items-center zabalRegFont mb-5">
								<div className="flex justify-center flex-col items-center">
									<FaBoxOpen size={35} color="#b5b5b5" />
									<p className="text-[13px] font-medium text-[#b2b2b2]">
										No country matches the search text
									</p>
								</div>
							</div>
						)
					)}
				</div>
			</div>
			<ProfileInput
				type="date"
				text="Expected Departure Date"
				value={departure_date}
				id="departure_date"
				placeholderText="Choose departure date"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="Travel Purpose"
				value={travel_purpose}
				id="travel_purpose"
				placeholderText="Enter travelling purpose"
				handleChange={handleChange}
			/>
			<ProfileInput
				type="text"
				text="How long do you intend to stay?"
				value={travel_duration}
				id="travel_duration"
				placeholderText="Enter travel duration"
				handleChange={handleChange}
			/>
			<div className="flex flex-col sm:flex-row sm:items-center ap-1.5 sm:gap-x-5 md:gap-x-7 gap-y-2 text-[#393F42]">
				<p className="w-full sm:w-[30%] md:w-1/5 text-xs md:text-[13px] zabalMedFont">
					Is this your first time of travelling:
				</p>
				<div className="w-full sm:w-[70%] md:w-4/5 text-sm relative text-[13px] zabalMedFont flex gap-7 lg:gap-10">
					<div className="flex items-center gap-2">
						<CustomRadioInput
							name="travel_experience"
							isChecked={travel_experience === "yes"}
							value="yes"
							id="travel_experience"
							handleChange={handleChange}
						/>
						<span>Yes</span>
					</div>
					<div className="flex items-center gap-2">
						<CustomRadioInput
							name="travel_experience"
							value="no"
							isChecked={travel_experience === "no"}
							id="travel_experience"
							handleChange={handleChange}
						/>
						<span>No</span>
					</div>
				</div>
			</div>
			<div className="hidden sm:flex justify-end py-2">
				<CustomGreenBtn
					text="save"
					width="105px"
					height="42px"
					fontSize="13px"
					isDisabled={isBtnDisabled() || travelInfoLoading}
					isLoading={travelInfoLoading}
					onRequestClick={onTravelInfoSave}
				/>
			</div>
			<div className="sm:hidden pt-4 pb-2">
				<CustomGreenBtn
					text="save"
					isDisabled={isBtnDisabled() || travelInfoLoading}
					isLoading={travelInfoLoading}
					onRequestClick={onTravelInfoSave}
				/>
			</div>
		</div>
	);
};

export default TravelInformation;
