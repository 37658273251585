import React from "react";
import { MdKeyboardArrowRight, MdOutlineClose } from "react-icons/md";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { selectedPaymentMethod } from "../../redux/lease/leaseActions";

const PaymentOption = ({ onClose, onWalletSelect, onCardSelect }) => {
	const dispatch = useDispatch();

	const onWalletClick = (text) => {
		dispatch(selectedPaymentMethod({ method: text }));
		onWalletSelect();
	};
	const onCardClick = (text) => {
		dispatch(selectedPaymentMethod({ method: text }));
		onCardSelect();
	};

	return (
		<div className="w-full grid place-items-center">
			<div className="rounded-lg bg-[#F6F7F8] w-11/12 max-w-[400px] sm:max-w-[410px] mx-auto p-5 md:p-6 space-y-3 md:space-y-4">
				<div className="space-y-1">
					<div className="flex items-center justify-between text-fortcore-green">
						<p className="text-[15px] zabalBoldFont">Select Option</p>
						<button onClick={onClose}>
							<MdOutlineClose size={18} />
						</button>
					</div>
					<p className="text-xs text-[#717E83] zabalRegFont">
						Choose preferred payment mode
					</p>
				</div>
				<div className="grid gap-2">
					<Option
						text="Investment from wallet"
						onRequestClick={() => onWalletClick("wallet")}
					/>
					<Option
						text="Investment from bank card"
						onRequestClick={() => onCardClick("card")}
						isUnavailable={true}
					/>
				</div>
			</div>
		</div>
	);
};
export default PaymentOption;

PaymentOption.propTypes = {
	onClose: PropTypes.func,
	onWalletSelect: PropTypes.func,
	onCardSelect: PropTypes.func,
};

export const Option = ({ text, onRequestClick, height, isUnavailable }) => {
	return (
		<button
			className={`px-4 md:px-5 text-[13.5px] zabalRegFont font-semibold flex items-center justify-between border border-[#E3E9EC] text-[#393F42] rounded-md ${
				height ? "h-[82px] md:h-[85px] xl:h-[90px]" : "h-[70px] sm:h-[75px]"
			} ${
				isUnavailable
					? "bg-gray-50"
					: "bg-[#fefefe]  hover:border-[#557C8A] hover:text-fortcore-green hover:bg-[#EEF2F3]"
			}`}
			onClick={onRequestClick}
			disabled={isUnavailable}
		>
			<p>{text}</p>
			<span>
				<MdKeyboardArrowRight size={22} />
			</span>
		</button>
	);
};

Option.propTypes = {
	text: PropTypes.string,
	onRequestClick: PropTypes.func,
	height: PropTypes.bool,
	isUnavailable: PropTypes.bool,
};
