import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { onBlurAmount, onInputAmount } from "../../../../common/utils";
import PropTypes from "prop-types";
import { QuestionMarkIcon } from "../../../../layout/assets";
import { BiSolidLock } from "react-icons/bi";
import theme from "../../../../components/mui/theme";
import { toast } from "react-toastify";
import Parse from "../../../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { createNotification } from "../../../../components/notification/CreateNotification";
import { Preloader } from "../../../../components/globalStyled";

function Restructure({ data, setRestructureModal, setVerify, verify }) {
	const navigate = useNavigate();
	const [formRestructure, setFormRestructure] = useState({
		restructureAmount: "",
		equityContribution: "",
		interest: "",
	});
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line no-undef, no-unused-vars
	const [interestRate, setInterestRate] = useState(0);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setFormRestructure((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	useEffect(() => {
		const calculateEquityContribution = () => {
			const amount = parseFloat(
				formRestructure.restructureAmount.replace(/,/g, "")
			);
			const calculatedEquityContribution = (amount * 20) / 100;

			const formattedEquityContribution =
				calculatedEquityContribution.toLocaleString(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});

			setFormRestructure((prevState) => ({
				...prevState,
				equityContribution: formattedEquityContribution,
			}));
		};

		const calculateFormerInterest = () => {
			const amount = parseFloat(data.detailsOfRequest.amount.replace(/,/g, ""));
			const monthlyInterest = data.detailsOfRequest.monthlyInterest;
			const calculatedFormerInterest = (amount * monthlyInterest) / 100;

			const formattedFormerInterest = calculatedFormerInterest.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}
			);

			setInterestRate(formattedFormerInterest);
		};

		const calculateNewInterest = () => {
			const amount = parseFloat(
				formRestructure.restructureAmount.replace(/,/g, "")
			);
			const monthlyInterest = data.detailsOfRequest.monthlyInterest;
			const calculatedNewInterest = (amount * monthlyInterest) / 100;

			const formattedNewInterest = calculatedNewInterest.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}
			);

			setFormRestructure((prevState) => ({
				...prevState,
				interest: formattedNewInterest,
			}));
		};

		calculateEquityContribution();
		calculateFormerInterest();
		calculateNewInterest();
	}, [formRestructure.restructureAmount, data.detailsOfRequest.amount]);

	const handleRestructure = () => {
		setLoading(true);
		const currentUser = Parse.User.current();

		if (currentUser) {
			let RestructureTA = new Parse.Object("TA_DetailsOfRequest");
			RestructureTA.set("objectId", data.detailsOfRequest.objectId);
			RestructureTA.set("amount", formRestructure.restructureAmount);
			RestructureTA.set(
				"equityContribution",
				formRestructure.equityContribution
			);

			RestructureTA.save().then((result) => {
				toast.success("Application Restructured Successfully");
				navigate("/travels");
				setLoading(false);
				const id = result.id;

				const message = "Travel Advance Restructure";
				const metaData = {
					category: "TA",
					linkToId: id,
					moreDetails: `You restructured your Travel Advance request amount. New request amount is NGN ${formRestructure.restructureAmount}`,
				};

				createNotification(message, metaData);
			});
		} else {
			toast.error("Session Expired");
			navigate("/login");
			setLoading(false);
		}
	};
	// eslint-disable-next-line no-undef
	console.log("Data", data);
	return (
		<>
			<Box sx={{ overflow: "auto" }}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between">
					<Typography variant="h2" color="primary">
						{!verify ? "Restructure Transaction" : ""}
					</Typography>
					<IconButton
						onClick={() => {
							setRestructureModal(false);
							setVerify(false);
						}}
						color="primary">
						<MdClose />
					</IconButton>
				</Stack>

				{!verify ? (
					<Box
						mt={3}
						component="form"
						onSubmit={(e) => {
							e.preventDefault();
							if (formRestructure.restructureAmount) {
								setVerify(true);
							} else {
								toast.error("New Request Amount is required");
							}
						}}>
						<Stack spacing={2}>
							{/* Requested Amount */}
							<Stack
								spacing={1}
								direction={{ sm: "row" }}
								justifyContent={{ sm: "space-between" }}
								alignItems={{ sm: "center" }}>
								<Typography width={{ xs: "100%", sm: "60%" }}>
									Requested Amount:
								</Typography>
								<TextField
									fullWidth
									type="tel"
									value={data.detailsOfRequest.amount}
									InputProps={{
										readOnly: "true",
										startAdornment: (
											<InputAdornment position="start">
												<Typography sx={{ fontFamily: "ZabalMedium" }}>
													NGN
												</Typography>
											</InputAdornment>
										),
									}}
								/>
							</Stack>

							{/* New Request  */}
							<Stack
								spacing={1}
								direction={{ sm: "row" }}
								justifyContent={{ sm: "space-between" }}
								alignItems={{ sm: "center" }}>
								<Typography width={{ xs: "100%", sm: "60%" }}>
									New Request:
								</Typography>

								<TextField
									fullWidth
									type="tel"
									name="restructureAmount"
									value={formRestructure.restructureAmount}
									onChange={handleInputChange}
									onInput={onInputAmount}
									onBlur={(e) =>
										onBlurAmount(e, formRestructure.restructureAmount)
									}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Typography sx={{ fontFamily: "ZabalMedium" }}>
													NGN
												</Typography>
											</InputAdornment>
										),
									}}
								/>
							</Stack>

							{/* Equity Contribution */}
							<Stack
								spacing={1}
								direction={{ sm: "row" }}
								justifyContent={{ sm: "space-between" }}
								alignItems={{ sm: "center" }}>
								<Typography width={{ xs: "100%", sm: "60%" }}>
									Equity Contribution:
								</Typography>
								<TextField
									fullWidth
									type="tel"
									name="equityContribution"
									value={formRestructure.equityContribution}
									InputProps={{
										readOnly: "true",
										startAdornment: (
											<InputAdornment position="start">
												<Typography sx={{ fontFamily: "ZabalMedium" }}>
													NGN
												</Typography>
											</InputAdornment>
										),
									}}
								/>
							</Stack>

							{/* Tenure */}
							{/* <Stack
								spacing={1}
								direction={{ sm: "row" }}
								justifyContent={{ sm: "space-between" }}
								alignItems={{ sm: "center" }}>
								<Typography width={{ xs: "100%", sm: "60%" }}>
									Tenure:
								</Typography>
								<TextField
									fullWidth
									type="text"
									value={data.detailsOfRequest.equityContribution}
									InputProps={{
										readOnly: "true",
									}}
								/>
							</Stack> */}

							{/* Interest Rate */}
							<Stack
								spacing={1}
								direction={{ sm: "row" }}
								justifyContent={{ sm: "space-between" }}
								alignItems={{ sm: "center" }}>
								<Typography width={{ xs: "100%", sm: "60%" }}>
									Interest Rate:
								</Typography>
								<TextField
									fullWidth
									type="text"
									value={interestRate.toLocaleString()}
									InputProps={{
										readOnly: "true",
										startAdornment: (
											<InputAdornment position="start">
												<Typography sx={{ fontFamily: "ZabalMedium" }}>
													NGN
												</Typography>
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="start">
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}>
													<Typography
														color="primary"
														sx={{ fontFamily: "ZabalMedium" }}>
														({data.detailsOfRequest.monthlyInterest}%P/M)
													</Typography>
													<BiSolidLock
														size={15}
														color={theme.palette.primary.main}
													/>
												</Stack>
											</InputAdornment>
										),
									}}
									onInput={onInputAmount}
									onBlur={(e) => onBlurAmount(e, interestRate)}
								/>
							</Stack>

							{/* New Interest Rate */}
							<Stack
								spacing={1}
								direction={{ sm: "row" }}
								justifyContent={{ sm: "space-between" }}
								alignItems={{ sm: "center" }}>
								<Typography width={{ xs: "100%", sm: "60%" }}>
									New Request:
								</Typography>
								<TextField
									fullWidth
									type="tel"
									name="interest"
									value={formRestructure.interest}
									InputProps={{
										readOnly: "true",
										startAdornment: (
											<InputAdornment position="start">
												<Typography sx={{ fontFamily: "ZabalMedium" }}>
													NGN
												</Typography>
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="start">
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}>
													<Typography
														color="primary"
														sx={{ fontFamily: "ZabalMedium" }}>
														({data.detailsOfRequest.monthlyInterest}%P/M)
													</Typography>
													<BiSolidLock
														size={15}
														color={theme.palette.primary.main}
													/>
												</Stack>
											</InputAdornment>
										),
									}}
								/>
							</Stack>

							<Box
								mt={2}
								sx={{
									display: "flex",
									justifyContent: { sm: "flex-end" },
								}}>
								<Button
									type="submit"
									variant="contained"
									sx={{ width: { xs: "100%", sm: "" } }}>
									Submit
								</Button>
							</Box>
						</Stack>
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							overflow: "hidden",
						}}>
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={3}>
							<QuestionMarkIcon />
							<Typography>Are you sure you want to submit</Typography>
							<Stack direction="row" spacing={2}>
								<Button
									variant="outlined"
									color="primary"
									onClick={() => setVerify(false)}>
									No
								</Button>
								{loading ? (
									<Button variant="contained" type="button" color="primary">
										<Preloader />
									</Button>
								) : (
									<Button
										variant="contained"
										type="button"
										color="primary"
										onClick={handleRestructure}>
										Yes
									</Button>
								)}
							</Stack>
						</Stack>
					</Box>
				)}
			</Box>
		</>
	);
}

Restructure.propTypes = {
	setRestructureModal: PropTypes.func,
	setVerify: PropTypes.func,
	verify: PropTypes.bool,
	data: PropTypes.any,
};
export default Restructure;
