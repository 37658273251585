import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { MdClose } from "react-icons/md";
import ModalContainer from "../../../components/ModalContainer";
import { CustomConfirmModal } from "../../../layout/CustomComponents";

const VerifyDeposit = () => {
	const [num, setNum] = useState(300);
	const [closeConfirmation, setCloseConfirmation] = useState(false);

	let intervalRef = useRef();

	const decreaseNum = () => setNum((prev) => prev - 1);
	useEffect(() => {
		intervalRef.current = setInterval(decreaseNum, 1000);

		return () => clearInterval(intervalRef.current);
	}, []);

	if (num <= 0) {
		clearInterval(intervalRef.current);
	}

	const mins = Math.floor(num / 60);
	const secs = Math.floor(num % 60);

	return (
		<>
			{closeConfirmation && (
				<ModalContainer modal={closeConfirmation}>
					<CustomConfirmModal
						text="Closing deposit confirmation modal will reload the page. Do you want to continue ?"
						greenBtnClick={() => window.location.reload()}
						onCloseBtnClick={() => setCloseConfirmation(false)}
						greenBtnText="yes"
					/>
				</ModalContainer>
			)}
			<div className="bg-white rounded-lg p-5 md:p-6 pb-7 sm:pb-5 text-[#393F42] w-[90%] max-w-[400px] md:max-w-[450px] mx-auto gap-2 flex flex-col items-center relative">
				<p className="text-sm w-10/12 mx-auto mb-3">
					Deposit confirmation can take up to five minutes.
				</p>
				<button
					className="absolute right-3 top-3"
					onClick={() => setCloseConfirmation(true)}
				>
					<MdClose size={20} />
				</button>
				<CountdownCircleTimer
					isPlaying
					duration={300}
					colors={["#00525D", "#003A50", "#f00"]}
					colorsTime={[300, 15, 0]}
					size={80}
					strokeWidth={5}
				></CountdownCircleTimer>
				<p
					className={`font-bold text-xl ${
						num < 5 ? "text-red-500" : "#00525D"
					}`}
				>
					{num > 0 ? `${mins}:${secs < 10 ? `0${secs}` : secs}` : "0.00"}
				</p>
				<p className="text-[#8E9DA4] text-sm">Confirming Payment</p>
			</div>
		</>
	);
};

export default VerifyDeposit;

VerifyDeposit.propTypes = {
	setActivePaymentTab: PropTypes.func,
};
